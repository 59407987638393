import './index.css';
import { ICriterium } from 'src/utils/types';
import { useEffect, useRef, useState } from 'react';
import useAutosizeTextArea from '../../utils/useAutosizeTextArea';
import { ReactComponent as TrashIcon } from '../../assets/icon-trash.svg'

interface CriteriumProps {
    item: ICriterium;
    handleDeleteCriterium: (criterium: ICriterium) => void;
    handleUpdateCriterium: (criteriumToUpdate: ICriterium) => void;
    updateErrorMessage: (message: string) => void;
}

const Criterium = ({ item: criterium, handleDeleteCriterium, handleUpdateCriterium, updateErrorMessage }: CriteriumProps) => {

    const nameTextAreaRef = useRef<HTMLTextAreaElement>(null);
    const [name, setName] = useState<string>(criterium.name);
    const descriptionTextAreaRef = useRef<HTMLTextAreaElement>(null);
    const [description, setDescription] = useState<string>(criterium.description);
    const pointsInputRef = useRef<HTMLInputElement>(null);
    const [nameError, setNameError] = useState<boolean>(false);

    useAutosizeTextArea(nameTextAreaRef.current, name);
    useAutosizeTextArea(descriptionTextAreaRef.current, description);

    useEffect(() => {
        if (nameError && name.trim() !== '') {
            setNameError(false);
            updateErrorMessage('');
        }
    }, [name]);

    return (
        <div className="criterium">
            <div className="criterium-header">
                <div className="criterium-header-left">
                    <div className="criterium-name">
                        <textarea
                            className={`text input-editable ${nameError ? 'error' : ''}`}
                            placeholder='Nom du critère'
                            value={name}
                            maxLength={50}
                            onChange={(e) => {
                                setName(e.target.value);
                                handleUpdateCriterium({ ...criterium, name: e.target.value });
                                if (e.target.value.trim() === '') {
                                    setNameError(true);
                                    updateErrorMessage('Veuillez entrer un nom pour le critère.');
                                } else {
                                    setNameError(false);
                                    updateErrorMessage('');
                                }
                            }}
                            ref={nameTextAreaRef}
                        />
                    </div>
                    <TrashIcon className='trash-icon' onClick={() => handleDeleteCriterium(criterium)} />
                </div>
                <div className="criterium-header-right">
                    <p>
                        <input
                            type='number'
                            min={0}
                            className="small-lead-text input-editable"
                            defaultValue={!isNaN(criterium.valuePoints) ? criterium.valuePoints : 0}
                            onChange={(e) => handleUpdateCriterium({ ...criterium, valuePoints: parseInt(e.target.value) || 0 })}
                            ref={pointsInputRef}
                        />
                        <span className='text'>pts</span>
                    </p>
                </div>
            </div>
            <div className="criterium-description">
                <textarea
                    className="text input-editable"
                    placeholder='Description'
                    value={description}
                    maxLength={250}
                    onChange={(e) => {
                        setDescription(e.target.value);
                        handleUpdateCriterium({ ...criterium, description: e.target.value });
                    }}
                    ref={descriptionTextAreaRef}
                />
            </div>
        </div>
    );
};

export default Criterium;
