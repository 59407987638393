import {generateRandomPlanning, localiseDate} from "../../utils/functions";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

// style
import './index.css';

// constants
import {YEAR_MONTHS} from "../../utils/consts";


// react
import {useEffect, useState} from "react";

// planning type
import {IPlanning} from "../../utils/types";

// moment
import moment from "moment";
import TimelineChallengeItem from "../../components/TimelineChallengeItem";

import {useSelector} from "react-redux";
import {timelineOppositeContentClasses} from "@mui/lab";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {useParams} from "react-router-dom";
import {useTheme} from "@mui/material";
import api from "../../utils/api";

const fetchPlanning = (setPlanning: (data: any) => void, challengeId: number) => {
    api.get("/challenge/planning?challengeId=" + challengeId,
        {withCredentials: true}
    )
        .then((response) => {
            const planning = response.data.planning;

            //TODO: À SUPPRIMER APRÈS LE HACKATHON AMBASSADE
            // Ajout manuel des évènements des pitchs et de la soirée Alumni

            if (challengeId === 23) {
                const newEvents = [
                    {
                        event: "projectPitch",
                        date: "2024-05-10T13:00:00.000Z",
                        text: "Pitchs des projets",
                    },
                    {
                        event: "alumniNight",
                        date: "2024-05-23T16:30:00.000Z",
                        text: "Soirée Alumni Day UK à la Résidence de France (remise des prix)",
                    },
                ];

                const planningWithNewEvents = [...planning, ...newEvents].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                setPlanning(planningWithNewEvents)
            } else {
                setPlanning(planning)
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

function TheTheme() {
    const theme = useTheme();
    return theme;
}

const ModuleChallengePlanning = () => {
    const [planning, setPlanning] = useState<IPlanning[]>([]);

    const challengeId = useParams().id;

    // const userIsOnMobile = window.innerWidth <= 768;
    const theme = useTheme();
    const userIsOnMobile = theme.breakpoints.down("md");
    useEffect(() => {
        fetchPlanning(setPlanning, Number(challengeId) || 0);
    }, [])

    return (
        <div className="admin-challenge-planning-content">
            <Timeline position="right" sx={{
                width: {xs: '100%', lg: '70vw', xl: '65vw'},
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: {xs: 0, sm: 0.4},
                },
            }}>
                <TimelineItem sx={{
                    minHeight: '0',
                    height: '2.5rem'
                }}>
                    <TimelineOppositeContent sx={{
                        // pour que le texte soit aligné avec le point
                        // clown emoji
                        padding: {xs: '0', sm: '0px 16px'},
                        marginTop: userIsOnMobile ? '-32.5px' : '-20px',
                        marginLeft: {xs: '5px', sm: '10px'},
                    }}/>
                    <TimelineSeparator>
                        <TimelineConnector sx={{
                            backgroundColor: 'var(--secondary50)',
                            opacity: 1,
                            width: "2px"
                        }}/>
                    </TimelineSeparator>
                    <TimelineContent/>
                </TimelineItem>
                {planning && planning.map((item, index: number, items) =>
                    <TimelineChallengeItem
                        key={index}
                        date={item.date}
                        event={item.event}
                        name={item.text}
                        nextDate={items[index + 1] ? items[index + 1].date : ""}
                        hasSelection={item.hasSelection}
                        end={!items[index + 1]}
                    />
                )}
            </Timeline>
        </div>
    )
}

export default ModuleChallengePlanning;