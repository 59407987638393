// react
import React, { useEffect, useRef, useState } from 'react';
// css
import './index.css';

//cookies
import CookieConsent from './components/cookie-consent';

// react-router
import { redirect, useLocation, useNavigate } from 'react-router-dom';

// components
import RouteurComponent from './routes';

// assets
import { ReactComponent as IconFeat } from './assets/icon-feat.svg';

// context
import { useAuth } from './context/AuthContext'

// modules
import Header from './modules/Header';
import NavBar from './modules/NavBar';
import Footer from './modules/Footer';
import LoginHeader from "./modules/LoginHeader";
import LoginHeader2 from "./modules/LoginHeader2";
import { useMediaQuery, useTheme } from '@mui/material';
import TopBannerAbout from "../src/components/TopBannerAbout";

import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import api from "./utils/api";

const App = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
    // récupération de l'url courante
    const location = useLocation();
    const pathnameRef = useRef(location.pathname);;

    const [errorCode, setErrorCode] = useState(null);

    const projectPageRegex = new RegExp(/^\/participant\/challenge\/\d+\/project\/\d+$/);
    const challengePageRegex = new RegExp(/^\/challenge\/\d+\/general$/)
    const pathsToIgnore498Error = ["/login/1", "/signup", "/error", "/participant/portfolio", "/login/choice/profile", "/reset-password", "/forgot/password"];

    useEffect(() => {
        pathnameRef.current = location.pathname;
    }, [location.pathname]);

    // Intercepteur pour capturer les erreurs
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            // Récupérer le code d'erreur
            if (error.response.status) {
                const code = error.response.status;

                const pathname = String(pathnameRef.current);

                // Stocker le code d'erreur dans l'état
                setErrorCode(code);

                if (code === 498 && pathname !== "/" && !pathsToIgnore498Error.some(item => pathname.includes(item))
                    && !projectPageRegex.test(pathname) && (!challengePageRegex.test(pathname) && (error.response.data.code === "notConnected" || error.response.data.code === "sessionExpired"))) {
                    logout();
                    navigate("/error/498");
                }

                if (code === 401) {
                    navigate("/error/401");
                }

                return Promise.reject(error);
            }
        }
    );

    const navBarBlacklist = [
        "/admin/challenges/edit",
        "/login/choice/profile",
        "/my-profile/update",
        "/signup",
        "/error"
    ];
    const headerBlacklist = [
        "/admin/challenge/edit",
        "/login/choice/profile",
        "/signup",
        "/error"
    ];

    const headerNoLogedBlacklist = [
        "/login/1",
        "/signup/participant",
    ];

    const navBarMobileBlacklist = [
        "/my-profile/update",
        "/my-profile/change"
    ];
    const navBarMdBlacklist = [
        "/projects/selections"
    ];

    // const headerAboutWhitelist = [
    //     // "/login/1",
    // ]
    const headerAboutWhitelist2 = [
        "/signup/participant",
        "/reset-password",
        "/reset-password/done",
        "/forgot/password",
        "/login",
        "/signup/admin",
        "/error/498"
    ]

    const { isAuthenticated, login, setFinishedCheckingAuth, finishedCheckingAuth, logout } = useAuth();
    const showNavBar = !navBarBlacklist.some(item => location.pathname.includes(item));
    const showHeader = !headerBlacklist.some(item => location.pathname.includes(item));
    // const showHeaderAbout = headerAboutWhitelist.some(item => location.pathname.includes(item));
    // const showHeaderNone = headerNoneWhitelist.some(item => location.pathname.includes(item));
    const showHeaderAbout2 = headerAboutWhitelist2.some(item => location.pathname.includes(item));
    const hideNavBarMobile = isMdDown && navBarMdBlacklist.some(item => location.pathname.includes(item));
    const containerAppConfigure = navBarMobileBlacklist.some(item => location.pathname.includes(item));

    const [contentChanged, setContentChanged] = useState(false);
    const navigate = useNavigate();

    // const scrollPosition = useRef<{ [key: string]: number }>({});

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location.pathname]);
    useEffect(() => {
        const checkAuth = async () => {
            await login();
        };
        if (!finishedCheckingAuth) {
            checkAuth();
        }
    }, [finishedCheckingAuth, login]);

    return (
        <>
            <I18nextProvider i18n={i18next}>
                {showHeader && isAuthenticated && <Header />}
                {!isAuthenticated && !showHeaderAbout2 && <LoginHeader />}
                {!isAuthenticated && showHeaderAbout2 && <LoginHeader2 />}
                {(showNavBar && isAuthenticated) && !hideNavBarMobile && <NavBar />}
                <CookieConsent />
                <div
                    className={`${(showHeader && showNavBar) ?
                        'container-app' :
                        ''} 
                    ${!isAuthenticated ?
                            'container-app-logout' :
                            ''}
                    ${containerAppConfigure ?
                            'container-app-configure' :
                            ''}`}>
                    <RouteurComponent /> { /* routes */}
                </div>
                {/*<Footer/>*/}
            </I18nextProvider>
        </>
    );
}

export default App;