import "./index.css";
import React, {useEffect, useRef, useState} from "react";
import StepperGradient from "../../../../../../components/StepperGradient";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    Typography
} from "@mui/material";
import {
    ReactComponent as ImgNoUserInChallengeRight
} from '../../../../../../assets/imgs/no_users_in_challenge_look_right.svg';

import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {ReactComponent as ArrowRight} from "../../../../../../assets/arrow-right.svg";

import ActionButton from "../../../../../../components/ActionButton";
import {
    IALLProjects,
    IListProjectDeposit, IListProjectDepositSorted, IProjects, IRowsProject
} from "./types";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import api from "../../../../../../utils/api";

const AdminInfosChallengeProjects = () => {
    const [listProjectDeposit, setListProjectDeposit] = useState<IListProjectDeposit[]>(
        [
            {
                nameTeam: 'Équipe A',
                nameProject: 'Tesla 1',
                nbMembers: 5,
                nameSubChallenge: 'Tesla Challenge',
                projectSent: true,
                dateDepositAt: '2023-10-26 17:29:34',
                image: 'https://images.unsplash.com/photo-1603811478698-0b1d6256f79a?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dG95b3RhJTIwc3VwcmF8ZW58MHx8MHx8fDA%3D'
            },
            {
                nameTeam: 'Équipe B',
                nameProject: 'Shelby 1',
                nbMembers: 3,
                nameSubChallenge: 'Shelby Challenge',
                projectSent: false,
                dateDepositAt: '',
                image: 'https://wallpapers.com/images/hd/iconic-metallic-orange-toyota-supra-8fowwgubilou0y4r.jpg'
            },
            {
                nameTeam: 'Équipe C',
                nameProject: 'Tesla 2',
                nbMembers: 4,
                nameSubChallenge: 'Tesla Challenge',
                projectSent: true,
                dateDepositAt: '2023-10-25 11:28:07',
                image: 'https://i.pinimg.com/originals/c1/18/ea/c118ea8520504ef2788607a0835b39e2.jpg'
            },
            {
                nameTeam: 'Équipe D',
                nameProject: 'GTR 1',
                nbMembers: 2,
                nameSubChallenge: 'GTR',
                projectSent: true,
                dateDepositAt: '2023-10-24 08:58:36',
                image: 'https://i.pinimg.com/originals/af/09/b1/af09b13f4e38effd157316f054854c0f.jpg'
            },
            {
                nameTeam: 'Équipe E',
                nameProject: 'Shelby 2',
                nbMembers: 6,
                nameSubChallenge: 'Shelby Challenge',
                projectSent: true,
                dateDepositAt: '2023-10-23 09:45:15',
                image: 'https://wallpapers.com/images/hd/black-toyota-supra-car-at-dusk-bgfmpvccno21tadz.jpg'
            },
            {
                nameTeam: 'Équipe F',
                nameProject: '205 1',
                nbMembers: 4,
                nameSubChallenge: '205 Challenge',
                projectSent: false,
                dateDepositAt: '',
                image: 'https://cdn.wallpapersafari.com/0/16/6M0Hhw.jpg'
            },
            {
                nameTeam: 'Équipe G',
                nameProject: 'GTR 2',
                nbMembers: 3,
                nameSubChallenge: 'GTR Challenge',
                projectSent: true,
                dateDepositAt: '2023-10-22 15:23:10',
                image: 'https://cdn.wallpapersafari.com/61/55/6Fhpbd.jpg'
            },
            {
                nameTeam: 'Équipe H',
                nameProject: 'Tesla 3',
                nbMembers: 2,
                nameSubChallenge: 'Tesla Challenge',
                projectSent: true,
                dateDepositAt: '2023-10-21 10:58:01',
                image: 'https://cdn.suwalls.com/wallpapers/cars/toyota-supra-47952-1920x1080.jpg'
            },
        ]
    );
    const [sortBySubChallenge, setSortBySubChallenge] = useState(false);
    const [sortBySubChallengeEliminated, setSortBySubChallengeEliminated] = useState(false);
    const challengeId = useParams().id;
    const [allProjects, setAllProjects] = useState<IALLProjects>();
    const [listSortBySubChallenge, setListSortBySubChallenge] = useState<IListProjectDepositSorted[]>([]);
    const [listSortBySubChallengeEliminated, setListSortBySubChallengeEliminated] = useState<IListProjectDepositSorted[]>([]);
    const showSlide = () => {
        if (!sortBySubChallenge) {
            setSortBySubChallenge(true);
        }
    };

    const hideSlide = () => {
        if (sortBySubChallenge) {
            setSortBySubChallenge(false);
        }
    };

    const getAllProjects = async () => {
        await api.get("/admin/projectsMonitoring",
            {
                withCredentials: true,
                params: {
                    challengeId: challengeId
                }
            })
            .then((res) => {
                setAllProjects(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function groupProjectsBySubChallenge(projects: IRowsProject[]) {
        const newListSortBySubChallenge: IListProjectDepositSorted[] = [];

        projects.forEach((project) => {
            const subChallengeIndex = newListSortBySubChallenge.findIndex((item) => item.subChallenge === project.subChallenge?.name);
            if (subChallengeIndex === -1) {
                newListSortBySubChallenge.push({
                    subChallenge: project.subChallenge?.name,
                    position: project.subChallenge?.position,
                    projects: [project],
                });
            } else {
                newListSortBySubChallenge[subChallengeIndex].projects.push(project);
            }
        });
        newListSortBySubChallenge.sort((a, b) => (a.position || 0) - (b.position || 0));
        setListSortBySubChallenge(newListSortBySubChallenge);
    }

    function groupProjectsBySubChallengeEliminated(projects: IRowsProject[]) {
        const newListSortBySubChallengeEliminated: IListProjectDepositSorted[] = [];

        projects.forEach((project) => {
            const subChallengeIndex = newListSortBySubChallengeEliminated.findIndex((item) => item.subChallenge === project.subChallenge?.name);
            if (subChallengeIndex === -1) {
                newListSortBySubChallengeEliminated.push({
                    subChallenge: project.subChallenge?.name,
                    position: project.subChallenge?.position,
                    projects: [project],
                });
            } else {
                newListSortBySubChallengeEliminated[subChallengeIndex].projects.push(project);
            }
        });
        setListSortBySubChallengeEliminated(newListSortBySubChallengeEliminated);
    }

    useEffect(() => {
        getAllProjects();
    }, []);
    useEffect(() => {
    }, [allProjects]);
    const projectsDeposit = allProjects?.runningProjects.rows.filter((project) => {
        if (project.step_projects.length > 0) {
            return true;
        } else {
            return false;
        }
    });
    const nbProjectsDeposit = projectsDeposit?.filter((item) => item.step_projects[0].sent).length;
    const navigate = useNavigate();
    // @ts-ignore
    // @ts-ignore
    return (
        <div className={`container-admin-infos-challenge-project`}>
            {
                allProjects && (allProjects.runningProjects.count > 0 || allProjects.eliminatedProjects.count > 0) ? (
                    <>
                        <div className="admin-infos-challenge-project-header">
                            <div className="admin-infos-challenge-project-header-content">
                                <div>
                                    <h2>Avancement</h2>
                                    <p>Etape {allProjects.runningProjects && allProjects.countDoneSteps} sur {allProjects.countAllSteps}</p>
                                </div>
                                <div className="admin-infos-challenge-project-header-content-stepper">
                                    <StepperGradient totalSteps={allProjects.countAllSteps}
                                                     activeStep={allProjects.countDoneSteps}
                                                     runningStep={allProjects.runningProjects && allProjects.countDoneSteps + 1}
                                    />
                                </div>
                            </div>
                            <div className="admin-infos-challenge-project-header-content">
                                {
                                    allProjects.stepDetails ? (
                                        <>
                                            <div>
                                                <h2>Étape {allProjects.runningProjects && allProjects.countDoneSteps} - {allProjects.stepDetails?.name}</h2>
                                                {
                                                    allProjects.stepDetails?.hasSelection && (
                                                        <p>Avec sélection</p>
                                                    )
                                                }
                                            </div>
                                            <Chip
                                                label={`Du ${moment(allProjects.stepDetails.dateStartDeposite).format("L")} à ${moment(allProjects.stepDetails.dateStartDeposite).format("HH[h]mm")} au ${moment(allProjects.stepDetails.dateEndDeposite).format("L")} à ${moment(allProjects.stepDetails.dateEndDeposite).format("HH[h]mm")}`}
                                                sx={{
                                                    backgroundColor: "var(--primary10)",
                                                    color: "var(--primary90)",
                                                    fontSize: "14px",
                                                    fontFamily: "var(--font-secondary)",
                                                    fontWeight: "700",
                                                    minHeight: "2rem",
                                                    maxWidth: "90%",
                                                    height: 'auto',
                                                    '& .MuiChip-label': {
                                                        display: 'block',
                                                        whiteSpace: 'normal',
                                                    },
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <h2>Étape non démarrée</h2>
                                    )
                                }
                            </div>
                        </div>
                        <div className="admin-infos-challenge-project-body">
                            <div className="admin-infos-challenge-project-body-content">
                                <div className="admin-infos-challenge-project-body-content-header">
                                    <div
                                        className="admin-infos-challenge-project-body-content-header-bottom">
                                        {
                                            allProjects.stepDetails && allProjects.stepDetails?.hasSelection && (moment().isBetween(allProjects.stepDetails.dateEndDeposite, allProjects.stepDetails.dateEndSelection)) && (
                                                <>
                                                    <p>Fin de la phase de sélection
                                                        : {moment(allProjects.stepDetails.dateEndSelection).from(moment())}</p>
                                                    <ActionButton
                                                        variant={"primary"}
                                                        onClick={() => navigate(`/admin/challenge/${challengeId}/projects/selections`)}
                                                    >
                                                        Commencer la sélection des projets
                                                    </ActionButton>
                                                </>
                                            )
                                        }
                                        {
                                            allProjects.stepDetails && allProjects.stepDetails?.hasSelection && (moment().isBetween(allProjects.stepDetails.dateStartDeposite, allProjects.stepDetails.dateEndDeposite)) && (
                                                <>
                                                    <p>Début de la phase de sélection
                                                        : {moment(allProjects.stepDetails.dateEndDeposite).from(moment())}</p>
                                                    <ActionButton
                                                        variant={"disabled"}
                                                        // onClick={() => navigate(`/admin/challenge/${challengeId}/projects/selections`)}
                                                    >
                                                        Commencer la sélection des projets
                                                    </ActionButton>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div
                                        className="admin-infos-challenge-project-body-content-header-top">
                                        <h2>{nbProjectsDeposit ? nbProjectsDeposit > 1 ? `${nbProjectsDeposit} projets déposés` : `${nbProjectsDeposit} projet déposé` : "0 projets déposés"}</h2>
                                        <p>sur {allProjects.runningProjects.rows.length + allProjects.eliminatedProjects.rows.length} {(allProjects.runningProjects.rows.length + allProjects.eliminatedProjects.rows.length) > 1 ? "équipes participantes" : "équipe participante"}</p>
                                    </div>
                                    <div
                                        className="admin-infos-challenge-project-body-content-header-middle">
                                        {
                                            allProjects.runningProjects.rows.some((item) => item.subChallenge !== null) && (
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox
                                                        checked={sortBySubChallenge}
                                                        onChange={(e) => {
                                                            setSortBySubChallenge(e.target.checked);
                                                            if (e.target.checked) {
                                                                groupProjectsBySubChallenge(allProjects?.runningProjects.rows);
                                                                hideSlide();
                                                            } else {
                                                                showSlide();
                                                            }
                                                        }}
                                                    />}
                                                    label="Trier par défi"
                                                    labelPlacement="end"
                                                    sx={
                                                        {
                                                            '& .MuiTypography-root': {
                                                                fontSize: '0.75rem',
                                                                color: 'var(--primary90)',
                                                                fontFamily: 'var(--font-secondary)',
                                                                fontWeight: '600'
                                                            },
                                                            '& .MuiCheckbox-root': {
                                                                color: 'var(--primary50)',
                                                            },
                                                            '& .MuiCheckbox-root:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                            '& .Mui-checked': {
                                                                color: 'var(--actionprimary50)!important',
                                                            },
                                                            '& .MuiCheckbox-root.Mui-checked:hover': {
                                                                backgroundColor: 'transparent',
                                                            }
                                                        }
                                                    }
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="admin-infos-challenge-project-body-content-body">
                                    <div
                                        className={`list-project ${sortBySubChallenge ? 'showList' : 'hideList'}`}
                                    >
                                        {
                                            listSortBySubChallenge.map((subChallenge, subChallengeIndex) => (
                                                <div className={"list-projects-sorted"}
                                                     key={subChallengeIndex}>
                                                    <p className={"typo-card-h3"}>Défi {subChallenge?.position} - {subChallenge.subChallenge}</p>
                                                    <Grid container spacing={3}
                                                          justifyContent={"center"}>
                                                        {
                                                            subChallenge.projects.map((project, projectIndex) => (
                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}
                                                                      key={projectIndex}
                                                                >
                                                                    <Card
                                                                        className="admin-infos-challenge-project-list">
                                                                        {
                                                                            project.file ? (
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    alt="image card projet déposé"
                                                                                    image={project.file.fileUrl}
                                                                                />
                                                                            ) : (
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    alt="image card projet déposé"
                                                                                    image={"/images/couverture-challenge.jpg"}
                                                                                />
                                                                            )
                                                                        }
                                                                        <CardContent>
                                                                            <Typography variant="h3"
                                                                                        className="typo-card-h3">
                                                                                {project.team.name}
                                                                            </Typography>
                                                                            <Typography variant="h3"
                                                                                        className="typo-card-h4">
                                                                                {project.name}
                                                                            </Typography>
                                                                            <Typography marginTop={1}
                                                                                        variant="body1"
                                                                                        className="typo-card-p">
                                                                                {project.team.profiles.length} {project.team.profiles.length > 1 ? "participants" : "participant"}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <CardActions
                                                                            className={`typo-card-bottom ${!project.step_projects[0] && 'typo-card-bottom-error'}`}
                                                                        >
                                                                            <Typography variant="body1"
                                                                                        className="typo-card-p typo-card-dateDeposit">
                                                                                {project.step_projects[0]
                                                                                    ? `Déposé le ${moment(project.step_projects[0].dateDeposite).format('DD/MM/YYYY à HH[h]mm')}`
                                                                                    : "Projet non déposé"}
                                                                            </Typography>
                                                                            {
                                                                                project.step_projects[0] && (
                                                                                    <ActionButton
                                                                                        variant="little-underline"
                                                                                        onClick={() => navigate(`/admin/challenge/${challengeId}/project/${project.id}`)}>Voir
                                                                                        le
                                                                                        projet</ActionButton>
                                                                                )
                                                                            }
                                                                        </CardActions>
                                                                    </Card>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div
                                        className={`list-project ${sortBySubChallenge ? 'hideList' : 'showList'}`}

                                    >
                                        <Grid container spacing={3} justifyContent={"center"}>
                                            {
                                                allProjects.runningProjects.rows.map((project, index) => (
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                                                        <Card
                                                            className={"admin-infos-challenge-project-list"}>
                                                            {
                                                                project.file ? (
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt="image card projet déposé"
                                                                        image={`${project.file.fileUrl}`}
                                                                    />
                                                                ) : (
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt="image card projet déposé"
                                                                        image={"/images/couverture-challenge.jpg"}
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                project.subChallenge && (
                                                                    <Chip
                                                                        label={`Défi ${project.subChallenge.position} - ${project.subChallenge.name}`}
                                                                        sx={{
                                                                            backgroundColor: "white",
                                                                            padding: "5px",
                                                                            fontFamily: "var(--font-secondary)",
                                                                            lineHeight: "24px",
                                                                            color: "var(--primary90)",
                                                                            fontWeight: "500",
                                                                            fontSize: "11px",
                                                                            position: "absolute",
                                                                            top: "1rem",
                                                                            right: "1rem"
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            <CardContent>
                                                                <Typography variant="h3"
                                                                            className={"typo-card-h3"}>
                                                                    {project.team.name}
                                                                </Typography>
                                                                <Typography variant="h3"
                                                                            className={"typo-card-h4"}>
                                                                    {project.name}
                                                                </Typography>
                                                                <Typography marginTop={1}
                                                                            variant="body1"
                                                                            className={"typo-card-p"}>
                                                                    {project.team.profiles.length} {project.team.profiles.length > 1 ? "participants" : "participant"}
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions
                                                                className={`typo-card-bottom ${!project.step_projects[0]?.sent && 'typo-card-bottom-error'}`}>
                                                                <Typography variant={"body1"}
                                                                            className={"typo-card-p typo-card-dateDeposit"}>
                                                                    {
                                                                        project.step_projects[0]?.sent ?
                                                                            `Déposé le ${moment(project.step_projects[0].dateDeposite).format('DD/MM/YYYY à HH[h]mm')}` :
                                                                            `Projet non déposé`
                                                                    }
                                                                </Typography>
                                                                {
                                                                    ((!project.step_projects[0]?.sent) && (allProjects?.countDoneSteps >= 1)) && (
                                                                        <>
                                                                            Étape {allProjects?.countDoneSteps} sur {allProjects?.countAllSteps}
                                                                            <ActionButton
                                                                                variant={"see-project"}
                                                                                icon={<ArrowRight/>}
                                                                                iconEnd={true}
                                                                                onClick={() => {
                                                                                    navigate(`/admin/challenge/${challengeId}/project/${project.id}`)
                                                                                }}>Voir le
                                                                                projet</ActionButton>
                                                                        </>
                                                                    )
                                                                }

                                                                {
                                                                    project.step_projects[0]?.sent && (
                                                                        <ActionButton
                                                                            variant={"see-project"}
                                                                            icon={<ArrowRight/>}
                                                                            iconEnd={true}
                                                                            onClick={() => {
                                                                                navigate(`/admin/challenge/${challengeId}/project/${project.id}`)
                                                                            }}>Voir le
                                                                            projet</ActionButton>
                                                                    )
                                                                }
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </div>
                                </div>
                                <div className="admin-infos-challenge-project-body-content-header">
                                    <div
                                        className="admin-infos-challenge-project-body-content-header-top">
                                        <h2>{allProjects.eliminatedProjects.count} {allProjects.eliminatedProjects.count > 1 ? "équipes non retenues" : "équipe non retenue"}</h2>
                                    </div>
                                    {
                                        allProjects.eliminatedProjects.rows.some((item) => item.subChallenge !== null) && (
                                            <div
                                                className="admin-infos-challenge-project-body-content-header-middle">
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox
                                                        checked={sortBySubChallengeEliminated}
                                                        onChange={(e) => {
                                                            setSortBySubChallengeEliminated(e.target.checked);
                                                            if (e.target.checked) {
                                                                groupProjectsBySubChallengeEliminated(allProjects?.eliminatedProjects.rows);
                                                                hideSlide();
                                                            } else {
                                                                showSlide();
                                                            }
                                                        }}
                                                    />}
                                                    label="Trier par défi"
                                                    labelPlacement="end"
                                                    sx={
                                                        {
                                                            '& .MuiTypography-root': {
                                                                fontSize: '0.75rem',
                                                                color: 'var(--primary90)',
                                                                fontFamily: 'var(--font-secondary)',
                                                                fontWeight: '600'
                                                            },
                                                            '& .MuiCheckbox-root': {
                                                                color: 'var(--primary50)',
                                                            },
                                                            '& .MuiCheckbox-root:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                            '& .Mui-checked': {
                                                                color: 'var(--actionprimary50)!important',
                                                            },
                                                            '& .MuiCheckbox-root.Mui-checked:hover': {
                                                                backgroundColor: 'transparent',
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="admin-infos-challenge-project-body-content-body">
                                    <div
                                        className={`list-project ${sortBySubChallengeEliminated ? 'showList' : 'hideList'}`}
                                    >
                                        {
                                            listSortBySubChallengeEliminated.map((subChallenge, subChallengeIndex) => (
                                                <div className={"list-projects-sorted"}
                                                     key={subChallengeIndex}>
                                                    <p className={"typo-card-h3"}>Défi {subChallenge?.position} - {subChallenge.subChallenge}</p>
                                                    <Grid container spacing={3}
                                                          justifyContent={"center"}>
                                                        {
                                                            subChallenge.projects.map((project, projectIndex) => (
                                                                <Grid item xs={12} sm={6} md={6} lg={4}
                                                                      key={projectIndex}>
                                                                    <Card key={projectIndex}
                                                                          className={"admin-infos-challenge-project-list admin-infos-challenge-project-list-disqualifed"}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            alt="image card projet éliminé"
                                                                            height="50%"
                                                                            image={`${project.file.fileUrl}`}
                                                                        />
                                                                        <CardContent>
                                                                            <Typography variant="h3"
                                                                                        className={"typo-card-h3"}>
                                                                                {project.team.name}
                                                                            </Typography>
                                                                            <Typography variant="h3"
                                                                                        className={"typo-card-h4"}>
                                                                                {project.name}
                                                                            </Typography>
                                                                            <Typography marginTop={1}
                                                                                        variant="body1"
                                                                                        className={"typo-card-p"}>
                                                                                {project.team.profiles.length} {project.team.profiles.length > 1 ? "participants" : "participant"}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <CardActions
                                                                            className={`typo-card-bottom typo-card-bottom-disqualified`}>
                                                                            <Typography
                                                                                variant={"body1"}
                                                                                className={"typo-card-p"}>
                                                                                Non retenue
                                                                            </Typography>
                                                                            <ActionButton
                                                                                variant={"little-underline"}
                                                                                onClick={() => {
                                                                                    navigate(`/admin/challenge/${challengeId}/project/${project.id}`)
                                                                                }}>Voir le
                                                                                projet</ActionButton>
                                                                        </CardActions>
                                                                    </Card>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div
                                        className={`list-project ${sortBySubChallengeEliminated ? 'hideList' : 'showList'}`}>
                                        <Grid container spacing={3} justifyContent={"center"}>
                                            {
                                                allProjects.eliminatedProjects.rows.map((project, index) => (
                                                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                                                        <Card key={index}
                                                              className={"admin-infos-challenge-project-list admin-infos-challenge-project-list-disqualifed"}>
                                                            {
                                                                project.file?.fileUrl && (
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt="image card projet éliminé"
                                                                        height="50%"
                                                                        image={`${project.file.fileUrl}`}
                                                                    />
                                                                )
                                                            }
                                                            {project.subChallenge && (
                                                                <Chip
                                                                    label={`Défi ${project.subChallenge?.position} - ${project.subChallenge?.name}`}
                                                                    sx={{
                                                                        backgroundColor: "white",
                                                                        padding: "5px",
                                                                        fontFamily: "var(--font-secondary)",
                                                                        lineHeight: "24px",
                                                                        color: "var(--primary90)",
                                                                        fontWeight: "500",
                                                                        fontSize: "11px",
                                                                        position: "absolute",
                                                                        top: "1rem",
                                                                        right: "1rem"
                                                                    }}
                                                                />)
                                                            }
                                                            <CardContent>
                                                                <Typography variant="h3"
                                                                            className={"typo-card-h3"}>
                                                                    {project.team.name}
                                                                </Typography>
                                                                <Typography variant="h3"
                                                                            className={"typo-card-h4"}>
                                                                    {project.name}
                                                                </Typography>
                                                                <Typography marginTop={1}
                                                                            variant="body1"
                                                                            className={"typo-card-p"}>
                                                                    {project.team.profiles.length} {project.team.profiles.length > 1 ? "participants" : "participant"}
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions
                                                                className={`typo-card-bottom typo-card-bottom-disqualified`}>
                                                                <Typography variant={"body1"}
                                                                            className={"typo-card-p"}>
                                                                    Non retenue
                                                                </Typography>
                                                                <ActionButton
                                                                    variant={"little-underline"}
                                                                    onClick={() => {
                                                                        navigate(`/admin/challenge/${challengeId}/project/${project.id}`)
                                                                    }}>Voir le
                                                                    projet</ActionButton>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </div>
                                </div>
                                <div className="admin-infos-challenge-project-body-content-footer">
                                    <div
                                        className="admin-infos-challenge-project-body-content-footer-content">
                                        {
                                            allProjects.stepDetails && allProjects.stepDetails?.hasSelection && (moment().isBetween(allProjects.stepDetails.dateEndDeposite, allProjects.stepDetails.dateEndSelection)) && (
                                                <>
                                                    <p>Fin de la phase de sélection
                                                        : {moment(allProjects.stepDetails.dateEndSelection).from(moment())}</p>
                                                    {/*: {moment(allProjects.stepDetails.dateEndSelection).from(moment(allProjects.stepDetails.dateEndDeposite))}</p>*/}
                                                    <ActionButton
                                                        variant={"primary"}
                                                        onClick={() => navigate(`/admin/challenge/${challengeId}/projects/selections`)}
                                                    >
                                                        Commencer la sélection des projets
                                                    </ActionButton>
                                                </>
                                            )
                                        }
                                        {
                                            allProjects.stepDetails && allProjects.stepDetails?.hasSelection && (moment().isBetween(allProjects.stepDetails.dateStartDeposite, allProjects.stepDetails.dateEndDeposite)) && (
                                                <>
                                                    <p>Début de la phase de sélection
                                                        : {moment(allProjects.stepDetails.dateEndDeposite).from(moment())}</p>
                                                    <ActionButton
                                                        variant={"disabled"}
                                                        // onClick={() => navigate(`/admin/challenge/${challengeId}/projects/selections`)}
                                                    >
                                                        Commencer la sélection des projets
                                                    </ActionButton>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={"no-users"}>
                        <p>En attente de projets !</p>
                        <ImgNoUserInChallengeRight/>
                    </div>
                )
            }
        </div>
    )
}

export default AdminInfosChallengeProjects;
