//Context
import {RootState} from "../../../../../app/type";
import { useChallengeDataContext } from "..";

//Modules/Components
import SubChallenge from "../../../../../modules/SubscribeChallenge";
import MyProject from "../../../../../modules/MyProject";
import ActionButton from "../../../../../components/ActionButton";

//Types
import { IChallengeData } from "../../../../../modules/SubscribeChallenge/type";
import { IChallenge } from "../types";
import {IMyProject} from "./types";

//React
import React, {ContextType, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

//Style
import "./index.css";

//Other
import moment from "moment";
import {DEFAULT_CHALLENGE_PICTURE} from "../../../../../utils/consts";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";



const ChallengeMyProject = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const navigate = useNavigate();
    const challengeId = useParams().id;
    const [myProject, setMyProject] = useState<IMyProject>();

    //Récupération des fonctions et données depuis la page générale pour insérer un bouton "Démarrer projet"
    const { getRegisteredToChallengeById, getChallengeById, challenge, challengeData } = useChallengeDataContext();

    const firstName = useSelector((action: RootState) => action.user.firstname);
    const lastName = useSelector((action: RootState) => action.user.lastname);
    const getMyProject = async () => {
        await api.get(`/participant/myproject?challengeId=${challengeId}`,
            {
                withCredentials: true
            }
        ).then((res) => {
            setMyProject(res.data);
            console.log("le projet", res.data)
        }).catch((err) => {
            console.log("Error: ", err);
            setMyProject(undefined);
        });
    }

    let projectStatus;
    let projectStatusClass;
    switch (myProject?.stepProjectStatus) {
        case 'draft' :
            projectStatus = t("status.draft");
            projectStatusClass = "draft";
            break;
        case 'sent' :
            projectStatus = t("status.sent");
            projectStatusClass = "good";
            break;
        case 'eliminated' :
            projectStatus = t("status.eliminated");
            projectStatusClass = "bad";
            break;
    }

    useEffect(() => {
        getMyProject();
    }, []);
    useEffect(() => { //UseEffect qui met à jour les données du projet quand l'état d'inscription change (si on est désinscrit on dit que le projet est null)
        if(challengeData?.registered) {
            getMyProject();
        }else {
            setMyProject(undefined);
        }
    }, [challengeData])

    return (
        <div className={`general-challenge-my-project`}>
            <h3>{t("page.challenge.myProject.myProject")}</h3>
            <div className={"general-challenge-my-project-block-content"}>
                <div className={"general-challenge-my-project-content"}>
                    <MyProject
                        challengeId={challengeId}
                        projectId={myProject?.id}
                        challengeName={myProject?.challenge.name}
                        projectFileUrl={myProject?.file?.fileUrl}
                        projectName={myProject?.name}
                        doneSteps={myProject?.challenge.doneSteps}
                        totalSteps={myProject?.challenge.totalSteps}
                        projectDescription={myProject?.shortDesc}
                        projectStatusClass={projectStatusClass}
                        projectStatus = {projectStatus}
                    />
                </div>
                <div
                    className={`my-project-infos-details ${myProject?.stepProjectStatus === "eliminated" ? "my-project-error" : ""}`}>
                    <div className={"content"}>
                        <div className={`project-status`}>
                            <h4>{myProject ?
                                    !myProject?.team.single
                                        ? t("team") + ` ${myProject?.team.numero + 1} - ${myProject?.team.name}`
                                        : firstName + ' ' + lastName
                                    : challengeData?.registered
                                        ? t("page.challenge.myProject.registered")
                                        : t("page.challenge.myProject.notRegistered")
                                }</h4>
                        </div>
                        <div className={"participation-team"}>
                            { myProject ? myProject?.team.single
                                    ? <p>{t("participationType.single")}</p>
                                    : <p>{t("participationType.team")} :
                                    {t("from")} {myProject?.challenge.minMembersTeam} {t("to")} {myProject?.challenge.maxMembersTeam} {t("members")}</p>
                                : <p>{t("page.challenge.myProject.startYourProject")}</p>
                            }
                            {
                                (myProject && !myProject?.team.single && myProject?.team.membersCount < myProject?.challenge.minMembersTeam) &&
                                <p className="warning">{t("page.challenge.myProject.invalidTeam")}
                                    : {myProject.team.membersCount} {t("member")}{myProject.team.membersCount > 1 && "s"}</p>
                            }
                            {
                                (myProject && !myProject?.team.single && myProject?.team.membersCount > myProject?.challenge.minMembersTeam) &&
                                <p>{myProject.team.membersCount} {t("member")}{myProject.team.membersCount > 1 && "s"}</p>
                            }

                        </div>
                    </div>
                    <div className={"block-dateEndDeposit-button"}>
                        {myProject && myProject?.stepProjectStatus !== "eliminated" && ( //On affiche le bloc de date de dépôt si on a démarré un projet et qu'on est pas éliminé
                            <div className={"block-dateEndDeposit"}>
                                <p>{ 
                                        myProject?.challenge.steps &&  myProject?.challenge.steps.length > 0 //S'il y a au moins une étape, on affiche la date de dépôt
                                        ? t("page.challenge.myProject.limitDate") + ` : ${moment(String(myProject?.challenge.steps[0]?.dateEndDeposite)).format("DD/MM/YYYY")}`
                                        : t("page.challenge.myProject.waiting")
                                }</p>
                            </div>
                        )
                            
                        }
                        <div className={`block-button ${myProject?.stepProjectStatus == "eliminated" ? "w-100 bottom-left-radius" : ""}`}>
                            {
                                myProject && (
                                    <ActionButton
                                        variant={"secondary"}
                                        onClick={() => navigate(`/participant/challenge/${challengeId}/project/${myProject?.id}`)}
                                    >
                                        {myProject?.stepProjectStatus !== "eliminated" ? t("page.challenge.myProject.continueProject") : t("page.challenge.myProject.seeMyProject")}
                                    </ActionButton>
                                )
                            }
                        </div>
                        <SubChallenge
                            getRegisteredToChallengeById={getRegisteredToChallengeById}
                            getChallengeById={getChallengeById}
                            challengeData={challenge}
                            challengeInscriptionDetails={challengeData}
                            disableStatus
                            hideAllButtons={myProject !== undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChallengeMyProject;
