// Style
import './index.css';

// hooks
import {useSortable} from '@dnd-kit/sortable';
import {useState, useEffect} from 'react';

// icons
import {ReactComponent as IconClose} from '../../assets/icon-close.svg';
import {ReactComponent as IconDragNDrop} from '../../assets/icon-dragndrop.svg';

// components
import ImageUploader from '../../modules/ImageUploader/ImageUploader';

// utilities
import {CSS} from '@dnd-kit/utilities';
import React from 'react';
import IPrice from '../PrizeList/types';
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/index';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';



interface PrizeProps {
    prize: IPrice;
    handleDeletePrize: (prize: IPrice) => void;
    handleUpdatePrize: (prize: IPrice) => void;
    disabled: boolean;
}

const Prize = ({ prize, handleDeletePrize, handleUpdatePrize, disabled }: PrizeProps) => {

    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    const { imagePrices, setImagePrices} = useAdminEditChallengeContext()

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: prize.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const [imagePreview, setImagePreview] = useState<string>('');
    const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);
    const [hasClickedOnCancelButton, setHasClickedOnCancelButton] = useState<boolean>(false);

    useEffect(() => {
        console.log("fileToUpload",fileToUpload);
        //mettre à jour le tableau fileToUpload qui correspond à ça imagePrices:[number,File] setImagePrices: (imagePrices: [number,File]) => void,
        if(fileToUpload){
            if(prize && prize.id) {
                setImagePrices([...imagePrices, {id:prize.id, file: fileToUpload}]);
                // Supprimer l'erreur qui correspond avec son id
                const newErrorFieldGeneral = errorFieldGeneral.filter((errorField: { id: string | number; })=> String(errorField.id) !== String(prize.id));
                setErrorFieldGeneral(newErrorFieldGeneral);
            }
        }
    }, [fileToUpload]);
    console.log("disabled",disabled);

    return (
        <div ref={setNodeRef} {...attributes} style={style} {...listeners}>
            <FormHelperText sx={{marginBottom: '1vh'}}
                error={errorFieldGeneral.find((error:any) => error.code === 'prices')?true:false}
            >
                {errorFieldGeneral.find((error:any) => error.code === 'prices')?errorFieldGeneral.find((error:any) => error.code === 'prices').message:''}
            </FormHelperText>
            <TextField
            //Afficher l'erreur qui correspond au subChallenge id et qui a un code 'subChallenge'
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(prize.id) && (error.code === 'price')) || (error.code === 'prices'))?true:false}
                // helperText={errorFieldGeneral.find((error:any) => String(error.id) === String(prize.id) && error.code === 'price')?errorFieldGeneral.find((error:any) => String(error.id) === String(prize.id) && error.code === 'price').message:''}
                variant="outlined"
                fullWidth={true}
                defaultValue={prize.name}
                multiline
                inputProps={{ maxLength: 50 }}
                // select
                label="Nom du prix *"
                InputProps={{
                    endAdornment:
                    <InputAdornment position="end">
                        <IconClose
                            className='icon-close icon-clickable cursor-pointer'
                            // onClick={disabled?() => {} :() => removeDeliverableFromStep(String(stepId), String(deliverable.id))}
                            onClick={disabled?() => {} :() => handleDeletePrize(prize)}
                        />
                        <IconDragNDrop className={`icon-dragndrop icon-clickable ${!disabled&& 'draggable'} cursor-pointer`}/>
                        {/* <IconDragNDrop className={`icon-dragndrop icon-clickable draggable cursor-pointer`}/> */}
                    </InputAdornment>,
                }}
                InputLabelProps={{
                    style: {
                        color: '#a9a9a9',
                        fontWeight: '400'
                    },
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '0px',
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, name: e.target.value })}
                onChange={(e) => {
                    handleUpdatePrize({ ...prize, name: e.target.value })
                    setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => (String(error.id) !== String(prize.id))))
                }}
                // disabled={disabled}
            />
            <div style={{border:"solid 1px rgba(0, 0, 0, 0.23)", borderTop:"0px", padding:"2em"}}>
                <ImageUploader
                    disabled={disabled}
                    image={prize.prizePicture?prize.prizePicture:""}
                    defaultImage={"image-prize"}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    setFileToUpload={setFileToUpload}
                    hasClickedOnCancelButton={hasClickedOnCancelButton}
                    id={prize.id}
                    noImageText="Vous n'avez pas encore d'image pour votre prix"
                    noImageButtonText="Ajouter une image"
                    ratio='1/1'
                    width={"20rem"}
                    height={"auto"}
                    noDeletion={true}
                />
            </div>
            <TextField
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(prize.id) && (error.code === 'price')) || (error.code === 'prices'))?true:false}
                helperText={errorFieldGeneral.find((error:any) => String(error.id) === String(prize.id) && error.code === 'price')?errorFieldGeneral.find((error:any) => String(error.id) === String(prize.id) && error.code === 'price').message:''}
                variant="outlined"
                fullWidth={true}
                defaultValue={prize.description}
                placeholder="Description du prix"
                multiline
                maxRows={3}
                minRows={2}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                    startAdornment: <InputAdornment sx={{marginBottom:'10px'}} position="start">Description (max 255 caractères)</InputAdornment>,
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        display:'block',
                        background: "#fff",
                        // borderRadius: '10px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '10px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '10px',
                        '& fieldset' :{
                            borderTop: '0px',
                        },

                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, description: e.target.value })}
                onChange={(e) => {
                    handleUpdatePrize({ ...prize, description: e.target.value })
                    setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => (String(error.id) !== String(prize.id))))
                }}
                // disabled={disabled}
            />
        </div>
        // <div className="prize" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        //     <div className="prize-header">
        //         <div className="prize-title">
        //             <div>
        //                 {isEditingName ? (
        //                     <h2 className="h1 form-title category-title">
        //                         Nom du prix
        //                     </h2>
        //                 ):(
        //                     <textarea ref={nameTextArea} name="prizeName" id={"prize-name-" + prize.id} className='text input-editable' placeholder='Nom du prix' value={prize.name} onChange={(e) => {
        //                         setCurrentPrize({
        //                             ...currentPrize,
        //                             name: e.target.value
        //                         });
        //                         handleUpdatePrize({
        //                             ...currentPrize,
        //                             name: e.target.value
        //                         });
        //                     }} onClick={handleEditingName}/>
        //                 )}
        //                 <span className='icons'>
        //                     <IconClose className="icon-close icon-clickable" onClick={() => handleDeletePrize(prize)}/>
        //                     <IconDragNDrop className="icon-dragndrop icon-clickable draggable" />
        //                 </span>
        //             </div>
        //             {isEditingName && (
        //                 <textarea ref={nameTextArea} name="prizeName" id={"prize-name-" + prize.id} className='text input-editable' placeholder='Nom du prix' value={prize.name} onChange={(e) => {
        //                     setCurrentPrize({
        //                         ...currentPrize,
        //                         name: e.target.value
        //                     });
        //                     handleUpdatePrize({
        //                         ...currentPrize,
        //                         name: e.target.value
        //                     });
        //                 }}/>
        //             )}
        //         </div>
        //     </div>
        //     <div className="prize-image">
        //         <h2 className="h1 form-title category-title">Image du prix</h2>
        //         <ImageUploader
        //             image={prize.image}
        //             defaultImage={"image-prize"}
        //             imagePreview={imagePreview}
        //             setImagePreview={setImagePreview}
        //             setFileToUpload={setFileToUpload}
        //             id={prize.id + (mobile ? "-mobile" : "")}
        //             imgRef={prize.ref}
        //             noImageText="Vous n'avez pas encore d'image pour votre prix"
        //             noImageButtonText="Ajouter une image"
        //             noDeletion
        //             ratio='1/1'
        //         />
        //     </div>
        //     <div className="prize-description">
        //         {isEditingDescription ? (
        //             <h2 className="h1 form-title category-title">Description du prix <span className="thin">(max 250 caractères)</span></h2>
        //         ) : (
        //             <textarea ref={descriptionTextArea} name="prizeDescription" id={"prize-description-" + prize.id} className='text input-editable' placeholder='Description du prix (facultatif)' maxLength={250} value={prize.description} onChange={(e) => {
        //                 setCurrentPrize({
        //                     ...currentPrize,
        //                     description: e.target.value
        //                 });
        //                 handleUpdatePrize({
        //                     ...currentPrize,
        //                     description: e.target.value
        //                 });
        //             }} onClick={handleEditingDescription}/>
        //         )}
        //         {isEditingDescription && (
        //             <textarea ref={descriptionTextArea} name="prizeDescription" id={"prize-description-" + prize.id} className='text input-editable' placeholder='Description du prix (facultatif)' maxLength={250} value={prize.description} onChange={(e) => {
        //                 setCurrentPrize({
        //                     ...currentPrize,
        //                     description: e.target.value
        //                 });
        //                 handleUpdatePrize({
        //                     ...currentPrize,
        //                     description: e.target.value
        //                 });
        //             }}/>
        //         )}
        //     </div>
        // </div>
    )
}

export default Prize;