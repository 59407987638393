/**
 * @deprecated
 * URL de l'API (avec port et endpoint)
 * 
 * @example
 * axios.get(API_FULL_URL + "/superadmin/list_establishments")
 */
const API_FULL_URL = process.env.REACT_APP_API_URL + ":" + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT;

/**
 * URL de l'image par défaut d'un établissement
 * 
 * @example
 * <img src={DEFAULT_ESTABLISHMENT_IMAGE} alt="Image par défaut" />
 */
const DEFAULT_ESTABLISHMENT_IMAGE = `/images/photo-profil.jpg`;
const DEFAULT_CHALLENGE_PICTURE = `/images/couverture-challenge.jpg`;
const DEFAULT_CHALLENGE_LOGO = ``;

const DEFAULT_MAIN_PROJECT = `/images/projet.jpg`;
const DEFAULT_PROFILE_PICTURE = `/images/photo-profil.jpg`;
const DEFAULT_PRIZE_PICTURE = `/images/photo-profil.jpg`;
const DEFAULT_UPLOAD_PICTURE = "https://storage.googleapis.com/makee_bucket/e91fa2d9-8eb0-4361-ad84-3898d69ad422.jpg";

/**
 * Mois de l'année selon leur nom
 * 
 * @example
 * MONTHS.JANUARY // "Janvier"
 */
const MONTHS = {
    JANUARY: "Janvier",
    FEBRUARY: "Février",
    MARCH: "Mars",
    APRIL: "Avril",
    MAY: "Mai",
    JUNE: "Juin",
    JULY: "Juillet",
    AUGUST: "Août",
    SEPTEMBER: "Septembre",
    OCTOBER: "Octobre",
    NOVEMBER: "Novembre",
    DECEMBER: "Décembre"
}


/**
 * Mois de l'année selon leur numéro
 * 
 * @example
 * YEAR_MONTHS["01"] // "Janvier"
 */
const YEAR_MONTHS = {
    "01": MONTHS.JANUARY,
    "02": MONTHS.FEBRUARY,
    "03": MONTHS.MARCH,
    "04": MONTHS.APRIL,
    "05": MONTHS.MAY,
    "06": MONTHS.JUNE,
    "07": MONTHS.JULY,
    "08": MONTHS.AUGUST,
    "09": MONTHS.SEPTEMBER,
    "10": MONTHS.OCTOBER,
    "11": MONTHS.NOVEMBER,
    "12": MONTHS.DECEMBER
}

export default API_FULL_URL;

export { DEFAULT_PRIZE_PICTURE, DEFAULT_CHALLENGE_LOGO, DEFAULT_ESTABLISHMENT_IMAGE,DEFAULT_CHALLENGE_PICTURE,DEFAULT_MAIN_PROJECT, DEFAULT_PROFILE_PICTURE, DEFAULT_UPLOAD_PICTURE, YEAR_MONTHS };