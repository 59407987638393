// cette page est la page où seront renseignées les information préremplies de l'utilisateur par l'école
// ces informations pourront être modifiées par l'utilsiateur avant la validation de son inscription

// material ui
import { Alert, AlertTitle, Box, FormControl, TextField } from "@mui/material";

// react router dom
import { useNavigate, useParams } from "react-router-dom";

// components
import ActionButton from "../../../../../../components/ActionButton";

// context
import { useAuth } from "../../../../../../context/AuthContext";
import { useEffect, useState } from "react";

// style
import './index.css';

// react
import DiamondColor from '../../../../../../assets/diamond-color';

// assets
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../app/type';

const AdminInvNameEmailSchool = () => {

    // initialisation de la navigation
    const navigate = useNavigate();

    // affichage de l'alerte
    const [showAlert, setShowAlert] = useState(false);

    // récupérer le profil
    const { updateInfos } = useAuth();

    // redux
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [establishment, setEstablishment] = useState('');

    const firstNameDefaultValue = useSelector((state: RootState) => state.user.firstname);
    const lastNameDefaultValue = useSelector((state: RootState) => state.user.lastname);
    const emailDefaultValue = useSelector((state: RootState) => state.user.email_pro);
    const establishmentDefaultValue = useSelector((state: RootState) => state.user.establishment);

    useEffect(() => {
        setFirstName(firstNameDefaultValue);
        setLastName(lastNameDefaultValue);
        setEmail(emailDefaultValue);
        setEstablishment(establishmentDefaultValue);
    }, [firstNameDefaultValue, lastNameDefaultValue, emailDefaultValue, establishmentDefaultValue]);


    return (
        <>
            <section className="admin-name-email-establishment">
                <div className="container">
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login' />

                    <h1 className='h1-auth'>Créez votre profil administrateur</h1>

                    <p className='form-text-auth paragraphs'>Vous êtes en train de créer un profil administrateur associé à l’établissement : <br />
                        {/* établissement en lien avec l’adresse e-mail professionnelle renseignée. */}
                        <span className='small-lead-text'>{establishmentDefaultValue}</span>
                    </p>

                    <p className="form-text-auth fields paragraphs">* Champs obligatoires</p>

                    {/* formulaire d'inscription réalisé à partir de matérial ui */}

                    <FormControl
                        onSubmit={(e) => {
                            setShowAlert(true);
                            updateInfos(e)
                        }}
                    >
                        <Box
                            component="form"

                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="lastname"
                                label="Nom"
                                variant="outlined"
                                placeholder="Nom"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />

                            <TextField
                                id="firstname"
                                label="Prénom"
                                variant="outlined"
                                placeholder="Nom"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />

                            <TextField
                                id="email_pro"
                                label="Adresse e-mail de profil"
                                variant="outlined"
                                placeholder="example@mail.com"
                                type="email"
                                disabled
                                value={email}
                            />

                            <TextField
                                id="school"
                                label="Établissement"
                                variant="outlined"
                                placeholder="Rennes School of Business"
                                type="text"
                                disabled
                                value={establishment}
                            />

                            <ActionButton
                                auth
                                variant="primary"
                                type="submit"
                            >
                                Valider
                            </ActionButton>
                        </Box>
                    </FormControl>
                </div>
                {/* image svg à droite du formulaire */}
                <DiamondColor className='diamond' />
            </section>
        </>
    )
}

export default AdminInvNameEmailSchool
