// cette page est la page de création de compte pour un utilisateur qui n'a pas de compte Makee
// il va pouvoir y renseigner :
// - son mot de passe
// - son numéro de téléphone
// - son adresse e-mail de récupération

// Components
import ActionButton from '../../../../../components/ActionButton'

// react-dom
import {Link, useNavigate} from "react-router-dom"

// material-ui
import {
    TextField,
    Step,
    StepConnector,
    Stepper,
    stepConnectorClasses,
    styled,
    FormControl,
    Box,
    Checkbox,
    FormControlLabel, useMediaQuery, useTheme
} from '@mui/material';

//react
import React, {FormEvent, useEffect, useState} from "react";

// style
import './index.css';

// context
import {useAuth} from '../../../../../context/AuthContext';

// redux
import {useSelector} from "react-redux";
import {RootState} from '../../../../../app/type';

// assets
import MakeeDiamondLogin from '../../../../../assets/makee-diamond-login';
import DiamondColor from '../../../../../assets/diamond-color';
import {isEmail} from "../../../../../utils/functions";

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import SupportFooter from "../../../../../modules/SupportFooter";
import SwitchTranslate from "../../../../../components/SwitchTranslate";

const SignupParticipantNameEmail = () => {
    // Style des steps Material UI (voir la doc)
    const QontoConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 16,
            right: 16,
            margin: 'auto',
            borderTopWidth: 10,
            borderRadius: 2,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#EDF1F7',
                borderTopWidth: 10,
                borderRadius: 2,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#F86767',
                borderTopWidth: 10,
                borderRadius: 2,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EDF1F7',
            borderTopWidth: 10,
            borderRadius: 1,
        },

    }));

    // on défini le nombre de steps
    const steps = ['', ''];

    // on change l'étape active en fonction du paramètre d'URL
    const activeStep = 1;

    const navigate = useNavigate();

    // context
    const {createParticipantLibre, registrationError, setRegistrationError} = useAuth();

    // redux state
    const error = useSelector((action: RootState) => action.error.error);
    const errorMessage = useSelector((action: RootState) => action.error.message);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const handleOnBlur = () => {
        if (!isEmail(email)) {
            setEmailError(true);
            setEmailErrorMessage(`${t("page.signUpParticipant.error.email")}`);
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }
    }

    useEffect(() => {
        console.log(registrationError);
    }, [registrationError]);
    const handleOnChange = (email: string) => {
        setEmail(email);
        if (isEmail(email)) {
            setEmailError(false);
            setEmailErrorMessage('');
            setRegistrationError(null);
        }
    }

    const handleOnSubmit = (e: FormEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (!isEmail(email)) {
            setEmailError(true);
            setEmailErrorMessage(`${t("page.signUpParticipant.error.email")}`);
        } else {
            createParticipantLibre(e);
        }
    }
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    return (
        <>
            <div className="login-email-psw-svg">
                <div className='login-email-psw mt-10'>
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login'/>
                    {
                        isMobile && (
                            <SwitchTranslate className={"translate-icon"}/>
                        )
                    }
                    {/* texte au dessus du formulaire */}
                    <h1 className='h1-auth'>{t("page.signUpParticipant.title")}</h1>
                    <p className='form-text-auth account'>{t("page.signUpParticipant.noAccount.text1")}
                        <Link to="/login/1"
                              className='login-email-psw-link'>{t("page.signUpParticipant.noAccount.text2")}</Link></p>
                    <div className="instruction-password">
                        <p>{t("form.requiredFields")}</p>
                    </div>
                    {/* Formulaire de connexion avec email et mot de passe qui appelle la fonction loginUser() au submit
                    Utilisation de composants de Material-UI pour la création du formulaire */}


                    <FormControl
                        onSubmit={(e) => {
                            handleOnSubmit(e)
                        }}
                    >
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <div className={"container-form-signup-participant"}>
                                {/* nom */}
                                <TextField
                                    id="lastname"
                                    label={t("page.signUpParticipant.form.inputTextLastname")}
                                    variant="outlined"
                                    placeholder={t("page.signUpParticipant.form.inputTextLastname")!}
                                    type='text'
                                    required
                                    error={registrationError && registrationError.error === "emptyLastname"}
                                    helperText={registrationError && registrationError.error === "emptyLastname" ? registrationError.message : ''}
                                />
                                {/* prénom */}
                                <TextField
                                    {...(error && {error: true})}
                                    id="firstname"
                                    label={t("page.signUpParticipant.form.inputTextFirstname")}
                                    variant="outlined"
                                    placeholder={t("page.signUpParticipant.form.inputTextFirstname")!}
                                    type='text'
                                    required
                                    error={registrationError && registrationError.error === "emptyFirstname"}
                                    helperText={registrationError && registrationError.error === "emptyFirstname" ? registrationError.message : ''}
                                />

                                {/* email de récupération */}
                                <TextField
                                    id="email"
                                    label={t("page.signUpParticipant.form.inputTextEmail")}
                                    variant="outlined"
                                    placeholder='contact@mail.com'
                                    type='email'
                                    onBlur={() => handleOnBlur()}
                                    onChange={(e) => handleOnChange(e.target.value)}
                                    required
                                    error={(emailError) || (registrationError && (registrationError.error === "emailAlreadyExists" || registrationError.error === "emptyEmail"))}
                                    helperText={(emailError ? emailErrorMessage : '') || (registrationError && (registrationError.error === "emailAlreadyExists") ? registrationError.message : '')}
                                />
                                {/* boutons de validation et d'annulation */}
                                <ActionButton
                                    auth
                                    variant="primary"
                                    type="submit"
                                >
                                    {t("signup")}
                                </ActionButton>
                            </div>
                        </Box>
                    </FormControl>
                </div>

                {/* image svg à droite du formulaire */}
                <DiamondColor className='diamond'/>
            </div>
            <SupportFooter/>
        </>
    )
}

export default SignupParticipantNameEmail;
