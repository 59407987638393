//Style
import './index.css';

//Components
import ActionButton from '../../../../components/ActionButton';

//Modules
import ChallengeCard from '../../../../modules/ChallengeCard';

//MUI
import { Grid, Pagination, useMediaQuery, useTheme } from '@mui/material';

//React
import { useEffect, useState } from 'react';

//Router
import { useNavigate } from 'react-router-dom';

//Types
import { IPublicHomeChallenge } from '../../../../app/type';

//Assets
import homeCompaniesImg from '../../../../assets/imgs/home-companiesImg.jpg';
import homeSchoolsImg from '../../../../assets/imgs/home-schoolsImg.jpg';
import { ReactComponent as ExternarlIcon } from '../../../../assets/icon-external.svg';
import { ReactComponent as FlecheHaut } from '../../../../assets/imgs/fleche_haut.svg';
import { ReactComponent as FlecheBas } from '../../../../assets/imgs/fleche_bas.svg';

//Others
import moment from 'moment';
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../components/SetUpTranslation";
import api from "../../../../utils/api";

const AlphaMakee = () => {
    const pagesLimit = 10; //Nbr de challenges par page
    const [challengesNb, setChallengesNb] = useState(0);
    const [challenges, setChallenges] = useState<IPublicHomeChallenge[]>([]);
    const [challengeModel, setChallengeModel] = useState<any[]>();

    const getChallenges = async (page: number) => {
        await api.get("/challenges/home", {
            params: {
                page: page,
                limit: pagesLimit
            }
        }).then((res) => {
            console.log(res.data)
            setChallengeModel(res.data.modelChallenge);
            setChallenges(res.data.challenges.rows);
            setChallengesNb(res.data.challenges.count);
        })
    }

    const handlePaginationPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
        getChallenges(page - 1);
    }

    ///!\ TEMPORAIRE /!\
    // const shouldRedirect = true;
    // if(shouldRedirect) {
    //     window.location.replace("https://get.makee.io");
    //     return <></>
    // }

    useEffect(() => {
        getChallenges(0);
    }, [])

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const handleNavigateToSection = (id: any, offset = 100) => {
        navigate('/');
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }, 0);
    };
    const filteredChallenges = challenges.filter(challenge => challenge.name === "Challenge modèle");
    const allChallenges = challenges.filter(challenge => challenge.name !== "Challenge modèle");

    return (
        <section className='home-no-logged'>
            <section className='presentation'>
                <div className='title'>
                    <h1><span>Makee</span>, {t("page.alphaMakee.title")} {!isMdDown && (<br/>)}{t("page.alphaMakee.title2")} </h1>
                    <p>{t("page.alphaMakee.subTitle")}</p>
                </div>
                <div className='icon-fleche'>
                    {
                        isMdDown ? <FlecheBas /> : <FlecheHaut />
                    }
                </div>
                <div className='block-buttons'>
                    <div className='buttons'>
                        <ActionButton
                            variant='primary'
                            onClick={() => window.open('https://get.makee.io/', '_blank')}
                        >
                            {t("page.alphaMakee.startMyChallenge")}
                        </ActionButton>
                        <ActionButton
                            variant='tertiary'
                            onClick={() => window.open('https://get.makee.io/contactez-nous/', '_blank')}
                        >
                            {t("page.alphaMakee.contactUs")}
                        </ActionButton>
                    </div>
                </div>
            </section>
            <section className='hero-banner'>
                <img src='./images/home_banner.png' />
                <div className='block-button'>
                    <ActionButton
                        variant='secondary'
                        onClick={() => handleNavigateToSection("list-challenges")}
                        className='button'
                    >
                        {t("page.alphaMakee.discoverChallenges")}
                    </ActionButton>
                </div>
            </section>
            <section className='explanation'>
                <h2 className='title'>{t("page.alphaMakee.cards.title")}</h2>
                <div className='cards'>
                    <div className='card'>
                        <div className='text'>
                            <p className='number'>1.</p>
                            <p className='description'>{t("page.alphaMakee.cards.card1.text")} <span>{t("page.alphaMakee.cards.card1.span")}</span></p>
                        </div>
                        {
                            !isMdDown && (
                                <img src='/images/illustration_1.svg' />
                            )
                        }
                    </div>
                    <div className='card'>
                        <div className='text'>
                            <p className='number'>2.</p>
                            <p className='description'>{t("page.alphaMakee.cards.card2.text1")} <span>{t("page.alphaMakee.cards.card2.span")}</span> {t("page.alphaMakee.cards.card2.text2")}</p>
                        </div>
                        {
                            !isMdDown && (
                                <img src='/images/illustration_2.svg' />
                            )
                        }
                    </div>
                    <div className='card'>
                        <div className='text'>
                            <p className='number'>3.</p>
                            <p className='description'>{t("page.alphaMakee.cards.card3.text1")} <span>{t("page.alphaMakee.cards.card3.span")}</span> {t("page.alphaMakee.cards.card3.text2")}</p>
                        </div>
                        {
                            !isMdDown && (
                                <img src='/images/illustration_3.svg' />
                            )
                        }
                    </div>
                    <div className='card'>
                        <div className='text'>
                            <p className='number'>4.</p>
                            <p className='description'><span>{t("page.alphaMakee.cards.card4.span")}</span> {t("page.alphaMakee.cards.card4.text")}</p>
                        </div>
                        {
                            !isMdDown && (
                                <img src='/images/illustration_4.svg' />
                            )
                        }
                    </div>
                </div>
            </section>
            <section className='block-list-challenges'>
                <div className='challenge-model'>
                    <h1 className='title-alpha-makee-home'>{t("page.alphaMakee.challengeModel")}</h1>
                    {
                        challengeModel?.map((challenge: any) => {
                            //Traduction status challenge
                            let finalState = '';
                            let done = false;
                            if (challenge.status.name === 'published') {
                                finalState = t("status.published")
                            } else if (challenge.status.name === 'registrations') {
                                finalState = t("status.registrations");
                            } else if (challenge.status.name === 'submission') {
                                finalState = t("status.submission");
                            } else if (challenge.status.name === 'deliberations') {
                                finalState = t("status.deliberations");
                            } else if (challenge.status.name === 'finished') {
                                finalState = t("status.finished");
                                done = true;
                            }

                            // participation type
                            let participation_type = '';
                            if (challenge.participationType === 'team') {
                                participation_type = t("participationType.team")
                            }
                            if (challenge.participationType === 'single') {
                                participation_type = t("participationType.single")
                            }
                            if (challenge.participationType === 'both') {
                                participation_type = t("participationType.both")
                            }

                            // dates de début et de fin du challenge
                            const startDate = new Date(challenge.startDate).toLocaleDateString(i18n.language, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            const startEnd = new Date(challenge.endDate).toLocaleDateString(i18n.language, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });

                            return <ChallengeCard
                                key={challenge.id}
                                title={challenge.name}
                                description={challenge.shortDescription || ''}
                                img={challenge.challengePicture || ''}
                                date={`${t("challengeCard.from")} ${startDate} ${t("challengeCard.to")} ${startEnd}`}
                                state={finalState}
                                registration_date={challenge.registrationStartDate && challenge.registrationEndDate ? `Du ${moment(challenge.registrationStartDate).format("LL")} au ${moment(challenge.registrationEndDate).format("LL")}` : undefined}
                                // link_to_challenge={`${userType === 'admin' ? `/${userType}/home/challenge/general/${challengeName}` : `/${userType}/challenge/${challenge.id}/general`}`}
                                link_to_challenge={`/challenge/${challenge.id}/general`}
                                desktop={!isMdDown}
                                participation_type={participation_type}
                                long_description={challenge.longDescription ? challenge.longDescription : undefined}
                                min_members={challenge.minMembersTeam}
                                max_members={challenge.maxMembersTeam}
                                organizer={challenge.establishments[0]?.name ? challenge.establishments[0]?.name : ''}
                                done={done}
                            />
                        })
                    }
                </div>
                <div id='list-challenges' className='list-challenges'>
                    <h1 className='title-alpha-makee-home'>{t("page.alphaMakee.allChallenges.title")}</h1>
                    {
                        allChallenges.map((challenge) => {
                            //Traduction status challenge
                            let finalState = '';
                            let done = false;
                            if (challenge.status.name === 'published') {
                                finalState = t("status.published")
                            } else if (challenge.status.name === 'registrations') {
                                finalState = t("status.registrations");
                            } else if (challenge.status.name === 'submission') {
                                finalState = t("status.submission");
                            } else if (challenge.status.name === 'deliberations') {
                                finalState = t("status.deliberations");
                            } else if (challenge.status.name === 'finished') {
                                finalState = t("status.finished");
                                done = true;
                            }

                            // participation type
                            let participation_type = '';
                            if (challenge.participationType === 'team') {
                                participation_type = t("participationType.team")
                            }
                            if (challenge.participationType === 'single') {
                                participation_type = t("participationType.single")
                            }
                            if (challenge.participationType === 'both') {
                                participation_type = t("participationType.both")
                            }

                            // dates de début et de fin du challenge
                            const startDate = new Date(challenge.startDate).toLocaleDateString(i18n.language, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            const startEnd = new Date(challenge.endDate).toLocaleDateString(i18n.language, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });

                            return <ChallengeCard
                                key={challenge.id}
                                title={challenge.name}
                                description={challenge.shortDescription || ''}
                                img={challenge.challengePicture || ''}
                                date={`${t("challengeCard.from")} ${startDate} ${t("challengeCard.to")} ${startEnd}`}
                                state={finalState}
                                registration_date={challenge.registrationStartDate && challenge.registrationEndDate ? `Du ${moment(challenge.registrationStartDate).format("LL")} au ${moment(challenge.registrationEndDate).format("LL")}` : undefined}
                                // link_to_challenge={`${userType === 'admin' ? `/${userType}/home/challenge/general/${challengeName}` : `/${userType}/challenge/${challenge.id}/general`}`}
                                link_to_challenge={`/challenge/${challenge.id}/general`}
                                desktop={!isMdDown}
                                participation_type={participation_type}
                                long_description={challenge.longDescription ? challenge.longDescription : undefined}
                                min_members={challenge.minMembersTeam}
                                max_members={challenge.maxMembersTeam}
                                organizer={challenge.establishments[0]?.name ? challenge.establishments[0]?.name : ''}
                                done={done}
                            />
                        })
                    }
                    <Pagination
                        className='pagination'
                        defaultPage={1} count={Math.ceil(challengesNb / pagesLimit)} shape={'rounded'} onChange={(e, page) => handlePaginationPageChange(e, page)} />
                </div>
            </section>
        </section>
    )
    {
        /*
        <Grid container spacing={3} display={"flex"} alignItems={"stretch"}>
            <Grid item md={6} >
                <div className='discoverMakee-presentation-companies'>
                    <div className='discoverMakee-presentation-companies-textPresentation'>
                        <h2 className='discoverMakee-presentation-companies-textPresentation-title'>{t("page.alphaMakee.title2")}</h2>
                        <ul className='discoverMakee-presentation-companies-textPresentation-textList'>
                            <li><b>{t("page.alphaMakee.create")}</b> {t("page.alphaMakee.createText")}</li>
                            <li><b>{t("page.alphaMakee.manage")}</b> {t("page.alphaMakee.manageText1")}</li>
                            <li><b>{t("page.alphaMakee.manage")}</b> {t("page.alphaMakee.manageText2")}</li>
                            <li><b>{t("page.alphaMakee.benefit")}</b> {t("page.alphaMakee.benefitText")}</li>
                        </ul>
                        <ActionButton icon={<ExternarlIcon />} variant='primary' className='discoverMakee-presentation-companies-textPresentation-btn' width='60%' onClick={() => window.open("https://get.makee.io/decouvrir-makee/?role=organization", "_blank", "noopener")}>{t("page.alphaMakee.discoverOffer")}</ActionButton>
                    </div>
                    <div className='discoverMakee-presentation-companies-decorationImg'>
                        <img src={homeCompaniesImg} alt="Accélérez l'employabilité de vos étudiants"/>
                    </div>
                </div>
            </Grid>
            <Grid item md={6}>
                <div className='discoverMakee-presentation-schools'>
                    <div className='discoverMakee-presentation-schools-textPresentation'>
                        <h2 className='discoverMakee-presentation-companies-textPresentation-title'>{t("page.alphaMakee.forSchools")}</h2>
                        <ul className='discoverMakee-presentation-schools-textPresentation-textList'>
                            <li><b>{t("page.alphaMakee.offer.text1")}</b> {t("page.alphaMakee.offer.text2")}</li>
                            <li><b>{t("page.alphaMakee.boost.text1")}</b> {t("page.alphaMakee.boost.text2")}</li>
                            <li><b>{t("page.alphaMakee.boost2.text1")}</b> {t("page.alphaMakee.boost2.text2")}</li>
                            <li><b>{t("page.alphaMakee.create")}</b> {t("page.alphaMakee.createText2")}</li>
                        </ul>
                        <ActionButton icon={<ExternarlIcon />} variant='primary' width='60%' className='discoverMakee-presentation-schools-textPresentation-btn' onClick={() => window.open("https://get.makee.io/decouvrir-makee/?role=school", "_blank", "noopener")}>{t("page.alphaMakee.discoverOffer")}</ActionButton>
                    </div>
                    <div className='discoverMakee-presentation-schools-decorationImg'>
                        <img src={homeSchoolsImg} alt="Accélérez l'employabilité de vos étudiants"/>
                    </div>
                </div>
            </Grid>
        </Grid>
<section className='discoverMakee-news'>
    <h1>{t("page.alphaMakee.news.title")}</h1>
    <div onClick={() => window.open("https://get.makee.io/participez-au-challenge-etudiants/", "_blank", "noopener")} className='discoverMakee-news-discoverChallengeBanner'>
        <img src='/images/presentation-challenge-discover.jpg' alt='Bannière de présentation du challenge discover'/>
    </div>
    <ActionButton variant='primary' onClick={() => window.open("https://get.makee.io/participez-au-challenge-etudiants/", "_blank", "noopener")}>
        {t("page.alphaMakee.news.readMore")}
    </ActionButton>
</section>
        */
    }

    // const redirectToAlphaMakee = () => {
    // window.location.href = 'https://get.makee.io';
    // return null;
    // }

    // (
    //     <>
    //         <div className="redirect-alpha-block">
    //             <div className="redirect-alpha-block-text">
    //                 {/* image svg en haut du formulaire (mobile) */}
    //                 <DiamondLogin className='makee-diamond-login' />
    //
    //
    //                 <h1 className="h1-auth">Bienvenue sur <span className="text-logo">Makee !</span></h1>
    //                 <p className="form-text">Accédez à <a href="https://get.makee.io">get.makee.io</a> pour découvrir les challenges étudiants
    //                     disponibles <br/>et déposer vos propres challenges ou cas d'entreprises.</p>
    //
    //                 <ActionButton
    //                     onClick={redirectToAlphaMakee}
    //                     variant='primary'
    //                 >
    //                     Poursuivre sur Makee
    //                 </ActionButton>
    //             </div>
    //
    //             {/* image svg à droite du formulaire */}
    //             <Diamond className='diamond-login'/>
    //         </div>
    //     </>
    // )
}

export default AlphaMakee
