interface IDiamonColor {
    className?: string;
    onClick?: () => void;
}

const DiamonColor: React.FC<IDiamonColor> = ({
    className,
    onClick
                                             }) => {
    return (
        <svg onClick={onClick} width="574" height="769" viewBox="0 0 574 769" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${className && className}`}>
            <path
                d="M579.7 143.192C548.36 180.507 517.1 217.637 485.707 254.82C475.639 266.801 466.573 281.291 451.861 287.777C439.87 293.024 427.563 297.478 415.546 302.276L269.359 360.639C258.927 364.804 247.727 371.266 236.4 370.888C219.739 370.191 202.895 367.117 186.394 365.284C144.229 360.53 101.932 355.829 59.6358 351.127C53.0616 350.384 51.613 344.838 52.6198 339.69C52.911 338.502 53.1495 337.182 53.8369 335.835C54.1542 335.096 54.5243 334.489 54.8416 333.75C55.1324 333.328 55.3704 332.773 55.7931 332.298C56.2425 331.507 56.7447 330.847 57.2468 330.187L57.8809 329.475C57.8809 329.475 57.8809 329.475 58.0923 329.237C88.8244 293.083 119.451 256.665 149.945 220.3C160.648 207.607 170.56 191.401 186.116 184.731C198.107 179.485 210.415 175.03 222.564 170.18L368.618 111.87C379.183 107.652 390.33 101.058 401.895 101.647C420.404 102.372 439.069 105.79 457.34 107.835C497.735 112.377 537.999 116.971 578.21 121.434C591.121 122.71 584.298 137.835 579.7 143.192Z"
                fill="#122236"/>
            <path d="M486.816 662.551L67.8359 375.102L234.99 393.8L486.816 662.551Z"
                  fill="url(#paint0_linear_1418_50676)"/>
            <path
                d="M463.361 312.734L489.219 661.288L464.735 631.409L461.095 626.891C422.681 578.995 353.451 495.327 302.452 433.93C295.039 424.947 288.1 416.388 281.477 408.621L267.125 391.079L463.361 312.734Z"
                fill="url(#paint1_linear_1418_50676)"/>
            <path d="M596.855 163.898L491.281 659.544L488.618 292.39L596.855 163.898Z"
                  fill="url(#paint2_linear_1418_50676)"/>
            <path
                d="M278.305 295.211C299.748 286.651 310.19 262.329 301.63 240.887C293.069 219.444 268.747 209.002 247.305 217.562C225.863 226.123 215.42 250.445 223.981 271.887C232.541 293.329 256.863 303.772 278.305 295.211Z"
                fill="white"/>
            <path
                d="M254.786 290.378C268.206 285.02 274.741 269.798 269.384 256.379C264.026 242.959 248.804 236.424 235.384 241.781C221.965 247.139 215.429 262.361 220.787 275.78C226.144 289.2 241.366 295.735 254.786 290.378Z"
                fill="#122236"/>
            <path
                d="M389.233 250.925C410.675 242.364 421.118 218.042 412.558 196.6C403.997 175.158 379.675 164.715 358.233 173.276C336.791 181.836 326.348 206.158 334.908 227.6C343.469 249.043 367.791 259.485 389.233 250.925Z"
                fill="white"/>
            <path
                d="M365.436 246.201C378.856 240.844 385.391 225.622 380.034 212.202C374.676 198.783 359.454 192.247 346.035 197.605C332.615 202.962 326.08 218.184 331.437 231.604C336.795 245.024 352.017 251.559 365.436 246.201Z"
                fill="#122236"/>
            <path
                d="M133.13 211.549C121.165 177.744 155.44 135.124 190.072 149.774C176.286 154.36 162.393 160.978 153.54 172.933C144.001 183.938 139.186 197.955 134.503 211.92C134.186 212.659 133.13 212.315 133.13 211.549Z"
                fill="#122236"/>
            <path
                d="M352.714 83.925C370.005 50.5349 425.079 58.8621 436.464 91.9806C423.265 85.7669 410.016 77.8897 395.177 76.771C380.815 74.5436 366.131 78.4153 353.321 85.0608C352.529 85.3771 352.107 84.3207 352.662 83.7929L352.714 83.925Z"
                fill="#122236"/>
            <defs>
                <linearGradient id="paint0_linear_1418_50676" x1="592.123" y1="692.008" x2="-18.2761" y2="266.021"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B00362"/>
                    <stop offset="0.47" stopColor="#F86767"/>
                    <stop offset="1" stopColor="#F8BD0E"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1418_50676" x1="648.446" y1="607.211" x2="41.4253" y2="183.551"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B00362"/>
                    <stop offset="0.47" stopColor="#F86767"/>
                    <stop offset="1" stopColor="#F8BD0E"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1418_50676" x1="707.964" y1="526.186" x2="97.5115" y2="100.067"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B00362"/>
                    <stop offset="0.47" stopColor="#F86767"/>
                    <stop offset="1" stopColor="#F8BD0E"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default DiamonColor;