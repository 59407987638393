// style
import { CircularProgress } from '@mui/material';
import './index.css';
import { useEffect, useState } from 'react';

const Save = (props: any) => {
    const [show, setShow] = useState(true);
    const {savingInProgress, pleaseWait} = props;
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={show ? 'div-save' : 'div-save-hidden'}>
            {show && (
                <div className="div-save-container">
                    <h1 className="h1">{savingInProgress}</h1>
                    <div className="circular-progress">
                        <CircularProgress color="inherit" className="circular-progress-gradient" />
                    </div>
                    <p className="text">{pleaseWait}</p>
                </div>
            )}
        </div>
    );
};

export default Save;