import "./index.css";
import React from "react";
import {ICardMyParticipation} from "./types";
import ActionButton from "../../components/ActionButton";

const CardMyParticipation: React.FC<ICardMyParticipation> = (
    {
        children,
        buttonVariant,
        buttonText,
        iconTeam,
        onClick,
    }
) => {
    return (
        <div className={"card-my-participation"}>
            <div className={"content"}>
                {children}
                <div className={"icon-team"}>{iconTeam}</div>
            </div>
            <div className={"buttons"}>
                <ActionButton variant={buttonVariant} onClick={onClick}>
                    {buttonText}
                </ActionButton>
            </div>
        </div>
    );
}
export default CardMyParticipation;