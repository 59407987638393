// style
import './index.css';

// hooks
import {useEffect, useState} from 'react';

// components
import ActionButton from '../../../../../../components/ActionButton';
import GoBack from '../../../../../../components/GoBack';

// logos
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';
import {ReactComponent as Diamond} from '../../../../../../assets/diamond-eyes-up.svg';

// hooks
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import api from "../../../../../../utils/api";

const resendPasswordMail = async (email: string, setEmailSent: (data: any) => void) => {
    await api.post('/auth/send_reset_password',
        {
            emailreset: email,
            language: localStorage.getItem("translation")
        }, {
            withCredentials: true
        })
        .then((response) => {
            localStorage.setItem('emailreset', email);
            setEmailSent(true);
        })
        .catch((error) => {
            console.log(error);
        });
}

const ProfileUpdateEmail = () => {
    const [email, setEmail] = useState(' ');

    const [emailreset, setEmailReset] = useState(' ');

    const [emailSent, setEmailSent] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (email === ' ') {
            const mail = localStorage.getItem('emailprofile')?.replace(/.{5}(?=@)/g, 'xxxxx') || ' ';
            setEmail(mail);
            setEmailReset(localStorage.getItem('emailprofile') || ' ');
        } else {
            localStorage.removeItem('emailprofile');
        }
    }, [email]);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <div className='admin-profile-update-email'>
            <div className="admin-profile-update-email-header">
                <div className="admin-profile-update-email-go-back">
                    <GoBack
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Retour
                    </GoBack>
                </div>
            </div>
            <section className='admin-profile-update-email-section-container'>
                {/*<MakeeDiamondLogin className='makee-diamond-login-reset-password' />*/}
                <div className="admin-profile-update-email-main">
                    <div className="main-header">
                        <h1 className="h1-auth">{t("page.profileUpdateEmail.title")}</h1>
                        <p className="text">{t("page.profileUpdateEmail.text1")}</p>
                    </div>
                    <h1>{email}</h1>
                    <div className="main-footer">
                        <p className="text italic">{t("page.profileUpdateEmail.rememberCheckSpam")}</p>
                        {emailSent && <p className="text email-sent-text">{t("page.profileUpdateEmail.emailSent")}</p>}
                        <ActionButton
                            type='button'
                            variant='tertiary'
                            className='button-text-auth'
                            onClick={() => {
                                setEmailSent(false);
                                resendPasswordMail(emailreset, setEmailSent)
                            }}
                        >
                            {t("page.profileUpdateEmail.buttonText")}
                        </ActionButton>
                        <a href="mailto:contact@challenkers.com" className='text link'>{t("page.resetPasswordDone.textContact")}</a>
                    </div>
                </div>
                {window.innerWidth > 768 && <Diamond className='diamond-update-email'/>}
            </section>
        </div>
    )
}

export default ProfileUpdateEmail;
