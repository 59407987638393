//react-router-dom
import { Outlet } from "react-router-dom";

// style
import './index.css'

const AdminProfile = () => {
    return <Outlet/>
}

export default AdminProfile;