import React from "react";
import {RootState} from "../../../app/type";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../components/SetUpTranslation";


export const titleHeader = (t:any) => {
    return [
        {
            path: '/',
            title: t("titleHeader.home"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: `/challenge/:id`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        },
        {
            path: `/challenge/:id/general`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        },
        {
            path: `/challenge/:id/planning`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        }, {
            path: `/challenge/:id/select`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        }, {
            path: `/challenge/:id/projects`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        }, {
            path: `/challenge/:id/faq`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        },
        {
            path: `/challenge/:id/ressources`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        },
        {
            path: `/preview/challenge/:id/general`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: '/admin/challenge/:id/general'
        },
        {
            path: `/preview/challenge/:id/planning`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: '/admin/challenge/:id/general'
        },
        {
            path: `/preview/challenge/:id/faq`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: '/admin/challenge/:id/general'
        },
        {
            path: `/preview/challenge/:id/ressources`,
            title: t("titleHeader.challenge"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: '/admin/challenge/:id/general'
        },
        // challenges participant
        {
            path: '/my-projects',
            title: t("titleHeader.projects"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/participant/challenges/search',
            title: t("titleHeader.challenges"),
            makee: false,
            goback: true,
            menu: false,
            eye: false,
            none: false,
            nav: '/',
            redirectGoBack: ''
        },
        {
            path: '/my-projects',
            title: t("titleHeader.myChallenges"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/participant/challenges/my-challenges/current',
            title: t("titleHeader.challenges"),
            nav: '/',
            makee: false,
            goback: true,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        // portfolio participant
        {
            path: '/participant/portfolio',
            title: 'Portfolio',
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/participant/portfolio/all',
            title: 'Portfolio',
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },{
            path: '/participant/portfolio/:id',
            title: 'Portfolio',
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/participant/portfolio/chall-page',
            title: 'Portfolio',
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/participant/portfolio/focus',
            title: 'Portfolio',
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        // profil participant
        // {
        //     path: '/participant/profile/preview/infos',
        //     title: 'Profil participant',
        //     goback: true,
        //     menu: true,
        //     nav: '/',
        //     makee: false,
        //     eye: false,
        //     none: false,
        //     redirectGoBack: ''
        // },

        {
            path: '/participant/profile/update',
            title: t("titleHeader.editMyProfile"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/"
        },

        // home administrateur
        {
            path: '/',
            title: t("titleHeader.home"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        // challenges administrateur


        {
            path: '/admin/challenges',
            title: t("titleHeader.establishmentChallenges"),
            makee: true,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/create',
            title: t("titleHeader.createChallenge"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/admin/challenge/:id/general',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/admin/challenges',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/admin/challenges"
        },
        {
            path: '/admin/challenge/:id/planning',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/admin/challenges',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/admin/challenges"
        },
        {
            path: '/admin/challenge/:id/select',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/admin/challenges',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/admin/challenges"
        },
        {
            path: '/admin/challenge/:id/select',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/admin/challenges',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/admin/challenges"
        },
        {
            path: '/admin/challenge/:id/projects',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/admin/challenges',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: "/admin/challenges"
        },{
            path: '/admin/challenge/:id/projects/selections',
            title: t("titleHeader.projectSelection"),
            goback: true,
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ""
        },
        {
            path: '/admin/challenges/infos/general',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/admin/challenges',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/infos/planning',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/', makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/infos/projects',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/infos/projects',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/infos/select',
            title: t("titleHeader.challenges"),
            goback: true,
            nav: '/',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/preview',
            title: t("titleHeader.challenges"),
            eye: true,
            makee: false,
            menu: false,
            none: false,
            params: true,
            nav: '/admin/challenges/infos/general',
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/edit/general',
            title: t("titleHeader.editMyChallenge"),
            none: true,
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/edit/sign-up',
            title: t("titleHeader.editMyChallenge"),
            none: true,
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/edit/steps',
            title: t("titleHeader.editMyChallenge"),
            none: true,
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/edit/ressources',
            title: t("titleHeader.editMyChallenge"),
            none: true,
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/challenges/edit/criteria',
            title: t("titleHeader.editMyChallenge"),
            none: true,
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            redirectGoBack: ''
        },


        // configuration établissement administrateur
        {
            path: '/admin/school/config/general',
            title: t("titleHeader.configurationEstablishment"),
            goback: true,
            nav: '/',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/school/config/general/update',
            title: t("titleHeader.configurationEstablishment"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/school/config/user',
            title: t("titleHeader.configurationEstablishment"),
            goback: true,
            nav: '/',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/school/config/user/add',
            title: t("titleHeader.configurationEstablishment"),
            goback: true,
            nav: '/',
            makee: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/school/config/challenges',
            title: t("titleHeader.configurationEstablishment"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        // profile

        {
            path: '/my-profile',
            title: t("titleHeader.myProfile"),
            goback: true,
            nav: '/',
            menu: true,
            makee: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },

        {
            path: '/admin/profile/preview/profile-chall',
            title: t("titleHeader.adminProfile"),
            goback: true,
            nav: '/',
            menu: true,
            makee: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/my-profile/update',
            title: t("titleHeader.editMyProfile"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        //Equipe - Projets
        {
            path: '/participant/challenge/:id/team/:id/management',
            title: t("titleHeader.myProject"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/participant/challenge/:id/project/:id',
            title: t("titleHeader.myProject"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/participant/challenge/:id/project/:id/edit',
            title: t("titleHeader.myProject"),
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
        {
            path: '/participant/challenge/project/edition/:id',
            title: 'Mon projet',
            makee: false,
            goback: false,
            menu: false,
            eye: false,
            none: false,
            redirectGoBack: ''
        },
    ]
}
