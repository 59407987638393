// style
import './index.css'

// types
import { IFile, IResource } from 'src/utils/types';

// icons
import { ReactComponent as FileDirectory } from '../../assets/file-directory.svg'
import { ReactComponent as Lien } from '../../assets/icon-link.svg'
import { ReactComponent as IconTrash } from '../../assets/icon-trash.svg';
import { ReactComponent as ArrowUp } from '../../assets/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icon-trash.svg'
import { ReactComponent as ChangeIcon } from '../../assets/icon-change-profile.svg'
import { ReactComponent as ChangeResourceIcon } from '../../assets/icon-change.svg'

// components
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';

// context
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge';

// hooks
import { useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { link } from 'fs';
import ActionButton from '../ActionButton';
import { borderColor } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
        </DialogTitle>
    );
}

interface ResourceProps {
    resource: IResource;
    setResources: React.Dispatch<React.SetStateAction<IResource[]>>;
    resourcesToDelete: IResource[];
    setResourcesToDelete: React.Dispatch<React.SetStateAction<IResource[]>>;
    handleUpdateResource: (stepToUpdate: IResource) => void;
    handleDeleteResource: (stepToDelete: IResource) => void;
    last: boolean
    first: boolean
    index: number,
    errorResource: any[] | [];
    setErrorResource: React.Dispatch<React.SetStateAction<any[] | []>>;
}

const Resource = ({
    resource,
    setResources,
    resourcesToDelete,
    setResourcesToDelete,
    handleUpdateResource,
    handleDeleteResource,
    index,
    first,
    errorResource,
    setErrorResource,
    last }: ResourceProps) => {

    const { setChange } = useAdminEditChallengeContext();

    useEffect(() => {
        console.log("errorResource", errorResource);
    }, [errorResource]);
    // savoir si il y a déjà eu un lien ou un fichier avant
    const [change, setChangeResource] = useState(resource.link !== null ? "link" : resource.file !== null ? "file" : "");
    const [currentResource, setCurrentResource] = useState(resource);
    const globalDateErrors: string[] = [
        "NotfoundDateStartDepositeAndDateEndDeposite",
        "DateRangeOverlap",
    ]

    const depositeStartDateErrors: string[] = [
        "DepositStartDateBeforeToday"
    ]

    const depositeEndDateErrors: string[] = [
        "DepositEndDateBeforeDepositStartDate"
    ]

    const selectionDateErrors: string[] = [
        "SelectionsEndDateProvided",
        "SelectionEndDateBeforeDepositEndDate",
        "SelectionEndDateAfterNextStepStart",
        "SelectionEndDateAfterChallengeEndDate"
    ]

    const nameErrors: string[] = [
        "NotfoundName"
    ]

    const globalErrors: string[] = [
        "StepNotCreated",
        "StepNotFound",
        "NotfoundStep",
        "NotdeleteStep",
        "ErrorServer"
    ]

    const knownErrors: string[] = [
        ...globalDateErrors,
        ...depositeStartDateErrors,
        ...depositeEndDateErrors,
        // ...selectionDateErrors,
        ...nameErrors,
        ...globalErrors
    ]
    const [file, setFile] = useState<File>();
    const [saveOriginalFile, setSaveOriginalFile] = useState(resource.file?.originalFileName || null);
    const [clickDoc, setClickDoc] = useState<boolean>(false);
    const [clickLink, setClickLink] = useState<boolean>(false);
    const [isShown, setIsShown] = useState(false);
    // Function to handle toggling between doc and link
    const handleToggleDocLink = (isDoc: boolean) => {
        if (isDoc) {
            setClickDoc(true);
            setClickLink(false);
        } else {
            setClickDoc(false);
            setClickLink(true);
        }
    };

    useEffect(() => {
    }, [clickDoc, clickLink, saveOriginalFile]);

    useEffect(() => {
        if (currentResource.file) {
            setClickDoc(true);
        } else {
            setClickDoc(false);
        }
    }, [currentResource.file]);


    useEffect(() => {
        if (file) {
            setSaveOriginalFile(file.name);
        }
    }, [file]);

    useEffect(() => {
    }, [saveOriginalFile, file]);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const limitText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <div className={"resource" + (resource.error ? " resource-error" : "")}>
            {resource.error && !knownErrors.includes(resource.error.name) && (
                <div className="resource-error">
                    <p className="text">{resource.error.message}</p>
                </div>
            )}
            <div>
                <div className="resource-header mb-2">
                    {/* <Button> */}
                    <div className='Icon-trash'>
                        <IconTrash
                            onClick={() => {
                                handleDeleteResource(resource)
                                setCurrentResource(prevCurrentResource => ({ ...prevCurrentResource, name: '' }));
                            }
                            } className='icon-trash cur' />
                    </div>
                    {/* </Button> */}
                </div>
                <FormControl onChange={() => setChange(true)} className='resource-form' >
                    <Box >
                        {/* <label>Nom de la ressource</label> */}
                        <TextField
                            error={errorResource.some(error => String(error.id) === String(resource.id) && error.code === "name")}
                            helperText={errorResource.some(error => String(error.id) === String(resource.id) && error.code === "name") ? errorResource.find(error => String(error.id) === String(resource.id) && error.code === "name")?.message : ""}
                            size='small'
                            name="name"
                            id={uuidv4()}
                            label="Nom de la resource (50 caractères max)"
                            variant="outlined"
                            defaultValue={resource.name !== null ? resource.name : ""}
                            value={resource.name !== null ? resource.name : ""}
                            placeholder='Nom de la ressource (50 caractères max)'
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    backgroundColor: "white",
                                }
                            }}
                            inputProps={{ maxLength: 50 }}
                            onChange={(event) => {
                                setCurrentResource(prevCurrentResource => ({
                                    ...prevCurrentResource,
                                    name: event.target.value,
                                }));

                                handleUpdateResource({
                                    ...currentResource,
                                    name: event.target.value,
                                });
                            }
                            }
                        />
                        <Box className='ms-auto text-end my-2'>
                            <IconButton className={`${clickDoc || (!clickDoc && !clickLink && change === "file") ? 'IconButtonActive' : 'IconButton'} doc m-0 p-0 me-2`}
                                onClick={() => {
                                    if (resource.link && (resource.link !== null || resource.link !== "")) {
                                        handleClickOpen()
                                    } else {
                                        handleToggleDocLink(true)
                                        setChange(true);
                                    }
                                }}>
                                <FileDirectory className='m-2' />
                            </IconButton>
                            <IconButton className={`${clickLink || (!clickLink && !clickDoc && change === ("link")) ? 'IconButtonActive' : 'IconButton'} link m-0 p-0`}
                                onClick={() => {
                                    if (file !== undefined || resource.file !== undefined) {
                                        handleClickOpen()
                                    } else {
                                        handleToggleDocLink(false)
                                        setChange(true);
                                    }
                                }}
                            >
                                <Lien className='m-2' />
                            </IconButton>
                        </Box>
                        {(clickDoc || (!clickDoc && !clickLink && change === "file")) && (
                            // {/* Si il y a une erreur lié a un fichier alors border red */}
                            <div style={{ border: '1px' }}>
                                {/* <label>Document de la ressource</label> */}
                                <Button

                                    size='small'
                                    variant="contained"
                                    className={errorResource.some(error => String(error.id) === String(resource.id) && error.code === "file") ? "upload-file-error" : "upload-file"}
                                    component="label"
                                    sx={{
                                        maxWidth: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        borderColor: errorResource.some(error => String(error.id) === String(resource.id) && error.code === "link") ? "red" : "none",
                                    }}
                                >
                                    <p style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        margin: 0,
                                    }}
                                        className='button-text w-80'> {limitText(saveOriginalFile && saveOriginalFile !== undefined ? saveOriginalFile : 'Importer un document', 15)}</p>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            const files = (event.target as HTMLInputElement).files
                                            if (files && files.length > 0) {
                                                setFile(files[0])
                                                setCurrentResource(prevCurrentResource => ({
                                                    ...prevCurrentResource,
                                                    file: files[0],
                                                    isModified: true
                                                }));

                                                handleUpdateResource({
                                                    ...currentResource,
                                                    file: files[0],
                                                    isModified: true
                                                });
                                            }
                                        }
                                        }

                                    />

                                    <div>
                                        <Button onClick={() => {
                                            setFile(undefined)
                                            setSaveOriginalFile(null)
                                            setChange(true)
                                            setCurrentResource(prevCurrentResource => ({
                                                ...prevCurrentResource,
                                                file: undefined,
                                                isModified: true
                                            }));

                                            handleUpdateResource({
                                                ...currentResource,
                                                file: undefined,
                                                isModified: true
                                            });
                                        }}>
                                            <DeleteIcon
                                                className='ms-auto'
                                            />
                                        </Button>
                                    </div>


                                </Button>
                                <FormHelperText
                                    error={errorResource.some(error => String(error.id) === String(resource.id) && (error.code === "linkOrFile" || error.code === "file"))}
                                >
                                    {errorResource.some(error => String(error.id) === String(resource.id) && (error.code === "linkOrFile" || error.code === "file")) ? errorResource.find(error => String(error.id) === String(resource.id) && (error.code === "linkOrFile" || error.code === "file"))?.message : ""}
                                </FormHelperText>
                            </div>
                        )}
                        {(clickLink || (!clickLink && !clickDoc && change === ("link"))) && (
                            <Box>
                                {/* <label>Lien de la ressource</label> */}
                                <TextField
                                    error={errorResource.some(error => String(error.id) === String(resource.id) && (error.code === "link" || error.code === "linkOrFile"))}
                                    helperText={errorResource.some(error => String(error.id) === String(resource.id) && (error.code === "link" || error.code === "linkOrFile")) ? errorResource.find(error => String(error.id) === String(resource.id) && (error.code === "link" || error.code === "linkOrFile"))?.message : ""}
                                    size='small'
                                    name="link"
                                    id={uuidv4()}
                                    label="Lien de la ressource"
                                    variant="outlined"
                                    defaultValue={resource.link !== null ? resource.link : ""}
                                    value={resource.link !== null ? resource.link : ""}
                                    placeholder='Lien de la ressource'
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            backgroundColor: "white",
                                        }
                                    }}
                                    onChange={(event) => {
                                        setCurrentResource(prevCurrentResource => ({
                                            ...prevCurrentResource,
                                            link: event.target.value,
                                        }));

                                        handleUpdateResource({
                                            ...currentResource,
                                            link: event.target.value,
                                        });
                                    }
                                    }
                                />
                            </Box>
                        )}
                        <div>
                            <BootstrapDialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                                className='black-dialog'
                            >
                                <Box className='dialog' sx={{ padding: `${isXsDown ? "2rem" : "0rem"}` }}>
                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                        <ChangeResourceIcon className='d-block m-auto mb-4 change-icon' />
                                        <h3 className={`h1 text-center ${isXsDown ? 'mx-5' : 'mx-3'}`}>
                                            Changer le type de votre ressource ?
                                        </h3>
                                    </BootstrapDialogTitle>
                                    <DialogContent>
                                        <Typography className={`text-center ${isXsDown ? 'p-0' : 'p-2'}`}>
                                            {currentResource.file && currentResource.file.originalFileName ? 'Si vous continuez, le fichier que vous avez importé sera supprimé. Souhaitez-vous vraiment passer une ressource de type lien?' : 'Si vous continuez, le lien que vous avez importé sera supprimé. Souhaitez-vous vraiment passer une ressource de type document?'}
                                        </Typography>
                                    </DialogContent>
                                    <DialogActions sx={{ display: 'block', textAlign: 'center' }} className='my-4'>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} className='py-0' marginTop={2}>
                                                <ActionButton
                                                    type='submit'
                                                    className='mx-auto h-100'
                                                    variant='tertiary'
                                                    onClick={() => handleClose()}>
                                                    Annuler
                                                </ActionButton>
                                            </Grid>
                                            <Grid item xs={12} sm={6} className='py-0' marginTop={2}>
                                                <ActionButton
                                                    type='submit'
                                                    variant='primary'
                                                    className='mx-auto h-100'
                                                    onClick={() => {
                                                        setChange(true);
                                                        if (clickDoc || (!clickDoc && !clickLink && change === "link")) {
                                                            if (clickDoc) {
                                                                // Mode actuel : doc, passer en mode link
                                                                setClickDoc(false);
                                                                setClickLink(true);
                                                                setChangeResource('link');
                                                                setCurrentResource(prevCurrentResource => ({
                                                                    ...prevCurrentResource,
                                                                    link: null,
                                                                    file: undefined,
                                                                    isModified: true,
                                                                }));

                                                                setFile(undefined);

                                                                setSaveOriginalFile(null);
                                                                handleUpdateResource({
                                                                    ...currentResource,
                                                                    link: null,
                                                                    file: undefined,
                                                                    isModified: true,
                                                                });
                                                            } else {
                                                                // Mode actuel : link, passer en mode doc
                                                                setClickDoc(true);
                                                                setClickLink(false);
                                                                setChangeResource('file');
                                                                setCurrentResource(prevCurrentResource => ({
                                                                    ...prevCurrentResource,
                                                                    file: undefined,
                                                                    isModified: true,
                                                                    link: null,
                                                                }));
                                                                handleUpdateResource({
                                                                    ...currentResource,
                                                                    file: undefined,
                                                                    isModified: true,
                                                                    link: null,
                                                                });
                                                                setFile(undefined);
                                                                setSaveOriginalFile(null);
                                                            }
                                                        }
                                                        handleClose();
                                                        setChange(true);
                                                    }}>
                                                    Oui, continuer
                                                </ActionButton>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Box>
                            </BootstrapDialog>
                        </div>
                    </Box>
                </FormControl>
            </div>
        </div>
    )
}
export default Resource;