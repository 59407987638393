import { Action } from 'redux';
import { Profile } from '../type';

interface SetProfileAction extends Action<'SET_PROFILE'> {
    payload: {
      profile: Profile;
    };
}

const profileInitialState: Profile = {
    id: "",
    name: "",
};

const profileRootReducer = (state = profileInitialState, action: Action): Profile => {
    switch (action.type) {
      case 'SET_PROFILE':
        const setProfileAction = action as SetProfileAction;
        return {
          ...state,
          ...setProfileAction.payload.profile,
        };
  
      case 'CLEAR_PROFILE':
        return profileInitialState;
  
      default:
        return state;
    }
};
  
  
  export default profileRootReducer;