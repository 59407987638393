import "./index.css";
import ActionButton from "../../../../../../components/ActionButton";
import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CardMedia, Chip,
    Grid,
    Snackbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import SwitchButton from "../../../../../../components/SwitchButton";
import {IProjectsSelection, ISelectedProjects, ISubChallenges} from "./types";
import Modal from "../../../../../../modules/Modal";
import api from "../../../../../../utils/api";

const AdminInfosChallengeProjectsSelections = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const down500 = useMediaQuery(theme.breakpoints.down(500));
    const [selectedProjects, setSelectedProjects] = useState<ISelectedProjects[]>([]);

    const challengeId = useParams().id;
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [textSnackBar, setTextSnackBar] = useState<string>("");
    const [variantSnackBar, setVariantSnackBar] = useState<"error" | "success">("success");

    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [selectionProjectsList, setSelectionProjectsLists] = useState<IProjectsSelection>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [openModalAnnouncement, setOpenModalAnnouncement] = useState<boolean>(false);
    const [selectedProjectsCount, setSelectedProjectsCount] = useState<number>(selectedProjects.filter(project => project.selected).length);
    const [unSelectedProjectsCount, setUnSelectedProjectsCount] = useState<number>(selectedProjects.filter(project => !project.selected).length);
    const selectionProjectSave = async () => {

        try {
            await api.put("/admin/projectsselectionsave",
                {
                    selected: selectedProjects,
                    stepId: selectionProjectsList?.step.id,
                    challengeId: challengeId,
                },
                {
                    withCredentials: true,
                }
            ).then((res) => {
                    setOpenSnackBar(true);
                    setVariantSnackBar("success");
                    setTextSnackBar("Les projets ont bien été enregistrés dans la sélection.")
                }
            ).catch((error) => {
                setOpenSnackBar(true);
                setVariantSnackBar("error");
                setTextSnackBar("Une erreur est survenue lors de l'enregistrement des projets dans la sélection.")
            })
            ;
            console.log("Projects saved successfully!");
        } catch (error) {
            console.error("Error saving projects:", error);
        }
    };
    const handleSwitchChange = (projectId: number) => {
        setSelectedProjects(prevSelectedProjects => {
            const index = prevSelectedProjects.findIndex(project => project.projectId === projectId);

            if (index !== -1) {
                // La carte est déjà sélectionnée, donc mettre à jour son état
                const updatedProjects = [...prevSelectedProjects];
                updatedProjects[index] = {
                    ...updatedProjects[index],
                    selected: !updatedProjects[index].selected,
                };
                return updatedProjects;
            } else {
                // La carte n'est pas encore sélectionnée, donc l'ajouter à la sélection
                return [...prevSelectedProjects, {projectId, selected: true}];
            }
        });
    };
    const getSelectionProjectsList = async () => {
        try {
            await api.get(`/admin/projectsselection?challengeId=${challengeId}`,
                {
                    withCredentials: true,
                }
            ).then((res) => {
                    setSelectionProjectsLists(res.data);
                    console.log("ok caca");
                    let selectedProjectsArray = [];
                    if (res.data.subChallenges) {
                        console.log("yooooo");
                        // Si des sous-défis existent, utilisez leurs projets sélectionnés
                        res.data.subChallenges.forEach((subChallenge: { stepProject: any[]; }) => {
                            subChallenge.stepProject.forEach(project => {
                                selectedProjectsArray.push({projectId: project.projectId, selected: project.selected});
                            });
                        });
                        console.log("je suis ici");
                    } else if (res.data.others) {
                        // Si aucun sous-défi mais des projets "others" existent, utiliser ces projets
                        selectedProjectsArray = res.data.others.map((project: { projectId: any; selected: any; }) => ({
                            projectId: project.projectId,
                            selected: project.selected
                        }));
                    }
                    console.log("selectedProjectsArray", selectedProjectsArray);
                    setSelectedProjects(selectedProjectsArray);
                }
            ).catch((error) => {
                // console.log("Erreur : ", error.response.data.message);
                //setErrorMessage(error.response.data.message);
            });
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const projectSelectionAnnouncement = async () => {
        try {
            await api.put(`/admin/projectsselection`,
                {
                    challengeId: challengeId,
                    stepId: selectionProjectsList?.step.id,
                    selected: selectedProjects
                },
                {
                    withCredentials: true,
                }
            ).then((res) => {
                    setOpenSnackBar(true);
                    setVariantSnackBar("success");
                    setTextSnackBar("Les projets ont bien été annoncés.");
                    setOpenModalAnnouncement(false);
                }
            ).catch((error) => {
                setOpenSnackBar(true);
                setVariantSnackBar("error");
                setTextSnackBar("Une erreur est survenue lors de l'annonce des projets de votre sélection.");
            });
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const findProjectInfo = (projectId: number) => {
        // Chercher dans les subChallenges
        for (const subChallenge of selectionProjectsList?.subChallenges || []) {
            const projectInfo = subChallenge.stepProject.find(project => project.projectId === projectId);
            if (projectInfo) {
                return projectInfo;
            }
        }

        // Chercher dans les projets "others"
        const otherProjectInfo = selectionProjectsList?.others && selectionProjectsList?.others.find(project => project.projectId === projectId);
        if (otherProjectInfo) {
            return otherProjectInfo;
        }

        return null;
    };
    useEffect(() => {
        getSelectionProjectsList();
    }, []);

    useEffect(() => {
        setSelectedProjectsCount(selectedProjects.filter(project => project.selected).length);
    }, [selectedProjects]);
    useEffect(() => {
        setUnSelectedProjectsCount(selectedProjects.filter(project => !project.selected).length);
    }, [selectedProjects]);
    return (
        <div className={"admin-infos-challenge-projects-selections"}>
            <div className={"admin-infos-challenge-projects-selections-header"}>
                <h3>Étape {selectionProjectsList?.currentPosition}</h3>
                <p className={"admin-infos-challenge-projects-selections-header-step-name"}>{selectionProjectsList?.step.name && selectionProjectsList?.step.name}</p>
                {
                    selectionProjectsList?.step.dateStartDeposite && selectionProjectsList?.step.dateEndDeposite && (
                        <Chip
                            label={`Du ${moment(selectionProjectsList?.step.dateStartDeposite).format("L")} à ${moment(selectionProjectsList?.step.dateStartDeposite).format("HH[h]mm")} au ${moment(selectionProjectsList?.step.dateEndDeposite).format("L")} à ${moment(selectionProjectsList?.step.dateEndDeposite).format("HH[h]mm")}`}
                            sx={{
                                backgroundColor: "white",
                                color: "var(--primary90)",
                                fontSize: "14px",
                                fontFamily: "var(--font-secondary)",
                                fontWeight: "700",
                                minHeight: "2rem",
                                maxWidth: "90%",
                                marginTop: "1rem",
                                height: 'auto',
                                '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                },
                            }}
                        />
                    )
                }
                {
                    selectionProjectsList?.step.dateEndSelection && (
                        <p className={"admin-infos-challenge-projects-selections-header-step-date-selection"}>Fin de la
                            phase de sélection :
                            le {moment(selectionProjectsList?.step.dateEndSelection).format("L")} à {moment(selectionProjectsList?.step.dateEndSelection).format("HH[h]mm")}</p>
                    )
                }
            </div>
            {
                errorMessage && (
                    <p className={"error-message"}>{errorMessage}</p>
                )
            }
            <div className={"admin-infos-challenge-projects-selections-content"}>
                {
                    selectionProjectsList?.criterias && selectionProjectsList.criterias.length > 0 && (
                        <div className={"admin-infos-challenge-projects-selections-content-criterias"}>
                            <h4>Rappel de vos critères de sélection</h4>
                            <p className={"text-infos-criterias"}>Pour vous aidez dans la sélection des équipes,
                                appuyez
                                vous sur votre barème de
                                sélection.</p>
                            {
                                selectionProjectsList.criterias.map((criteria, indexCriteria) => (
                                    <div className={"admin-infos-challenge-projects-selections-content-criteria"}
                                         key={indexCriteria}>
                                        <div
                                            className={"admin-infos-challenge-projects-selections-content-criteria-text"}>
                                            <p className={"name"}>{criteria.name}</p>
                                            <p className={"description"}>{criteria.description}</p>
                                        </div>
                                        <div
                                            className={"admin-infos-challenge-projects-selections-content-criteria-points"}>
                                            <p>{criteria.grade} points</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <h3 style={{marginTop: "3rem"}}>Sélectionner les projets</h3>
                <p className={"description w-90"}>
                    Votre sélection ne sera pas visible par les participants. Ils recevront un e-mail lorsque
                    vous
                    cliquerez sur “Annoncer les résultats” afin de savoir si leur projet a été retenu ou non.
                </p>
                <div className={`admin-infos-challenge-projects-selections-content-projects`}>
                    <Grid container spacing={3} justifyContent={"center"}>
                        {
                            selectionProjectsList?.others && selectionProjectsList?.others.map((project, indexProject) => (
                                <Grid item xs={12} sm={6} md={6}
                                      lg={selectionProjectsList && selectionProjectsList.others && selectionProjectsList.others.length > 1 ? 4 : 6}>
                                    <Card
                                        sx={{
                                            borderRadius: "10px",
                                        }}
                                        // className={`admin-infos-challenge-projects-selections-list`}
                                        className={`admin-infos-challenge-projects-selections-list${selectedProjects.find(projectSelected => projectSelected.projectId === project.projectId)?.selected ? ' card-selected' : ''}`}
                                        // className={`admin-infos-challenge-projects-selections-list${cardStates && cardStates[indexProject] ? ' card-selected' : ''}`}
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="image card projet déposé"
                                            image={`${project.file.fileUrl}`}
                                            className={"admin-infos-challenge-projects-selections-list-img"}
                                        />
                                        <CardContent>
                                            <Typography variant="h3" className={"typo-card-p"}>
                                                {project.team.name}
                                            </Typography>
                                            <Typography variant="h3" className={"typo-card-h3"}
                                                        style={{textAlign: "start"}}>
                                                {project.projectName}
                                            </Typography>
                                        </CardContent>
                                        <CardActions
                                            className={`admin-infos-challenge-projects-selections-typo-card-bottom`}>
                                            <div className={"block-switchbutton-text"}>
                                                <SwitchButton
                                                    size={isMdDown ? 'small' : 'medium'}
                                                    checked={selectedProjects.find(projectSelected => projectSelected.projectId === project.projectId)?.selected || false}
                                                    onChange={() => handleSwitchChange(project.projectId)}
                                                />
                                                <p>Sélectionner</p>
                                            </div>
                                            <Typography variant={"body1"}
                                                        className={"typo-card-p typo-card-dateDeposit"}>
                                            </Typography>
                                            <ActionButton
                                                variant={"little-underline"}
                                                onClick={() => {
                                                    navigate(`/admin/challenge/${challengeId}/project/${project.projectId}`);
                                                }}
                                            >
                                                Voir le projet
                                            </ActionButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                    {
                        selectionProjectsList?.subChallenges && selectionProjectsList?.subChallenges.map((subChallenge, indexSubChallenge) => (
                            <div
                                className={`admin-infos-challenge-projects-selections-content-project-subchallenge`}
                                key={indexSubChallenge}>
                                {
                                    subChallenge.stepProject.length > 0 && (
                                        <h4>Défi {subChallenge.position} - {subChallenge.name}</h4>
                                    )
                                }
                                <Grid container spacing={3} justifyContent={"center"}>
                                    {
                                        subChallenge.stepProject.map((project, indexProject) => (
                                            <Grid item xs={12} sm={6} md={6} lg={4}
                                                  key={indexProject}>
                                                <Card
                                                    sx={{
                                                        borderRadius: 10,
                                                    }}
                                                    // className={`admin-infos-challenge-projects-selections-list`}
                                                    className={`admin-infos-challenge-projects-selections-list${selectedProjects.find(projectSelected => projectSelected.projectId === project.projectId)?.selected ? ' card-selected' : ''}`}
                                                    // className={`admin-infos-challenge-projects-selections-list${cardStates[indexProject] ? ' card-selected' : ''}`}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        alt="image card projet déposé"
                                                        image={`${project.file.fileUrl}`}
                                                        className={"admin-infos-challenge-projects-selections-list-img"}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="h3" className={"typo-card-p"}>
                                                            <p>Equipe {project.team.numero} - {project.team.name}</p>
                                                        </Typography>
                                                        <Typography variant="h3" className={"typo-card-h3"}>
                                                            <h3 className={"text-start"}>{project.projectName}</h3>
                                                        </Typography>
                                                        <Typography marginTop={1} variant="body1"
                                                                    className={"typo-card-p"}>
                                                            <p>{project.team.memberCount} {project.team.memberCount > 1 ? 'participants' : 'participant'}</p>
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions
                                                        className={`admin-infos-challenge-projects-selections-typo-card-bottom`}>
                                                        <div className={"block-switchbutton-text"}>
                                                            <SwitchButton
                                                                size={isMdDown ? 'small' : 'medium'}
                                                                checked={selectedProjects.find(projectSelected => projectSelected.projectId === project.projectId)?.selected || false}
                                                                onChange={() => handleSwitchChange(project.projectId)}
                                                            />
                                                            <p>Sélectionner</p>
                                                        </div>
                                                        <Typography variant={"body1"}
                                                                    className={"typo-card-p typo-card-dateDeposit"}>
                                                        </Typography>
                                                        <ActionButton
                                                            variant={"little-underline"}
                                                            onClick={() => {
                                                                navigate(`/admin/challenge/${challengeId}/project/${project.projectId}`);
                                                            }}
                                                        >
                                                            Voir le projet
                                                        </ActionButton>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))}
                                </Grid>
                            </div>
                        ))}
                </div>
                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={3000}
                    onClose={() => {
                        setOpenSnackBar(false)
                        setVariantSnackBar('success')
                        setTextSnackBar('')
                    }}
                >
                    <Alert onClose={() => {
                        setOpenSnackBar(false)
                        setVariantSnackBar('success')
                        setTextSnackBar('')
                    }} severity={variantSnackBar}>{textSnackBar}</Alert>
                </Snackbar>
            </div>
            {
                !errorMessage && (
                    <div className={'admin-infos-challenge-projects-selections-footer'}>
                        <div className={"admin-infos-challenge-projects-selections-footer-buttons"}>
                            <ActionButton variant={"tertiary"} onClick={selectionProjectSave}>
                                {
                                    down500 ? (
                                        <span>Enregistrer</span>
                                    ) : (
                                        <span>Enregistrer ma sélection</span>
                                    )
                                }
                            </ActionButton>
                            <ActionButton variant={"primary"} onClick={() => setOpenModalAnnouncement(true)}>
                                {
                                    down500 ? (
                                        <span>Annoncer</span>
                                    ) : (
                                        <span>Annoncer la sélection</span>
                                    )
                                }
                            </ActionButton>
                        </div>
                    </div>
                )
            }
            <Modal
                title={"Annoncez votre sélection aux participants"}
                open={openModalAnnouncement}
                onClose={() => setOpenModalAnnouncement(false)}
                primaryButtonText={down500 ? "Annoncer" : "Annoncer la sélection"}
                primaryButtonAction={() => projectSelectionAnnouncement()}
                tertiaryButtonText={"Annuler"}
                tertiaryButtonAction={() => setOpenModalAnnouncement(false)}
            >
                <p>Les participants vont être informés par e-mail de la sélection ou non de leur
                    projet. </p>

                <div className={"admin-infos-challenge-projects-selections-modal"}>
                    <h3>Projets sélectionnés :</h3>
                    <div className={"admin-infos-challenge-projects-selections-modal-block-selections"}>
                        {
                            // afficher les projets sélectionnés donc qui ont selected à true, afficher les infos qui sont contenues dans selectionProjectsLists
                            // Donc il faut comparer ceux qui ont selected à true dans selectedProjects
                            selectedProjectsCount > 0 ?
                                selectedProjects.map((selectedProject, index) => {
                                    if (selectedProject.selected) {
                                        const projectInfo = findProjectInfo(selectedProject.projectId);
                                        if (projectInfo) {
                                            return (
                                                <div key={index}
                                                     className={"admin-infos-challenge-projects-selection-modal-content"}>
                                                    <img src={projectInfo.file.fileUrl} className={"img-project"}
                                                         alt="projectTeam"/>
                                                    <div className={"block-description"}>
                                                        <div className={"description"}>
                                                            <p className={"team"}>Equipe {projectInfo.team.numero} - {projectInfo.team.name}</p>
                                                            <p className={"project-name"}>{projectInfo.projectName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                })
                                : (
                                    <p>Aucun projet sélectionné</p>
                                )
                        }
                    </div>
                </div>
                <div className={"admin-infos-challenge-projects-selections-modal"}>
                    <h3>Projets non sélectionnés :</h3>
                    <div className={"admin-infos-challenge-projects-selections-modal-block-selections"}>
                        {
                            unSelectedProjectsCount > 0 ?
                                selectedProjects.map((selectedProject, index) => {
                                    if (!selectedProject.selected) {
                                        const projectInfo = findProjectInfo(selectedProject.projectId);
                                        if (projectInfo) {
                                            return (
                                                <div key={index}
                                                     className={"admin-infos-challenge-projects-selection-modal-content"}>
                                                    <img src={projectInfo.file.fileUrl} className={"img-project"}
                                                         alt="url-project"/>
                                                    <div className={"block-description"}>
                                                        <div className={"description"}>
                                                            <p className={"team"}>Equipe {projectInfo.team.numero} - {projectInfo.team.name}</p>
                                                            <p className={"project-name"}>{projectInfo.projectName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                })
                                : (
                                    <p>Aucun projet non sélectionné</p>
                                )
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AdminInfosChallengeProjectsSelections;