import { useEffect, useState } from 'react';
import ActionButton from '../../../../../../components/ActionButton';
import GoBack from '../../../../../../components/GoBack';
import GoNext from '../../../../../../components/GoNext';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminEditChallengeContext } from '..';
import { ICriterium } from 'src/utils/types';
import './index.css';
import { ReactComponent as IconAdd } from '../../../../../../assets/icon-add.svg';
import Criterium from '../../../../../../components/Criterium';
import { v4 as uuidv4 } from 'uuid';

const AdminEditChallengeCriteria = () => {
    const navigate = useNavigate();
    const { name } = useParams();
    const { page, setPage } = useAdminEditChallengeContext();
    const { click, setClick } = useAdminEditChallengeContext();
    const { change, setChange } = useAdminEditChallengeContext();
    const { id } = useAdminEditChallengeContext();
    const { criteria, setCriteria } = useAdminEditChallengeContext();
    const { setCriteriaToDelete } = useAdminEditChallengeContext();
    const { fetchCriteria } = useAdminEditChallengeContext();
    const [points, setPoints] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        setPage('criteria');
        fetchCriteria();
    }, []);

    useEffect(() => {
        setPoints(criteria.reduce((acc, criterium) => (!isNaN(acc) ? acc : 0) + criterium.valuePoints, 0));
    }, [criteria]);

    const handleAddCriterium = () => {
        if (criteria.some(criterium => criterium.name.trim() === '')) {
            setErrorMessage('Veuillez entrer un nom pour le critère.');
            return;
        }
        setChange(true);
        const newId = uuidv4();
        setCriteria([
            ...criteria,
            {
                id: newId,
                challengeId: id,
                name: '',
                description: '',
                valuePoints: 0,
                isNew: true
            }
        ]);
        setErrorMessage('');
    };

    const handleUpdateCriterium = (criteriumToUpdate: ICriterium) => {
        setChange(true);
        setCriteria(criteria.map((criterium) => {
            if (criterium.id === criteriumToUpdate.id) {
                if (typeof criteriumToUpdate.id === 'number') {
                    criteriumToUpdate.isModified = true;
                }
                return criteriumToUpdate;
            } else {
                return criterium;
            }
        }));
    };

    const handleDeleteCriterium = (criterium: ICriterium) => {
        setChange(true);
        setCriteria(criteria.filter((c) => c !== criterium));
        if (typeof criterium.id === 'number') {
            setCriteriaToDelete((criteriaToDelete) => [...criteriaToDelete, criterium]);
        }
    };

    return (
        <div className='admin-edit-challenge'>
            <section className='criteria'>
                <div className="header">
                    <h1>Définissez vos critères de sélection</h1>
                    <p className="text">Ajouter des critères avec des points associés afin de guider la phase de sélection de façon cohérente.</p>
                </div>
                {criteria.map((criterium: ICriterium, index: number) => {
                    return (
                        <Criterium
                            item={criterium}
                            key={index}
                            handleDeleteCriterium={handleDeleteCriterium}
                            handleUpdateCriterium={handleUpdateCriterium}
                            updateErrorMessage={setErrorMessage}
                        />
                    );
                })}
                <div className="footer">
                    <ActionButton
                        variant='secondary'
                        onClick={handleAddCriterium}
                        className='button-add-criterium'
                        icon={<IconAdd className='icon-add' />}
                    >
                        Ajouter un critère
                    </ActionButton>
                    <div className="total-points">
                        <h2 className="form-title">Total</h2>
                        <div className="points">
                            <p className="small-lead-text">{points} pts</p>
                        </div>
                    </div>
                </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            </section>
        </div>
    );
};

export default AdminEditChallengeCriteria;
