// react-routeur
import { useNavigate } from "react-router-dom";

// assets
import { ReactComponent as IconInscr} from '../../assets/icon-pen.svg'

// style
import './index.css'

interface ButtonBaseProps {
    children: React.ReactNode;
    link?: string;
    bold?: boolean;
    type?: string;
    clickable?: boolean;
    className?: string;
    darken?: boolean;
}

/**
 * ## ButtonBase
 * 
 * ---
 * 
 * Ce composant est le bouton de base.  
 * Il existe deux types de boutons :  
 * - white : bouton blanc
 * - darken : bouton noir 
 *    
 * **Par défaut le bouton est blanc**
 *
 * ---
 * 
 * @param {React.ReactNode} children - Le texte à afficher dans le bouton
 * @param {string} link - Le lien vers lequel rediriger au clic
 * @param {boolean} bold - Si le texte doit être en gras
 * @param {string} type - Le type du bouton (button, submit, reset)
 * @param {boolean} clickable - Si le bouton est cliquable
 * @param {string} className - Les classes CSS à appliquer au bouton
 * @param {boolean} darken - Si le bouton doit être noir
 * 
 * ---
 * 
 * @example
 * <ButtonBase
 *  link='/inscription'
 *  bold={true}
 *  type='button'
 *  clickable={true}
 *  className='button-auth'
 *  darken={true}
 * >
 *   Créer un compte
 * </ButtonBase>
 * 
 */

const ButtonBase = ({ children, link, type, bold, clickable, className, darken }: ButtonBaseProps) => {

    const navigate = useNavigate();

    return <button 
        onClick={() =>
            clickable && navigate('{' + link + '}')
        }
        className={'button-base' + (darken ? '-darken' : '') + (className ? ' ' + className : '') }
    >
        {
            type === 'inscription' ? <IconInscr/> : <></>
        }
        <p className={bold ? 'button-text' : 'button-small-text'}>{children}</p>
    </button>;
};

export default ButtonBase;