// style
import './index.css';

// hooks
import {useEffect, useState} from 'react';

// components
import ActionButton from '../../../../../../components/ActionButton';
import GoBack from '../../../../../../components/GoBack';

// logos
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';
import {ReactComponent as Diamond} from '../../../../../../assets/diamond-eyes-up.svg';


// hooks
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../app/type";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import api from "../../../../../../utils/api";

const mail = localStorage.getItem('newEmailPro')?.replace(/.{5}(?=@)/g, 'xxxxx')!;

const resendNewEmailForChangingEmail = async (email: string, profileId: any) => {
    await api.put('/auth/change_email',
        {
            language: localStorage.getItem("translation"),
            newemail: email,
            profileId: profileId
        },
        {
            withCredentials: true
        })
        .then((res) => {
            console.log(res.data)
        })
        .catch((error) => {
            console.log(error);
        })
}

const ProfileChangeEmail = () => {
    const profileId = useSelector((action: RootState) => action.rootProfile.id);
    const [emailSent, setEmailSent] = useState(false);

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <div className='admin-profile-update-email'>
            <div className="admin-profile-update-email-header">
                <div className="admin-profile-update-email-go-back">
                    <GoBack
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        {t("page.resetPasswordDone.back")}
                    </GoBack>
                </div>
            </div>
            <section className='admin-profile-update-email-section-container'>
                {/*<MakeeDiamondLogin className='makee-diamond-login-reset-password' />*/}
                <div className="admin-profile-update-email-main">
                    <div className="main-header">
                        <h1 className="h1-auth">{t("page.resetPasswordDone.title")}</h1>
                        <p className="text">{t("page.resetPassword.text1")}</p>
                    </div>
                    <h1>{mail}</h1>
                    <div className="main-footer">
                        <p className="text italic">{t("page.resetPasswordDone.rememberCheckSpam")}</p>
                        {emailSent && <p className="text email-sent-text">{t("page.resetPasswordDone.emailSent")}</p>}
                        <ActionButton
                            type='button'
                            variant='tertiary'
                            className='button-text-auth'
                            onClick={() => {
                                setEmailSent(false);
                                resendNewEmailForChangingEmail(mail, profileId)
                            }}
                        >
                            {t("page.resetPasswordDone.buttonText")}
                        </ActionButton>
                        <a href="/" className='text link'>{t("page.resetPasswordDone.textContact")}</a>
                    </div>
                </div>
                {window.innerWidth > 768 && <Diamond className='diamond-update-email'/>}
            </section>
        </div>
    )
}

export default ProfileChangeEmail;
