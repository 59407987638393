// Material UI
import {Alert, Box, FormControl, FormControlLabel, Radio, RadioGroup, Snackbar, TextField} from "@mui/material";

// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../app/type";

// style
import './index.css';

// components
import ActionButton from "../../../../../components/ActionButton";
import InputDate from "../../../../../components/InputDate";

// react
import {useNavigate} from "react-router-dom";

// moment
import moment, {Moment} from 'moment';
import React, {useState} from "react";
import {DateTimePicker, renderTimeViewClock} from "@mui/x-date-pickers";
import api from "../../../../../utils/api";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";

const AdminCreateChallenge = () => {

    const [value, setValue] = useState('public');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    // navigation
    const navigate = useNavigate();

    // redux dispatch
    const dispatch = useDispatch();

    // redux state
    const establishment_name = useSelector((action: RootState) => action.user.establishment);
    const type = useSelector((action: RootState) => action.error.type)

    // date du jour + heure au format ISO : 2021-10-13T15:00 + un jour
    // const dateTime = moment().add(1, 'day').format('YYYY-MM-DDTHH:mm');
    // create challenge
    // establishment_name, challenge_name, startDate, endDate
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [openSnackBarError, setOpenSnackbarError] = useState(false);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);
    const createChallenge = (e: any | Moment) => {
        e.preventDefault();
        const currentLanguage = i18n.language;
        let dateFormat = 'DD/MM/YYYY hh:mm'; // Format par défaut
        if (currentLanguage === 'fr') {
            dateFormat = 'DD/MM/YYYY HH:mm'; // Format français
        } else if (currentLanguage === 'en') {
            dateFormat = 'MM/DD/YYYY hh:mm A'; // Format anglais
        }
        const challenge_name = e.target.challengeName.value;
        const startDate = moment(e.target.startDate.value, dateFormat).toISOString()
        const endDate = moment(e.target.endDate.value, dateFormat).toISOString()
        const isPublic = value === 'public';
        api.post('/admin/challenge/create', {
                challenge_name: challenge_name,
                isPublic: isPublic,
                establishment_name: establishment_name,
                startDate: startDate,
                endDate: endDate,
            },
            {
                withCredentials: true,
            }
        )
            .then(response => {
                dispatch({
                    type: "CLEAR_ERROR",
                });
                navigate('/admin/challenges')
            })
            .catch(error => {
                setOpenSnackbarError(true);
                setErrorMessage(error.response.data.message);
            })
    }
    return (
        <div className='block-admin-create-challenge'>
            <section className="admin-create-challenge">
                <Snackbar
                    open={openSnackBarError}
                    autoHideDuration={3000}
                    onClose={() => {
                        setOpenSnackbarError(false);
                    }}
                >
                    <Alert
                        onClose={() => {
                            setOpenSnackbarError(false)
                        }}
                        severity={"error"}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <p className='text-obligatory'>* Champs obligatoires</p>
                <FormControl
                    onSubmit={(e) => {
                        createChallenge(e);
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            // on ajoute un margin top de 40px à tous les enfants de Box
                            '& > :not(style)': {marginTop: '40px'},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <p className="form-text">Nom du Challenge * (max 50 caractères)</p>
                            <TextField
                                {...(type === 'NoDateProvided' && {error: true})}
                                {...(type === 'EmptyChallengeName' && {error: true})}
                                id="challengeName"
                                variant="outlined"
                                placeholder='Nom du challenge'
                                type='text'
                                helperText="50 caractères maximum"
                                inputProps={{maxLength: 50}}
                                required
                                fullWidth
                                autoFocus
                            />
                        </div>
                        <div className='choix-dates'>
                            <div>
                                <p className="form-text">Début du Challenge *</p>
                                <div className="text-field-date">
                                    <DateTimePicker
                                        slotProps={{
                                            textField: {
                                                // size:"small",
                                                id: "startDate",
                                                fullWidth: true,
                                                error: type === 'NoDateProvided' || type === 'EarlierEndDate',
                                                helperText: type === 'NoDateProvided' || type === 'EarlierEndDate' ? errorMessage : '',
                                            },
                                        }}
                                        defaultValue={moment()}
                                        onChange={(date: Moment | null,) => {
                                            console.log(String(moment(date)))
                                        }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="form-text">Fin du Challenge * <br/> <span className="text">(date d'annonce des résultats)</span>
                                </p>
                                <div className="text-field-date">
                                    <DateTimePicker
                                        slotProps={{
                                            textField: {
                                                // size:"small",
                                                id: "endDate",
                                                fullWidth: true,
                                                error: type === 'NoDateProvided' || type === 'EarlierEndDate',
                                                helperText: type === 'NoDateProvided' || type === 'EarlierEndDate' ? errorMessage : '',
                                            },
                                        }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        defaultValue={moment().add(1, 'day')}
                                    />
                                </div>
                            </div>
                        </div>
                        {type === 'NoDateProvided' && <p className='text helper-text'>{errorMessage}</p>}
                        {type === 'EarlierEndDate' && <p className='text helper-text'>{errorMessage}</p>}
                        {type === 'EmptyChallengeName' && <p className='text helper-text'>{errorMessage}</p>}
                        <div className='radio-buttons'>
                            <p className="form-text">Visibilité du Challenge *</p>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="public"
                                name="radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="public" control={<Radio/>}
                                                  label="Challenge public (visible par tous)"/>
                                <FormControlLabel value="private" control={<Radio/>}
                                                  label="Challenge privé (accessible avec un lien)"/>
                            </RadioGroup>
                        </div>
                        <div className='form-buttons'>
                            <ActionButton
                                variant='tertiary'
                                onClick={() => {
                                    dispatch({
                                        type: "CLEAR_ERROR",
                                    });
                                    navigate(-1)
                                }}
                            >
                                Annuler
                            </ActionButton>
                            <ActionButton
                                type='submit'
                                variant='primary'
                            >
                                Créer
                            </ActionButton>
                        </div>
                    </Box>
                </FormControl>
            </section>
        </div>
    )
}

export default AdminCreateChallenge;
