
import React from 'react';
import './index.css';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme } from '@mui/material';

import {ReactComponent as IconReturn} from '../../assets/icon-return.svg';

import { RootState } from 'src/app/type';
import { useSelector } from 'react-redux';

interface IQuestion {
    answer: string;
    question: string;
}

const FaqCard = ({ question, key }: { question: IQuestion; key: number }) => {
    const getOneChallenge = useSelector((state: RootState) => state.challenges.getOneChallenge)
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    const theme = useTheme();
    const isbetweenXsAndMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isbetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const ismorelg = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Accordion 
            id="accordion" 
            key={key}
            sx={{
                boxShadow: 'none !important',
                borderRadius: '10px !important',
                border: '1px solid #E4E9F2 !important',
                marginBottom: '1em !important',
                "&, &:before, &:after": {
                    background: 'none !important',
                },
            width: "100%"
            }}
            TransitionProps={{ unmountOnExit: true }} expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
            <AccordionSummary
            sx={{
                background: '#EDF1F7 !important',
                boxShadow: 'none',
                borderTopLeftRadius: '10px !important',
                borderTopRightRadius: '10px !important',
                borderRadius: '10px !important',
            }}
            expandIcon={<IconReturn className='icon' />}
            aria-controls={`panel${key}bh-content`}
            id={`panel${key}bh-header`}
            >
                <Typography className='title-accordion'  sx={{ width: '100%', flexShrink: 0, wordBreak:'break-word' }}>
                    <h5 className='m-0'>{question.question}</h5>
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
                padding: '16px 16px 1em 1em !important',
            }}>
                <Typography sx={{ wordBreak:'break-word', whiteSpace: 'pre-wrap' }}>
                    <p>{question.answer}</p>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}

export default FaqCard;