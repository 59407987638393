import React from "react";

interface IMakeeDiamonLogin {
    className?: string;
}

const makeeDiamondLogin: React.FC<IMakeeDiamonLogin> = ({
                                                            className
                                                        }
) => {
    return (
        <svg width="110" height="122" viewBox="0 0 110 122" fill="none" xmlns="http://www.w3.org/2000/svg"
             className={`${className && className}`}>
            <path
                d="M89.1313 52.5929C83.832 55.4361 78.5485 58.2608 73.2492 61.0881C71.5486 62.0016 69.8453 63.2468 67.863 63.3179C66.2492 63.3705 64.6355 63.3179 63.0375 63.3179H43.612C42.2246 63.3179 40.6451 63.5469 39.3631 62.9836C37.4861 62.1411 35.6959 61.017 33.8874 60.0535C29.2725 57.5789 24.6392 55.1044 20.006 52.6298C19.2873 52.2428 19.3742 51.5425 19.7243 50.9976C19.8138 50.8739 19.9007 50.7343 20.0402 50.6106C20.1113 50.5395 20.1797 50.4869 20.2508 50.4184C20.3034 50.3842 20.3561 50.3315 20.4245 50.2947C20.514 50.2236 20.6009 50.171 20.6878 50.1183C20.722 50.0999 20.7589 50.0841 20.7931 50.0657C20.8054 50.0657 20.8168 50.0595 20.8273 50.0472C26.0029 47.3094 31.1811 44.5373 36.3409 41.7652C38.1468 40.7991 40.0265 39.396 42.1141 39.3433C43.7278 39.2907 45.3416 39.3433 46.958 39.3433H66.3677C67.7708 39.3433 69.3504 39.0985 70.6509 39.6934C72.7411 40.6227 74.7234 41.8706 76.7241 42.9394C81.1468 45.3087 85.551 47.6779 89.9553 50.0288C91.3769 50.7659 89.9026 52.1875 89.1313 52.5903V52.5929Z"
                fill="#122236"/>
            <path d="M54.7183 107.879L19.8477 55.7573L38.1517 65.5503L54.7183 107.879Z"
                  fill="url(#paint0_linear_1363_62637)"/>
            <path
                d="M68.0272 66.698L55.0409 107.834L53.6009 103.288L53.3903 102.603C51.1789 95.3559 47.0721 82.5959 44.0368 73.2267C43.5972 71.8578 43.1944 70.5599 42.7916 69.3647L41.9492 66.698H68.0272Z"
                fill="url(#paint1_linear_1363_62637)"/>
            <path d="M90.151 55.7572L55.3672 107.737L71.8627 65.5319L90.151 55.7572Z"
                  fill="url(#paint2_linear_1363_62637)"/>
            <path
                d="M47.6167 56.2232C50.462 56.2232 52.7686 53.9166 52.7686 51.0713C52.7686 48.226 50.462 45.9194 47.6167 45.9194C44.7714 45.9194 42.4648 48.226 42.4648 51.0713C42.4648 53.9166 44.7714 56.2232 47.6167 56.2232Z"
                fill="white"/>
            <path
                d="M50.8473 54.3962C52.6313 54.3962 54.0774 52.95 54.0774 51.166C54.0774 49.3821 52.6313 47.9359 50.8473 47.9359C49.0634 47.9359 47.6172 49.3821 47.6172 51.166C47.6172 52.95 49.0634 54.3962 50.8473 54.3962Z"
                fill="#122236"/>
            <path
                d="M62.3589 56.2232C65.2042 56.2232 67.5108 53.9166 67.5108 51.0713C67.5108 48.226 65.2042 45.9194 62.3589 45.9194C59.5136 45.9194 57.207 48.226 57.207 51.0713C57.207 53.9166 59.5136 56.2232 62.3589 56.2232Z"
                fill="white"/>
            <path
                d="M65.5895 54.3962C67.3735 54.3962 68.8196 52.95 68.8196 51.166C68.8196 49.3821 67.3735 47.9359 65.5895 47.9359C63.8056 47.9359 62.3594 49.3821 62.3594 51.166C62.3594 52.95 63.8056 54.3962 65.5895 54.3962Z"
                fill="#122236"/>
            <path
                d="M34.8129 39.9909C34.9866 35.5656 40.8678 32.246 44.1611 35.5156C42.3709 35.4156 40.4702 35.5393 38.9091 36.5002C37.3164 37.3242 36.1107 38.7194 34.9472 40.1015C34.8708 40.1805 34.776 40.0778 34.8129 39.9883V39.9909Z"
                fill="#122236"/>
            <path
                d="M65.8294 35.4076C69.3386 32.367 75.2644 35.8446 75.0617 40.1699C73.8271 38.8562 72.6687 37.3478 71.0313 36.5396C69.4913 35.6287 67.6353 35.3944 65.8505 35.5813C65.7425 35.5866 65.7399 35.4471 65.8294 35.4076Z"
                fill="#122236"/>
            <path
                d="M102.299 12.7943L101.825 15.8559H106.556V118.941H3.45228V15.8559H7.58537L8.05923 12.7943H0.390625V122H109.612V12.7943H102.296H102.299Z"
                fill="#122236"/>
            <path
                d="M19.8924 1.09521L22.904 10.8014L29.3748 1.09521H34.6926L31.1176 21.3526H26.0262L28.0533 9.93003L23.7201 16.7404H20.0346L18.1207 9.93003L16.0963 21.3526H11.0312L14.6036 1.09521H19.8924Z"
                fill="#122236"/>
            <path
                d="M48.3928 17.0221C48.3928 17.5092 48.6402 17.754 49.1378 17.754C49.6353 17.754 50.0513 17.6592 50.3909 17.4697L50.2487 20.6498C49.3484 21.3816 48.3349 21.7476 47.2108 21.7476C45.1468 21.7476 44.0043 20.9973 43.7779 19.4967C42.8407 20.9973 41.4613 21.7476 39.6422 21.7476C36.5674 21.7476 35.0273 20.426 35.0273 17.7803C35.0273 17.3301 35.0747 16.8326 35.1669 16.2877L35.7592 12.997C36.0593 11.3069 36.7464 9.96165 37.8257 8.95865C38.9051 7.95828 40.3109 7.45547 42.0457 7.45547C43.7806 7.45547 44.9573 7.85825 45.5759 8.66644L45.7155 7.82139H49.9907L48.4165 16.6852C48.398 16.7984 48.3875 16.9089 48.3875 17.0221H48.3928ZM40.0634 15.6716C40.0265 15.8428 40.0081 16.0744 40.0081 16.3745C40.0081 16.6746 40.1476 16.9721 40.4293 17.2617C40.711 17.5513 41.1849 17.6987 41.8509 17.6987C42.5169 17.6987 43.0935 17.4354 43.5805 16.9089L44.4255 12.1835C44.2202 11.7149 43.7332 11.4806 42.9618 11.4806C41.5192 11.4806 40.6741 12.173 40.4293 13.5603L40.0634 15.6716Z"
                fill="#122236"/>
            <path
                d="M65.6104 17.7539C66.0422 17.7539 66.4265 17.6591 66.7635 17.4696L66.624 20.6497C65.7421 21.3815 64.6653 21.7474 63.3886 21.7474C62.1118 21.7474 61.1693 21.4105 60.5612 20.7339C59.9505 20.0574 59.4766 18.9254 59.1396 17.33L59.0554 16.9378C58.6605 16.9904 58.0708 17.0405 57.2837 17.0773L56.5255 21.3526H51.7422L55.512 0H60.2953L58.6631 9.25603C59.7688 8.03979 61.2694 7.42904 63.1648 7.42904C64.4389 7.42904 65.384 7.74231 65.9921 8.37149C66.6029 8.99804 66.9056 9.84045 66.9056 10.8882C66.9056 12.9153 65.8447 14.5001 63.7255 15.6452L64.0362 16.6851C64.2415 17.3959 64.768 17.7565 65.6104 17.7565V17.7539ZM60.7429 11.2541C59.7293 11.2541 58.829 11.7332 58.0419 12.6862L57.7312 14.5448C60.5059 14.4316 61.8959 13.6892 61.8959 12.3203C61.8959 12.0044 61.7985 11.7438 61.6011 11.5463C61.4036 11.3489 61.1193 11.2541 60.7429 11.2541Z"
                fill="#122236"/>
            <path
                d="M74.7545 21.7475C70.8531 21.7475 68.9023 20.397 68.9023 17.696C68.9023 17.3196 68.9392 16.9168 69.0155 16.4851L69.6079 12.997C69.9264 11.1963 70.7557 9.8142 72.0983 8.84806C73.4382 7.88192 75.2152 7.39753 77.4292 7.39753C81.199 7.39753 83.0839 8.82437 83.0839 11.6754C83.0839 12.0887 83.047 12.5363 82.9707 13.0233L82.6337 15.024H74.0516L73.9384 15.669C73.9015 15.8927 73.8831 16.1034 73.8831 16.2876C73.8831 17.3196 74.4912 17.8356 75.7101 17.8356C76.1603 17.8356 76.5446 17.696 76.8632 17.4144C77.1817 17.1327 77.3686 16.8036 77.4265 16.4272L82.0967 16.4851C81.478 19.9916 79.0297 21.7475 74.7519 21.7475H74.7545ZM78.132 13.3076C78.2058 12.9864 78.2452 12.6916 78.2452 12.4204C78.2452 12.1493 78.1241 11.8729 77.8793 11.5885C77.6345 11.3069 77.2449 11.1673 76.7131 11.1673C76.1813 11.1673 75.689 11.3463 75.2494 11.7017C74.8098 12.0571 74.5044 12.5915 74.3359 13.3076H78.1347H78.132Z"
                fill="#122236"/>
            <path
                d="M90.2857 21.7475C86.3843 21.7475 84.4336 20.397 84.4336 17.696C84.4336 17.3196 84.4705 16.9168 84.5468 16.4851L85.1391 12.997C85.4577 11.1963 86.2869 9.8142 87.6295 8.84806C88.9695 7.88192 90.7464 7.39753 92.9604 7.39753C96.7302 7.39753 98.6151 8.82437 98.6151 11.6754C98.6151 12.0887 98.5783 12.5363 98.5019 13.0233L98.1649 15.024H89.5829L89.4697 15.669C89.4328 15.8927 89.4144 16.1034 89.4144 16.2876C89.4144 17.3196 90.0225 17.8356 91.2414 17.8356C91.6915 17.8356 92.0759 17.696 92.3944 17.4144C92.713 17.1327 92.8999 16.8036 92.9578 16.4272L97.6279 16.4851C97.0093 19.9916 94.561 21.7475 90.2831 21.7475H90.2857ZM93.6633 13.3076C93.737 12.9864 93.7765 12.6916 93.7765 12.4204C93.7765 12.1493 93.6554 11.8729 93.4106 11.5885C93.1657 11.3069 92.7761 11.1673 92.2444 11.1673C91.7126 11.1673 91.2203 11.3463 90.7807 11.7017C90.341 12.0571 90.0357 12.5915 89.8672 13.3076H93.6659H93.6633Z"
                fill="#122236"/>
            <defs>
                <linearGradient id="paint0_linear_1363_62637" x1="65.4433" y1="116.074" x2="14.9616" y2="39.3039"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B00362"/>
                    <stop offset="0.47" stopColor="#F86767"/>
                    <stop offset="1" stopColor="#F8BD0E"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1363_62637" x1="75.7774" y1="108.927" x2="25.58" y2="32.5881"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B00362"/>
                    <stop offset="0.47" stopColor="#F86767"/>
                    <stop offset="1" stopColor="#F8BD0E"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1363_62637" x1="86.3075" y1="102.353" x2="35.8258" y2="25.583"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B00362"/>
                    <stop offset="0.47" stopColor="#F86767"/>
                    <stop offset="1" stopColor="#F8BD0E"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default makeeDiamondLogin;
