// components
import OtpInput from '../../../../../../components/OtpInput';
import ActionButton from '../../../../../../components/ActionButton';

// react-router-dom
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';

// react
import {useEffect, useState} from 'react';

// context
import {useAuth} from '../../../../../../context/AuthContext';

// style
import './index.css';

// assets
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// redux
import {useSelector} from "react-redux";
import {RootState} from '../../../../../../app/type';
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import SupportFooter from "../../../../../../modules/SupportFooter";

const VerifyCode = () => {

    // la chaine de caractères qui sera envoyée par les inputs
    // elle change à chaque changement de l'input
    const [otp, setOtp] = useState('');

    // fonction de vérification de code
    const {verifyCodeAdmin, verifyCodeParticipant, resendEmail} = useAuth();

    // token
    const {token} = useParams();

    // navigate
    const location = useLocation();
    const navigate = useNavigate();

    // redux state
    const error = useSelector((action: RootState) => action.error.error);
    const errorMessage = useSelector((action: RootState) => action.error.message);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <>
            <section className="verifyCodePage">

                <div className='container'>
                    <MakeeDiamondLogin className='diamondLogin'/>
                    <h1 className='h1-auth'>{t("page.signUpParticipantVerifyCode.welcome")}</h1>
                    <p className='form-text-auth paragraphs'>{t("page.signUpParticipantVerifyCode.receiveInvitation")}<br/>
                        {t("page.signUpParticipantVerifyCode.validateIdentity")}</p>

                    <h2 className='h1 paragraphs'>{t("page.signUpParticipantVerifyCode.enterCode")}</h2>

                    {/* Formulaire de connexion avec email et mot de passe qui appelle la fonction verifyCode() au submit */}

                    {/* otp input on le défini ici à 6 caractères */}
                    <div className="otp-input-container">
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            valueLength={6}
                            {...(error && {errorMessage: errorMessage})}
                        />
                    </div>

                    {/* boutons de validation */}
                    <div className="buttons">
                        {/* <ActionButton
                            auth
                            variant="tertiary"
                            type="button"
                            onClick={() => {
                                resendEmail()
                            }}
                        >
                            Renvoyer le code
                        </ActionButton> */}

                        <ActionButton
                            auth
                            variant="primary"
                            type="submit"
                            onClick={() => {
                                (location.pathname.includes('admin') ?
                                    verifyCodeAdmin(otp, token)
                                    :
                                    location.pathname.includes('participant') ?
                                        (verifyCodeParticipant(otp, token))
                                        // : location.pathname.includes('participant') ?
                                        //     (verifyCodeParticipant(otp, token))
                                        :
                                        (console.log('error')))
                            }
                            }
                        >
                            {t("page.signUpParticipantVerifyCode.buttonValidateText")}
                        </ActionButton>
                    </div>

                    {/* lien vers une page de changement de mail de récupération */}
                    {/* <Link to="/" className='link'>
                        Je n'ai plus accès à cette adresse mail
                    </Link> */}

                </div>

            </section>
            <SupportFooter />
        </>
    )
}

export default VerifyCode;
