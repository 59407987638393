import React, {useEffect, useState} from 'react';
import './index.css';
import {Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme} from '@mui/material';

import {ReactComponent as IconReturn} from '../../../../../assets/icon-return.svg';
import {RootState} from 'src/app/type';
import {useSelector} from 'react-redux';
import FaqCard from '../../../../../modules/FaqCard';
import {IChallenge} from "../../../Global/Challenge/types";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";


const ChallengeFaq = () => {
    const getOneChallenge = useSelector((state: RootState) => state.challenges.getOneChallenge)
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const theme = useTheme();

    console.log("faq preview", getOneChallenge);
    const isbetweenXsAndMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isbetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const ismorelg = useMediaQuery(theme.breakpoints.up('lg'));
    const [challenge, setChallenge] = useState<IChallenge | null>(null);
    const challengeId = useParams().id;
    const getChallengeById = () => {
        api.get('/challenge/preview', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                console.log(res.data);
                setChallenge(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        getChallengeById();
    }, []);

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    return (
        <section id="section-faq">
            <div id="bloc-faq">
                <div
                    className={`content-faq m-auto ${isbetweenXsAndMd ? "w-100" : ""} ${isbetweenMdAndLg ? "w-75" : ""} ${ismorelg ? "w-50" : ""}`}>
                    <h2 className='mb-4 text-center'>{t("page.challenge.FAQ.FAQ")}</h2>
                    {challenge && challenge.challenge.itemFAQs && challenge.challenge.itemFAQs.map((question, index: number) => <FaqCard question={question} key={index}/>
                            
                        
                    )}
                </div>
            </div>
        </section>
    );
}

export default ChallengeFaq;