import {
    Alert,
    Avatar,
    Box,
    Button,
    Chip,
    Grid,
    Snackbar,
    Stack,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";

import {ChangeEvent, useEffect, useState} from "react";
import {ReactComponent as IconCheck} from '../../../../../assets/icon-check.svg';
import {ReactComponent as IconWait} from '../../../../../assets/icon-wait.svg';
import {ReactComponent as IconChange} from '../../../../../assets/icon-change.svg';

import {ReactComponent as Icontrash} from '../../../../../assets/icon-trash.svg';
import {ReactComponent as IconAdd} from '../../../../../assets/icon-add.svg';
import {ReactComponent as IconWinner} from '../../../../../assets/icon-winner.svg';

import "./index.css";
import ActionButton from "../../../../../components/ActionButton";
import Modal from "../../../../../modules/Modal";
import {isEmail} from "../../../../../utils/functions";
import api from "../../../../../utils/api";

interface Role {
    name: string;
}

interface User {
    code: string

    email: string

    firstname: string

    lastname: string
}

interface Profile {
    email: string;

    active: boolean;

    status: string;

    user: User;

    role: Role;

    // Autres propriétés de profil si nécessaire
}

interface Establishment {
    profiles: Profile[];

    // Autres propriétés de l'établissement si nécessaire
}

const AdminConfigUser = () => {
    const [admin, setAdmin] = useState<Establishment | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackBarText, setSnackBarText] = useState("");
    const [newAdmin, setNewAdmin] = useState({
        lastname: '',
        firstname: '',
        email: ''
    });
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [error, setError] = useState({error: '', message: ''});

    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [establishmentName, setEstablishmentName] = useState<string>("");
    const fetchAdminEstablishment = async () => {
        try {
            const response = await api.get("/admin/list_admin",
                {
                    withCredentials: true
                }
            );
            const data: Establishment = response.data.establishment;
            setAdmin(data);
            await api.get("/admin/info_establishment",
                {
                    withCredentials: true
                }
            ).then((res) => {
                setEstablishmentName(res.data.establishment.name);
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error);
        }
    };

    const handleOnBlur = () => {
        if (!isEmail(newAdmin.email)) {
            setEmailError(true);
            setEmailErrorMessage('Adresse e-mail invalide');
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }
    }

    const createAdmin = async () => {
        if (isEmail(newAdmin.email)) {
            await api.post("/admin/signup_admin",
                {
                    language: localStorage.getItem("translation"),
                    newAdmin
                }, {withCredentials: true})
                .then((data) => {
                    setNewAdmin({
                        lastname: '',
                        firstname: '',
                        email: ''
                    });
                    setError({error: '', message: ''});
                    setOpenModal(false);
                    setOpenSnackbar(true);
                    setSnackBarText("Invitation envoyée !")
                    fetchAdminEstablishment();
                })
                .catch((err) => {
                    setError({
                        error: (err.response.data.error),
                        message: (err.response.data.message)
                    });
                })
        } else {
            setEmailError(true);
            setEmailErrorMessage('Adresse e-mail invalide');
        }

    }
    const [errorDeleteAdmin, setErrorDeleteAdmin] = useState<string>("");
    const [succesMessageDeleteAdmin, setSuccesMessageDeleteAdmin] = useState<string>("");
    const [openSnackbarDeleteAdmin, setOpenSnackbarDeleteAdmin] = useState<boolean>(false);

    const deleteAdmin = async (email: string) => {
        await api.delete(`/admin/delete_admin?email=${email}&establishment_name=${establishmentName}`,
            {
                withCredentials: true,
            },
        )
            .then((data) => {
                setOpenModalDelete(false);
                setOpenSnackbarDeleteAdmin(true);
                setSuccesMessageDeleteAdmin(data.data.message);
                fetchAdminEstablishment();
            })
            .catch((err) => {
                console.log(err);
                setErrorDeleteAdmin(err.response.data.message);
            });
    }

    useEffect(() => {
        fetchAdminEstablishment();
    }, []);

    useEffect(() => {
        console.log(admin);
    }, [admin]);

    const handleChangeEmail = (e: any) => {
        setNewAdmin({
            ...newAdmin,
            email: e.target.value
        })

        if (isEmail(newAdmin.email)) {
            setEmailError(false);
            setEmailErrorMessage('');
        }
    }

    const handleChangeFirstname = (e: any) => {
        setNewAdmin({
            ...newAdmin,
            lastname: e.target.value
        })
    }
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
    const [selectedAdminIndex, setSelectedAdminIndex] = useState<number>();

    const resendCode = (email: string) => {
        api.post('/admin/resend_code_signup_admin', {
                language: localStorage.getItem("translation"),
                email: email,
            },
            {
                withCredentials: true
            })
            .then(() => {
                setOpenSnackbar(true);
                setSnackBarText("L'invitation a bien été renvoyée !")
            })
            .catch((err: any) => {
                console.log(err.message);
            })
    }

    return (
        <div className="container-admin-users">
            <div className={"container-add-admin"}>
                {
                    admin && (
                        <h4 className={""}>
                            {admin.profiles.length} {admin.profiles.length === 1 ? "Administrateur" : "Administrateurs"}
                        </h4>
                    )
                }
                <div className={"button"}>
                    <ActionButton
                        variant="primary"
                        icon={<IconAdd className='edit-icon'/>}
                        onClick={() => setOpenModal(true)}
                    >
                        Ajouter un administrateur
                    </ActionButton>
                </div>
            </div>
            <div className={"container-choice-admin"}>
                <div className={"block-description"}>
                    <div className={"icon"}>
                        <IconWinner className={"red"}/>
                    </div>
                    <div className={"description"}>
                        <p>Vous êtes l’Admin Principal de cet établissement.</p>
                        <p>Vous seul peut ajouter ou supprimer un administrateur.</p>
                    </div>
                </div>
                <div className={"block-button"}>
                    <ActionButton
                        variant="tertiary"
                        icon={<IconWinner className=''/>}
                    >
                        Redéfinir l'Admin Principal
                    </ActionButton>
                </div>
            </div>
            <div className={"container-list-admin"}>
                {admin && admin.profiles.map((profile, index) => (
                    <div className="item-admin" key={index}>
                        <div className={"block-details-user"}>
                            {!isXsDown && (
                                <Stack direction="row" spacing={1} className="d-block mb-3">
                                    <Chip
                                        className={profile.status.toString()}
                                        icon={profile.status.toString() === "active" ?
                                            <IconCheck/> : profile.status.toString() === "pending" ?
                                                <IconWait/> : <></>}
                                        label={profile.status.toString() === "active" ? "Invitation validée" : profile.status.toString() === "pending" ? "Invitation en attente" : "Invitation expirée"}
                                    />
                                </Stack>
                            )}
                            <h3 className="my-2 my-0 p-0 overflow-hidden text-truncate text-nowrap identity">{profile.user.firstname + " " + profile.user.lastname}</h3>
                            {/*<p className=" my-2 overflow-hidden text-truncate text-nowrap">{profile.role.name === "admin" ? "administrateur" : ""}</p>*/}
                            <p className=" my-2 overflow-hidden text-truncate text-nowrap">{profile.email}</p>
                        </div>
                        {
                            isMobile && (
                                <div className={"br"}></div>
                            )
                        }
                        <div className={"block-right"}>
                            {isXsDown && (
                                <Stack direction="row" spacing={1} className="block-chip">
                                    <Chip
                                        className={".chip-status " + profile.status.toString()}
                                        icon={profile.status.toString() === "active" ?
                                            <IconCheck/> : profile.status.toString() === "pending" ?
                                                <IconWait/> : <></>}
                                        label={profile.status.toString() === "active" ? "invitation validée" : profile.status.toString() === "pending" ? "invitation en attente" : "invitation expirée"}
                                    />
                                </Stack>
                            )}
                            {
                                !isMobile && (
                                    <div className={"isolation-white"}></div>
                                )
                            }
                            <div className={"block-icons"}>
                                <div>
                                    {profile.status.toString() !== "active" && (
                                        <Button onClick={() => resendCode(profile.email)}>
                                            <div className="block-button-list-admin">
                                                <IconChange/>
                                                <span>Renvoyer</span>
                                            </div>
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        onClick={() => {
                                            setOpenModalDelete(true);
                                            setSelectedAdminIndex(index);
                                        }}
                                        className={""}
                                    >
                                        <div className="block-button-list-admin">
                                            <Icontrash/>
                                            <span>Supprimer</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                            <Modal
                                open={openModalDelete && selectedAdminIndex === index}
                                onClose={() => {
                                    setOpenModalDelete(false);
                                    setErrorDeleteAdmin("");
                                }}
                                title={
                                    <>
                                        Supprimer{" "}
                                        <i>{`"${profile.user.firstname} ${profile.user.lastname}"`}</i>
                                        {" "}des administrateurs de votre établissement ?
                                    </>
                                }
                                info={true}
                                primaryButtonText={"Supprimer"}
                                primaryButtonAction={() => {
                                    deleteAdmin(profile.email);
                                    setErrorDeleteAdmin("");
                                }}
                                tertiaryButtonText={"Annuler"}
                                tertiaryButtonAction={() => {
                                    setOpenModalDelete(false);
                                    setSelectedAdminIndex(0);
                                    setErrorDeleteAdmin("");
                                }}
                                icon={<Icontrash/>}
                                iconColor={"red"}
                            >
                                <p>L'utilisateur ne pourra plus accéder aux fonctions liés à votre
                                    établissement. S'il a créé des challenges, ils seront archivés.</p>
                                {
                                    errorDeleteAdmin && (
                                        <p className={"error-message"}>{errorDeleteAdmin}</p>
                                    )
                                }
                            </Modal>
                        </div>
                    </div>
                ))}
            </div>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={() => {
                    setOpenSnackbar(false);
                    setSnackBarText("")
                }}>
                <Alert severity="success">
                    {snackBarText}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openSnackbarDeleteAdmin}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbarDeleteAdmin(false)}>
                <Alert severity="success">
                    {succesMessageDeleteAdmin}
                </Alert>
            </Snackbar>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={'Ajouter un administrateur'}
                tertiaryButtonText={'Annuler'}
                tertiaryButtonAction={() => setOpenModal(false)}
                primaryButtonText={'Ajouter'}
                primaryButtonAction={() => createAdmin()}
            >
                <TextField
                    id="outlined-basic"
                    label="Nom de l'administrateur"
                    placeholder="Nom"
                    variant="outlined"
                    value={newAdmin.lastname}
                    onChange={(e) => setNewAdmin({...newAdmin, lastname: e.target.value})}
                    required
                    error={error && error.error === "emptyLastname"}
                    helperText={error && error.error === "emptyLastname" ? error.message : ''}
                    InputLabelProps={{
                        style: {
                            color: '#a9a9a9',
                            fontWeight: '400'
                        },
                    }}
                    sx={{
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-root': {
                            background: "#fff",
                            borderRadius: '10px',
                            "&.Mui-focused": {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                    border: 'solid 1px'
                                }
                            }
                        }
                    }}/>
                <TextField
                    id="outlined-basic"
                    label="Prénom"
                    placeholder="Prénom"
                    value={newAdmin.firstname}
                    variant="outlined"
                    onChange={(e) => setNewAdmin({...newAdmin, firstname: e.target.value})}
                    required
                    error={error && error.error === "emptyFirstname"}
                    helperText={error && error.error === "emptyFirstname" ? error.message : ''}
                    InputLabelProps={{
                        style: {
                            color: '#a9a9a9',
                            fontWeight: '400'
                        },
                    }}
                    sx={{
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-root': {
                            background: "#fff",
                            borderRadius: '10px',
                            "&.Mui-focused": {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                    border: 'solid 1px'
                                }
                            }
                        }
                    }}/>
                <TextField
                    id="outlined-basic"
                    label="Adresse e-mail"
                    placeholder="nomprenom@mail.com"
                    value={newAdmin.email}
                    variant="outlined"
                    onBlur={() => handleOnBlur()}
                    onChange={handleChangeEmail}
                    required
                    error={emailError || (error && (error.error === "emailAlreadyExists" || error.error === "emptyEmail"))}
                    helperText={(emailError ? emailErrorMessage : '') || (error.error === "emailAlreadyExists") ? error.message : ''}
                    InputLabelProps={{
                        style: {
                            color: '#a9a9a9',
                            fontWeight: '400'
                        },
                    }}
                    sx={{
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-root': {
                            background: "#fff",
                            borderRadius: '10px',
                            "&.Mui-focused": {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                    border: 'solid 1px'
                                }
                            }
                        }
                    }}/>
            </Modal>
        </div>
    );
};

export default AdminConfigUser;
