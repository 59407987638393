// style
import './index.css'

// types
import { IResource } from 'src/utils/types';

// context
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge';

// components
import ActionButton from '../ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

// uuid
import { v4 as uuidv4 } from 'uuid';
import Resource from '../Resource';


interface ResourceProps {
    resources: IResource[];
    setResources: React.Dispatch<React.SetStateAction<IResource[]>>;
    resourcesToDelete: IResource[];
    setResourcesToDelete: React.Dispatch<React.SetStateAction<IResource[]>>;
    errorResource: any[] | [];
    setErrorResource: React.Dispatch<React.SetStateAction<any[] | []>>;
    // last: boolean
    // first: boolean
    // index: number,
}

const StepList = ({resources,setResources,resourcesToDelete,setResourcesToDelete,errorResource, setErrorResource} :ResourceProps) => {
    const { setChange } = useAdminEditChallengeContext();
     const {id} = useAdminEditChallengeContext();

    useEffect(() => {
    }, [resources]);

    const handleUpdateResource = (resourceToUpdate: IResource) => {
        console.log("errorResource  resourceToUpdate", resourceToUpdate);
        //Si je modifie le nom d'un ressource alors retiré l'erreur qui correspond au meme id dans errorResource, retiré uniquement l'erreur lié au code= "name"
        if (resourceToUpdate.name !== undefined && resourceToUpdate.name !== null && resourceToUpdate.name !== "") {
            setErrorResource((errorResource) => errorResource.filter((error) => String(error.id) !== String(resourceToUpdate.id) || error.code !== "name"));
        }
        
        //Si je modifie le lien d'un ressource alors retiré l'erreur qui correspond au meme id dans errorResource, retiré uniquement l'erreur lié au code= "link"
        if (resourceToUpdate.link !== undefined && resourceToUpdate.link !== null && resourceToUpdate.link !== "") {
            setErrorResource((errorResource) => errorResource.filter((error) => String(error.id) !== String(resourceToUpdate.id) || error.code !== "link"));
        }

        //Si je modifie le fichier d'un ressource alors retiré l'erreur qui correspond au meme id dans errorResource, retiré uniquement l'erreur lié au code= "file"
        if (resourceToUpdate.file !== undefined && resourceToUpdate.file !== null && resourceToUpdate.file !== "") {
            setErrorResource((errorResource) => errorResource.filter((error) => String(error.id) !== String(resourceToUpdate.id) || error.code !== "file"));
        }
        
        setResources((resources) => resources.map((resource) => {
            if (resource.id === resourceToUpdate.id) {                
                if (resourceToUpdate.id !== undefined && resourceToUpdate.id !== null) {
                    resourceToUpdate.isModified = true;
                } else {
                    resourceToUpdate.isModified = false;
                }
                return resourceToUpdate;
            } else {
                return resource;
            }
        }))
    }


    const handleAddResource = () => {
        setChange(true);
        setResources((resources) => [...resources, {
            id: "new-"+uuidv4(),
            challengeId: id,
            name: '',
            link: null,
            file: undefined,
            isModified: true
        }])
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        })
    }

    const handleDeleteResource = (resourceToDelete: IResource) => {     
        setChange(true);
        if (resourceToDelete.id !== null && resourceToDelete.id !== "string" && resourceToDelete.id !==undefined && resourceToDelete.id.toString().startsWith("new-")) {
            setResources((resources) => resources.filter((resource) => resource.id !== resourceToDelete.id));
        } else {
            setResourcesToDelete((resourcesToDelete: any) => [...resourcesToDelete, resourceToDelete]);
            setResources((resources) => resources.filter((resource) => resource.id !== resourceToDelete.id));
        }
    }
        
    return (
        <div className='resource-list mb-5'>
            <Grid container  alignContent={"center"} justifyContent={"center"}>
                <Grid item xs={10} sm={8} md={6}>
                    <div className='m-auto'>
                    <ActionButton
                        variant='primary'
                        className='resources-add-button'
                        onClick={handleAddResource}
                        icon={<IconAdd/>}
                    >
                        Ajouter une ressource
                    </ActionButton>
                </div>
                </Grid>
            </Grid>

            {/* Responsive desktop = 2 grid and mobile 1 grid */}
            <Grid container spacing={2} justifyContent={"center"}>
                {resources.map((resource, index) => {
                    const largeValue = resources.length === 1 ? 12 : 6;
                    return (
                        <Grid item xs={12} sm={12} md={12} lg={largeValue} xl={largeValue} style={{maxWidth:'500px'}} key={index}>
                            <Resource 
                                errorResource={errorResource}
                                setErrorResource={setErrorResource}
                                resource={resource}
                                setResources={setResources} 
                                resourcesToDelete={resourcesToDelete}
                                setResourcesToDelete={setResourcesToDelete}
                                handleUpdateResource={handleUpdateResource}
                                handleDeleteResource={handleDeleteResource}
                                index={index}
                                first={index === 0}
                                last={index === resources.length - 1}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            {resources.length !== 0 &&
                <Grid container  alignContent={"center"} justifyContent={"center"}>
                    <Grid item xs={10} sm={8} md={6}>
                        <div className='m-auto'>
                        <ActionButton
                            variant='primary'
                            className='resources-add-button'
                            onClick={handleAddResource}
                            icon={<IconAdd/>}
                        >
                            Ajouter une ressource
                        </ActionButton>
                    </div>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

export default StepList;