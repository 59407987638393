import {useEffect, useRef, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

// components
import NavTabs from "../../../../modules/NavTabs";
import MenuTabs from "../../../../modules/MenuTabs";
import Modal from "../../../../modules/Modal";
import ActionButton from '../../../../components/ActionButton';
import SchoolCard from '../../../../components/SchoolCard';

// assets
import {ReactComponent as IconCross} from '../../../../assets/icon-cross.svg';
import {ReactComponent as IconPen} from '../../../../assets/icon-pen.svg';
import {DEFAULT_ESTABLISHMENT_IMAGE} from '../../../../utils/consts';
import {isPlural, openModal, closeModal} from '../../../../utils/functions';
import {Box, FormControl, TextField, useMediaQuery, useTheme} from "@mui/material";
import {useAuth} from '../../../../context/AuthContext';
import ImageUploader from '../../../../modules/ImageUploader/ImageUploader';

// style
import './index.css';
import api from "../../../../utils/api";

export interface IEstablishmentDetails {
    id: number;

    name: string;

    establishmentPicture: string;

    establishmentType: string;

    created_at: string;

    adminCount: number;

    nbChallenges: number;

    nbMaxChallenges: number;
}

export const fetchEstablishmentDetails = async (setEstablishmentDetails: (data: any) => void) => {
    await api.get("/admin/info_establishment",
        {
            withCredentials: true
        }
    ).then((res) => {
        console.log(res.data)
        const maxChallenges = Math.round(Math.random() * 10);
        setEstablishmentDetails({
            id: res.data.establishment.id,
            name: res.data.establishment.name,
            establishmentPicture: res.data.establishment.establishmentPicture,
            establishmentType: res.data.establishment.establishmentType,
            adminCount: res.data.establishment.adminCount,
            created_at: new Date(res.data.establishment.createdAt).toLocaleDateString(),
            nbChallenges: Math.round(Math.random() * maxChallenges),
            nbMaxChallenges: maxChallenges
        })
    })
}

export const outletNavbarLinks = [
    {
        label: 'Général',
        key: 'general',
        link: '/admin/school/config/general'
    },
    {
        label: 'Administrateurs',
        key: 'user',
        link: '/admin/school/config/user'
    },
    // {
    //     label: 'Challenges',
    //     key: 'challenges',
    //     link: '/admin/school/config/challenges'
    // }
];

const AdminConfig = () => {

    useEffect(() => {
        fetchEstablishmentDetails(setEstablishmentDetails);
    }, [])

    const [establishmentDetails, setEstablishmentDetails] = useState({
        id: 0,
        name: "Chargement...",
        establishmentPicture: DEFAULT_ESTABLISHMENT_IMAGE,
        establishmentType: "school",
        adminCount: 0,
        created_at: new Date().toLocaleDateString(),
        nbChallenges: 3,
        nbMaxChallenges: 8
    } as IEstablishmentDetails);

    const {errorMessage} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/admin/school/config') navigate('/admin/school/config/general');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [imagePreview, setImagePreview] = useState<string>('');
    const [fileToUpload, setFileToUpload] = useState<File>();
    const [fileToDelete, setFileToDelete] = useState<string | null>(null);

    //UseState de la modale
    const [isOpenModal, setIsOpenModal] = useState(false);

    //UseState pour les forms (nouveau nom établissement, ..)
    const [newEstablishmentName, setNewEstablishmentName] = useState(establishmentDetails.name);

    const submitButton = useRef<HTMLInputElement>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const updateEstablishment = (fileToUpload: File | undefined, defaultEstablishmentName: string | undefined, setEstablishmentDetails: (data: any) => void, establishmentDetails: IEstablishmentDetails) => {        
        const formData = new FormData();
        if (fileToUpload) {
            formData.append('file', fileToUpload);
        }
        formData.append('establishmentId', String(establishmentDetails.id));
        formData.append('establishment_name', defaultEstablishmentName as string);
        formData.append('newname', newEstablishmentName);
        console.log("id :", establishmentDetails.id)

        if (fileToDelete) {
            formData.append('fileToDelete', fileToDelete);
        }

        api.put("/admin/update_establishment",
            formData,
            {
                withCredentials: true
            }
        ).then((res) => {
            console.log("LA RÉPONSE" ,res);
            setEstablishmentDetails({
                ...establishmentDetails,
                name: newEstablishmentName,
                establishmentPicture: res.data.publicUrl ? res.data.publicUrl : establishmentDetails.establishmentPicture,
            })
            setIsOpenModal(false);

            setFileToDelete(null);
            setFileToUpload(undefined)
        }
        ).catch((err) => {
            console.log(err);
        })
    }

    /* Fonction ayant pour but d'ouvrir la modale
    * Charge les données originelles de l'établissement par défaut puis ouvre la modale
    * Est utile pour être envoyée dans les outlet afin d'ouvrir la modale depuis une sous-page*/
    const openModal = () => {
        setIsOpenModal(true);
        setNewEstablishmentName(establishmentDetails.name);
        setImagePreview(establishmentDetails.establishmentPicture)
    }

    const handleOnSubmitButtonClicked = () => {
        submitButton.current?.click();
    }

    function handleDeleteLogo() {
        if (establishmentDetails.establishmentPicture.includes("makee_bucket")) {
            setFileToDelete(establishmentDetails.establishmentPicture);
        }
    }

    return (
        <>
            <section className="establishment-config-global">
                {/* {!isMobile && ( //Ancien code qui pourrait ressortir si ce que je fais est une catastrophe
                    <div className="establishment-info">
                        <div className="p-4">
                            <ActionButton icon={<IconPen className='edit-icon'/>}type='submit' variant='primary' className='admin-config-edit-button'
                                        onClick={() => openModal('modal-update-establishment-admin')}>
                                Modifier les informations
                            </ActionButton>
                        </div>
                        <SchoolCard
                            variant='grey'
                            img_url={establishmentDetails.establishmentPicture}
                            establishmentType={
                                establishmentDetails.establishmentType === "school"
                                    ? "Établissements"
                                    : "Entreprise"
                            }
                        >
                            <h1>{establishmentDetails.name}</h1>
                        </SchoolCard>
                        <dialog id='modal-update-establishment-admin'>
                            <div className="modal-update-establishment-admin-header">
                                <h1>Configuration de l'établissement</h1>
                                <IconCross className='close-modal-cross'
                                           onClick={() => closeModal('modal-update-establishment-admin')}/>
                            </div>
                            <FormControl
                                onSubmit={(e) => { updateEstablishment(e, fileToUpload, establishmentDetails.name, setEstablishmentDetails, establishmentDetails) }}>
                                <Box component="form" noValidate autoComplete='off'
                                     className='form-content-update-establishment-admin'>
                                    <TextField
                                        {
                                            ...(
                                                errorMessage && {
                                                    error: true,
                                                    helperText: errorMessage
                                                })
                                        }
                                        id='name'
                                        label='Nom de l’établissement'
                                        variant='outlined'
                                        placeholder='Nom de l’établissement'
                                        type='text'
                                        className='form-text-auth input-text-field'
                                        defaultValue={establishmentDetails.name}
                                        key={establishmentDetails.name}
                                    />
                                    <div className="image-input-update-establishment">
                                        <p className="form-title">Logo de l'établissement</p>
                                        <ImageUploader
                                            image={establishmentDetails.establishmentPicture}
                                            defaultImage={"default"}
                                            imagePreview={imagePreview}
                                            setImagePreview={setImagePreview}
                                            setFileToUpload={setFileToUpload}
                                            handleDelete={handleDeleteLogo}
                                            ratio="1/1"
                                        />
                                    </div>
                                    <input type="submit" style={{display: 'none'}} ref={submitButton}/>
                                </Box>
                            </FormControl>
                            <ActionButton type='submit' variant='primary' className='admin-config-edit-confirm-button'
                                          onClick={handleOnSubmitButtonClicked}>
                                Modifier
                            </ActionButton>
                        </dialog>
                    </div>
                )} */}
                <div className="establishment-config-card">
                    <SchoolCard
                        variant={isMobile ? 'white' : 'grey'}
                        img_url={establishmentDetails.establishmentPicture}
                        establishmentType={
                            establishmentDetails.establishmentType === "school"
                                ? "Établissement"
                                : "Entreprise"
                        }
                    >
                        <h1>{establishmentDetails.name}</h1>
                    </SchoolCard>
                    <div className="establishment-config-card-btn">
                        <ActionButton icon={<IconPen className='edit-icon'/>}type='submit' variant='primary' className='admin-config-edit-button'
                            onClick={() => {
                                openModal();
                            }
                        }>
                            Modifier les informations
                        </ActionButton>
                    </div>
                </div>

                { 
                    /* NavBar qui varie selon la version Desktop ou mobile */
                    !isMobile 
                    ? <MenuTabs links={outletNavbarLinks} variant={"menu-tabs-primary"}/> 
                    : <NavTabs links={outletNavbarLinks}/>
                }

                <Modal
                    onClose={() => setIsOpenModal(false)} 
                    open={isOpenModal} 
                    title="Configuration de l'établissement" 
                    primaryButtonText="Enregistrer" 
                    tertiaryButtonText="Annuler" 
                    primaryButtonAction={() => updateEstablishment(fileToUpload, establishmentDetails.name, setEstablishmentDetails, establishmentDetails)} 
                    tertiaryButtonAction={() => { /*On reset les données et on ferme la modale quand on clique sur "annuler"*/
                        setIsOpenModal(false)
                    }}
                >
                    <FormControl
                        onSubmit={() => { updateEstablishment(fileToUpload, establishmentDetails.name, setEstablishmentDetails, establishmentDetails) }}>
                        <Box component="form" noValidate autoComplete='off'
                                className='form-content-update-establishment-admin'>
                            <TextField
                                {
                                    ...(
                                        errorMessage && {
                                            error: true,
                                            helperText: errorMessage
                                        })
                                }
                                id='name'
                                label='Nom de l’établissement'
                                variant='outlined'
                                placeholder='Nom de l’établissement'
                                type='text'
                                className='form-text-auth input-text-field'
                                defaultValue={establishmentDetails.name}
                                key={establishmentDetails.name}
                                onChange={(e) => setNewEstablishmentName(e.target.value)}
                                value={newEstablishmentName}
                                inputProps={{ maxLength: 50 }}
                            />
                            <div className="image-input-update-establishment">
                                <p className="form-title">Logo de l'établissement</p>
                                <ImageUploader
                                    image={establishmentDetails.establishmentPicture}
                                    defaultImage={"default"}
                                    imagePreview={imagePreview}
                                    setImagePreview={setImagePreview}
                                    setFileToUpload={setFileToUpload}
                                    handleDelete={handleDeleteLogo}
                                    ratio="1/1"
                                />
                            </div>
                            <input type="submit" style={{display: 'none'}} ref={submitButton}/>
                        </Box>
                    </FormControl>
                </Modal>
                <Outlet/>
            </section>
        </>
    );
}

export default AdminConfig;