import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";

function CookieConsentModal() {

  return (
    <>
      <CookieConsent
        location="bottom"
        sameSite="strict"
        cookieSecurity={true}
        cookieName="cookie-consent"
        expires={150}
        overlay={true}
        style={{ background: "#030140" }}
        buttonText="J'accepte"
        buttonStyle={{
          borderRadius: "10px",
          border: "2px solid #030140",
          background: "#fff",
          color: "#030140",
          fontSize: "13px",
        }}
      >
        Notre site utilise des cookies pour améliorer votre expérience de navigation. En continuant à naviguer sur notre site, vous acceptez notre politique de confidentialité et l'utilisation de cookies.
      </CookieConsent>
    </>
  );
}

export default CookieConsentModal;