// style
import './style.css';

// react
import Button from '../../../components/Button';
import ActionButton from '../../../components/ActionButton';

// hooks
import React, {useState, useRef, useEffect} from 'react'
import {useDebounceEffect} from './useDebounceEffect'

// components & types
import ReactCrop, {Crop} from '..'
import {PixelCrop} from '../types'
import {canvasPreview} from './canvasPreview'
import ProgressBar from '../../../components/ProgressBar';

// utils
import {openModal, closeModal} from '../../../utils/functions';

// image compression
import imageCompression from 'browser-image-compression';

// consts
import {
    DEFAULT_CHALLENGE_PICTURE,
    DEFAULT_CHALLENGE_LOGO,
    DEFAULT_ESTABLISHMENT_IMAGE,
    DEFAULT_MAIN_PROJECT, DEFAULT_PROFILE_PICTURE,
    DEFAULT_UPLOAD_PICTURE, DEFAULT_PRIZE_PICTURE,
} from '../../../utils/consts';

// icons
import {ReactComponent as IconAdd} from '../../../assets/icon-add.svg';
import {Grid} from '@mui/material';
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../components/SetUpTranslation";

/**
 * Interface représentant les props du composant ImageUploader.
 */
interface ImageUploaderProps {
    image: string;
    imagePreview: string;
    setImagePreview: React.Dispatch<React.SetStateAction<string>>;
    setFileToUpload: React.Dispatch<React.SetStateAction<File | undefined>>;
    rectangle?: boolean;
    noImageText?: string;
    id?: number | string;
    imgRef?: React.RefObject<HTMLImageElement>;
    noDeletion?: boolean;
    noImageButtonText?: string;
    hasClickedOnCancelButton?: boolean;
    setChange?: (change: boolean) => void;
    truc?: boolean;
    setTruc?: (truc: boolean) => void;
    // la fonction qui doit s'executer lorsque l'on clique sur "supprimer"
    handleDelete?: () => void;
    ratio: string;
    defaultImage: "image-prize" | "image-profile-picture" | "image-challenge-banner" | "image-challenge-logo" | "image-establishment" | "image-project" | "default";
    disabled?: boolean;
    width?: string;
    height?: string;
}


/**
 *
 * Composant qui permet de gérer l'upload d'une image.
 *
 * ---
 *
 * Le composant suivant est utilisé :
 * - Button
 *
 * ---
 *
 * @param {string} image - Image à uploader.
 */

const ImageUploader = ({
                           disabled,
                           ratio,
                           image,
                           defaultImage,
                           imagePreview,
                           setImagePreview,
                           setFileToUpload,
                           rectangle,
                           noImageText,
                           id = "cropper-default",
                           imgRef = React.createRef(),
                           noDeletion,
                           noImageButtonText = `${localStorage.getItem("translation") === "fr" ? "Ajouter une image": "Add an image"}`,
                           hasClickedOnCancelButton,
                           setChange,
                           truc,
                           setTruc,
                           handleDelete,
                           width,
                           height,
                       }: ImageUploaderProps) => {
    const getDefaultImage = () => {
        switch (defaultImage) {
            case "image-challenge-banner":
                return DEFAULT_CHALLENGE_PICTURE;
                break;
            case "image-challenge-logo":
                return DEFAULT_CHALLENGE_LOGO;
                break;
            case "image-establishment":
                return DEFAULT_ESTABLISHMENT_IMAGE;
                break;
            case "image-project":
                return DEFAULT_MAIN_PROJECT;
                break;
            case "image-profile-picture":
                return DEFAULT_PROFILE_PICTURE;
                break;
            case "image-prize":
                return DEFAULT_PRIZE_PICTURE;
                break;
            case "default":
            default:
                return DEFAULT_UPLOAD_PICTURE;
        }
    };
    // const { setError } = useAuth();
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [numerator, denominator] = ratio.split('/').map(Number);
    const ratioNumber = numerator / denominator;
    const [originalName, setOriginalName] = useState<string>('');
    const heightRatio52 = ratioNumber * 100;
    const [crop, setCrop] = useState<Crop | undefined>({
        unit: '%',
        x: 0, // Centré horizontalement
        y: (100 - ratioNumber) / 2, // Vous pouvez ajuster la position verticale si nécessaire
        width: 100,
        height: heightRatio52, // Hauteur à 100% pour couvrir toute l'image
    });

    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

    const imageString = typeof image === 'string' ? image : '';

    const [clicked, setClicked] = useState<boolean>(false);

    const [hasClickedOnImageDeletion, setHasClickedOnImageDeletion] = useState<boolean>(false);

    const [imageUploadingProgress, setImageUploadingProgress] = useState<number>(0);
    // Translation
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    useEffect(() => {
        if (hasClickedOnCancelButton) {
            setHasClickedOnImageDeletion(false);
            setTruc && setTruc(!truc);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasClickedOnCancelButton])

    async function compressImage(file: File) {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            onProgress: (progress: number) => setImageUploadingProgress(progress),
        }
        try {
            const compressedFile = await imageCompression(file, options);
            console.log("FILE COMPRESSÉ", compressedFile)
            return compressedFile;
        } catch (error) {
            console.log(error);
            return file;
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        let {width, height} = e.currentTarget
        const copieheight = height
        // Obtenez les dimensions initiales de l'image
        const largeurInitiale = width;
        const hauteurInitiale = height;

        // Calculez les nouvelles dimensions en maintenant le nouveau ratio
        if (largeurInitiale / hauteurInitiale > ratioNumber) {
            // Réduisez la largeur pour maintenir le ratio
            width = hauteurInitiale * ratioNumber;
            height = hauteurInitiale;
        } else {
            // Réduisez la hauteur pour maintenir le ratio
            width = largeurInitiale;
            height = largeurInitiale / ratioNumber;
        }

        // Maintenant, l'image a le nouveau ratio souhaité
        setCrop({
            unit: 'px',
            width: width,
            height: height,
            x: 0,
            y: (copieheight - height) / 2,
        })
    }

    useDebounceEffect(
        async () => {
            if (imgRef.current && completedCrop?.width && completedCrop?.height && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
            }
        },
        100,
        [completedCrop]
    )

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {

        if (e.target.files && e.target.files.length > 0 && e.target.files[0].type.match('image.*')) {
            setCrop(undefined); // Makes crop preview update between images.
            compressImage(e.target.files[0]).then((compressedFile) => {
                // Specify a custom file name for the compressed file
                const compressedFileName = compressedFile.name;
                setOriginalName(compressedFileName)
                const compressedFileWithCustomName = new File([compressedFile], compressedFileName, {type: 'image/png'});
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    const imgSrc = reader.result?.toString() || '';
                    setImgSrc(imgSrc);
                });

                reader.readAsDataURL(compressedFileWithCustomName);
                openModal('cropper-' + id);
                if (!clicked) setClicked(true);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            console.error("Le fichier sélectionné n'est pas une image");
        }
    }


    function dataURLtoFile(dataurl: any, filename: any) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    const handleConfirmCrop = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        previewCanvasRef?.current?.toBlob(async (blob) => {
            if (blob) {
                setHasClickedOnImageDeletion(false);
                const file = new File([blob], originalName, {type: 'image/png'});
                compressImage(file).then((compressedFile) => {
                    // convert Blob to File
                    compressedFile = new File([compressedFile], originalName, {type: 'image/png'});
                    setFileToUpload(compressedFile);

                    // get url of compressed file, then set imagePreview
                    const reader = new FileReader();

                    reader.addEventListener('load', () => {
                        // This code will execute after the file is loaded
                        const result = reader.result;

                        setImagePreview(String(result));
                    });

                    // Start reading the file after adding the event listener
                    reader.readAsDataURL(compressedFile);
                }).catch((error) => {
                    console.log(error);
                });
            }
        });
        closeModal('cropper-' + id);
    }
    const [defaultImageSet, setDefaultImageSet] = useState<string>(getDefaultImage());
    const handleDeletionImageClick = () => {
        setHasClickedOnImageDeletion(true);
        setImagePreview(defaultImageSet);
        setClicked(false);
        setChange && setChange(true);
    }

    return (
        <>
            <div
                className={(!hasClickedOnImageDeletion && (imageString !== defaultImageSet && imageString !== String(process.env.REACT_APP_FRONT_URL + defaultImageSet))) ? "image-uploader" : "image-uploader-no-image"}>
                <dialog id={"cropper-" + id} className='cropper'>
                    <form className="cropper form-modal">
                        <h1 className="h1-form">Redimensionner l'image</h1>
                        {!!imgSrc && (
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={c => setCompletedCrop(c)}
                                aspect={ratioNumber}
                                className='image-cropper'
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    onLoad={onImageLoad}
                                    id={id.toString()}
                                />
                            </ReactCrop>
                        )}
                        <div className="cropper buttons">
                            <Button option='cancel' onClick={() => closeModal("cropper-" + id)}
                                    padding="0.8rem 1.3rem">Annuler</Button>
                            <Button option='submit' onClick={(e) => {
                                handleConfirmCrop(e)
                            }} padding="0.8rem 1.3rem">Valider</Button>
                        </div>
                    </form>
                </dialog>
                {(!hasClickedOnImageDeletion && (imageString !== defaultImageSet && imageString !== String(process.env.REACT_APP_FRONT_URL + defaultImageSet))) || clicked ? (
                    <>
                        <Grid container justifyContent={'flex-start'} flexWrap={"wrap"} spacing={2}
                              alignItems={"start"}
                              className={"container-image"}
                        >
                            <Grid item height={'100%'} display={"flex"} marginRight={'2rem'}>
                                {
                                    (imagePreview || imageString) && (
                                        <div className='uploaderImage' style={{aspectRatio: ratio}}>
                                            <img src={imagePreview || imageString} alt="à uploader"
                                                 style={{aspectRatio: ratio, width: width, height: height}}/>
                                        </div>
                                    )
                                }
                                {
                                    (imageUploadingProgress > 0 && imageUploadingProgress < 100) && (
                                        <div className="progress-bar-image-uploader" style={{width: "100%"}}>
                                            Photo en cours de chargement...
                                            <ProgressBar progression={imageUploadingProgress}
                                                         className='progress-bar-image-uploading'/>
                                        </div>
                                    )
                                }
                            </Grid>
                            <Grid item>
                                <div className={"block-buttons"}>
                                    {!noDeletion &&
                                        <Button
                                            disabled={disabled}
                                            option='delete'
                                            onClick={disabled ? () => {
                                            } : () => {
                                                handleDeletionImageClick();
                                                handleDelete && handleDelete();
                                            }}
                                            padding="0.8rem 1.3rem">
                                            {t("form.delete")}
                                        </Button>
                                    }
                                    <input type="file" id={'imageFile-' + id} style={{display: 'none'}}
                                           onChange={(e) => onSelectFile(e)} accept='image/*'/>
                                    <Button disabled={disabled} option='update' onClick={disabled ? () => {
                                    } : (e) => {
                                        document.getElementById('imageFile-' + id)?.click()
                                    }} padding="0.8rem 1.3rem">{t("form.replace")}</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <p className="text image-uploader-no-image-text">{noImageText || t("imageUploader.noImageYet")}</p>
                        <input type="file" id={'imageFile-' + id} style={{display: 'none'}}
                               onChange={(e) => onSelectFile(e)}/>
                        <div>
                            <ActionButton type='button' className='add-logo-button' onClick={disabled ? () => {
                            } : () => {
                                document.getElementById('imageFile-' + id)?.click()
                            }} variant={disabled ? 'disabled' : 'secondary'} icon={<IconAdd className='icon-add'/>}>
                                {noImageButtonText}
                            </ActionButton>
                        </div>
                    </>
                )}
            </div>
            <div>
                {!!completedCrop && (
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            display: 'none',
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default ImageUploader;
