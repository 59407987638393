import { Box, Grid, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import "./index.css";
import { useEffect, useRef, useState } from 'react';

interface ISteps {
    totalSteps: number,
    activeStep?: number,
    runningStep?: number,
    blockedAt?: number | null,
}



export default function StepperGradient({ totalSteps, activeStep, runningStep, blockedAt }: ISteps) {
    const [activeSteps, setActiveSteps] = useState(0);
    const [runningSteps, setRunningSteps] = useState(0);
    const [totalStep, setTotalStep] = useState(0);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(0);
    
    function LinearProgressWithLabel(props: LinearProgressProps & { value: number, valueBuffer: number }) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress {...props} 
                sx={{
                    '&.MuiLinearProgress-root': {
                        height: 8,
                    },
                    '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
                        backgroundColor: blockedAt!==null?"#3F4F64":"#EDF1F7",
                    },
                    '& .MuiLinearProgress-barColorPrimary' : {
                        background: 'linear-gradient(275deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%)'
                    },
                    '& .MuiLinearProgress-colorPrimary' : {
                        background: blockedAt!==null?'#3F4F64':'#F86767',
                        animation: "animation-1lq7mgo 3s infinite linear"
                    },
                    '& .MuiLinearProgress-dashed': {
                        backgroundImage: `radial-gradient(${blockedAt?"rgb(63,79,100)":"rgb(167, 202, 237)"}rgb(167, 202, 237) 0%, ${blockedAt!==null?"#3F4F64":"#EDF1F7"} 30%, transparent 42%)`,
                        height: 8,
                        marginTop:'2.3px'
                    },
                }}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
    }
    useEffect(() => {
        // Mettez à jour l'état des steppers lorsque les propriétés changent
        setActiveSteps(activeStep || 0);
        setRunningSteps(runningStep || 0);
        setTotalStep(totalSteps || 0);
      }, [activeStep, runningStep, totalSteps]);


    useEffect(() => {

        if (!activeStep || activeStep===undefined || activeStep === 0) {
            setActiveSteps(0);
        }

        if (!totalSteps || totalSteps===undefined || totalSteps === 0) {
            setTotalStep(0);
        }

        if (!runningStep || runningStep===undefined || runningStep === 0) {
            setRunningSteps(0);
        }

        const calcBuffer = (runningSteps / totalStep) * 100;
        const Buffer = isNaN(calcBuffer) || !isFinite(calcBuffer) ? 0 : calcBuffer;
        setBuffer(Buffer);

        const calcProgress = (activeSteps / totalStep) * 100;
        const Progress = isNaN(calcProgress) || !isFinite(calcProgress) ? 0 : calcProgress;
        setProgress(Progress);
    }, [activeStep, activeSteps, runningStep, runningSteps, totalStep, totalSteps] );
      

    // useEffect(() => {
    //     // ... (code précédent)
    
    //     // Vérifiez si blockedAt est défini et qu'il est inférieur au nombre total d'étapes
    //     if (blockedAt !== undefined && blockedAt < totalSteps) {
    //         // Bloquez les étapes au-dessus de la valeur de blockedAt
    //         for (let i = blockedAt; i < totalSteps; i++) {
    //             const element = document.querySelector(`#stepperGrid > div:nth-child(${i + 1}) .step`);
    //             if (element) {
    //                 const styledElement = element as HTMLElement;
    //                 styledElement.style.backgroundColor = '#3F4F64'; // Changez la couleur en rouge (ou toute autre couleur de votre choix)
    //             }
    //         }
    //     }
    // }, [activeStep, activeSteps, runningStep, runningSteps, totalStep, totalSteps, blockedAt]);

    
    const onlyActiveStep = (backgroundSize:number,tab:any,animationDuration:number) => {
        
        const prevActiveSteps = activeSteps;
        if(prevActiveSteps !== undefined && typeof prevActiveSteps === 'number') {
            for (let i = 0; i < prevActiveSteps; i++) {
                const start = (i * 100) / prevActiveSteps;
                const end = ((i + 1) * 100) / prevActiveSteps;
                tab.push(`${start}% ${end}%`);
            }
            Array.from({ length: prevActiveSteps }).forEach((_, index) => {
                console.log(index);
                console.log(blockedAt);
                
                
                const element = document.querySelector(`#stepperGrid > div:nth-child(${index + 1}) .step`);
                if (element) {
                  const styledElement = element as HTMLElement;
                  const afterElement = document.createElement('div');
                  afterElement.style.position = 'absolute';
                  afterElement.style.content = '';
                  afterElement.style.width = '100%';
                  afterElement.style.height = '100%';
                  afterElement.style.zIndex = '1';
                  afterElement.style.background = "#EDF1F7";
                  
                  const delay = (index * animationDuration) / prevActiveSteps;
                  
                  const position = tab[index++];
                  styledElement.appendChild(afterElement);
                      setTimeout(() => {                        
                          afterElement.style.backgroundImage = `linear-gradient(275deg, ${blockedAt&& blockedAt<=index?"#3F4F64":'#B00362'} -11.94%, ${blockedAt&& blockedAt<=index?"#3F4F64":'#F86767'} 67.21%, ${blockedAt&& blockedAt<=index?"#3F4F64":'#F8BD0E'} 145.85% )`;
                          afterElement.style.backgroundSize = `${backgroundSize}% 100%`;
                          afterElement.style.animationName = 'deplacementDroit';
                          afterElement.style.animationDuration = `${(animationDuration / prevActiveSteps)}s`;
                          afterElement.style.animationTimingFunction = 'linear';
                          afterElement.style.animationFillMode = 'forwards';
                          afterElement.style.backgroundPosition = position;
                      }, delay * 1000);
                  }
            });
        }
    }

    const onlyRunningStep = (backgroundSize: number, tab: any, animationDuration: number) => {
        const prevRunningSteps = runningSteps;
        if (prevRunningSteps !== undefined && typeof prevRunningSteps === 'number') {
            for (let i = 0; i < prevRunningSteps; i++) {
                const start = (i * 100) / prevRunningSteps;
                const end = ((i + 1) * 100) / prevRunningSteps;
                tab.push(`${start}% ${end}%`);
              }
            Array.from({ length: prevRunningSteps }).forEach((_, index) => {   
                const element = document.querySelector(`#stepperGrid > div:nth-child(${index + 1}) .step`);
                if (element) {
                
                const styledElement = element as HTMLElement;
                const afterElement = document.createElement('div');
                afterElement.style.position = 'absolute';
                afterElement.style.content = '';
                afterElement.style.width = '100%';
                afterElement.style.height = '100%';
                afterElement.style.zIndex = '1';
                afterElement.style.background = "#EDF1F7";
            
                const delay = (index * animationDuration) / prevRunningSteps;
            
                const position = tab[index++];
                styledElement.appendChild(afterElement);
                setTimeout(() => {
                    afterElement.style.backgroundImage = `linear-gradient(275deg, ${blockedAt&& blockedAt<=index?"#3F4F64, #3F4F64":"#FCACAC, #FCACAC"})`;
                    afterElement.style.backgroundSize = `${backgroundSize}% 100%`;
                    afterElement.style.animationName = 'deplacementDroit';
                    afterElement.style.animationDuration = `${(animationDuration / prevRunningSteps)}s`;
                    afterElement.style.animationTimingFunction = 'linear';
                    afterElement.style.animationFillMode = 'forwards';
                    afterElement.style.backgroundPosition = position;
                }, delay * 1000);
                }
            });
        }
    }
    const bothActiveAndRunningStep = (backgroundSize: number, tab: any, animationDuration: number) => {
        const prevRunningSteps = runningSteps;
        const prevActiveSteps = activeStep;
        let delaytab: any = [];
        
        if (prevRunningSteps !== undefined && typeof prevRunningSteps === 'number' && prevActiveSteps !== undefined && typeof prevActiveSteps === 'number') {
            const totalSteps = prevRunningSteps + prevActiveSteps;            
            // Calcul des délais pour chaque étape
            for (let i = 0; i < totalSteps; i++) {
                delaytab.push((i * animationDuration) / totalSteps);
            }
            for (let i = 0; i < prevActiveSteps; i++) {
                const start = (i * 100) / prevActiveSteps;
                const end = ((i + 1) * 100) / prevActiveSteps;
                tab.push(`${start}% ${end}%`);
            }         
            Array.from({ length: totalSteps }).forEach((_, index) => {
                const position = tab[index++];
                const element = document.querySelector(`#stepperGrid > div:nth-child(${index}) .step`);
                
                if (element) {
                const styledElement = element as HTMLElement;
                const afterElement = document.createElement('div');
                afterElement.style.position = 'absolute';
                afterElement.style.content = '';
                afterElement.style.width = '100%';
                afterElement.style.height = '100%';
                afterElement.style.zIndex = '1';
                afterElement.style.background = "#EDF1F7";
                styledElement.appendChild(afterElement);                
                setTimeout(() => {
                    if (index <= prevActiveSteps) {
                    // Si c'est une étape active, applique les styles correspondants
                    afterElement.style.backgroundImage = `linear-gradient(275deg, ${blockedAt&& blockedAt<=index?"#3F4F64":'#B00362'} -11.94%, ${blockedAt&& blockedAt<=index?"#3F4F64":'#F86767'} 67.21%, ${blockedAt&& blockedAt<=index?"#3F4F64":'#F8BD0E'} 145.85% )`
                    afterElement.style.backgroundSize = `${backgroundSize}% 100%`;
                    afterElement.style.animationName = 'deplacementDroit';
                    afterElement.style.animationDuration = `${(animationDuration / totalSteps)}s`;
                    } else {
                    // Sinon, c'est une étape en cours d'exécution, applique les styles correspondants
                    afterElement.style.backgroundImage = `linear-gradient(275deg, ${blockedAt&& blockedAt<=index?"#3F4F64, #3F4F64":"#FCACAC, #FCACAC"})`;
                    afterElement.style.backgroundSize = `${100}% 100%`;
                    afterElement.style.animationName = 'deplacementDroit';
                    afterElement.style.animationDuration = `${(animationDuration / totalSteps)}s`;
                    }
                    afterElement.style.animationTimingFunction = 'linear';
                    afterElement.style.animationFillMode = 'forwards';
                    afterElement.style.backgroundPosition = position;
                }, delaytab[index - 1] * 1000);
                }
            });            
        }
    }

      
    useEffect(() => {
        let backgroundSize;
        let tab: any = [];
        const animationDuration = 2;
      
        if ( activeSteps && activeSteps !== 0 && activeSteps === activeStep && runningStep && runningSteps && runningSteps > 0 ) {   
            backgroundSize = (blockedAt ?activeStep-blockedAt:activeStep) * 100;
            bothActiveAndRunningStep(backgroundSize, tab, animationDuration);            
        } else if (activeSteps && activeSteps !== 0 && activeSteps !== undefined) {    
            backgroundSize = (blockedAt ?activeSteps-blockedAt:activeSteps) * 100;            
            onlyActiveStep(backgroundSize, tab, animationDuration);
        } else if (runningSteps && runningSteps !== 0 && runningSteps !== undefined) {
            backgroundSize = runningSteps * 100;            
            onlyRunningStep(backgroundSize, tab, animationDuration);
        }
      }, [activeStep, activeSteps, runningStep, runningSteps]);
      
      
    return (
        <div id='stepper'>
            {totalSteps && totalSteps < 7 ? (
                <>
                <Grid id="stepperGrid" container spacing={1} justifyContent={"space-between"} direction={"row"} height={"8px"}>
                    {Array.from({ length: totalSteps }).map((_, index) => (
                        <Grid item xs key={index}>
                            <div className={`step`}/>                    
                        </Grid>
                    ))}
                </Grid>
                </>
            ): (
                <>
                {/* <p>Active : {activeSteps}</p>
                <p>Running : {runningSteps}</p>
                <p>Total : {totalStep}</p>
                <p>Progress : {progress}</p>
                <p>Buffer : {buffer}</p> */}
                <Box sx={{ width: '100%' }}>
                    {totalStep && (
                            <LinearProgressWithLabel variant='buffer' value={progress} valueBuffer={progress+buffer}/>
                        )
                    }
                </Box>
                </>
                
            )} 
        </div>
    );
}
