// Cette page est la page de connexion principale sur laquelle l'utilisateur rentre son email et son mot de passe

// material-ui
import {Box, FormControl, TextField, useMediaQuery, useTheme} from "@mui/material";

// react-router-dom
import {Link} from "react-router-dom";

// components
import ActionButton from "../../../../../components/ActionButton";

// context
import {useAuth} from "../../../../../context/AuthContext";

//react
import DiamondColor from '../../../../../assets/diamond-color';
import MakeeDiamondLogin from '../../../../../assets/makee-diamond-login';

// style
import "./index.css";

// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../../../app/type';

// react
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import SupportFooter from "../../../../../modules/SupportFooter";
import SwitchTranslate from "../../../../../components/SwitchTranslate";


const LoginEmailPsw = () => {

    // redux state
    const error = useSelector((action: RootState) => action.error.error);
    let errorMessage = useSelector((action: RootState) => action.error.message);
    // context
    const {loginUser} = useAuth();
    // console.log("loginUser", useAuth().loginUser);
    //redux
    const dispatch = useDispatch();

    // enlever les erreurs
    useEffect(() => {
        dispatch({
            type: "CLEAR_ERROR",
        });
    }, []);
    const errorLinkEmail = () => {
        return (
            <>
                {errorMessage}
                <a href="mailto:contact@challenkers.com">contact@challenkers.com</a>
            </>
        )
    }
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <div className="login-email-psw-svg" id={"test"}>
                <div className='login-email-psw'>
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login'/>
                    {
                        isMobile && (
                            <SwitchTranslate className={"translate-icon"}/>
                        )
                    }
                    {/* texte au dessus du formulaire */}
                    <h1 className='h1-auth'>{t("login")}</h1>
                    <p className='form-text-auth account'>{t("page.login.noAccount.text1")}
                        <Link to="/signup/participant"
                              className='login-email-psw-link'>{t("page.login.noAccount.text2")}</Link>
                    </p>

                    {/* Formulaire de connexion avec email et mot de passe qui appelle la fonction loginUser() au submit
                    Utilisation de composants de Material-UI pour la création du formulaire */}

                    <FormControl onSubmit={(e) => {
                        loginUser(e);
                    }}
                    >
                        <Box
                            component="form"
                            sx={{
                                // on ajoute un margin top de 40px à tous les enfants de Box
                                '& > :not(style)': {marginTop: '40px'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* email à entrer pour se connecter */}
                            <TextField
                                helperText={
                                    error && errorMessage === "Votre compte est désactivé. Pour le réactiver, contactez-nous sur " ?
                                        errorLinkEmail() :
                                        error && errorMessage
                                }
                                error={error}
                                id="email"
                                label={t("page.login.formConnection.inputText1")}
                                variant="outlined"
                                placeholder="example@mail.com"
                                type="email"
                                className='form-text-auth login-email-psw-input'
                            />

                            {/* mot de passe à entrer pour se connecter */}
                            <TextField
                                id="password"
                                label={t("page.login.formConnection.inputText2")}
                                variant="outlined"
                                placeholder="************"
                                type="password"
                                className='form-text-auth'
                                helperText={
                                    error && errorMessage === "Votre compte est désactivé. Pour le réactiver, contactez-nous sur " ?
                                        errorLinkEmail() :
                                        error && errorMessage
                                }
                                error={error}
                            />

                            <ActionButton
                                type="submit"
                                variant='primary'
                            >
                                {t("login")}
                            </ActionButton>
                        </Box>
                    </FormControl>

                    {/* lien vers la page de récupération du mot de passe */}
                    <Link to="/reset-password" className='login-email-psw-svg-link small-lead-text'>
                        {t("page.login.forgotPswd")}
                    </Link>
                </div>

                {/* image svg à droite du formulaire */}
                <DiamondColor className='diamond-login'/>
            </div>
            <SupportFooter />
        </>
    )
}

export default LoginEmailPsw;
