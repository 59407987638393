// cette page est la page de création de compte pour un utilisateur qui n'a pas de compte Makee
// il va pouvoir y renseigner :
// - son mot de passe
// - son numéro de téléphone
// - son adresse e-mail de récupération


// Components
import ActionButton from '../../../../../../components/ActionButton'

// react-dom
import {Link, useNavigate, useLocation} from "react-router-dom"

// material-ui
import {
    TextField,
    Step,
    StepConnector,
    Stepper,
    stepConnectorClasses,
    styled,
    FormControl,
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText
} from '@mui/material';

//react
import React, {FormEvent, useState} from "react";

// style
import '../index.css';

// context
import {useAuth} from '../../../../../../context/AuthContext';
import MuiPhoneInput from "mui-phone-number";
import 'react-phone-input-2/lib/style.css'
import {isEmail} from "../../../../../../utils/functions";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import SupportFooter from "../../../../../../modules/SupportFooter";


const CreateAccountPswTelEmail = () => {

    const location = useLocation();

    // Style des steps Material UI (voir la doc)
    const QontoConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 16,
            right: 16,
            margin: 'auto',
            borderTopWidth: 10,
            borderRadius: 2,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#EDF1F7',
                borderTopWidth: 10,
                borderRadius: 2,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#F86767',
                borderTopWidth: 10,
                borderRadius: 2,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EDF1F7',
            borderTopWidth: 10,
            borderRadius: 1,
        },

    }));

    // on défini le nombre de steps
    const steps = ['', ''];

    // on change l'étape active en fonction du paramètre d'URL
    const activeStep = 1;

    const navigate = useNavigate();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    // context
    const {createAccountAdmin, createAccountParticipant, registrationError, setRegistrationError} = useAuth();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    const handleOnBlur = () => {
        if (!isEmail(email)) {
            setEmailError(true);
            setEmailErrorMessage(`${t("page.signUpParticipantPswTelEmail.emailInvalid")}`);
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }
    }

    const handleOnChange = (email: string) => {
        setEmail(email);
        if (isEmail(email)) {
            setEmailError(false);
            setEmailErrorMessage('');
            setRegistrationError(null);
        }
    }

    const handleCheckboxChange = (event: any) => {
        setIsCheckboxChecked(event.target.checked);
    };
    const [checkboxError, setCheckboxError] = useState<boolean>(false);
    const [checkboxErrorMessage, setCheckboxErrorMessage] = useState<string>("");
    const handleOnSubmit = (e: FormEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (!isEmail(email)) {
            setEmailError(true);
            setEmailErrorMessage(`${t("page.signUpParticipantPswTelEmail.emailInvalid")}`);
        } else {
            if (!isCheckboxChecked) {
                // Affiche un message d'erreur si la case à cocher n'est pas cochée
                // (vous pouvez également stocker cet état dans un état séparé si nécessaire)
                setCheckboxErrorMessage(`${t("page.signUpParticipantPswTelEmail.checkboxError")}`)
                setCheckboxError(true);
            }else{
            location.pathname.includes('admin') ? createAccountAdmin(e) :
                location.pathname.includes('participant') ? createAccountParticipant(e) :
                    console.log('erreur');
            }
        }
    }
    return (
        <>
            <div className="create-account-email-password">

                {/* texte  */}
                <div className="text-top">

                    {/* titre de la page */}

                    {location.pathname.includes('admin') &&
                        <>
                            <h1 className='h1-auth'>{t("page.signUpParticipantPswTelEmail.titleAdmin")}</h1>
                            <p>{t("page.signUpParticipantPswTelEmail.alreadyAccount")} <Link
                                to="/signup/admin/merge/search">{t("page.signUpParticipantPswTelEmail.associateAccount")}</Link>
                            </p>
                        </>
                    }


                    {location.pathname.includes('participant') &&
                        <>
                            <h1 className='h1-auth'>{t("page.signUpParticipantPswTelEmail.titleParticipant")}</h1>
                            <p>{t("page.signUpParticipantPswTelEmail.alreadyAccountParticipant")} <Link
                                to="/signup/participant/merge/search">{t("page.signUpParticipantPswTelEmail.associateAccount")}</Link>
                            </p>
                        </>
                    }

                    {/* si l'utilisateur a déjà un compte Makee, il peut l'associer à son compte en cliquant sur le lien "Associer mon compte ?" */}


                </div>
                {/*<div className="stepper">*/}
                {/*    /!* barre de progression en haut de l'écran Material UI (voir la doc) *!/*/}
                {/*    < Stepper className='progress-bar' alternativeLabel activeStep={activeStep} connector={null} >*/}
                {/*        /!* affichage du nombre de barres en fonction du nombre d'étapes *!/*/}
                {/*        {*/}
                {/*            steps.map((label) => (*/}
                {/*                <Step className='stepper'>*/}
                {/*                    <QontoConnector />*/}
                {/*                </Step>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </Stepper >*/}
                {/*</div>*/}

                {/* formulaire de création de compte */}
                <div className="instruction-password">
                    <h2 className='h2'>{t("page.signUpParticipantPswTelEmail.choosePsw")}</h2>
                    <p>{t("form.requiredFields")}</p>
                </div>

                <FormControl
                    onSubmit={(e) => {
                        handleOnSubmit(e)
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': {marginTop: '25px'},
                        }}
                        noValidate
                        autoComplete="off"
                        textAlign='left'
                    >

                        {/* password */}
                        <TextField
                            id="password"
                            label={t("page.signUpParticipantPswTelEmail.form.pswd")}
                            variant="outlined"
                            placeholder='************'
                            type='password'
                            error={registrationError && (registrationError.error === "PwdsDontMatch" || registrationError.error === "PwdTooWeak")}
                            helperText={registrationError && (registrationError.error === "PwdsDontMatch" || registrationError.error === "PwdTooWeak") ? registrationError.message : ''}
                            required
                        />

                        {/* confirmer password */}
                        <TextField
                            id="password_confirm"
                            label={t("page.signUpParticipantPswTelEmail.form.confirmPswd")}
                            variant="outlined"
                            placeholder='************'
                            type='password'
                            error={registrationError && (registrationError.error === "PwdsDontMatch" || registrationError.error === "PwdTooWeak")}
                            helperText={registrationError && (registrationError.error === "PwdsDontMatch" || registrationError.error === "PwdTooWeak") ? registrationError.message : ''}
                            required
                        />

                        {/* telephone */}
                        {/*<TextField*/}
                        {/*    id="tel"*/}
                        {/*    label="Téléphone"*/}
                        {/*    variant="outlined"*/}
                        {/*    placeholder='06 00 00 00 00'*/}
                        {/*    type='text'*/}
                        {/*    required*/}
                        {/*/>*/}

                        <MuiPhoneInput
                            id="tel"
                            variant="outlined"
                            defaultCountry='fr'
                            regions={'europe'}
                            onChange={(value) => console.log(value)}
                            preferredCountries={['fr']}
                            label={t("page.signUpParticipantPswTelEmail.form.numTel")}
                            countryCodeEditable={false}
                            disableAreaCodes={true}
                            error={registrationError && registrationError.error === "emptyTel"}
                            helperText={registrationError && registrationError.error === "emptyTel" ? registrationError.message : ''}
                        />

                        {/* email de récupération */}
                        <TextField
                            id="email_recup"
                            label={t("page.signUpParticipantPswTelEmail.form.emailRecup")}
                            variant="outlined"
                            placeholder='contact@mail.com'
                            type='email'
                            onBlur={() => handleOnBlur()}
                            onChange={(e) => handleOnChange(e.target.value)}
                            error={(emailError) || (registrationError && registrationError.error === "emailAlreadyExists")}
                            helperText={(emailError ? emailErrorMessage : '') || (registrationError && registrationError.error === "emailAlreadyExists" ? registrationError.message : '')}
                            required
                        />
                        <FormHelperText
                            sx={{m: '5px !important'}}>{t("page.signUpParticipantPswTelEmail.form.emailRecupDescription")}</FormHelperText>

                        {/* check box pour accepter les conditions générales d'utilisation */}
                        <FormControlLabel
                            value="end"
                            control={<Checkbox/>}
                            onChange={handleCheckboxChange}
                            label={
                                <>
                                    {t("page.signUpParticipantPswTelEmail.form.acceptCGU")}
                                    <span style={{marginLeft: "5px", textDecoration: "underline"}}>
                                        <a
                                            href={"https://get.makee.io/wp-content/uploads/2024/03/CGU-Makee.pdf"}
                                            target={"_blank"}
                                            style={{color: "#6D6D6D"}}
                                        >
                                            {t("page.signUpParticipantPswTelEmail.form.linkCGU")}
                                            </a>
                                    </span>
                                </>
                            }
                            labelPlacement="end"
                            sx={
                                {
                                    '& .MuiTypography-root': {
                                        fontSize: '0.75rem',
                                        color: '#6D6D6D',
                                    },
                                    '& .MuiCheckbox-root': {
                                        color: '#F86767',
                                    },
                                    '& .MuiCheckbox-root:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    '& .Mui-checked': {
                                        color: '#F86767',
                                    },
                                    '& .MuiCheckbox-root.Mui-checked:hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }
                            }
                            required
                        />
                        <FormHelperText
                            sx={{m: '5px !important', color: "red"}}>{checkboxError && t("page.signUpParticipantPswTelEmail.checkboxError")}</FormHelperText>
                        {/* boutons de validation et d'annulation */}
                        <div className="buttons">
                            <ActionButton
                                auth
                                variant="tertiary"
                                type="button"
                                onClick={() => {
                                    navigate(-1) // naviguer vers la page précédente
                                }}
                            >
                                {t("form.cancel")}
                            </ActionButton>
                            <ActionButton
                                auth
                                variant="primary"
                                type="submit"
                            >
                                {t("form.validate")}
                            </ActionButton>
                        </div>
                    </Box>
                </FormControl>
            </div>
            {/*<SupportFooter />*/}
        </>
    )
}
export default CreateAccountPswTelEmail;
