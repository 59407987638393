import { Action } from 'redux';
import { ErrorState } from '../type';

// Définissez le type pour l'action 'SET_USER'
interface SetErrorAction extends Action<'SET_ERROR'> {
  payload: {
    message: string;
    error: boolean;
    type: string;
  };
}

const initialState: ErrorState = {
    message: '',
    error: false,
    type: '',
};

const errorReducer = (state = initialState, action: Action): ErrorState => {
  switch (action.type) {
    case 'SET_ERROR':
      // Utilisez une assertion de type pour indiquer que l'action a le type approprié
      const setErrorAction = action as SetErrorAction;
      return {
        ...state,
        message: setErrorAction.payload.message,
        error: true,
        type: setErrorAction.payload.type,
      };

    case 'CLEAR_ERROR':
      return {
        ...state,
        message: '',
        error: false,
        type: '',
      };
    default:
      return state;
  }
};

export default errorReducer;
