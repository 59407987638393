// pages de création de compte

// react-dom
import { Outlet } from "react-router-dom"

// style
import './index.css';

// assets
import MakeeDiamondLogin from '../../../../../assets/makee-diamond-login';
import SupportFooter from "../../../../../modules/SupportFooter";


const CreateAccount = () => {

    return (
        <>
            <section className="create-account">
                <MakeeDiamondLogin className='diamond-login' />
                <Outlet />
            </section>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <SupportFooter />
        </>
    )
};

export default CreateAccount;