// mui
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

// moment
import moment from 'moment';
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../SetUpTranslation";

// function generate planning format date from admin infos challenge planning component

interface TimelineChallengeItemProps {
    date: string;

    event: string;

    name: string;

    nextDate?: string;

    end?: boolean;

    hasSelection?: boolean;
}

const TimelineChallengeItem = ({
                                   date, event, nextDate, hasSelection, name,
                                   end = false
                               }: TimelineChallengeItemProps) => {

    const userIsOnMobile = window.innerWidth <= 768;

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    if (!event.includes("endSelection")) {
        return (
            <TimelineItem sx={{
                '&::before': {
                    flex: '0 !important',
                    padding: '0 !important'
                }
            }}>

                {!userIsOnMobile && (
                    <TimelineOppositeContent sx={{
                        // pour que le texte soit aligné avec le point
                        // clown emoji
                        padding: '0px 16px',
                        marginTop: userIsOnMobile ? '-32.5px' : '-20px',
                        marginLeft: '10px',
                    }}>
                        <div className="planning-item-content planning-item-opposite">
                            <div className="planning-item-date">
                                <p className="text-end">
                                    {moment(date).format('LL - HH[h]mm')}
                                </p>
                            </div>
                        </div>
                    </TimelineOppositeContent>
                )}
                <TimelineSeparator>
                    <TimelineDot variant={moment().isAfter(date) ? "filled" : "outlined"} sx={{
                        borderColor: 'var(--secondary50)',
                        backgroundColor: moment().isAfter(date) ? 'var(--secondary50)' : "",
                        marginTop: '0',
                        marginBottom: '0'
                    }}/>
                    <TimelineConnector sx={{
                        backgroundColor: 'var(--secondary50)',
                        opacity: moment().isAfter(nextDate) ? 1 : 0.3,
                        height: {xs: end ? '2.5rem' : '7.5rem', md: end ? '2.5rem' : '6rem'}
                    }}/>
                </TimelineSeparator>
                <TimelineContent sx={{
                    // pour que le texte soit aligné avec le point
                    // clown emoji
                    padding: '0px 16px',
                    marginTop: userIsOnMobile ? '-32.5px' : '-20px',
                    marginLeft: '10px',
                    width: '35vw'
                }} className="planning-item">
                    <div className="planning-item-content planning-item-start">
                        {userIsOnMobile &&
                            <div className="planning-item-date">
                                <p className="text">
                                    {moment(date).format('LL - HH[h]mm')}
                                </p>
                            </div>}
                        <div className="planning-item-name">
                            <p className="small-lead-text">
                                {event === 'startDate'
                                    ? t("page.challenge.planning.challengeStart")
                                    : event === 'endDate'
                                        ? t("page.challenge.planning.challengeEnd")
                                        : event === 'registrationStartDate'
                                            ? t("page.challenge.planning.registrationsStart")
                                            : event === "registrationEndDate"
                                                ? t("page.challenge.planning.registrationsEnd")
                                                : event.includes("step") && event.includes("start")
                                                    // event[4] pour récupérer le numéro de l'étape
                                                    // name.split pour extraire le nom de l'étape
                                                    ? t("page.challenge.planning.stepStart") + " - " + name.split(" - ")[1]
                                                    // : event.includes("step") && event.includes("endSelection")
                                                    //     // event[4] pour récupérer le numéro de l'étape
                                                    //     ? t("page.challenge.planning.stepEndSelection")
                                                    : event.includes("step") && event.includes("end") && !event.includes("endSelection")
                                                        // event[4] pour récupérer le numéro de l'étape
                                                        // name.split pour extraire le nom de l'étape
                                                        ? name.split(" - ")[1] + " - " + t("page.challenge.planning.stepEnd")
                                                        : event === "projectPitch"
                                                            ? t("page.challenge.planning.projectPitch")
                                                            : event === "alumniNight"
                                                                ? t("page.challenge.planning.alumniNight")
                                                                : ""}

                            </p>
                        </div>
                        {/* Affichage de "avec sélection" */}
                        {/*{hasSelection &&*/}
                        {/*    <div className="planning-item-has-selection">*/}
                        {/*        <p className="text italic">{t("page.challenge.planning.hasSelection")}</p>*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>
                </TimelineContent>
            </TimelineItem>
        );
    } else {
        return <></>;
    }
}

export default TimelineChallengeItem;