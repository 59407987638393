// react
import React from 'react';

// react-dom
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';

// App
import App from './App';

// styles
import './index.css'

// i18n
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';

// context
import { AuthProvider } from './context/AuthContext';

// persistor 
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store'; // Importez le store et le persistor depuis votre fichier de configuration Redux Persist


import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


// Configuration du niveau de journalisation en fonction de l'environnement

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}
  

const root: HTMLElement | null = document.getElementById('root');
// Si root existe, on affiche l'application
if (root) {
    ReactDOM.createRoot(root).render(
        <React.Suspense>
            <I18nextProvider i18n={i18next}> { /* traduction */}
                <BrowserRouter > { /* navigation */}
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <AuthProvider> { /* context */}
                                    <App /> { /* application */}
                                </AuthProvider>
                            </LocalizationProvider>
                        </PersistGate>
                    </Provider >
                </BrowserRouter>
            </I18nextProvider>
        </React.Suspense>
    );
}