

// react
import {createRef, useEffect} from "react"

// redux
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../../../../app/type"

// modules
import ChallengeCard from "../../../../modules/ChallengeCard";
// style
import './index.css'
import {Grid} from "@mui/material"
import {useLocation} from "react-router-dom";
import {useAuth} from "../../../../context/AuthContext";
import moment from "moment";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../components/SetUpTranslation";
import api from "../../../../utils/api";

const Home = () => {
    const { profile } = useAuth();

    //redux
    const dispatch = useDispatch()

    // récupération de tous les challenges
    const getAllChallenges = () => {
        // requête axios
        api.get('/challenges',
            {withCredentials: true})
            .then((res) => {
                dispatch({
                    type: 'SET_CHALLENGES',
                    payload: {
                        allChallenges: res.data.challenges
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getOneChallenge = (id: string) => {
        api.get('/challenge/preview', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                res.data.challenge = {
                    ...res.data.challenge,
                    prizes: res.data.challenge.prizes.map((prize: any) => {
                        return {
                            ...prize,
                            id: prize.id,
                            image: prize.prizePicture,
                            ref: createRef<HTMLImageElement>(),
                            position: prize.position
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    questions: res.data.challenge.itemFAQs.map((question: any) => {
                        return {
                            ...question,
                            id: question.id,
                            challengeId: res.data.challenge.id,
                            ref: createRef<HTMLImageElement>()
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    dares: res.data.challenge.subChallenges.map((dare: any) => {
                        return {
                            ...dare,
                            id: dare.id,
                            challengeId: res.data.challenge.id,
                            ref: createRef<HTMLImageElement>()
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    partners: res.data.challenge.partners.map((partner: any) => {
                        return {
                            ...partner,
                            id: partner.id,
                            name: partner.name,
                            link: partner.partnerLink,
                            image: partner.partnerLogo,
                            ref: createRef<HTMLImageElement>(),
                            challengeId: res.data.challenge.id,
                            position: partner.position
                        }
                    }).sort((a: any, b: any) => a.position - b.position)
                }
                console.log('getOneChallenge');
                dispatch({
                    type: "SET_CHALLENGES",
                    payload: {
                        getOneChallenge: {
                            countMembers: res.data.challenge.countMembers,
                            id: res.data.challenge.id,
                            name: res.data.challenge.name,
                            shortDescription: res.data.challenge.shortDescription,
                            longDescription: res.data.challenge.longDescription,
                            challengePicture: res.data.challenge.challengePicture,
                            challengeLogo: res.data.challenge.challengeLogo,
                            startDate: res.data.challenge.startDate,
                            endDate: res.data.challenge.endDate,
                            public: res.data.challenge.public,
                            prizes: res.data.challenge.prizes,
                            questions: res.data.challenge.questions,
                            dares: res.data.challenge.dares,
                            partners: res.data.challenge.partners,
                            hasDefis: res.data.challenge.hasDefis || res.data.challenge.dares.length > 0,
                            hasPrizes: res.data.challenge.hasPrizes || res.data.challenge.prizes.length > 0,
                            hasPartners: res.data.challenge.hasPartners || res.data.challenge.partners.length > 0,
                            hasFaq: res.data.challenge.hasFaq || res.data.challenge.questions.length > 0,
                            registrationEndDateText: res.data.challenge.registrationEndDateText,
                            status: res.data.challenge.status.name,

                            organizer: res.data.challenge.establishments[0].name,
                            imgOrganizer: res.data.challenge.establishments[0].establishmentPicture,

                            totalParticipants: res.data.challenge.maxMembers,
                            typeParticipation: res.data.challenge.participationType,
                            nbMinParticipants: res.data.challenge.minMembersTeam,
                            nbMaxParticipants: res.data.challenge.maxMembersTeam,
                            registrationStartDate: res.data.challenge.registrationStartDate,
                            registrationEndDate: res.data.challenge.registrationEndDate,
                            linkRules: !res.data.challenge.file ? "" : res.data.challenge.file.fileUrl,
                            fileName: !res.data.challenge.file ? "" : res.data.challenge.file.originalFileName,
                            fileNameStorage: !res.data.challenge.file ? "" : res.data.challenge.file.fileName,
                        }
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getAllChallenges();
    }, [])

    // redux
    const allChallenges = useSelector((state: RootState) => state.challenges.allChallenges)
    const location = useLocation();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <div className='admin-home-page'>
            <h1>{t("page.globalHome.title")}</h1>
            <div className='block-challenges'>
                {
                    allChallenges && allChallenges.map((challenge: any, index:number) => {
                        // état du challenge
                        let state = '';
                        let done = false;

                        if (challenge.status.name === 'published') {
                            state = t("status.published");
                        } else if (challenge.status.name === 'registrations') {
                            state = t("status.registrations");
                        } else if (challenge.status.name === 'submission') {
                            state = t("status.submission");
                        } else if (challenge.status.name === 'deliberations') {
                            state = t("status.deliberations");
                        } else if (challenge.status.name === 'finished') {
                            state = t("status.finished");
                            done = true;
                        }
                        // dates de début et de fin du challenge
                        const startDate = new Date(challenge.startDate).toLocaleDateString(i18n.language, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        const startEnd = new Date(challenge.endDate).toLocaleDateString(i18n.language, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });

                        // date de début et de fin des inscriptions
                        // const registrationStartDate = challenge.registrationStartDate ? new Date(challenge.registrationStartDate).toLocaleDateString('fr-FR', {
                        //     day: '2-digit',
                        //     month: '2-digit',
                        //     year: 'numeric'
                        // }) : null;
                        // const registrationEndDate = challenge.registrationEndDate ? new Date(challenge.registrationEndDate).toLocaleDateString('fr-FR', {
                        //     day: '2-digit',
                        //     month: '2-digit',
                        //     year: 'numeric'
                        // }) : null;

                        // nom du challenge pour le lien
                        const challengeName = challenge.name.replace(/\s+/g, '-').toLowerCase();
                        const userType = location.pathname.includes('admin') ? 'admin' : 'participant';

                        // participation type
                        let participation_type = '';
                        if (challenge.participationType === 'team') {
                            participation_type = t("participationType.team");
                        }
                        if (challenge.participationType === 'single') {
                            participation_type = t("participationType.single");
                        }
                        if (challenge.participationType === 'both') {
                            participation_type = t("participationType.both");
                        }

                        return (
                            <div className="block-challenge" key={index}>
                                <ChallengeCard
                                    key={challenge.id}
                                    title={challenge.name}
                                    description={challenge.shortDescription || ''}
                                    img={challenge.challengePicture || ''}
                                    date={`${t("challengeCard.from")} ${startDate} ${t("challengeCard.to")} ${startEnd}`}
                                    state={state}
                                    registration_date={moment(challenge.registrationStartDate).isValid() && moment(challenge.registrationEndDate).isValid() ?
                                                    `${t("challengeCard.from")} ${moment(challenge.registrationStartDate).format("LL")} ${t("challengeCard.to")} ${moment(challenge.registrationEndDate).format("LL")}`
                                                    : moment(challenge.registrationStartDate).isValid() ?
                                                        `${t("challengeCard.startingFrom")} ${moment(challenge.registrationStartDate).format("LL")}`
                                                        : moment(challenge.registrationEndDate).isValid() ?
                                                            `${t("challengeCard.until")} ${moment(challenge.registrationEndDate).format("LL")}`
                                                            : null}
                                    // link_to_challenge={`${userType === 'admin' ? `/${userType}/home/challenge/general/${challengeName}` : `/${userType}/challenge/${challenge.id}/general`}`}
                                    link_to_challenge={`/challenge/${challenge.id}/general`}
                                    desktop
                                    participation_type={participation_type}
                                    long_description={challenge.longDescription}
                                    min_members={challenge.minMembersTeam}
                                    max_members={challenge.maxMembersTeam}
                                    onClick={() => getOneChallenge(challenge.id)}
                                    isPublic={challenge.public}
                                    organizer={challenge.establishments[0]?.name ? challenge.establishments[0]?.name : ''}
                                    done={done}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Home;
