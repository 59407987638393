// style
import './index.css';

// icons
import { ReactComponent as IconPen } from '../../../../../assets/icon-pen.svg';
import { ReactComponent as IconTel } from '../../../../../assets/icon-tel.svg';
import { ReactComponent as IconMail } from '../../../../../assets/icon-message.svg';
import { ReactComponent as IconWarn } from '../../../../../assets/icon-warn.svg';

// hooks
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

// components
import ActionButton from '../../../../../components/ActionButton';
import SchoolCard from '../../../../../components/SchoolCard';
import ProgressBar from '../../../../../components/ProgressBar';


// functions
import { isPlural } from '../../../../../utils/functions';

// constants
import { DEFAULT_ESTABLISHMENT_IMAGE } from '../../../../../utils/consts';
import { useMediaQuery, useTheme } from '@mui/material';
import api from "../../../../../utils/api";

export interface IEstablishmentDetails {
    id: number;
    name: string;
    establishmentPicture: string;
    establishmentType: string;
    created_at: string;
    adminCount: number;
    nbChallenges: number;
    challengeCount: number;
}

export const fetchEstablishmentDetails = async (setEstablishmentDetails: (data: any) => void) => {
    await api.get(
        "/admin/info_establishment",
        {
            withCredentials: true
        }
    ).then((res) => {
        setEstablishmentDetails({
            name: res.data.establishment.name,
            establishmentPicture: res.data.establishment.establishmentPicture,
            establishmentType: res.data.establishment.establishmentType,
            adminCount: res.data.establishment.adminCount,
            created_at: new Date(res.data.establishment.createdAt).toLocaleDateString(),
            nbChallenges: res.data.establishment.nbChallenges,
            challengeCount: res.data.establishment.challengeCount
        })
    })
}

const AdminConfigGeneral = () => {

    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [establishmentDetails, setEstablishmentDetails] = useState<IEstablishmentDetails>({
        id: 0,
        name: "",
        establishmentPicture: DEFAULT_ESTABLISHMENT_IMAGE,
        establishmentType: "",
        created_at: "",
        adminCount: 0,
        nbChallenges: 0,
        challengeCount: 0
    });

    useEffect(() => {
        fetchEstablishmentDetails(setEstablishmentDetails);
    }, [])

    return (
        <div className='admin-config'>
            {/* isMobile && ( //School card + affichage modifications établissement pour la version mobile
                <div className='admin-config-card-mobile'>
                    <div className='admin-config-card-mobile-btn'>
                        <ActionButton type='submit' variant='primary' icon={<IconPen/>} className='admin-config-edit-button' onClick={() => openModal()}>
                            Modifier les informations
                        </ActionButton>
                    </div>
                    <SchoolCard 
                        variant='white'
                        img_url={establishmentDetails.establishmentPicture || DEFAULT_ESTABLISHMENT_IMAGE}
                        establishmentType={
                            establishmentDetails.establishmentType === "school"
                            ? "Établissement"
                            : "Entreprise"
                        }
                    >
                        <h1 className='h1'>{establishmentDetails.name}</h1>
                    </SchoolCard>
                </div>
            ) */}
            <main className='main-admin-config'>
                {/* {isMobile && (
                    <div className="establishment-info">
                        <div className='w-auto'>
                        <ActionButton type='submit' variant='primary' icon={<IconPen/>} className='admin-config-edit-button' onClick={() => navigate("/admin/school/config/general/update")}>
                            Modifier les informations
                        </ActionButton>
                        </div>
                        <SchoolCard 
                            variant='white'
                            img_url={establishmentDetails.establishmentPicture}
                            establishmentType={
                                establishmentDetails.establishmentType === "school"
                                ? "Établissements"
                                : "Entreprise"
                            }
                        >
                            <h1 className='h1'>{establishmentDetails.name}</h1>
                        </SchoolCard>
                    </div>
                )} */}

                <div className="license">
                    <div className="license-info">
                        <div className="license-header">
                            <h1>Licence</h1>
                            <p>Depuis le {establishmentDetails.created_at}</p>
                        </div>
                        <div className="license-content">
                            <div className="license-content-item">
                                <h1 className='form-title'>{establishmentDetails.adminCount} administrateur{isPlural(establishmentDetails.adminCount)}</h1>
                                <p className='text'>(nombre illimité)</p>
                            </div>
                            <div>
                                <div className="license-content-item">
                                    <h1 className='form-title'>{establishmentDetails.challengeCount || "Aucun"} challenge{isPlural(establishmentDetails.challengeCount)} créé{isPlural(establishmentDetails.challengeCount)}</h1>
                                    <p className='text'>({establishmentDetails.nbChallenges} challenge{isPlural(establishmentDetails.nbChallenges)} maximum)</p>
                                </div>
                                <ProgressBar progression={establishmentDetails.challengeCount / establishmentDetails.nbChallenges * 100} className="challenges-progress-bar" />
                            </div>
                        </div>
                    </div>
                    { establishmentDetails.challengeCount >= establishmentDetails.nbChallenges &&
                        (<div className="max-challenges-reached">
                            <IconWarn />
                            <p>Vous avez atteint le nombre de challenges inclus dans votre licence. Pour créer un nouveau challenge, contactez votre <span>conseiller référent.</span></p>
                        </div>)
                    }
                </div>
                <div className="contact">
                    <h1>Contacts</h1>
                    <div className="contact-content">
                        <div>
                            <h2 className='h2'>Contacter le support technique</h2>
                            <div className="contact-info">
                                <IconMail />
                                <p>support@makee.io</p>
                            </div>
                            <div className="contact-info">
                                <IconTel />
                                <p>06 09 10 46 76</p>
                            </div>
                        </div>
                        <div>
                            <h2 className='h2'>Contacter un conseiller référent</h2>
                            <div className="contact-info">
                                <IconMail />
                                <p>contact@challenkers.com</p>
                            </div>
                            <div className="contact-info">
                                <IconTel />
                                <p>06 09 10 46 76</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default AdminConfigGeneral;