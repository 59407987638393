// dnd-kit
import {
    DndContext,
    useSensor
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';

import { v4 as uuidv4 } from 'uuid';

// sensor
import GlobalPointerSensor from '../../utils/GlobalPointerSensor';

// types
// import Question, { IQuestion } from '../Question';

// react
import React, { useEffect, useState } from 'react';

// components
import ActionButton from '../../components/ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';

import { Box } from '@mui/material';
import { IStepQuestion } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeSteps/types';
import QuestionStep from '../../components/QuestionStep';
import './index.css';

interface QuestionListProps {
    questions: IStepQuestion[];
    stepId: number | string;
    addQuestionToStep: (stepId: string, questionName: string) => void;
    updateQuestionPosition: (stepId: string, newQuestions:any) => void;
    updateQuestion: (stepId: string, updatedQuestion: IStepQuestion) => void;
    removeQuestionFromStep: (stepId: string, questionId: string) => void;
    disabled?: boolean;
}

const DragAndDropQuestions = ({questions,stepId,addQuestionToStep,updateQuestionPosition,updateQuestion,removeQuestionFromStep, disabled}: QuestionListProps) => {

    const sensors = [useSensor(GlobalPointerSensor)];
    // Utilisez useMemo pour trier les questions lorsque la liste change
    const [sortedQuestions, setSortedQuestions] = useState<IStepQuestion[]>(questions.sort((a, b) => a.position - b.position));

    
    const handleDragEnd = (event: any) => {
        // setChange(true);
        const { active, over } = event;
        console.log("active", active.id);
        console.log("over", over.id);
        
        if(active.id !== over.id) {
            const oldIndex = questions.findIndex((question) => String(question.id) === active.id);
            const newIndex = questions.findIndex((question) => String(question.id) === over.id);

            const newQuestions = questions.map((question) => {
                console.log("ids", question.id, active.id, over.id)
                if(String(question.id) === active.id){
                    return {
                        ...question,
                        position: newIndex + 1,
                    }
                } else if(String(question.id) === over.id){
                    return {
                        ...question,
                        position: oldIndex + 1,
                    }
                }
                return question;
            });

            console.log("newQuestions iciiiii", newQuestions);
            updateQuestionPosition(String(stepId), newQuestions);
        }
    }
    useEffect(() => {
        console.log("questions", questions);
        setSortedQuestions(questions.sort((a, b) => a.position - b.position));
    }, [questions]);

    useEffect(() => {
        console.log("sortedQuestions", sortedQuestions);
    }, [sortedQuestions]);
    
    return (
        <div className="question-list">
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={sortedQuestions.map((question: IStepQuestion) => String(question.id))}
                    strategy={verticalListSortingStrategy}
                >
                    { sortedQuestions.length>0 && sortedQuestions.map((question: IStepQuestion, index) => (
                        <QuestionStep
                            key={question.id} // Utilisez une clé unique basée sur l'ID de la question
                            question={question}
                            stepId={String(stepId)}
                            updateQuestion={updateQuestion}
                            removeQuestionFromStep={removeQuestionFromStep}
                            disabled={disabled}
                        />
                    ))}
                </SortableContext>

            </DndContext>
            <ActionButton
                variant={disabled ? 'disabled' : 'secondary'}
                onClick={() => addQuestionToStep(String(stepId), '')}
                className='button-add-question'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter une question
            </ActionButton>
        </div>
    );
}

export default DragAndDropQuestions;