import React, { useState } from "react";
import "./index.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {ReactComponent as IconLink} from '../../assets/icon-link.svg';
import {ReactComponent as IconDownload} from '../../assets/icon-download.svg';
import {ReactComponent as IconEye} from '../../assets/icon-eye.svg';

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";
import api from "../../utils/api";

interface IResource {
    challengeId: number;
    file?: {
        id: number;
        originalFileName: string;
        fileName: string;
        fileUrl: string;
    } | null;
    fileId: number | null;
    id: number;
    link?: string | null;
    name: string;
    public: boolean;
}
const ResourceCard = ({ resource }: { resource: IResource }) => {
    const theme = useTheme();
    const isbetweenXsAndMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isbetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const ismorelg = useMediaQuery(theme.breakpoints.up('lg'));
    
    // const [isLink, setIsLink] = useState(false);
    // const [isdocument, setIsdocument] = useState(true);
    const [isLink, setIsLink] = useState(!resource.file);

    // upload file resource
    const downloadFileResource = async (url:string) => {
        await api.get('/downloadfile',
        {
            params: {
                url: resource.file?.fileUrl, 
                filename: resource.file?.originalFileName       
            },
            withCredentials: true,
            responseType: 'blob'
        })
        .then((res) => {
            // Créez un lien pour le téléchargement du fichier
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            console.log("blob", blob);
            
            const urlToDownload = window.URL.createObjectURL(blob);
            console.log("urlToDownload", urlToDownload);
            

            // Créez un élément d'ancre pour déclencher le téléchargement
            const a = document.createElement('a');
            a.href = urlToDownload;
            a.download = String(resource.file?.originalFileName);
            a.style.display = 'none';
            document.body.appendChild(a);

            // Déclenchez le téléchargement
            a.click();

            // Nettoyez l'URL créé
            window.URL.revokeObjectURL(urlToDownload);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    return (            
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" px={4} p={2} my={2} sx={{background:'#EDF1F7' ,borderRadius:'10px'}} key={resource.id}>
            <Grid item  xs={10}>
                    <p className="label-resource exceeding">{isLink ? t("page.challenge.resources.linkName") : t("page.challenge.resources.documentName")}</p>
                    <p className="name-resource exceeding">{resource.name}</p>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
                {isLink && resource.link ? (
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} sm={6}>
                            <a href={resource.link} target="_blank" rel="noopener noreferrer"><IconLink /></a>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <a href={resource.file?.fileUrl} target="_blank" rel="noopener noreferrer"><IconEye /></a>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <IconDownload className="cursor-pointer" onClick={() => resource && resource.file && downloadFileResource(resource.file?.fileUrl)} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default ResourceCard;
