import { Avatar, AvatarGroup, Button, Chip, Grid, useMediaQuery, useTheme } from "@mui/material";
import ActionButton from "../../../../../components/ActionButton";
import React, { useEffect, useState } from "react";
import { IChallenge, IProject } from "../ProjectRunning/types";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../../../assets/arrow-right.svg";
import { ReactComponent as IconDownload } from "../../../../../assets/icon-download.svg";
import { ReactComponent as IconEye } from "../../../../../assets/icon-eye.svg";
import { ReactComponent as EmptyPortfolio } from "../../../../../assets/imgs/portfolio-vide.svg";

import "./index.css";
import { IStepProject } from "./types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/type";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";

const ParticipantProjectFocus = () => {
    const navigate = useNavigate();
    const profileId = useState(useSelector((state: RootState) => state.rootProfile.id) as string | null);
    const projectId = useParams().projectId;
    const challengeId = useParams().id;
    const theme = useTheme();
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const roleName = useSelector((state: RootState) => state.rootProfile.name);
    const [challenge, setChallenge] = useState<IChallenge>();
    const [project, setProject] = useState<IProject>();
    const [stepsProject, setStepsProject] = useState<IStepProject[]>([]);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const redirectChallengePage = () => {
        navigate(`/challenge/${challengeId}/general`);
    }
    const redirectPortfolio = async (id: number) => {
        const url = `/participant/portfolio/${id}`
        navigate(url);
    }

    const viewDeliverable = async (url: string) => {
        window.open(url, '_blank')
    }

    const getProject = async (roleName: null | undefined | string) => {
        await api.get(`/${roleName !== "" ? roleName + "/project" : "project"}`, {
            params: {
                challengeId: challengeId,
                projectId: projectId
            },
            withCredentials: true,
        })
            .then((res) => {
                const project = res.data.project;
                const stepproject = res.data.stepsProject;
                const challenge = project.challenge
                setProject(project);
                setChallenge(challenge);
                setStepsProject(stepproject)
            })
            .catch((e) => {
                if (e.response.status == 400) { //On redirige sur la page "unauthorized" si on est pas autorisé à voir
                    navigate("/error/401")
                }
            })
    }

    useEffect(() => {
        console.log(roleName);
        getProject(roleName);
    }, [])

    const downloadDeliverable = async (url: string, fileName: string) => {
        await api.get('/downloadfile',
            {
                params: {
                    url: url,
                    filename: fileName
                },
                withCredentials: true,
                responseType: 'blob'
            }).then((res) => {
                // Créez un lien pour le téléchargement du fichier
                const blob = new Blob([res.data], { type: res.headers['content-type'] });

                const urlToDownload = window.URL.createObjectURL(blob);

                // Créez un élément d'ancre pour déclencher le téléchargement
                const a = document.createElement('a');
                a.href = urlToDownload;
                a.download = String(fileName);
                a.style.display = 'none';
                document.body.appendChild(a);

                // Déclenchez le téléchargement
                a.click();

                // Nettoyez l'URL créé
                window.URL.revokeObjectURL(urlToDownload);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const redirectEditProject = async () => {
        navigate(`/participant/challenge/project/edition/${projectId}`);
    }

    let editingUser = null;
    if (project && project.isEditing) {
        const editingProfile = project.team.profiles.find((profile) => Number(project.isEditing) === Number(profile.id))

        editingUser = editingProfile?.user.firstName;
    }
    let dateStartDeposite = moment(challenge?.steps[0]?.dateStartDeposite);
    let currentDate = moment();
    useEffect(() => {
        if (currentDate.isAfter(dateStartDeposite)) {

        }
    }, []);
    return (
        <section className="w-100" id="project-page">
            {challenge && (
                <div id="bloc-info-project-challenge" className="bloc-info-project-challenge">
                    <Grid container spacing={1} justifyContent={"space-between"} direction={"row"}
                        alignItems={"center"}>
                        <Grid item xs={12} md={9}>
                            <p className={!isDownMd ? "text-start" : "text-center"}>{challenge.establishments[0].name} {isDownMd ? "" : `${moment(String(challenge.startDate)).format("DD/MM/YYYY")} ${t("toDate")} ${moment(String(challenge.endDate)).format("DD/MM/YYYY")}`}</p>
                            <h1 className={`text-uppercase ${!isDownMd ? "text-start" : "text-center"}`}>{challenge.name}</h1>
                            <p className={!isDownMd ? "text-start" : "text-center"}>
                                <span>{!isDownMd ? "" : `${moment(String(challenge.startDate)).format("DD MMMM YYYY")} ${t("toDate")} ${moment(String(challenge.endDate)).format("DD MMMM YYYY")}`}</span>
                            </p>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={!isDownMd ? "text-end" : "text-center"}>
                                <ActionButton
                                    variant="little-underline"
                                    onClick={() => redirectChallengePage()}
                                    iconEnd={true}
                                    icon={<IconArrowRight />}
                                >
                                    {t("page.myProject.buttonSeeChallenge")}
                                </ActionButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
            <div className="bloc-info-profile-participation-team mb-4 mt-4">
                <div>
                    <h4 className="mt-1">{t("page.projectRunning.myTeam")}</h4>
                    <p style={{ fontSize: "16px" }}>{project?.team?.name} - {t("page.participantJoinTeamInvite.team")} {project?.team?.numero}</p>
                    <div className="mt-2 mb-2">
                        <div className={"group-avatar-teamspace"}>
                            <div>
                                <AvatarGroup max={5}>
                                    {project?.team?.profiles?.map((profile, index) => (
                                        <Avatar key={index}
                                            alt={profile.user.firstName + " " + profile.user.lastName}
                                            src={profile.user.profile_picture} />
                                    ))}
                                </AvatarGroup>
                            </div>
                            <div>
                                <div>
                                    <ActionButton
                                        variant="little-underline"
                                        onClick={() => navigate(`/participant/challenge/${challenge?.id}/team/${project?.team.id}/management`)}
                                    >
                                        {t("page.projectRunning.teamSpace")}
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {roleName === "participant" && challenge && challenge.status.name !== "finished" && (
                <div className="d-flex flex-column my-5">
                    <div className="m-auto">
                        <ActionButton
                            variant={currentDate.isBefore(dateStartDeposite) || project?.isEditing && Number(project?.isEditing) !== Number(profileId[0]) ? "disabled" : "primary"}
                            onClick={() => redirectEditProject()}
                        >
                            {t("page.myProject.button")}
                        </ActionButton>
                    </div>
                    {(editingUser && project?.isEditing && Number(project?.isEditing) !== Number(profileId[0])) &&
                        <p className="small text-center mt-1">{editingUser} travaille déjà sur ce projet.</p>}
                </div>
            )}
            <section className={`${!isDownMd ? "w-75" : "w-90"} m-auto my-4`}>
                {currentDate.isBefore(dateStartDeposite) &&
                    <div className='portfolio-empty'>
                        <p className='portfolio-empty-txt'>{t("page.myProject.projectWillAppear")}</p>
                        <EmptyPortfolio className='portfolio-empty-img' />
                    </div>
                }
                {currentDate.isAfter(dateStartDeposite) && (
                    <div>
                        <img className="w-100 border"
                            src={stepsProject[0]?.project?.file?.fileUrl ? stepsProject[0]?.project?.file?.fileUrl : project?.file ? project?.file.fileUrl : "/images/couverture-challenge.jpg"}
                            alt={stepsProject[0]?.project?.file?.fileName} />
                        <div id="team-detail">
                            <Grid container alignItems={"stretch"}>
                                <Grid item xs={12} md={6}>
                                    <div className="project-identity-main  h-100">
                                        <div className="project-identity  h-100">
                                            <h5>{t("page.myProject.nameOfYourProject")}</h5>
                                            {
                                                project?.name ? (
                                                    <h3
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${project?.name}`
                                                        }}
                                                    />
                                                ) : (
                                                    <p className={"italic"}>{t("page.myProject.projectName")}</p>
                                                )
                                            }
                                            <h5>{t("page.myProject.shortDesc")}</h5>
                                            {
                                                project?.shortDesc ? (
                                                    <p className="text"
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${project?.shortDesc}`
                                                        }}
                                                    />
                                                ) : (
                                                    <p className={"italic"}>{t("page.myProject.awaitingInfos")}</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="team h-100">
                                        <h5>{t("page.myProject.projectAuthor")}</h5>
                                        <p>{t("page.myProject.seePortfolio")}</p>
                                        <Grid container spacing={1} mt={1}>
                                            {project && project.team && project.team.profiles && (
                                                project.team.profiles.map((profile, index) => (
                                                    <Grid item xs={"auto"} sx={{ wordBreak: "break-all" }} key={index}>
                                                        <Button onClick={() => redirectPortfolio(profile.id)}>
                                                            <Chip deleteIcon={<IconArrowRight
                                                                className="go-to-portfolio" />}
                                                                onDelete={() => redirectPortfolio(profile.id)} sx={{
                                                                    height: '30px',
                                                                    background: "#fff",
                                                                    borderRadius: '20px'
                                                                }} size="small" key={index} avatar={<Avatar sx={{
                                                                    height: '30px !important',
                                                                    width: '30px !important',
                                                                    marginLeft: '0px !important',
                                                                    marginRight: '0px !important'
                                                                }} alt="Natacha"
                                                                    src={profile.user.profile_picture ? profile.user.profile_picture : ""} />}
                                                                label={`${profile.user.firstName} ${profile.user.lastName}`} />
                                                        </Button>
                                                    </Grid>
                                                ))
                                            )}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {project && (
                            <div id="longdesc">
                                <h5>{t("page.myProject.longDesc")}</h5>
                                {
                                    project?.longDesc ? (
                                        <p className="text"
                                            dangerouslySetInnerHTML={{
                                                __html: `${project && project.longDesc}`
                                            }}
                                        />
                                    ) : (
                                        <p className={"italic"}>{t("page.myProject.awaitingInfos")}</p>
                                    )
                                }
                            </div>
                        )}
                        <section id="steps" className="mt-3">
                            {
                                project?.challenge?.steps.map((step: any, index: number) =>

                                    moment().isAfter(moment(step.dateStartDeposite)) && (
                                        <div key={index} className={"mb-4"}>
                                            {stepsProject && stepsProject[index] ? (
                                                <div className="mt-5" key={index}>
                                                    <div
                                                        className={(step?.deliverable_step_projects && (step?.step_projects[0]?.deliverable_step_projects?.length > 0) && (step?.questions?.length + step?.deliverables?.length > 0)) ? "mb-2" : ""}>
                                                        <h4 className="pb-3 number-step">{step?.name}
                                                            {(step?.questions?.filter((question: any) => question?.answers && question?.answers?.some((answer: any) => answer?.answerText?.trim().length > 0)).length + (step?.step_projects ? step?.step_projects[0]?.deliverable_step_projects?.length : 0)) === 0 &&
                                                                <span> - {t("page.myProject.noInfoProvided")}</span>}
                                                        </h4>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="mt-5">
                                                    <div
                                                        className={"mb-2"}>
                                                        <h4 className="pb-3 number-step">{step?.name} <span>- {t("page.myProject.noInfoProvided")}</span></h4>
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                step?.questions?.map((question: any, indexQuestion: number) => (
                                                    <div key={indexQuestion}>
                                                        <h5
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${question.questionText}`
                                                            }}
                                                        />
                                                        {
                                                            question.answers[0]?.answerText ? (
                                                                <p className="text"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: `${question.answers[0].answerText}`
                                                                    }}
                                                                />
                                                            ) : (
                                                                <p className={"italic"}>{t("page.myProject.awaitingInfos")}</p>
                                                            )
                                                        }
                                                    </div>
                                                ))
                                            }
                                            {(stepsProject && stepsProject.length > 0 && step?.step_projects) ? (
                                                <div className="step-detail">
                                                    <div className="pb-4 mb-2">
                                                        <h5>{t("page.myProject.deliverables")}</h5>
                                                        {
                                                            !step?.step_projects[0] && (
                                                                <p className={"italic"}>{t("page.myProject.noDeliverables")}</p>
                                                            )
                                                        }
                                                        {
                                                            step?.step_projects[0]?.deliverable_step_projects?.length === 0 && (
                                                                <p className={"italic"}>{t("page.myProject.noDeliverables")}</p>
                                                            )
                                                        }
                                                    </div>
                                                    {step?.step_projects[0]?.deliverable_step_projects?.length > 0 && (
                                                        <div className="pb-4 mb-2">
                                                            {step?.step_projects[0]?.deliverable_step_projects?.length > 0 && (
                                                                <h5>{t("page.myProject.documentsSubmitted")}</h5>
                                                            )}
                                                            {step?.step_projects[0]?.deliverable_step_projects && step?.step_projects[0]?.deliverable_step_projects?.length > 0 && (
                                                                step?.step_projects[0]?.deliverable_step_projects?.map((deliverable_step_projects: any, index: number) => (
                                                                    deliverable_step_projects.file.originalFileName !== "" &&
                                                                    <div className="deliverable" key={index}>
                                                                        <Grid container alignItems={"center"} padding={1}
                                                                            key={index}>
                                                                            <Grid item xs={9}>
                                                                                <h5 className="inline">
                                                                                    {deliverable_step_projects.file.originalFileName}
                                                                                </h5>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className="text-end">
                                                                                    <IconEye className="cursor-pointer me-3"
                                                                                        onClick={() => viewDeliverable(deliverable_step_projects.file.fileUrl)} />
                                                                                    <IconDownload className="cursor-pointer"
                                                                                        onClick={() => downloadDeliverable(deliverable_step_projects.file.fileUrl, deliverable_step_projects.file.originalFileName)} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                                : (
                                                    <div className="pb-4 mb-2">
                                                        <h5>{t("page.myProject.deliverables")}</h5>
                                                        <p className={"italic"}>{t("page.myProject.noDeliverables")}</p>
                                                    </div>
                                                )}
                                        </div>
                                    )

                                )
                            }
                        </section>
                    </div>
                )}
            </section>
        </section>
    )
}

export default ParticipantProjectFocus;
