//Style
import './index.css';

//React
import React, { useState, useEffect } from 'react';

//Contexte
import { useAuth } from '../../context/AuthContext';

//MUI
import { useMediaQuery, useTheme } from "@mui/material";

//Router DOM
import { useLocation, useNavigate } from 'react-router-dom';

//Assets
import { ReactComponent as DiamondMobile } from '../../assets/diamond-loader.svg';
import { ReactComponent as DiamondHeader } from '../../assets/diamond-colors-horizontal.svg';

//Components
import ActionButton from '../../components/ActionButton';
import SwitchTranslate from "../../components/SwitchTranslate";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";

//Props
interface LoginHeaderProps {
    title?: string;
}

//Bannière de connexion
// -> Vérifie si le processus de connexion soit fini avant de vérifier si on est connecté
// -> Si on est pas connecté, n'affiche pas la bannière (ici, retourne NULL)
export const LoginHeader: React.FC<LoginHeaderProps> = ({
    title
}) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const location = useLocation();

    const { isAuthenticated, allProfiles, finishedCheckingAuth } = useAuth();
    const [isNotAuthAfterRender, setIsNotAuthAfterRender] = useState(false);

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    useEffect(() => {
        setIsNotAuthAfterRender(finishedCheckingAuth && !isAuthenticated);
    }, [finishedCheckingAuth])

    const handleSignupClick = () => {
        localStorage.setItem("redirect", location.pathname);
        navigate("/signup/participant");
    }
    const handleCompanyOffer = () => {
        window.open('https://get.makee.io/', '_blank')
    }

    const handleLoginClick = () => {
        localStorage.setItem("redirect", location.pathname);
        navigate("/login/1")
    }

    if (!isNotAuthAfterRender) return null;
    return (
        <div className='loginheader'>
            {!isMdDown ? <DiamondHeader className={"diamond-logo"} onClick={() => navigate("/")} /> :
                <DiamondMobile className={"diamond-logo"} onClick={() => navigate("/")} />}
            {!isMdDown && <h2 className='loginheader-title'>{title}</h2>}
            <SwitchTranslate className={"translate-icon-login-header"} />
            <div className='loginheader-accountBtns'>
                <ActionButton variant='tertiary'
                    onClick={handleCompanyOffer}>{t("companyOffer")}</ActionButton>
                <ActionButton variant='primary' onClick={handleLoginClick}>{t("login")}</ActionButton>
            </div>
        </div>
    )
}

export default LoginHeader;
