import {NavLink, useLocation} from 'react-router-dom';
import {ReactComponent as LogoHeader} from '../../assets/diamond-colors-horizontal.svg';
import {ReactComponent as LogoHeaderTablet} from '../../assets/diamond-loader.svg';
import {ReactComponent as IconHome} from '../../assets/icon-home.svg';
import {ReactComponent as IconChallenge} from '../../assets/icon-challenge.svg';
import {ReactComponent as IconSchool} from '../../assets/icon-school.svg';
import {ReactComponent as IconPortfolio} from '../../assets/icon-portfolio.svg';
import { ReactComponent as IconMail } from '../../assets/icon-message.svg';
import {ReactComponent as IconDiamond} from '../../assets/icon-diamond.svg';
import './index.css';
import {useDispatch, useSelector} from 'react-redux';
import {ProfileState, RootState} from "../../app/type";
import {useAuth} from "../../context/AuthContext";
import {Skeleton} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";


const NavBar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {profile} = useAuth();

    const userType = profile as 'admin' | 'participant';

    const error = useSelector((state: RootState) => state.error.error);
    const errorMessage = useSelector((state: RootState) => state.error.message);
    const profileId = useSelector((state: RootState) => state.rootProfile.id);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const listLinks = {
        admin: [
            {
                name: "Accueil",
                keyName: 'home',
                path: '/',
                icon: <IconHome className='icons'/>,
            },
            {
                name: 'Challenges',
                keyName: 'challenges',
                path: '/admin/challenges',
                icon: <IconChallenge className='icons'/>,
            },
            {
                name: 'Établissement',
                keyName: 'school',
                path: '/admin/school/config',
                icon: <IconSchool className='icons'/>,
            },
            // {
            //     name: 'Annonces',
            //     keyName: 'announcement',
            //     path: 'https://get.makee.io/depot-referencer-challenge/',
            //     icon: <IconDiamond className='icons'/>,
            // },
        ],
        participant: [
            {
                name: t("menuParticipant.home"),
                keyName: 'home',
                path: '/',
                icon: <IconHome className='icons'/>,
            },
            {
                name: t("menuParticipant.myProjects"),
                keyName: 'projets',
                path: '/my-projects',
                icon: <IconChallenge className='icons'/>,
            },
            {
                name: t("menuParticipant.portfolio"),
                keyName: 'portfolio',
                path: `/participant/portfolio/${profileId}`,
                icon: <IconPortfolio className='icons'/>,
            },
            // {
            //     name: t("menuParticipant.announcements"),
            //     keyName: 'announcement',
            //     path: 'https://get.makee.io/consulter-nos-challenges/',
            //     icon: <IconDiamond className='icons'/>,
            // },
        ],
    };

    const links = listLinks[userType];

    function getProfileText(profile: string) {
        switch (profile) {
            case 'admin':
                return 'Administrateur';
                break;
            case 'participant':
                return 'Participant';
                break;
            default:
                return 'Profil Inconnu';
        }
    }

    const [isSkeleton, setIsSkeleton] = useState<boolean>(false);
    useEffect(() => {
        if (location.pathname.includes("/preview/challenge")) {
            setIsSkeleton(true);
        } else {
            setIsSkeleton(false);
        }
        // console.log("location.pathname = ", location.pathname);
        // console.log("path = ", links[0].path);
    }, [location.pathname]);
    return (
        <div className='nav-bar'>
            <div className='nav-bar-header'>
                <NavLink to={`/`}><LogoHeader className='diamond-header-svg-desktop'/></NavLink>
                <NavLink to={`/`}><LogoHeaderTablet className='diamond-header-svg-tablet'/></NavLink>
                <div className='text-status-user'>
                    <p>{t("menuParticipant.connectedAs")}</p>
                    <p className='small-lead-text delete-space-paragraph'>
                        {
                            location.pathname.includes("/preview/challenge") ? (
                                    <Skeleton variant={"text"}/>
                                ) :
                                getProfileText(profile)
                        }
                    </p>
                </div>
            </div>
            <div className='nav-bar-block-navlinks'>
                <div className='nav-bar-navlinks-center'>
                    <div className='nav-bar-navlinks'>
                        <div className='nav-bar-navlinks-item'>
                            {links &&
                                links.map((link, index: number) =>
                                    <ul key={index}>
                                        {
                                            isSkeleton ? (
                                                <>
                                                    <li className={`nav-bar-navlinks-list${(location.pathname === link.path) ? ' nav-bar-navlinks-list-active' : ''}`}>
                                                        <div className='nav-bar-navlinks-icon'>
                                                            <Skeleton variant={"circular"}
                                                                      sx={{minWidth: "35px", minHeight: "35px"}}/></div>
                                                        <div className='nav-bar-navlinks-links'>
                                                            <Skeleton variant={"text"} sx={{minWidth: "10vw"}}/></div>
                                                    </li>
                                                </>
                                            ) : (
                                                <NavLink
                                                    to={link.path}
                                                    target={link.keyName === "announcement" ? "_blank" : ""}
                                                    className='nav-link'
                                                    onClick={() => dispatch({type: 'CLEAR_ERROR'})}
                                                >
                                                    <li className={`nav-bar-navlinks-list${location.pathname === link.path ? ' nav-bar-navlinks-list-active' : ''}`}>
                                                        <div className='nav-bar-navlinks-icon'>{link.icon}</div>
                                                        <div className='nav-bar-navlinks-links'>{link.name}</div>
                                                    </li>
                                                </NavLink>
                                            )
                                        }
                                    </ul>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='nav-bar-footer'>
                <p><strong>{t("contactSupport")}</strong><br/>
                    <IconMail /> <a href="mailto:support@makee.io">support@makee.io</a></p>
            </div>
        </div>
    );
};

export default NavBar;





