//  Cette carte est utilisée pour afficher le nom et la photo d'un établissement

//  Elle possède plusieurs propriétés :
//  - img_url : l'url de l'image de l'établissement
//  - variant : le type de carte (grey ou normal)
//  - onClick : la fonction à exécuter au clic
// - children : le texte à afficher dans la carte (nom de l'établissement)

// react
import { useEffect, useState } from 'react';

// style
import './index.css'

// components
import BaseButton from '../BaseButton';

// props
interface SchoolCardProps {
    children: React.ReactNode;
    img_url?: string;
    variant?: 'grey' | 'white';
    onClick?: () => void;
    focus?: boolean;
    hover?: boolean;
    establishmentType?: string;
}

const SchoolCard = ({
    children,
    img_url,
    variant,
    onClick,
    focus,
    hover,
    establishmentType
}: SchoolCardProps) => {

    const [classNameVariant, setClassNameVariant] = useState('');
    const [classNameFocus, setClassNameFocus] = useState('');
    const [classNameHover, setClassNameHover] = useState('');

    // On définit la classe CSS à appliquer en fonction du type de carte
    useEffect(() => {
        variant === 'grey' ?
            setClassNameVariant('school-card-grey') :
            variant === 'white' ?
                setClassNameVariant('school-card-white') :
                setClassNameVariant('school-card');
    }, [variant])

    useEffect(() => {
        focus ?
            setClassNameFocus('focus') :
            setClassNameFocus('');
    }, [focus])

    useEffect(() => {
        hover ?
            setClassNameHover('hover') :
            setClassNameHover('');
    }, [hover])

    return (
        <div className='schoolcard-container'>
            <div className={classNameVariant + ' ' + classNameFocus + ' ' + classNameHover} onClick={onClick}>
                <img src={`${img_url}`} className="img-left-school-card" />
                <div className='text-school-card'>
                    {establishmentType && <BaseButton className='school-card-button'>{establishmentType}</BaseButton>}
                    <p className='text-school-card h2'>{children}</p>
                </div>
            </div>
        </div>
    )
}

export default SchoolCard;
