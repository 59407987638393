// react-routeur
import { Link, useLocation } from "react-router-dom";

// mui
import { Box, FormControl, TextField } from "@mui/material";

// components
import ActionButton from "../../../../../../components/ActionButton";

// context
import { useAuth } from "../../../../../../context/AuthContext";

// assets
import DiamondColor from '../../../../../../assets/diamond-color';
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// style
import './index.css';

// redux
import { useSelector } from "react-redux";
import { RootState } from '../../../../../../app/type';


const MergeExistingAccount = () => {

    // context
    const { mergeAccountAdmin, mergeAccountParticipant, searchProfile } = useAuth();
    const location = useLocation();

    // redux state
    const error = useSelector((action: RootState) => action.error.error);
    const errorMessage = useSelector((action: RootState) => action.error.message);


    return (
        <>
            <div className="merge-existing-account-svg">
                <div className='merge-existing-account'>
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login' />
                    <h1 className='h1-auth'>Je me connecte à mon compte</h1>
                    <FormControl onSubmit={(e) => {
                        location.pathname.includes('admin') ? mergeAccountAdmin(e) :
                            location.pathname.includes('participant') ? mergeAccountParticipant(e) :
                                console.log('erreur')
                    }}
                    >
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { marginTop: '40px' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                {...(error && { error: true })}
                                id="email"
                                label="Identifiant"
                                variant="outlined"
                                placeholder="example@mail.com"
                                type="email"
                                className='form-text-auth login-email-psw-input'
                                defaultValue={searchProfile && searchProfile.user.email}
                                disabled
                            />

                            <TextField
                                {...(error && { error: true })}
                                id="password"
                                label="Mot de Passe"
                                variant="outlined"
                                placeholder="************"
                                type="password"
                                className='form-text-auth'
                                helperText={error && errorMessage}
                            />

                            <ActionButton
                                auth
                                className="button-text-auth"
                                type="submit"
                                variant='primary'
                            >
                                Se connecter
                            </ActionButton>
                        </Box>
                    </FormControl>

                    {/* lien vers la page de récupération du mot de passe */}
                    <Link to="/reset-password" className='merge-existing-account-link small-lead-text'>
                        Mot de passe oublié ?
                    </Link>
                </div>

                <DiamondColor className='diamond' />
            </div>
        </>
    )
}

export default MergeExistingAccount;