import {Action} from 'redux';
import {UserState} from '../type';
import {DEFAULT_PROFILE_PICTURE} from '../../utils/consts';

// Définissez le type pour l'action 'SET_USER'
interface SetUserAction extends Action<'SET_USER'> {
    payload: {
        establishment: string;
        firstname: string;
        lastname: string;
        birthdate: string;
        email_recup: string;
        email_pro: string;
        profile_picture: string;
        school: string;
        tel: string;
        job: string;
        company: string;
        degree: string;
    };
}

const initialState: UserState = {
    firstname: '',
    lastname: '',
    birthdate: '',
    email_pro: '',
    email_recup: '',
    profile_picture: '',
    establishment: '',
    tel: '',
    job: '',
    company: '',
    degree: '',
    school: ''
};

const userReducer = (state = initialState, action: Action): UserState => {
    switch (action.type) {
        case 'SET_USER':
            // Utilisez une assertion de type pour indiquer que l'action a le type approprié
            const setUserAction = action as SetUserAction;
            return {
                ...state,
                firstname: setUserAction.payload.firstname || state.firstname,
                lastname: setUserAction.payload.lastname || state.lastname,
                email_recup: setUserAction.payload.email_recup || state.email_recup,
                email_pro: setUserAction.payload.email_pro || state.email_pro,
                profile_picture: setUserAction.payload.profile_picture || state.profile_picture,
                tel: setUserAction.payload.tel || state.tel,
                establishment: setUserAction.payload.establishment || state.establishment,
                birthdate: setUserAction.payload.birthdate || state.birthdate,
                job: setUserAction.payload.job || state.job,
                company: setUserAction.payload.company || state.company,
                degree: setUserAction.payload.degree || state.degree,
                school: setUserAction.payload.school || state.school
            };

        case 'CLEAR_USER':
            return initialState;

        case 'CLEAR_USER_ESTABLISHMENT':
            return {
                ...state,
                establishment: '',
            };
        default:
            return state;
    }
};

export default userReducer;
