// react-dom
import {useNavigate, useLocation} from 'react-router-dom';

// components
import ActionButton from '../../../components/ActionButton';

// style
import './index.css'

// assets
import {ReactComponent as DiamondColor} from '../../../assets/diamond-error.svg';
import {Sign} from 'crypto';
import {useAuth} from "../../../context/AuthContext";
import { Chip, Grid, useMediaQuery, useTheme } from '@mui/material';
import {ReactComponent as ErrorImg} from "../../../assets/imgs/page404.svg"

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../components/SetUpTranslation";

const Error498 = () => {
    // fonction qui permet de naviguer vers une autre page
    const navigate = useNavigate();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    const chipLabel = `${t("errors.error")} 498`;

    return (
        <>
            <section className="error-498">
                <Grid container spacing={isMdDown ? 3 : 10} alignItems={'center'} height={'100%'}>
                    <Grid item md={6} display={'flex'} justifyContent={'center'} width={'100%'}>
                        <ErrorImg className='error-498-img' width={isMdDown ? '70%' : '90%'}/>
                    </Grid>
                    <Grid item md={6} className='error-498-text'>
                        <Chip label={chipLabel} sx={{ width: '7rem'}} variant='outlined' size='medium' className='error-498-text-chip'/>
                        <h1>{t("errors.498.title")}</h1>
                        <p>{t("errors.498.lightText")}</p>
                        <div className='error-498-text-btns'>
                            <ActionButton
                                variant='primary'
                                onClick={() => {
                                    navigate('/login/1');
                                }
                            }>
                                {t("errors.498.buttonPrimary")}
                            </ActionButton>
                            <ActionButton
                                variant='tertiary'
                                onClick={() =>
                                    window.location.href = 'mailto:contact@makee.io'
                                }>
                                {t("errors.498.buttonTertiary")}
                            </ActionButton>
                        </div>
                    </Grid>
                </Grid>

                {/* <DiamondColor className='return-svg'/>
                <div>
                    <h1 className='h1-auth'>Erreur 498</h1>
                    <p className='text'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam suscipit enim
                        quasi mollitia dolore, incidunt impedit neque aperiam itaque sed, totam commodi officiis unde.
                        Veritatis esse quaerat maxime sequi praesentium. Lorem, ipsum dolor sit amet consectetur
                        adipisicing elit.
                    </p>

                    {/* bouton qui renvoie à la page d'accueil 
                    <ActionButton 
                        variant='primary' 
                        onClick={() =>
                            profile === ('admin' || 'participant') ? (
                                navigate('/home')
                            ) : (navigate('/'))
                    }>
                        allo
                    </ActionButton>
                </div>
                <DiamondColor className='normal-svg'/> */}
            </section>
        </>
    )
}

export default Error498;