import React, { useEffect, useState } from 'react'
import "./index.css"
import { ReactComponent as IconEdit } from "../../../../../assets/icon-edit.svg";
import { ReactComponent as IconPrivate } from "../../../../../assets/icon-private.svg";
import { ReactComponent as IconPublic } from "../../../../../assets/icon-public.svg";
import { ReactComponent as IconGroup } from "../../../../../assets/icon-group.svg";
import { ReactComponent as IconAlert } from "../../../../../assets/icon-alert.svg";
import { ReactComponent as IconMessage } from "../../../../../assets/icon-message.svg";
import { ReactComponent as IconCopy } from "../../../../../assets/icon-copy.svg";
import { ReactComponent as IconChange } from "../../../../../assets/icon-change.svg";
import { ReactComponent as IconLogout } from "../../../../../assets/icon-logout.svg";
import { ReactComponent as IconInfo } from "../../../../../assets/icon-info.svg";
import { ReactComponent as IconAdd } from "../../../../../assets/icon-add.svg";
import { ReactComponent as IconLike } from "../../../../../assets/icon-like.svg";
import { ReactComponent as IconClose } from "../../../../../assets/icon-close.svg";

import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Grow, IconButton,
    InputAdornment,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    styled,
    TextField,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ActionButton from '../../../../../components/ActionButton';
import moment from "moment";
import { useNavigate, useParams } from 'react-router-dom';

import { IChallenge, IPortfolio, ITeam, IUser } from './type';
import Modal from '../../../../../modules/Modal';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const TeamManagement = () => {
    const challengeId = useParams().id;
    const teamId = useParams().teamId;
    const navigate = useNavigate();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [openToolTip, setOpenToolTip] = useState(false);
    const [openToolTipLink, setOpenToolTipLink] = useState(false);
    const [openToolTipEmail, setOpenToolTipEmail] = useState(false);
    const [openEditTeamName, setOpenEditTeamName] = useState(false);

    const [challenge, setChallenge] = useState<any>();
    const [resources, setResources] = useState<any>();
    const [team, setTeam] = useState<ITeam>();
    const [user, setUser] = useState<IUser>();
    const [nbMembers, setNbMembers] = useState<number>(0);
    const [portfolio, setPortfolio] = useState<IPortfolio[]>();
    const [saveRegistrationType, setSaveRegistrationType] = useState<boolean>(true);
    const [registrationType, setRegistrationType] = useState<string>("public");
    const [errorRegistrationType, setErrorRegistrationType] = useState<string>("");

    const [newTeamName, setNewTeamName] = useState<string>("");
    const [saveTeamName, setSaveTeamName] = useState<string>("");
    const [errorTeamName, setErrorTeamName] = useState<string>("");
    const [message, setMessage] = useState<string | null>(null);
    const [saveMessage, setSaveMessage] = useState<string | null>(null);

    //Error quite team
    const [errorQuite, setErrorQuite] = useState<string>("");
    const [openErrorQuite, setOpenErrorQuite] = useState<boolean>(false);

    //ModalRegistrationType
    const [openModalRegistrationType, setOpenModalRegistrationType] = useState(false);
    const [openModalInviteMates, setOpenModalInviteMates] = useState(false);
    const [openModalInviteMatesValidate, setOpenModalInviteMatesValidate] = useState(false);
    //ModalQuitTeam
    const [openModalQuitTeam, setOpenModalQuitTeam] = useState(false);
    const [teamEmails, setTeamEmails] = useState<any>();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    const [sent, setSent] = useState<boolean>();
    const GetMemberTeam = async () => {
        console.log("get member team");
        await api.get("/participant/team/teammembers",
            {
                params: {
                    challengeId: challengeId,
                    teamId: teamId
                },
                withCredentials: true
            }
        ).then((res) => {
            console.log("GetMemberTeam", res.data);
            const challenge = res.data.challenge
            setChallenge(challenge);
            setResources(res.data.resources);
            setTeam(challenge.projects[0].team);
            setNbMembers(challenge.projects[0].portfolios.length);
            setPortfolio(challenge.projects[0].portfolios);
            setNewTeamName(challenge.projects[0].team.name)
            setSaveTeamName(challenge.projects[0].team.name)
            setSaveRegistrationType(challenge.projects[0].team.registration)
            setMessage(challenge.projects[0].team.bio)
            setSaveMessage(challenge.projects[0].team.bio)
            setRegistrationType(challenge.projects[0].team.registration ? "public" : "private")
            setSaveRegistrationType(challenge.projects[0].team.registration);
            const emails: string[] = res.data.challenge.projects[0].portfolios.map((portfolio: {
                profile: { user: { profiles: { email: any; }[]; }; };
            }) =>
                portfolio.profile.user.profiles[0].email
            );
            console.log(emails);
            setTeamEmails(emails);
            setSent(res.data.portfolio?.project?.step_projects[0]?.sent);
            console.log("valeur de sent : ", sent);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        console.log("challenge", challenge);
        console.log("newTeamName", newTeamName);

    }, [challenge, newTeamName])
    useEffect(() => {
        GetMemberTeam();
    }, [])

    const handleTooltipOpen = () => {
        setOpenToolTip(true);
        navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}/participant/challenge/${challengeId}/team/${teamId}`)
    };
    const handleTooltipOpenLink = (link: string) => {
        setOpenToolTipLink(true);
        navigator.clipboard.writeText(`${link}`)
    }
    const handleTooltipClose = () => {
        setOpenToolTip(false);
    };
    const handleTooltipCloseLink = () => {
        setOpenToolTipLink(false);
    };
    const [tooltipOpenStates, setTooltipOpenStates] = useState<any>({});

    const handleTooltipOpenEmail = (email: any, index: any) => {
        setTooltipOpenStates((prevState: any) => ({
            ...prevState,
            [index]: true
        }));
        navigator.clipboard.writeText(email);
    };
    const handleTooltipCloseEmail = (index: any) => {
        setTooltipOpenStates((prevState: any) => ({
            ...prevState,
            [index]: false
        }));
    };

    useEffect(() => {
        if (openToolTip) {
            setTimeout(() => {
                setOpenToolTip(false);
            }, 2000);
        }
        if (openToolTipLink) {
            setTimeout(() => {
                setOpenToolTipLink(false);
            }, 2000);
        }
        if (tooltipOpenStates) {
            setTimeout(() => {
                setTooltipOpenStates(false);
            }, 2000);
        }
    }, [openToolTip, openToolTipLink, tooltipOpenStates]);

    const handleEmailIconClick = (email: string) => {
        window.location.href = `mailto:${email}`;
    };
    const OpenEditTeamName = () => {
        setOpenEditTeamName(true);
    }

    const CloseEditTeamName = () => {
        setOpenEditTeamName(false);
        setNewTeamName(saveTeamName)
        setErrorTeamName("")
    }

    const EditTeamName = async () => {
        console.log("mettre à jour le nom de l'équipe");
        setErrorTeamName("")
        await api.put("/participant/team/updateteamname",
            {
                challengeId: challengeId,
                teamId: teamId,
                newname: newTeamName
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            setOpenEditTeamName(false);
            GetMemberTeam()
        }).catch((err) => {
            console.log(err);
            setErrorTeamName(err.response.data.message)
        })
    }

    const ChangeRegistrationType = async () => {
        console.log("mettre à jour le type d'inscription de l'équipe");
        setErrorRegistrationType("")
        await api.put("/participant/team/updateteamregistrationtype",
            {
                challengeId: challengeId,
                teamId: teamId,
                registration: registrationType === "public",
                bio: message
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            setSaveMessage("")
            setMessage("")
            setOpenModalRegistrationType(false);
            GetMemberTeam()
        }).catch((err) => {
            console.log(err);
            setErrorRegistrationType(err.response.data.message)
        })
    }

    const cancelChangeRegistrationType = () => {
        setOpenModalRegistrationType(false);
        setErrorRegistrationType("")
        setRegistrationType(saveRegistrationType ? "public" : "private")
        setMessage(saveMessage)
    }

    const quitTeam = async (isClickLeaveAndDeleteTeam: boolean) => {
        console.log("quitter l'équipe");
        await api.put("/participant/team/quitteam",
            {
                challengeId: challengeId,
                teamId: teamId,
                isClickLeaveAndDeleteTeam: isClickLeaveAndDeleteTeam,
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            setOpenModalQuitTeam(false);
            navigate(`/challenge/${challengeId}/general`)
        }).catch((err) => {
            console.log("je passe ici");

            setOpenModalQuitTeam(false);

            setErrorQuite(err.response.data.message)
            setOpenErrorQuite(true)
            console.log(err);
        })
    }

    const [inviteMatesListTest, setInviteMatesListTest] = useState([
        "yael.busser@gmail.com",
    ]);

    interface IError {
        state: boolean;

        message: string;
    }

    interface IBulkEmail {
        id: string;

        email: "";

        error: IError;
    }

    const [error, setError] = useState<IError>(
        {
            state: false,
            message: "",
        }
    );
    const [bulkEmail, setBulkEmail] = useState<IBulkEmail[]>([
        {
            id: uuidv4(),
            email: "",
            error: {
                state: false,
                message: "",
            }
        }
    ]);

    function deleteTextFields(index: string) {
        const newBulkEmail = [...bulkEmail];
        const findIndex = newBulkEmail.findIndex((email) => email.id === index);
        newBulkEmail.splice(findIndex, 1);
        setBulkEmail(newBulkEmail);
    }

    const modifyEmail = (e: any) => {
        let { id, value } = e.target;
        const findIndex = bulkEmail.findIndex((email) => email.id === id);
        if (findIndex !== -1) {
            const newValueBulk = [...bulkEmail];
            newValueBulk[findIndex].email = value;
            setBulkEmail(newValueBulk);
        }
    }

    function validateEmail(email: string) {
        const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(String(email).toLowerCase());
    }

    function addTextFields() {
        const isError = bulkEmail.some((email) => email.email === "" || !validateEmail(email.email));
        if (isError) {
            const newBulkEmail = [...bulkEmail];
            const bulkEmailError = bulkEmail.find((email) => email.email === "" || !validateEmail(email.email));
            const indexError = bulkEmail.findIndex((email) => email === bulkEmailError);
            const bulkEmailErrorEmpty = bulkEmail.some((email) => email.email === "");
            const bulkEmailErrorNotValidated = bulkEmail.some((email) => !validateEmail(email.email));
            if (bulkEmailErrorEmpty) {
                newBulkEmail[indexError].error.state = true;
                newBulkEmail[indexError].error.message = `${t("page.teamManagement.error.enterEmail")}`;
            } else if (bulkEmailErrorNotValidated) {
                newBulkEmail[indexError].error.state = true;
                newBulkEmail[indexError].error.message = `${t("page.teamManagement.error.validEmail")}`;
            }
            setBulkEmail(newBulkEmail);
        } else {
            setBulkEmail([...bulkEmail, { id: uuidv4(), email: "", error: { state: false, message: "" } }]);
        }
    }

    const validateAndSetError = (email: IBulkEmail) => {
        const newBulkEmail = [...bulkEmail];
        const emailToUpdate = newBulkEmail.find((e) => e.id === email.id);
        if (emailToUpdate) {
            if (emailToUpdate.email === "") {
                emailToUpdate.error.state = true;
                emailToUpdate.error.message = `${t("page.teamManagement.error.enterEmail")}`;
            } else if (!validateEmail(emailToUpdate.email)) {
                emailToUpdate.error.state = true;
                emailToUpdate.error.message = `${t("page.teamManagement.error.validEmail")}`;
            } else {
                emailToUpdate.error.state = false;
                emailToUpdate.error.message = "";
            }
            setBulkEmail(newBulkEmail);
        }
    };
    const inviteMatesToTeam = async () => {
        const isError = bulkEmail.some((email) => email.email === "" || !validateEmail(email.email));
        if (!isError) {
            const emails: any = [];
            bulkEmail.forEach((email) => {
                emails.push(email.email);
            });
            await api.post("/participant/team/invite/mates/email",
                {
                    language: localStorage.getItem("translation"),
                    emails: emails,
                    team: team,
                    challenge: challenge,
                },
                {
                    withCredentials: true
                }
            ).then((res) => {
                setOpenModalInviteMates(false);
                setOpenModalInviteMatesValidate(true);
            }).catch((err) => {
                console.log(err);
            })
            setBulkEmail([{
                id: uuidv4(),
                email: "",
                error: {
                    state: false,
                    message: "",
                }
            }]);
        } else {
            if (bulkEmail[0].email === "") {
                setBulkEmail([{
                    id: uuidv4(),
                    email: bulkEmail[0].email,
                    error: {
                        state: true,
                        message: `${t("page.teamManagement.error.enterEmail")}`,
                    }
                }]);
            } else if (!validateEmail(bulkEmail[0].email)) {
                setBulkEmail([{
                    id: uuidv4(),
                    email: bulkEmail[0].email,
                    error: {
                        state: true,
                        message: `${t("page.teamManagement.error.validEmail")}`,
                    }
                }]);
            }
        }
    }
    moment.locale(i18n.language);

    const sendEmailToAllTeam = () => {
        console.log(teamEmails);
        window.location.href = `mailto:${teamEmails.join(',')}`;
    };
    return (
        <section id="section_team_mangement">
            {challenge && team && nbMembers > 0 && portfolio && (
                <>
                    <>
                        <h1>{t("page.teamManagement.team")} {team.numero}</h1>
                        {!openEditTeamName ? (
                            <h5>{team.name}&nbsp;<IconEdit className='icon-edit cursor-pointer'
                                onClick={() => OpenEditTeamName()} /></h5>
                        ) : (
                            <>
                                <Grid container spacing={6} paddingTop={4} justifyContent={"center"}>
                                    <Grid item xs={12} md={6}>
                                        <h6 className='team-name-label'>{t("page.teamManagement.textNameTeam")}</h6>
                                        <TextField
                                            defaultValue={newTeamName}
                                            value={newTeamName}
                                            sx={{
                                                marginTop: '0.5em',
                                                marginBottom: '0.5em',
                                                width: "100%",
                                                border: "none",
                                                borderRadius: "10px",
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                                "& fieldset": { borderRadius: "10px" },
                                            }}
                                            id="team-name"
                                            fullWidth
                                            onChange={(e) => setNewTeamName(e.target.value)}
                                            helperText={errorTeamName}
                                            error={errorTeamName !== ""}
                                        />
                                        <Grid container spacing={8}>
                                            <Grid item xs={6}>
                                                <div className='text-end'>
                                                    <ActionButton
                                                        variant="little-underline"
                                                        onClick={() => CloseEditTeamName()}
                                                    >
                                                        {t("page.teamManagement.cancel")}
                                                    </ActionButton>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className='text-start'>
                                                    <ActionButton
                                                        variant="little-underline"
                                                        onClick={() => EditTeamName()}
                                                    >
                                                        {t("page.teamManagement.edit")}
                                                    </ActionButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                    <Grid container spacing={6} paddingTop={4}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2} justifyContent={"center"}>
                                <Grid item xs={6}>
                                    <div className='info-general-team h-100'>
                                        <div className='info-registration'>
                                            <div>
                                                {team.registration ? (
                                                    <IconPublic className='w-100 m-auto icon-public'></IconPublic>
                                                ) : (
                                                    <IconPrivate className='w-100 m-auto icon-private'></IconPrivate>
                                                )}
                                                <p className='text-center'>{t("page.teamManagement.team")} {team.registration ?
                                                    <span>{t("page.teamManagement.opened")}</span> :
                                                    <span>{t("page.teamManagement.closed")}</span>} {t("page.teamManagement.forRegistrations")}</p>
                                                <div className='text-center'>
                                                    <ActionButton
                                                        variant="little-underline"
                                                        onClick={() => setOpenModalRegistrationType(true)}
                                                    >
                                                        {t("page.teamManagement.edit")}
                                                    </ActionButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className='info-general-team h-100'>
                                        <div
                                            className={`info-nb-member h-100' ${nbMembers < challenge.minMembersTeam ? 'info-nb-member-warning' : ''}`}>
                                            <div>
                                                <IconGroup className='w-100 m-auto'></IconGroup>
                                                {(challenge.minMembersTeam !== null && challenge.minMembersTeam !== 0) && (challenge.maxMembersTeam !== null && challenge.maxMembersTeam !== 0) && (
                                                    <p className='nb-members'>{String(challenge.minMembersTeam)} {t("page.teamManagement.to")} {String(challenge.maxMembersTeam)} {t("page.teamManagement.members")}</p>
                                                )}

                                                {(challenge.minMembersTeam !== null && challenge.minMembersTeam !== 0) && (challenge.maxMembersTeam === null || challenge.maxMembersTeam === 0) && (
                                                    <p className='nb-members'>Minimum {challenge.minMembersTeam} {t("page.teamManagement.members")}</p>
                                                )}

                                                {(challenge.maxMembersTeam !== null && challenge.maxMembersTeam !== 0) && (challenge.minMembersTeam === null || challenge.minMembersTeam === 0) && (
                                                    <p className='nb-members'>Maximum {challenge.maxMembersTeam} {t("page.teamManagement.to")}</p>
                                                )}

                                                {nbMembers < challenge.minMembersTeam && (
                                                    <p className='text-center warning-complete-team'>{t("page.teamManagement.completeTeam")}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className='bloc-member-team'>
                                <div className='info'>
                                    <h5>{t("page.teamManagement.teamMembers")}</h5>
                                    {
                                        nbMembers < challenge.minMembersTeam && (
                                            <p className="warning">{nbMembers} {t("page.teamManagement.member")}{nbMembers > 1 && "s"} ({challenge.minMembersTeam - nbMembers} {t("page.teamManagement.toComplete")})</p>
                                        )
                                    }
                                    {
                                        nbMembers === challenge.maxMembersTeam && (
                                            <p className="full-members">{nbMembers} {t("page.teamManagement.members")} ({t("page.teamManagement.complete")})</p>
                                        )
                                    }
                                </div>
                                <div className='text-center mt-4'>
                                    <ActionButton
                                        variant={`${(moment().isAfter(challenge?.steps[0]?.dateEndDeposite) || challenge?.projects[0]?.team?.projects[0]?.step_projects[0]?.sent === true) ? 'little-underline-disabled' : 'little-underline'}`}
                                        onClick={() => setOpenModalQuitTeam(true)}
                                    >
                                        {t("page.teamManagement.leaveTeam")}
                                    </ActionButton>
                                    {
                                        (moment().isAfter(challenge?.steps[0]?.dateEndDeposite) ||
                                            (challenge?.projects[0]?.team?.projects[0]?.step_projects[0]?.sent === true) && (
                                                <Tooltip title={`${t("page.challenge.modal.projectAlreadySent")}`}>
                                                    <IconButton>
                                                        <IconInfo />
                                                    </IconButton>
                                                </Tooltip>
                                            ))
                                    }
                                </div>
                                {portfolio && (
                                    <Grid container marginTop={3} spacing={1} justifyContent={"center"}>
                                        {portfolio && portfolio.map((item, index) => (
                                            <Grid item xs="auto" key={index}>
                                                <div className={"chip-customize"}>
                                                    <img src={item.profile.user.profile_picture}
                                                        alt={item.profile.user.firstname + " " + item.profile.user.lastname} />
                                                    <div className={"chip-customize-text"}>
                                                        <p>{item.profile.user.firstname} {item.profile.user.lastname}</p>
                                                        <div className={"email"}>
                                                            {item.profile.user.profiles[0].email}
                                                            <Tooltip
                                                                key={index}
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                onClose={() => handleTooltipCloseEmail(index)}
                                                                open={tooltipOpenStates[index] || false}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                                title={`${t("page.teamManagement.copied")}`}
                                                            >
                                                                <>
                                                                    <IconCopy className='cursor-pointer'
                                                                        onClick={() => {
                                                                            handleTooltipOpenEmail(item.profile.user.profiles[0].email, index);
                                                                        }} />
                                                                    <span className='cursor-pointer icon-email'
                                                                        style={{ marginLeft: ".5rem" }}
                                                                        onClick={() => handleEmailIconClick(item.profile.user.profiles[0].email)}
                                                                    >
                                                                        <IconMessage />
                                                                    </span>
                                                                </>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Grid>

                                        ))}
                                        <ActionButton
                                            variant={"primary"}
                                            className={"send-message-whole-team"}
                                            iconTextStart
                                            icon={<IconMessage />}
                                            onClick={() => sendEmailToAllTeam()}
                                        >
                                            {t("page.teamManagement.sendWholeTeam")}
                                        </ActionButton>
                                    </Grid>
                                )}
                                <div className={"fonctions-collabo"}>
                                    {
                                        resources.length > 0 && (
                                            <p className={"fonctions-collabo-title"}>{t("page.teamManagement.collaboFunctions")}</p>
                                        )
                                    }
                                    <div className={"fonctions-collabo-content"}>
                                        {
                                            resources.map((resource: any) => (
                                                <div className={"fonctions-collabo-content-resource"}>
                                                    <p className={"fonctions-collabo-content-resource-title"}>{resource.name}</p>
                                                    <p className={"fonctions-collabo-content-resource-description"}>{resource.description}</p>
                                                    <OutlinedInput
                                                        sx={{
                                                            marginTop: '0.5em',
                                                            width: "100%",
                                                            backgroundColor: !isMdUp ? "#EDF1F7" : "white",
                                                            border: "none",
                                                            borderRadius: "10px",
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "#000000",
                                                            },
                                                            "& fieldset": { border: 'none' },
                                                        }}
                                                        id="outlined-adornment-weight"
                                                        value={resource.link}
                                                        disabled
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    onClose={() => handleTooltipCloseLink}
                                                                    open={openToolTipLink}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={`${t("page.teamManagement.copied")}`}
                                                                >
                                                                    <IconCopy className='cursor-pointer'
                                                                        onClick={() => handleTooltipOpenLink(resource.link)} />
                                                                </Tooltip>
                                                            </InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'weight',
                                                        }}
                                                    />
                                                    <ActionButton
                                                        variant={"primary"}
                                                        onClick={() => {
                                                            window.open(resource.link, '_blank');
                                                        }}
                                                        className={"button-goto"}
                                                    >
                                                        {t("page.teamManagement.goTo")}
                                                    </ActionButton>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div id='manage-team'>
                                <h5 className='mb-4'>{t("page.teamManagement.manageTeam")}</h5>
                                {nbMembers < challenge.maxMembersTeam && (
                                    <div className='warning-date-complete'>
                                        <div className='text-center mb-2'>
                                            <IconAlert className='icon-alert' />
                                        </div>
                                        <p>{t("page.teamManagement.mustBeComplete")}</p>
                                        <p className='date'>{challenge.registrationEndDate ? moment(String(challenge.registrationEndDate)).format("DD MMMM YYYY") : moment(String(challenge.steps[0].dateEndDeposite)).format("DD MMMM YYYY")}</p>
                                    </div>
                                )}
                                {
                                    nbMembers < challenge.maxMembersTeam && (
                                        <p className='mt-4 text-center'>{t("page.teamManagement.sendEmail")}</p>
                                    )
                                }
                                <div className='mt-2'>
                                    <ActionButton
                                        variant={nbMembers === challenge.maxMembersTeam ? "disabled" : "primary"}
                                        onClick={() => setOpenModalInviteMates(true)}
                                        icon={<IconMessage className='icon-group'></IconMessage>}
                                    >
                                        {t("page.teamManagement.inviteTeammates")}
                                    </ActionButton>
                                    <Modal
                                        open={openModalInviteMates}
                                        onClose={() => setOpenModalInviteMates(false)}
                                        title={`${t("page.teamManagement.inviteTeammates")}`}
                                        primaryButtonText={`${t("page.teamManagement.invite")}`}
                                        tertiaryButtonText={`${t("page.teamManagement.cancel")}`}
                                        primaryButtonAction={() => {
                                            inviteMatesToTeam();
                                        }}
                                        tertiaryButtonAction={() => setOpenModalInviteMates(false)}
                                    >
                                        <>
                                            <p className="subtext-invite-mates">{t("page.teamManagement.subText")}</p>
                                            {bulkEmail && bulkEmail.map((email, index) => {
                                                const id = email.id;
                                                return (
                                                    <div key={index}>
                                                        <label htmlFor={id}
                                                            className="label-textfields">{t("page.teamManagement.email")}</label>
                                                        <TextField
                                                            sx={{
                                                                "fieldset": {
                                                                    borderRadius: '8px',
                                                                    border: '1px solid var(--primary50)',
                                                                },
                                                                padding: "0",
                                                                '& label.Mui-focused': {
                                                                    color: '#828D9B',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#828D9B',
                                                                        borderWidth: '0.1px',
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '&.Mui-focused &.Mui-error': {
                                                                        borderColor: 'red!important',
                                                                        borderRadius: '8px',
                                                                    }
                                                                },
                                                            }}
                                                            id={id}
                                                            placeholder={`${t("page.teamManagement.emailFutureMate")}`}
                                                            type="email"
                                                            value={email.email}
                                                            onChange={(e) => modifyEmail(e)}
                                                            error={email.error.state}
                                                            helperText={email.error.message && email.error.message}
                                                            InputProps={{
                                                                onBlur: () => {
                                                                    validateAndSetError(email)
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {email === bulkEmail[0] ? null : (
                                                                            <IconClose
                                                                                onClick={() => deleteTextFields(email.id)}
                                                                                className="icon-cursor" />
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            <ActionButton variant={"primary"} icon={<IconAdd />}
                                                onClick={() => addTextFields()}>
                                                {t("page.teamManagement.addAnotherAdress")}
                                            </ActionButton>
                                        </>
                                    </Modal>
                                    <Modal
                                        open={openModalInviteMatesValidate}
                                        onClose={() => setOpenModalInviteMatesValidate(false)}
                                        title={`${t("page.teamManagement.invitationsSent")}`}
                                        icon={<IconLike />}
                                        primaryButtonText={`${t("page.teamManagement.understood")}`}
                                        primaryButtonAction={() => setOpenModalInviteMatesValidate(false)}
                                    >
                                        <p>{t("page.teamManagement.textFutureMates")}</p>
                                    </Modal>
                                </div>
                                {
                                    nbMembers < challenge.maxMembersTeam ? (
                                        <>
                                            <p className='mt-4 text-center'>{t("page.teamManagement.orSendLink")}</p>
                                            <OutlinedInput
                                                sx={{
                                                    marginTop: '0.5em',
                                                    width: "100%",
                                                    backgroundColor: !isMdUp ? "#EDF1F7" : "white",
                                                    border: "none",
                                                    borderRadius: "10px",
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000",
                                                    },
                                                    "& fieldset": { border: 'none' },
                                                }}
                                                id="outlined-adornment-weight"
                                                value={`${process.env.REACT_APP_FRONT_URL}/participant/challenge/${challengeId}/team/${teamId}`}
                                                disabled
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            onClose={() => handleTooltipClose}
                                                            open={openToolTip}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title={`${t("page.teamManagement.copied")}`}
                                                        >
                                                            <IconCopy className='cursor-pointer'
                                                                onClick={handleTooltipOpen} />
                                                        </Tooltip>
                                                    </InputAdornment>}
                                                aria-describedby="outlined-weight-helper-text"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <p className="team-full-text">{t("page.teamManagement.teamComplete")}</p>
                                    )
                                }
                            </div>
                            {/* <div id="bloc-invit-mail">
                                <p className='title'>Invations envoyées</p>
                                <p className='mt-2'>Vous avez envoyé des invitations à rejoindre votre équipe aux adresses e-mail suivantes :</p>
                                <ul className='mt-1'>
                                    <li>
                                        <p>clearousseau@hotmail.fr</p>
                                    </li>
                                    <li>
                                        <p>clearousseau@hotmail.fr</p>
                                    </li>
                                    <li>
                                        <p>clearousseau@hotmail.fr</p>
                                    </li>
                                </ul>
                        </div> */
                            }
                        </Grid>
                    </Grid>
                </>
            )
            }
            <Modal
                open={openModalRegistrationType}
                onClose={() => setOpenModalRegistrationType(false)}
                title="Type d'équipe"
                icon={<IconChange className='icon-change' />}
                tertiaryButtonAction={() => cancelChangeRegistrationType()}
                tertiaryButtonText={`${t("page.teamManagement.cancel")}`}
                primaryButtonAction={() => ChangeRegistrationType()}
                primaryButtonText={`${t("page.teamManagement.validate")}`}
            >
                <FormControl>
                    <RadioGroup
                        aria-labelledby="registration-type"
                        name="registrationType"
                        value={registrationType}
                        defaultValue={`${saveRegistrationType ? "public" : "private"}`}
                        onChange={(event) => setRegistrationType(event.target.value)
                        }
                    >
                        <FormControlLabel
                            value="public"
                            control={
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: registrationType === "public" ? '#F86767' : '#828D9B'
                                        },
                                    }}
                                />
                            }
                            label={<p
                                className={`text-start radio-label ${registrationType === "public" && "check"}`}>{t("page.teamManagement.textTeamOpen")}</p>} />
                        <FormControlLabel
                            value="private"
                            control={
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: registrationType !== "public" ? '#F86767' : '#828D9B'
                                        },
                                    }}
                                />
                            }
                            label={<p
                                className={`text-start radio-label ${registrationType !== "public" && "check"}`}>{t("page.teamManagement.textRegistrationByEmailOnly")}</p>} />
                    </RadioGroup>
                </FormControl>
                <Grow in={registrationType === "public"} unmountOnExit>
                    <div>
                        <p className="text-start label-desc">{t("page.teamManagement.textInspire")}</p>
                        <TextField
                            size="small"
                            multiline
                            maxRows={5}
                            inputProps={{ maxLength: 255 }}
                            sx={{
                                borderRadius: '10px',
                                '& label.Mui-focused': {
                                    color: '#828D9B',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#828D9B',
                                        borderWidth: '1px',
                                    }
                                }

                            }}
                            id='message'
                            variant='outlined'
                            placeholder={`Message`}
                            type='text'
                            value={message !== (undefined || null) ? message : ""}
                            defaultValue={team?.bio === null ? "" : team?.bio}
                            onChange={(e) => setMessage(e.target.value === "" ? null : e.target.value)}
                        />
                    </div>
                </Grow>
                <Grow in={errorRegistrationType !== ""} unmountOnExit>
                    <FormHelperText error={errorRegistrationType !== ""}>{errorRegistrationType}</FormHelperText>
                </Grow>
            </Modal>
            {/* modalQuiterl'équipe */
            }
            <Modal
                open={openModalQuitTeam}
                onClose={() => setOpenModalQuitTeam(false)}
                title={`${t("page.teamManagement.leaveTeam")}`}
                icon={<IconLogout className='icon-logout' />}
                tertiaryButtonAction={() => quitTeam(false)}
                tertiaryButtonText={`${t("page.teamManagement.leaveTeam")}`}
                tertiaryButtonDisabled={challenge?.steps[0]?.status?.name === "done" || sent === true || (nbMembers === 1 && (
                    (challenge?.projects[0]?.team?.projects[0]?.name?.length > 0) ||
                    (challenge?.projects[0]?.team?.projects[0]?.short_desc?.length > 0) ||
                    (challenge?.projects[0]?.team?.projects[0]?.long_desc?.length > 0) ||
                    (challenge?.projects[0]?.team?.projects[0]?.project_picture) ||
                    (challenge?.projects[0]?.team?.projects[0]?.answers?.some((answer: any) => answer.answer_text && answer.answer_text.trim().length > 0))
                ))}
                primaryButtonAction={() => quitTeam(true)}
                primaryButtonText={`${t("page.teamManagement.leaveAndDeleteTeam")}`}
                primaryButtonDisabled={challenge?.steps[0]?.status?.name === "done" || sent === true || nbMembers > 1}
                info={true}
            >
                <p className='text-center'>{t("page.teamManagement.textSureLeave")}</p>
                <div style={{ width: "2rem", marginLeft: "auto", marginRight: "auto" }}>
                    {
                        sent === true && (
                            <Tooltip title={`${t("page.challenge.modal.projectAlreadySent")}`}>
                                <IconButton>
                                    <IconInfo />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </div>
            </Modal>
            <Modal
                open={openErrorQuite}
                onClose={() => setOpenErrorQuite(false)}
                title={`${t("page.teamManagement.textImpossibleLeaveTeam")}`}
                icon={<IconInfo />}
                error={errorQuite}
                primaryButtonText={`${t("page.teamManagement.back")}`}
                primaryButtonAction={() => setOpenErrorQuite(false)}
            ></Modal>

        </section>
    )
}

export default TeamManagement;
