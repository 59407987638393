// style
import './index.css';

// react
import {useEffect, useState} from 'react';

interface CardNameProps {
    img_url: string;
    firstname?: string;
    lastname?: string;
    date?: string;
    onClick?: () => void;
    variant?: 'large';
}
const CardName = ({
                      img_url,
                      firstname = '',
                      lastname = '',
                      onClick,
                  }: CardNameProps) => {

    return (
        <>
            <div className="card-name" onClick={onClick}>
                <img src={img_url} className={"card-pp"}/>
                <div className="card-name-text">
                    <p>{firstname}</p>
                    <p>{lastname}</p>
                </div>
            </div>
        </>
    )
}

export default CardName;