// react-router
import { useNavigate } from "react-router-dom";

// style
import './index.css'

interface GoNextProps {
    children?: React.ReactNode;
    variant?: string;
    onClick?: () => void;
}

const GoNext = ({ children, onClick, variant }: GoNextProps) => {

    // navigation
    const navigate = useNavigate();

    return (
        <div className='div-go-next'>
            <button
                className="button-go-next"
                onClick={
                    function () {
                        onClick && onClick()
                    }}>
                <p className={variant === 'text-mobile' ? 'add-text-mobile' : 'delete-text-mobile'}>{children}</p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.5 18.5L8.5 12L15.5 5.5" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
        </div>

    )
};

export default GoNext;