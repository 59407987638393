// components
import ActionButton from "../../../../components/ActionButton";

// react-router
import {useNavigate} from "react-router-dom";

// assets
import {ReactComponent as IconAdd} from '../../../../assets/icon-add.svg';
import {ReactComponent as IconPreview} from '../../../../assets/icon-eye.svg';
import {ReactComponent as IconAnnotationAlert} from '../../../../assets/icon-annotation-alert.svg';
import {ReactComponent as EmptyChallenges} from '../../../../assets/imgs/challengeadmin-vide.svg';

// composants
import ChallengeCard from '../../../../modules/ChallengeCard';

// style
import './index.css';

// react
import {createRef, useEffect, useRef, useState} from "react";


// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/type";
import {useMediaQuery, useTheme} from "@mui/material";
import api from "../../../../utils/api";

export interface IEstablishment {
    name: string;
    nbChallenges: number;
}

const AdminChallenges = () => {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.up('sm'));
    const isbetweenXsAndMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isbetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const ismorelg = useMediaQuery(theme.breakpoints.up('lg'));

    const [establishment, setEstablishment] = useState<IEstablishment>({
        name: "",
        nbChallenges: 0
    });

    // navigation
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();

    const getAllChallenges = async () => {
        await api.get('/admin/challenges',
            {withCredentials: true})
            .then((res) => {
                console.log('get all challenge ', res.data.challenges)
                setEstablishment(res.data.establishment);
                dispatch({
                    type: 'SET_CHALLENGES',
                    payload: {
                        allChallenges: res.data.challenges
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // function getOneChallenge(id: string) {
    //     axios.get(process.env.REACT_APP_API_URL + '/challenge/preview', {
    //         params: {
    //             challengeId: id
    //         },
    //         withCredentials: true,
    //     })
    //         .then((res) => {
    //             console.log("challenge", res.data.challenge);
    //             res.data.challenge = {
    //                 ...res.data.challenge,
    //                 prizes: res.data.challenge.prizes.map((prize: any) => {
    //                     return {
    //                         ...prize,
    //                         id: prize.id,
    //                         image: prize.prizePicture,
    //                         ref: createRef<HTMLImageElement>(),
    //                         position: prize.position
    //                     }
    //                 }).sort((a: any, b: any) => a.position - b.position),
    //                 questions: res.data.challenge.itemFAQs.map((question: any) => {
    //                     return {
    //                         ...question,
    //                         id: question.id,
    //                         challengeId: res.data.challenge.id,
    //                         ref: createRef<HTMLImageElement>()
    //                     }
    //                 }).sort((a: any, b: any) => a.position - b.position),
    //                 dares: res.data.challenge.subChallenges.map((dare: any) => {
    //                     return {
    //                         ...dare,
    //                         id: dare.id,
    //                         challengeId: res.data.challenge.id,
    //                         ref: createRef<HTMLImageElement>()
    //                     }
    //                 }).sort((a: any, b: any) => a.position - b.position),
    //                 partners: res.data.challenge.partners.map((partner: any) => {
    //                     return {
    //                         ...partner,
    //                         id: partner.id,
    //                         name: partner.name,
    //                         link: partner.partnerLink,
    //                         image: partner.partnerLogo,
    //                         ref: createRef<HTMLImageElement>(),
    //                         challengeId: res.data.challenge.id,
    //                         position: partner.position
    //                     }
    //                 }).sort((a: any, b: any) => a.position - b.position),

    //             }
    //             setChallenge(res.data.challenge)
    //             // dispatch({
    //             //     type: "SET_CHALLENGES",
    //             //     payload: {
    //             //         getOneChallenge: {
    //             //             id: res.data.challenge.id,
    //             //             name: res.data.challenge.name,
    //             //             shortDescription: res.data.challenge.shortDescription,
    //             //             longDescription: res.data.challenge.longDescription,
    //             //             challengePicture: res.data.challenge.challengePicture,
    //             //             challengeLogo: res.data.challenge.challengeLogo,
    //             //             startDate: res.data.challenge.startDate,
    //             //             endDate: res.data.challenge.endDate,
    //             //             public: res.data.challenge.public,
    //             //             prizes: res.data.challenge.prizes,
    //             //             questions: res.data.challenge.questions,
    //             //             dares: res.data.challenge.dares,
    //             //             status: res.data.challenge.status.name,
    //             //             partners: res.data.challenge.partners,
    //             //             hasDefis: res.data.challenge.hasDefis || res.data.challenge.dares.length > 0,
    //             //             hasPrizes: res.data.challenge.hasPrizes || res.data.challenge.prizes.length > 0,
    //             //             hasPartners: res.data.challenge.hasPartners || res.data.challenge.partners.length > 0,
    //             //             hasFaq: res.data.challenge.hasFaq || res.data.challenge.questions.length > 0,
    //             //             published: res.data.challenge.published,
    //             //             registration: res.data.challenge.registration,

    //             //             organizer: res.data.challenge.establishments[0].name,
    //             //             imgOrganizer: res.data.challenge.establishments[0].establishmentPicture,
    //             //             // updatedChallengeDate: res.data.challenge.publicationDate,

    //             //             totalParticipants: res.data.challenge.maxMembers,
    //             //             typeParticipation: res.data.challenge.participationType,
    //             //             nbMinParticipants: res.data.challenge.minMembersTeam,
    //             //             nbMaxParticipants: res.data.challenge.maxMembersTeam,
    //             //             registrationStartDate: res.data.challenge.registrationStartDate,
    //             //             registrationEndDate: res.data.challenge.registrationEndDate,
    //             //             linkRules: !res.data.challenge.file ? "" : res.data.challenge.file.fileUrl,
    //             //             fileName: !res.data.challenge.file ? "" : res.data.challenge.file.originalFileName,
    //             //             fileNameStorage: !res.data.challenge.file ? "" : res.data.challenge.file.fileName,

    //             //             ressources: res.data.challenge.resources?.map((ressource: any) => {
    //             //                 return {
    //             //                     id: ressource.id,
    //             //                     name: ressource.name,
    //             //                     link: ressource.link,
    //             //                     challengeId: res.data.challenge.id,
    //             //                     ressourceFileName: ressource.file?.originalFileName,
    //             //                     ressourceFileNameStorage: ressource.file?.fileName,
    //             //                     ressourceUrl: ressource.file?.fileUrl,
    //             //                 }
    //             //             }),
    //             //             countMembers: res.data.challenge.countMembers,
    //             //         }
    //             //     }
    //             // });
    //             console.log(res.data.challenge)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }

    const allChallenges = useSelector((action: RootState) => action.challenges.allChallenges);

    useEffect(() => {
        getAllChallenges();
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])

    let countCreate = 0;
    let countCurrent = 0;
    let countFinished = 0;

    allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && allChallenges.map((challenge: any) => {
        if (challenge.status.name === 'in creation' && new Date(challenge.endDate).getTime() > Date.now()) {
            countCreate += 1
        }
        if (challenge.status.name !== 'in creation' && new Date(challenge.endDate).getTime() > Date.now()) {
            countCurrent += 1
        }
        if (new Date(challenge.endDate).getTime() < Date.now()) {
            countFinished += 1
        }
    })
    if (allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && allChallenges[0]?.establishments) {
        console.log(allChallenges[0]?.establishments[0]);
    }

    const noChallengesCreated = (countCreate === 0 && countCurrent === 0 && countFinished === 0);

    return (
        <section className='admin-challenges'>
            {allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && allChallenges.length !== 0 && allChallenges[0].establishments[0].nbChallenges ? (
                    <div>
                        <h1 className="nb-run-challenge">{allChallenges.length} challenge{allChallenges.length === 1 ? "" : "s"} créé{allChallenges.length === 1 ? "" : "s"}</h1>
                        <p className="text-center nb-challenges mb-4">Sur {allChallenges[0].establishments[0].nbChallenges} maximum</p>
                    </div>
                ) :
                <div className="admin-challenges-noChallengesPresentation text-center">
                    <p>Vous n'avez pas encore créé de challenge</p>
                    <h1>Créez votre challenge !</h1>
                </div>
            }
            <div className="button-create">
                <ActionButton
                    variant={`${allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && (allChallenges.length >= allChallenges[0].establishments[0].nbChallenges) ? 'disabled' : 'primary'}`}
                    onClick={() => {
                        if (allChallenges && allChallenges.length < establishment.nbChallenges) {
                            navigate('/admin/challenges/create')
                        }
                    }}
                    icon={<IconAdd/>}
                >
                    Créer un challenge
                </ActionButton>
            </div>
            {allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && (allChallenges.length >= allChallenges[0].establishments[0].nbChallenges) && (
                <div
                    className={`bloc-limit-challenge ${isbetweenXsAndMd ? "w-100" : ""} ${isbetweenMdAndLg ? "w-75" : ""} ${ismorelg ? "w-50" : ""}`}>
                    <IconAnnotationAlert/>
                    <p>Vous avez atteint le nombre de challenges inclus dans votre licence. Pour créer un nouveau
                        challenge, contactez votre <span><button
                            onClick={() => navigate("/admin/school/config/general")}
                            className="text-decoration-underline p-0">conseiller référent.</button></span></p>
                </div>
            )}

            <div className='admin-challenges-list'>
                {noChallengesCreated && <EmptyChallenges className="admin-challenges-list-empty"/>}
                {countCreate > 0 && <h1 className="text-center">Vos challenges en création</h1>}
                <div className="challenges">
                    <div className="d-flex justify-content-center spacing-2 wrap">
                        {
                            allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && allChallenges.map((challenge: any, index: number) => {

                                    const name = challenge.name.replace(/\s+/g, '-').toLowerCase();
                                    const id = challenge.id;
                                    const startDate = new Date(challenge.startDate).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });
                                    const startEnd = new Date(challenge.endDate).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });

                                    let state = '';
                                    if (challenge.status.name === 'in creation') {
                                        state = 'En création';
                                    }

                                    if (challenge.status.name === 'in creation' && new Date(challenge.endDate).getTime() > Date.now()) {
                                        return (
                                            <div
                                                key={index}
                                                className={`${isbetweenXsAndMd ? 'w-100' : ""} ${isbetweenMdAndLg ? "w-50" : ""} ${ismorelg ? "w-33" : ""}`}>
                                                <ChallengeCard
                                                    organizer={establishment?.name}
                                                    title={challenge.name}
                                                    theme={challenge.theme}
                                                    state={state}
                                                    description={challenge.shortDescription}
                                                    date={'Du ' + startDate + ' au ' + startEnd}
                                                    img={challenge.challengePicture}
                                                    link={`/admin/challenge/${id}/general`}
                                                    isPublic={challenge.public}
                                                    onClick={() => {
                                                        navigate(`/admin/challenge/${challenge.id}/general`)
                                                    }}
                                                />
                                            </div>

                                        )
                                    }
                                }
                            )
                        }
                    </div>
                </div>
                {countCurrent > 0 && <h1 className="text-center">Challenges en cours</h1>}
                <div className="challenges">
                    <div className="d-flex justify-content-center spacing-2 wrap">
                        {
                            allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && allChallenges.map((challenge: any, index: number) => {
                                    const name = challenge.name.replace(/\s+/g, '-').toLowerCase();
                                    const id = challenge.id;
                                    const startDate = new Date(challenge.startDate).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });
                                    const endDate = new Date(challenge.endDate).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });

                                    let state = '';
                                    if (challenge.status.name === 'published') {
                                        state = 'Publié';
                                    } else if (challenge.status.name === 'registrations') {
                                        state = 'Inscriptions';
                                    } else if (challenge.status.name === 'submission') {
                                        state = 'Soumissions';
                                    } else if (challenge.status.name === 'deliberations') {
                                        state = 'Délibérations';
                                    }

                                    if (challenge.status.name !== 'in creation' && new Date(challenge.endDate).getTime() > Date.now()) {
                                        return (
                                            <div
                                                key={index}
                                                className={`${isbetweenXsAndMd ? 'w-100' : ""} ${isbetweenMdAndLg ? "w-50" : ""} ${ismorelg ? "w-33" : ""}`}>
                                                <ChallengeCard
                                                    organizer={establishment?.name}
                                                    title={challenge.name}
                                                    theme={challenge.theme}
                                                    state={state}
                                                    description={challenge.shortDescription}
                                                    date={'Du ' + startDate + ' au ' + endDate}
                                                    img={challenge.challengePicture}
                                                    link={`/admin/challenge/${id}/general`}
                                                    isPublic={challenge.public}
                                                    // onClick={() => {
                                                    //     getOneChallenge(challenge.id)
                                                    // }}
                                                />
                                            </div>
                                        )
                                    }
                                    return null;

                                }
                            )
                        }
                    </div>
                </div>
                {countFinished > 0 && <h1 className="text-center">Vos challenges Terminés</h1>}
                <div className="challenges">
                    <div className="d-flex justify-content-center spacing-2 wrap">
                        {
                            allChallenges && allChallenges.length > 0 && allChallenges[0].establishments && allChallenges.map((challenge: any, index: number) => {

                                    const name = challenge.name.replace(/\s+/g, '-').toLowerCase();
                                    const id = challenge.id;
                                    const startDate = new Date(challenge.startDate).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });
                                    const endDate = new Date(challenge.endDate).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });

                                    if (challenge.status.name === 'finished') {
                                        return (
                                            <div
                                                key={index}
                                                className={`${isbetweenXsAndMd ? 'w-100' : ""} ${isbetweenMdAndLg ? "w-50" : ""} ${ismorelg ? "w-33" : ""}`}>
                                                <ChallengeCard
                                                    organizer={establishment?.name}
                                                    title={challenge.name}
                                                    theme={challenge.theme}
                                                    state='Gagné'
                                                    description={challenge.shortDescription}
                                                    date={'Du ' + startDate + ' au ' + endDate}
                                                    img={challenge.challengePicture}
                                                    link={`/admin/challenge/${id}/general`}
                                                    status='finished'
                                                    isPublic={challenge.public}
                                                    // onClick={() => {
                                                    //     getOneChallenge(challenge.id)
                                                    // }
                                                    // }
                                                />
                                            </div>
                                        )
                                    }

                                }
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdminChallenges;
