// drag and drop kit
import { PointerSensor } from "@dnd-kit/core";

// react
import { PointerEvent } from "react";

class GlobalPointerSensor extends PointerSensor{
    static activators = [
        {
            eventName: "onPointerDown" as const,
            handler: ({ nativeEvent: event }: PointerEvent) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target)
                ) {
                    return false;
                }
                return true;
            },
        }
    ]
}

function isInteractiveElement(element: any){

    // on regarde aussi pour le parent dans le cas du svg où des fois on clique sur le path et non le svg
    const isDraggable = element.classList.contains('draggable') || element.parentElement.classList.contains('draggable');

    return !isDraggable;
}

export default GlobalPointerSensor;