import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { redirect, useLocation, useParams, useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
import Modal from "../../modules/Modal";
import { ReactComponent as IconLike } from "../../assets/icon-like.svg";
import { ReactComponent as IconLogout } from "../../assets/icon-logout.svg";
import { ReactComponent as IconInfo } from "../../assets/icon-info.svg";
import { ReactComponent as IconValidated } from "../../assets/icon-validated.svg";
import { ReactComponent as IconAnnotation } from "../../assets/icon-annotation-alert.svg";
import { ReactComponent as IconProfile } from "../../assets/icon-profile.svg";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from "moment";

import {
    Dialog,
    DialogProps,
    FormControlLabel,
    Grid,
    Grow,
    Radio,
    RadioGroup,
    Slide,
    TextField,
    Fade,
    FormControl,
    FormLabel,
    FormControlLabelProps,
    useRadioGroup, Tooltip, IconButton
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { IAction, IChallengeData, IError, IListErrorTeam } from "./type";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";
import api from "../../utils/api";
import { log } from "console";

interface ParticipantChallengeProps {
    getRegisteredToChallengeById: () => void;

    getChallengeById: () => void;

    challengeData: any;

    challengeInscriptionDetails: any;

    disableStatus?: boolean;

    hideAllButtons?: boolean;
}

const ParticipantChallenge = ({
    getRegisteredToChallengeById,
    getChallengeById,
    challengeData,
    challengeInscriptionDetails,
    disableStatus,
    hideAllButtons
}: ParticipantChallengeProps) => {
    const { profile } = useAuth();
    const challengeId = useParams().id;
    const [searchParams] = useSearchParams();

    const [redirectCreateTeam, setRedirectCreateTeam] = useState(searchParams.get("redirectcreateteam") === "true");
    const [choiceParticipationType, setChoiceParticipationType] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    // const [action, setAction] = useState<IAction | null>(null);

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const registerChallenge = async () => {
        await getChallengeById(); //On récupère les infos du challenge avant l'inscription, pour s'assurer que tout est en ordre
        await api.put("/challenge/register",
            {
                language: localStorage.getItem("translation"),
                challengeId: challengeId
            },
            {
                withCredentials: true
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    if (!!res.data) {
                        getRegisteredToChallengeById();
                        setIsModalConfirmOpen(!isModalConfirmOpen);
                        setIsModalOpen(false);
                        autoCreateTeam();
                        getChallengeById();
                    }
                } else {
                    setError(res.data);
                }
            })
            .catch((err) => {
                setError(err.response.data);
            });
    };

    const autoCreateTeam = async () => {
        if (challengeData && challengeData.challenge.participationType === "single") {
            console.log("Main single");
            if (challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length === 0) {
                console.log("Main single no subchallenge");

                // setIsModalConfirmOpen(false)
            } else if (challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length !== 0) {
                console.log("Main single subchallenge");
                setSingle(true);
                setOpenRunProject(false);
                // setIsModalConfirmOpen(false)
                // setOpenSubChallenge(true);
            }
        } else if (challengeData && challengeData.challenge.participationType === "team") {
            console.log("Main team");
            setChoiceParticipationType(false);
            setTeam(true);
        }
    }

    const unsubscribeChallenge = async () => {
        await api.delete('/challenge/member/unsubscribe/' + challengeId,
            {
                withCredentials: true,
                params: {
                    language: localStorage.getItem("translation")
                }
            }).then((res) => {
                if (res.status === 200) {
                    if (!!res.data) {
                        getRegisteredToChallengeById();
                        setIsModalUnSubscribeOpen(!isModalUnSubscribeOpen);
                        getChallengeById();
                    }
                } else {
                    setIsModalUnSubscribeOpen(false)
                    setError(res.data);
                    setIsModalOpen(true);
                }
            }).catch((err) => {
                setIsModalUnSubscribeOpen(false)
                setError(err.response.data);
                setIsModalOpen(true);
            })
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isDisabledActionButton, setIsDisabledActionButton] = useState(true);

    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [isModalUnSubscribeOpen, setIsModalUnSubscribeOpen] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const [openRunProject, setOpenRunProject] = useState(false);
    const [openListSubChallenge, setOpenSubChallenge] = useState(false);

    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    //form modal
    const [single, setSingle] = useState(false);
    const [team, setTeam] = useState(false);
    const [createteam, setCreateTeam] = useState(false);
    const [jointeam, setJoinTeam] = useState(false);
    const [registration, setRegistration] = useState('openRunProject');
    const [teamName, setTeamName] = useState('');
    const [message, setMessage] = useState('');
    const [titleActionButton, setTitleActionButton] = useState(t("form.next"));
    const [titleActionButtonDisabled, setTitleActionButtonDisabled] = useState(false);
    const [selectSubChallengeDisabledButton, setSelectSubChallengeDisabledButton] = useState(true);
    const [challenge2, setChallenge2] = useState<any>();
    //SubChalleng
    const [selectSubChallenge, setSelectSubChallenge] = useState<string>();

    useEffect(() => {
        // console.log("selectSubChallenge",selectSubChallenge);
    }, [selectSubChallenge]);

    //Sucess
    const [openSucess, setOpenSucess] = useState(false);
    const [titleSucess, setTitleSucess] = useState('Vous êtes bien inscrit au challenge !');
    const [moreInformation, setMoreInformation] = useState(false);

    const [runProject, setRunProject] = useState<IAction>();

    const [projectId, setProjectId] = useState<string | null | Number>(null);

    //Error Team
    const [errorTeam, setErrorTeam] = useState<IListErrorTeam | null>(null);
    const [openErrorTeam, setOpenErrorTeam] = useState(errorTeam === null ? false : true);
    const [openUnauthenticad, setOpenUnauthenticad] = useState<boolean>(false);

    const [eligibilities, setEligibilities] = useState<any[]>([]);
    const [checkedItems, setCheckedItems] = useState(Array(eligibilities.length).fill(false));


    const [openModalQuitTeam, setOpenModalQuitTeam] = useState(false);

    const [errorQuite, setErrorQuite] = useState<string>("");
    const [openErrorQuite, setOpenErrorQuite] = useState<boolean>(false);

    const [nbMembers, setNbMembers] = useState<number>(0);

    useEffect(() => {
        const createTeam = async () => {
            await api.post("/participant/team/create", {
                language: localStorage.getItem("translation"),
                runProject
            }, { withCredentials: true })
                .then((res) => {
                    if (res.status === 200) {
                        if (!!res.data) {
                            if (res.data.originTeam && res.data.originTeam !== ('' || null || undefined)) {
                                if (res.data.newProjectId && res.data.newProjectId !== ('' || null || undefined)) {
                                    setProjectId(res.data.newProjectId);
                                    sucessRunProjectModal(res.data.originTeam)
                                    //clear data dans le formulaire
                                    handleCloseandClear();
                                    setOpenSubChallenge(false);
                                    setSelectSubChallengeDisabledButton(true);
                                    setSelectSubChallenge('');
                                    setErrorTeam(null);
                                    getRegisteredToChallengeById()
                                }
                            }
                        }
                    }
                })
                .catch((err) => {
                    if (err.response.data) {
                        setErrorTeam(err.response.data);
                        setOpenErrorTeam(true);
                        setOpenRunProject(false);
                        setOpenSubChallenge(false);
                        setSelectSubChallengeDisabledButton(true);
                        setSelectSubChallenge('');
                        setSingle(false);
                        setTeam(false);
                        setJoinTeam(false);
                        setCreateTeam(false);
                        setRegistration('openRunProject');
                        setMessage('');
                        setTeamName('');
                    }
                });
        };
        if (runProject && runProject !== (null || undefined)) {
            createTeam();
        }
    }, [runProject]);

    useEffect(() => {
        // console.log("projectId", projectId);
    }, [projectId]);
    useEffect(() => {
        // console.log("registration", registration);
    }, [registration]);

    // write data form
    const handleChange = (index: string, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event?.preventDefault();
        switch (index) {
            case "team":
                setTeam(true);
                setSingle(false);
                break;
            case "single":
                setSingle(true);
                setTeam(false);
                setRegistration('openRunProject');
                setCreateTeam(false);
                setMessage('');
                setTeamName('');
                setJoinTeam(false);
                break;
            case "jointeam":
                setCreateTeam(false);
                setJoinTeam(true);
                setRegistration('openRunProject');
                setMessage('');
                setTeamName('');
                break;
            case "createteam":
                setJoinTeam(false);
                setCreateTeam(true);
                break;
            case "teamName":
                if (event && 'target' in event) {
                    const inputEvent = event as React.ChangeEvent<HTMLInputElement>;
                    console.log(inputEvent.target.value);
                    setTeamName(inputEvent.target.value);
                }
                break;
            case "registrationType":
                if (event && 'target' in event) {
                    const inputEvent = event as React.ChangeEvent<HTMLInputElement>;
                    setRegistration(inputEvent.target.value);
                    if (inputEvent.target.value === 'private') {
                        setMessage('');
                    }
                }
                break;
            case "message":
                if (event && 'target' in event) {
                    const inputEvent = event as React.ChangeEvent<HTMLInputElement>;
                    setMessage(inputEvent.target.value);
                }
                break;
            case "cancel":
                setTeam(false);
                setSingle(false);
                setJoinTeam(false);
                setCreateTeam(false);
                setRegistration('openRunProject');
                setMessage('');
                setTeamName('');
                break;
            case "subChallenge":
                if (event && 'target' in event) {
                    const inputEvent = event as React.ChangeEvent<HTMLInputElement>;
                    setSelectSubChallenge(inputEvent.target.value)
                }
                break;
            default:
                setTeam(false);
                setSingle(false);
                setJoinTeam(false);
                setCreateTeam(false);
                setRegistration('openRunProject');
                setCreateTeam(false);
                break;
        }
    };

    // Action button and Get data
    const action = async () => {
        let action: IAction = { participationType: challengeData?.challenge.participationType };
        if (!team && !single) {
            // console.log("pas de choix de type de participant");
        }
        if (!team && single) {
            // console.log("redirect vers liste défi");
            // redirect to challenge list
            action.single = single;
            action.challengeId = challengeId;
            if (challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length >= 1) {
                OpenlistSubChallenge()
                if (selectSubChallenge && selectSubChallenge !== (null || undefined || '')) {
                    action.subChallenge = selectSubChallenge;
                }
            }
            //  else {
            //     sucessRunProjectModal("project")
            // }
        }
        if (team && !createteam && !jointeam) {
            // console.log("pas de choix de type d'équipe");
        }
        if (team && createteam && !jointeam && teamName === '') {
            // console.log("pas de nom d'équipe");
        }
        if (team && createteam && !jointeam && teamName !== '') {
            // console.log("redirect vers la page projet");
            action.team = team;
            action.teamName = teamName;
            action.registration = registration;
            action.createteam = createteam;
            action.challengeId = challengeId;
            if (registration === 'openRunProject') {
                action.message = message;
            }
            if (challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length >= 1) {
                OpenlistSubChallenge()
                if (selectSubChallenge && selectSubChallenge !== (null || undefined || '')) {
                    action.subChallenge = selectSubChallenge;
                }
            }
            // else {
            //     sucessRunProjectModal("team")
            // }
        }
        if (team && !createteam && jointeam) {
            // console.log("redirect vers liste équipe");
            // console.log("profile", profile);
            navigate(`/${profile}/challenge/${challengeId}/teams`);
        }
        setRunProject(action);
    }

    useEffect(() => {
        if (challengeData && challengeData.challenge.participationType === "single") {
            setOpenRunProject(false);
        }
        if (!team && !single) {
            setTitleActionButton(t("form.next"));
            setTitleActionButtonDisabled(true);
        }
        if (!team && single) {
            setTitleActionButton(t("form.next"));
            setTitleActionButtonDisabled(false);
            setJoinTeam(false);
            setCreateTeam(false);
            setRegistration('openRunProject');
            setMessage('');
            setTeamName('');
        }
        if (team && !createteam && !jointeam) {
            setTitleActionButton(t("form.next"));
            setTitleActionButtonDisabled(true);
        }
        if (team && createteam && !jointeam && teamName === '') {
            setTitleActionButton(t("form.validate"));
            setTitleActionButtonDisabled(true);
        }
        if (team && createteam && !jointeam && teamName !== '') {
            setTitleActionButton(t("form.validate"));
            setTitleActionButtonDisabled(false);
        }
        if (team && !createteam && jointeam) {
            setTitleActionButton(t("page.challenge.modal.findTeam"));
            setTitleActionButtonDisabled(false);
        }

        if (selectSubChallenge !== (undefined || null || '')) {
            setSelectSubChallengeDisabledButton(false);
        } else {
            setSelectSubChallengeDisabledButton(true);
        }
    }, [challengeData, createteam, jointeam, openRunProject, selectSubChallenge, single, team, teamName]);

    useEffect(() => {
        // console.log("runProject", runProject);
    }, [runProject]);

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpenRunProject(true);
        setScroll(scrollType);
    };

    const handleCloseandClear = () => {
        setOpenRunProject(false);
        /*setSingle(false);
        setTeam(false);
        setJoinTeam(false);
        setCreateTeam(false);*/
        setRegistration('openRunProject');
        setMessage('');
        setTeamName('');
        setSelectSubChallengeDisabledButton(true);
        setSelectSubChallenge('');
    };

    const OpenlistSubChallenge = () => {
        setSelectSubChallengeDisabledButton(true);
        setSelectSubChallenge('');
        setOpenRunProject(false);
        setOpenSubChallenge(true);
    };

    const closeListSubChallenge = () => {
        setSelectSubChallengeDisabledButton(true);
        setSelectSubChallenge('');
        // console.log("closeListSubChallenge");
        setOpenSubChallenge(false);
    };

    const returnToTeamModal = () => {

        if (challengeData && challengeData.challenge.participationType === "single" && challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length !== 0) {
            setSelectSubChallengeDisabledButton(true);
            setSelectSubChallenge('');
            setOpenSubChallenge(false);
        } else {
            setSelectSubChallengeDisabledButton(true);
            setSelectSubChallenge('');
            setOpenRunProject(true);
            setOpenSubChallenge(false);
        }
    }

    const sucessRunProjectModal = async (origin: string) => {
        let title = "";
        if (origin === 'project') {
            title = t("page.challenge.successProjectCreation");
            setOpenSubChallenge(false);
            setOpenRunProject(false);
            setOpenSucess(true);
            setTitleSucess(title);
            setMoreInformation(false);
        } else if (origin === 'team') {
            title = t("page.challenge.successTeamCreation");
            setOpenSubChallenge(false);
            setOpenRunProject(false);
            setOpenSucess(true);
            setTitleSucess(title);
            setMoreInformation(true);
        }
        // await createTeam();
    }

    const openCreateTeam = () => {
        setOpenRunProject(true);
        setTeam(true);
        setSingle(false);
        setCreateTeam(true);
        setJoinTeam(false);
    }

    useEffect(() => {
        if (redirectCreateTeam) {
            openCreateTeam();
            navigate(`/challenge/${challengeId}/general`);
            // setRedirectCreateTeam(false);
        }
    }, []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckboxChecked(event.target.checked);
    };

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            getRegisteredToChallengeById();
            setIsLoaded(true);
        }
    }, []);
    const MyFormControlLabel = (props: FormControlLabelProps) => {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        if (checked)
            return (
                <FormControlLabel
                    sx={{
                        background: 'var(--Saumon10, #FFF1F1)',
                        borderRadius: '10px',
                        border: '1px solid #F86767',
                        margin: "0",
                        marginBottom: "0.5em",
                        padding: "0.5em",
                        whiteSpace: 'normal'
                    }}
                    checked={checked}
                    {...props}
                />
            );
        return <FormControlLabel
            sx={{
                borderRadius: '10px',
                border: '1px solid #828D9B',
                margin: "0",
                marginBottom: "0.5em",
                padding: "0.5em",
                whiteSpace: 'normal'
            }} checked={checked} {...props}
        />;
    }
    const runMyProject = async () => {
        if (challengeData && challengeData.challenge.participationType === "single") {
            if (challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length === 0) {
                const action: IAction = {
                    participationType: challengeData.challenge.participationType,
                    single: true,
                    challengeId: challengeId,
                };
                setRunProject(action);
            } else if (challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length !== 0) {
                setSingle(true);
                setIsModalConfirmOpen(false)
                setOpenSubChallenge(true);
            }
        } else {
            setIsModalConfirmOpen(false);
            setOpenRunProject(true)
        }
    }

    const runMyProjectSingleAndNoSubChallenge = async () => {
        const participation: string = challengeData && challengeData.challenge.participationType === "single" ? "single" : "team";
        const action: IAction = {
            participationType: participation,
            single: true,
            challengeId: challengeId,
        };
        setRunProject(action);
    }
    const { isAuthenticated, login, finishedCheckingAuth } = useAuth();

    //LES COMPOSANTS / BOUTONS (il y en a 3):
    //Ne sont pas affichés quand la variable de TEXTE est "" ou null
    //1er composant : Inscription au challenge (son état)
    //Affiche si on peut s'inscrire ou non
    const inscriptionBtnText =
        !hideAllButtons && //On cache dans le cas où on le demande explicitement (props)
            (!isAuthenticated || !challengeInscriptionDetails)
            || (isAuthenticated && !challengeInscriptionDetails?.registered) ? //Si pas connecté (forcément pas inscrit) ou pas inscrit (mais connecté)
            challengeData?.challenge.registration ? //Inscriptions ouvertes ?
                t("registration.register")
                : t("registration.registrationFinished") //Inscriptions fermées
            : "" //NE PAS AFFICHER

    const inscriptionBtnVariant =
        inscriptionBtnText === t("registration.register") ? "primary" : "disabled"; //Le variant est assez binaire ici

    const inscriptionBtnAction = () => {
        if (inscriptionBtnText === "") return; //N'exécute pas l'action si le bouton est caché

        if (challengeData?.challenge.registration) { //Inscriptions ouvertes ?
            if (isAuthenticated) { //Si on est connecté
                setIsModalOpen(!isModalOpen) //Lance l'inscription
            } else {
                localStorage.setItem("redirect", location.pathname);
                setOpenUnauthenticad(true);
                //navigate("/login/1") //Sinon on redirige sur la page de connexion
            }
        } //Inscriptions fermées
    }
    //2nd composant : Création de projet (son état)
    //Affiche si on peut voir le projet ou si on doit le démarrer
    const projectBtnText =
        !hideAllButtons && //On cache dans le cas où on le demande explicitement (props)
            isAuthenticated && challengeInscriptionDetails?.registered ? //Connecté ET inscrit ?
            challengeInscriptionDetails?.team?.team ? //On a un projet ?
                t("page.challenge.myProject.seeMyProject")
                : challengeInscriptionDetails?.canUnregister ? //On peut démarrer un projet tant que la première étape est pas finie
                    t("page.challenge.myProject.startMyProject")
                    : ""
            : ""; //NE PAS AFFICHER

    const projectBtnAction = () => {
        if (projectBtnText === "") return; //N'exécute pas l'action si le bouton est caché

        if (challengeInscriptionDetails?.team?.team) { //On a un projet ?
            navigate(`/participant/challenge/${challengeId}/project/${challengeInscriptionDetails.team?.team?.projects[0].id}`)
        } else if (challengeInscriptionDetails?.canUnregister) runMyProject(); //Si on a pas de projet, on peut démarrer tant que la première étape est pas finie
    }
    //3ème composant : Status inscription équipe ? / Désinscription
    //Affiche soit une possibilité de se désinscrire, soit le status de notre projet (éliminé ? Peut continuer ?)
    const elimine = challengeInscriptionDetails?.team?.team?.eliminated;

    let statusText =
        !hideAllButtons && //On cache dans le cas où on le demande explicitement (props)
            !disableStatus && //On cache si on veut afficher seulement le bouton d'inscription et de projet
            isAuthenticated && challengeInscriptionDetails?.registered ? //Connecté ET inscrit ?
            challengeInscriptionDetails.canUnregister ? //Avant la fin de la première étape ?
                (challengeInscriptionDetails?.team?.team) ?
                    t("page.challenge.leaveTeam") :
                    t("page.challenge.unsubscribe") :
                elimine ?
                    t("page.challenge.notSelected") :
                    t("page.challenge.selected") :
            ""; //NE PAS AFFICHER
    const statusAction = (statusText: string) => {
        if (statusText === "") return; //N'exécute pas l'action si le bouton est caché
        if (statusText === t("page.challenge.unsubscribe")) {
            if (challengeInscriptionDetails.canUnregister) setIsModalUnSubscribeOpen(!isModalUnSubscribeOpen); //Possibilité de se désinscrire avant la fin de la 1ère étape
        } else if (statusText === t("page.challenge.leaveTeam")) {
            setOpenModalQuitTeam(true);
        }
    }

    const handleSignupClick = () => {
        localStorage.setItem("redirect", location.pathname);
        navigate("/signup/participant");
    }

    const handleLoginClick = () => {
        localStorage.setItem("redirect", location.pathname);
        navigate("/login/1")
    }

    const getEligibility = async () => {
        await api.get('/challenge/eligibilities', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                setEligibilities(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const handleCheckboxEligibilitiesChange = (index: any) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
    };

    useEffect(() => {
        getEligibility();
    }, []);
    useEffect(() => {
        console.log("error", error);
    }, [error]);

    useEffect(() => {
        const checkAuth = async () => {
            await login();
        };

        if (!finishedCheckingAuth) {
            checkAuth();
        }
    }, [finishedCheckingAuth, login]);

    const quitTeam = async (isClickLeaveAndDeleteTeam: boolean) => {
        console.log("quitter l'équipe");
        await api.put("/participant/team/quitteam",
            {
                challengeId: challengeId,
                teamId: await challengeInscriptionDetails?.team?.team?.id,
                isClickLeaveAndDeleteTeam: isClickLeaveAndDeleteTeam,
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            setOpenModalQuitTeam(false);
            getRegisteredToChallengeById();
        }).catch((err) => {
            console.log("je passe ici");

            setOpenModalQuitTeam(false);

            setErrorQuite(err.response.data.message)
            setOpenErrorQuite(true)
            console.log(err);
        })
    }

    useEffect(() => {
        console.log(challengeInscriptionDetails?.portfolio?.project?.team?.id);
        const getMemberTeam = async () => {
            await api.get("/participant/team/teammembers",
                {
                    params: {
                        challengeId: challengeId,
                        teamId: challengeInscriptionDetails?.team?.team?.id,
                    },
                    withCredentials: true
                }
            ).then((res) => {
                const challenge = res.data.challenge;
                setChallenge2(res.data.challenge);
                setNbMembers(challenge.projects[0].portfolios.length);
            }).catch((err) => {
                console.log(err);
            })
        }
        getMemberTeam();
    }, [challengeInscriptionDetails]);
    if (!finishedCheckingAuth) {
        // Attendre que la vérification d'authentification soit terminée
        return null; // ou un composant de chargement si vous le souhaitez
    }
    console.log("challengeData => ", challengeData);
    return (
        <div id="run-project" className="participant-challenge" style={{ width: `${hideAllButtons ? '0' : '100%'}` }}>
            <div className='participant-registered'>
                {/* {challengeData && challengeData.registered && ( !challengeData.portfolioExists ?
                    <ActionButton variant="primary" onClick={() => {
                        runMyProject();
                    }}>Démarrer mon projet</ActionButton> :
                    <ActionButton variant="primary" onClick={() => navigate(`/participant/challenge/${challengeId}/project/${challengeData.portfolio.project.id}`)}>
                        Voir mon projet
                    </ActionButton>
                )} */}
                {
                    inscriptionBtnText !== "" && (
                        <ActionButton /*Bouton d'inscription (ou pour démarrer/voir son projet)*/
                            variant={
                                inscriptionBtnVariant
                            }
                            onClick={() =>
                                inscriptionBtnAction()
                            }
                        >
                            {
                                inscriptionBtnText
                            }
                        </ActionButton>
                    )
                }
                {
                    projectBtnText !== "" && (
                        <ActionButton /*Bouton d'inscription (ou pour démarrer/voir son projet)*/
                            variant={
                                "primary"
                            }
                            onClick={() =>
                                projectBtnAction()
                            }
                        >
                            {
                                projectBtnText
                            }
                        </ActionButton>
                    )
                }
                {
                    statusText !== "" && (
                        statusText === t("page.challenge.leaveTeam") ? (
                            <ActionButton /*Bouton d'inscription (ou pour démarrer/voir son projet)*/
                                variant={`${(moment().isAfter(challenge2?.steps?.[0]?.dateEndDeposite) ||
                                    (challenge2?.projects?.[0]?.team?.projects?.[0]?.step_projects?.[0]?.sent === true))
                                    ? 'little-underline-disabled'
                                    : 'little-underline'
                                    }`}
                                onClick={() =>
                                    statusAction(t("page.challenge.leaveTeam"))
                                }
                            >
                                {
                                    statusText
                                }

                            </ActionButton>
                        ) : statusText === t("page.challenge.unsubscribe") ? (
                            <ActionButton /*Bouton d'inscription (ou pour démarrer/voir son projet)*/
                                variant="little-underline"
                                onClick={() =>
                                    statusAction(t("page.challenge.unsubscribe"))
                                }
                            >
                                {
                                    statusText
                                }
                            </ActionButton>
                        ) : (
                            <p>{statusText}</p> /*Simple texte indiquant le status */
                        )

                    )
                }
                {
                    (moment().isAfter(challenge2?.steps?.[0]?.dateEndDeposite) ||
                        (challenge2?.projects?.[0]?.team?.projects?.[0]?.step_projects?.[0]?.sent === true) && (
                            <Tooltip title={`${t("page.challenge.modal.projectAlreadySent")}`}>
                                <IconButton>
                                    <IconInfo />
                                </IconButton>
                            </Tooltip>
                        ))
                }
                {/* <ActionButton
                    variant={
                        buttonVariant
                    }
                    onClick={() =>

                        challengeInscriptionDetails && challengeInscriptionDetails?.registered ? //Inscrit ?
                            challengeInscriptionDetails?.portfolioExists ? //Inscrit -> On a un projet ?
                                navigate(`/participant/challenge/${challengeId}/project/${challengeData.portfolio.project.id}`)
                            : runMyProject()
                        : challengeData?.challenge.registration ?//Pas Inscrit -> Inscriptions ouvertes ?
                            !isAuthenticated ?
                                navigate("/login/1")
                            : challengeData?.openRegistration && setIsModalOpen(!isModalOpen)
                        : null

                    }
                >
                    {
                        buttonText
                    }
                </ActionButton> */}

                {/* {
                    challengeData?.registered && challengeData.canUnregister ? ( Bouton de désinscription
                        <ActionButton
                            variant={"little-underline"}
                            onClick={() => setIsModalUnSubscribeOpen(!isModalUnSubscribeOpen)}
                        >
                            Me désinscrire du challenge
                        </ActionButton>
                    ) : null
                } */}
            </div>
            <Modal
                icon={error?.message && <IconAnnotation />}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={error && error.error === "unsubscribe" ? t("page.challenge.modal.unregisterChallenge") : t("page.challenge.modal.registerChallenge")}
                tertiaryButtonText={error && error.message === t("page.challenge.modal.registrationsClosed") ? undefined : String(t("form.cancel"))}
                tertiaryButtonAction={() => setIsModalOpen(false)}
                primaryButtonText={error && error.message === String(t("page.challenge.modal.registrationsClosed")) ? String(t("page.challenge.modal.gotIt")) : error && error.error === "unsubscribe" ? String(t("page.challenge.unsubscribe")) : String(t("registration.register"))}
                primaryButtonDisabled={
                    challengeData?.challenge.file && eligibilities.length > 0 ?
                        ((checkedItems.filter((item) => item).length !== eligibilities.length) || (isCheckboxChecked === false))
                        : challengeData?.challenge.file === null && eligibilities.length > 0 ?
                            (checkedItems.filter((item) => item).length !== eligibilities.length) :
                            challengeData?.challenge.file !== null && eligibilities.length === 0 ?
                                (!isCheckboxChecked) : false
                }
                primaryButtonAction={() => {
                    if (error && error.error === "unsubscribe") {
                        unsubscribeChallenge();
                    } else if (error && error.message === t("page.challenge.modal.registrationsClosed")) {
                        setIsModalOpen(false);
                    } else {
                        registerChallenge();
                    }
                }
                }
                error={error?.message}
            >
                <div className="participant-challenge-modal-body">
                    {
                        eligibilities.length > 0 && (
                            <div>
                                <p className="text-criteria-register">{t("page.challenge.modal.criteriaRegister")}</p>
                                <div className="bloc-eligibilities-check">
                                    {
                                        eligibilities.map((eligibility, index) => (
                                            <div key={index} className="bloc-eligibility-check">
                                                <input
                                                    type="checkbox"
                                                    id={`check-eligibility-${index}`}
                                                    checked={checkedItems[index]}
                                                    onChange={() => handleCheckboxEligibilitiesChange(index)}
                                                />
                                                <label htmlFor={`check-eligibility-${index}`}
                                                    className="eligibility-label"
                                                >
                                                    {eligibility.label}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                                <p className="sub-text-criteria-register">{t("page.challenge.modal.criteriaSubTextRegister")}</p>
                            </div>
                        )
                    }
                    {challengeData?.challenge.file ? (
                        <>
                            <p>{t("page.challenge.modal.registeringChallenge", { challengeName: challengeData?.challenge.name })}</p>
                            <p className="bold">
                                {t("page.challenge.modal.readChallengeRules")}
                            </p>

                            <p>
                                <a href={`${challengeData.challenge.file.fileUrl}`} target="_blank">
                                    {t("page.challenge.modal.linkToRules")}
                                </a>
                            </p>
                            <div className="block-checkbox">
                                <input
                                    type="checkbox"
                                    id="check-reglement"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="check-reglement">
                                    {t("page.challenge.modal.readAndAcceptRules")}
                                </label>
                            </div>
                        </>
                    ) : (
                        <>
                            {error && error.error === "unsubscribe" ?
                                (
                                    <p style={{ fontWeight: "bold" }}>{t("page.challenge.modal.confirmUnregisterChallenge", { challengeName: challengeData?.challenge.name })}</p>
                                ) : (
                                    <p style={{ fontWeight: "bold" }}>{t("page.challenge.modal.confirmRegisterChallenge", { challengeName: challengeData?.challenge.name })}</p>
                                )}
                        </>
                    )}
                </div>
            </Modal>
            <Modal
                open={openModalQuitTeam}
                onClose={() => setOpenModalQuitTeam(false)}
                title={`${t("page.teamManagement.leaveTeam")}`}
                icon={<IconLogout className='icon-logout' />}
                tertiaryButtonAction={() => quitTeam(false)}
                tertiaryButtonText={`${t("page.teamManagement.leaveTeam")}`}
                tertiaryButtonDisabled={challengeInscriptionDetails?.portfolio?.project?.step_projects[0]?.sent === true ||
                    (nbMembers === 1 && (
                        (challengeInscriptionDetails?.team?.team?.projects[0]?.name?.length > 0) ||
                        (challengeInscriptionDetails?.team?.team?.projects[0]?.short_desc?.length > 0) ||
                        (challengeInscriptionDetails?.team?.team?.projects[0]?.long_desc?.length > 0) ||
                        (challengeInscriptionDetails?.team?.team?.projects[0]?.project_picture) ||
                        (challengeInscriptionDetails?.team?.team?.projects[0]?.answers?.some((answer: any) => answer.answer_text && answer.answer_text.trim().length > 0))
                    ))}
                primaryButtonAction={() => quitTeam(true)}
                primaryButtonText={`${t("page.teamManagement.leaveAndDeleteTeam")}`}
                primaryButtonDisabled={challengeInscriptionDetails?.portfolio?.project?.step_projects[0]?.step?.status?.name === "done" || challengeInscriptionDetails?.portfolio?.project?.step_projects[0]?.sent === true || nbMembers > 1}
                info={true}
            >
                <p className='text-center'>{t("page.teamManagement.textSureLeave")}</p>
                <div style={{ width: "2rem", marginLeft: "auto", marginRight: "auto" }}>
                    {
                        challengeInscriptionDetails?.portfolio?.project?.step_projects[0]?.sent === true && (
                            <Tooltip title={`${t("page.challenge.modal.projectAlreadySent")}`}>
                                <IconButton>
                                    <IconInfo />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </div>
            </Modal>
            <Modal
                open={isModalUnSubscribeOpen}
                onClose={() => setIsModalUnSubscribeOpen(false)}
                icon={<IconLogout />}
                title={t("page.challenge.modal.unregisterChallenge")}
                tertiaryButtonText={String(t("form.cancel"))}
                tertiaryButtonAction={() => setIsModalUnSubscribeOpen(false)}
                primaryButtonText={String(t("page.challenge.unsubscribe"))}
                primaryButtonAction={() => unsubscribeChallenge()}
            >
                <p>{t("page.challenge.modal.confirmUnregisterChallenge", { challengeName: challengeData?.challenge.name })}</p>
            </Modal>
            <Modal
                open={isModalConfirmOpen}
                onClose={() => setIsModalConfirmOpen(false)}
                icon={<IconLike />}
                title={t("page.challenge.modal.successfullyRegistered")}
                primaryButtonText={String(t("page.challenge.myProject.startMyProject"))}
                primaryButtonAction={() => {
                    // setIsModalConfirmOpen(false);
                    // setOpenRunProject(true)
                    if (challengeData && challengeData.challenge.participationType === "single" && challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length === 0) {
                        runMyProjectSingleAndNoSubChallenge();
                        setIsModalConfirmOpen(false)
                    } else {
                        runMyProject();
                    }
                }}
                buttonsColumn={true}
                underlineButtonText={String(t("page.challenge.modal.startLater"))}
                underlineButtonAction={() => setIsModalConfirmOpen(false)}
            >
                <div className='participant-challenge-modal-confirm-body'>
                    <p>{t("page.challenge.myProject.startYourProject")}</p>
                </div>
            </Modal>
            {/* <Button onClick={handleClickOpen('paper')}>Démarrer mon projet</Button> */}
            <Modal
                open={openRunProject}
                onClose={() => handleCloseandClear()}
                title={t("page.challenge.myProject.startMyProject")}
                tertiaryButtonText={String(t("form.cancel"))}
                tertiaryButtonAction={() => handleCloseandClear()}
                primaryButtonText={String(titleActionButton)}
                primaryButtonDisabled={titleActionButtonDisabled}
                primaryButtonAction={() => {
                    if (titleActionButton && titleActionButton !== t("page.challenge.modal.findTeam") && challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.length >= 1) {
                        setOpenSubChallenge(true);
                        setOpenRunProject(false);
                    } else {
                        action()
                    }
                }}
                buttonsColumn={false}
            >
                <h5 className="text-center h5modal mt-4">{t("page.challenge.modal.participating")}</h5>
                {choiceParticipationType && (
                    <Grid container spacing={2} justifyContent={"center"}>
                        {
                            challengeData && (challengeData.challenge.participationType === "single" || challengeData.challenge.participationType === "both") && (
                                <Grid item xs={6}>
                                    <ActionButton variant={!single ? 'deselect' : "choice"}
                                        onClick={() => handleChange("single")}>{t("page.challenge.modal.participationAlone")}</ActionButton>
                                </Grid>
                            )
                        }
                        {
                            challengeData && (challengeData.challenge.participationType === "team" || challengeData.challenge.participationType === "both") && (
                                <Grid item xs={6}>
                                    <ActionButton variant={!team ? 'deselect' : "choice"}
                                        onClick={() => handleChange("team")}>{t("page.challenge.modal.participationTeam")}</ActionButton>
                                </Grid>
                            )
                        }
                    </Grid>
                )}
                <Grow in={team} unmountOnExit>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                            <ActionButton variant={!jointeam ? 'deselect' : "choice"}
                                onClick={() => handleChange("jointeam")}>{t("page.challenge.modal.joinTeam")}</ActionButton>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionButton variant={!createteam ? 'deselect' : "choice"}
                                onClick={() => handleChange("createteam")}>{t("page.challenge.modal.createTeam")}</ActionButton>
                        </Grid>
                    </Grid>
                </Grow>
                <Grow in={team && createteam} unmountOnExit className="mt-4">
                    <div>
                        <h5 className="h5modal text-center mb-3">{t("page.challenge.modal.createTeam")}</h5>
                        <h6 className="text-start label">{t("page.challenge.modal.teamName")}</h6>
                        <p className="text-start label-desc">{t("page.challenge.modal.changeNameLater")}</p>
                        <TextField
                            size="small"
                            sx={{
                                borderRadius: '10px',
                                '& label.Mui-focused': {
                                    color: '#828D9B',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#828D9B',
                                        borderWidth: '1px',
                                    }
                                }

                            }}
                            id='nameteam'
                            variant='outlined'
                            placeholder={String(t("page.challenge.modal.teamName"))}
                            type='text'
                            value={teamName}
                            defaultValue={teamName}
                            onChange={(e) => handleChange("teamName", e)}
                        />

                        <h6 className="mt-4 mb-2 text-start label ">{t("page.challenge.modal.teamRegistrations")}</h6>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="registration-type"
                                name="registrationType"
                                value={registration}
                                defaultValue={registration}
                                onChange={(event) => handleChange("registrationType", event)}
                            >
                                <FormControlLabel
                                    value="openRunProject"
                                    control={
                                        <Radio
                                            sx={{
                                                '&, &.Mui-checked': {
                                                    color: registration === ("openRunProject" || null) ? '#F86767' : '#828D9B'
                                                },
                                            }}
                                        />
                                    }
                                    label={<p
                                        className={`text-start ${registration === ("openRunProject" || null) ? "label-radio-check" : " label-radio"}`}>{t("page.challenge.modal.teamOpen")}</p>} />
                                <FormControlLabel
                                    value="private"
                                    control={
                                        <Radio
                                            sx={{
                                                '&, &.Mui-checked': {
                                                    color: registration !== ("openRunProject" || null) ? '#F86767' : '#828D9B'
                                                },
                                            }}
                                        />
                                    }
                                    label={<p
                                        className={`text-start ${registration !== ("openRunProject" || null) ? "label-radio-check" : " label-radio"}`}>{t("page.challenge.modal.teamByInvitation")}</p>} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Grow>
                <Grow in={team && createteam && registration === "openRunProject"} unmountOnExit className="mt-2">
                    <div>
                        <p className="text-start label-desc">{t("page.challenge.modal.writeAMessage")}</p>
                        <TextField
                            size="small"
                            multiline
                            maxRows={5}
                            inputProps={{ maxLength: 255 }}
                            sx={{
                                borderRadius: '10px',
                                '& label.Mui-focused': {
                                    color: '#828D9B',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#828D9B',
                                        borderWidth: '1px',
                                    }
                                }

                            }}
                            id='message'
                            variant='outlined'
                            placeholder={`Message`}
                            type='text'
                            value={message}
                            defaultValue={message}
                            onChange={(e) => handleChange("message", e)}
                        />
                    </div>
                </Grow>
            </Modal>
            <Modal
                open={openListSubChallenge}
                onClose={() => setOpenSubChallenge(false)}
                title={t("page.challenge.myProject.startMyProject")}
                tertiaryButtonText={challengeData && challengeData.challenge.participationType === "single" ? String(t("form.cancel")) : String(t("form.back"))}
                tertiaryButtonAction={() => {
                    returnToTeamModal()
                }}
                primaryButtonText={String(t("form.validate"))}
                primaryButtonDisabled={selectSubChallengeDisabledButton}
                primaryButtonAction={() => {
                    selectSubChallenge && selectSubChallenge !== (null || undefined || '') && action();
                    // sucessRunProjectModal("project");
                }}
                buttonsColumn={false}
            >
                <div>
                    <h5 className="text-center h5modal mt-4">Sélectionnez un défi</h5>
                    <p>Votre choix pourra être modifié par la suite</p>
                </div>
                <div className="mt-2">
                    <RadioGroup name="use-radio-group" value={selectSubChallenge || ''} sx={{ wordBreak: 'break-word' }}
                        onChange={(e) => handleChange("subChallenge", e)}>
                        {challengeData && challengeData.challenge.subChallenges && challengeData.challenge.subChallenges.map((subchallenge: any, index: number) => (
                            <MyFormControlLabel
                                key={index}
                                value={subchallenge.id.toString()}
                                label={
                                    <div className="text-start">
                                        <h5 className="h5modal">{subchallenge.name}</h5>
                                        <p className="desc-sub-challenge">{subchallenge.description}</p>
                                    </div>
                                }
                                control={
                                    <Radio
                                        sx={{
                                            '&, &.Mui-checked': {
                                                color: subchallenge && selectSubChallenge && selectSubChallenge !== undefined && selectSubChallenge === String(subchallenge.id) ? '#F86767' : '#828D9B'
                                            },
                                        }}
                                    />
                                }
                            />
                        ))}
                    </RadioGroup>
                </div>
            </Modal>
            <Modal
                open={openSucess}
                onClose={() => setOpenSucess(false)}
                title={titleSucess}
                primaryButtonText={String(t("page.challenge.modal.discoverMyProjectPage"))}
                primaryButtonAction={async () => {
                    await setOpenSucess(false);
                    navigate(`/${profile}/challenge/${challengeId}/project/${projectId}`)
                }}
                icon={<IconLike />}
            >
                <p className="text-center">{t("page.challenge.modal.discoverProjectPage")}</p>
                {moreInformation === true && (
                    <div className="bloc-info-modal">
                        <IconInfo className="mb-2" />
                        <h5>{t("page.challenge.modal.information")}</h5>
                        <h6>{runProject && runProject.registration === "openRunProject" ? t("page.challenge.modal.openTeam") : t("page.challenge.modal.closeTeam")}</h6>
                        <span>{t("page.challenge.modal.accessManageTeam")}</span>
                    </div>
                )}

            </Modal>
            <Modal
                open={openErrorTeam}
                onClose={() => {
                    setOpenErrorTeam(false);
                    setErrorTeam(null)
                }}
                title={t("page.challenge.modal.errorOccurred")}
                primaryButtonText={String(t("page.challenge.modal.close"))}
                error={errorTeam && errorTeam.message}
                primaryButtonAction={() => {
                    setOpenErrorTeam(false);
                }}
                icon={<IconAnnotation />}
            >
                {/* <p className="text-center">{errorTeam && errorTeam.message}</p> */}
            </Modal>
            <Modal
                open={openUnauthenticad}
                onClose={() => {
                    setOpenUnauthenticad(false);
                }}
                title={t("page.challenge.modal.titleSubscribeChallenge")}
                icon={<IconProfile />}
                primaryButtonText={t("login")}
                primaryButtonAction={handleLoginClick}
                tertiaryButtonText={t("signup")}
                tertiaryButtonAction={handleSignupClick}
            >
                <p>{t("page.challenge.modal.textSubscribeChallenge")}</p>
            </Modal>
            <Modal
                open={openErrorQuite}
                onClose={() => setOpenErrorQuite(false)}
                title={`${t("page.teamManagement.textImpossibleLeaveTeam")}`}
                icon={<IconInfo />}
                error={errorQuite}
                primaryButtonText={`${t("page.teamManagement.back")}`}
                primaryButtonAction={() => setOpenErrorQuite(false)}
            ></Modal>
        </div>
    );
};

export default ParticipantChallenge;