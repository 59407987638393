import React, { useState } from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import ActionButton from '../../../../../components/ActionButton';
import GoBack from '../../../../../components/GoBack';
import MakeeDiamondLogin from '../../../../../assets/makee-diamond-login';
import { ReactComponent as Diamond } from '../../../../../assets/diamond-eyes-up.svg';
import { useAuth } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SetUpTranslation from '../../../../../components/SetUpTranslation';
import SupportFooter from '../../../../../modules/SupportFooter';
import "./index.css";
const ResetPassword = () => {
    const { sendResetPassword } = useAuth();
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;
    const [t, i18n] = useTranslation('translation');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    SetUpTranslation(i18n);

    const handleResetPassword = (e: any) => {
        e.preventDefault();

        if (!email) {
            setError(`${t('page.resetPassword.error.emailRequired')}`);
            return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setError(`${t('page.resetPassword.error.invalidEmail')}`);
            return;
        }
        sendResetPassword(e);
    };

    return (
        <>
            <div className='password-reset'>
                <div className='password-reset-header'>
                    <div className='go-back'>
                        <GoBack
                            onClick={() => {
                                navigate(-1);
                            }}>
                            {t('page.resetPassword.back')}
                        </GoBack>
                    </div>
                </div>
                <section className='section-container'>
                    <MakeeDiamondLogin className='makee-diamond-login-reset-password' />
                    <div className='form'>
                        <div className='form-header'>
                            <h1 className='h1-auth'>{t('page.resetPassword.title')}</h1>
                            <p className='text'>{t('page.resetPassword.text1')}</p>
                        </div>
                        <FormControl onSubmit={handleResetPassword}>
                            <Box component='form' noValidate autoComplete='off' className='form-content'>
                                <TextField
                                    {...(error && { error: true, helperText: error })}
                                    id='email'
                                    label={t('page.resetPassword.label')}
                                    variant='outlined'
                                    placeholder={`${t('page.resetPassword.label')}`}
                                    type='email'
                                    className='form-text-auth input-text-field'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <ActionButton auth className='button-text-auth auth-button' type='submit' variant='primary'>
                                    {t('page.resetPassword.resetPswd')}
                                </ActionButton>
                            </Box>
                        </FormControl>
                    </div>
                    {!isMobile && <Diamond className='diamond' />}
                </section>
                {isMobile && <Diamond className='diamond' />}
            </div>
            <SupportFooter />
        </>
    );
};

export default ResetPassword;
