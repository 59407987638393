// La page permet de choisir le profil avec lequel on veut se connecter en tant qu'administrateur

// react-dom
import { useNavigate } from "react-router-dom";

// components
import SchoolCard from "../../../../../../../components/SchoolCard";
import { useAuth } from "../../../../../../../context/AuthContext";

// redux
import { useSelector } from "react-redux";
import { RootState } from '../../../../../../../app/type';
import { AdminProfile, CollaboratorProfile } from "../../../../../../../app/type";


// style
import '../index.css'

const LoginAdmin = () => {

    // fonction qui permet de naviguer vers une autre page
    const navigate = useNavigate();

    // récupération des données de l'utilisateur connecté
    const { selectProfile } = useAuth();

    // redux
    const allProfiles = useSelector((action: RootState) => action.profile.allProfiles);


    return (
        <>
            <div className="line"></div>
            <div className="container">
                <h1 className="h1-auth h1-intervenant-admin">Administrateur</h1>
                <p className="form-text-auth">dans l'établissement :</p>
                <div className="all-school-cards">
                    {
                        // affichage des établissements de l'admin (photo, nom)
                        allProfiles && allProfiles.Profiles.admin?.map((admin: AdminProfile, index:number) => {
                            return (
                                <SchoolCard
                                    key={index}
                                    hover
                                    variant='grey'
                                    img_url={admin.pictureEstablishment}
                                    onClick={() => {
                                        selectProfile('admin', admin.establishment)
                                    }}
                                >
                                    {admin.establishment}
                                </SchoolCard>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default LoginAdmin