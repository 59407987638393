import ModuleChallengePlanning from "../../../../../modules/Planning";
import "./index.css";
const ChallengePlanning = () => {
    return (
        <section className="admin-challenge-planning">
            <div className="planning-header">
                <h2>Planning</h2>
            </div>
            <ModuleChallengePlanning />
        </section>
    );
}

export default ChallengePlanning;