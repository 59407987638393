import { Action } from 'redux';
import { ChallengesState, Challenge } from '../type';

interface SetChallengesAction extends Action<'SET_CHALLENGES'> {
    payload: {
        allChallenges: Challenge[] | null;
        getOneChallenge: Challenge | null;
    };
}

const initialState: ChallengesState = {
    getOneChallenge: null,
    allChallenges: null,
};

const challengesReducer = (state = initialState, action: Action): ChallengesState => {
    switch (action.type) {
        case 'SET_CHALLENGES':
            const setChallengesAction = action as SetChallengesAction;
            let newChallenge: Challenge | null = null;
            if (state.getOneChallenge && setChallengesAction.payload.getOneChallenge) {
                newChallenge = {
                    ...state.getOneChallenge,
                    ...setChallengesAction.payload.getOneChallenge,
                };
            } else if (setChallengesAction.payload.getOneChallenge) {
                newChallenge = setChallengesAction.payload.getOneChallenge;
            }
            return {
                ...state,
                getOneChallenge: newChallenge,
                allChallenges: setChallengesAction.payload.allChallenges || state.allChallenges,
            };

        case 'CLEAR_CHALLENGES':
            return initialState;

        default:
            return state;
    }
};

export default challengesReducer;
