// cette page coorespond aux résultats de recherche d'un compte existant
// elle affichera la liste des comptes correspondants au nom et prénom renseignés précedemment

// react
import { useEffect, useState } from "react";

// components
import CardNameDate from "../../../../../../../components/CardNameDate";
import ActionButton from "../../../../../../../components/ActionButton";

// react-dom
import { useNavigate, useLocation } from "react-router-dom";

// context
import { useAuth } from "../../../../../../../context/AuthContext";

// style
import "../index.css";


const ProfileMergeSearch = () => {
  
  const location = useLocation();
  // constante qui sert à afficher un bouton de confirmation 'est ce bien votre compte ?'
  // si la valeur est true, le bouton est affiché
  // si la valeur est false, le bouton n'est pas affiché
  const [clicked, setClicked] = useState(false);

  // navigation
  const navigate = useNavigate();

  // fonction qui permet d'afficher le bouton de confirmation 'est ce bien votre compte ?'
  // au clic, la valeur de la constante clicked passe à true si elle n'était pas déjà à true
  const handleClick = () => {
    setClicked(true);
    clicked ? setClicked(false) : setClicked(true);
  };

  // récupérations des données du profil recherché
  const { searchProfile } = useAuth();


  useEffect(() => {
    console.log(searchProfile)
}, []);
  

  return (
    <>
      <div className="line"></div>
      <section className="profile-merge-search">
        <div className="div-profile-merge-search">
          <h2 className='h2'>Compte trouvé :</h2>
          {/* affichage de la liste des comptes correspondants à la recherche sous forme de cartes */}
          <CardNameDate
            img_url={searchProfile.user.profile_picture}
            name={searchProfile.user.firstname + " " + searchProfile.user.lastname}
          />
        </div>
        <>
          <h2 className='h2'>Est-ce bien votre compte ?</h2>

          <div className="buttons">
            <ActionButton
              auth
              variant="tertiary"
              type="button"
              onClick={() =>
                navigate(-1) // la constante clicked passe à false
              }
            >
              Non
            </ActionButton>

            <ActionButton
              auth
              variant="primary"
              type="button"
              onClick={() =>
                location.pathname.includes('admin') ? navigate("/signup/admin/merge/connect") : // naviguer vers la page de vérification du compte
                location.pathname.includes('participant') ? navigate("/signup/participant/merge/connect") : // naviguer vers la page de vérification du compte
                console.log('erreur')
              }
            >
              Oui
            </ActionButton>
          </div>

        </>
      </section>
    </>
  );
}

export default ProfileMergeSearch;