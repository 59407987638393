// cette page est le relais entre les différentes étapes de la connexion
// elle permet de choisir entre se connecter en tant qu'intervenant ou en tant qu'admin ou en tant que participant

// react-dom
import { Outlet } from 'react-router-dom'

const Login = () => {
    return <Outlet />
}

export default Login