// react-dom
import {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";

// components
import ActionButton from "../../../../../components/ActionButton";
import SchoolCard from "../../../../../components/SchoolCard";
import GoBack from "../../../../../components/GoBack";

// context
import {useAuth} from "../../../../../context/AuthContext";

// react

// style
import './index.css'

// redux
import {useDispatch, useSelector} from "react-redux";
import {ParticipantProfile, RootState} from '../../../../../app/type';
import {AdminProfile, CollaboratorProfile} from "../../../../../app/type";

import {ReactComponent as Arrow} from "../../../../../assets/arrow-right.svg";
import {useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";

const LoginChoice = () => {
    // récupérer le profil de l'utilisateur
    const {logout, selectProfile, getProfile} = useAuth();
    const dispatch = useDispatch();
    // redux state
    const allProfiles = useSelector((action: RootState) => action.profile.allProfiles);
    const profile_picture = useSelector((action: RootState) => action.user.profile_picture);
    const firstname = useSelector((action: RootState) => action.user.firstname);
    const lastname = useSelector((action: RootState) => action.user.lastname);
    const nbAdmin = useSelector((action: RootState) => action.profile.nbAdmin);
    const nbCollaborator = useSelector((action: RootState) => action.profile.nbCollaborator);
    const nbParticipant = useSelector((action: RootState) => action.profile.nbParticipant);
    const profiles = useSelector((action: RootState) => action.profile.profiles);

    // récupérer les profils de l'utilisateur

    // navigation
    const navigate = useNavigate();

    useEffect(() => {
        getProfile(true);
    }, [])
    // clic mobile
    const [click, setClick] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <>
            <div className='login-profile'>
                <section className="section-container">
                    <div className={"container"}>
                        <div className="login-go-back">
                            <GoBack
                                onClick={() => {
                                    setClick(false);
                                    logout();
                                    navigate("/login/1")
                                }
                                }
                            >
                                {t("logout")}
                            </GoBack>
                        </div>
                        {
                            isMobile && (
                                <img src={"/images/diamond-vertical-text.png"} className='logo-makee-diamond-login'
                                     alt="diamandPicture"/>
                            )
                        }
                        <p className="form-text-auth bjr">{t("hello")}</p>

                        <img src={profile_picture} alt="avatar" className="avatar"/>

                        {/* affichage du nom et prénom de l'utilisateur */}
                        <h1 className="h1-auth">{firstname} <br/> {lastname}</h1>

                        <div className="profile-choice">
                            <p className="form-text-auth connect">{t("page.loginChoice.loginAs")}</p>
                            {   // affichage de la liste des profils disponibles
                                profiles && profiles.map((e: any, index: number) => {
                                    return (
                                        <div className="w-100" key={index}>
                                            <ActionButton
                                                auth
                                                icon={(e === 'admin' && nbAdmin > 1) || (e === 'participant' && nbParticipant > 1) ?
                                                    <Arrow/> : ''}
                                                iconEnd={true}
                                                variant={"grey"}
                                                type="button"
                                                className={e === "participant" && allProfiles.Profiles.participant?.[0].deleted ? 'error-button' : ''}
                                                onClick={() => {
                                                    if (e === 'admin') {
                                                        if (nbAdmin && nbAdmin <= 1) {
                                                            const adminProfile = allProfiles.Profiles.admin?.[0];
                                                            if (adminProfile) {
                                                                selectProfile('admin', adminProfile.establishment);
                                                            }
                                                        } else {
                                                            navigate("/login/choice/profile/admin");
                                                        }
                                                    } else if (e === 'intervenant') {
                                                        if (nbCollaborator && nbCollaborator <= 1) {
                                                            const collaboratorProfile = allProfiles.Profiles.collaborator?.[0];
                                                            if (collaboratorProfile) {
                                                                selectProfile('collaborator', collaboratorProfile.establishment);
                                                            }
                                                        } else {
                                                            navigate("/login/choice/profile/intervenant");
                                                        }
                                                    } else if (e === 'participant') {
                                                        if (nbParticipant && nbParticipant <= 1) {
                                                            const participantProfile = allProfiles.Profiles.participant?.[0];
                                                            if (participantProfile && !participantProfile.deleted) {
                                                                selectProfile('participant', participantProfile.establishment);
                                                            }
                                                        } else {
                                                            navigate("/login/choice/profile/participant");
                                                        }
                                                    }
                                                    setClick(true);
                                                }}

                                            >
                                                <p className={"name-profile"}>{
                                                    // affichage du nom du profil en fonction du type de profil
                                                    // admin => Administrateur
                                                    // intervenant => Intervenant
                                                    // participant => Participant
                                                    e === "admin" ?
                                                        t("page.loginChoice.admin")
                                                        :
                                                        e === "intervenant" ?
                                                            "Intervenant"
                                                            :
                                                            e === "participant" ?
                                                                "Participant"
                                                                : ""
                                                }</p>
                                                {
                                                    // si il y a un seul profil administrateur, afficher le nom de l'établissement associé
                                                    // redirection vers la page d'accueil
                                                    e === "admin" && nbAdmin && nbAdmin <= 1 ?
                                                        allProfiles && allProfiles.Profiles.admin?.map((admin: AdminProfile, index: number) => {
                                                            return (
                                                                <SchoolCard
                                                                    key={index}
                                                                    img_url={admin.pictureEstablishment}
                                                                    onClick={() => {
                                                                        selectProfile('admin', admin.establishment)
                                                                    }}
                                                                >
                                                                    {admin.establishment}
                                                                    {admin.deleted}
                                                                </SchoolCard>
                                                            )
                                                        })
                                                        :
                                                        // si il y a un seul profil intervenant, afficher le nom de l'établissement associé
                                                        // redirection vers la page d'accueil
                                                        e === "intervenant" && nbCollaborator && nbCollaborator <= 1 ?

                                                            allProfiles && allProfiles.Profiles.collaborator?.map((collaborator: CollaboratorProfile, index: number) => {
                                                                return (
                                                                    <div className='school-card-login-choice' key={index}>
                                                                        <SchoolCard
                                                                            img_url={collaborator.pictureEstablishment}
                                                                            onClick={() => {
                                                                                selectProfile('collaborator', collaborator.establishment);
                                                                            }}
                                                                        >
                                                                            {/* {collaborator.establishment} */}
                                                                            établissement
                                                                        </SchoolCard>
                                                                    </div>
                                                                );
                                                            })
                                                            :
                                                            <></>
                                                }
                                            </ActionButton>
                                            {
                                                e === "participant" && allProfiles && allProfiles.Profiles.participant?.map((participant: ParticipantProfile, index: number) => {
                                                    return (
                                                        <>
                                                            {participant.deleted && (
                                                                <p className={"error-profile"}>Ce profil est désactivé. Pour le
                                                                    réactiver,
                                                                    contactez-nous sur <a
                                                                        href={"mailto:contact@challenkers.com"}>contact@challenkers.com</a>
                                                                </p>
                                                            )}
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <Outlet/>
                </section>
            </div>
        </>
    );
}

export default LoginChoice;
