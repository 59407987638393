// style
import './index.css';

// hooks
import {useEffect, useState} from 'react';

// components
import ActionButton from '../../../../../../components/ActionButton';

// logos
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';
import {ReactComponent as Diamond} from '../../../../../../assets/diamond-eyes-up.svg';

// axios
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import SupportFooter from "../../../../../../modules/SupportFooter";
import api from "../../../../../../utils/api";

const resendPasswordMail = async (email: string, setEmailSent: (data: any) => void) => {
    await api.post(
        '/auth/send_reset_password',
        {
            emailreset: email,
            language: localStorage.getItem("translation")
        }, {
            withCredentials: true
        })
        .then((response) => {
            localStorage.setItem('emailreset', email);
            setEmailSent(true);
        })
        .catch((error) => {
            console.log(error);
        });
}

const ResetPasswordDone = () => {
    const [email, setEmail] = useState(' ');

    const [emailreset, setEmailReset] = useState(' ');

    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        if (email === ' ') {
            const mail = localStorage.getItem('emailreset')?.replace(/.{5}(?=@)/g, 'xxxxx') || ' ';
            setEmail(mail);
            setEmailReset(localStorage.getItem('emailreset') || ' ');
        } else {
            localStorage.removeItem('emailreset');
        }
    }, [email]);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <>
            <div className='password-reset-done'>
                <section className='password-reset-done-section-container'>
                    <MakeeDiamondLogin className='makee-diamond-login-reset-password'/>
                    <div className="password-reset-done-main">
                        <div className="main-header">
                            <h1 className="h1-auth">{t("page.resetPasswordDone.title")}</h1>
                            <p className="text">{t("page.resetPasswordDone.text1")}</p>
                        </div>
                        <h1>{email}</h1>
                        <div className="main-footer">
                            <p className="text italic">{t("page.resetPasswordDone.rememberCheckSpam")}</p>
                            {emailSent &&
                                <p className="text email-sent-text">{t("page.resetPasswordDone.emailSent")}</p>}
                            <ActionButton
                                type='button'
                                variant='tertiary'
                                className='button-text-auth'
                                onClick={() => {
                                    setEmailSent(false);
                                    resendPasswordMail(emailreset, setEmailSent)
                                }}
                            >
                                {t("page.resetPasswordDone.buttonText")}
                            </ActionButton>
                            <a href="mailto:contact@challenkers.com"
                               className='text link'>{t("page.resetPasswordDone.textContact")}</a>
                        </div>
                    </div>
                    {window.innerWidth > 768 && <Diamond className='diamond-reset-done'/>}
                </section>
            </div>
            <SupportFooter/>
        </>
    )
}

export default ResetPasswordDone;
