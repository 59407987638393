import * as React from 'react';

import {Grid, Chip, AccordionSummary, AccordionDetails, Accordion} from "@mui/material";

import {ReactComponent as IconArrowDown} from '../../assets/arrow-down.svg';
import {ReactComponent as IconDownload} from '../../assets/icon-download.svg';
import {ReactComponent as IconEye} from '../../assets/icon-eye.svg';
import moment from 'moment';
import {useNavigate} from "react-router-dom";

import './index.css';
import api from "../../utils/api";

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";

const CollaspseSteps = ({stepsProject}: any) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const viewDeliverable = (url: string) => () => {
        console.log("viewDeliverable", url);
        window.open(url, '_blank', 'noreferrer');
    }

    const dowloadDeliverable = async (url: string, fileName: string) => {
        await api.get('/downloadfile',
            {
                params: {
                    url: url,
                    filename: fileName
                },
                withCredentials: true,
                responseType: 'blob'
            }).then((res) => {
            // Créez un lien pour le téléchargement du fichier
            const blob = new Blob([res.data], {type: res.headers['content-type']});

            const urlToDownload = window.URL.createObjectURL(blob);

            // Créez un élément d'ancre pour déclencher le téléchargement
            const a = document.createElement('a');
            a.href = urlToDownload;
            a.download = String(fileName);
            a.style.display = 'none';
            document.body.appendChild(a);

            // Déclenchez le téléchargement
            a.click();

            // Nettoyez l'URL créé
            window.URL.revokeObjectURL(urlToDownload);
        })
            .catch((err) => {
                console.log(err);
            });

    }

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    return (
        <div id='module-collapse-steps-recap'>
            {stepsProject.map((step: any, index: number) => (
                // Utilisez des parenthèses pour encadrer le contenu du .map
                <Accordion
                    key={index} // Ajoutez une clé unique pour chaque élément généré par .map
                    expanded={expanded === String(index)}
                    onChange={(step.step.questions.length + step.deliverable_step_projects.length !== 0) ? handleChange(String(index)) : () => {}}
                    disableGutters={true}
                    sx={{
                        boxShadow: 'none !important',
                        marginBottom: '1em !important',
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={(step.step.questions.length + step.deliverable_step_projects.length !== 0) &&
                            <IconArrowDown/>}
                        aria-controls={`${index}bh-content`}
                        id={`${index}bh-header`}
                        sx={{
                            background: '#EDF1F7 !important',
                            boxShadow: 'none',
                            borderTopLeftRadius: '10px !important',
                            borderTopRightRadius: '10px !important',
                            borderRadius: expanded === false ? '10px !important' : '0px',
                        }}
                    >

                        <div className='summary-collapse'>
                            <p>{t("page.projectRunning.collapseStep.submittedOn")} {moment(String(step?.dateDeposite)).format("D/M/YYYY, H[h]mm")}</p>
                            <h4>{step.step.name}</h4>
                            <span>{
                                step.step.hasSelection && step.selected && moment(String(step.step.dateEndSelection)).isBefore(moment()) ? t("page.projectRunning.collapseStep.selected") :
                                    step.step.hasSelection && !step.selected && moment(String(step.step.dateEndSelection)).isBefore(moment()) ? t("page.projectRunning.collapseStep.notSelected") :
                                        step.step.hasSelection && !step.selected && moment(String(step.step.dateEndSelection)).isAfter(moment()) && t("page.projectRunning.collapseStep.waitingSelection")
                            }</span>
                        </div>
                    </AccordionSummary>
                    {(step.step.questions.length + step.deliverable_step_projects.length !== 0) && (
                        <AccordionDetails
                            sx={{
                                background: '#D9E0EA !important',
                                padding: '16px 16px 1em 1em !important',
                                borderBottomLeftRadius: '10px !important',
                                borderBottomRightRadius: '10px !important',
                            }}
                        >
                            {step.step.questions && step.step.questions.length > 0 && (
                                <div>
                                    {step.step.questions.map((question: any, index: number) => (
                                        <div key={index}>
                                            <div className='chip-info-recap'>
                                                <h4
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${question.questionText}`
                                                    }}
                                                />
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${question.answers[0].answerText}`
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <br/>
                                </div>
                            )}
                            {step.step.deliverables && step.deliverable_step_projects && (
                                <>
                                    {step.step.deliverables.length > 0 && (
                                        <div>
                                            <h3>{t("page.projectRunning.collapseStep.requiredDeliverables")}</h3>
                                            {step.step.deliverables.map((deliverable: any, index: number) => (
                                                <div key={index}>
                                                    <div className='chip-info-recap'>
                                                        <h4>{deliverable.name}</h4>
                                                        <p>{deliverable.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                            <br/>
                                        </div>
                                    )}
                                    {step.deliverable_step_projects.length > 0 && (
                                        <div>
                                            <h3>{t("page.projectRunning.collapseStep.deliverablesSubmitted")}</h3>
                                            {step.deliverable_step_projects.map((deliverable: any, index: number) => (
                                                <div key={index}>
                                                    <div className='chip-info-recap'>
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item xs>
                                                                <h4>{deliverable.file.originalFileName}</h4>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container spacing={2} paddingRight={1}>
                                                                    <Grid item xs>
                                                                        <IconEye
                                                                            onClick={viewDeliverable(deliverable.file.fileUrl)}
                                                                            className='cursor-pointer'/>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <IconDownload
                                                                            onClick={() => dowloadDeliverable(deliverable.file.fileUrl, deliverable.file.originalFileName)}
                                                                            className='cursor-pointer'/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}

                        </AccordionDetails>
                    )}
                </Accordion>
            ))}
        </div>
    );
};

export default CollaspseSteps;
