// components
import { Box, FormControl, TextField } from "@mui/material";
import ImageUploader from "../../../../../../modules/ImageUploader/ImageUploader";
import Button from "../../../../../../components/Button";

// hooks & context
import { useAuth } from "../../../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

// consts
import { DEFAULT_ESTABLISHMENT_IMAGE } from "../../../../../../utils/consts";

// style
import './index.css';


// types & functions
import { IEstablishmentDetails, fetchEstablishmentDetails } from "..";
import api from "../../../../../../utils/api";

const AdminConfigGeneralUpdate = () => {
    const { errorMessage } = useAuth();
    const [imagePreview, setImagePreview] = useState<string>('');
    const [fileToUpload, setFileToUpload] = useState<File>();
    const [fileToDelete, setFileToDelete] = useState<string | null>(null);
    const [establishmentDetails, setEstablishmentDetails] = useState<IEstablishmentDetails>({
        id: 0,
        name: "",
        establishmentPicture: DEFAULT_ESTABLISHMENT_IMAGE,
        establishmentType: "",
        created_at: "",
        adminCount: 0,
        nbChallenges: 0,
        challengeCount: 0
    });
    useEffect(() => {
        fetchEstablishmentDetails(setEstablishmentDetails);
    }, [])

    const updateEstablishment = (e: any, defaultEstablishmentName: string | undefined, navigate: NavigateFunction) => {
        e.preventDefault();

        const name = e.target.name.value;
        const formData = new FormData();
        if (fileToUpload) {
            formData.append('file', fileToUpload);
        }
        formData.append('establishment_name', defaultEstablishmentName as string);
        formData.append('newname', name);

        if (fileToDelete) {
            formData.append('fileToDelete', fileToDelete);
        }

        api.put("/admin/update_establishment",
            formData,
            {
                withCredentials: true
            }
        ).then((res) => {
                navigate('/admin/school/config/general');
            }
        ).catch((err) => {
            console.log(err);
        })
    }

    const navigate = useNavigate();

    function handleDeleteLogo() {
        if (establishmentDetails.establishmentPicture.includes("makee_bucket")) {
            setFileToDelete(establishmentDetails.establishmentPicture);
        }
    }

    return(
        <div className="admin-update-establishment">
            <FormControl onSubmit={(e) => updateEstablishment(e, establishmentDetails.name, navigate)}>
                <Box component="form" noValidate autoComplete='off' className='form-content'>
                    <TextField
                        {
                        ...(
                            errorMessage && {
                                error: true,
                                helperText: errorMessage
                            })
                        }
                        id='name'
                        label='Nom de l’établissement'
                        variant='outlined'
                        placeholder='Nom de l’établissement'
                        type='text'
                        className='form-text-auth input-text-field'
                        defaultValue={establishmentDetails.name}
                        key={establishmentDetails.name}
                    />
                    <div className="image-input-update-establishment">
                        <p className="form-title">Logo de l'établissement</p>
                        <ImageUploader 
                            image={establishmentDetails.establishmentPicture}
                            defaultImage={"image-establishment"}
                            imagePreview={imagePreview}
                            setImagePreview={setImagePreview}
                            setFileToUpload={setFileToUpload}
                            handleDelete={handleDeleteLogo}
                            ratio="1200/350"
                        />
                    </div>
                    <footer className="footer-update-establishment">
                        <Button option="cancel" padding="0.8rem 1.3rem" onClick={(e) => navigate(-1)}>Annuler</Button>
                        <Button option="submit" padding="0.8rem 1.3rem">Modifier</Button>
                    </footer>
                </Box>
            </FormControl>
        </div>
    )
}

export default AdminConfigGeneralUpdate;