// style
import './index.css'

// components
import {Box, FormControl, TextField} from '@mui/material';
import ActionButton from '../../../../../components/ActionButton';

// logos
import MakeeDiamondLogin from '../../../../../assets/makee-diamond-login';
import {ReactComponent as Diamond} from '../../../../../assets/diamond-eyes-up.svg';

// hooks & context
import {useAuth} from '../../../../../context/AuthContext';
import {useParams} from 'react-router-dom';
import SupportFooter from "../../../../../modules/SupportFooter";

const ForgotPassword = () => {
    const {resetPassword, errorMessage} = useAuth();
    const {token} = useParams();
    const isMobile: boolean = window.innerWidth < 768;
    return (
        <>
            <div className='forgot-password'>
                <section className="section-container">
                    <MakeeDiamondLogin className='makee-diamond-login-reset-password'/>
                    <div className="form">
                        <div className="form-header">
                            <h1 className="h1-auth">Réinitialisez votre mot de passe</h1>
                        </div>
                        <FormControl onSubmit={(e) => resetPassword(e, token)}>
                            <Box component="form" noValidate autoComplete='off' className='form-content'>
                                <TextField
                                    {
                                        ...(
                                            errorMessage && {
                                                error: true,
                                                helperText: errorMessage
                                            })
                                    }
                                    id='password'
                                    label='Votre nouveau mot de passe'
                                    variant='outlined'
                                    placeholder={'*'.repeat(10)}
                                    type='password'
                                    className='form-text-auth'
                                    helperText='Votre mot de passe doit contenir au minimum 8 caractères, dont 1 majuscule, 1 minuscule, 1 caractère spécial et 1 chiffre.'
                                />
                                <TextField
                                    {
                                        ...(
                                            errorMessage && {
                                                error: true,
                                                helperText: errorMessage
                                            })
                                    }
                                    id='passwordconfirm'
                                    label='Confirmez votre nouveau mot de passe'
                                    variant='outlined'
                                    placeholder={'*'.repeat(10)}
                                    type='password'
                                    className='form-text-auth'
                                />
                                <ActionButton
                                    auth
                                    className='button-text-auth auth-button'
                                    type='submit'
                                    variant='primary'
                                >
                                    Réinitialiser mon mot de passe
                                </ActionButton>
                            </Box>
                        </FormControl>
                    </div>
                    {!isMobile && <Diamond className='diamond'/>}
                </section>
                {isMobile && <Diamond className='diamond'/>}
            </div>
            <SupportFooter />
        </>
    )
}

export default ForgotPassword;