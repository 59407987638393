// cette page permet de choisir l'école que l'on souhaite associé à notre profil intervenant lors de la connexion 
// exemple : je veux me connecter en tant qu'intervenant dans l'établissement ESC Rennes

// react-dom
import { useNavigate } from "react-router-dom";

// components
import SchoolCard from "../../../../../../../components/SchoolCard";

// context
import { useAuth } from "../../../../../../../context/AuthContext";

// style
import '../index.css'


const LoginIntervenant = () => {

    // fonction qui permet de naviguer vers une autre page
    const navigate = useNavigate();

    // récupération des données de l'utilisateur connecté
    const { allProfiles, selectProfile } = useAuth();

    return (
        <>
            <div className="line"></div>
            <div className="container">
                <h1 className="h1-auth h1-intervenant-admin">Intervenant</h1>
                <p className="form-text-auth">dans l'établissement :</p>
                <div className="all-school-cards">

                    {
                        // affichage des établissements de l'intervenant (photo, nom)
                        allProfiles && allProfiles.Profiles && allProfiles.Profiles.intervenant && allProfiles.Profiles.intervenant.map((intervenant: any, index:number) => {
                            return (
                                <SchoolCard
                                    key={index}
                                    hover
                                    variant='grey'
                                    img_url={intervenant.pictureEstablishment}
                                    onClick={() => {
                                        selectProfile('intervenant', intervenant.establishment)
                                    }}
                                >
                                    {intervenant.establishment}
                                </SchoolCard>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default LoginIntervenant