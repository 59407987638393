// style
import './style.css';

// icons
import {ReactComponent as IconAdd} from '../../assets/icon-add.svg';
import {ReactComponent as IconSee} from '../../assets/icon-see.svg';
import {ReactComponent as IconUpdate} from '../../assets/icon-update.svg';
import {ReactComponent as IconTrash} from '../../assets/icon-trash.svg';

// React
import React from 'react';

/**
 * Interface représentant les props du composant Button.
 */
interface ButtonProps {
    option?: "submit" | "add" | "see" | "cancel" | "delete" | "update" | undefined,
    padding?: string | undefined | {
        top?: string | undefined,
        right?: string | undefined,
        bottom?: string | undefined,
        left?: string | undefined
    },
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    disabled?: boolean
}

/**
 * 
 * Renvoie un bouton avec des paramètres spécifiques.
 * 
 * ---
 * 
 * @param option {string} - Option du bouton
 * @param padding {string | undefined | {top?: string | undefined, right?: string | undefined, bottom?: string | undefined, left?: string | undefined}} - Padding du bouton
 * @param children {React.ReactNode} - Contenu du bouton
 * @param onClick {(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} - Fonction à exécuter au clic
 * 
 * ---
 * @example
 * <Button option="add" padding="10px">Ajouter</Button>
 * <Button option="see" padding={{top: "10px", right: "20px", bottom: "10px", left: "20px"}}>Voir</Button>
 * 
 */
const Button = ({option, padding, children, onClick, disabled}: ButtonProps) => {
    // const { setError } = useAuth();
    
    return (
        <button onClick={disabled?() =>{}:(e) => {if(onClick !== undefined) onClick(e);}} className={"basic-button " + (option === "add" || option === "see" ? "basic-button-classic" : "basic-button-" + option) +(disabled?" disabled-button":"")} type={option === "submit" ? "submit" : "button"} style={
            padding ?
            typeof padding === "string" ?
            {padding: padding} :
            {paddingTop: padding.top, paddingRight: padding.right, paddingBottom: padding.bottom, paddingLeft: padding.left} :
            {}
        }
        disabled={disabled}
        >
            {option === "add" && <IconAdd />}
            {option === "see" && <IconSee />}
            {option === "update" && <IconUpdate />}
            {option === "delete" && <IconTrash />}
            <p className='button-text'>{children}</p>
        </button>
    )
}

export default Button;