// Style
import './index.css';

// hooks
import { useSortable } from '@dnd-kit/sortable';
import React, { useState, useEffect } from 'react';

// icons
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { ReactComponent as IconDragNDrop } from '../../assets/icon-dragndrop.svg';

// components
import ImageUploader from '../../modules/ImageUploader/ImageUploader';

// utilities
import { CSS } from '@dnd-kit/utilities';

import IPartner from './types'
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/index';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';

interface PartnerProps {
    partner: IPartner;
    handleDeletePartner: (partner: IPartner) => void;
    handleUpdatePartner: (partner: IPartner) => void;
    disabled?: boolean;
}

const Partner = ({ partner, handleDeletePartner, handleUpdatePartner, disabled }: PartnerProps) => {

    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    const { imagePartners, setImagePartners} = useAdminEditChallengeContext()
    
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: partner.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const [imagePreview, setImagePreview] = useState<string>("");
    const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);
    const [hasClickedOnCancelButton, setHasClickedOnCancelButton] = useState<boolean>(false);

    useEffect(() => {
        console.log("fileToUpload",fileToUpload);
        //mettre à jour le tableau fileToUpload qui correspond à ça imagePartners:[number,File] setImagePartners: (imagePartners: [number,File]) => void,
        if(fileToUpload){
            if(partner && partner.id) {
                setImagePartners([...imagePartners, {id:partner.id, file: fileToUpload}]);
                //Supprimer les errorFieldGeneral avec cette id 
                const newErrorFieldGeneral = errorFieldGeneral.filter((errorField: { id: string | number; })=> String(errorField.id) !== String(partner.id));
                setErrorFieldGeneral(newErrorFieldGeneral);
            }
        }
    }, [fileToUpload]);

    

    return (
        <div ref={setNodeRef} {...attributes} style={style} {...listeners}>
            <FormHelperText sx={{marginBottom: '1vh'}}
                error={errorFieldGeneral.find((error:any) => error.code === 'partners')?true:false}
            >
                {errorFieldGeneral.find((error:any) => error.code === 'partners')?errorFieldGeneral.find((error:any) => error.code === 'partners').message:''}
            </FormHelperText>
            <TextField
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(partner.id) && (error.code === 'partner')) || (error.code === 'partners'))?true:false}
                variant="outlined"
                fullWidth={true}
                defaultValue={partner.name}
                // placeholder="Partenaire"
                multiline
                inputProps={{ maxLength: 50 }}
                // select
                label="Nom du partenaire *"
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconClose 
                            className='icon-close icon-clickable cursor-pointer' 
                            // onClick={disabled?() => {} :() => removeDeliverableFromStep(String(stepId), String(deliverable.id))}
                            onClick={disabled?() => {} : () => handleDeletePartner(partner)}
                        />
                        <IconDragNDrop className={`icon-dragndrop icon-clickable ${!disabled&& 'draggable'} cursor-pointer`}/>
                        {/* <IconDragNDrop className={`icon-dragndrop icon-clickable draggable cursor-pointer`}/> */}
                    </InputAdornment>,
                }}
                InputLabelProps={{
                    style: { 
                        color: '#a9a9a9',
                        fontWeight: '400'
                    },
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '0px',
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }     
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, name: e.target.value })}
                onChange={(e) => {
                    handleUpdatePartner({ ...partner, name: e.target.value })                    
                    setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => (String(error.id) !== String(partner.id))))
                }}
                // disabled={disabled}
            />
            <div style={{border:"solid 1px rgba(0, 0, 0, 0.23)", borderTop:"0px", padding:"2em"}}>
                <ImageUploader
                    disabled={disabled}
                    image={partner.partnerLogo?partner.partnerLogo:""}
                    defaultImage={"image-prize"}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    setFileToUpload={setFileToUpload}
                    hasClickedOnCancelButton={hasClickedOnCancelButton}
                    id={partner.id}
                    noImageText="Vous n'avez pas encore d'image pour votre partenaire"
                    noImageButtonText="Ajouter une image"
                    ratio='1/1'
                    width={"20rem"}
                    height={"auto"}
                    noDeletion={true}
                />
            </div>
            <TextField
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(partner.id) && (error.code === 'partner')) || (error.code === 'partners'))?true:false}                
                helperText={errorFieldGeneral.find((error:any) => String(error.id) === String(partner.id) && error.code === 'partner')?errorFieldGeneral.find((error:any) => String(error.id) === String(partner.id) && error.code === 'partner').message:''}
                variant="outlined"
                fullWidth={true}
                defaultValue={partner.partnerLink}
                placeholder="Lien du partenaire"
                multiline
                maxRows={3}
                minRows={2}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                    startAdornment: <InputAdornment sx={{marginBottom:'10px'}} position="start">Lien (max 255 caractères)</InputAdornment>,
                }}
                // disabled={disabled}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        display:'block',
                        background: "#fff",
                        // borderRadius: '10px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '10px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '10px',
                        '& fieldset' :{
                            borderTop: '0px',
                        },
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }   
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, description: e.target.value })}
                onChange={(e) => {
                    handleUpdatePartner({ ...partner, partnerLink: e.target.value })
                    setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => (String(error.id) !== String(partner.id))))
                }}
                // disabled={disabled}
            />
        </div>
        // <div className="partner" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        //     <div className="partner-header">
        //         <div className="partner-title">
        //             <div>
        //                 {isEditingName ? (
        //                     <h2 className="h1 form-title category-title">
        //                         Nom du partenaire
        //                     </h2>
        //                 ) : (
        //                     <textarea ref={partnerNameTextArea} name="partnerName" id={"partner-name-" + partner.id} className='text input-editable' placeholder="Nom" value={partner.name} onChange={(e) => {
        //                         setCurrentPartner({
        //                             ...currentPartner,
        //                             name: e.target.value
        //                         });
        //                         handleUpdatePartner({
        //                             ...currentPartner,
        //                             name: e.target.value
        //                         });
        //                     }} onClick={handleEditName}/>
        //                 )}
        //                 <span className='icons'>
        //                     <IconClose className="icon-close icon-clickable" onClick={() => handleDeletePartner(currentPartner)} />
        //                     <IconDragNDrop className="icon-dragndrop icon-clickable draggable" />
        //                 </span>
        //             </div>
        //             {isEditingName && (
        //                 <textarea ref={partnerNameTextArea} name="partnerName" id={"partner-name-" + partner.id} className='text input-editable' placeholder="Nom" value={partner.name} onChange={(e) => {
        //                     setCurrentPartner({
        //                         ...currentPartner,
        //                         name: e.target.value
        //                     });
        //                     handleUpdatePartner({
        //                         ...currentPartner,
        //                         name: e.target.value
        //                     });
        //                 }}/>
        //             )}
        //         </div>
        //     </div>
        //     <div className="partner-image">
        //         <h1 className='form-title category-title'>Logo du partenaire</h1>
        //         <ImageUploader
        //             image={partner.image}
        //             defaultImage={"default"}
        //             imagePreview={imagePreview}
        //             setImagePreview={setImagePreview}
        //             setFileToUpload={setFileToUpload}
        //             id={"partner-" + partner.id + (mobile ? '-mobile' : '')}
        //             imgRef={partner.ref}
        //             noImageText="Vous n'avez pas encore de logo pour votre partenaire"
        //             noDeletion
        //             ratio="1/1"
        //         />
        //     </div>
        //     <div className="partner-link">
        //         {isEditingLink ? (
        //             <h2 className='h1 form-title category-title'>Lien vers le partenaire <span className='unbold'>(facultatif)</span></h2>
        //         ) : (
        //             <textarea ref={partnerLinkTextArea} name="partnerLink" id={"partner-link-" + partner.id} className='text input-editable' placeholder='Lien' value={partner.link} onChange={(e) => {
        //                 setCurrentPartner({
        //                     ...currentPartner,
        //                     link: e.target.value
        //                 });
        //                 handleUpdatePartner({
        //                     ...currentPartner,
        //                     link: e.target.value
        //                 });
        //             }} onClick={handleEditLink}/>
        //         )}
        //         {isEditingLink && (
        //             <textarea ref={partnerLinkTextArea} name="partnerLink" id={"partner-link-" + partner.id} className='text input-editable' placeholder='Lien' value={partner.link} onChange={(e) => {
        //                 setCurrentPartner({
        //                     ...currentPartner,
        //                     link: e.target.value
        //                 });
        //                 handleUpdatePartner({
        //                     ...currentPartner,
        //                     link: e.target.value
        //                 });
        //             }}/>
        //         )}
        //     </div>
        // </div>
    );
}

export default Partner;