import {
    Alert,
    Avatar,
    AvatarGroup,
    Button,
    Chip,
    Fade,
    FormControlLabel,
    FormControlLabelProps,
    FormHelperText,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Snackbar,
    Stack,
    TextField,
    Theme,
    useMediaQuery,
    useRadioGroup,
    useTheme
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {ReactComponent as IconArrowRight} from "../../../../../assets/arrow-right.svg";
import {ReactComponent as IconEdit} from "../../../../../assets/icon-edit.svg";
import {ReactComponent as IconChange} from "../../../../../assets/icon-change.svg";
import {ReactComponent as IconClose} from "../../../../../assets/icon-close.svg";
import {ReactComponent as IconAdd} from "../../../../../assets/icon-add.svg";
import {ReactComponent as IconCheckFlash} from "../../../../../assets/icon-check-flash.svg";
import {ReactComponent as IconLogout} from "../../../../../assets/icon-logout.svg";
import {ReactComponent as IconWarn} from "../../../../../assets/icon-warn.svg";
import {ReactComponent as IconLike} from "../../../../../assets/icon-like.svg";
import {ReactComponent as IconArrowDown} from '../../../../../assets/arrow-down.svg';
import {ReactComponent as IconTrash} from '../../../../../assets/icon-trash.svg';
import StepperGradient from "../../../../../components/StepperGradient";

import "./index.css";
import ActionButton from "../../../../../components/ActionButton";
import React, {createRef, useEffect, useRef, useState} from "react";
import {
    IAnswerQuestions,
    IChallenge,
    IDefaultStep,
    IDeliverable,
    IDeliverableFile,
    IErrorDefaultDataProject,
    IErrorQuestion,
    IProject,
    IQuestion,
    IStepInprogress,
    IStepSend,
    IStepper,
    ISubChallenge,
    ITeam,
    IWaiting
} from "./types";
import moment from "moment";
import Modal from "../../../../../modules/Modal";
import Save from "../../../../../components/Save";

import {DEFAULT_MAIN_PROJECT} from "../../../../../utils/consts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../app/type";
import {v4 as uuidv4} from 'uuid';
import ImageUploader from "../../../../../modules/ImageUploader/ImageUploader";
import CollaspseSteps from "../../../../../modules/CollapseSteps";
import {useAdminEditChallengeContext} from "../../../Admin/AdminChallenges/AdminEditChallenge";
import {set} from "lodash";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import axios from "axios";

// import { RootState } from '../../../../../App/type';

const ProjectRunning = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectId = useParams().projectId;

    const projects = useSelector((state: RootState) => {
        const {projects} = state.projectInProgress;
        if (!projects) {
            return null;
        } else {
            return projects.find((project) => String(project.projectId) === String(projectId));
        }
    });

    const PersisteProjectInProgressDefaultStep = projects && projects.defaultStep
    const PersisteProjectInProgressQuestions = projects && projects.questions

    const challengeId = useParams().id;
    const theme = useTheme();
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [single, setSingle] = useState(false);

    const [project, setProject] = useState<IProject>();
    const [errorAccess, setErrorAccess] = useState<boolean>(false);
    const [challenge, setChallenge] = useState<IChallenge>();
    const [teamchoiceSubChallenge, seTeamChoicetSubChallenge] = useState<ISubChallenge>();
    const [selectSubChallenge, setSelectSubChallenge] = useState<number>();
    const [team, setTeam] = useState<ITeam>();
    const [SubChallenges, setSubChallenges] = useState<ISubChallenge[]>();

    // WaitingSelection
    const [waitingSelection, setWaitingSelection] = useState<boolean>(false);
    // StepInProgress
    const [stepInProgress, setStepInProgress] = useState<IStepInprogress | null>(null);
    const [stepWaiting, setStepWaiting] = useState<IWaiting | null>(null);
    const [infoStep, setInfoStep] = useState<any | null>(null);
    const [questionsInProgress, setQuestionsInProgress] = useState<IQuestion[]>();
    const [deliverablesInProgress, setDeliverablesInProgress] = useState<IDeliverable[]>();

    //Collaspse StepsProject
    const [stepsProject, setStepsProject] = useState<any[]>([]);

    //StepProject complet and not sent
    const [stepProjectSave, setStepProjectSave] = useState<any>();

    // copie de delivrablesInProgress [id, file:null]
    const [deliverablesInProgressFile, setDeliverablesInProgressFile] = useState<IDeliverableFile[]>([]);
    //saveDeliverablesInProgressFile
    const [saveDeliverablesInProgressFile, setSaveDeliverablesInProgressFile] = useState<IDeliverableFile[]>([]);
    const [listDeliverablesIdentic, setListDeliverablesIdentic] = useState<number[]>([]);

    const [openModalDeleteProject, setOpenModalDeleteProject] = useState<boolean>(false);
    //Error
    //Error Front
    const [errorFront, setErrorFront] = useState<boolean>(false);
    const [messageErrorFront, setMessageErrorFront] = useState<string>("");
    const [severityErrorFront, setSeverityErrorFront] = useState<"error" | "success" | "info" | "warning">("error");
    const [errorTitleProject, setErrorTitleProject] = useState<boolean>(false);
    const [errorShortDesc, setErrorShortDesc] = useState<boolean>(false);
    const [errorLongDesc, setErrorLongDesc] = useState<boolean>(false);

    const [errorQuestions, setErrorQuestions] = useState<IErrorQuestion[]>([]);
    const [errorDeliverables, setErrorDeliverables] = useState<boolean>(false);
    const [errorDefaultDataProject, setErrorDefaultDataProject] = useState<IErrorDefaultDataProject[]>([]);
    const [errorProjectDeletion, setErrorProjectDeletion] = useState("");

    //Modal
    //SubChallenge
    const [openChangeSubChallenge, setOpenChangeSubChallenge] = useState(false);

    /********************* Return Data complete *********************/
        // defaultDataProject
    const [defaultDataProject, setDefaultDataProject] = useState<IDefaultStep>({
            title_project: PersisteProjectInProgressDefaultStep?.title_project || '',
            short_desc: PersisteProjectInProgressDefaultStep?.short_desc || '',
            long_desc: PersisteProjectInProgressDefaultStep?.long_desc || '',
        });

    const [defaultDataProjectSave, setDefaultDataProjectSave] = useState<IDefaultStep>({
        title_project: '',
        short_desc: '',
        long_desc: '',
    });

    console.log("le project", project)
    // QuestionData
    const [answerQuestionStep, setAnswerQuestionStep] = useState<IAnswerQuestions>({answers: PersisteProjectInProgressQuestions || []});

    const [fileToUpload, setFileToUpload] = useState<File | undefined>();
    //Permet de comparer le fichier enregistré et le fichier en cours d'upload
    const [saveFileToUpload, setSaveFileToUpload] = useState<File | undefined>(undefined);
    //Si les fichier sont identique ne pas upload
    const [fileToUploadCompare, setFileToUploadCompare] = useState<boolean>(false);
    const [img, setImg] = useState<string>("");

    const [imagePreview, setImagePreview] = useState<string>("");
    const [stepper, setStepper] = useState<IStepper>({total: 0, active: 0, running: 0, stopped: null});

    //Loading
    const [save, setSave] = useState<boolean>(false);

    //Modal
    const [openConfirmModalSend, setOpenConfirmModalSend] = useState<boolean>(false);

    //EditProject
    const [editProject, setEditProject] = useState<boolean>(false);

    //Step Done (>=1 step finie OU Projet envoyé)
    const [stepDone, setStepDone] = useState<boolean>(true);

    const downloadFile = (url: string, filename: string) => {
        axios.get(process.env.REACT_APP_API_URL + '/downloadfile', {
            params: {
                url: url,
                filename: filename
            },
            withCredentials: true,
            responseType: 'blob'
        })
            .then((res) => {
                // Créez un lien pour le téléchargement du fichier
                const blob = new Blob([res.data], {type: res.headers['content-type']});

                // Créez un objet File à partir du blob
                const file = new File([blob], stepProjectSave?.project?.file?.originalFileName, {type: res.headers['content-type']});
                setFileToUpload(file);
                setSaveFileToUpload(file);
                setImagePreview(URL.createObjectURL(file));
            })
            .catch((err) => {
                setFileToUpload(undefined);
                setSaveFileToUpload(undefined);
            })
    }

    useEffect(() => {
        if (stepProjectSave?.project?.file) {
            axios.get(process.env.REACT_APP_API_URL + '/downloadfile', {
                params: {
                    url: stepProjectSave?.project?.file?.fileUrl,
                    filename: stepProjectSave?.project?.file?.originalFileName
                },
                withCredentials: true,
                responseType: 'blob'
            })
                .then((res) => {
                    // Créez un lien pour le téléchargement du fichier
                    const blob = new Blob([res.data], {type: res.headers['content-type']});

                    // Créez un objet File à partir du blob
                    const file = new File([blob], stepProjectSave?.project?.file?.originalFileName, {type: res.headers['content-type']});
                    setFileToUpload(file);
                    setSaveFileToUpload(file);
                    setImagePreview(URL.createObjectURL(file));
                })
                .catch((err) => {
                    setFileToUpload(undefined);
                    setSaveFileToUpload(undefined);
                })
        } else {

            // Aucun fichier n'est disponible
            setFileToUpload(undefined);
            setSaveFileToUpload(undefined);
        }

        if (stepProjectSave?.deliverable_step_projects?.length > 0) {
            for (let index = 0; index < stepProjectSave?.deliverable_step_projects.length; index++) {
                axios.get(process.env.REACT_APP_API_URL + '/downloadfile', {
                    params: {
                        url: stepProjectSave?.deliverable_step_projects[index]?.file?.fileUrl,
                        filename: stepProjectSave?.deliverable_step_projects[index]?.file?.originalFileName
                    },
                    withCredentials: true,
                    responseType: 'blob'
                })
                    .then((res) => {
                        // Créez un lien pour le téléchargement du fichier
                        const blob = new Blob([res.data], {type: res.headers['content-type']});

                        // Créez un objet File à partir du blob
                        const file = new File([blob], stepProjectSave?.deliverable_step_projects[index]?.file?.originalFileName, {type: res.headers['content-type']});

                        setDeliverablesInProgressFile((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                                idDeliverable: stepProjectSave?.deliverable_step_projects[index]?.id,
                                file: file
                            };
                            return updatedData;
                        });
                    })
                    .catch((err) => {
                        setDeliverablesInProgressFile([]);
                        setSaveDeliverablesInProgressFile([]);
                    })
            }
        } else {
            setDeliverablesInProgressFile([]);
            setSaveDeliverablesInProgressFile([]);
        }

    }, [stepProjectSave]);

    useEffect(() => {
        if (fileToUpload !== undefined || fileToUpload !== null) {
            //supprimer l'erreur
            setErrorDefaultDataProject(errorDefaultDataProject.filter((error) => error.id !== "fileToUpload"));
        }
    }, [fileToUpload]);

    const redirectChallengePage = () => {
        navigate(`/challenge/${challengeId}/general`);
    }

    const redirectTeamManagementPage = () => {
        navigate(`/participant/challenge/${challengeId}/team/${team?.id}/management`);
    }

    const deleteProject = async () => {
        await axios.delete(process.env.REACT_APP_API_URL + `/participant/project/`, {
            params: {
                challengeId: challengeId,
                projectId: projectId,
            },
            withCredentials: true,
        })
            .then((res) => {
                navigate(`/challenge/${challengeId}/general`);
            })
            .catch((err) => {
                console.log("err", err);
                getProject();
                setErrorProjectDeletion(err.response.data.message);
            })
    }

    const getProject = async () => {
        setWaitingSelection(false);
        setErrorAccess(false);
        await axios.get(process.env.REACT_APP_API_URL + `/participant/project/edit`,
            {
                params: {
                    challengeId: challengeId,
                    projectId: projectId
                },
                withCredentials: true,
            })
            .then((res) => {
                const project = res.data.project;
                /************** Default Value **************/
                const challenge = project.challenge;
                const teamchoiceSubChallenge = project.subChallenge;
                const team = project.team;
                const subChallenges = challenge.subChallenges;
                const stepsProject = res.data.stepsProject
                const stepWaiting = res.data.stepWaiting;

                const stepProjectSave = res.data.stepProjectSave;
                const stepper = res.data.stepper;

                setStepper(stepper);

                console.log(" LE STEPPER ICI ", stepper)
                // const updatedDefaultDataProject = { ...defaultDataProject };

                // Mettez à jour les propriétés de la copie avec les valeurs de project.
                // updatedDefaultDataProject.title_project = stepProjectSave.project.name;
                // updatedDefaultDataProject.short_desc = stepProjectSave.project.shortDesc;
                // updatedDefaultDataProject.long_desc = stepProjectSave.project.longDesc;
                // setDefaultDataProject(updatedDefaultDataProject);
                if (stepProjectSave) {
                    setStepProjectSave(stepProjectSave);
                }
                if (stepsProject) {
                    setStepsProject(stepsProject);
                    const stepDone = stepsProject.some((step: any) => step.step.status.name == "done" || step.sent == true);
                    setStepDone(stepDone);
                    // const copyproject = {
                    //     title_project: stepsProject.project.name,
                    //     short_desc: stepsProject.project.shortDesc,
                    //     long_desc: stepsProject.project.longDesc,
                    // }
                    // setDefaultDataProjectSave(copyproject)
                    // setDefaultDataProject(copyproject)
                } else {
                    setStepsProject([]);
                }

                if (stepWaiting && stepWaiting.status.code !== "save") {
                    setStepInProgress(null)
                    setStepWaiting(stepWaiting)
                }

                let statusName = challenge.status.name;
                switch (statusName) {
                    case "in creation":
                        statusName = `${t("status.inCreation")}`;
                        break;
                    case "published":
                        statusName = `${t("status.published")}`;
                        break;
                    case "registrations":
                        statusName = `${t("status.registrations")}`;
                        break;
                    case "submission":
                        statusName = `${t("status.submission")}`;
                        break;
                    case "deliberations":
                        statusName = `${t("status.deliberations")}`;
                        break;
                    case "finished":
                        statusName = `${t("status.finished")}`;
                        break;
                    default:
                        break;
                }
                challenge.status.name = statusName;
                setProject(project);
                setChallenge(challenge);
                seTeamChoicetSubChallenge(teamchoiceSubChallenge);
                setTeam(team);
                setSingle(team.single)
                setSubChallenges(subChallenges);
                setSelectSubChallenge(teamchoiceSubChallenge ? teamchoiceSubChallenge.id : "");

                /************** InProgress **************/
                const InProgress = res.data.stepInProgress
                if (InProgress) {
                    setStepInProgress(InProgress);
                    setQuestionsInProgress(InProgress.questions);
                    setDeliverablesInProgress(InProgress.deliverables);
                    //copie de delivrablesInProgress [id, file:null] ==> deliverablesInProgressFile
                    setDeliverablesInProgressFile(InProgress.deliverables?.map((deliverable: any) => {
                        return {idDeliverable: "newDeliverable-" + uuidv4(), file: null}
                    }));
                }

                if (project.file) {
                    downloadFile(project.file.fileUrl, project.file.originalFileName);
                }
            })
            .catch((err) => {
                console.log("err", err);
                setErrorAccess(true);
            })
    }

    useEffect(() => {
        getProject()
    }, []);

    useEffect(() => {
    }, [stepInProgress, stepWaiting]);

    useEffect(() => {
    }, [stepsProject]);

    const OpenModalChangeSubChallenge = () => {
        setOpenChangeSubChallenge(true);
    }

    const MyFormControlLabel = (props: FormControlLabelProps) => {
        const {value} = props;
        let checked = false;
        if (value !== undefined && selectSubChallenge !== undefined && Number(value) === Number(selectSubChallenge)) {
            checked = true;
        }
        if (checked)
            return (
                <FormControlLabel
                    sx={{
                        background: 'var(--Saumon10, #FFF1F1)',
                        borderRadius: '10px',
                        border: '1px solid #F86767',
                        margin: "0",
                        marginBottom: "0.5em",
                        padding: "0.5em",
                        whiteSpace: 'normal'
                    }}
                    checked={checked}
                    {...props}
                />
            );
        return <FormControlLabel
            sx={{
                borderRadius: '10px',
                border: '1px solid #828D9B',
                margin: "0",
                marginBottom: "0.5em",
                padding: "0.5em",
                whiteSpace: 'normal'
            }}

            checked={checked} {...props}
        />;
    }
    const onChangeSubChallenge = async (e: any) => {
        setSelectSubChallenge(Number(e.target.value));
    }

    const updateSelectSubChallenge = async () => {
        setOpenChangeSubChallenge(false);
        await axios.put(process.env.REACT_APP_API_URL + `/participant/updatesubchallenge/`,
            {
                challengeId: challengeId,
                projectId: projectId,
                subChallengeId: selectSubChallenge
            },
            {
                withCredentials: true,
            })
            .then((res) => {
                getProject();
            })
            .catch((err) => {
                console.log("err", err);
            })
    }

    /******** Return data ********/
        //Default Data Project
    const onChangeDefaultDataProject = (data: any, id: any) => {
            //const {id, value} = e.target;
            // Supprimer l'erreur associée au champ "title_project"
            setErrorDefaultDataProject(errorDefaultDataProject.filter((error) => error.id !== id));

            // Mettre à jour la valeur du champ
            setDefaultDataProject((prevData) => ({
                ...prevData,
                [id]: data
            }));

            setDefaultDataProject({...defaultDataProject, [id]: data});
        }

    useEffect(() => {
        if (stepInProgress && stepInProgress.id) {
            dispatch({
                type: "SET_DEFAULT_DATA_PROJECT_IN_PROGRESS",
                payload: {
                    projectId: projectId,
                    stepId: stepInProgress?.id || 0,
                    defaultStep: defaultDataProject,
                    questions: answerQuestionStep.answers,
                },
            })
        }
    }, [defaultDataProject, answerQuestionStep]);

    //Answer Question Step
    const onChangeAnswerQuestionStep = (data: any, id: any) => {
        id = Number(id);
        // Supprimer l'erreur associée à la question
        setErrorQuestions(errorQuestions.filter((error) => error.idQuestion !== id));
        // Créez une copie du tableau existant
        const updatedAnswers = [...answerQuestionStep.answers];

        // Recherchez si une réponse pour cette question existe déjà
        if (updatedAnswers) {
            const index = updatedAnswers.findIndex((answer) => answer.idQuestion === id);

            if (index !== -1) {
                // Si l'id correspond, créez une copie de l'objet avec la valeur mise à jour
                updatedAnswers[index] = {...updatedAnswers[index], answer: data};
            } else {
                // Si aucune réponse n'existe, ajoutez une nouvelle réponse
                updatedAnswers.push({idQuestion: id, answer: data});
            }

            // Mettez à jour l'état avec le tableau mis à jour
            setAnswerQuestionStep({answers: updatedAnswers});
        }
    }
    const ValidateProjectStep = async () => {
        setOpenConfirmModalSend(false)
        CompleteFormStepProject();
    }

    const SaveProjectStep = async () => {
        setSave(true);
        const formData = new FormData();
        formData.append('projectId', String(projectId));
        formData.append('defaultStep', stepper.active + stepper.running > 1 ? JSON.stringify(defaultDataProjectSave) : JSON.stringify(defaultDataProject));
        formData.append('stepId', String(stepInProgress?.id));
        if (!fileToUploadCompare) {
            formData.append('fileToUpload', fileToUpload as File);
        }
        formData.append('fileToUploadCompare', JSON.stringify(fileToUploadCompare));

        // deliverablesInProgressFile.forEach((deliverable, index) => {
        //     if (deliverable.file) {
        //         formData.append(`deliverablesInProgressFile[${index}]`, deliverable.file);
        //     }
        // });

        deliverablesInProgressFile.forEach((deliverable, index) => {
            if (deliverable.file && !listDeliverablesIdentic.includes(Number(deliverable.idDeliverable))) {

                formData.append(`deliverablesInProgressFile[${index}]`, deliverable.file);
            }
        });

        formData.append('answerQuestionStep', JSON.stringify(answerQuestionStep.answers));

        await axios.post(process.env.REACT_APP_API_URL + `/participant/savestepproject`,
            formData,
            {
                withCredentials: true,
            })
            .then((res) => {
                getProject();
                setSave(false);
                setSeverityErrorFront("success");
                setErrorFront(true);
                setMessageErrorFront(`${t("page.projectRunning.messageErrorFront2")}`);
            })
            .catch((err) => {
                console.log("err", err);
                setSave(false);
                setSeverityErrorFront("error");
                setMessageErrorFront(err.response.data.message);
                setErrorFront(true);
            })
    }

    const limitText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const onChangeDeliverableFile = (file: File, idDeliverable: string) => {

        // 1. Créez une copie du tableau deliverablesInProgressFile.
        const updatedDeliverablesInProgressFile = [...deliverablesInProgressFile];

        // 2. Recherchez l'index de l'idDeliverable dans le tableau.
        const index = updatedDeliverablesInProgressFile.findIndex(
            (deliverable) => String(deliverable.idDeliverable) === String(idDeliverable)
        );

        // 3. Si l'idDeliverable n'existe pas, ajoutez une nouvelle entrée au tableau.
        if (index === -1) {
            updatedDeliverablesInProgressFile.push({
                idDeliverable: idDeliverable,
                file: file,
            });
        } else {
            // 4. Si l'idDeliverable existe, remplacez le fichier existant.
            updatedDeliverablesInProgressFile[index].file = file;
            updatedDeliverablesInProgressFile[index].idDeliverable = "newDeliverable-" + uuidv4();
        }

        // Mettez à jour le state avec le tableau mis à jour.
        setDeliverablesInProgressFile(updatedDeliverablesInProgressFile);
    }

    const deletedDeliverable = (id: string) => {
        const updatedDeliverablesInProgressFile = [...deliverablesInProgressFile];
        const index = updatedDeliverablesInProgressFile.findIndex(
            (deliverable) => deliverable?.idDeliverable === id
        );
        if (stepInProgress && stepInProgress.deliverables && stepInProgress.deliverables.length > 0 && updatedDeliverablesInProgressFile.length > 1) {
            setDeliverablesInProgressFile(updatedDeliverablesInProgressFile);
        } else if (stepInProgress && stepInProgress.deliverables && stepInProgress.deliverables.length === 0) {
            updatedDeliverablesInProgressFile.splice(index, 1);
            setDeliverablesInProgressFile(updatedDeliverablesInProgressFile);
        } else {
            // Gérer le cas où il ne doit pas rester moins d'un élément.
            setErrorDeliverables(true);
        }
    }

    const addDeliverableFile = () => {
        // Vérifiez si tous les éléments dans deliverablesInProgressFile ont des fichiers non nuls
        const allHaveFiles = deliverablesInProgressFile.every((deliverable) => deliverable.file !== null);
        // Si tous les éléments ont des fichiers, ajoutez un nouvel élément avec un fichier nul
        if (allHaveFiles) {
            setDeliverablesInProgressFile([...deliverablesInProgressFile, {
                idDeliverable: "newDeliverable-" + uuidv4(),
                file: null
            }]);
        }
    };

    const inputRefs: { [key: string]: React.RefObject<HTMLInputElement> } = {};

    const handleDeleteProfilePicture = () => {
        setFileToUpload(undefined);
        setSaveFileToUpload(undefined);
    }

    const CompleteFormStepProject = async () => {
        const ErrorQuestions: IErrorQuestion[] = [];
        const ErrorDefaultDataProject: IErrorDefaultDataProject[] = [];
        let errorDeliverables = false;

        // Déclaration de la variable deliverablesInProgressFileNotNull
        const deliverablesInProgressFileNotNull = deliverablesInProgressFile && deliverablesInProgressFile.filter((deliverable) => deliverable.file !== null);

        const questionAnswerInProgressNotNull = answerQuestionStep && answerQuestionStep.answers.filter((question) => question.answer !== "");

        //Vérifier si toutes les questions ont une réponse
        if (stepInProgress && stepInProgress.questions && (stepInProgress.questions.length !== questionAnswerInProgressNotNull.length)) {
            // Trouver les questions sans réponse
            const questionsWithoutAnswer = stepInProgress.questions.filter((question) => {
                return !questionAnswerInProgressNotNull.some((answer) => answer.idQuestion === question.id);
            });

            if (questionsWithoutAnswer.length > 0) {
                // Il y a des questions sans réponse, ajoutez-les à ErrorQuestions.
                questionsWithoutAnswer.forEach((question) => {
                    ErrorQuestions.push({
                        idQuestion: question.id,
                        message: `${t("page.projectRunning.messageAnswerQuestion")}`
                    });
                });
            }
        }

        // Vérifier si au moin un livrable est fournit si ont demande au moin un livrable
        if (stepInProgress && stepInProgress.deliverables && stepInProgress.deliverables.length > 0 && deliverablesInProgressFileNotNull && deliverablesInProgressFileNotNull.length === 0) {
            errorDeliverables = true;
        }

        if (!defaultDataProject.title_project || defaultDataProject.title_project === "") {
            ErrorDefaultDataProject.push({
                id: "title_project",
                message: `${t("page.projectRunning.messageTitle")}`
            });
        }

        // Vérification si short_desc est vide
        if (!defaultDataProject.short_desc || defaultDataProject.short_desc === "") {
            ErrorDefaultDataProject.push({
                id: "short_desc",
                message: `${t("page.projectRunning.messageShortDesc")}`
            });
        }

        // Vérification si long_desc est vide
        if (!defaultDataProject.long_desc || defaultDataProject.long_desc === "") {
            ErrorDefaultDataProject.push({
                id: "long_desc",
                message: `${t("page.projectRunning.messageLongDesc")}`
            });
        }

        if (!fileToUpload || fileToUpload === undefined) {
            ErrorDefaultDataProject.push({
                id: "fileToUpload",
                message: `${t("page.projectRunning.messageFileToUpload")}`
            });
        }

        if ((ErrorQuestions && ErrorQuestions.length > 0) || (ErrorDefaultDataProject && ErrorDefaultDataProject.length > 0) || errorDeliverables) {
            setErrorDefaultDataProject(ErrorDefaultDataProject);
            setErrorQuestions(ErrorQuestions);
            setErrorDeliverables(errorDeliverables);
            setErrorFront(true);
            setSeverityErrorFront("error");
            setMessageErrorFront(`${t("page.projectRunning.messageErrorFront")}`);
            setOpenConfirmModalSend(false);
        } else {
            const formData = new FormData();
            formData.append('projectId', String(projectId));
            formData.append('defaultStep', stepper.active + stepper.running > 1 ? JSON.stringify(defaultDataProjectSave) : JSON.stringify(defaultDataProject));
            formData.append('stepId', String(stepInProgress?.id));
            if (!fileToUploadCompare) {
                formData.append('fileToUpload', fileToUpload as File);
            }
            formData.append('fileToUploadCompare', JSON.stringify(fileToUploadCompare));
            // deliverablesInProgressFileNotNull.forEach((deliverable, index) => {
            //     if (deliverable.file) {
            //         formData.append(`deliverablesInProgressFile[${index}]`, deliverable.file);
            //     }
            // });
            deliverablesInProgressFile.forEach((deliverable, index) => {
                if (deliverable.file && !listDeliverablesIdentic.includes(Number(deliverable.idDeliverable))) {

                    formData.append(`deliverablesInProgressFile[${index}]`, deliverable.file);
                }
            });
            formData.append('answerQuestionStep', JSON.stringify(questionAnswerInProgressNotNull));
            const getFormDataSize = (formData: FormData) => [...formData].reduce((size, [name, value]) => size + (typeof value === 'string' ? value.length : value.size), 0);

            if (getFormDataSize(formData) === 0) {
                setErrorFront(true);
                setSeverityErrorFront("error");
                setMessageErrorFront("Vous devez répondre à tous les champs.");
                setOpenConfirmModalSend(false);
            } else {
                setErrorFront(false);
                setMessageErrorFront("");
                renderStepInProgress(formData)
            }
        }
    }

    const renderStepInProgress = async (formData: FormData) => {
        setSave(true);
        await axios.post(process.env.REACT_APP_API_URL + `/participant/stepproject`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true,
        })
            .then((res) => {
                // dispatch({
                //     type: 'CLEAR_PROJECT'
                // });

                getProject();
                setSave(false);
                setOpenConfirmModalSend(false);
                setEditProject(false);
                setSeverityErrorFront("success");
                setMessageErrorFront("L'étape a bien été envoyée.");
                setErrorFront(true);
            })
            .catch((err) => {
                console.log("err", err);
                setSave(false);
                setSeverityErrorFront("error");
                setMessageErrorFront(err.response.data.message);
                setErrorFront(true);
            })

    }

    const redirectProjectPage = () => {
        navigate(`/participant/challenge/${challengeId}/project/${projectId}`);
    }

    useEffect(() => {
        if (fileToUpload && saveFileToUpload) {

            compare(fileToUpload, saveFileToUpload)
                .then((res) => {
                    setFileToUploadCompare(res);
                });
        } else {
            setFileToUploadCompare(false);
        }
    }, [fileToUpload, saveFileToUpload]);

    useEffect(() => {
        if (deliverablesInProgressFile && saveDeliverablesInProgressFile) {
            deliverablesInProgressFile.forEach((deliverable, index) => {
                // Trouver la correspondance d'idDeliverable entre deliverablesInProgressFile et saveDeliverablesInProgressFile.
                const matchingSavedDeliverable = saveDeliverablesInProgressFile.find(
                    (savedDeliverable) => savedDeliverable?.idDeliverable === deliverable?.idDeliverable
                );

                if (matchingSavedDeliverable) {
                    // Correspondance trouvée, vous pouvez maintenant comparer les fichiers.
                    if (deliverable?.file && matchingSavedDeliverable.file) {
                        compare(deliverable?.file, matchingSavedDeliverable.file)
                            .then((isModified) => {
                                if (isModified) {
                                    setListDeliverablesIdentic((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = Number(deliverable?.idDeliverable);
                                        return updatedData;
                                    });
                                }
                            });
                    }
                }
            });
        }
    }, [deliverablesInProgressFile, saveDeliverablesInProgressFile]);

    useEffect(() => {
    }, [fileToUpload, saveFileToUpload]);
    useEffect(() => {
    }, [fileToUploadCompare]);

    const compare = (file1: File, file2: File) => {
        // load both as ArrayBuffers
        return Promise.all([
            readAsArrayBuffer(file1),
            readAsArrayBuffer(file2)
        ]).then(([buf1, buf2]) => {
            // create views over our ArrayBuffers
            const arr1 = new Uint8Array(buf1);
            const arr2 = new Uint8Array(buf2);
            return !arr1.some((val, i) =>
                arr2[i] !== val // search for diffs
            );
        });
    }

    const readAsArrayBuffer = (file: File) => {
        return new Response(file).arrayBuffer();
    }
    const errorMessageFillField = t("page.projectRunning.messageErrorFillThisField");
    const QuestionDefaultProject = () => {
        return (
            <>
                <h3>{t("page.projectRunning.questions.presentation")}</h3>
                <p className="mb-3">{t("page.projectRunning.questions.text1")}</p>
                <p className="mb-2">{t("page.projectRunning.questions.fieldsRequired")}</p>
                <h4>{t("page.projectRunning.questions.titleProject")}</h4>
                {
                    /*
            <div
                className={`ckeditor-container-normal ${errorDefaultDataProject.some((error) => error.id === "title_project") ? "error-ckeditor" : ""}`}>

                <CKEditor
                    editor={ClassicEditor}
                    data={defaultDataProject.title_project || project?.name || ''}
                    disabled={challenge?.status.name === "finished"}
                    onReady={editor => {
                        console.log('Editor is ready to use!', editor);
                        //console.log(Array.from( editor.ui.componentFactory.names()));
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        onChangeDefaultDataProject(data, "title_project");
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                    config={{
                        placeholder: `${t("page.projectRunning.questions.yourAnswer")}`,
                        toolbar: {
                            items: [
                                'undo', 'redo',
                                '|', 'heading',
                                '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                '|', 'link', 'blockQuote', 'codeBlock',
                                '|', 'alignment',
                                'fontColor',
                                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                            ],
                            shouldNotGroupWhenFull: true
                        }
                    }}
                />
            </div>
                     */
                }
                <TextField
                    size="small"
                    fullWidth
                    inputProps={{maxLength: 45}}
                    sx={{
                        borderRadius: '10px',
                        '& label.Mui-focused': {
                            color: '#828D9B',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#828D9B',
                                borderWidth: '1px',
                            }
                        }

                    }}
                    id="title_project"
                    placeholder={`${t("page.projectRunning.questions.yourAnswer")}`}
                    value={defaultDataProject.title_project || project?.name || ''}
                    onChange={(e) => onChangeDefaultDataProject(e.target.value, "title_project")}
                    // helperText="Champ obligatoire *"
                    error={errorDefaultDataProject.some((error) => error.id === "title_project")}
                />
                <p className={"error-message mt-2"}>
                    {
                        errorDefaultDataProject.some((error) => error.id === "title_project") && errorMessageFillField
                    }
                </p>
                <h4 className="mt-3">{t("page.projectRunning.questions.shortDescription")}</h4>
                {
                    /*

                <div
                    className={`ckeditor-container-short-desc ${errorDefaultDataProject.some((error) => error.id === "short_desc") ? "error-ckeditor" : ""}`}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={defaultDataProject.short_desc || project?.shortDesc || ''}
                        disabled={challenge?.status.name === "finished"}
                        onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                            //console.log(Array.from( editor.ui.componentFactory.names()));
                        }}
                        onChange={(event: any, editor) => {
                            const data = editor.getData();
                            onChangeDefaultDataProject(data, "short_desc");
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                        config={{
                            placeholder: `${t("page.projectRunning.questions.yourAnswer")}`,
                            toolbar: {
                                items: [
                                    'undo', 'redo',
                                    '|', 'heading',
                                    '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                    '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                    '|', 'link', 'blockQuote', 'codeBlock',
                                    '|', 'alignment',
                                    'fontColor',
                                    '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                ],
                                shouldNotGroupWhenFull: true
                            }
                        }}
                    />
                </div>
                     */
                }
                <TextField
                    maxRows={4}
                    minRows={2}
                    multiline
                    inputProps={{maxLength: 200}}
                    size="small"
                    fullWidth
                    sx={{
                        borderRadius: '10px',
                        '& label.Mui-focused': {
                            color: '#828D9B',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#828D9B',
                                borderWidth: '1px',
                            }
                        }

                    }}
                    id="short_desc"
                    placeholder={`${t("page.projectRunning.questions.yourAnswer")}`}
                    value={defaultDataProject.short_desc || project?.shortDesc || ''}
                    onChange={(e) => onChangeDefaultDataProject(e.target.value, "short_desc")}
                    // helperText="Champ obligatoire *"
                    error={errorDefaultDataProject.some((error) => error.id === "short_desc")}
                />
                <p className={"error-message mt-2"}>
                    {
                        errorDefaultDataProject.some((error) => error.id === "short_desc") && errorMessageFillField
                    }
                </p>
                <h4 className="mt-3">{t("page.projectRunning.questions.inDepthDescription")}</h4>
                <div
                    className={`ckeditor-container-long-desc ${errorDefaultDataProject.some((error) => error.id === "long_desc") ? "error-ckeditor" : ""}`}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={defaultDataProject.long_desc || project?.longDesc || ''}
                        disabled={challenge?.status.name === "finished"}
                        onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                            //console.log(Array.from( editor.ui.componentFactory.names()));
                        }}
                        onChange={(event: any, editor) => {
                            let data = editor.getData();
                            if (data.length > 5000) {
                                data = data.substring(0, 5000);
                                editor.setData(data);
                            }
                            onChangeDefaultDataProject(data, "long_desc");
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                        config={{
                            placeholder: `${t("page.projectRunning.questions.yourAnswer")}`,
                            toolbar: {
                                items: [
                                    'undo', 'redo',
                                    '|', 'heading',
                                    '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                    '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                    '|', 'link', 'blockQuote', 'codeBlock',
                                    '|', 'alignment',
                                    'fontColor',
                                    '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                ],
                                shouldNotGroupWhenFull: true
                            }
                        }}
                    />
                </div>
                {
                    /*
                <TextField
                    maxRows={5}
                    minRows={4}
                    multiline
                    inputProps={{maxLength: 5000}}
                    size="small"
                    fullWidth
                    sx={{
                        borderRadius: '10px',
                        '& label.Mui-focused': {
                            color: '#828D9B',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#828D9B',
                                borderWidth: '1px',
                            }
                        }

                    }}
                    id="long_desc"
                    placeholder={`${t("page.projectRunning.questions.yourAnswer")}`}
                    value={defaultDataProject.long_desc || project?.longDesc || ''}
                    onChange={(e) => onChangeDefaultDataProject(e)}
                    // helperText="Champ obligatoire *"
                    error={errorDefaultDataProject.some((error) => error.id === "long_desc")}
                />
                     */
                }
                <p className={"error-message mt-2"}>
                    {
                        errorDefaultDataProject.some((error) => error.id === "long_desc") && errorMessageFillField
                    }
                </p>
                <h4 id="title_main_picture" className="mt-3">
                    {t("page.projectRunning.questions.mainImage")}
                </h4>
                <div>
                    <ImageUploader
                        image={img}
                        defaultImage={"image-project"}
                        imagePreview={imagePreview || DEFAULT_MAIN_PROJECT}
                        setImagePreview={setImagePreview}
                        setFileToUpload={setFileToUpload}
                        handleDelete={handleDeleteProfilePicture}
                        ratio="5/2"
                        // rectangle={true}
                        noImageText={`${t("page.projectRunning.questions.noImage")}`}
                        noImageButtonText={`${t("page.projectRunning.questions.addImage")}`}
                        // hasClickedOnCancelButton={hasClickedOnCancelButton}
                        // setChange={setChange}
                        // truc={truc2}
                        // setTruc={setTruc2}
                        // imgRef={createRef()}
                        // id={"img-edit-challenge-general"}
                    />
                </div>
            </>
        )
    }

    const infoProject = () => {
        return (
            <>
                <h3 className="presentation">{t("page.projectRunning.questions.presentation")}</h3>
                {/* Si il n'y a pas de titre ni de short_desc ni de long_desc ni d'image principale, le projet ne sera pas visible sur la page du challenge. */}
                {(defaultDataProject.long_desc && defaultDataProject.short_desc && defaultDataProject.title_project && imagePreview !== "")
                || (project?.longDesc && project?.shortDesc && project?.name) ? (
                    <div className="bloc-info-project-edit">
                        <h3 className="mb-2">
                            <div className="text"
                                 dangerouslySetInnerHTML={{
                                     __html: `${defaultDataProject.title_project !== "" ? defaultDataProject.title_project : project?.name}`
                                 }}/>
                            {/*{stepDone && (stepper && !stepper.stopped) && (!stepWaiting || (stepWaiting && stepWaiting.status.code === "save")) &&*/}
                                <span onClick={() => setEditProject(!editProject)}
                                      className="float-end icon-edit"><IconEdit/></span>
                             {/*}*/}
                        </h3>
                        <p className="my-1">
                            <div className="text"
                                 dangerouslySetInnerHTML={{
                                     __html: `${defaultDataProject.short_desc !== "" ? defaultDataProject.short_desc : project?.shortDesc}`
                                 }}/>
                        </p>
                        {(imagePreview !== "") && <div>
                            <img className="w-100 image-preview my-2" src={imagePreview} alt="previewImageProject"/>
                        </div>}
                        <div className="text"
                             dangerouslySetInnerHTML={{
                                 __html: `${defaultDataProject.long_desc !== "" ? defaultDataProject.long_desc : project?.longDesc}`
                             }}/>
                    </div>
                ) : (
                    <div className="presentation mt-1">
                        <p>{t("page.projectRunning.projectNotComplete")}</p>
                    </div>
                )}
            </>
        )
    }

    const infoEditProject = () => {
        return (
            <div className="bloc-info-project-edit-open">
                {QuestionDefaultProject()}
                <Grid container marginTop={4} spacing={2}>
                    <Grid item xs={6}>
                        <ActionButton
                            variant="tertiary"
                            onClick={() => cancelProjectEdit()}
                        >{t("form.cancel")}</ActionButton>
                    </Grid>
                    <Grid item xs={6}>
                        <ActionButton
                            variant="primary"
                            onClick={() => {
                                setEditProject(!editProject);
                                setDefaultDataProjectSave(defaultDataProject)
                            }}
                        >{t("form.save")}</ActionButton>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const cancelProjectEdit = () => {
        setDefaultDataProject(defaultDataProjectSave)
        setEditProject(!editProject)
    }

    console.log("le project", project);
    console.log("defaultDataProject", defaultDataProject);
    return (
        <section id="detail-project">
            {!errorAccess && project && challenge && team ?
                (
                    <>
                        {challenge && challenge.establishments && challenge.establishments.length > 0 && (

                            <div id="bloc-info-project-challenge" className="bloc-info-project-challenge">
                                <Grid container spacing={1} justifyContent={"space-between"} direction={"row"}>
                                    <Grid item xs={12} md={9}>
                                        <h4>{challenge.establishments[0].name} {isDownMd ? "" : `${moment(String(challenge.startDate)).format("DD/MM/YYYY")} ${t("page.projectRunning.to")} ${moment(String(challenge.endDate)).format("DD/MM/YYYY")}`}</h4>
                                        <h1 className="text-uppercase">{challenge.name}</h1>
                                        <p className="text">
                                            <span>{!isDownMd ? "" : `${moment(String(challenge.startDate)).format("DD MMMM YYYY")} ${t("page.projectRunning.to")} ${moment(String(challenge.endDate)).format("DD MMMM YYYY")} - `}</span>{challenge.shortDescription}
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <div className={isDownMd ? "text-start" : "text-end"}>
                                            <Chip label={challenge.status.name} sx={{
                                                backgroundColor: "#fff",
                                                fontWeight: "700",
                                                fontFamily: "Ubuntu"
                                            }}/>
                                        </div>
                                        {/* <p className={isDownMd?"text-start":"text-end"}>CHIP</p> */}
                                    </Grid>
                                    <Grid item xs={6} md={12}>
                                        <div className={isDownMd ? "text-end" : "text-start"}>
                                            <ActionButton
                                                variant="little-underline"
                                                onClick={() => redirectChallengePage()}
                                                iconEnd={true}
                                                icon={<IconArrowRight/>}
                                            >
                                                {t("page.projectRunning.seeChallengePage")}
                                            </ActionButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch">
                            {team && (
                                <Grid item xs={12} md={5.5} marginBottom={2}>
                                    {single ? (
                                        <div id="bloc-project-profile">
                                            <h2 className="text-center">{t("page.projectRunning.myProject")}</h2>
                                            <div className="w-50 m-auto mb-3">
                                                <ActionButton
                                                    variant='primary'
                                                    onClick={() => {
                                                        redirectProjectPage()
                                                    }}
                                                >
                                                    {t("page.projectRunning.seeProjectPage")}
                                                </ActionButton>
                                            </div>
                                            <div className="bloc-info-profile-participation">
                                                <div className="text-center">
                                                    <p>{t("page.projectRunning.participationAlone")}</p>
                                                    <div className="mt-2 mb-2">
                                                        <Chip
                                                            avatar={<Avatar sx={{
                                                                height: "40px !important",
                                                                width: "40px !important",
                                                                marginLeft: "0 !important"
                                                            }}
                                                                            alt={team.profiles[0].user.firstName + " " + team.profiles[0].user.lastName}
                                                                            src={team.profiles[0].user.profile_picture}/>}
                                                            label={team.profiles[0].user.firstName + " " + team.profiles[0].user.lastName}
                                                            variant="filled"
                                                            sx={{
                                                                backgroundColor: "#EDF1F7",
                                                                height: "40px",
                                                                borderRadius: "20px"
                                                            }}
                                                        />
                                                    </div>

                                                    {!stepDone && !project.challenge.steps.some((step) => step.status && step.status.name === 'done') && ( //Si aucune étape est finie, on cache
                                                        <ActionButton
                                                            variant="little-underline"
                                                            onClick={!stepDone && !project.challenge.steps.some((step) => step.status && step.status.name === 'done') ? () => setOpenModalDeleteProject(true) : () => {
                                                            }}
                                                        >
                                                            {t("page.projectRunning.deleteMyProject")}
                                                        </ActionButton>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div id="bloc-project-team">
                                            <h2 className="text-center">{t("page.projectRunning.team")} {team.numero}</h2>
                                            <h4 className="text-center team-name">{team.name}</h4>
                                            <div className="bloc-info-profile-participation">
                                                <div>
                                                    <h4 className="team-name">{t("page.projectRunning.myTeam")}</h4>
                                                    <div className="mt-2 mb-2">
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item xs={6}>
                                                                <AvatarGroup max={3} sx={{justifyContent: "left"}}>
                                                                    {team.profiles.map((profile, index) => (
                                                                        <Avatar key={index}
                                                                                alt={profile.user.firstName + " " + profile.user.lastName}
                                                                                src={profile.user.profile_picture}/>
                                                                    ))}
                                                                </AvatarGroup>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="text-end teamButtons">
                                                                    <ActionButton
                                                                        variant="little-underline"
                                                                        onClick={() => redirectTeamManagementPage()}
                                                                    >
                                                                        {t("page.projectRunning.teamSpace")}
                                                                    </ActionButton>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {teamchoiceSubChallenge && (
                                        <div id="bloc-choice-subchallenge">
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <h3 className="ps-3 ms-3">{t("page.projectRunning.selectedSubChallenge")}</h3>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="text-end">
                                                        <IconEdit className="icon-pen cursor-pointer"
                                                                  onClick={() => OpenModalChangeSubChallenge()}/>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="choice-subchallenge">
                                                <h4 className="bold-600">{teamchoiceSubChallenge.name}</h4>
                                                <p className={"text"}>{teamchoiceSubChallenge.description}</p>
                                            </div>
                                        </div>
                                    )}
                                    {challenge.steps && (
                                        <div id="bloc-project-breakthrough">
                                            <h3 className="ps-3 ms-3">{t("page.projectRunning.projectProgess")}</h3>
                                            {stepWaiting && (stepWaiting.status.code === "todo" || stepWaiting.status.code === "firstStep") && (
                                                <p className="ps-3 ms-3 fst-italic">{t("page.projectRunning.waitingLaunch")}{(stepper.active + stepper.running) === 0 ? ` ${t("page.projectRunning.first")} ` : ` ${t("page.projectRunning.next")} `}
                                                    {t("page.projectRunning.step")}{t("page.projectRunning.stepByOrganize")}</p>
                                            )}
                                            <>
                                                <p className="ps-3 ms-3">{t("page.projectRunning.stepUpper")} {stepWaiting ? (stepper.active) : (stepper.active + stepper.running)} {t("page.projectRunning.of")} {stepper.total} {stepper.stopped && ` - ${t("page.projectRunning.eliminatedAt")} ${stepper.stopped}`}</p>
                                                <div className="mt-3">
                                                    <StepperGradient
                                                        totalSteps={stepper.total}
                                                        activeStep={stepper.active}
                                                        runningStep={stepper.running}
                                                        blockedAt={stepper.stopped}
                                                    />
                                                </div>
                                            </>
                                        </div>
                                    )}
                                    {(stepper.running !== 0 ? (stepper.active + stepper.running) : stepper.active) > 0 && (
                                        editProject ? infoEditProject() : infoProject()
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={12} md={6.5}>
                                <div id="bloc-project-subchallenge"
                                     className={stepper && stepper.stopped ? "warning" : "default"}>
                                    {(stepper && !stepper.stopped) && stepWaiting && (
                                        <>
                                            {stepWaiting.status.code === "todo" && (
                                                <>
                                                    {/* <h2 className="text-center">Étape {stepper.running !== 0 ? stepper.active + stepper.running : stepper.active}</h2> */}
                                                    <h2 className="text-center">{stepWaiting.name}</h2>
                                                    <div className="text-center">
                                                        <h4 className="p-03">{t("page.projectRunning.stepNotStarted")}</h4>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {stepWaiting && stepWaiting.status && (stepper && !stepper.stopped) && (stepWaiting.status.code === "sentAndWaintingStepInProgress" || stepWaiting.status.code === "sentAndWaintingSelection") && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconCheckFlash/>
                                            </div>
                                            <h2 className="text-center">{t("page.projectRunning.congrats")}</h2>
                                            <h2 className="text-center">{t("page.projectRunning.projectSent")}</h2>
                                            <div className="text-center mt-2">
                                                <h4 className="p-03 border-0">{stepWaiting.status.message}</h4>
                                            </div>
                                        </div>
                                    )}

                                    {stepper && stepper.stopped && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconWarn/>
                                            </div>
                                            <h2 className="text-center">{t("page.projectRunning.projectNotSelected")}</h2>
                                        </div>
                                    )}

                                    {stepWaiting && stepWaiting.status && (stepper && !stepper.stopped) && stepWaiting.status.code === "firstStep" && (
                                        <div>
                                            <h2 className="text-center">{t("page.projectRunning.stepUpper")} 1</h2>
                                            <h4 className="bold-600 border-0 bg-transparent text-center w-100">{stepWaiting.name}</h4>
                                            <div className="text-center mt-1">
                                                <h4 className="p-03">{stepWaiting.status.message}</h4>
                                            </div>
                                        </div>
                                    )}
                                    {(!stepWaiting || (stepWaiting && stepWaiting.status.code === "save")) && stepInProgress && (stepper && !stepper.stopped) && (
                                        <div>
                                            <h2 className="text-center">{t("page.projectRunning.stepUpper")} {stepper.running !== 0 ? stepper.active + stepper.running : stepper.active}</h2>
                                            <h4 className="bold-600 border-0 bg-transparent text-center w-100">{stepInProgress.name}</h4>
                                            <div className="text-center mt-1">
                                                <h4 className="p-03">{t("page.projectRunning.dueBy")} {moment(String(stepInProgress?.dateEndDeposite)).format("DD/MM/YYYY, HH[h]mm")}</h4>
                                            </div>
                                        </div>
                                    )}
                                    {(stepper && !stepper.stopped) && stepWaiting && stepWaiting.status && stepWaiting.status.code === "challengeDone" && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconLike/>
                                            </div>
                                            <h2 className="text-center">{stepWaiting.status.message}</h2>
                                        </div>
                                    )}
                                </div>
                                {(stepper && !stepper.stopped) && (!waitingSelection) && stepInProgress && stepInProgress.status.name === "inProgress" && (
                                    <div className="bloc-step-running-tobe-complete">
                                        <section>
                                            {challenge.resources && challenge.resources.length > 0 &&
                                                <p className="info-resource mb-3">{t("page.projectRunning.resourcesText1")}
                                                    <span
                                                        onClick={() => navigate('/challenge/' + challengeId + '/ressources')}>{t("page.projectRunning.resourcesText2")}</span> {t("page.projectRunning.resourcesText3")}
                                                </p>}
                                            {stepper.active + stepper.running <= 1 && QuestionDefaultProject()}
                                        </section>
                                        {stepInProgress && (
                                            <>
                                                {questionsInProgress && questionsInProgress.length !== 0 && (
                                                    <section className="mt-3">
                                                        <h3>{t("page.projectRunning.stepQuestions")}</h3>
                                                        <p>{t("page.projectRunning.questionText")}</p>
                                                        {questionsInProgress &&
                                                            questionsInProgress.map((question, index) => {
                                                                // Recherchez la réponse correspondante à l'ID de la question
                                                                let persistedAnswer = PersisteProjectInProgressQuestions?.find(
                                                                    (answer) => answer.idQuestion === question.id
                                                                );
                                                                const savedAnswer = stepProjectSave?.step?.questions[index].answers[0];

                                                                const matchingAnswer = persistedAnswer ? persistedAnswer?.answer : savedAnswer?.answerText;
                                                                return (
                                                                    <div key={index}>
                                                                        <h4 className="mt-3">{question.questionText} *</h4>
                                                                        <div
                                                                            className={`ckeditor-container-long-desc ${errorQuestions.some((error) => error.idQuestion === question.id) ? "error-ckeditor" : ""}`}>
                                                                            <CKEditor
                                                                                editor={ClassicEditor}
                                                                                data={matchingAnswer ? matchingAnswer : ''}
                                                                                disabled={challenge?.status.name === "finished"}
                                                                                onReady={editor => {
                                                                                    console.log('Editor is ready to use!', editor);
                                                                                    //console.log(Array.from( editor.ui.componentFactory.names()));
                                                                                }}
                                                                                onChange={(event, editor) => {
                                                                                    const data = editor.getData();
                                                                                    onChangeAnswerQuestionStep(data, question.id);
                                                                                }}
                                                                                onBlur={(event, editor) => {
                                                                                    console.log('Blur.', editor);
                                                                                }}
                                                                                onFocus={(event, editor) => {
                                                                                    console.log('Focus.', editor);
                                                                                }}
                                                                                config={{
                                                                                    placeholder: `${t("page.projectRunning.questions.yourAnswer")}`,
                                                                                    toolbar: {
                                                                                        items: [
                                                                                            'undo', 'redo',
                                                                                            '|', 'heading',
                                                                                            '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                                                            '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                                                                            '|', 'link', 'blockQuote', 'codeBlock',
                                                                                            '|', 'alignment',
                                                                                            'fontColor',
                                                                                            '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                                                                        ],
                                                                                        shouldNotGroupWhenFull: true
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            /*
                                                                        <TextField
                                                                            multiline
                                                                            // rows={4}
                                                                            maxRows={4}
                                                                            inputProps={{maxLength: 5000}}
                                                                            key={index}
                                                                            size="small"
                                                                            fullWidth
                                                                            sx={{
                                                                                borderRadius: '10px',
                                                                                '& label.Mui-focused': {
                                                                                    color: '#828D9B',
                                                                                },
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '&.Mui-focused fieldset': {
                                                                                        borderColor: '#828D9B',
                                                                                        borderWidth: '1px',
                                                                                    },
                                                                                },
                                                                            }}
                                                                            defaultValue={matchingAnswer ? matchingAnswer : ''}
                                                                            id={`${question.id}`}
                                                                            placeholder={`${t("page.projectRunning.questions.yourAnswer")}`}
                                                                            onChange={(e) => onChangeAnswerQuestionStep(e)}
                                                                            // helperText="Champ obligatoire *"
                                                                            error={errorQuestions.some((error) => error.idQuestion === question.id)}
                                                                        />
                                                                             */
                                                                        }
                                                                        <p className={"error-message mt-2"}>
                                                                            {
                                                                                errorQuestions.some((error) => error.idQuestion === question.id) && errorMessageFillField
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })}
                                                    </section>
                                                )}
                                                {deliverablesInProgress && deliverablesInProgress !== undefined && deliverablesInProgress.length !== 0 && (
                                                    <section className="mt-3">
                                                        <h3>{t("page.projectRunning.requiredDeliverables")}</h3>
                                                        {deliverablesInProgress && deliverablesInProgress.map((deliverable, index) => (
                                                            <div className="bloc-info-deliverable" key={index}>
                                                                <h4>{deliverable?.name}</h4>
                                                                <p>{deliverable?.description}</p>
                                                            </div>
                                                        ))}
                                                    </section>
                                                )}
                                                {deliverablesInProgressFile && deliverablesInProgressFile !== undefined && deliverablesInProgressFile.length !== 0 && (
                                                    <section className="mt-3">
                                                        <h3>{t("page.projectRunning.yourDeliverables")} *
                                                        </h3>
                                                        {deliverablesInProgressFile && deliverablesInProgressFile.map((deliverable, index) => (
                                                            <div key={index} className="mb-2">
                                                                <Button
                                                                    key={index}
                                                                    variant="contained"
                                                                    className='upload-file'
                                                                    component="label"
                                                                    sx={{
                                                                        padding: '0',
                                                                        maxWidth: '100%',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                >
                                                                    <p style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        margin: 0,
                                                                    }}
                                                                       className='button-text w-80 '>
                                                                        {deliverable?.file ? limitText(deliverable?.file?.name, 15) : limitText(`${t("page.projectRunning.importDocument")}`, 20)}
                                                                    </p>
                                                                    <input
                                                                        ref={inputRefs[deliverable?.idDeliverable] = createRef()}
                                                                        id={`${deliverable?.idDeliverable}`}
                                                                        key={index}
                                                                        name={`${deliverable?.idDeliverable}`}
                                                                        type="file"
                                                                        hidden
                                                                        onChange={(event) => {
                                                                            const files = (event.target as HTMLInputElement).files
                                                                            if (files && files.length > 0) {
                                                                                setErrorDeliverables(false);
                                                                                onChangeDeliverableFile(files[0], String(event.target.name));
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                    <div>
                                                                        <Grid container>
                                                                            <Grid item xs={6}>
                                                                                <Button onClick={() => {
                                                                                    inputRefs[deliverable?.idDeliverable].current?.click()
                                                                                }}>
                                                                                    <IconChange/>
                                                                                </Button>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Button
                                                                                    onClick={() => deletedDeliverable(deliverable?.idDeliverable)}>
                                                                                    <IconClose/>
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </section>
                                                )}
                                            </>
                                        )}
                                        <section id="info-attachment" className="w-75 m-auto mt-4">
                                            <ActionButton
                                                variant="secondary"
                                                onClick={() => addDeliverableFile()}
                                                icon={<IconAdd/>}
                                            >
                                                {t("page.projectRunning.addAttachment")}
                                            </ActionButton>
                                            <p className="mt-2">{t("page.projectRunning.imageFiles")} -PDF- Excel <span
                                                className="limit">&nbsp;(10Mo maximum)</span></p>
                                        </section>

                                        <Grid container mt={1} alignContent={"center"} alignItems={"center"} spacing={3}
                                              width={"80%"} margin={"auto"}>
                                            <Grid item xs>
                                                <ActionButton
                                                    variant="tertiary"
                                                    onClick={() => SaveProjectStep()}
                                                >
                                                    {t("page.projectRunning.save")}
                                                </ActionButton>
                                            </Grid>
                                            <Grid item xs>
                                                <ActionButton
                                                    variant="primary"
                                                    onClick={() => setOpenConfirmModalSend(true)}
                                                >
                                                    {t("page.projectRunning.validateStep")}
                                                </ActionButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {stepsProject && stepsProject.length > 0 && (
                                    <section id="section-collapse-steps-project" className="mt-3">
                                        <h2 className="text-center mb-3">{t("page.projectRunning.stepUpper")}{stepsProject.length > 1 && "s"} {t("page.projectRunning.sent")}{stepsProject.length > 1 && "s"}</h2>
                                        <CollaspseSteps stepsProject={stepsProject}/>
                                    </section>
                                )}
                            </Grid>
                        </Grid>
                        <Modal
                            open={openModalDeleteProject}
                            onClose={() => setOpenModalDeleteProject(false)}
                            title={t("page.projectRunning.modalDeleteProject.title")}
                            icon={<IconLogout/>}
                            iconColor={"errorIconFill"}
                            statusError={true}
                            primaryButtonText={t("page.projectRunning.modalDeleteProject.primaryButton")}
                            tertiaryButtonText={t("page.projectRunning.modalDeleteProject.tertiaryButton")}
                            primaryButtonAction={() => deleteProject()}
                            tertiaryButtonAction={() => setOpenModalDeleteProject(false)}
                            error={errorProjectDeletion}
                        >
                            <p>{t("page.projectRunning.modalDeleteProject.text1")}</p>
                            <p>{t("page.projectRunning.modalDeleteProject.text2")}</p>
                            <p>{t("page.projectRunning.modalDeleteProject.text3")}</p>
                        </Modal>
                        <Modal
                            open={openConfirmModalSend}
                            onClose={() => setOpenConfirmModalSend(false)}
                            title={t("page.projectRunning.modalConfirmModalSend.title")}
                            icon={<IconWarn className="iconWarn"/>}
                            primaryButtonText={t("page.projectRunning.modalConfirmModalSend.primaryButton")}
                            primaryButtonAction={() => ValidateProjectStep()}
                            tertiaryButtonText={t("page.projectRunning.modalConfirmModalSend.tertiaryButton")}
                            tertiaryButtonAction={() => setOpenConfirmModalSend(false)}
                        >
                            <p><span
                                className="fw-bold">{t("page.projectRunning.modalConfirmModalSend.attention")}</span>, {t("page.projectRunning.modalConfirmModalSend.text1")}
                            </p>
                        </Modal>
                        <Modal
                            icon={<IconChange className='icon-change'/>}
                            title={t("page.projectRunning.selectedSubChallenge")}
                            open={openChangeSubChallenge}
                            onClose={() => setOpenChangeSubChallenge(false)}
                            primaryButtonText={t("form.validate")}
                            primaryButtonAction={() => updateSelectSubChallenge()}
                            secondaryButtonText={t("form.cancel")}
                            secondaryButtonAction={() => {
                                setSelectSubChallenge(teamchoiceSubChallenge?.id);
                                setOpenChangeSubChallenge(false)
                            }}
                        >
                            <RadioGroup
                                name="use-radio-group"
                                defaultValue={teamchoiceSubChallenge && teamchoiceSubChallenge !== undefined && Number(teamchoiceSubChallenge)}
                                value={selectSubChallenge && selectSubChallenge !== undefined && Number(selectSubChallenge)}
                                sx={{wordBreak: 'break-word'}}
                                onChange={(e) => onChangeSubChallenge(e)}
                            >
                                {SubChallenges && SubChallenges.map((subChallenge, index) => (
                                    <MyFormControlLabel
                                        key={index}
                                        value={Number(subChallenge.id)}
                                        label={
                                            <div className="text-start">
                                                <h5 className="h5modal">{subChallenge.name}</h5>
                                                <p className="desc-sub-challenge">{subChallenge.description}</p>
                                            </div>
                                        }
                                        control={
                                            <Radio
                                                sx={{
                                                    '&, &.Mui-checked': {
                                                        color: subChallenge && selectSubChallenge !== undefined && selectSubChallenge === Number(subChallenge.id) ? '#F86767' : '#828D9B'
                                                    },
                                                }}
                                            />
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </Modal>
                        <Snackbar
                            open={errorFront}
                            autoHideDuration={3000}
                            onClose={() => setErrorFront(false)}
                        >
                            <Alert onClose={() => {
                                setErrorFront(false)
                            }} severity={severityErrorFront}>{messageErrorFront}</Alert>
                        </Snackbar>
                        {save && <Save savingInProgress={`${t("page.projectRunning.saveModal.savingInProgress")}`}
                                       pleaseWait={`${t("page.projectRunning.saveModal.pleaseWait")}`}/>}
                    </>
                ) : (
                    <div>
                        <p>{t("page.projectRunning.notAuthorized")}</p>
                    </div>
                )
            }
        </section>
    )
}
export default ProjectRunning;
