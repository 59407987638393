// style
import './index.css';

// mui
import LinearProgress from '@mui/material/LinearProgress';


interface IProgressBar {
    progression: number;
    className?: string;
}

/**
 * 
 * # ProgressBar
 * 
 * ---
 * 
 * La ProgressBar représente une barre de progression (logique...),
 * remplie avec le dégradé makee en fonction du pourcentage de progression.
 * 
 * ---
 * 
 * @param progression {number} - Le pourcentage de remplissage de la barre.
 * @param className {string} - Les classes à ajouter au composant
 * 
 * ---
 * 
 * @example
 * <ProgressBar progression={50}/>
 * <ProgressBar progression={53.65}/>
 * <ProgressBar progression={
 *  (establishment.nbUsers / establishment.nbMaxUsers) * 100
 * }/>
 */
const ProgressBar = ({progression, className}: IProgressBar) => {
    return (
        <LinearProgress
            sx={{
                background: "var(--primary10)",
                height: "0.8vh",
                borderRadius: "10px",
                // on met !important pour que le style soit prioritaire
                // car le style de MUI n'est pas adapté aux gradients
                '> span':{
                    background: "var(--gradient)",
                    transform: 'translateX(0) !important', // pour que la barre soit toujours à 0% au début
                    width: progression + '% !important',
                    transition: 'width 2s ease-in-out'
                }
            }}
            value={progression}
            variant="determinate"
            className={className}
        />
    )
}

export default ProgressBar;