// react
import React, {ReactComponentElement, useEffect, useState} from 'react';

// icons
import {ReactComponent as Arrow} from '../../assets/arrow-right.svg';

// style
import './index.css'

// props
interface ActionButtonProps {
    children: React.ReactNode;
    type?: 'button' | 'submit';
    onClick?: () => void;
    variant: string | 'disabled' | 'primary' | 'grey' | 'secondary' | 'tertiary' | 'grey-profiles' | 'little-underline' | 'little-underline-disabled' | 'deselect' | 'choice' | 'primary-card' | 'disabled-card' | 'see-project';
    className?: string;
    key?: number | string;
    icon?: ReactComponentElement<any> | string;
    iconStart?: boolean;
    iconEnd?: boolean;
    iconTextEnd?: boolean;
    iconTextStart?: boolean;
    focus?: boolean;
    auth?: boolean;
    width?: string;
    error?: boolean;
};

/**
 *  * ## ActionButton
 *  *
 *  * ---
 *  *
 *  * Ce composant est un bouton d'action. Il est utilisé pour les boutons d'action (bouton de connexion, bouton de création de compte, etc.)
 *  * Il existe plusieurs types de boutons :
 * - primary : dégradé MAKEE
 * - secondary : bouton rose clair
 * - grey : bouton gris
 * - grey-profiles : bouton gris quand il y a plusieurs profils
 *
 * ---
 *
 * @param {React.ReactNode} children - Le texte à afficher dans le bouton
 * @param {string} type - Le type du bouton (button, submit, reset)
 * @param {() => void} onClick - La fonction à exécuter au clic
 * @param {string} variant - Le type de bouton (primary, grey, secondary, grey-profiles)
 * @param {string} className - Les classes CSS à appliquer au bouton
 * @param {number | string} key - La clé du bouton
 * @param {ReactComponentElement<any>} icon - L'icône à afficher à gauche du texte
 * @param {boolean} focus - Si le bouton est focus ou non
 * @param {boolean} auth - Si le bouton est un bouton d'authentification ou non
 *
 * ---
 *
 * @example
 * <ActionButton
 * type='submit'
 * onClick={() => submitForm()}
 * variant='primary'
 * className='button-auth'
 * key={0}
 * icon={<DoneIcon/>}>
 *    Valider
 * </ActionButton>
 *
 */

const ActionButton: React.FC<ActionButtonProps> = ({
                                                       children,
                                                       type = 'button',
                                                       onClick,
                                                       variant,
                                                       key = 0,
                                                       icon,
                                                       iconStart,
                                                       iconEnd,
                                                       iconTextEnd,
                                                       iconTextStart,
                                                       auth,
                                                       width,
                                                       className,
                                                       error
                                                   }) => {
    return (
        <button
            className={`action-button ${variant} ${(iconTextStart ? 'icon-text-start' : '')} ${(iconTextEnd ? 'icon-text-end' : '')} ${className ? `${className}` : ''}`}
            type={type}
            onClick={onClick}
            key={key}
            style={{width: `${width}`}}
        >
            <div className='after'></div>
            {icon && <div
                className={`action-button-icon ${iconStart ? 'icon-start' : ''}${iconEnd ? 'icon-end' : ''} ${icon ? 'icon-' + variant : ''}`}
            >
                {icon}
            </div>}
            <span>{children}</span>
        </button>
    );
}

export default ActionButton;
