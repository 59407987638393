//style
import './index.css'
import {ReactComponent as IconCopy} from '../../../../assets/icon-copy.svg';

// components
import ActionButton from "../../../../components/ActionButton";
import GoBack from '../../../../components/GoBack';

// assets
import {ReactComponent as CalendarIcon} from '../../../../assets/icon-calendar.svg';
import {ReactComponent as AlertIcon} from '../../../../assets/icon-alert.svg';
import {ReactComponent as DownloadIcon} from '../../../../assets/icon-download.svg';
import {ReactComponent as Arrow} from '../../../../assets/arrow-down.svg';
import {ReactComponent as LinkIcon} from '../../../../../assets/lien.svg';
import {ReactComponent as EyeIcon} from '../../../../assets/icon-eye.svg';
import {ReactComponent as AnnonceIcon} from '../../../../assets/icon-annonce.svg';
import {ReactComponent as ClockIcon} from '../../../../assets/icon-wait.svg'
import {ReactComponent as TeamIcon} from '../../../../assets/icon-network.svg'
import {ReactComponent as IconPreview} from '../../../../assets/icon-eye.svg';
import {ReactComponent as IconAdd} from '../../../../assets/icon-add.svg';

// moment
import moment from "moment";
import "moment/locale/fr"; // Importer la localisation française pour les noms de mois en français

// components
import OutletNavBar from '../../../../modules/MenuTabs'

//modules
import LoginHeader from '../../../../modules/LoginHeader';

// react-router-dom
import {Outlet, useLocation, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../app/type';

// icons
import React, {createRef, useEffect, useState} from 'react';
import {Avatar, Chip, Grid, InputAdornment, TextField, Tooltip, useMediaQuery} from '@mui/material';

import {useTheme} from '@mui/material/styles';
import {DEFAULT_ESTABLISHMENT_IMAGE} from '../../../../utils/consts';
import SubChallenge from "../../../../modules/SubscribeChallenge";
import {useAuth} from "../../../../context/AuthContext";
import MenuTabs from "../../../../modules/MenuTabs";


//Types
import {IChallengeData} from '../../../../modules/SubscribeChallenge/type';
import {IChallenge} from "./types";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../components/SetUpTranslation";
import api from "../../../../utils/api";

type ContextType = {
    getRegisteredToChallengeById: () => void,
    getChallengeById: () => void,
    challenge: IChallenge | null,
    challengeData: IChallengeData | null
}

const Challenge = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const navigate = useNavigate();

    const roleName = useSelector((state: RootState) => state.rootProfile.name)

    // const id = challenge?.challenge?.id;
    const id = useParams().id;
    const outletLinksPreview = [
        {
            label: t("page.challenge.nav.general"),
            key: 'general',
            link: `/preview/challenge/${id}/general`
        },
        {
            label: 'Planning',
            key: 'planning',
            link: `/preview/challenge/${id}/planning`
        },
        {
            label: t("page.challenge.nav.faq"),
            key: 'faq',
            link: `/preview/challenge/${id}/faq`
        },
        {
            label: t("page.challenge.nav.resources"),
            key: 'ressources',
            link: `/preview/challenge/${id}/ressources`
        },
    ];
    const [outletNavbarLinks, setOutletNavbarLinks] = useState<any>([]);
    const [state, setState] = useState('');
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        // console.log("isMdDown", isMdDown);
    }, [isMdDown]);

    const location = useLocation();
    const {profile} = useAuth();
    const dispatch = useDispatch();

    const challengeId = useParams().id;
    const [challengeData, setChallengeData] = useState<IChallengeData | null>(null);
    const [challenge, setChallenge] = useState<IChallenge | null>(null);
    const getChallengeById = () => {
        api.get('/challenge/preview', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                const challenge = res.data;
                setChallenge(res.data);
                navTabs(challenge);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const getRegisteredToChallengeById = () => {
        api.get("/challenge/details/" + challengeId,
            {
                withCredentials: true
            }
        ).then((res) => {
            const challenge = res.data;
            setChallengeData(challenge);
            navTabs(challenge);

        }).catch((err) => {
            console.log(err);
        });

    };
    useEffect(() => {
        getChallengeById();
        window.scrollTo(0, 0);
    }, []);
    console.log("challenge", challenge);
    console.log(challenge?.challenge.status.name);

    function getStatusText(challenge: any) {
        switch (challenge) {
            case "in creation":
                return t("status.inCreation")
                break;
            case "published":
                return t("status.published");
                break;
            case "registrations":
                return t("status.registrations");
                break;
            case "submission":
                return t("status.submission");
                break;
            case "deliberations":
                return t("status.deliberations");
                break;
            default:
                return t("status.finished");
        }
    }

    const navTabs = (challengeT: IChallengeData) => {
        if (challengeT) {
            if (roleName === "admin") {
                if (challenge?.challenge.status.name === "finished") {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        },
                        {
                            label: t("page.challenge.nav.results"),
                            key: 'resultats',
                            link: `/challenge/${id}/resultats`
                        }
                    ]);
                } else {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        }
                    ]);
                }
            } else if (roleName === "participant" && challengeT.registered) {
                if (challenge?.challenge.status.name === "finished") {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        },
                        {
                            label: t("page.challenge.nav.myProject"),
                            key: "my-project",
                            link: `/challenge/${id}/my-project`
                        },
                        {
                            label: t("page.challenge.nav.resources"),
                            key: 'ressources',
                            link: `/challenge/${id}/ressources`
                        },
                        {
                            label: t("page.challenge.nav.results"),
                            key: 'resultats',
                            link: `/challenge/${id}/resultats`
                        }
                    ]);
                } else {
                    if (challenge?.challenge.status.name === "finished") {
                        setOutletNavbarLinks([
                            {
                                label: t("page.challenge.nav.general"),
                                key: 'general',
                                link: `/challenge/${id}/general`
                            },
                            {
                                label: 'Planning',
                                key: 'planning',
                                link: `/challenge/${id}/planning`
                            },
                            {
                                label: t("page.challenge.nav.myProject"),
                                key: "my-project",
                                link: `/challenge/${id}/my-project`
                            },
                            {
                                label: t("page.challenge.nav.resources"),
                                key: 'ressources',
                                link: `/challenge/${id}/ressources`
                            },
                            {
                                label: t("page.challenge.nav.results"),
                                key: 'resultats',
                                link: `/challenge/${id}/resultats`
                            }
                        ]);
                    } else {
                        setOutletNavbarLinks([
                            {
                                label: t("page.challenge.nav.general"),
                                key: 'general',
                                link: `/challenge/${id}/general`
                            },
                            {
                                label: 'Planning',
                                key: 'planning',
                                link: `/challenge/${id}/planning`
                            },
                            {
                                label: t("page.challenge.nav.myProject"),
                                key: "my-project",
                                link: `/challenge/${id}/my-project`
                            },
                            {
                                label: t("page.challenge.nav.resources"),
                                key: 'ressources',
                                link: `/challenge/${id}/ressources`
                            }
                        ]);
                    }
                }
            } else {
                if (challenge?.challenge.status.name === "finished") {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        },
                        {
                            label: t("page.challenge.nav.results"),
                            key: 'resultats',
                            link: `/challenge/${id}/resultats`
                        }
                    ]);
                } else {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        }
                    ]);
                }
            }
        }
    }

    useEffect(() => {
        // if (challengeData && (location.pathname.includes("/ressources") && ((roleName === "participant" && !challengeData.registered) || (roleName === "admin") || (!roleName)))) {
        //     navigate(-1);
        // }
        if (roleName === "admin") {
            if (challenge?.challenge.status.name === "finished") {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    },
                    {
                        label: t("page.challenge.nav.results"),
                        key: 'resultats',
                        link: `/challenge/${id}/resultats`
                    }
                ]);
            } else {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    }
                ]);
            }
        } else if (roleName === "participant" && challengeData?.registered) {
            if (challenge?.challenge.status.name === "finished") {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    },
                    {
                        label: t("page.challenge.nav.myProject"),
                        key: "my-project",
                        link: `/challenge/${id}/my-project`
                    },
                    {
                        label: t("page.challenge.nav.resources"),
                        key: 'ressources',
                        link: `/challenge/${id}/ressources`
                    },
                    {
                        label: t("page.challenge.nav.results"),
                        key: 'resultats',
                        link: `/challenge/${id}/resultats`
                    }
                ]);
            } else {
                if (challenge?.challenge.status.name === "finished") {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        },
                        {
                            label: t("page.challenge.nav.myProject"),
                            key: "my-project",
                            link: `/challenge/${id}/my-project`
                        },
                        {
                            label: t("page.challenge.nav.resoures"),
                            key: 'ressources',
                            link: `/challenge/${id}/ressources`
                        },
                        {
                            label: t("page.challenge.nav.results"),
                            key: 'resultats',
                            link: `/challenge/${id}/resultats`
                        }
                    ]);
                } else {
                    setOutletNavbarLinks([
                        {
                            label: t("page.challenge.nav.general"),
                            key: 'general',
                            link: `/challenge/${id}/general`
                        },
                        {
                            label: 'Planning',
                            key: 'planning',
                            link: `/challenge/${id}/planning`
                        },
                        {
                            label: t("page.challenge.nav.myProject"),
                            key: "my-project",
                            link: `/challenge/${id}/my-project`
                        },
                        {
                            label: t("page.challenge.nav.resources"),
                            key: 'ressources',
                            link: `/challenge/${id}/ressources`
                        }
                    ]);
                }
            }
        } else {
            if (challenge?.challenge.status.name === "finished") {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    },
                    {
                        label: t("page.challenge.nav.results"),
                        key: 'resultats',
                        link: `/challenge/${id}/resultats`
                    }
                ]);
            } else {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    }
                ]);
            }
        }
        if (challenge && challenge.challenge.itemFAQs.length > 0) {
            if (challenge?.challenge.status.name === "finished") {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    },
                    {
                        label: t("page.challenge.nav.myProject"),
                        key: "my-project",
                        link: `/challenge/${id}/my-project`
                    },
                    {
                        label: t("page.challenge.nav.resources"),
                        key: 'ressources',
                        link: `/challenge/${id}/ressources`
                    },
                    {
                        label: t("page.challenge.nav.faq"),
                        key: 'faq',
                        link: `/challenge/${id}/faq`
                    },
                    {
                        label: t("page.challenge.nav.results"),
                        key: 'resultats',
                        link: `/challenge/${id}/resultats`
                    }
                ]);
            } else {
                setOutletNavbarLinks([
                    {
                        label: t("page.challenge.nav.general"),
                        key: 'general',
                        link: `/challenge/${id}/general`
                    },
                    {
                        label: 'Planning',
                        key: 'planning',
                        link: `/challenge/${id}/planning`
                    },
                    {
                        label: t("page.challenge.nav.myProject"),
                        key: "my-project",
                        link: `/challenge/${id}/my-project`
                    },
                    {
                        label: t("page.challenge.nav.resources"),
                        key: 'ressources',
                        link: `/challenge/${id}/ressources`
                    },
                    {
                        label: t("page.challenge.nav.faq"),
                        key: 'faq',
                        link: `/challenge/${id}/faq`
                    }
                ]);
            }
        }
    }, [challengeData, id, roleName, challenge, challenge?.challenge.status, challenge?.challenge.itemFAQs, location.pathname, navigate])
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {isAuthenticated, login, finishedCheckingAuth} = useAuth();
    const [openTooltipLive, setOpenToolTipLive] = useState(false);
    const handleTooltipLiveOpen = () => {
        setOpenToolTipLive(true);
        navigator.clipboard.writeText(challenge?.challenge.link_live!);
    };
    const handleTooltipLiveClose = () => {
        setOpenToolTipLive(false);
    };
    useEffect(() => {
        const checkAuth = async () => {
            await login();
        };
        if (!finishedCheckingAuth) {
            checkAuth();
        }
    }, [finishedCheckingAuth, login]);
    moment.locale(i18n.language);
    return (
        <>
            {/* <LoginHeader /> */}
            <div className={`admin-preview-challenge ${!isAuthenticated ? 'admin-preview-challenge-logout' : ''}`}>
                {
                    !isAuthenticated && (
                        <GoBack onClick={() => navigate("/")}
                                className="challenge-back-to-home">{t("page.challenge.backToHome")}</GoBack>
                    )
                }
                <section className='w-100 relative'>
                    <div className='w-100' style={{position: "relative"}}>
                        <img className='w-100' src={challenge?.challenge.challengePicture} alt="challengepicture"/>
                        {
                            !isMobile && (
                                <Chip
                                    sx={{
                                        backgroundColor: "var(--primary10)",
                                    }}
                                    className={"chip-countMembers"}
                                    label={challenge?.challenge.countMembers !== 0 ? `${challenge?.challenge?.countMembers} ${challenge?.challenge?.countMembers === 1 ? i18n.language === "en" ? "registered" : 'inscrit' : 'inscrits'}` : t("page.challenge.notRegistrationsYet")}
                                    variant={"filled"}
                                    icon={<TeamIcon/>}
                                />
                            )
                        }
                    </div>
                </section>
                <div className="header">
                    <div className="challenge-infos">
                        <div className="go-back-tablet">
                            <GoBack
                                onClick={() => {
                                    if (location.pathname === '/') {
                                        navigate('/')
                                    } else {
                                        navigate(`/admin/challenge/${id}/general`)
                                    }
                                }}
                            />
                        </div>
                        <div className="go-back-title">
                            <GoBack
                                onClick={() => {
                                    if (location.pathname === '/') {
                                        navigate('/')
                                    } else {
                                        navigate(`/admin/challenge/${id}/general`)
                                    }
                                }}
                            />
                        </div>
                        <Grid container spacing={2} mt={4}>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Grid container sx={{mx: 'auto'}}>
                                    {/*<div className='block-logo-establishment'>*/}
                                    {
                                        challenge?.challenge.challengeLogo && (
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}
                                                  className={`${isMobile ? 'logo-establishment-mobile' : ''}`}>
                                                <img
                                                    className={`logo-establishment`}
                                                    src={challenge?.challenge.challengeLogo}
                                                    alt="img-profile-establishment"
                                                />
                                            </Grid>
                                        )
                                    }
                                    {/*</div>*/}
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} id="info" alignItems="center"
                                          sx={
                                              !isMdDown ? {
                                                  padding: "0!important"
                                              } : {paddingLeft: '16px'}
                                          }
                                    >
                                        <div className={`${!isMdDown ? "alignlast-center" : ""}`}>
                                            <Chip sx={{
                                                backgroundColor: "var(--primary10)",
                                            }}
                                                  label={getStatusText(challenge?.challenge.status.name)}
                                            />
                                            <h1 className='h1'>{challenge?.challenge.name}</h1>
                                            <p className="text-justify">{challenge?.challenge?.shortDescription}</p>
                                            <Chip
                                                avatar={<Avatar alt="organizer-icon"
                                                                src={challenge?.challenge.establishments[0].establishmentPicture}/>}
                                                label={challenge?.challenge.establishments[0].name}
                                                variant="outlined"
                                                className="chip-organizers"
                                            />
                                        </div>
                                        {
                                            isMobile && (
                                                <div className="chip-countMembers-mobile-container">
                                                    <Chip
                                                        sx={{
                                                            backgroundColor: "var(--primary10)",
                                                        }}
                                                        className={"chip-countMembers-mobile"}
                                                        label={challenge?.challenge.countMembers !== 0 ? `${challenge?.challenge.countMembers} ${challenge?.challenge.countMembers === 1 ? 'inscrit' : 'inscrits'}` : `Pas encore d'inscrit`}
                                                        variant={"filled"}
                                                        icon={<TeamIcon/>}

                                                    />
                                                </div>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Grid container spacing={1} alignContent={"center"} className='h-100'>
                                    <Grid item xs={12} sm={6}>
                                        {(getStatusText(challenge?.challenge.status.name) !== "Gagné") &&
                                            <div className="info-primary-bloc">
                                                <div className='w-100'>
                                                    <CalendarIcon/>
                                                    <p className="bold">{t("challengeCard.from")} {moment(challenge?.challenge.startDate).format('LL')} {t("challengeCard.to")} {moment(challenge?.challenge.endDate).format('LL')}</p>
                                                </div>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="info-primary-bloc h-100">
                                            <div className='w-100'>
                                                <TeamIcon/>
                                                <p className="bold">{
                                                    (challenge?.challenge.participationType === 'single') ?
                                                        t("participationType.single") :
                                                        (challenge?.challenge.participationType === 'team') ?
                                                            t("participationType.team") : t("participationType.both")
                                                }</p>
                                                <p className="text">{
                                                    (challenge?.challenge?.participationType === 'team') ?
                                                        `${t("challengeCard.fromMembers")} ` + challenge?.challenge?.minMembersTeam + ` ${t("challengeCard.toMembers")} ` + challenge?.challenge?.maxMembersTeam + ` ${t("projectCard.members")}` :
                                                        (challenge?.challenge?.participationType === 'both') ?
                                                            `${t("challengeCard.untilMembers")} ` + challenge?.challenge?.maxMembers + ` ${t("projectCard.members")}` : ''
                                                }</p>
                                            </div>
                                        </div>
                                    </Grid>
                                    {
                                        challengeData?.registered && challenge?.challenge.link_live && (
                                            <Grid item xs={12} sm={12}>
                                                <section className={"live-challenge"}>
                                                    <p className={"live-challenge-title"}>Live du challenge</p>
                                                    <p className={"live-challenge-description"}>Retrouvez ci-dessous le lien du
                                                        live Challenge
                                                        pour tous les
                                                        moments clés de votre évènement : lancement, pitchs, keynote ...</p>
                                                    {
                                                        challenge?.challenge.link_live && (
                                                            <TextField
                                                                sx={{
                                                                    backgroundColor: '#EDF1F7',
                                                                    borderRadius: '10px',
                                                                    boxShadow: 'none',
                                                                    border: 'none',
                                                                    '& fieldset': {
                                                                        border: 'none',
                                                                    },
                                                                    marginTop: "1rem"
                                                                }}
                                                                hiddenLabel
                                                                id="filled-hidden-label-small"
                                                                defaultValue={challenge?.challenge.link_live}
                                                                value={challenge?.challenge.link_live}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    sx: {
                                                                        backgroundColor: "white",
                                                                        borderRadius: '10px',
                                                                        boxShadow: 'none',
                                                                        border: 'none'
                                                                    },
                                                                    endAdornment:
                                                                        <InputAdornment
                                                                            sx={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            position="start"
                                                                        >
                                                                            <Tooltip
                                                                                PopperProps={{
                                                                                    disablePortal: true,
                                                                                }}
                                                                                onClose={() => handleTooltipLiveClose()}
                                                                                open={openTooltipLive}
                                                                                disableFocusListener
                                                                                disableHoverListener
                                                                                disableTouchListener
                                                                                title="Copié !"
                                                                            >
                                                                                <IconCopy onClick={handleTooltipLiveOpen}/>
                                                                            </Tooltip>
                                                                        </InputAdornment>,
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </section>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12}>
                                        {
                                            (challenge?.challenge?.registrationEndDate && moment().format() < moment(challenge?.challenge?.registrationEndDate).format() && challenge.challenge.registration) &&
                                            <div className="registrations">
                                                <ClockIcon/>
                                                <p className="text">{t("page.challenge.registrationsClose")} {moment(challenge?.challenge?.registrationEndDate).endOf("day").fromNow()}</p>
                                            </div>
                                        }
                                    </Grid>
                                    {
                                        (profile === "admin" && location.pathname.includes("/preview/challenge")) ? (
                                            <Grid item xs={12}>
                                                <ActionButton variant={"primary"}>
                                                    M'inscrire
                                                </ActionButton>
                                            </Grid>
                                        ) : (!profile || profile === "participant") && (
                                            <Grid item xs={12}>
                                                <SubChallenge
                                                    getRegisteredToChallengeById={getRegisteredToChallengeById}
                                                    getChallengeById={getChallengeById}
                                                    challengeData={challenge}
                                                    challengeInscriptionDetails={challengeData}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="sticky-nav-bar-mobile">
                    <MenuTabs
                        links={(profile === "admin" && location.pathname.includes("/preview/challenge")) ? outletLinksPreview : outletNavbarLinks}
                        variant={"menu-tabs-primary"}
                    />
                </div>

                <section className="admin-preview-challenge-container">
                    <Outlet context={{getRegisteredToChallengeById, getChallengeById, challenge, challengeData}}/>
                </section>
            </div>
        </>

    )
}

export const useChallengeDataContext = () => {
    const {
        getRegisteredToChallengeById, getChallengeById, challenge, challengeData
    } = useOutletContext() as ContextType;
    return {
        getRegisteredToChallengeById, getChallengeById, challenge, challengeData
    };
}

export default Challenge;
