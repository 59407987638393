// cette page est un résumé des informations du profil créé
// elle permet de vérifier que les informations sont correctes

// material ui
import {Box, FormControl, MenuItem, Select, TextField} from "@mui/material";

// components
import CardNameDate from "../../../../../../components/CardNameDate";
import ActionButton from "../../../../../../components/ActionButton";

// react-dom
import {Link, useNavigate, useLocation} from "react-router-dom";

// context
import {useAuth} from "../../../../../../context/AuthContext";

// assets 
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// style
import "./index.css";

// react
import {useState} from "react";

const ProfileSummary = () => {

    const location = useLocation();

    // navigation
    const navigate = useNavigate();

    // context 
    const {mergeProfile, searchProfile} = useAuth();
    const emailRecup = searchProfile && searchProfile.user.email || null;
    const emailPro = searchProfile && mergeProfile.emailpro;
    const lastname = searchProfile && searchProfile.user.lastname;
    const firstname = searchProfile && searchProfile.user.firstname;
    const img_url = searchProfile && searchProfile.user.profile_picture;

    // states pour savoir si l'email souhaite être modifié
    const [email, setEmail] = useState(emailRecup);

    const {profile} = useAuth();

    return (
        <>

            <section className="profile-summary">
                <div className="div-profile-summary">
                    {/* affichage d'une carte avec les informations du profil créé : nom, prénom, date de naissance, photo de profil */}
                    <div className='card-date-name-container'>
                        <CardNameDate
                            img_url={img_url}
                            name={firstname + " " + lastname}
                            // img_url='url'
                            // name='Eléa Crunchant'
                            variant="large"
                            new_profile
                        />
                    </div>

                    <div className="profile-summary-infos">
                        <div className="profile-summary-infos-container">

                            {/* image svg en haut du formulaire (mobile) */}
                            <MakeeDiamondLogin className='makee-diamond-login'/>

                            {location.pathname.includes('admin') &&
                                <h1 className="h1-auth">Votre nouveau profil administrateur est bien associé à votre
                                    compte Makee</h1>}
                            {location.pathname.includes('participant') &&
                                <h1 className="h1-auth">Votre nouveau profil participant est bien associé à votre compte
                                    Makee</h1>}
                            <div className='card-date-name-container-mobile'>
                                <CardNameDate
                                    img_url={img_url}
                                    name={firstname + " " + lastname}
                                    // img_url='url'
                                    // name='Eléa Crunchant'
                                    variant="large"
                                    new_profile
                                />
                            </div>

                            <FormControl>
                                <Box
                                    component="form"
                                    sx={{
                                        // on ajoute un margin top de 40px à tous les enfants de Box
                                        '& > :not(style)': {marginTop: '2rem', width: '100%'},
                                        'input': {paddingLeft: '3rem'},
                                        'div': {display: 'flex', flexDirection: 'column', gap: '1rem'},
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    {/* adresses mails du profil (pro et de récupération) */}
                                    <div>
                                        <p className="small-lead-text">Mon email professionelle :</p>
                                        <TextField
                                            disabled
                                            id="outlined-disabled"
                                            defaultValue={emailPro}
                                            fullWidth
                                        />
                                    </div>
                                    <div>
                                        <p className="small-lead-text">Mon email de récupération :</p>
                                        <TextField
                                            type='text'
                                            disabled
                                            id="outlined-disabled"
                                            defaultValue={email}
                                            fullWidth
                                        />
                                    </div>

                                    <ActionButton
                                        auth
                                        variant="primary"
                                        type="button"
                                        onClick={() => {
                                            navigate('/')
                                        }}
                                        className='button-text-auth'
                                    >
                                        Découvrir mon nouvel espace Makee
                                    </ActionButton>
                                </Box>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProfileSummary