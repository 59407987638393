// dnd-kit
import {
    DndContext,
    useSensor
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';

// sensor
import GlobalPointerSensor from '../../utils/GlobalPointerSensor';

// react
import React, { useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

// components
import ActionButton from '../ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';

// style
import './index.css'

import IQuestion from './types';
import IChallenge from '../../pages/MINIMAL/Admin/AdminChallenges/types'
import Question from '../Question';
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge';

interface QuestionListProps {
    setChallenge: (challenge: IChallenge) => void,
    challenge: IChallenge
}

const QuestionList = ({setChallenge, challenge}: QuestionListProps) => {
    const sensors = [useSensor(GlobalPointerSensor)];
    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = challenge.itemFAQs.findIndex((itemFAQ) => itemFAQ.id === active.id);
            const newIndex = challenge.itemFAQs.findIndex((itemFAQ) => itemFAQ.id === over.id);

            const newItemFAQs = challenge.itemFAQs.map((itemFAQ) => {
                if (itemFAQ.id === active.id) {
                    return {
                        ...itemFAQ,
                        position: newIndex + 1
                    };
                } else if (itemFAQ.id === over.id) {
                    return {
                        ...itemFAQ,
                        position: oldIndex + 1
                    };
                }
                return itemFAQ;
            });

            // Mettre à jour le challenge en changeant la position
            setChallenge({
                ...challenge,
                itemFAQs: arrayMove(newItemFAQs, oldIndex, newIndex)
            });
        }
    };
    useEffect(() => {
        console.log("errorFieldGeneral FAQ ",errorFieldGeneral);
    }, [errorFieldGeneral])
        

    const handleDeleteQuestion = (question: IQuestion) => {
        // Supprimer dare et remettre les positions à jour
        const newItemFAQs = challenge.itemFAQs
            .filter((q) => String(q.id) !== String(question.id))
            .map((q, index) => ({
                ...q,
                position: index + 1
            }));

        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            itemFAQs: newItemFAQs
        });
        //Supprimer les errorFieldGeneral avec cette id 
        const newErrorFieldGeneral = errorFieldGeneral.filter((errorFieldGeneral: { id: any; }) => String(errorFieldGeneral.id) !== String(question.id))
        setErrorFieldGeneral(newErrorFieldGeneral)
        
    };

    const handleUpdateQuestion = (itemFAQ: IQuestion) => {
        // Mettre à jour dare
        const newItemFAQs = challenge.itemFAQs.map((f) =>
            String(f.id) === String(itemFAQ.id) ? itemFAQ : f
        );

        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            itemFAQs: newItemFAQs
        });
        //Supprimer les errorFieldGeneral avec cette id 
        const newErrorFieldGeneral = errorFieldGeneral.filter((errorFieldGeneral: { id: any; }) => String(errorFieldGeneral.id) !== String(itemFAQ.id))
        setErrorFieldGeneral(newErrorFieldGeneral)
    };

    const handleAddQuestion = () => {
        console.log('add FAQ');
        console.log("itemFAQs", challenge.itemFAQs);
        // Ajouter Dare
        const newItemFAQs: IQuestion = {
            id: "new-"+uuidv4(),
            question: "",
            answer: "",
            position: challenge.itemFAQs.length + 1
        };

        // Ajouter newDare à challenge
        setChallenge({
            ...challenge,
            itemFAQs: [...challenge.itemFAQs, newItemFAQs]
        });
    };

    useEffect(() => {
        console.log("subChallenges", challenge.subChallenges);
    }, [challenge.subChallenges]);

    return (
        <div className="question-list">
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={challenge.itemFAQs.sort((a, b) => a.position - b.position).map((faq) => faq.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {challenge.itemFAQs.map((faq) => (
                        <Question
                            disabled={challenge.status.name==="finished"}
                            key={String(faq.id)}
                            question={faq}
                            handleDeleteQuestion={handleDeleteQuestion}
                            handleUpdateQuestion={handleUpdateQuestion}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <ActionButton
                variant={challenge.status.name==="finished"?'disabled':'secondary'}
                onClick={challenge.status.name==="finished"?() => {}:handleAddQuestion}
                className='button-add-question'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter une question
            </ActionButton>
        </div>
    );
}

export default QuestionList;
