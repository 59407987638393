// style
import './index.css'

// components
import ActionButton from '../../../../../../components/ActionButton';

// logos
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';
import {ReactComponent as Diamond} from '../../../../../../assets/diamond-eyes-up.svg';

// hooks
import {useNavigate} from 'react-router-dom';
import SupportFooter from "../../../../../../modules/SupportFooter";

const ResetDone = () => {
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;
    return (
        <>
            <div className='forgot-password-reset-done'>
                <section className='section-container'>
                    <MakeeDiamondLogin className='makee-diamond-login-reset-password'/>
                    <div className="main">
                        <h1>Votre mot de passe a bien été modifié !</h1>
                        <ActionButton
                            auth
                            variant='primary'
                            className='button-text-auth auth-button'
                            onClick={() => navigate('/login/1')}
                        >
                            Me connecter à mon compte
                        </ActionButton>
                    </div>
                    {!isMobile && <Diamond className='diamond'/>}
                </section>
                {isMobile && <Diamond className='diamond'/>}
            </div>
            <SupportFooter/>
        </>
    )
}

export default ResetDone;