import React, {useEffect, useState} from "react";
import SwitchTranslateEn from "../../assets/imgs/switchTranslateEn";
import SwitchTranslateFr from "../../assets/imgs/switchTranslateFr";
import api from "../../utils/api";

interface ISwitchTranslate {
    onClick?: () => void;
    className?: string;
}

const SwitchTranslate: React.FC<ISwitchTranslate> = ({className, onClick}) => {
    const [currentTranslation, setCurrentTranslation] = useState<string>("");

    useEffect(() => {
        const storedTranslation = localStorage.getItem('translation');
        if (!storedTranslation) {
            const defaultTranslation = "fr";
            localStorage.setItem('translation', defaultTranslation);
            setCurrentTranslation(defaultTranslation);
        } else {
            setCurrentTranslation(storedTranslation);
        }
    }, []);

    const toggleTranslation = async () => {
        if (currentTranslation === "fr") {
            localStorage.setItem('translation', "en");
            setCurrentTranslation("en");
        } else {
            localStorage.setItem('translation', "fr");
            setCurrentTranslation("fr");
        }
        const event = new CustomEvent("translationChanged", {
            detail: {newTranslation: currentTranslation === "fr" ? "en" : "fr"},
        });
        window.dispatchEvent(event);
        await api.post("/language",
            {
                language: localStorage.getItem("translation")
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            console.log(res.data);
        })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className={`${className} global-translate-icon`} onClick={toggleTranslation}>
            {currentTranslation === "fr" ? <SwitchTranslateEn/> : <SwitchTranslateFr/>}
        </div>
    );
};

export default SwitchTranslate;
