// page de relais pour l'inscription à partir d'un email d'invitation

// react-dom
import { Outlet } from "react-router-dom"

// react 
import { useState } from "react";
import { Alert, AlertTitle } from "@mui/material";

const AdminInv = () => {

    const [showAlert, setShowAlert] = useState(false);

    return (
        <>
            {showAlert && (
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    This is an info alert — <strong>check it out!</strong>
                </Alert>
            )}
            <Outlet />
        </>
    )
};

export default AdminInv;