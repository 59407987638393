import React, {useEffect, useState} from "react";
import "./index.css";
import {useMediaQuery, useTheme} from "@mui/material";
import ResourceCard from "../../../../../modules/ResourceCard";
import {Routes, Route, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {useAuth} from "../../../../../context/AuthContext";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";

interface IResources {
    challengeId: number;
    file?: {
        id: number;
        originalFileName: string;
        fileName: string;
        fileUrl: string;
    } | null;
    fileId: number | null;
    id: number;
    link?: string | null;
    name: string;
    public: boolean;
}

interface IRegistered {
    registered: boolean;
}

const ChallengeRessources = () => {
    const challengeId = useParams<{ id: string }>().id;
    const [resources, setResources] = React.useState<IResources[]>([]);

    const theme = useTheme();
    //width size
    const isbetweenXsAndMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const ismorelg = useMediaQuery(theme.breakpoints.up('lg'));
    const {profile} = useAuth();

    //list resource
    const listResources = async () => {
        await api.get('/challenge/resources',
            {
                params: {
                    challengeId: challengeId
                },
                withCredentials: true
            })
            .then((res) => {
                // console.log(res.data.resources);
                setResources(res.data.resources)
            })
            .catch((err) => {
                console.log(err)
            });
    }
    // const [isRegistered, setIsRegistered] = useState<IRegistered>();
    // const getRegisterdToChallengeById = async () => {
    //     await axios.get(process.env.REACT_APP_API_URL + `/challenge/${challengeId}/registered`,
    //         {
    //             withCredentials: true
    //         }
    //     ).then((res) => {
    //         setIsRegistered(res.data);
    //         console.log(isRegistered);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }
    useEffect(() => {
        listResources();
        // getRegisterdToChallengeById();
    }, []);

    useEffect(() => {
        // console.log("list resources",resources);
    }, [resources]);

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    return (
        <section id="AdminPreviewChallengeRessources"
                 className={`m-auto w-100`}>
            <div className="bloc-title">
                <h2 className="text-center">{t("page.challenge.resources.resources")} {!isbetweenXsAndMd ? t("page.challenge.resources.provided") : ""}</h2>
            </div>
            <div className="bloc-ressources">
                {
                    resources && resources.length > 0 ?
                        resources.map((resource) => {
                            return <ResourceCard key={resource.id} resource={resource}/>
                        })
                        : (<p>{t("page.challenge.resources.noResource")}</p>)
                }
            </div>
        </section>
    );
}

export default ChallengeRessources;