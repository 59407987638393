//Components
import ActionButton from "../../../../components/ActionButton";

//Style
import "./index.css";

//React
import React, {useEffect, useState} from "react";

//Assets
import {ReactComponent as EmptyChallengesImg} from "../../../../assets/imgs/challengeencours-vide.svg";
import {useNavigate} from "react-router-dom";

//Module
import ProjectCard from "../../../../modules/ProjectCard";
import {IProject, IProjectChallenge} from "../../../../modules/ProjectCard/types";
import {Grid} from "@mui/material";

//Libs
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../components/SetUpTranslation";
import api from "../../../../utils/api";

const ParticipantChallenges = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const [projects, setProjects] = useState<IProject[]>([]);
    const [challenges, setChallenges] = useState<IProjectChallenge[]>([]);

    const fetchData = async () => {
        await api.get("/participant/myprojects", {withCredentials: true})
            .then((res) => {
                const registeredChallenges: any[] | ((prevState: IProjectChallenge[]) => IProjectChallenge[]) = [];
                const projects = res.data.reformattedProjects;
                setProjects(projects);

                res.data.registeredChallenges.forEach((challenge: any) => {
                    if (challenge.profiles[0].teams.length === 0) {
                        registeredChallenges.push(challenge);
                    }
                })
                setChallenges(registeredChallenges);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        fetchData();
    }, [])

    const navigate = useNavigate();

    return (
        <div className="participant-challenges">
            <div className={"participant-my-project-content"}>
                {((projects.length === 0 && challenges.length === 0) || (challenges.length !== 0)) && <h1>{t("titleHeader.myChallenges")}</h1>}
                {
                    (projects.length <= 0 && challenges.length === 0) ? (
                        <div className="participant-challenges-empty">
                            <p>{t("page.myProjects.notYetRegistered")}</p>
                            <ActionButton variant="primary" onClick={() => navigate("/")}
                                          className={"button-see-all-challenges"}>
                                {t("page.myProjects.seeAllChallenges")}
                            </ActionButton>
                            <div className="participant-challenges-empty-paragraph">
                                <h1>{t("page.myProjects.title2")}</h1>
                                <p>{t("page.myProjects.registerToChallengeToSeeProject")}</p>
                            </div>
                            <EmptyChallengesImg/>
                        </div>
                    ) : (
                        <>
                            {challenges.length !== 0 &&
                                <>
                                    <Grid container spacing={2} justifyContent={"center"} marginY={"2rem"}>
                                        {
                                            challenges.map((challenge, index: number) => {
                                                return (
                                                    <Grid item sm={12} md={12} lg={6} xl={6} key={index}>
                                                        <ProjectCard challengeRegistered={challenge}/>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </>}
                            {projects.length !== 0 &&
                                <>
                                    <h1>{t("page.myProjects.title")}</h1>
                                    <Grid container spacing={2} justifyContent={"center"} marginY={"2rem"}>
                                        {
                                            projects.map((project, index: number) => {
                                                return (
                                                    <Grid item sm={12} md={12} lg={6} xl={6} key={index}>
                                                        <ProjectCard project={project}/>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </>}
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ParticipantChallenges;
