// react routeur
import {Outlet, useNavigate, useOutletContext, useParams} from "react-router-dom";

// style
import './index.css'

// components
import GoBack from '../../../../../components/GoBack'
import ActionButton from '../../../../../components/ActionButton'
import GoNext from '../../../../../components/GoNext'
import Save from '../../../../../components/Save'

// modules
import OutletNavBar from '../../../../../modules/MenuTabs'
import {Dispatch, SetStateAction, createRef, useEffect, useState} from "react";

// redux
import {RootState} from '../../../../../app/type'
import {useDispatch, useSelector} from "react-redux"


// types
import {IFileInteger, IPrice} from "./types";
import {IPartner} from "./types";
import {
    ICriterium,
    IResource,
    IFile,
    // IStep,
    IStepDeliverable,
    IStepQuestion,
    IResourceError,
    IRegistrationError
} from "src/utils/types";

import {IStep, ITimeSlot} from "./AdminEditChallengeSteps/types";

import React from "react";

// utils
import {generateRandomCriteria, localiseDate} from "../../../../../utils/functions";
import {get} from "https";
import {Alert, Button, Grid, Snackbar, Stack, useMediaQuery, useTheme} from "@mui/material";
import {create, set} from "lodash";
import {type} from "os";
import {count, log} from "console";
import {use} from "i18next";
import Footer from "../../../../../modules/Footer";
import moment from "moment";
import {IErrorResource, IQuestion} from "./types";
import IChallenge from "../types";
import ISubChallenge from "../../../../../components/DareList/types";
import api from "../../../../../utils/api";

//Step
type ContextType = {
    // general
    challenge: IChallenge,
    setChallenge: (challenge: IChallenge) => void,
    challengeOrigin: IChallenge,
    setChallengeOrigin: (challengeOrigin: IChallenge) => void,
    errorFieldGeneral: any,
    setErrorFieldGeneral: (errorFieldGeneral: any) => void,
    //general - Prices
    imagePrices: IFileInteger[];
    setImagePrices: (imagePrices: IFileInteger[]) => void,
    imagePartners: IFileInteger[];
    setImagePartners: (imagePartners: IFileInteger[]) => void,

    //Registration
    regulation: File | undefined,
    setRegulation: Dispatch<SetStateAction<File | undefined>>,

    change: boolean,
    setChange: (change: boolean) => void,
    page: string,
    setPage: (page: string) => void,
    click: boolean,
    setClick: (click: boolean) => void,
    id: number,
    setId: (id: number) => void,
    title: string,
    setTitle: (title: string) => void,
    startDate: string,
    setStartDate: (startDate: string) => void,
    endDate: string,
    setEndDate: (endDate: string) => void,
    shortDescription: string,
    setShortDescription: (shortDescription: string) => void,
    longDescription: string,
    setLongDescription: (longDescription: string) => void,
    isPublic: boolean,
    setIsPublic: (isPublic: boolean) => void,
    img: string,
    setImg: (img: string) => void,
    logo: string,
    setLogo: (logo: string) => void,
    hasDefis: boolean,
    setHasDefis: (hasDefis: boolean) => void,
    hasPrizes: boolean,
    setHasPrizes: (hasPrizes: boolean) => void,
    hasPartners: boolean,
    setHasPartners: (hasPartners: boolean) => void,
    hasFaq: boolean,
    setHasFaq: (hasFaq: boolean) => void,
    imgToUpload: File | undefined,
    logoToUpload: File | undefined,
    setImgToUpload: Dispatch<SetStateAction<File | undefined>>,
    setLogoToUpload: Dispatch<SetStateAction<File | undefined>>,
    prizes: IPrice[],
    setPrizes: Dispatch<SetStateAction<IPrice[]>>,
    partners: IPartner[],
    setPartners: Dispatch<SetStateAction<IPartner[]>>,
    questions: IQuestion[],
    setQuestions: Dispatch<SetStateAction<IQuestion[]>>,
    dares: ISubChallenge[],
    setDares: Dispatch<SetStateAction<ISubChallenge[]>>,
    prizesToDelete: IPrice[],
    setPrizesToDelete: Dispatch<SetStateAction<IPrice[]>>,
    partnersToDelete: IPartner[],
    setPartnersToDelete: Dispatch<SetStateAction<IPartner[]>>,
    questionsToDelete: [],
    setQuestionsToDelete: Dispatch<SetStateAction<IQuestion[]>>,
    daresToDelete: ISubChallenge[],
    setDaresToDelete: Dispatch<SetStateAction<ISubChallenge[]>>
    filesToDelete: string[],
    setFilesToDelete: Dispatch<SetStateAction<string[]>>,
    handleDeleteLogo: () => void,
    handleDeleteImg: () => void,

    // sign-up
    registrationStartDate: string,
    setRegistrationStartDate: (registrationStartDate: string) => void,
    registrationEndDate: string,
    setRegistrationEndDate: (registrationEndDate: string) => void,
    totalParticipants: number,
    setTotalParticipants: (totalParticipants: number) => void,
    typeParticipation: string,
    setTypeParticipation: (typeParticipation: string) => void,
    nbMinParticipants: number,
    setNbMinParticipants: (nbMinParticipants: number) => void,
    nbMaxParticipants: number,
    setNbMaxParticipants: (nbMaxParticipants: number) => void,
    eligibilities: any,
    setEligibilities: (eligibilities: any) => void,
    rules: File | undefined,
    setRules: Dispatch<SetStateAction<File | undefined>>,
    linkRules: string,
    setLinkRules: (rulesLink: string) => void,
    fileName: string,
    setFileName: (fileName: string) => void,
    fileNameStorage: string,
    setFileNameStorage: (fileNameStorage: string) => void,
    fileToDelete: string,
    setFileToDelete: (fileToDelete: string) => void,
    error?: IRegistrationError | null,
    setError: (error: IRegistrationError | null) => void,

    // ressources
    resources: IResource[],
    setResources: Dispatch<SetStateAction<IResource[]>>,
    errorResource: any[],
    setErrorResource: Dispatch<SetStateAction<any[]>>,
    currentResources: IResource[],
    setCurrentResources: Dispatch<SetStateAction<IResource[]>>,
    resourcesToDelete: IResource[],
    setResourcesToDelete: Dispatch<SetStateAction<IResource[]>>,
    fetchResources: () => void,
    //SnackBar
    openSnackBarResource: boolean,
    setOpenSnackBarResource: (openSnackBarResource: boolean) => void,
    textSnackBarResource: string,
    setTextSnackBarResource: (textSnackBarResource: string) => void,
    variantSnackBarResource: "error" | "success",
    setVariantSnackBarResource: (variantSnackBarResource: "error" | "success") => void,

    getOneChallenge: (id: string) => void,

    openSnackBarEditChallenge: boolean,
    setOpenSnackBarEditChallenge: (openSnackBarEditChallenge: boolean) => void,
    variantSnackEditChallenge: "error" | "success",
    setVariantSnackBarEditChallenge: (variantSnackEditChallenge: "error" | "success") => void,
    textSnackBarEditChallenge: string,
    setTextSnackBarEditChallenge: (textSnackBarEditChallenge: string) => void,

    //Registrations
    openSnackBarEditRegistration: boolean,
    setOpenSnackBarEditRegistration: (openSnackBarEditRegistration: boolean) => void,
    variantSnackEditRegistration: "error" | "success",
    setVariantSnackBarEditRegistration: (variantSnackEditRegistration: "error" | "success") => void,
    textSnackBarEditRegistration: string,
    setTextSnackBarEditRegistration: (textSnackBarEditRegistration: string) => void,

    // ressources: [],
    // setRessources: (ressources: []) => void,
    // ressourcesToDelete: string[],
    // setRessourcesToDelete: (ressourcesToDelete: string[]) => void,
    // addRessources: () => void,
    // getAllRessources: () => void,
    // deleteRessources: () => void,
    // updateRessources: () => void,
    // newRessources: [],
    // setNewRessources: (newRessources: []) => void,
    // currentRessources: any[],
    // setCurrentRessources: (currentRessources: any[]) => void,
    // ressourcesToUpdate: any[]
    // setRessourcesToUpdate: (ressourcesToTupdate: any[]) => void,
    // ressourceFile: File | null | undefined,
    // setRessourceFile: Dispatch<SetStateAction<File | null | undefined>>,
    // ressourceFileName: string,
    // setRessourceFileName: (ressourceFileName: string) => void,
    // ressourceFileNameStorage: string,
    // setRessourceFileNameStorage: (ressourceFileNameStorage: string) => void,
    // ressourceFileToDelete: string,
    // setRessourceFileToDelete: (ressourceFileToDelete: string) => void,

    // steps: IStep[],
    listSteps: IStep[],
    setListSteps: Dispatch<SetStateAction<IStep[] | []>>,
    originalSteps: IStep[] | [],
    setOriginalSteps: Dispatch<SetStateAction<IStep[] | []>>,
    updateListSteps: IStep[] | [],
    setUpdateListSteps: Dispatch<SetStateAction<IStep[] | []>>,
    newStep: IStep[] | [],
    setNewStep: Dispatch<SetStateAction<IStep[] | []>>,
    stepToDelete: IStep[] | [],
    setStepToDelete: Dispatch<SetStateAction<IStep[] | []>>,
    timeSlotError: ITimeSlot[],
    setTimeSlotError: Dispatch<SetStateAction<ITimeSlot[]>>,
    errorStep: any | any[],
    setErrorStep: Dispatch<SetStateAction<any | any[]>>,
    // setSteps: Dispatch<SetStateAction<IStep[]>>,
    // currentSteps: IStep[],
    // setCurrentSteps: Dispatch<SetStateAction<IStep[]>>,
    // stepsToDelete: IStep[],
    // setStepsToDelete: Dispatch<SetStateAction<IStep[]>>,

    // // question
    // questionsStep: IStepQuestion[],
    // setQuestionsStep: Dispatch<SetStateAction<IStepQuestion[]>>,
    // questionsStepToDelete: (string | number | null)[],
    // setQuestionsStepToDelete: Dispatch<SetStateAction<(string | number | null)[]>>,

    // //deliverable
    // deliverablesStep: IStepDeliverable[],
    // setDeliverablesStep: Dispatch<SetStateAction<IStepDeliverable[]>>,
    // deliverablesStepToDelete: (string | number | null)[],
    // setDeliverablesStepToDelete: Dispatch<SetStateAction<(string | number | null)[]>>,

    // criteria
    criteria: ICriterium[],
    setCriteria: Dispatch<SetStateAction<ICriterium[]>>,
    currentCriteria: ICriterium[],
    setCurrentCriteria: Dispatch<SetStateAction<ICriterium[]>>,
    criteriaToDelete: ICriterium[],
    setCriteriaToDelete: Dispatch<SetStateAction<ICriterium[]>>,
    fetchCriteria: () => void,
    // others
    hasClickedOnCancelButton: boolean,
    truc: boolean,
    setTruc: (truc: boolean) => void,
    truc2: boolean,
    setTruc2: (truc: boolean) => void,
};

const AdminEditChallenge = () => {

    // navigation
    const navigate = useNavigate();

    // params
    const {name} = useParams();
    //Edit general
    const [challenge, setChallenge] = useState<IChallenge>();
    const [challengeOrigin, setChallengeOrigin] = useState<IChallenge>();

    const [openSnackBarEditChallenge, setOpenSnackBarEditChallenge] = useState<boolean>(false);
    const [variantSnackEditChallenge, setVariantSnackBarEditChallenge] = useState<"error" | "success">("success");
    const [textSnackBarEditChallenge, setTextSnackBarEditChallenge] = useState<string>("");

    //registration
    const [openSnackBarEditRegistration, setOpenSnackBarEditRegistration] = useState<boolean>(false);
    const [variantSnackEditRegistration, setVariantSnackBarEditRegistration] = useState<"error" | "success">("success");
    const [textSnackBarEditRegistration, setTextSnackBarEditRegistration] = useState<string>("");

    const [nav, setNav] = useState<string>('');
    const id = useParams().id;
    // state - general
    const [change, setChange] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [click, setClick] = useState<boolean>(false);
    const [nextClick, setNextClick] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [shortDescription, setShortDescription] = useState<string>('');
    const [longDescription, setLongDescription] = useState<string>('');
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [img, setImg] = useState<string>("");
    const [logo, setLogo] = useState<string>("");
    const [hasDefis, setHasDefis] = useState<boolean>(false);
    const [hasPrizes, setHasPrizes] = useState<boolean>(false);
    const [hasPartners, setHasPartners] = useState<boolean>(false);
    const [hasFaq, setHasFaq] = useState<boolean>(false);
    const [imgToUpload, setImgToUpload] = useState<File | undefined>();
    const [logoToUpload, setLogoToUpload] = useState<File | undefined>();
    // ajouter img ou logo en fonction de ce que je veux supprimer ex: ['img', 'logo']
    const [filesToDelete, setFilesToDelete] = useState([] as string[]);

    const [prizes, setPrizes] = useState<IPrice[]>([]);
    const [currentPrizes, setCurrentPrizes] = useState(prizes);
    const [prizesToDelete, setPrizesToDelete] = useState<IPrice[]>([]);

    const [partners, setPartners] = useState<IPartner[]>([]);
    const [currentPartners, setCurrentPartners] = useState(partners);
    const [partnersToDelete, setPartnersToDelete] = useState<IPartner[]>([]);

    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [currentQuestions, setCurrentQuestions] = useState(questions);
    const [questionsToDelete, setQuestionsToDelete] = useState<IQuestion[]>([]);

    const [dares, setDares] = useState<ISubChallenge[]>([]);
    const [currentDares, setCurrentDares] = useState(dares);
    const [daresToDelete, setDaresToDelete] = useState<ISubChallenge[]>([]);

    const [steps, setSteps] = useState<IStep[]>([]);
    const [currentSteps, setCurrentSteps] = useState(steps);
    const [stepsToDelete, setStepsToDelete] = useState<IStep[]>([]);

    //question
    const [questionsStep, setQuestionsStep] = useState<IStepQuestion[]>([]);
    const [questionsStepToDelete, setQuestionsStepToDelete] = useState<(string | number | null)[]>([]);

    //deliverable
    const [deliverablesStep, setDeliverablesStep] = useState<IStepDeliverable[]>([]);
    const [deliverablesStepToDelete, setDeliverablesStepToDelete] = useState<(string | number | null)[]>([]);

    // state - sign-up
    const [registrationStartDate, setRegistrationStartDate] = useState<string>('');
    const [registrationEndDate, setRegistrationEndDate] = useState<string>('');
    const [totalParticipants, setTotalParticipants] = useState<number>(0);
    const [typeParticipation, setTypeParticipation] = useState<string>('team');
    const [nbMinParticipants, setNbMinParticipants] = useState<number>(0);
    const [nbMaxParticipants, setNbMaxParticipants] = useState<number>(0);
    const [eligibilities, setEligibilities] = useState<any[]>([]);
    const [rules, setRules] = useState<File | undefined>();
    const [linkRules, setLinkRules] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [fileNameStorage, setFileNameStorage] = useState<string>('');
    const [fileToDelete, setFileToDelete] = useState<string | null>(null);
    const [error, setError] = useState<IRegistrationError | null>(null);
    // ressources
    const [resources, setResources] = useState<IResource[]>([]);
    const [errorResource, setErrorResource] = useState<IErrorResource[]>([]);
    const [currentResources, setCurrentResources] = useState(resources);
    const [resourcesToDelete, setResourcesToDelete] = useState<IResource[]>([]);
    //SnackBar
    const [openSnackBarResource, setOpenSnackBarResource] = useState<boolean>(false);
    const [textSnackBarResource, setTextSnackBarResource] = useState<string>("");
    const [variantSnackBarResource, setVariantSnackBarResource] = useState<"error" | "success">("success");
    // const [filesResource, setFilesResource] = useState<IFile>();
    // const [filesResourceToDelete, setFilesResourceToDelete] = useState<(string | number | null)[]>([]);

    // const [newRessources, setNewRessources] = useState<Array<any>>([]);
    // const [ressourcesToUpdate, setRessourcesToUpdate] = useState<any>([]);
    // const [ressources, setRessources] = useState<any>([])
    // const [currentRessources, setCurrentRessources] = useState<any>([])
    // const [updatedRessources, setUpdatedRessources] = useState<any>([])
    // const [ressourceFileName, setRessourceFileName] = useState<string>('');
    // const [ressourceFileNameStorage, setRessourceFileNameStorage] = useState<string>('');
    // const [ressourceFileToDelete, setRessourceFileToDelete] = useState<string | null>(null);
    // const [ressourceFile, setRessourceFile] = useState<File | null | undefined>();

    // criteria
    const [criteria, setCriteria] = useState<ICriterium[]>([]);
    const [currentCriteria, setCurrentCriteria] = useState(criteria);
    const [criteriaToDelete, setCriteriaToDelete] = useState<ICriterium[]>([]);

    // redux
    // const getOneChallenge = useSelector((action: RootState) => action.challenges.getOneChallenge);
    // const challengeId = useSelector((state: RootState) => state.challenges.getOneChallenge?.id);

    //Step
    const [listSteps, setListSteps] = useState<IStep[]>([]);
    const [originalSteps, setOriginalSteps] = useState<IStep[]>([]);
    const [updateListSteps, setUpdateListSteps] = useState<IStep[]>([]);
    const [newStep, setNewStep] = useState<IStep[]>([]);
    const [stepToDelete, setStepToDelete] = useState<IStep[]>([]);
    const [timeSlotError, setTimeSlotError] = useState<ITimeSlot[]>([]);
    const [errorStep, setErrorStep] = useState<any | any[]>();

    // links
    const outletNavbarLinks = [
        {
            label: 'Général',
            key: 'general',
            onClick: () => {
                if (change) {
                    setNav('/admin/challenges/edit/general/')
                    setClick(true);
                } else {
                    setPage('general');
                    navigate('/admin/challenges/edit/general/' + (id || ''));
                }
            },
            link: `/admin/challenges/edit/general/${id}`
        },
        {
            label: 'Inscriptions',
            key: 'sign-up',
            onClick: () => {
                if (change) {
                    setNav('/admin/challenges/edit/sign-up/')
                    setClick(true);
                } else {
                    setPage('sign-up');
                    navigate('/admin/challenges/edit/sign-up/' + (id || ''));
                }
            },
            link: `/admin/challenges/edit/sign-up/${id}`
        },
        {
            label: 'Étapes',
            key: 'steps',
            onClick: () => {
                if (change) {
                    setNav('/admin/challenges/edit/steps/')
                    setClick(true);
                } else {
                    setPage('steps');
                    navigate('/admin/challenges/edit/steps/' + (id || ''));
                }
            },
            link: `/admin/challenges/edit/steps/${id}`
        },
        {
            label: 'Ressources',
            key: 'ressources',
            onClick: () => {
                if (change) {
                    setNav('/admin/challenges/edit/ressources/')
                    setClick(true);
                } else {
                    setPage('ressources');
                    navigate('/admin/challenges/edit/ressources/' + (name || ''));
                }
            },
            link: `/admin/challenges/edit/ressources/${id}`
        },
        {
            label: 'Critères',
            key: 'criteria',
            onClick: () => {
                if (change) {
                    setNav('/admin/challenges/edit/criteria/')
                    setClick(true);
                } else {
                    setPage('criteria');
                    navigate('/admin/challenges/edit/criteria/' + (id || ''));
                }
            },
            link: `/admin/challenges/edit/criteria/${id}`
        }
    ];

    //Général
    const getOneChallenge = async (id: string) => {
        await api.get('/challenge/preview', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                res.data.challenge = {
                    ...res.data.challenge,
                    prizes: res.data.challenge.prizes.map((prize: any) => {
                        return {
                            ...prize,
                            id: prize.id,
                            image: prize.prizePicture,
                            ref: createRef<HTMLImageElement>(),
                            position: prize.position
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    questions: res.data.challenge.itemFAQs.map((question: any) => {
                        return {
                            ...question,
                            id: question.id,
                            challengeId: res.data.challenge.id,
                            ref: createRef<HTMLImageElement>()
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    subChallenges: res.data.challenge.subChallenges.sort((a: any, b: any) => a.position - b.position),
                    partners: res.data.challenge.partners.map((partner: any) => {
                        return {
                            ...partner,
                            id: partner.id,
                            name: partner.name,
                            link: partner.partnerLink,
                            image: partner.partnerLogo,
                            ref: createRef<HTMLImageElement>(),
                            challengeId: res.data.challenge.id,
                            position: partner.position
                        }
                    }).sort((a: any, b: any) => a.position - b.position),

                }
                setChallenge(res.data.challenge)
                setChallengeOrigin(res.data.challenge)
                console.log(res.data.challenge)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const findChallenge = (id: string) => {
        api.get('/challenge/preview', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                res.data.challenge = {
                    ...res.data.challenge,
                    prizes: res.data.challenge.prizes.map((prize: any) => {
                        return {
                            ...prize,
                            id: prize.id,
                            image: prize.prizePicture,
                            ref: createRef<HTMLImageElement>(),
                            position: prize.position
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    questions: res.data.challenge.itemFAQs.map((question: any) => {
                        return {
                            ...question,
                            id: question.id,
                            challengeId: res.data.challenge.id,
                            ref: createRef<HTMLImageElement>()
                        }
                    }).sort((a: any, b: any) => a.position - b.position),
                    subChallenges: res.data.challenge.subChallenges.sort((a: any, b: any) => a.position - b.position),
                    partners: res.data.challenge.partners.map((partner: any) => {
                        return {
                            ...partner,
                            id: partner.id,
                            name: partner.name,
                            link: partner.partnerLink,
                            image: partner.partnerLogo,
                            ref: createRef<HTMLImageElement>(),
                            challengeId: res.data.challenge.id,
                            position: partner.position
                        }
                    }).sort((a: any, b: any) => a.position - b.position),

                }

                console.log(res.data.challenge)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchCriteria = async () => {
        await api.get("/admin/challenge/criterias",
            {
                params: {
                    challengeId: id
                },
                withCredentials: true
            }
        ).then((res) => {
            const criteriaFetched: ICriterium[] = res.data.map((criterium: any) => {
                return {
                    id: criterium.id,
                    name: criterium.name,
                    description: criterium.description,
                    valuePoints: parseInt(criterium.grade),
                    challengeId: criterium.challengeId
                }
            });
            setCriteria(criteriaFetched);
            setCurrentCriteria(criteriaFetched);
        }).catch((err) => {
            console.log(err);
        })
    }

    const dispatch = useDispatch();

    // useEffect(() => {
    //     let hasFile: boolean = false;
    //     prizes.forEach((prize) => {
    //         // set change to true if file has changed
    //         // if (prize.file) {
    //         //     setChange(true);
    //         //     hasFile = true;
    //         // }
    //     })
    //     partners.forEach((partner) => {
    //         if (partner.file) {
    //             setChange(true);
    //             hasFile = true;
    //         }
    //     })
    //     if (hasFile) return;
    //     if (JSON.stringify(prizes) !== JSON.stringify(currentPrizes) || JSON.stringify(partners) !== JSON.stringify(currentPartners) || JSON.stringify(dares) !== JSON.stringify(currentDares) || JSON.stringify(questions) !== JSON.stringify(currentQuestions)) {
    //         setChange(true);
    //     }
    // }, [prizes, dares, partners, questions]) //TODO: add currentPrizes, currentPartners, currentDares, currentQuestions

    // const handleUpdatePrizesToDatabase = async () => {
    //     console.log("looking for prizes update...")

    //     prizesToDelete.forEach(async (prize) => {
    //         console.log("found a prize to delete with id ", prize.id);
    //         await axios.delete(
    //             process.env.REACT_APP_API_URL + "/admin/challenge/prize/delete",
    //             {
    //                 data: {
    //                     prizeId: prize.id,
    //                     challengeId: id
    //                 },
    //                 withCredentials: true
    //             }
    //         ).then(() => {
    //             console.log("prize with id ", prize.id, " successfully deleted")
    //         }).catch((err) => {
    //             console.log(err);
    //         });
    //     });

    //     prizes.forEach(async (prize) => {
    //         if (prize.isNew) {
    //             console.log("found a prize to update with id ", prize.id)
    //             const formdata = new FormData();
    //             if (prize.file) formdata.append("file", prize.file)
    //             formdata.append("prizeId", prize.id.toString());
    //             formdata.append("name", prize.name);
    //             formdata.append("description", prize.description);
    //             formdata.append("position", prize.position.toString());
    //             formdata.append("challengeId", String(id));
    //             await axios.put(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/prize/update",
    //                 formdata,
    //                 {withCredentials: true}
    //             ).then((res) => {
    //                 console.log("prize with id ", prize.id, " successfully updated");
    //             }).catch((err) => console.log(err))
    //         } else {
    //             currentPrizes.forEach(async (currentPrize) => {
    //                 console.log(prize.id, currentPrize.id)
    //                 if (prize.id === currentPrize.id) {
    //                     if (prize.name !== currentPrize.name || prize.description !== currentPrize.description || prize.position !== currentPrize.position || prize.image !== currentPrize.image || prize.file !== currentPrize.file) {
    //                         console.log("found a prize to update with id ", prize.id)
    //                         const formdata = new FormData();
    //                         if (prize.file) formdata.append("file", prize.file)
    //                         formdata.append("prizeId", prize.id.toString());
    //                         formdata.append("name", prize.name);
    //                         formdata.append("description", prize.description);
    //                         formdata.append("position", prize.position.toString());
    //                         formdata.append("challengeId", String(id));
    //                         await axios.put(
    //                             process.env.REACT_APP_API_URL + "/admin/challenge/prize/update",
    //                             formdata,
    //                             {
    //                                 withCredentials: true
    //                             }
    //                         ).then((res) => {
    //                             console.log("prize with id ", prize.id, " successfully updated");
    //                         }).catch((err) => {
    //                             console.log(err)
    //                         });
    //                     }
    //                 }
    //             })
    //         }
    //     });
    //     console.log("all prizes have been updated !")
    // }

    // const handleUpdatePartnersToDatabase = () => {
    //     console.log("fetching for partners update...")

    //     partnersToDelete.forEach(async (partner) => {
    //         console.log("found a partner to delete with id : ", partner.id)
    //         await axios.put(
    //             process.env.REACT_APP_API_URL + "/admin/challenge/partner/delete",
    //             {
    //                 challengeId: id,
    //                 partnerId: partner.id
    //             },
    //             {
    //                 withCredentials: true
    //             }
    //         ).then((res) => {
    //             console.log("partner with id ", partner.id, " successfully deleted")
    //         }).catch((err) => {
    //             console.log(err);
    //         })
    //     });

    //     partners.forEach(async (partner) => {
    //         if (partner.isNew) {
    //             console.log("found a partner to update with id : ", partner.id)
    //             const formdata = new FormData();
    //             if (partner.file) formdata.append("file", partner.file)
    //             formdata.append("challengeId", String(id));
    //             formdata.append("partnerId", partner.id.toString());
    //             formdata.append("name", partner.name);
    //             if (partner.link) formdata.append("partnerLink", partner.link);
    //             formdata.append("position", partner.position.toString());
    //             await axios.put(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/partner/update",
    //                 formdata,
    //                 {
    //                     withCredentials: true
    //                 }
    //             ).then((res) => {
    //                 console.log("partner with id ", partner.id, " successfully updated")
    //             }).catch((err) => {
    //                 console.log(err)
    //             });
    //         } else {
    //             currentPartners.forEach(async (currentPartner) => {
    //                 if (partner.id === currentPartner.id) {
    //                     if (partner.name !== currentPartner.name || partner.link !== currentPartner.link || partner.position !== currentPartner.position || partner.image !== currentPartner.image || partner.file !== currentPartner.file) {
    //                         console.log("found a partner to update with id : ", partner.id)
    //                         const formdata = new FormData();
    //                         if (partner.file) formdata.append("file", partner.file)
    //                         formdata.append("challengeId", String(id));
    //                         formdata.append("partnerId", partner.id.toString());
    //                         formdata.append("name", partner.name);
    //                         if (partner.link) formdata.append("partnerLink", partner.link);
    //                         formdata.append("position", partner.position.toString());
    //                         await axios.put(
    //                             process.env.REACT_APP_API_URL + "/admin/challenge/partner/update",
    //                             formdata,
    //                             {
    //                                 withCredentials: true
    //                             }
    //                         ).then((res) => {
    //                             console.log("partner with id ", partner.id, " successfully updated")
    //                         }).catch((err) => {
    //                             console.log(err)
    //                         });
    //                     }
    //                 }
    //             })
    //         }
    //     });
    //     console.log("all partners have been updated !")
    // }

    // const handleUpdateQuestionsToDatabase = async () => {
    //     console.log("fetching for questions update...")

    //     questionsToDelete.forEach(async (question) => {
    //         console.log("found a question to delete with id : ", question.id)
    //         await axios.put(
    //             process.env.REACT_APP_API_URL + "/admin/challenge/itemfaq/delete",
    //             {
    //                 challengeId: id,
    //                 itemfaq_id: question.id,
    //             },
    //             {
    //                 withCredentials: true
    //             }
    //         ).then((res) => {
    //             console.log("question with id ", question.id, " successfully deleted")
    //         }).catch((err) => {
    //             console.log(err);
    //         });
    //     });

    //     questions.forEach(async (question) => {
    //         if (question.isNew) {
    //             console.log("found a question to update with id : ", question.id)
    //             await axios.put(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/itemfaq/update",
    //                 {
    //                     itemfaqId: question.id,
    //                     question: question.question,
    //                     answer: question.answer,
    //                     position: question.position,
    //                     challengeId: id
    //                 },
    //                 {
    //                     withCredentials: true
    //                 }
    //             ).then((res) => {
    //                 console.log("question with id ", question.id, " successfully updated")
    //             }).catch((err) => {
    //                 console.log(err)
    //             });
    //         } else {
    //             currentQuestions.forEach(async (currentQuestion) => {
    //                 if (question.id === currentQuestion.id) {
    //                     if (question.question !== currentQuestion.question || question.answer !== currentQuestion.answer || question.position !== currentQuestion.position) {
    //                         console.log("found a question to update with id : ", question.id)
    //                         await axios.put(
    //                             process.env.REACT_APP_API_URL + "/admin/challenge/itemfaq/update",
    //                             {
    //                                 itemfaqId: question.id,
    //                                 question: question.question,
    //                                 answer: question.answer,
    //                                 position: question.position,
    //                                 challengeId: id
    //                             },
    //                             {
    //                                 withCredentials: true
    //                             }
    //                         ).then((res) => {
    //                             console.log("question with id ", question.id, " successfully updated")
    //                         }).catch((err) => {
    //                             console.log(err)
    //                         });
    //                     }
    //                 }
    //             })
    //         }
    //     });

    //     console.log("all questions have been updated !")
    // }

    // const handleUpdateDaresToDatabase = () => {
    //     console.log("fetching for dares update...")
    //     daresToDelete.forEach(async (dare) => {
    //         console.log("found a dare to delete with id : ", dare.id)
    //         await axios.put(
    //             process.env.REACT_APP_API_URL + "/admin/challenge/subchallenge/delete",
    //             {
    //                 challengeId: id,
    //                 subchallengeId: dare.id
    //             },
    //             {
    //                 withCredentials: true
    //             }
    //         ).then(() => {
    //             console.log("dare with id ", dare.id, " successfully deleted")
    //         }).catch((err) => {
    //             console.log(err);
    //         })
    //     });
    //     dares.forEach(async (dare) => {
    //         if (dare.isNew) {
    //             console.log("found a dare to update with id : ", dare.id)
    //             await axios.put(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/subchallenge/update",
    //                 {
    //                     challengeId: id,
    //                     subchallengeId: dare.id,
    //                     name: dare.name,
    //                     description: dare.description,
    //                     position: dare.position
    //                 },
    //                 {
    //                     withCredentials: true
    //                 }
    //             ).then((res) => {
    //                 console.log("dare with id ", dare.id, " successfully updated")
    //             }).catch((err) => {
    //                 console.log(err)
    //             });
    //         } else {
    //             currentDares.forEach(async (currentDare) => {
    //                 if (dare.id === currentDare.id) {
    //                     if (dare.name !== currentDare.name || dare.description !== currentDare.description || dare.position !== currentDare.position) {
    //                         console.log("found a dare to update with id : ", dare.id)
    //                         await axios.put(
    //                             process.env.REACT_APP_API_URL + "/admin/challenge/subchallenge/update",
    //                             {
    //                                 challengeId: id,
    //                                 subchallengeId: dare.id,
    //                                 name: dare.name,
    //                                 description: dare.description,
    //                                 position: dare.position
    //                             },
    //                             {
    //                                 withCredentials: true
    //                             }
    //                         ).then((res) => {
    //                             console.log("dare with id ", dare.id, " successfully updated")
    //                         }).catch((err) => {
    //                             console.log(err)
    //                         });
    //                     }
    //                 }
    //             })
    //         }
    //     });

    //     console.log("all dares have been updated !")
    // }

    // const handleDeleteNewItems = () => {
    //     console.log("checking if new items wanna be deleted...")
    //     prizes.forEach(async (prize) => {
    //         if (prize.isNew) {
    //             await axios.delete(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/prize/delete",
    //                 {
    //                     data: {
    //                         prizeId: prize.id,
    //                         challengeId: id
    //                     },
    //                     withCredentials: true
    //                 }
    //             ).catch((err) => {
    //                 console.log(err);
    //             });
    //         }
    //     })
    //     partners.forEach(async (partner) => {
    //         if (partner.isNew) {
    //             await axios.put(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/partner/delete",
    //                 {
    //                     challengeId: id,
    //                     partnerId: partner.id
    //                 },
    //                 {
    //                     withCredentials: true
    //                 }
    //             ).catch((err) => {
    //                 console.log(err);
    //             })
    //         }
    //     })
    //     questions.forEach(async (question) => {
    //         if (question.isNew) {
    //             await axios.put(
    //                 process.env.REACT_APP_API_URL + "/admin/challenge/itemfaq/update",
    //                 {
    //                     itemfaqId: question.id,
    //                     question: question.question,
    //                     answer: question.answer,
    //                     position: question.position,
    //                     challengeId: id
    //                 },
    //                 {
    //                     withCredentials: true
    //                 }
    //             ).catch((err) => {
    //                 console.log(err)
    //             });
    //         }
    //     })
    //     // dares.forEach(async (dare) => {
    //     //     if (dare.isNew) {
    //     //         await axios.put(
    //     //             process.env.REACT_APP_API_URL + "/admin/challenge/subchallenge/delete",
    //     //             {
    //     //                 challengeId: id,
    //     //                 subchallengeId: dare.id
    //     //             },
    //     //             {
    //     //                 withCredentials: true
    //     //             }
    //     //         ).catch((err) => {
    //     //             console.log(err);
    //     //         })
    //     //     }
    //     // })
    //     console.log("new items have been deleted !")
    // }

    //Créer un tableau d'imagePrices
    const [imagePrices, setImagePrices] = useState<{ id: number | string; file: File }[]>([]);
    const [imagePartners, setImagePartners] = useState<{ id: number | string; file: File }[]>([]);

    //Créer un tableau d'erreur lié à au fild du challenge
    const [errorFieldGeneral, setErrorFieldGeneral] = useState<any[]>([]);

    const updateChallenge = async () => {
        const deletedSubChallengeId: number[] = [];
        const deletedPrizeId: number[] = [];
        const deletedPartnerId: number[] = [];
        const deletedItemFaqId: number[] = [];

        const formData = new FormData();
        console.log("errorFieldGeneral avant envoie ----------", errorFieldGeneral);

        if (errorFieldGeneral && errorFieldGeneral.length > 0) {
            setOpenSnackBarEditChallenge(true);
            setVariantSnackBarEditChallenge("error");
            setTextSnackBarEditChallenge("Une erreur est survenue");
            return;
        }

        let copyErrorFieldGeneral: any[] = [];

        if (challenge) {
            //Check name
            if (challenge.name && challenge.name !== "") {
                formData.append("name", challenge.name);
            } else {
                copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                    code: "name",
                    message: "Le nom du challenge est obligatoire"
                }];
            }

            //Check startDate
            if (challenge.startDate && moment.isMoment(moment(challenge.startDate))) {
                formData.append("startDate", challenge.startDate);
            } else {
                copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                    code: "startDate",
                    message: "La date de début du challenge est obligatoire"
                }];
            }

            //Check endDate
            if (challenge.endDate && moment.isMoment(moment(challenge.endDate))) {
                formData.append("endDate", challenge.endDate);
            } else {
                copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                    code: "endDate",
                    message: "La date de fin du challenge est obligatoire"
                }];
            }

            //Check if startDate < endDate
            if (challenge.startDate && challenge.endDate && moment.isMoment(moment(challenge.startDate)) && moment.isMoment(moment(challenge.endDate))) {
                if (moment(challenge.startDate).isAfter(moment(challenge.endDate))) {
                    copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                        code: "startDate",
                        message: "La date de début du challenge doit être avant la date de fin du challenge"
                    }];
                    copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                        code: "endDate",
                        message: "La date de fin du challenge doit être après la date de début du challenge"
                    }];
                }
            }

            //IF challenge.hasDefis est false et qu'il y a des challenge.SubChallenges alors stupprimer les challenge.SubChallenges de chalenge
            if (!challenge.hasDefis && challenge.subChallenges && challenge.subChallenges.length > 0) {
                challenge.subChallenges = [];
            }

            //If hasPrice est false et qu'il y a des challenge.Prizes alors supprimer les challenge.Prizes de challenge
            if (!challenge.hasPrizes && challenge.prizes && challenge.prizes.length > 0) {
                challenge.prizes = [];
            }

            //If hasPartners est false et qu'il y a des challenge.Partners alors supprimer les challenge.Partners de challenge
            if (!challenge.hasPartners && challenge.partners && challenge.partners.length > 0) {
                challenge.partners = [];
            }

            //If hasFAQ est false et qu'il y a des challenge.ItemFAQs alors supprimer les challenge.ItemFAQs de challenge
            if (!challenge.hasFaq && challenge.itemFAQs && challenge.itemFAQs.length > 0) {
                challenge.itemFAQs = [];
            }

            //If Pas de defis alors challenge.hasDefis est false
            if (!challenge.subChallenges || challenge.subChallenges.length === 0) {
                challenge.hasDefis = false;
            }

            //If Pas de prix alors challenge.hasPrizes est false
            if (!challenge.prizes || challenge.prizes.length === 0) {
                challenge.hasPrizes = false;
            }

            //If Pas de partenaires alors challenge.hasPartners est false
            if (!challenge.partners || challenge.partners.length === 0) {
                challenge.hasPartners = false;
            }

            //If Pas de FAQ alors challenge.hasFaq est false
            if (!challenge.itemFAQs || challenge.itemFAQs.length === 0) {
                challenge.hasFaq = false;
            }

            //If challenge.hasDefis est true et qu'il y a qu'un challenge.SubChallenge alors error car dans ce cas il doit obligatoirement y avoir 2 challenge.SubChallenges
            if (challenge.hasDefis && challenge.subChallenges && challenge.subChallenges.length === 1) {
                copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                    code: "subChallenges",
                    message: "Il doit y avoir au moins 2 défis"
                }];
            }

            //SubChallenge à supprimer
            if (challengeOrigin && challengeOrigin.subChallenges && challenge.subChallenges) {
                challengeOrigin.subChallenges.forEach((subChallengeOrigin) => {
                    if (!challenge.subChallenges.find((subChallenge) => subChallenge.id === subChallengeOrigin.id)) {
                        deletedSubChallengeId.push(Number(subChallengeOrigin.id));
                    }
                });
            }

            //Prize à supprimer
            if (challengeOrigin && challengeOrigin.prizes && challenge.prizes) {
                challengeOrigin.prizes.forEach((prizeOrigin) => {
                    if (!challenge.prizes.find((prize) => prize.id === prizeOrigin.id)) {
                        deletedPrizeId.push(Number(prizeOrigin.id));
                    }
                });
            }

            //Partner à supprimer
            if (challengeOrigin && challengeOrigin.partners && challenge.partners) {
                challengeOrigin.partners.forEach((partnerOrigin) => {
                    if (!challenge.partners.find((partner) => partner.id === partnerOrigin.id)) {
                        deletedPartnerId.push(Number(partnerOrigin.id));
                    }
                });
            }

            //IQuestion à supprimer
            if (challengeOrigin && challengeOrigin.itemFAQs && challenge.itemFAQs) {
                challengeOrigin.itemFAQs.forEach((itemFaqOrigin) => {
                    if (!challenge.itemFAQs.find((itemFaq) => itemFaq.id === itemFaqOrigin.id)) {
                        deletedItemFaqId.push(Number(itemFaqOrigin.id));
                    }
                });
            }
            if (challenge.subChallenges.length === 0) {
                formData.append("hasDefis", String(false));
            } else {
                formData.append("hasDefis", String(challenge.hasDefis));
            }

            if (challenge.prizes.length === 0) {
                formData.append("hasPrizes", String(false));
            } else {
                formData.append("hasPrizes", String(challenge.hasPrizes));
            }

            if (challenge.partners.length === 0) {
                formData.append("hasPartners", String(false));
            } else {
                formData.append("hasPartners", String(challenge.hasPartners));
            }

            if (challenge.itemFAQs.length === 0) {
                formData.append("hasFaq", String(false));
            } else {
                formData.append("hasFaq", String(challenge.hasFaq));
            }
            formData.append("public", String(challenge.public));

            if (challenge.shortDescription) formData.append("shortDescription", challenge.shortDescription);
            if (challenge.longDescription) formData.append("longDescription", challenge.longDescription);

            formData.append("startDate", challenge.startDate);
            formData.append("endDate", challenge.endDate);

            //Ajouter le tableau de subChallenge
            if (challenge.subChallenges && challenge.subChallenges.length > 0) {
                //chaque subChallenge doit avoir un name et une description
                challenge.subChallenges.forEach((subChallenge) => {
                    if (subChallenge.name === "" && subChallenge.description === "") {
                        if (!String(subChallenge.id).startsWith("new-") && !Number.isNaN(Number(subChallenge.id))) {
                            deletedSubChallengeId.push(Number(subChallenge.id));
                        }
                        challenge.subChallenges = challenge.subChallenges.filter((subChallengeFilter) => String(subChallengeFilter.id) !== String(subChallenge.id));
                    } else {
                        if (!subChallenge.name || subChallenge.name === "") {
                            copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                                code: "subChallenge",
                                message: "Le nom du défi est obligatoire",
                                id: subChallenge.id
                            }];
                        }
                    }
                });
                formData.append("subChallenges", JSON.stringify(challenge.subChallenges));

            }

            if (challenge && challenge.prizes && challenge.prizes.length > 0) {

                challenge.prizes.forEach((prize) => {

                    const imagePrice = imagePrices.find((imagePrice) => String(imagePrice.id) === String(prize.id));

                    if (prize.prizePicture && prize.prizePicture !== '/images/photo-profil.jpg' && !imagePrice) {
                        formData.append("prizePicture", prize.prizePicture);
                    } else if (imagePrices && imagePrices.length > 0) {if (imagePrice && imagePrice.file) {
                            formData.append(`prizePicture${String(prize.id)}`, imagePrice?.file);
                        } else {
                            copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                                code: "price",
                                message: "L'image du prix est obligatoire",
                                id: prize.id
                            }];
                        }
                    } else {
                        copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                            code: "price",
                            message: "L'image du prix est obligatoire",
                            id: prize.id
                        }];
                    }

                    if (prize.name === "") {
                        copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                            code: "price",
                            message: "Le nom du prix est obligatoire",
                            id: prize.id
                        }];
                    }
                });
                formData.append("prizes", JSON.stringify(challenge.prizes));
            }

            if (challenge.partners && challenge.partners.length > 0) {
                challenge.partners.forEach((partner) => {
                    const imagePartner = imagePartners.find((imagePartner) => String(imagePartner.id) === String(partner.id));

                    if (partner.partnerLogo && partner.partnerLogo !== '/images/photo-profil.jpg' && !imagePartner) {
                        formData.append("partnerLogo", partner.partnerLogo);
                    } else if (imagePartners && imagePartners.length > 0) {
                        if (imagePartner && imagePartner.file) {
                            formData.append(`partnerLogo${String(partner.id)}`, imagePartner?.file);
                        } else {
                            copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                                code: "partner",
                                message: "Le logo du partnaire est obligatoire",
                                id: partner.id
                            }];
                        }
                    } else {
                        copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                            code: "partner",
                            message: "Le logo du partenaire est obligatoire",
                            id: partner.id
                        }];
                    }

                    if (partner.name === "") {
                        copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                            code: "partner",
                            message: "Le nom du partenaire est obligatoire",
                            id: partner.id
                        }];
                    }
                });
                console.log("partner", challenge.partners);
                formData.append("partners", JSON.stringify(challenge.partners));
            }

            if (challenge.itemFAQs && challenge.itemFAQs.length > 0) {
                //chaque itemFAQs doit avoir un name et une description
                challenge.itemFAQs.forEach((itemFAQ) => {
                    if (itemFAQ.question === "" && itemFAQ.answer === "") {
                        if (!String(itemFAQ.id).startsWith("new-") && !Number.isNaN(Number(itemFAQ.id))) {
                            deletedItemFaqId.push(Number(itemFAQ.id));
                        }
                        challenge.itemFAQs = challenge.itemFAQs.filter((itemFAQFilter) => String(itemFAQFilter.id) !== String(itemFAQ.id));
                    } else {
                        if (!itemFAQ.question || itemFAQ.question === "") {
                            copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                                code: "itemFAQ",
                                message: "La question de la FAQ est obligatoire",
                                id: itemFAQ.id
                            }];
                        }
                        if (!itemFAQ.answer || itemFAQ.answer === "") {
                            copyErrorFieldGeneral = [...copyErrorFieldGeneral, {
                                code: "itemFAQ",
                                message: "La réponse de la FAQ est obligatoire",
                                id: itemFAQ.id
                            }];
                        }
                    }

                    if (challenge.itemFAQs.length === 0) {
                        formData.append("hasFaq", String(false));
                    }
                });
                formData.append("itemFAQs", JSON.stringify(challenge.itemFAQs));
            }

            //Si j'ai des subChallenge à supprimer
            if (deletedSubChallengeId.length > 0) {
                formData.append("deletedSubChallengeId", JSON.stringify(deletedSubChallengeId));
                //retirer les subChallenges dans challenge qui ont été supprimé
                challenge.subChallenges = challenge.subChallenges.filter((subChallenge) => !deletedSubChallengeId.includes(Number(subChallenge.id)));
            }

            //Si j'ai des prize à supprimer
            if (deletedPrizeId.length > 0) {
                formData.append("deletedPrizeId", JSON.stringify(deletedPrizeId));
                //retirer les prize dans challenge qui ont été supprimé
                challenge.prizes = challenge.prizes.filter((prize) => !deletedPrizeId.includes(Number(prize.id)));
            }

            //Si j'ai des partner à supprimer
            if (deletedPartnerId.length > 0) {
                formData.append("deletedPartnerId", JSON.stringify(deletedPartnerId));
                //retirer les partner dans challenge qui ont été supprimé
                challenge.partners = challenge.partners.filter((partner) => !deletedPartnerId.includes(Number(partner.id)));
            }

            //Si j'ai des itemFAQ à supprimer
            if (deletedItemFaqId.length > 0) {
                formData.append("deletedItemFaqId", JSON.stringify(deletedItemFaqId));
                //retirer les itemFAQ dans challenge qui ont été supprimé
                challenge.itemFAQs = challenge.itemFAQs.filter((itemFaq) => !deletedItemFaqId.includes(Number(itemFaq.id)));
            }

            formData.append("challengeId", String(id));
            formData.append("establishments", JSON.stringify(challenge.establishments[0]));

            setErrorFieldGeneral(copyErrorFieldGeneral);
        }
        if (logoToUpload) {
            console.log("J'envoie un fichier");

            formData.append("challengeLogo", "null");
            formData.append("editChallengeLogo", logoToUpload);
        }

        if (imgToUpload) {
            console.log("J'envoie un fichier");
            formData.append("challengePicture", "null");
            formData.append("editChallengePicture", imgToUpload);
        }

        //Si il y a une erreur
        if (copyErrorFieldGeneral.length > 0) {
            setOpenSnackBarEditChallenge(true);
            setVariantSnackBarEditChallenge("error");
            setTextSnackBarEditChallenge("Une erreur est survenue");
            console.log("copyErrorFieldGeneral", copyErrorFieldGeneral);

            return;
        }

        // console.log("formData",...formData);

        await api.put('/admin/challenge/update/general',
            formData,
            {
                withCredentials: true,
            })
            .then((res) => {
                getOneChallenge(String(id))
                console.log(res)
                console.log("Challenge updated")
                findChallenge(String(id));
                setSave(false)
                setFilesToDelete([])

                //General
                setOpenSnackBarEditChallenge(true);
                setVariantSnackBarEditChallenge("success");
                setTextSnackBarEditChallenge("Challenge modifié avec succès");
            })
            .catch((err) => {
                console.log('formDate: ', formData)
                console.log(err)
                setSave(false)
                setFilesToDelete([])

                //General
                setOpenSnackBarEditChallenge(true);
                setVariantSnackBarEditChallenge("error");
                setTextSnackBarEditChallenge("Une erreur est survenue");
            })
    }

    const [regulation, setRegulation] = useState<File | undefined>(undefined)

    const updateRegistration = async () => {
        console.log("challenge", challenge);
        console.log("regulation", regulation);

        const formData = new FormData();

        if (challenge && challengeOrigin) {
            formData.append('challengeId', String(id));
            if (rules) {
                formData.append('file', rules);
                if (challengeOrigin.file) {
                    formData.append('fileToDelete', String(challengeOrigin.file.id));
                }
            } else if ((challengeOrigin && challengeOrigin.file) && (challenge && !challenge.file)) {
                formData.append('fileToDelete', String(challengeOrigin.file.id));
            }
            formData.append('registrationStartDate', String(challenge.registrationStartDate));
            formData.append('registrationEndDate', String(challenge.registrationEndDate));
            formData.append('maxMembers', String(challenge.maxMembers));

            switch (challenge.participationType) {
                case "single":
                    formData.append('minMembersTeam', "0");
                    formData.append('maxMembersTeam', "0");
                    break;
                case "team":
                    formData.append('minMembersTeam', String(challenge.minMembersTeam));
                    formData.append('maxMembersTeam', String(challenge.maxMembersTeam));
                    break;
                case "both":
                    formData.append('minMembersTeam', "0");
                    formData.append('maxMembersTeam', String(challenge.maxMembersTeam));
                    break;
                default:
                    formData.append('minMembersTeam', "0");
                    formData.append('maxMembersTeam', "0");
            }
            formData.append('participationType', challenge.participationType);
        }
        console.log("formData", ...formData);
        console.log(JSON.stringify(eligibilities));
        await api.put('/challenge/eligibilities',
            {
                eligibilities,
                challengeId: id
            },
            {
                withCredentials: true,
            })
            .then((res) => {
                console.log('update eligibilities : ', res.data)
            })
            .catch((err) => {
                console.log(err);
            });
        await api.put('/admin/challenge/update/registrations',
            formData,
            {
                withCredentials: true,
            })
            .then((res) => {
                console.log('update registration : ', res.data)
                setSave(false)
                getOneChallenge(String(id));
                setOpenSnackBarEditRegistration(true);
                setVariantSnackBarEditRegistration("success");
                setTextSnackBarEditRegistration("Inscription modifiée avec succès");
            })
            .catch((err) => {
                setSave(false)
                setOpenSnackBarEditRegistration(true);
                setVariantSnackBarEditRegistration("error");
                setTextSnackBarEditRegistration("Une erreur est survenue");
            });

    }

    const updateRegistrations = (file?: File | undefined, fileToDelete?: string | null) => {
        const formData = new FormData();
        console.log("registrationStartDate", registrationStartDate);

        formData.append('registrationStartDate', registrationStartDate);
        formData.append('registrationEndDate', registrationEndDate);
        formData.append('challengeId', String(id));
        formData.append('maxChallengeMembers', totalParticipants.toString());
        formData.append('participationType', typeParticipation);
        formData.append('minMembersTeam', nbMinParticipants.toString());
        formData.append('maxMembersTeam', nbMaxParticipants.toString());
        if (file) {
            formData.append('file', file);
        }
        if (fileToDelete) {
            formData.append('fileToDelete', fileToDelete);
        }

        api.put('/admin/challenge/update/registrations',
            formData,
            {
                withCredentials: true,
            })
            .then((res) => {
                console.log('upldate resgistrations : ', res.data)
                console.log("Registrations updated")
                if (res.data) {
                    setError(null)
                }

                setSave(false)
            })
            .catch((err) => {
                if (err.response.data.message && err.response.data.error) {
                    console.log("message", err.response.data.message);
                    console.log("error", err.response.data.error);
                    setError(err.response.data)
                }
                console.log(err)
                setSave(false)
            })
    }

    //Step
    const [stepUpdateDone, setStepUpdateDone] = useState(false);

    const createOrUpdateStep = async () => {
        //Vérifier si j'ai des erreurs côté client
        if (timeSlotError && timeSlotError.length > 0 && timeSlotError.some((error) => error.errorType !== "EndBeforeRegistrations")) {
            console.log("Afficher qu'il y a des erreurs et stopper la fonction");
            setErrorStep("Une erreur est survenue.");
            return;
        }
        //vider les erreurs
        setTimeSlotError([]);
        setErrorStep(null);

        const newStepFiltered = newStep
            .filter((step) => step.name !== "" && step.name !== null)
            .map((step) => ({
                ...step,
                questions: step.questions.filter((question) => question.questionText !== "" && question.questionText !== null),
                deliverables: step.deliverables.filter((deliverable) => deliverable.name !== "" && deliverable.name !== null)
            }));

        console.log("newStepFiltered", newStepFiltered);

        const updateListStepsFiltered = updateListSteps
            .filter((step) => step.name !== "" && step.name !== null)
            .map((step) => ({
                ...step,
                questions: step.questions.filter((question) => question.questionText !== "" && question.questionText !== null),
                deliverables: step.deliverables.filter((deliverable) => deliverable.name !== "" && deliverable.name !== null)
            }));

        console.log("updateListStepsFiltered", updateListStepsFiltered);

        console.log("updateListStepsFiltered", updateListStepsFiltered);

        await api.put("/admin/challenge/steps",
            {
                challengeId: id,
                newSteps: newStepFiltered,
                updateSteps: updateListStepsFiltered,
                deleteSteps: stepToDelete,
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            const steps = res.data.steps;
            const challenge = res.data.challenge;
            setListSteps(steps);
            setOriginalSteps(steps);
            setUpdateListSteps([]);
            setNewStep([]);
            setStepToDelete([]);
            setErrorStep(res.data.message);

            if (challenge.registrationEndDate) {
                setRegistrationEndDate(challenge.registrationEndDate);
            }
        }).catch((err) => {
            console.log(err);
            setErrorStep(err.response.data);
            // Remettre les step Delete dans la liste des steps
            setListSteps([...listSteps, ...stepToDelete]);
            setStepToDelete([]);
        })

        console.log("steps to update : ", updateListSteps);
        console.log("steps to create : ", newStep);
        console.log("steps to delete : ", stepToDelete);
    }

    useEffect(() => {
        console.log('filesToDelete', filesToDelete);
    }, [filesToDelete])

    const handleDeleteLogo = () => {
        (logo !== 'https://img.freepik.com/vecteurs-premium/vecteur-icone-image-par-defaut-page-image-manquante-pour-conception-site-web-application-mobile-aucune-photo-disponible_87543-11093.jpg') ?
            (!filesToDelete.includes('challengeLogo')) ?
                setFilesToDelete([...filesToDelete, 'challengeLogo']) :
                setFilesToDelete([...filesToDelete])
            : setFilesToDelete([...filesToDelete])
    }

    const handleDeleteImg = () => {
        (img !== `/images/couverture-challenge.jpg`) ?
            (!filesToDelete.includes('challengePicture')) ?
                setFilesToDelete([...filesToDelete, 'challengePicture']) :
                setFilesToDelete([...filesToDelete])
            : setFilesToDelete([...filesToDelete])
    }

    // critères
    const updateCriteria = async () => {
        const criteriaToAdd = criteria.filter((criterion) => criterion.isNew === true).map((c) => {
            return {
                id: null,
                name: c.name,
                description: c.description,
                grade: c.valuePoints,
                challengeId: id
            }
        })
        const criteriaToUpdate = criteria.filter((criterion) => criterion.isModified === true).map((c) => {
            return {
                id: c.id,
                name: c.name,
                description: c.description,
                grade: c.valuePoints,
                challengeId: id
            }
        })
        const criteriaToDeleteIds = criteriaToDelete.map((criterion) => criterion.id)

        const criteriaToAddOrUpdate = [...criteriaToAdd, ...criteriaToUpdate]

        if (criteriaToAddOrUpdate.length !== 0) {
            await api.post('/admin/challenge/criteria/create',
                {
                    newCriterias: criteriaToAddOrUpdate
                },
                {
                    withCredentials: true
                }
            ).then((res) => {
                    setSave(false)
                }
            ).catch((err) => {
                console.log(err)
                setSave(false)
            })
        }

        if (criteriaToDeleteIds.length !== 0) {
            await api.delete('/admin/challenge/criteria/delete',
                {
                    data: {
                        criteriaIds: criteriaToDeleteIds,
                        challengeId: id
                    },
                    withCredentials: true
                }
            ).then((res) => {
                setSave(false)
            }).catch((err) => {
                console.log(err)
                setSave(false)
            })
        }
    }

    // ressources
    const [ressources, setRessources] = useState<any[]>([])
    const fetchResources = async () => {
        // console.log("challenge id ==> ", id);

        await api.get("/admin/challenge/resources",
            {
                params: {
                    challengeId: id
                },
                withCredentials: true
            }
        ).then((res) => {
            const resourcesFetched: IResource[] = res.data.map((resource: any) => {
                return {
                    id: resource.id,
                    challengeId: id,
                    name: resource.name,
                    link: resource.link,
                    file: resource.file,
                    isModified: false,
                }
            });

            // const stepsdeliverableFetched: IStepDeliverable[] = res.data.steps.flatMap((step: any) => {
            //     return step.deliverables.map((deliverable: any) => {
            //         return {
            //             id: deliverable.id,
            //             name: deliverable.name,
            //             description: deliverable.description,
            //             position: deliverable.position,
            //             stepId: deliverable.stepId,
            //             // challengeId: deliverable.challengeId,
            //             isModified: false,
            //         }
            //     });
            // });

            // setFilesResource(stepsquestionFetched);
            // setFilesResourceToDelete([]);
            setErrorResource([]);
            setResources(resourcesFetched);
            setCurrentResources(resourcesFetched);
        }).catch((err) => {
            console.log(err);
        })
    }

    const createOrUpdateResource = async () => {
        setErrorResource([]);
        //vider les erreurs
        setResources(prevResources => {
            const newRessources = [...prevResources];
            newRessources.map((resource: any) => {
                if (resource.error !== null) {
                    resource.error = null
                }
            })
            return newRessources;
        });

        if (resourcesToDelete.length !== 0) {
            const idsToDelete = resourcesToDelete.map(resource => resource.id);
            await api.delete('/admin/challenge/resources/delete',
                {
                    data: {
                        resourcesIds: idsToDelete,
                        challengeId: id
                    },
                    withCredentials: true
                }
            ).then((res) => {
                    setSave(false)
                    fetchResources();
                    setOpenSnackBarResource(true);
                    setTextSnackBarResource("Les ressources ont bien été supprimées");
                    setVariantSnackBarResource("success");
                }
            ).catch((err) => {
                    console.log(err)
                    setSave(false)
                    setOpenSnackBarResource(true);
                    setTextSnackBarResource("Une erreur est survenue");
                    setVariantSnackBarResource("error");
                }
            )
        }

        if (resources.length !== 0) {
            let listressource: any[] = resources;
            console.log("listressource", listressource);

            const formData = new FormData();
            const saveError: IErrorResource[] = [];

            listressource.forEach((resource, index) => {
                if ((resource.name === "" || !resource.name) || ((resource.link === "" || !resource.link) && resource.file === undefined)) {
                    if (resource.name === "") {
                        const nouvelObjet: IErrorResource = {
                            id: resource.id,
                            code: "name",
                            message: "Le nom est obligatoire"
                        };
                        saveError.push(nouvelObjet);
                    }
                    if (resource.file === undefined) {
                        const nouvelObjet: IErrorResource = {
                            id: resource.id,
                            code: "file",
                            message: "Le fichier est obligatoire"
                        };
                        saveError.push(nouvelObjet);
                    }
                    if ((resource.link === "" || !resource.link)) {
                        const nouvelObjet: IErrorResource = {
                            id: resource.id,
                            code: "link",
                            message: "Le lien est obligatoire"
                        };
                        saveError.push(nouvelObjet);
                    }
                    setErrorResource(saveError);
                }
                formData.append(`resources[${index}][id]`, resource.id);
                formData.append(`resources[${index}][challengeId]`, resource.challengeId);
                formData.append(`resources[${index}][name]`, resource.name);
                formData.append(`resources[${index}][isModified]`, resource.isModified);
                formData.append(`resources[${index}][link]`, resource.link || '');

                if (resource.file) {
                    formData.append(`resources[${index}][file${resource.id}]`, resource.file);
                    console.log("resource.file", resource.file);
                } else {
                    const fileValue = resource.file === undefined ? null : resource.file;
                    formData.append(`resources[${index}][file${resource.id}]`, fileValue);
                }
            });
            if (saveError.length !== 0) {
                return;
            }
            console.log("formData", formData);

            await api.put('/admin/challenge/resources/createOrUpdate',
                formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.failedOperations) {
                            const errors: IResourceError[] = res.data.failedOperations;
                            const updatedResources = resources.map((resource, index) => {
                                const matchingError = errors.find((error) => error.index === index);
                                if (matchingError) {
                                    return {...resource, error: matchingError};
                                } else {
                                    return resource;
                                }
                            });
                            setResources([]);
                            setCurrentResources(updatedResources);
                            fetchResources();
                        } else {
                            fetchResources();
                            setErrorResource([]);
                        }
                        setOpenSnackBarResource(true);
                        setTextSnackBarResource(res.data.message);
                        setVariantSnackBarResource("success");
                    }
                })
                .catch((err) => {
                    console.log("err.response.data.error", err.response.data.error);
                    setOpenSnackBarResource(true);
                    setTextSnackBarResource(err.response.data.error);
                    setVariantSnackBarResource("error");
                })
        }
    }

    // useEffect(() => {

    //     if (Number(id) !== 0) fetchResources();
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [page === "ressources"])

    const [quit, setQuit] = useState(false)

    // besoin pour l'uploader d'images (si on a cliqué sur le bouton annuler, il faut retirer remettre la prévisualisation de l'image)
    const [hasClickedOnCancelButton, setHasClickedOnCancelButton] = useState(false)
    const [truc, setTruc] = useState(false);
    const [truc2, setTruc2] = useState(false);

    useEffect(() => {
        // console.log("je change hasClickedOnCancelButton")
        setHasClickedOnCancelButton(false);
    }, [truc])

    const [save, setSave] = useState(false)
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        setSave(false);

        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        setIsScrolled(scrollY > 0);
    }, [scrollY])
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <>
            <div className='admin-edit-challenge-outlet-nav'>
                <div className={`admin-edit-challenge-outlet-nav-background`}>
                    <div
                        className={`desktop-header ${isMobile ? 'mobile-header' : ''}${isScrolled ? ' sscrolled' : ''}`}>
                        <h1>Édition de mon challenge</h1>
                    </div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={2}>
                            <GoBack onClick={() => {
                                setClick(true)
                                setQuit(true)
                                if (!change) {
                                    setClick(false)
                                    setChange(false)
                                    setNextClick(false)
                                    setQuit(false)
                                    setFilesToDelete([])
                                    navigate(`/admin/challenge/${id}/general`)
                                }
                            }}
                                    variant='text-mobile'>
                                Quitter l'édition
                            </GoBack>
                            {
                                isMobile && (
                                    <div className={`buttons buttons-mobile `}>
                                        <ActionButton
                                            variant={`${!change ? 'disabled' : 'tertiary'}`}
                                            onClick={() => {
                                                if (page === 'general') {
                                                    setChange(false)
                                                    setTitle((challenge && challenge.name) || "");
                                                    setShortDescription((challenge && challenge?.shortDescription) || "");
                                                    setLongDescription((challenge && challenge.longDescription) || "");
                                                    setStartDate((challenge && challenge.startDate && challenge.startDate) || "");
                                                    setEndDate((challenge && challenge.endDate && challenge.endDate) || "");
                                                    setIsPublic((challenge && challenge.public) || false);
                                                    setImg((challenge && challenge.challengePicture) || "");
                                                    setLogo((challenge && challenge.challengeLogo) || "");
                                                    setPrizesToDelete([]);
                                                    setPartnersToDelete([]);
                                                    setQuestionsToDelete([]);
                                                    setDaresToDelete([]);
                                                    // handleDeleteNewItems();
                                                    setPrizes(currentPrizes);
                                                    setPartners(currentPartners);
                                                    setQuestions(currentQuestions);
                                                    setDares(currentDares);
                                                    setHasClickedOnCancelButton(true);
                                                    setFilesToDelete([]);

                                                    //General
                                                    setChange(false)
                                                    setChallenge(challengeOrigin);
                                                }
                                                if (page === 'signup') {
                                                    setChange(false)
                                                    setRegistrationStartDate((challenge && challenge.registrationStartDate) || "");
                                                    setRegistrationEndDate((challenge && challenge.registrationEndDate) || "");
                                                    setTotalParticipants((challenge && challenge.maxMembers) || 0);
                                                    setTypeParticipation((challenge && challenge.participationType) || "");
                                                    setNbMinParticipants((challenge && challenge.minMembersTeam) || 0);
                                                    setNbMaxParticipants((challenge && challenge.maxMembersTeam) || 0);
                                                    setFileToDelete(null)
                                                    setFileName((challenge && challenge.file?.originalFileName) || "");
                                                }
                                                if (page === 'ressources') {
                                                    setChange(false);
                                                    setResources(currentResources);
                                                    // setFilesResourceToDelete([]);
                                                    fetchResources()
                                                }
                                                if (page === "steps") {
                                                    setChange(false);
                                                    setUpdateListSteps([])
                                                    setNewStep([])
                                                    setStepToDelete([])

                                                    setListSteps(originalSteps)
                                                }
                                                if (page === "criteria") {
                                                    setChange(false);
                                                    setCriteria(currentCriteria);
                                                }
                                            }}
                                        >
                                            Annuler
                                        </ActionButton>
                                        <ActionButton
                                            variant='primary'
                                            onClick={() => {
                                                setSave(true)
                                                setChange(false)
                                                setClick(false)
                                                if (page === 'general') {
                                                    updateChallenge()
                                                }
                                                if (page === 'signup') {
                                                    // updateRegistrations(rules, fileToDelete)
                                                    updateRegistration()
                                                }
                                                if (page === 'ressources') {
                                                    createOrUpdateResource()
                                                }
                                                if (page === "steps") {
                                                    createOrUpdateStep();

                                                    // updateSteps();
                                                }
                                                if (page === "criteria") {
                                                    updateCriteria();
                                                }

                                            }}
                                        >
                                            Enregistrer
                                        </ActionButton>
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <OutletNavBar links={outletNavbarLinks} variant='menu-tabs-secondary'/>
                        </Grid>
                        <Grid item xs={2}>

                        </Grid>
                    </Grid>
                </div>
            </div>

            {save && <Save/>}

            <Outlet context={{
                // general
                change, setChange,
                page, setPage,
                click, setClick,
                id,
                getOneChallenge,
                challenge, setChallenge,
                challengeOrigin, setChallengeOrigin,
                errorFieldGeneral, setErrorFieldGeneral,
                imagePrices, setImagePrices,
                imagePartners, setImagePartners,
                regulation, setRegulation,
                openSnackBarEditChallenge, setOpenSnackBarEditChallenge,
                variantSnackEditChallenge, setVariantSnackBarEditChallenge,
                textSnackBarEditChallenge, setTextSnackBarEditChallenge,
                openSnackBarEditRegistration, setOpenSnackBarEditRegistration,
                variantSnackEditRegistration, setVariantSnackBarEditRegistration,
                textSnackBarEditRegistration, setTextSnackBarEditRegistration,

                // id, setId,
                title, setTitle,
                startDate, setStartDate,
                endDate, setEndDate,
                shortDescription, setShortDescription,
                longDescription, setLongDescription,
                isPublic, setIsPublic,
                img, setImg,
                logo, setLogo,
                hasDefis, setHasDefis,
                hasPrizes, setHasPrizes,
                hasPartners, setHasPartners,
                hasFaq, setHasFaq,
                imgToUpload, setImgToUpload,
                logoToUpload, setLogoToUpload,
                prizes, setPrizes,
                partners, setPartners,
                questions, setQuestions,
                dares, setDares,
                prizesToDelete, setPrizesToDelete,
                partnersToDelete, setPartnersToDelete,
                questionsToDelete, setQuestionsToDelete,
                daresToDelete, setDaresToDelete,
                filesToDelete, setFilesToDelete,
                handleDeleteLogo, handleDeleteImg,

                // sign-up
                registrationStartDate, setRegistrationStartDate,
                registrationEndDate, setRegistrationEndDate,
                totalParticipants, setTotalParticipants,
                typeParticipation, setTypeParticipation,
                nbMinParticipants, setNbMinParticipants,
                nbMaxParticipants, setNbMaxParticipants,
                eligibilities, setEligibilities,
                rules, setRules,
                fileName, setFileName,
                fileNameStorage, setFileNameStorage,
                linkRules, setLinkRules,
                fileToDelete, setFileToDelete,
                error, setError,

                // ressources
                resources, setResources,
                errorResource, setErrorResource,
                resourcesToDelete, setResourcesToDelete,
                openSnackBarResource, setOpenSnackBarResource,
                textSnackBarResource, setTextSnackBarResource,
                variantSnackBarResource, setVariantSnackBarResource,
                fetchResources,
                // filesResource, setFilesResource,
                // filesResourceToDelete, setFilesResourceToDelete,
                // ressources, setRessources,
                // ressourcesToDelete, setRessourcesToDelete,
                // addRessources, getAllRessources, updateRessources, deleteRessources,
                // newRessources, setNewRessources,
                // currentRessources, setCurrentRessources,
                // ressourcesToUpdate, setRessourcesToUpdate,
                // ressourceFile, setRessourceFile,
                // ressourceFileNameStorage, setRessourceFileNameStorage,
                // ressourceFileName, setRessourceFileName,
                // ressourceFileToDelete, setRessourceFileToDelete,

                // steps
                listSteps, setListSteps,
                originalSteps, setOriginalSteps,
                updateListSteps, setUpdateListSteps,
                newStep, setNewStep,
                stepToDelete, setStepToDelete,
                timeSlotError, setTimeSlotError,
                errorStep, setErrorStep,

                // steps, setSteps,
                // stepsToDelete, setStepsToDelete,
                // //question
                // questionsStep, setQuestionsStep,
                // questionsStepToDelete, setQuestionsStepToDelete,
                // //deliverable
                // deliverablesStep, setDeliverablesStep,
                // deliverablesStepToDelete, setDeliverablesStepToDelete,

                // criteria
                criteria, setCriteria,
                criteriaToDelete, setCriteriaToDelete,
                fetchCriteria,

                // others
                hasClickedOnCancelButton,
                truc, setTruc,
                truc2, setTruc2,
            }}/>

            <div className="admin-edit-challenge-nav-bottom">
                <div className='admin-edit-challenge-nav-bottom-content'>
                    <GoBack onClick={() => {
                        setClick(true)
                        setNextClick(false)
                        if (!change && page === 'general') {
                            setClick(false)
                            setChange(false)
                            navigate(`/admin/challenge/${id}/general`)
                        }
                        if (!change && page === 'signup') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/general/' + id)
                        }
                        if (!change && page === 'steps') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/sign-up/' + id)
                        }
                        if (!change && page === 'ressources') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/steps/' + id)
                        }
                        if (!change && page === 'criteria') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/ressources/' + id)
                        }
                    }}
                            variant='text-mobile'>
                        {page === 'general' ? "Quitter l'édition" : "Précédent"}
                    </GoBack>
                    {
                        !isMobile && (
                            <div className="buttons">
                                <ActionButton
                                    variant={`${!change ? 'disabled' : 'tertiary'}`}
                                    onClick={() => {
                                        if (page === 'general') {
                                            setChange(false)
                                            setTitle((challenge && challenge.name) || "");
                                            setShortDescription((challenge && challenge.shortDescription) || "");
                                            setLongDescription((challenge && challenge.longDescription) || "");
                                            setStartDate((challenge && challenge.startDate) || "");
                                            setEndDate((challenge && challenge.endDate) || "");
                                            setIsPublic((challenge && challenge.public) || false);
                                            setImg((challenge && challenge.challengePicture) || "");
                                            setLogo((challenge && challenge.challengeLogo) || "");
                                            setPrizesToDelete([]);
                                            setPartnersToDelete([]);
                                            setQuestionsToDelete([]);
                                            setDaresToDelete([]);
                                            // handleDeleteNewItems();
                                            setPrizes(currentPrizes);
                                            setPartners(currentPartners);
                                            setQuestions(currentQuestions);
                                            setDares(currentDares);
                                            setHasClickedOnCancelButton(true);
                                            setFilesToDelete([]);

                                            //General
                                            setChange(false)
                                            setChallenge(challengeOrigin)
                                        }
                                        if (page === 'signup') {
                                            setChange(false)
                                            setRegistrationStartDate((challenge && challenge.registrationStartDate) || "");
                                            setRegistrationEndDate((challenge && challenge.registrationEndDate) || "");
                                            setTotalParticipants((challenge && challenge.maxMembers) || 0);
                                            setTypeParticipation((challenge && challenge.participationType) || "");
                                            setNbMinParticipants((challenge && challenge.minMembersTeam) || 0);
                                            setNbMaxParticipants((challenge && challenge.maxMembersTeam) || 0);
                                            setFileToDelete(null)
                                            setFileName((challenge && challenge.file && challenge.file.originalFileName) || "");
                                        }
                                        if (page === 'ressources') {
                                            setChange(false);
                                            setResources(currentResources);
                                            // setFilesResourceToDelete([]);
                                            fetchResources()
                                        }
                                        if (page === "steps") {
                                            setChange(false);
                                            setUpdateListSteps([])
                                            setNewStep([])
                                            setStepToDelete([])
                                            setListSteps(originalSteps)
                                        }
                                        if (page === "criteria") {
                                            setChange(false);
                                            setCriteria(currentCriteria);
                                        }
                                    }}
                                >
                                    Annuler
                                </ActionButton>
                                <ActionButton
                                    variant='primary'
                                    onClick={() => {
                                        setSave(true)
                                        setChange(false)
                                        setClick(false)
                                        if (page === 'general') {
                                            updateChallenge()
                                        }
                                        if (page === 'signup') {
                                            // updateRegistrations(rules, fileToDelete)
                                            updateRegistration()
                                        }
                                        if (page === 'ressources') {
                                            createOrUpdateResource()
                                        }
                                        if (page === "steps") {
                                            createOrUpdateStep();

                                            // updateSteps();
                                        }
                                        if (page === "criteria") {
                                            updateCriteria();
                                        }

                                    }}
                                >
                                    Enregistrer
                                </ActionButton>
                            </div>
                        )
                    }

                    <GoNext onClick={() => {
                        setClick(true)
                        setNextClick(true)
                        if (!change && page === 'general') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/sign-up/' + id);
                        }
                        if (!change && page === 'signup') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/steps/' + id)
                        }
                        if (!change && page === 'steps') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/ressources/' + id)
                        }
                        if (!change && page === 'ressources') {
                            setClick(false)
                            setChange(false)
                            navigate('/admin/challenges/edit/criteria/' + id)
                        }
                        if (!change && page === 'criteria') {
                            setClick(false)
                            setChange(false)
                            navigate(`/admin/challenge/${id}/general`)
                        }
                    }}
                            variant='text-mobile'
                    >
                        {page === 'criteria' ? "Quitter l'édition" : "Suivant"}
                    </GoNext>
                </div>
            </div>
            {
                click && change && (
                    <>
                        <div className="pop-up-save">
                            <div className="container">
                                <p className="h1">Vous n'avez pas enregistré vos modifications</p>
                                <ActionButton
                                    variant={`${!change ? 'disabled' : 'tertiary'}`}
                                    onClick={() => {
                                        setChange(true)
                                        setClick(false)
                                        setQuit(false)
                                    }}
                                >
                                    Rester sur la page
                                </ActionButton>
                                <ActionButton
                                    variant={`${!change ? 'disabled' : 'tertiary'}`}
                                    onClick={() => {
                                        setSave(true)
                                        setChange(false)
                                        setClick(false)
                                        if (quit) {
                                            navigate(`/admin/challenge/${id}/general`)
                                            if (page === 'general') {
                                                updateChallenge();
                                            }
                                            if (page === 'signup') {
                                                // updateRegistrations(rules, fileToDelete);
                                                updateRegistration()
                                            }
                                            if (page === "steps") {
                                                createOrUpdateStep();
                                                // createOrUpdateDeliverablesStep();
                                            }
                                            if (page === "ressources") {
                                                createOrUpdateResource();
                                            }
                                        } else if (nav) {
                                            if (page === 'general') {
                                                updateChallenge();
                                            }
                                            if (page === 'signup') {
                                                // updateRegistrations(rules, fileToDelete);
                                                updateRegistration()
                                            }
                                            if (page === "criteria") {
                                                updateCriteria();
                                            }
                                            if (page === "steps") {
                                                createOrUpdateStep();
                                                // createOrUpdateDeliverablesStep();
                                            }
                                            if (page === "ressources") {
                                                createOrUpdateResource();
                                            }
                                            navigate(nav + name)
                                            setNav('')
                                        } else {
                                            if (page === 'general') {
                                                updateChallenge();
                                                nextClick && navigate('/admin/challenges/edit/sign-up/' + id);
                                                !nextClick && navigate('/admin/challenges');
                                            }
                                            if (page === 'signup') {
                                                // updateRegistrations(rules, fileToDelete);
                                                updateRegistration()
                                                nextClick && navigate('/admin/challenges/edit/steps/' + id)
                                                !nextClick && navigate('/admin/challenges/edit/general/' + id)
                                            }
                                            if (page === "criteria") {
                                                updateCriteria();
                                                nextClick && navigate(`/admin/challenge/${id}/general`)
                                                !nextClick && navigate('/admin/challenges/edit/ressources/' + id)
                                            }
                                            if (page === "steps") {
                                                createOrUpdateStep();
                                                // createOrUpdateDeliverablesStep();
                                                nextClick && navigate('/admin/challenges/edit/ressources/' + id)
                                                !nextClick && navigate('/admin/challenges/edit/sign-up/' + id)
                                            }
                                            if (page === "ressources") {
                                                createOrUpdateResource();
                                                nextClick && navigate(`/admin/challenge/${id}/general`)
                                                !nextClick && navigate('/admin/challenges/edit/steps/' + id)
                                            }
                                        }
                                        setQuit(false)
                                    }}
                                >
                                    Enregistrer et continuer
                                </ActionButton>
                                <ActionButton
                                    variant='tertiary'
                                    onClick={() => {
                                        setChange(false)
                                        setClick(false)
                                        if (quit) {
                                            navigate(`/admin/challenge/${id}/general`)
                                        } else if (nav) {
                                            navigate(nav + id)
                                            setNav('')
                                        } else {
                                            if (page === 'general') {
                                                nextClick && navigate('/admin/challenges/edit/sign-up/' + id);
                                                !nextClick && navigate('/admin/challenges');
                                            }
                                            if (page === 'signup') {
                                                nextClick && navigate('/admin/challenges/edit/steps/' + id)
                                                !nextClick && navigate('/admin/challenges/edit/general/' + id)
                                            }
                                            if (page === 'steps') {
                                                nextClick && navigate('/admin/challenges/edit/ressources/' + id)
                                                !nextClick && navigate('/admin/challenges/edit/sign-up/' + id)
                                            }
                                        }
                                        setQuit(false)
                                    }}
                                >
                                    Ignorer et continuer
                                </ActionButton>
                            </div>
                        </div>
                    </>
                )
            }
            <Footer/>
        </>
    )
}

export default AdminEditChallenge;

export const useAdminEditChallengeContext = () => {
    const {
        // general
        change, setChange,
        click, setClick,
        page, setPage,
        id,
        getOneChallenge,
        challenge, setChallenge,
        challengeOrigin, setChallengeOrigin,
        errorFieldGeneral, setErrorFieldGeneral,
        imagePrices, setImagePrices,
        imagePartners, setImagePartners,
        regulation, setRegulation,
        openSnackBarEditChallenge, setOpenSnackBarEditChallenge,
        variantSnackEditChallenge, setVariantSnackBarEditChallenge,
        textSnackBarEditChallenge, setTextSnackBarEditChallenge,
        openSnackBarEditRegistration, setOpenSnackBarEditRegistration,
        variantSnackEditRegistration, setVariantSnackBarEditRegistration,
        textSnackBarEditRegistration, setTextSnackBarEditRegistration,

        // setId,
        title, setTitle,
        startDate, setStartDate,
        endDate, setEndDate,
        shortDescription, setShortDescription,
        longDescription, setLongDescription,
        isPublic, setIsPublic,
        img, setImg,
        logo, setLogo,
        hasDefis, setHasDefis,
        hasPrizes, setHasPrizes,
        hasPartners, setHasPartners,
        hasFaq, setHasFaq,
        imgToUpload, setImgToUpload,
        logoToUpload, setLogoToUpload,
        prizes, setPrizes,
        partners, setPartners,
        questions, setQuestions,
        dares, setDares,
        prizesToDelete, setPrizesToDelete,
        partnersToDelete, setPartnersToDelete,
        questionsToDelete, setQuestionsToDelete,
        daresToDelete, setDaresToDelete,
        filesToDelete, setFilesToDelete,
        handleDeleteLogo, handleDeleteImg,

        // sign-up
        registrationStartDate, setRegistrationStartDate,
        registrationEndDate, setRegistrationEndDate,
        totalParticipants, setTotalParticipants,
        typeParticipation, setTypeParticipation,
        nbMinParticipants, setNbMinParticipants,
        nbMaxParticipants, setNbMaxParticipants,
        eligibilities, setEligibilities,
        rules, setRules,
        fileName, setFileName,
        fileNameStorage, setFileNameStorage,
        linkRules, setLinkRules,
        fileToDelete, setFileToDelete,
        error, setError,

        // ressources
        fetchResources,
        resources, setResources,
        errorResource, setErrorResource,
        resourcesToDelete, setResourcesToDelete,
        openSnackBarResource, setOpenSnackBarResource,
        textSnackBarResource, setTextSnackBarResource,
        variantSnackBarResource, setVariantSnackBarResource,
        // filesResource, setFilesResource,
        // filesResourceToDelete, setFilesResourceToDelete

        // ressources, setRessources,
        // ressourcesToDelete, setRessourcesToDelete,
        // addRessources, getAllRessources, updateRessources, deleteRessources,
        // newRessources, setNewRessources,
        // currentRessources, setCurrentRessources,
        // ressourcesToUpdate, setRessourcesToUpdate,
        // ressourceFile, setRessourceFile,
        // ressourceFileNameStorage, setRessourceFileNameStorage,
        // ressourceFileName, setRessourceFileName,
        // ressourceFileToDelete, setRessourceFileToDelete,

        // steps
        listSteps, setListSteps,
        originalSteps, setOriginalSteps,
        updateListSteps, setUpdateListSteps,
        newStep, setNewStep,
        stepToDelete, setStepToDelete,
        timeSlotError, setTimeSlotError,
        errorStep, setErrorStep,
        // steps, setSteps,
        // stepsToDelete, setStepsToDelete,
        // //question
        // questionsStep, setQuestionsStep,
        // questionsStepToDelete, setQuestionsStepToDelete,
        // //deliverable
        // deliverablesStep, setDeliverablesStep,
        // deliverablesStepToDelete, setDeliverablesStepToDelete,

        // criteria
        criteria, setCriteria,
        criteriaToDelete, setCriteriaToDelete,
        fetchCriteria,
        // others
        hasClickedOnCancelButton,
        truc, setTruc,
        truc2, setTruc2,

    } = useOutletContext() as ContextType;
    return {
        // general
        change, setChange,
        click, setClick,
        page, setPage,
        id,
        getOneChallenge,
        challenge, setChallenge,
        challengeOrigin, setChallengeOrigin,
        errorFieldGeneral, setErrorFieldGeneral,
        imagePrices, setImagePrices,
        imagePartners, setImagePartners,
        regulation, setRegulation,
        openSnackBarEditChallenge, setOpenSnackBarEditChallenge,
        variantSnackEditChallenge, setVariantSnackBarEditChallenge,
        textSnackBarEditChallenge, setTextSnackBarEditChallenge,
        openSnackBarEditRegistration, setOpenSnackBarEditRegistration,
        variantSnackEditRegistration, setVariantSnackBarEditRegistration,
        textSnackBarEditRegistration, setTextSnackBarEditRegistration,

        //  setId,
        title, setTitle,
        startDate, setStartDate,
        endDate, setEndDate,
        shortDescription, setShortDescription,
        longDescription, setLongDescription,
        isPublic, setIsPublic,
        img, setImg,
        logo, setLogo,
        hasDefis, setHasDefis,
        hasPrizes, setHasPrizes,
        hasPartners, setHasPartners,
        hasFaq, setHasFaq,
        imgToUpload, setImgToUpload,
        logoToUpload, setLogoToUpload,
        prizes, setPrizes,
        partners, setPartners,
        questions, setQuestions,
        dares, setDares,
        prizesToDelete, setPrizesToDelete,
        partnersToDelete, setPartnersToDelete,
        questionsToDelete, setQuestionsToDelete,
        daresToDelete, setDaresToDelete,
        filesToDelete, setFilesToDelete,
        handleDeleteLogo, handleDeleteImg,

        // sign-up
        registrationStartDate, setRegistrationStartDate,
        registrationEndDate, setRegistrationEndDate,
        totalParticipants, setTotalParticipants,
        typeParticipation, setTypeParticipation,
        nbMinParticipants, setNbMinParticipants,
        nbMaxParticipants, setNbMaxParticipants,
        eligibilities, setEligibilities,
        rules, setRules,
        fileName, setFileName,
        fileNameStorage, setFileNameStorage,
        linkRules, setLinkRules,
        fileToDelete, setFileToDelete,
        error, setError,

        // ressources
        resources, setResources,
        errorResource, setErrorResource,
        resourcesToDelete, setResourcesToDelete,
        openSnackBarResource, setOpenSnackBarResource,
        textSnackBarResource, setTextSnackBarResource,
        variantSnackBarResource, setVariantSnackBarResource,
        fetchResources,
        // filesResource, setFilesResource,
        // filesResourceToDelete, setFilesResourceToDelete
        // ressources, setRessources,
        // ressourcesToDelete, setRessourcesToDelete,
        // addRessources, getAllRessources, updateRessources, deleteRessources,
        // newRessources, setNewRessources,
        // currentRessources, setCurrentRessources,
        // ressourcesToUpdate, setRessourcesToUpdate,
        // ressourceFile, setRessourceFile,
        // ressourceFileNameStorage, setRessourceFileNameStorage,
        // ressourceFileName, setRessourceFileName,
        // ressourceFileToDelete, setRessourceFileToDelete,

        // steps
        listSteps, setListSteps,
        originalSteps, setOriginalSteps,
        updateListSteps, setUpdateListSteps,
        newStep, setNewStep,
        stepToDelete, setStepToDelete,
        timeSlotError, setTimeSlotError,
        errorStep, setErrorStep,
        // steps, setSteps,
        // stepsToDelete, setStepsToDelete,
        // //question
        // questionsStep, setQuestionsStep,
        // questionsStepToDelete, setQuestionsStepToDelete,
        // //deliverable
        // deliverablesStep, setDeliverablesStep,
        // deliverablesStepToDelete, setDeliverablesStepToDelete,

        // criteria
        criteria, setCriteria,
        criteriaToDelete, setCriteriaToDelete,
        fetchCriteria,
        // others
        hasClickedOnCancelButton,
        truc, setTruc,
        truc2, setTruc2,
    };
}