import React from "react";
import "./index.css"
import {IMyProject} from "./types";
import {DEFAULT_CHALLENGE_PICTURE} from "../../utils/consts";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";

const MyProject: React.FC<IMyProject> = ({
                                             challengeId,
                                             projectId,
                                             challengeName,
                                             projectFileUrl,
                                             projectName,
                                             doneSteps,
                                             totalSteps,
                                             projectDescription,
                                             projectStatusClass,
                                             projectStatus
                                         }) => {
    const navigate = useNavigate();
    return (
        <div className="my-project">
            <div className="coverImg">
                <img src={projectFileUrl ? projectFileUrl : DEFAULT_CHALLENGE_PICTURE}
                     alt='projectPicture'/>
            </div>
            { projectId && projectStatusClass != "draft" &&(
                <div className="projectInfos">
                    <h3>{projectName}</h3>
                    <div className={"step-status"}>
                        <b>Etape {doneSteps} sur {totalSteps}</b>
                        <p className={projectStatusClass}>{projectStatus}</p>
                    </div>
                    {/*<p>*/}
                    {/*    <b>Du {moment(challengeStartDate).format("L")} au {moment(challengeEndDate).format("L")}</b>*/}
                    {/*</p>*/}
                    <p>{projectDescription}</p>
                </div>
            )}
        </div>
    )

}

export default MyProject;