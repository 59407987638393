    import {useEffect} from "react";

const SetUpTranslation = (i18n: { changeLanguage: (arg0: string) => void; }) => {
    useEffect(() => {
        const handleTranslationChange = (event: Event) => {
            const customEvent = event as CustomEvent;
            i18n.changeLanguage(customEvent.detail.newTranslation);
        };

        window.addEventListener("translationChanged", handleTranslationChange);
        return () => {
            window.removeEventListener("translationChanged", handleTranslationChange);
        };
    }, []);
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("translation")!);
    }, []);
}

export default SetUpTranslation;
