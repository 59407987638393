// react-router
import {useNavigate} from "react-router-dom";

// style
import './index.css'

interface GoBackProps {
    children?: React.ReactNode;
    variant?: string;
    onClick?: () => void;
    className?: string;
}

const GoBack = ({children, onClick, variant, className}: GoBackProps) => {

    // navigation
    const navigate = useNavigate();

    return (
        <>
            <button
                className={`button-go-back ${className}`}
                onClick={
                    function () {
                        onClick && onClick()
                    }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.5 18.5L8.5 12L15.5 5.5" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
                {/*<p className={variant === 'text-mobile' ? 'add-text-mobile' : 'delete-text-mobile'}>{children}</p>*/}
                <p className={`${variant === 'text-mobile' ? 'add-text-mobile' : ''}`}>{children}</p>
            </button>
        </>
    )
};

export default GoBack;