import {useEffect, useState} from "react";
import {useParams, useNavigate, createSearchParams} from "react-router-dom";
import "./index.css"
import ActionButton from "../../../../../components/ActionButton";
import {Avatar, Chip, Grid} from "@mui/material";
import Modal from "../../../../../modules/Modal";
import {ReactComponent as IconInfo} from "../../../../../assets/icon-info.svg";
import {ReactComponent as IconLike} from "../../../../../assets/icon-like.svg";
import {IData, IErrorJoinTeam, ITeam} from "./types";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";

const ParticipantTeamsJoinInvite = () => {
    const navigate = useNavigate();
    const challengeId = useParams().id;
    const teamId = useParams().teamId;
    const [teamList, setTeamList] = useState<IData>();

    const [openConfirmJoinTeam, setOpenConfirmJoinTeam] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<ITeam>();

    const [sucessConfirmJoinTeam, setSucessConfirmJoinTeam] = useState<boolean>(false);

    const [errorOpenConfirmJoinTeam, setErrorOpenConfirmJoinTeam] = useState<boolean>(false);
    const [errorConfirmJoinTeam, setErrorConfirmJoinTeam] = useState<IErrorJoinTeam | null>(null);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    //Get au chargement de la page
    const getTeamList = async () => {
        try {
            const res = await api.get("/participant/team/invite",
                {
                    params: {
                        challengeId: challengeId,
                        teamId: teamId
                    },
                    withCredentials: true
                }
            );

            if (res.status === 200) {
                setTeamList(res.data);
            }
        } catch (err) {
            console.error("Error in getTeamList:", err);
        }
    };

    useEffect(() => {
        console.log("Component mounted");
        getTeamList();
    }, []);

    //Rejoindre une équipe
    const joinTeam = async (teamId: number) => {
        console.log(teamId);
        setErrorOpenConfirmJoinTeam(false)
        setErrorConfirmJoinTeam(null)
        setOpenConfirmJoinTeam(false)
        await api.post("/participant/team/jointeam",
            {
                teamId: teamId,
                challengeId: challengeId,
                language: localStorage.getItem("translation"),
            },
            {
                withCredentials: true
            }
        )
            .then((res) => {
                console.log("joinTeam", res.data);
                setOpenConfirmJoinTeam(false)
                if (res.status === 200) {
                    setSucessConfirmJoinTeam(true)
                    getTeamList();
                }
            })
            .catch((err) => {
                console.log(err);
                setErrorOpenConfirmJoinTeam(true)
                setErrorConfirmJoinTeam(err.response.data)
            })
    }


    const confirmJoinTeam = (team: any) => {
        console.log("id====>", team.id);
        setSelectedTeam(team)
        // joinTeam(id)
        setOpenConfirmJoinTeam(true)
    }

    const redirectToProject = (team: ITeam) => {
        // navigate(`/participant/challenge/${challengeId}/project/${String(team.projects?.[0]?.id)}/edit`)
        navigate(`/participant/challenge/project/edition/${String(team.projects?.[0]?.id)}`)
    }

    useEffect(() => {
        console.log("errorConfirmJoinTeam", errorConfirmJoinTeam);
    }, [errorConfirmJoinTeam])

    return (
        <section id="list-teams-join">
            {teamList && teamList.team && (
                <>
                    <div id="bloc-info-teams-join">
                        <h1 className="text-center mt-4">{t("page.participantJoinTeamInvite.title")}</h1>
                        <p className="text-center mt-2">{t("page.participantJoinTeamInvite.text1")}
                            <span>{teamList && teamList.team && teamList.team.name}</span> !</p>
                    </div>
                    <div id="teams-open" className="mt-3">
                        <Grid container spacing={2} marginTop={1} justifyContent={"center"}>
                            {teamList && teamList.team && (
                                <Grid item xs={12} md={6}>
                                    <div className="card-team-join">
                                        <div className="content">
                                            <div className="card-team-join-header">
                                                <p>{t("page.participantJoinTeamInvite.team")} {teamList.team.numero} - {teamList && teamList.team.projects && teamList.team.projects[0].portfolios.length} {t("page.participantJoinTeamInvite.member")}{teamList && teamList.team.projects && teamList.team.projects[0].portfolios.length > 1 ? "s" : ""}</p>
                                                <h5 className="team-name">{teamList.team.name}</h5>
                                                <Grid container spacing={1}>
                                                    {teamList && teamList.team.projects && teamList.team.projects[0].portfolios.map((portfolio, index) => {
                                                            return (
                                                                <Grid item xs={"auto"} key={index}>
                                                                    <Chip sx={{
                                                                        height: '40px',
                                                                        background: "#fff",
                                                                        borderRadius: '20px'
                                                                    }} key={index} avatar={
                                                                        <Avatar
                                                                            sx={{
                                                                                height: '40px !important',
                                                                                width: '40px !important',
                                                                                marginLeft: '0px !important',
                                                                                marginRight: '0px !important'
                                                                            }}
                                                                            alt={portfolio.profile.user.firstname}
                                                                            src={portfolio.profile.user.profile_picture ? portfolio.profile.user.profile_picture : ""}
                                                                        >

                                                                        </Avatar>}
                                                                          label={`${portfolio.profile.user.firstname} ${portfolio.profile.user.lastname}`}
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                    )}
                                                </Grid>
                                            </div>
                                            {teamList.team.bio &&
                                                <div className="card-team-join-bio">
                                                    <p className="word-team">Mot de l'équipe :</p>
                                                    <p className="bio">{teamList.team.bio}</p>
                                                </div>
                                            }
                                        </div>
                                        <ActionButton
                                            variant={"primary-card"}
                                            //variant={`${teamList.isInTeam ? "disabled-card" : "primary-card"}`}
                                            onClick={() => {
                                                teamList.isInTeam ? redirectToProject(teamList.team) : confirmJoinTeam(teamList.team)
                                            }
                                            }
                                        >
                                            {teamList.isInTeam ? t("page.participantJoinTeamInvite.seeProject") : t("page.participantJoinTeamInvite.joinTeam")}
                                        </ActionButton>
                                    </div>
                                </Grid>
                            )}
                        </Grid>

                        <div className="text-center mt-3">
                            <ActionButton
                                variant={"little-underline"}
                                onClick={() => {
                                    navigate(`/challenge/${challengeId}/general`)
                                }}>
                                {t("page.participantJoinTeamInvite.seeChallenge")}
                            </ActionButton>
                        </div>

                    </div>

                    <Modal
                        open={openConfirmJoinTeam}
                        onClose={() => setOpenConfirmJoinTeam(false)}
                        title={`${t("page.participantJoinTeamInvite.modal.title")} ${selectedTeam?.name} ?`}
                        tertiaryButtonText={`${t("page.participantJoinTeamInvite.modal.cancel")}`}
                        tertiaryButtonAction={() => setOpenConfirmJoinTeam(false)}
                        primaryButtonText={`${t("page.participantJoinTeamInvite.modal.join")}`}
                        primaryButtonAction={() => joinTeam(selectedTeam?.id ? selectedTeam?.id : 0)}
                    >
                        {/*<p>{t("page.participantJoinTeamInvite.modal.text1")}</p> */}
                        <Grid container spacing={1}>
                            {selectedTeam?.projects?.[0]?.portfolios && (
                                selectedTeam.projects[0].portfolios.map((portfolio, index) => (
                                    <Grid item xs={"auto"} key={index}>
                                        <Chip sx={{height: '40px', background: "#EDF1F7", borderRadius: '20px'}}
                                              size="medium" key={index} avatar={<Avatar sx={{
                                            height: '40px !important',
                                            width: '40px !important',
                                            marginLeft: '0px !important',
                                            marginRight: '0px !important'
                                        }} alt="Natacha"
                                                                                        src={portfolio.profile.user.profile_picture ? portfolio.profile.user.profile_picture : ""}/>}
                                              label={`${portfolio.profile.user.firstname} ${portfolio.profile.user.lastname}`}/>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </Modal>
                    <Modal
                        icon={<IconLike/>}
                        open={sucessConfirmJoinTeam}
                        onClose={() => setSucessConfirmJoinTeam(false)}
                        title={`${t("page.participantJoinTeamInvite.modal2.title")} ${selectedTeam?.numero} !`}
                        primaryButtonText={`${t("page.participantJoinTeamInvite.modal2.discoverMyProject")}`}
                        primaryButtonAction={() => redirectToProject(selectedTeam as ITeam)}
                    >
                        <p>{t("page.participantJoinTeamInvite.modal2.text1")}</p>
                    </Modal>
                    <p>{errorConfirmJoinTeam && errorConfirmJoinTeam.message}</p>
                    <Modal
                        open={errorOpenConfirmJoinTeam}
                        onClose={() => setErrorOpenConfirmJoinTeam(false)}
                        title={`${errorConfirmJoinTeam && errorConfirmJoinTeam.error === "AlreadyInTeam" ? t("page.participantJoinTeamInvite.modal3.title1") : t("page.participantJoinTeamInvite.modal3.title2")}`}
                        primaryButtonText={`${t("page.participantJoinTeamInvite.modal3.button")}`}
                        primaryButtonAction={() => {
                            setErrorConfirmJoinTeam(null);
                            setErrorOpenConfirmJoinTeam(false)
                        }}
                        error={errorConfirmJoinTeam && errorConfirmJoinTeam.message}
                    >
                    </Modal>
                </>
            )}

        </section>
    )
}

export default ParticipantTeamsJoinInvite
