import { Action } from 'redux';
import { IAnswerQuestion, IDefaultStep, Project, ProjectState } from '../type';


interface SetProjectsAction extends Action<'SET_DEFAULT_PROJECTS_IN_PROGRESS'> {
    payload: {
        projects: ProjectState[];
    };
}

interface SetProjectInProgressAction extends Action<'SET_DEFAULT_DATA_PROJECT_IN_PROGRESS'> {
    payload: {
        projectId: number;
        stepId: number;
        // status: IStatusInprogress;
        questions: IAnswerQuestion[] | [] 
        defaultStep: IDefaultStep;
    };
}

interface SetDefaultQuestionProjectInProgressAction extends Action<'SET_DEFAULT_QUESTION_PROJECT_IN_PROGRESS'> {
    payload: {
        title_project: string;
        short_desc: string;
        long_desc: string;
    };
}

// interface SetQuestionSepInProgressAction extends Action<'SET_QUESTION_STEP_IN_PROGRESS'> {
//     payload: {
//         answerQuestionStep: { answers: IAnswerQuestion[] | [] };
//     };
// }


// Initial State
const initialState: Project = {
    projects: [],
};


// Reducer
const projectReducer = (state = initialState, action: Action): Project => {
  switch (action.type) {
    case 'SET_DEFAULT_DATA_PROJECT_IN_PROGRESS':
    const setDefaultDataProjectAction = action as SetProjectInProgressAction;    
    // Recherchez le projet correspondant en fonction de ProjectId et StepId
    const existingProjectIndex = state.projects.findIndex((project) =>
        project.projectId === setDefaultDataProjectAction.payload.projectId &&
        project.stepId === setDefaultDataProjectAction.payload.stepId
    );

    if (existingProjectIndex !== -1) {
        // Mise à jour du projet existant avec les données du nouvel action
        const updatedProjects = [...state.projects];
        updatedProjects[existingProjectIndex] = {
        ...updatedProjects[existingProjectIndex],
        questions: setDefaultDataProjectAction.payload.questions,
        defaultStep: setDefaultDataProjectAction.payload.defaultStep,
        };

        return {
        projects: updatedProjects,
        };
    } else {
        // Ajoutez un nouveau projet si aucun projet correspondant n'est trouvé
        return {
            projects: [
                ...state.projects,
                {
                    projectId: setDefaultDataProjectAction.payload.projectId,
                    stepId: setDefaultDataProjectAction.payload.stepId,
                    questions: setDefaultDataProjectAction.payload.questions,
                    defaultStep: setDefaultDataProjectAction.payload.defaultStep,
                },
            ],
        };
    }

        

    // case 'SET_DEFAULT_QUESTION_PROJECT_IN_PROGRESS':
    //     const SetDefaultQuestionProjectInProgressAction = action as SetDefaultQuestionProjectInProgressAction;
    //     console.log("SET_DEFAULT_QUESTION_PROJECT_IN_PROGRESS", ...state.projects);
        

    // case 'SET_QUESTION_STEP_IN_PROGRESS':
    //     const setQuestionSepInProgressAction = action as SetQuestionSepInProgressAction;
    //     const answers = setQuestionSepInProgressAction.payload.answerQuestionStep.answers;        


    case 'CLEAR_PROJECT':        
      return initialState;

    default:
      return state;
  }
};

export default projectReducer;
