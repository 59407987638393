// page de validation de création de compte
// cette page indique à l'utilisateur que son compte a bien été créé

// assets
import {ReactComponent as ImgMobile} from '../../../../../../assets/bandeau-bienvenue-mobile.svg';
import {ReactComponent as ImgDesktop} from '../../../../../../assets/bandeau-bienvenue-desktop.svg';

// Components
import ActionButton from '../../../../../../components/ActionButton'

// react-dom
import {Link, useNavigate, useLocation} from "react-router-dom"

// material-ui
import {
    TextField,
    Step,
    StepConnector,
    Stepper,
    stepConnectorClasses,
    styled,
    useTheme,
    useMediaQuery
} from '@mui/material';

// style
import '../index.css';
import {useAuth} from "../../../../../../context/AuthContext";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";

const CreateAccountDone = () => {

    // fonction qui permet de naviguer vers une autre page
    const navigate = useNavigate();

    // récupérer le paramètre d'URL
    const location = useLocation();

    // Style des steps Material UI (voir la doc)
    const QontoConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 16,
            right: 16,
            margin: 'auto',
            borderTopWidth: 10,
            borderRadius: 2,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#EDF1F7',
                borderTopWidth: 10,
                borderRadius: 2,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#F86767',
                borderTopWidth: 10,
                borderRadius: 2,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EDF1F7',
            borderTopWidth: 10,
            borderRadius: 1,
        },

    }));

    // on défini le nombre de steps
    const steps = ['', ''];

    // on change l'étape active en fonction du paramètre d'URL
    const activeStep = 2;

    const {profile, setIsAuthenticated} = useAuth();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <>
            <div className="create-account-done">
                <h1 className='h1-auth'>{t("page.signUpParticipant.accountCreated")}</h1>
                {/* barre de progression en haut de l'écran Material UI (voir la doc) */}
                {/*<div className="stepper">*/}
                {/*    < Stepper className='progress-bar' alternativeLabel activeStep={activeStep} connector={null} >*/}
                {/*        /!* affichage du nombre de barres en fonction du nombre d'étapes *!/*/}
                {/*        {*/}
                {/*            steps.map((label) => (*/}
                {/*                <Step className='stepper'>*/}
                {/*                    <QontoConnector />*/}
                {/*                </Step>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </Stepper >*/}
                {/*</div>*/}

                {/* illustration (elle s'affiche dans le fichier CSS) */}
                <ImgMobile className='img-mobile'/>
                <ImgDesktop className='img-desktop'/>
                <p className="bold mb-5 text-signUp">{t("page.signUpParticipant.text")}</p>
                {/* bouton pour naviguer vers la page d'accueil */}
                <div className={"block-button-signup-challenge"}>
                    <div className={"button-signup-challenge"}>
                        <ActionButton
                            variant="primary"
                            type="button"
                            width={isMobile ? "15rem" : "20rem"}
                            onClick={() => {
                                setIsAuthenticated(true);
                                const redirect = localStorage.getItem("redirect");
                                if (redirect) {
                                    localStorage.removeItem("redirect");
                                    if (!redirect.includes("/admin") && profile === "participant") {
                                        navigate(redirect, {replace: true});
                                    } else if (!redirect.includes("/participant") && profile === "admin") {
                                        navigate(redirect, {replace: true});
                                    } else {
                                        navigate("/", {replace: true});
                                    }
                                } else {
                                    navigate("/", {replace: true});
                                }
                            }}
                        >
                            {t("page.signUpParticipant.registerChallenge")}
                        </ActionButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateAccountDone;