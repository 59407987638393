import ActionButton from "../../components/ActionButton";
import React from "react";
import {ReactComponent as DiamonColorsHorizontal} from "../../assets/diamond-colors-horizontal.svg";
import {useNavigate} from "react-router-dom";
import "./index.css";
import SwitchTranslate from "../../components/SwitchTranslate";

const LoginHeader2 = () => {
    const navigate = useNavigate();
    return (
        <div className='loginHeader2'>
            <SwitchTranslate className={"translateIcon"}/>
            <DiamonColorsHorizontal className={"loginHeader2-icon"} onClick={() => navigate("/")} />
        </div>
    )
}

export default LoginHeader2;
