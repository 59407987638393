import {Box, Grid, LinearProgress, LinearProgressProps, Typography} from '@mui/material';
import "./index.css";
import {useEffect, useRef, useState} from 'react';

interface ISteps {
    totalSteps: number,
    activeStep: number,
    runningStep?: number,
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress {...props}
                                sx={{
                                    '&.MuiLinearProgress-root': {
                                        height: 8,
                                    },
                                    '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
                                        backgroundColor: "#EDF1F7",
                                    },
                                    '& .MuiLinearProgress-barColorPrimary': {
                                        background: 'linear-gradient(275deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%)'
                                    },
                                    '& .MuiLinearProgress-colorPrimary': {
                                        background: '#F86767',
                                        animation: "animation-1lq7mgo 3s infinite linear"
                                    },
                                    '& .MuiLinearProgress-dashed': {
                                        backgroundImage: 'radial-gradient(rgb(167, 202, 237) 0%, #EDF1F7 30%, transparent 42%)',
                                        height: 8,
                                        marginTop: '2.3px'
                                    },
                                }}/>
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function StepperGradient({totalSteps, activeStep, runningStep}: ISteps) {
    const [progress, setProgress] = useState(0);
    const [bufferSteps, setBufferSteps] = useState(0);
    const prevActiveStepRef = useRef(activeStep);
    const prevTotalStepsRef = useRef(totalSteps);
    const prevProgressRef = useRef(progress);
    const prevBufferStepsRef = useRef(bufferSteps);
    useEffect(() => {
        const prevActiveStep = prevActiveStepRef.current;
        const prevTotalSteps = prevTotalStepsRef.current;
        if (activeStep !== prevActiveStep || totalSteps !== prevTotalSteps) {
            setProgress((activeStep / totalSteps) * 100);
        }
        if (runningStep && runningStep > 0) {
            setBufferSteps(((activeStep + runningStep) / totalSteps) * 100);
        }
        prevActiveStepRef.current = activeStep;
        prevTotalStepsRef.current = totalSteps;
        prevProgressRef.current = (activeStep / totalSteps) * 100;
        if (runningStep && runningStep > 0) prevBufferStepsRef.current = ((activeStep + runningStep) / totalSteps) * 100;
        console.log("prevActiveStepRef.current", prevActiveStepRef.current);
        console.log("prevTotalStepsRef.current", prevTotalStepsRef.current);
        console.log("prevProgressRef.current", prevProgressRef.current);
        console.log("prevBufferStepsRef.current", prevBufferStepsRef.current);
    }, [activeStep, runningStep, totalSteps]);
    const generateStep = (index: number) => {
        let backgroundSize = activeStep * 100; // Par exemple, si 3 étapes actives, background-size = 300%
        // let backgroundPosition = '';
        let tab = []
        for (let i = 0; i < activeStep; i++) {
            const start = (i * 100) / activeStep;
            const end = ((i + 1) * 100) / activeStep;
            tab.push(`${start}% ${end}%`);
        }
        return {
            'background': 'linear-gradient(275deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%)',
            'backgroundSize': `${backgroundSize}% 100%`,
            'backgroundPosition': index < prevActiveStepRef.current ? tab[index] : '0% 0%',
            'animation': `${4 / activeStep}s linear ${(4 / activeStep) * index}s gradient-fill`
        };
    }
    return (
        <div className='test'>
        <div id='stepper'>
            {/* <p style={{backgroundSize}}></p> */}
            {prevTotalStepsRef.current < 7 ? (
                <>
                    <Grid container spacing={1} justifyContent={"space-between"} direction={"row"} height={"8px"}>
                        {Array.from({length: prevTotalStepsRef.current}).map((_, index) => (
                            <Grid item xs key={index}>
                                <div
                                    className={`step ${index >= prevActiveStepRef.current && runningStep && index < (prevActiveStepRef.current + runningStep) ? 'running' : ''}`}
                                    style={index < prevActiveStepRef.current ? generateStep(index) : {

                                    }}>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </>
            ) : (
                <>
                    <Box sx={{width: '100%'}}>
                        {runningStep && runningStep > 0 ? (
                            <LinearProgressWithLabel variant='buffer' value={prevProgressRef.current}
                                                     valueBuffer={prevBufferStepsRef?.current}/>
                        ) : (
                            <LinearProgressWithLabel variant="determinate" value={prevProgressRef.current}/>
                        )
                        }
                    </Box>
                </>
            )}
        </div>
        </div>
    );
}