import React from "react";

import {useEffect, useState} from "react";

//style
import "./index.css";

const Footer = () => {
    return (
        <footer></footer>
    );
}

export default Footer;