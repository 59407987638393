import { styled, useMediaQuery, useTheme } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';

const StyledSwitchButton = (props: SwitchProps) => {
  return (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  );
};

const SwitchButton = styled(StyledSwitchButton)(({ theme, size }) => ({
      width: size === 'small' ? 36 : size === 'medium' ? 42 : 42,
      height: size === 'small' ? 20 : size === 'medium' ? 26 : 20,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#F86767' : '#F86767',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: size === 'small' ? 16 : size === 'medium' ? 22 : 16,
        height: size === 'small' ? 16 : size === 'medium' ? 22 : 16,
      },
      '& .MuiSwitch-track': {
        borderRadius: size === 'small' ? 20 / 2:26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#122236' : '#122236',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
  }));

  export default SwitchButton;