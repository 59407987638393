// style
import './index.css';

// hooks
import { useSortable } from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';

// icons
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { ReactComponent as IconDragNDrop } from '../../assets/icon-dragndrop.svg';

// utilities
import { CSS } from '@dnd-kit/utilities';
import ItemFAQ from '../QuestionList/types';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge';


interface QuestionProps {
    question: ItemFAQ;
    handleDeleteQuestion: (question: ItemFAQ) => void;
    handleUpdateQuestion: (question: ItemFAQ) => void;
    disabled?: boolean;
}



const Question = ({ question, handleDeleteQuestion, handleUpdateQuestion, disabled }: QuestionProps) => {
    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: question.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <div ref={setNodeRef} {...attributes} style={style} {...listeners}>
            <TextField
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(question.id) && (error.code === 'itemFAQ')) || (error.code === 'itemFAQs'))?true:false}
                variant="outlined"
                fullWidth={true}
                defaultValue={question.question}
                // placeholder="Question ?"
                multiline
                inputProps={{ maxLength: 50 }}
                // select
                label="Question *"
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconClose 
                            className='icon-close icon-clickable cursor-pointer' 
                            // onClick={disabled?() => {} :() => removeDeliverableFromStep(String(stepId), String(deliverable.id))}
                            onClick={disabled?() => {}:() => handleDeleteQuestion(question)}
                        />
                        <IconDragNDrop className={`icon-dragndrop icon-clickable ${!disabled&& 'draggable'} cursor-pointer`}/>
                        {/* <IconDragNDrop className={`icon-dragndrop icon-clickable draggable cursor-pointer`}/> */}
                    </InputAdornment>,
                }}
                InputLabelProps={{
                    style: { 
                        color: '#a9a9a9',
                        fontWeight: '400'
                    },
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '0px',
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }     
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, name: e.target.value })}
                onChange={(e) => handleUpdateQuestion({ ...question, question: e.target.value })}
                // disabled={disabled}
            />
            <TextField
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(question.id) && (error.code === 'itemFAQ')) || (error.code === 'itemFAQs'))?true:false}
                helperText={errorFieldGeneral.find((error:any) => (String(error.id) === String(question.id) && (error.code === 'itemFAQ')) || (error.code === 'itemFAQs'))?errorFieldGeneral.find((error:any) => (String(error.id) === String(question.id) && (error.code === 'itemFAQ')) || (error.code === 'itemFAQs')).message:''}
                variant="outlined"
                fullWidth={true}
                defaultValue={question.answer}
                placeholder="Réponse"
                multiline
                maxRows={3}
                minRows={2}
                inputProps={{ maxLength: 500 }}
                InputProps={{
                    startAdornment: <InputAdornment sx={{marginBottom:'10px'}} position="start">Réponse (max 500 caractères) *</InputAdornment>,
                }}
                // disabled={disabled}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        display:'block',
                        background: "#fff",
                        // borderRadius: '10px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '10px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '10px',
                        '& fieldset' :{
                            borderTop: '0px',
                        },

                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }   
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, description: e.target.value })}
                onChange={(e) => handleUpdateQuestion({ ...question, answer: e.target.value })}
                // disabled={disabled}
            />
        </div>
        // <div className="question" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        //     <div className="question-header">
        //         <div className="question-title">
        //             <div>
        //                 {isEditingQuestion ? (
        //                     <h2 className="h1 form-title category-title">
        //                         Question
        //                     </h2>
        //                 ) : (
        //                     <textarea
        //                         ref={questionTextArea}
        //                         name='questionName'
        //                         id={"question-name-" + question.id}
        //                         className="text input-editable"
        //                         placeholder="Question"
        //                         value={currentQuestion.question}
        //                         maxLength={250}
        //                         onChange={(e) => {
        //                             setCurrentQuestion({
        //                                 ...currentQuestion,
        //                                 question: e.target.value
        //                             });
        //                             handleUpdateQuestion({
        //                                 ...currentQuestion,
        //                                 question: e.target.value
        //                             });
        //                         }} onClick={handleEditingQuestion} />
        //                 )}
        //                 <span className="icons">
        //                     <IconClose className='icon-close icon-clickable' onClick={() => handleDeleteQuestion(question)} />
        //                     <IconDragNDrop className='icon-dragndrop icon-clickable draggable' />
        //                 </span>
        //             </div>
        //             {isEditingQuestion && (
        //                 <textarea
        //                     ref={questionTextArea}
        //                     name='questionName'
        //                     id={"question-name-" + question.id}
        //                     className="text input-editable"
        //                     placeholder="Question"
        //                     value={currentQuestion.question}
        //                     maxLength={250}
        //                     onChange={(e) => {
        //                         setCurrentQuestion({
        //                             ...currentQuestion,
        //                             question: e.target.value
        //                         });
        //                         handleUpdateQuestion({
        //                             ...currentQuestion,
        //                             question: e.target.value
        //                         });
        //                     }} />
        //             )}
        //         </div>
        //     </div>
        //     <div className="question-answer">
        //         {isEditingAnswer ? (
        //             <h2 className="h1 form-title category-title">Réponse <span className="thin">(max 500 caractères)</span></h2>
        //         ) : (
        //             <textarea 
        //             ref={answerTextArea} 
        //             name='questionAnswer' 
        //             id={"question-answer-" + question.id} 
        //             className='text input-editable' 
        //             placeholder='Réponse' 
        //             value={question.answer} 
        //             maxLength={500}
        //             onChange={(e) => {
        //                 setCurrentQuestion({
        //                     ...currentQuestion,
        //                     answer: e.target.value
        //                 });
        //                 handleUpdateQuestion({
        //                     ...currentQuestion,
        //                     answer: e.target.value
        //                 });
        //             }} onClick={handleEditingAnswer} />
        //         )}
        //         {isEditingAnswer && (
        //             <textarea 
        //             ref={answerTextArea} 
        //             name='questionAnswer' 
        //             id={"question-answer-" + question.id} 
        //             className='text input-editable' 
        //             placeholder='Réponse' 
        //             value={question.answer} 
        //             maxLength={500}
        //             onChange={(e) => {
        //                 setCurrentQuestion({
        //                     ...currentQuestion,
        //                     answer: e.target.value
        //                 });
        //                 handleUpdateQuestion({
        //                     ...currentQuestion,
        //                     answer: e.target.value
        //                 });
        //             }} />
        //         )}
        //     </div>
        // </div>
    )
}

export default Question;