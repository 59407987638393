import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import "./index.css";
import {FC} from "react";
import {useNavigate} from "react-router-dom";

interface ILink {
    label: string;
    key: string;
    link?: string;
    onClick?: () => void;
}

interface INavTabs {
    links: ILink[];
}

const NavTabs: FC<INavTabs> = ({
                                   links,
                               }) => {
        const [value, setValue] = React.useState(0);
        const handleChange = (event: React.SyntheticEvent, newValue: number) => {
            setValue(newValue);
        };
        const navigate = useNavigate();
        return (
            <div className='nav-tabs'>
                <Box sx={{border: "1px solid black", borderRadius: "10px"}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                        TabIndicatorProps={{
                            style: {
                                width: "0"
                            }
                        }}
                        sx={{
                            '.MuiTabs-scrollButtons.Mui-disabled': {
                                opacity: 0.3
                            }
                        }}
                    >
                        {
                            links.map((link, index:number) => (
                                <Tab
                                    key={index}
                                    label={link.label}
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: 'black',
                                            color: 'white',
                                        }
                                    }}
                                    onClick={() => link.link && navigate(link.link)}
                                />
                            ))
                        }
                    </Tabs>
                </Box>
            </div>
        )
            ;

    }
;

export default NavTabs;