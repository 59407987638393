// dnd-kit
import {
    DndContext,
    useSensor
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';

import { v4 as uuidv4 } from 'uuid';

// sensor
import GlobalPointerSensor from '../../utils/GlobalPointerSensor';

// types
import { IStepDeliverable } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeSteps/types';

// react
import React, { useEffect, useState } from 'react';

// components
import ActionButton from '../../components/ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';

import { Box } from '@mui/material';
import DeliverableStep from '../../components/DeliverableStep';
// import './index.css';

interface DeliverableListProps {
    deliverables: IStepDeliverable[];
    stepId: number | string;
    addDeliverableToStep: (stepId: string, questionName: string) => void;
    updateDeliverablePosition: (stepId: string, newDeliverables:any) => void;
    updateDeliverable: (stepId: string, updatedDeliverable: IStepDeliverable) => void;
    removeDeliverableFromStep: (stepId: string, deliverableId: string) => void;
    disabled?: boolean;
}

const DragAndDropDeliverables = ({deliverables,stepId,addDeliverableToStep,updateDeliverablePosition,updateDeliverable,removeDeliverableFromStep, disabled}: DeliverableListProps) => {

    const sensors = [useSensor(GlobalPointerSensor)];
    // Utilisez useMemo pour trier les questions lorsque la liste change
    const [sortedDeliverables, setSortedDeliverables] = useState<IStepDeliverable[]>(deliverables.sort((a, b) => a.position - b.position));

    
    const handleDragEnd = (event: any) => {
        // setChange(true);
        const { active, over } = event;
        console.log("active", active);
        console.log("over", over);
        
        if(active.id !== over.id) {
            const oldIndex = deliverables.findIndex((deliverable) => deliverable.id === active.id);
            const newIndex = deliverables.findIndex((deliverable) => deliverable.id === over.id);

            console.log("indexes", oldIndex, newIndex)

            const newdeliverables = deliverables.map((deliverable) => {
                console.log("ids", deliverable.id, active.id, over.id)
                if(deliverable.id === active.id){
                    return {
                        ...deliverable,
                        position: newIndex + 1,
                    }
                } else if(deliverable.id === over.id){
                    return {
                        ...deliverable,
                        position: oldIndex + 1,
                    }
                }
                return deliverable;
            });
            updateDeliverablePosition(String(stepId), newdeliverables);
        }
    }
    useEffect(() => {
        console.log("deliverables", deliverables);
        setSortedDeliverables(deliverables.sort((a, b) => a.position - b.position));
    }, [deliverables]);

    useEffect(() => {
        console.log("sortedDeliverables", sortedDeliverables);
    }, [sortedDeliverables]);
    
    return (
        <div className="question-list"> 
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={sortedDeliverables.map((deliverable: IStepDeliverable) => String(deliverable.id))}
                    strategy={verticalListSortingStrategy}
                >
                    { sortedDeliverables.length > 0 && sortedDeliverables.map((deliverable: IStepDeliverable, index) => (
                        <DeliverableStep
                            key={deliverable.id} // Utilisez une clé unique basée sur l'ID de la question
                            deliverable={deliverable}
                            stepId={String(stepId)}
                            updateDeliverable={updateDeliverable}
                            removeDeliverableFromStep={removeDeliverableFromStep}
                            disabled={disabled}
                        />
                    ))}
                </SortableContext>

            </DndContext>
            <ActionButton
                variant={disabled ? 'disabled' : 'secondary'}
                onClick={() => addDeliverableToStep(String(stepId), '')}
                className='button-add-question'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter un livrable
            </ActionButton>
        </div>
    );
}

export default DragAndDropDeliverables;