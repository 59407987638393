//Types
import { IPortfolios } from './types';

//Styles
import './index.css'

//Mui
import { Grid, useMediaQuery, useTheme } from "@mui/material";

//React
import { useEffect, useState } from 'react';

//React router
import { Link, useNavigate, useParams } from "react-router-dom";

//Assets
import { ReactComponent as EmptyPortfolio} from "../../../../assets/imgs/portfolio-vide.svg";

//Other
import { DEFAULT_CHALLENGE_PICTURE, DEFAULT_PROFILE_PICTURE } from '../../../../utils/consts';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../components/SetUpTranslation";
import api from "../../../../utils/api";

interface IProfileProject {
    id: number;
    challenge_name: string;
    project_name: string;
    project_start: string; //To moment
    project_end: string; //To moment
    short_desc: string;
}

const ParticipantProjects = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();

    const profileId = useParams().profileId;

    const [portfolios, setPortfolios] = useState<IPortfolios | null>(null)

    const fetchData = async() => {
        await api.get("/portfolio", { params: { profileId: profileId}}).then((res) => {
            res.data.portfolios.sort((a:any, b:any) => { //Tri du portfolio par date (le challenge fini le + tard arrive en haut)
                return moment.utc(b.project.challenge.endDate).valueOf() - moment.utc(a.project.challenge.endDate).valueOf()
            });
            setPortfolios(res.data);
        })
    }

    useEffect(() => {
        fetchData();
    }, [profileId])

    return (
        <div className="portfolio">
            <div className="portfolio-userDetails">
                <div className="portfolio-userDetails-picture">
                    <img src={portfolios?.user.profile_picture} alt='profilPicture'/>
                </div>
                <h2>{portfolios?.user.firstname} {portfolios?.user.lastname}</h2>
            </div>
            { portfolios && portfolios.portfolios && portfolios.portfolios!==undefined && portfolios.portfolios.length > 0 ?
                <Grid container className="portfolio-projects" justifyContent={"center"} alignItems={"flex-start"} paddingX={isMdDown ? "2rem" : "5rem"} paddingTop={"3rem"} spacing={"2rem"}>
                    {
                        portfolios?.portfolios?.map((project, index:number) => {

                            let projectItem = project.project;
                            let projectChallenge = projectItem.challenge;
                            console.log(projectItem);
                            // dates de début et de fin du challenge
                            const startDate = new Date(projectChallenge.startDate).toLocaleDateString(i18n.language, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            const endDate = new Date(projectChallenge.endDate).toLocaleDateString(i18n.language, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            return(
                                <Grid item key={index} md={6} width={"100%"}  height={"100%"}>
                                    <div className="portfolio-projects-singleProject" onClick={() => navigate(`/participant/challenge/${projectChallenge.id}/project/${projectItem.id}`)}>
                                        <div className="portfolio-projects-singleProject-challengeName"><p>{projectChallenge.name}</p></div>
                                        <div className="portfolio-projects-singleProject-coverImg">
                                            <img src={projectItem.file?.fileUrl ? projectItem.file?.fileUrl : DEFAULT_CHALLENGE_PICTURE} alt='projectPicture'/>
                                        </div>
                                        <div className="portfolio-projects-singleProject-projectInfos">
                                            <h3>{projectItem.name}</h3>
                                            <p><b>{t("challengeCard.from")} {startDate} {t("challengeCard.to")} {endDate}</b></p>
                                            <p>{projectItem.shortDesc}</p>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                :
                <div className='portfolio-empty'>
                    <p className='portfolio-empty-txt'>Il n'y a pas encore de projets réalisés.</p>
                    <EmptyPortfolio className='portfolio-empty-img'/>
                </div>
            }
        </div>
    )
}

export default ParticipantProjects;
