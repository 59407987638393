// modules
import { redirect } from "react-router-dom";
import ChallengeCard from "../../../../../../modules/ChallengeCard";

// style 
import '../../index.css'
import { Grid } from "@mui/material";

const ProfilePreviewChallenges = () => {

    const challenges = [
        {
            id: 1,
            organizer: 'Organisateur',
            title: 'Challenge 1',
            state: 'Terminé',
            description: 'Lorem ipsum dolor sit amet, consetetur adipiscing elit. Proin finibus fringilla mauris, vitae placerat tellus sodales at. Phasellus posuere.s',
            date: 'du 3 au 6 avril 2023',
            price: '1000€',
            participants: 10,
            img: 'https://img.freepik.com/photos-gratuite/jetee-au-bord-lac-hallstatt-autriche_181624-44201.jpg'
        },
        {
            id: 1,
            organizer: 'Organisateur',
            title: 'Challenge 1',
            state: 'Terminé',
            description: 'Lorem ipsum dolor sit amet, consetetur adipiscing elit. Proin finibus fringilla mauris, vitae placerat tellus sodales at. Phasellus posuere.s',
            date: 'du 3 au 6 avril 2023',
            price: '1000€',
            participants: 10,
            img: 'https://img.freepik.com/photos-gratuite/jetee-au-bord-lac-hallstatt-autriche_181624-44201.jpg'
        },
        {
            id: 1,
            organizer: 'Organisateur',
            title: 'Challenge 1',
            state: 'Terminé',
            description: 'Lorem ipsum dolor sit amet, consetetur adipiscing elit. Proin finibus fringilla mauris, vitae placerat tellus sodales at. Phasellus posuere.s',
            date: 'du 3 au 6 avril 2023',
            price: '1000€',
            participants: 10,
            img: 'https://img.freepik.com/photos-gratuite/jetee-au-bord-lac-hallstatt-autriche_181624-44201.jpg'
        }
    ]

    return (
        <>
            <div className="creator-challenges-desktop">
                <>
                    <h2>Créateur des challenges : </h2>
                    {
                        challenges && challenges.map((item, index:number) => {
                            return (
                                    <ChallengeCard
                                        key={index}
                                        organizer={item.organizer}
                                        participants={item.participants}
                                        title={item.title}
                                        state={item.state}
                                        description={item.description}
                                        date={item.date}
                                        price={item.price}
                                        img={item.img}
                                        done
                                    />
                            )
                        })}
                </>
            </div>
        </>
    );
}

export default ProfilePreviewChallenges;