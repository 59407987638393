// style
import './index.css';

// hooks
import { useSortable } from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';

// icons
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { ReactComponent as IconDragNDrop } from '../../assets/icon-dragndrop.svg';

// utilities
import { CSS } from '@dnd-kit/utilities';
import SubChallenges from '../DareList/types';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/index';

interface DareProps {
    dare: SubChallenges;
    handleDeleteDare: (dare: SubChallenges) => void;
    handleUpdateDare: (dare: SubChallenges) => void;
    disabled: boolean;
}

const Dare = ({ dare, handleDeleteDare, handleUpdateDare, disabled }: DareProps) => {
    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: dare.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} {...attributes} style={style} {...listeners}>
            <FormHelperText sx={{marginBottom: '1vh'}}
                error={errorFieldGeneral.find((error:any) => error.code === 'subChallenges')?true:false}
            >
                {errorFieldGeneral.find((error:any) => error.code === 'subChallenges')?errorFieldGeneral.find((error:any) => error.code === 'subChallenges').message:''}
            </FormHelperText>
            <TextField
            //Afficher l'erreur qui correspond au subChallenge id et qui a un code 'subChallenge'
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(dare.id) && (error.code === 'subChallenge')) || (error.code === 'subChallenges'))?true:false}
                variant="outlined"
                fullWidth={true}
                defaultValue={dare.name}
                multiline
                inputProps={{ maxLength: 45 }}
                // select
                label="Nom du défi *"
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconClose 
                            className='icon-close icon-clickable cursor-pointer' 
                            // onClick={disabled?() => {} :() => removeDeliverableFromStep(String(stepId), String(deliverable.id))}
                            onClick={disabled?() => {} : () => handleDeleteDare(dare)}
                        />
                        <IconDragNDrop className={`icon-dragndrop icon-clickable ${!disabled&& 'draggable'} cursor-pointer`}/>
                        {/* <IconDragNDrop className={`icon-dragndrop icon-clickable draggable cursor-pointer`}/> */}
                    </InputAdornment>,
                }}
                InputLabelProps={{
                    style: { 
                        color: '#a9a9a9',
                        fontWeight: '400'
                    },
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '0px',
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }     
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, name: e.target.value })}
                onChange={(e) => {
                    handleUpdateDare({ ...dare, name: e.target.value })                    
                    setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => (String(error.id) !== String(dare.id))))
                }}
                // disabled={disabled}
            />
            <TextField
                disabled={disabled}
                error={errorFieldGeneral.find((error:any) => (String(error.id) === String(dare.id) && (error.code === 'subChallenge')) || (error.code === 'subChallenges'))?true:false}                helperText={errorFieldGeneral.find((error:any) => String(error.id) === String(dare.id) && error.code === 'subChallenge')?errorFieldGeneral.find((error:any) => String(error.id) === String(dare.id) && error.code === 'subChallenge').message:''}
                variant="outlined"
                fullWidth={true}
                defaultValue={dare.description}
                placeholder="Description du défi"
                multiline
                maxRows={3}
                minRows={2}
                inputProps={{ maxLength: 500 }}
                InputProps={{
                    startAdornment: <InputAdornment sx={{marginBottom:'10px'}} position="start">Description (max 500 caractères)</InputAdornment>,
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        display: 'block',
                        background: "#fff",
                        // borderRadius: '10px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '10px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '10px',
                        '& fieldset' :{
                            borderTop: '0px',
                        },

                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }   
                    }
                }}
                // onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, description: e.target.value })}
                onChange={(e) => {
                    handleUpdateDare({ ...dare, description: e.target.value })
                    setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => (String(error.id) !== String(dare.id))))
                }}
                // disabled={disabled}
            />
        </div>
        // <div className="dare" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        //     <div className="dare-header">
        //         <div className="dare-title">
        //             <div>
        //                 <h2 className="h1 form-title category-title">
        //                     Nom du défi
        //                 </h2>

        //                 <span className="icons">
        //                     <IconClose className='icon-close icon-clickable' onClick={() => handleDeleteDare(dare)}/>
        //                     <IconDragNDrop className='icon-dragndrop icon-clickable draggable'/>
        //                 </span>
        //             </div>
        //         </div>
        //     {/* <div className="dare-header">
        //         <div className="dare-title">
        //             <div>
        //                 {isEditingName ? (
        //                     <h2 className="h1 form-title category-title">
        //                         Nom du défi
        //                     </h2>
        //                 ) : (
        //                     <textarea ref={nameTextArea} name='dareName' id={'dare-name-' + dare.id} className='text input-editable' placeholder='Nom du défi' value={dare.name} onChange={(e) => {
        //                         setCurrentDare({
        //                             ...currentDare,
        //                             name: e.target.value
        //                         });
        //                         handleUpdateDare({
        //                             ...currentDare,
        //                             name: e.target.value
        //                         });
        //                     }} onClick={handleEditingName}/>
        //                 )}
        //                 <span className="icons">
        //                     <IconClose className='icon-close icon-clickable' onClick={() => handleDeleteDare(dare)}/>
        //                     <IconDragNDrop className='icon-dragndrop icon-clickable draggable'/>
        //                 </span>
        //             </div>
        //             {isEditingName && (
        //                 <textarea ref={nameTextArea} name='dareName' id={'dare-name-' + dare.id} className='text input-editable' placeholder='Nom du défi' value={dare.name} onChange={(e) => {
        //                     setCurrentDare({
        //                         ...currentDare,
        //                         name: e.target.value
        //                     });
        //                     handleUpdateDare({
        //                         ...currentDare,
        //                         name: e.target.value
        //                     });
        //                 }}/>
        //             )}
        //         </div>
        //     </div>
        //     <div className="dare-description">
        //         {isEditingDescription ? (
        //             <h2 className='h1 form-title category-title'>Description du défi <span className="thin">(max 500 caractères)</span></h2>
        //         ) : (
        //             <textarea 
        //                 ref={descriptionTextArea}
        //                 name='dareDescription'
        //                 id={'dare-description-' + dare.id}
        //                 className='text input-editable'
        //                 placeholder='Description du défi'
        //                 value={dare.description}
        //                 maxLength={500}
        //                 onChange={(e) => {
        //                     setCurrentDare({
        //                         ...currentDare,
        //                         description: e.target.value
        //                     });
        //                     handleUpdateDare({
        //                         ...currentDare,
        //                         description: e.target.value
        //                     });
        //                 }}
        //                 onClick={handleEditingDescription}
        //             />
        //         )}
        //         {isEditingDescription && (
        //             <textarea 
        //                 ref={descriptionTextArea}
        //                 name='dareDescription'
        //                 id={'dare-description-' + dare.id}
        //                 className='text input-editable'
        //                 placeholder='Description du défi'
        //                 value={dare.description}
        //                 maxLength={500}
        //                 onChange={(e) => {
        //                     setCurrentDare({
        //                         ...currentDare,
        //                         description: e.target.value
        //                     });
        //                     handleUpdateDare({
        //                         ...currentDare,
        //                         description: e.target.value
        //                     });
        //                 }}
        //             />
        //         )}*/}
        //     </div> 
        // </div>
    )
}

export default Dare;