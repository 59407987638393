import { useEffect, useState } from "react";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import "./index.css"
import ActionButton from "../../../../../components/ActionButton";
import { Avatar, Chip, Grid } from "@mui/material";
import Modal from "../../../../../modules/Modal";
import {ReactComponent as IconInfo} from "../../../../../assets/icon-info.svg";
import {ReactComponent as IconLike} from "../../../../../assets/icon-like.svg";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import {useTranslation} from "react-i18next";
import api from "../../../../../utils/api";


interface IUser {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    profile_picture: string | null;
}

interface IProfile {
    id: number;
    user: IUser;
}

interface IPortfolio {
    id: number;
    visible: boolean;
    profile: IProfile;
}

interface IProject {
    id: number;
    portfolios: IPortfolio[];
}


interface ITeam {
    id: number;
    name: string;
    bio?: string | null;
    numero: number | null;
    projects?: IProject[] | null;
}

interface IChallenge {
    maxMembers: number;
    maxMembersTeam: number;
    minMembersTeam: number;
}

interface IData {
    challenge: IChallenge;
    teamsClose: ITeam[];
    teamsOpen: ITeam[];
    teamsSingle: ITeam[];
    isInTeam?: string;
}

interface IErrorJoinTeam {
    error: string;
    message: string;
}

const ParticipantTeamsJoin = () => {
    const navigate = useNavigate();
    const challengeId = useParams().id;
    const [teamList, setTeamList] = useState<IData>();
    const [cancelChoice, setCancelChoice] = useState<boolean>(false);

    const [openConfirmJoinTeam, setOpenConfirmJoinTeam] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<ITeam>();

    const [sucessConfirmJoinTeam, setSucessConfirmJoinTeam] = useState<boolean>(false);

    const [errorOpenConfirmJoinTeam, setErrorOpenConfirmJoinTeam] = useState<boolean>(false);
    const [errorConfirmJoinTeam, setErrorConfirmJoinTeam] = useState<IErrorJoinTeam | null >(null);

    // Translation
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    //Get au chargement de la page
    const getTeamList = async () => {
        await api.get("/participant/team/listteamschallenges",
            {
                params: {
                    challengeId: challengeId
                },
                withCredentials: true
            }
        )
        .then((res) => {
            console.log(res);
            if (res.status === 200) {
                setTeamList(res.data);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getTeamList()
    }, [])

    useEffect(() => {
        console.log("get",teamList);
    }, [teamList])


    //Rejoindre une équipe
    const joinTeam = async (teamId: number) => {
        console.log(teamId);
        setErrorOpenConfirmJoinTeam(false)
        setErrorConfirmJoinTeam(null)
        setOpenConfirmJoinTeam(false)
        await api.post("/participant/team/jointeam",
            {
                teamId: teamId,
                challengeId: challengeId
            },
            {
                withCredentials: true
            }
        )
        .then((res) => {
            console.log("joinTeam",res.data);
            setOpenConfirmJoinTeam(false)
            if (res.status === 200) {
                setSucessConfirmJoinTeam(true)
                getTeamList();
            }
        })
        .catch((err) => {
            console.log(err);
            setErrorOpenConfirmJoinTeam(true)
            setErrorConfirmJoinTeam(err.response.data)
        })
    }

    const returnAndCreateTeam = () => {
        console.log("returnAndCreateTeam");

        const pathname = `/challenge/${challengeId}/general`
        const path = {
            pathname,
            search: createSearchParams({redirectcreateteam: "true"}).toString()
          };
          navigate(path);
    }

    const confirmJoinTeam = (team:any) => {
        console.log("id====>",team.id);
        setSelectedTeam(team)
        // joinTeam(id)
        setOpenConfirmJoinTeam(true)
    }

    const cancelAndCreateTeam = () => {
        setCancelChoice(true);
    }

    const validateCancelAndCreateTeam = () => {
        navigate(`/challenge/${challengeId}/general`)
    }

    const redirectToProject = (team:ITeam) => {
        // navigate(`/participant/challenge/${challengeId}/project/${String(team.projects?.[0]?.id)}/edit`)
        navigate(`/participant/challenge/project/edition/${String(team.projects?.[0]?.id)}`)
    }

    useEffect(() => {
        console.log("errorConfirmJoinTeam",errorConfirmJoinTeam);
    }, [errorConfirmJoinTeam])
    //
    console.log("teamList?.isInTeam)", teamList?.isInTeam);
    // console.log("", team.id);

    return (
        <section id="list-teams-join">
            <div id="bloc-info-teams-join">
                <ActionButton
                    variant="tertiary"
                    onClick={() => cancelAndCreateTeam()}
                >
                    {t("page.participantJoinTeam.cancelChoice")}
                </ActionButton>
                <h1 className="text-center mt-4">{t("page.participantJoinTeam.joinTeam")}</h1>
                <p className="text-center mt-2">{t("page.participantJoinTeam.text1")}</p>
                <p className="text-center mt-4 p-bold">{t("page.participantJoinTeam.changedMind")} :</p>
                <div className="text-center pt-1">
                    <ActionButton
                        variant="little-underline"
                        onClick={() => returnAndCreateTeam()}
                    >
                        {t("page.participantJoinTeam.createTeam")}
                    </ActionButton>
                </div>
            </div>
            <div id="teams-open" className="mt-5">
                <h1 className="text-center">{t("page.participantJoinTeam.teamsOpenRegistrations")}</h1>
                <p className="text-center my-1">{t("page.participantJoinTeam.teams")} : {teamList && teamList.challenge && teamList.challenge.minMembersTeam} {t("projectCard.to")} {teamList && teamList.challenge && teamList.challenge.maxMembersTeam} {t("page.participantJoinTeam.members")}</p>
                <Grid container spacing={2} marginTop={1} justifyContent={"center"}>
                    { teamList && teamList.teamsOpen && teamList.teamsOpen.map((team, index) => {
                        return (
                            <Grid item xs={12} md={6} key={index}>
                                <div className="card-team-join">
                                    <div className="content" key={index}>
                                        <div className="card-team-join-header">
                                            <p>{t("page.participantJoinTeam.team")} {team.numero} - {team && team.projects && team.projects[0].portfolios.length} {t("page.participantJoinTeam.member")}{team && team.projects && team.projects[0].portfolios.length>1?"s":""}</p>
                                            <h5 className="team-name">{team.name}</h5>
                                            <Grid container spacing={1}>
                                                {team && team.projects && team.projects[0].portfolios.map((portfolio, index) => {
                                                    return (
                                                        <Grid item xs={"auto"} key={index}>
                                                            <Chip sx={{height:'40px', background:"#fff", borderRadius:'20px'}} key={index} avatar={
                                                                <Avatar
                                                                    sx={{height:'40px !important',width:'40px !important', marginLeft:'0px !important',marginRight:'0px !important'}}
                                                                    alt={portfolio.profile.user.firstname} src={portfolio.profile.user.profile_picture? portfolio.profile.user.profile_picture:""}
                                                                >

                                                                </Avatar>}
                                                            label={`${portfolio.profile.user.firstname} ${portfolio.profile.user.lastname}`}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                )}
                                            </Grid>
                                        </div>
                                        { team.bio &&
                                            <div className="card-team-join-bio">
                                                <p className="word-team">{t("page.participantJoinTeam.teamBio")} :</p>
                                                <p className="bio">{team.bio}</p>
                                            </div>
                                        }
                                    </div>
                                    <ActionButton
                                        variant={`${teamList.isInTeam!==undefined && Number(team.id)!==Number(teamList.isInTeam)?"disabled-card":"primary-card"}`}
                                        onClick={() =>
                                            {teamList.isInTeam!==undefined && Number(team.id)===Number(teamList.isInTeam)?redirectToProject(team):confirmJoinTeam(team)}
                                        }
                                    >
                                        {teamList.isInTeam!==undefined && Number(team.id)===Number(teamList.isInTeam)? t("page.participantJoinTeam.seeProjectPage"):t("page.participantJoinTeam.joinTheTeam")}
                                    </ActionButton>
                                </div>
                            </Grid>
                        )

                    })}
                </Grid>

                {/* <div className="card-team-join">

                </div> */}

            </div>

            <Modal
                open={cancelChoice}
                onClose={() => setCancelChoice(false)}
                title={`${t("page.participantJoinTeam.modalCancelChoice.title")}`}
                tertiaryButtonText={`${t("form.cancel")}`}
                tertiaryButtonAction={() => setCancelChoice(false)}
                primaryButtonText={`${t("page.participantJoinTeam.modalCancelChoice.confirmCancel")}`}
                primaryButtonAction={() => validateCancelAndCreateTeam()}
            >
                <div className="blocinfo-warning-modal-jointeam">
                    <IconInfo className="iconinfo-modal" />
                    <h5 className="h5modal">Attention</h5>
                    <p>{t("page.participantJoinTeam.modalCancelChoice.text1")}</p>
                    <p>{t("page.participantJoinTeam.modalCancelChoice.text2")}</p>
                </div>

            </Modal>

            <Modal
                open={openConfirmJoinTeam}
                onClose={() => setOpenConfirmJoinTeam(false)}
                title={`${t("page.participantJoinTeam.modalConfirmJoinTeam.title")} ${selectedTeam?.name} ?`}
                tertiaryButtonText={`${t("form.cancel")}`}
                tertiaryButtonAction={() => setOpenConfirmJoinTeam(false)}
                primaryButtonText={`${t("page.participantJoinTeam.modalConfirmJoinTeam.joinThem")}`}
                primaryButtonAction={() => joinTeam(selectedTeam?.id?selectedTeam?.id:0)}
            >
                {/*<p>{t("page.participantJoinTeam.modalConfirmJoinTeam.text1")}</p>*/}
                <Grid container spacing={1}>
                    {selectedTeam?.projects?.[0]?.portfolios && (
                        selectedTeam.projects[0].portfolios.map((portfolio, index) => (
                            <Grid item xs={"auto"} key={index}>
                                <Chip sx={{height:'40px', background:"#EDF1F7", borderRadius:'20px'}} size="medium" key={index} avatar={<Avatar sx={{height:'40px !important',width:'40px !important', marginLeft:'0px !important',marginRight:'0px !important'}} alt="Natacha" src={portfolio.profile.user.profile_picture? portfolio.profile.user.profile_picture:""} />} label={`${portfolio.profile.user.firstname} ${portfolio.profile.user.lastname}`} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Modal>
            <Modal
                icon={<IconLike/>}
                open={sucessConfirmJoinTeam}
                onClose={() => setSucessConfirmJoinTeam(false)}
                title={`${t("page.participantJoinTeam.modalSucessJoinTeam.title")} ${selectedTeam?.numero} !`}
                primaryButtonText={`${t("page.participantJoinTeam.modalSucessJoinTeam.dscoverProject")}`}
                primaryButtonAction={() => redirectToProject(selectedTeam as ITeam)}
            >
                <p>{t("page.participantJoinTeam.modalSucessJoinTeam.text1")}</p>
            </Modal>
            <p>{errorConfirmJoinTeam && errorConfirmJoinTeam.message}</p>
            <Modal
                open={errorOpenConfirmJoinTeam}
                onClose={() => setErrorOpenConfirmJoinTeam(false)}
                title={`${errorConfirmJoinTeam && errorConfirmJoinTeam.error==="AlreadyInTeam"?t("page.participantJoinTeam.modalSucessJoinTeam.requestNotAccepted"):t("page.participantJoinTeam.modalSucessJoinTeam.errorOccurred")}`}
                primaryButtonText={`${t("page.participantJoinTeam.modalSucessJoinTeam.understand")}`}
                primaryButtonAction={() => {setErrorConfirmJoinTeam(null); setErrorOpenConfirmJoinTeam(false)}}
                error={errorConfirmJoinTeam && errorConfirmJoinTeam.message}
            >
            </Modal>
        </section>
    )
}

export default ParticipantTeamsJoin
