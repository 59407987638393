import React, {useEffect, useRef, useState} from "react";
import ActionButton from "../../../../components/ActionButton";
import {Pagination} from "@mui/lab";
import {
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Skeleton,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";

import {ReactComponent as IconSearch} from "../../../../assets/icon-search.svg";
import {ReactComponent as IconClose} from "../../../../assets/icon-close.svg";
import {ReactComponent as IconNetwork} from "../../../../assets/icon-network.svg";
import {ReactComponent as IconProfile} from "../../../../assets/icon-profile.svg";
import {ReactComponent as IconCrossFilled} from "../../../../assets/icon-cross-filled.svg";
import {useNavigate, useParams} from "react-router-dom";

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../components/SetUpTranslation";

import {utils, write} from "xlsx";
//style
// import "./index.css";
import {IListParticipants} from "./types";
import {ReactComponent as ArrowRight} from "../../../../assets/arrow-right.svg";
import {ReactComponent as IconDownload} from '../../../../assets/icon-download.svg';
import api from "../../../../utils/api";

const ListChallengeSubscribes = () => {
    const [listParticipants, setListTest] = useState<IListParticipants>();

    const {id} = useParams();
    const navigate = useNavigate();
    const [pageNav, setPageNav] = useState<number>(1);
    const [filterTeam, setFilterTeam] = useState(false);
    const [filterSingle, setFilterSingle] = useState(false);
    const [filterUndetermined, setFilterUndetermined] = useState(false);

    const handleTeamFilterChange = (event: any) => {
        setFilterTeam(event.target.checked);
        setPageNav(1);
    };

    const handleSingleFilterChange = (event: any) => {
        setFilterSingle(event.target.checked);
        setPageNav(1);
    };

    const handleUndeterminedFilterChange = (event: any) => {
        setFilterUndetermined(event.target.checked);
        setPageNav(1);
    };

    const [valueSearch, setValueSearch] = useState<string>("");
    const prevValueSearch = useRef<string | null>(null);
    const [isFetchData, setIsFetchData] = useState<boolean>(false);

    const theme = useTheme();
    const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));
    const isMobile405 = useMediaQuery(theme.breakpoints.down(405));

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(10);
    const [filters, setFilters] = useState<string[]>([]);

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);


    const getParticipants = async (search?: string) => {
        setIsLoaded(false);
        if (filterTeam) filters.push("team");
        if (filterSingle) filters.push("single");
        if (filterUndetermined) filters.push("undetermined");
        await api.get(`/admin/challenge/participants`
            , {
                params: {
                    challengeId: id,
                    page: pageNav - 1,
                    limit: limit,
                    search: search ? search : valueSearch,
                    filters: filters
                },
                withCredentials: true
            })
            .then((res) => {
                const loaded = () => {
                    setIsLoaded(true);
                    setListTest(res.data);
                    console.log(res.data);
                }
                setTimeout(loaded, 250);
                setFilters([]);
            }).catch((error) => {
                console.log(error);
                setFilters([]);
            })
    }

    const generateCsv = (data: any[]) => {
        // const header = ["nom", "prénom", "mail", "téléphone", "nom d'équipe", "numéro d'équipe", "école", "diplôme", "entreprise", "poste"];
        const teamData = data.map((participant) => {
            let teamParticipation = t("participationType.notDetermined");
            if (participant.teams[0]) {
                teamParticipation = participant.teams[0].single ? t("participationType.single") : t("participationType.team");
            }

            return {
                [t("excelExport.lastname")]: participant.user.lastname,
                [t("excelExport.firstname")]: participant.user.firstname,
                "Email": participant.email,
                [t("excelExport.tel")]: participant.user.phone_number,
                [t("excelExport.participationType")]: teamParticipation,
                [t("excelExport.teamNumber")]: participant.teams[0] ? t("excelExport.team") + participant.teams[0].numero : '',
                [t("excelExport.teamName")]: participant.teams[0] ? participant.teams[0].name : '',
                [t("excelExport.job")]: participant.user.job,
                [t("excelExport.company")]: participant.user.company,
                [t("excelExport.degree")]: participant.user.degree,
                [t("excelExport.school")]: participant.user.school,
            };
        });

        // créer l'excel à partir du json teamData
        const ws = utils.json_to_sheet(teamData);
        const wb = utils.book_new();

        // récupérer le nombre de colonnes
        const nbCols = Object.keys(teamData[0]).length;

        // créer un tableau de !cols s'il n'existe pas
        if (!ws["!cols"]) ws["!cols"] = [];

        // boucler sur les colonnes pour gérer leur largeur
        for (let i = 0; i < nbCols; i++) {
            // create column metadata object if it does not exist
            if (!ws["!cols"][i]) ws["!cols"][i] = {wch: 8};
            if (i === 2 || i === 9) {
                // largeur des colonnes C et I
                ws["!cols"][i].wpx = 200;
            } else if (i === 4 || i === 6 || i === 7 || i === 10) {
                // largeur des colonnes E et G
                ws["!cols"][i].wpx = 150;
            } else {
                // largeur des autres colonnes
                ws["!cols"][i].wpx = 100;
            }
        }

        utils.book_append_sheet(wb, ws, 'Liste participants');

// Convertir le classeur en binaire
        return write(wb, {bookType: 'xlsx', type: 'buffer'});
    }

    const exportParticipantsList = async () => {
        await api.get(`/admin/challenge/export_participants`
            , {
                params: {challengeId: id},
                withCredentials: true
            })
            .then((res) => {
                const participants = res.data;

                if (participants) {
                    const csvContent = generateCsv(participants);

                    const filename = "liste_participants.xlsx";
                    const blob = new Blob([csvContent], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNav(value);
        setIsFetchData(true);
    };
    const handleSearchChange = (event: any) => {
        // Modifier la valeur de search en temps réel
        setIsFetchData(false);
        setValueSearch(event.target.value);
    }
    useEffect(() => {
        getParticipants();
    }, []);
    useEffect(() => {
        // Ce code permet d'obtenir les données modifiées uniquement si l'user arrête de tapper après 0,45 secondes
        // const getData = setTimeout(() => {
        //     getParticipants();
        // }, 450);
        // return () => clearTimeout(getData);
        // faudra remettre valueSearch dans les deps
        if (isFetchData) {
            getParticipants();
            setIsFetchData(false);
        }
    }, [pageNav, valueSearch]);

    useEffect(() => {
        if (isFetchData || prevValueSearch.current !== valueSearch) {
            getParticipants();
            setIsFetchData(false);
            prevValueSearch.current = valueSearch;
        }
    }, [pageNav]);
    useEffect(() => {
        setPageNav(1);
        getParticipants();
    }, [filterTeam, filterSingle, filterUndetermined]);

    return (
        <div>
            <div className="container-top">
                <p className="nb-inscrits">{listParticipants?.countAllParticipants} {listParticipants && listParticipants?.countAllParticipants > 1 ? 'incrits' : 'inscrit'}
                    <IconDownload className="cursor-pointer ms-2" onClick={() => exportParticipantsList()}/></p>
                <div className="participations">
                    <p>Type de participations</p>
                    <div className="participations-chip">
                        {
                            listParticipants?.participationType === "both" && (
                                <>
                                    <Chip label={(<><span
                                        style={{fontWeight: "900"}}>{listParticipants?.countTeamParticipants}</span> en
                                        équipe</>)} icon={<IconNetwork/>}
                                          sx={{
                                              backgroundColor: "var(--primary10)",
                                              padding: "5px",
                                              fontStyle: "italic",
                                          }}/>
                                    <Chip label={(<><span
                                        style={{fontWeight: "900"}}>{listParticipants?.countSingleParticipants}</span>
                                        {listParticipants?.countSingleParticipants && listParticipants?.countSingleParticipants > 1 ? ' seules' : ' seule'}
                                    </>)}
                                          icon={<IconProfile/>}
                                          sx={{
                                              backgroundColor: "var(--primary10)",
                                              padding: "5px",
                                              fontStyle: "italic"
                                          }}/>

                                    <Chip
                                        label={(<><span
                                            style={{fontWeight: "900"}}>{listParticipants?.countUndeterminedParticipants}</span>
                                            {listParticipants?.countUndeterminedParticipants && listParticipants?.countUndeterminedParticipants > 1 ? ' non déterminées' : ' non déterminée'}</>)}
                                        icon={<IconCrossFilled/>}
                                        sx={{
                                            backgroundColor: "var(--primary10)",
                                            padding: "5px",
                                            fontStyle: "italic"
                                        }}/>
                                </>
                            )
                        }
                        {
                            listParticipants?.participationType === "team" && (
                                <>
                                    <Chip label={(<><span
                                        style={{fontWeight: "900"}}>{listParticipants?.countTeamParticipants}</span> avec
                                        projet</>)} icon={<IconNetwork/>}
                                          sx={{
                                              backgroundColor: "var(--primary10)",
                                              padding: "5px",
                                              fontStyle: "italic",
                                          }}/>
                                    <Chip
                                        label={(<><span
                                            style={{fontWeight: "900"}}>{listParticipants?.countUndeterminedParticipants}</span>
                                            &nbsp; sans projet
                                        </>)}
                                        icon={<IconCrossFilled/>}
                                        sx={{
                                            backgroundColor: "var(--primary10)",
                                            padding: "5px",
                                            fontStyle: "italic"
                                        }}/>
                                </>
                            )
                        }
                        {
                            listParticipants?.participationType === "single" && (
                                <>
                                    <Chip label={(<><span
                                        style={{fontWeight: "900"}}>{listParticipants?.countSingleParticipants}</span>
                                        &nbsp; avec projet
                                    </>)}
                                          icon={<IconProfile/>}
                                          sx={{
                                              backgroundColor: "var(--primary10)",
                                              padding: "5px",
                                              fontStyle: "italic"
                                          }}/>
                                    <Chip
                                        label={(<><span
                                            style={{fontWeight: "900"}}>{listParticipants?.countUndeterminedParticipants}</span>
                                            &nbsp; sans projet
                                        </>)}
                                        icon={<IconCrossFilled/>}
                                        sx={{
                                            backgroundColor: "var(--primary10)",
                                            padding: "5px",
                                            fontStyle: "italic"
                                        }}/>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="container-admin-infos-challenge-select-list-content">
                <div className="block-search-filter">
                    <TextField
                        size={"small"}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: "10px",
                                color: "black",
                                backgroundColor: "var(--primary10)",
                                border: "none!important",
                            },
                            '& fieldset': {
                                border: "none",
                            }
                        }}
                        placeholder={"Recherche..."}
                        // type="search"
                        inputMode={"search"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch
                                        className="icon-cursor"
                                        onClick={() => {
                                            setIsFetchData(true);
                                            setPageNav(1);
                                            if (prevValueSearch.current !== valueSearch) {
                                                getParticipants();
                                                prevValueSearch.current = valueSearch;
                                            }
                                        }
                                        }
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: valueSearch && (
                                <InputAdornment position={"end"}>
                                    <IconClose
                                        className={"icon-cursor icon-search-close"}
                                        onClick={() => {
                                            setValueSearch("");
                                            setPageNav(1)
                                            setIsFetchData(true)
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                        value={valueSearch}
                        onChange={handleSearchChange}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                setIsFetchData(true);
                                setPageNav(1);
                                if (prevValueSearch.current !== valueSearch) {
                                    getParticipants();
                                    prevValueSearch.current = valueSearch;
                                }
                            }
                        }
                        }
                        helperText={`${
                            listParticipants?.countAllParticipants === listParticipants?.participants.count ? ""
                                : `${listParticipants?.participants.count} ${listParticipants?.participants.count === 1 ? 'résulat' : 'résultats'}`}`}
                    />
                    <div className="container-checkboxs">
                        <p>Filtres selon le mode de participation</p>
                        <FormGroup className="checkboxs" sx={{
                            ".MuiSvgIcon-root": {color: "var(--primary50)",},
                            ".Mui-checked svg path": {fill: "var(--actionprimary50)"},
                        }}>
                            {
                                listParticipants?.participationType === "both" && (
                                    <>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleTeamFilterChange}
                                                          checked={filterTeam} label="Participation en équipe"/>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleSingleFilterChange}
                                                          checked={filterSingle} label="Participation seule"/>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleUndeterminedFilterChange}
                                                          checked={filterUndetermined}
                                                          label="Participation non déterminée"/>
                                    </>
                                )
                            }
                            {
                                listParticipants?.participationType === "team" && (
                                    <>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleTeamFilterChange}
                                                          checked={filterTeam} label="Avec projet"/>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleUndeterminedFilterChange}
                                                          checked={filterUndetermined} label="Sans projet"/>
                                    </>
                                )
                            }
                            {
                                listParticipants?.participationType === "single" && (
                                    <>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleSingleFilterChange}
                                                          checked={filterSingle} label="Avec projet"/>
                                        <FormControlLabel control={<Checkbox/>} onChange={handleUndeterminedFilterChange}
                                                          checked={filterUndetermined} label="Sans projet"/>
                                    </>
                                )
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className={`container-inscrits`}>
                    {
                        listParticipants?.participants.count === 0 ? ''
                            : (
                                <Pagination
                                    count={listParticipants && Math.ceil(listParticipants?.participants.count / limit)}
                                    sx={{
                                        ".MuiPagination-ul": {
                                            gap: isMobile405 ? "0" : "1rem",
                                        },
                                        ".MuiPaginationItem-root": {
                                            // border: "1px solid red",
                                            fontSize: isMobile405 ? "10px!important" : "0.875rem!important",
                                        },
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        transition: "all 0.3s ease-in-out",
                                        opacity: isLoaded ? "1" : "0"
                                    }}
                                    shape={"rounded"}
                                    page={pageNav}
                                    onChange={handleChangePage}
                                    siblingCount={isMobileSm ? 0 : 1}
                                    boundaryCount={isMobileSm ? 1 : 1}
                                />
                            )
                    }
                    {Array.from({length: 10}).map((_, index) => (
                        <div className={`container-inscrit ${isLoaded ? 'notLoaded' : 'loaded'}`} key={index}>
                            <div className={`container-inscrits-infos-perso ${isLoaded ? 'notLoaded' : 'loaded'}`}>
                                <Skeleton variant={"rounded"} sx={{borderRadius: "50%",}}
                                          className={`list-inscrit-img-profile ${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                <div className={`skeleton-inscrit-name ${isLoaded ? 'notLoaded' : 'loaded'}`}>
                                    <div className={`infos-perso-name ${isLoaded ? 'notLoaded' : 'loaded'}`}>
                                        <Skeleton variant={"text"} width={50}
                                                  className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                        <Skeleton variant={"text"} width={50}
                                                  className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                    </div>
                                    <div
                                        className={`skeleton-infos-perso-email-tel ${isLoaded ? 'notLoaded' : 'loaded'}`}>
                                        <div>
                                            <Skeleton variant={"text"} width={150}
                                                      className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                            <Skeleton variant={"text"} width={70}
                                                      className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                        </div>
                                        <div>
                                            <Skeleton variant={"text"} width={150}
                                                      className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                            <Skeleton variant={"text"} width={70}
                                                      className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`container-inscrits-infos-equipe-project ${isLoaded ? 'notLoaded' : 'loaded'}`}>
                                <Skeleton variant={"text"} width={100}
                                          className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                                <Skeleton variant={"text"} width={80}
                                          className={`${isLoaded ? 'notLoaded' : 'loaded'}`}/>
                            </div>
                        </div>
                    ))}
                    {
                        isLoaded && listParticipants && listParticipants.participants.rows.map((inscrit, index) =>
                            (
                                <div className={`container-inscrit`} key={index}>
                                    <div className="container-inscrits-infos-perso">
                                        <img src={inscrit.user.profile_picture}
                                             className={"list-inscrit-img-profile"}
                                        />
                                        <div className="infos-perso">
                                            <div className="infos-perso-name">
                                                <p>{inscrit.user.firstname} {inscrit.user.lastname}</p>
                                            </div>
                                            <div className="infos-perso-email-tel">
                                                <p>{inscrit.email}</p>
                                                <p>{inscrit.user.phone_number && (
                                                    <span>-</span>)} {inscrit.user.phone_number}</p>
                                            </div>
                                            <div className={"infos-perso-job-company"}>
                                                <p>{inscrit.user.job}</p>
                                                <p>{inscrit.user.company && (
                                                    <span>-</span>)} {inscrit.user.company}</p>
                                            </div>
                                            <div className={"infos-perso-degree-school"}>
                                                <p>{inscrit.user.degree}</p>
                                                <p>{inscrit.user.degree && (
                                                    <span>-</span>)} {inscrit.user.school}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-inscrits-infos-equipe-project">
                                        <div className={"chip-container"}>
                                            <Chip
                                                label={
                                                    listParticipants?.participationType === "both" ?
                                                        inscrit.teams.length > 0 ?
                                                            !inscrit.teams[0].single ?
                                                                (<> Équipe {inscrit.teams[0].numero} </>)
                                                                : inscrit.teams[0].single &&
                                                                (<> Seule</>)
                                                            : (<>Non déterminé</>)
                                                        : inscrit.teams.length > 0 ?
                                                            !inscrit.teams[0].single ?
                                                                (<> Équipe {inscrit.teams[0].numero} </>)
                                                                : inscrit.teams[0].single &&
                                                                (<>Avec projet</>)
                                                            : (<>Sans projet</>)
                                                }
                                                icon={
                                                    inscrit.teams.length > 0 ?
                                                        !inscrit.teams[0].single ?
                                                            (<IconNetwork/>)
                                                            : inscrit.teams[0].single &&
                                                            (<IconProfile/>)
                                                        : (<IconCrossFilled/>)
                                                }
                                                sx={{
                                                    backgroundColor: "white",
                                                    padding: "5px",
                                                    fontStyle: "italic",
                                                    fontFamily: "var(--font-secondary)",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    width: "fit-content"
                                                }}
                                                className={"chip-infos-equipe-project"}
                                            />
                                            <p className={"team"}>{inscrit.teams[0] && inscrit.teams[0].name}</p>
                                        </div>
                                        <ActionButton
                                            variant={"see-project"}
                                            className={"button-see-project"}
                                            iconEnd={true}
                                            icon={<ArrowRight/>}
                                            onClick={() => navigate(`/participant/portfolio/${inscrit.id}`)}
                                        >
                                            Voir les projets
                                        </ActionButton>
                                    </div>
                                </div>
                            )
                        )
                    }
                    {
                        listParticipants?.participants.count === 0 ? (
                            <p className={"no-result"}>Aucun résultat</p>
                        ) : (
                            <Pagination
                                count={listParticipants && Math.ceil(listParticipants?.participants.count / limit)}
                                sx={{
                                    ".MuiPagination-ul": {
                                        gap: isMobile405 ? "0" : "1rem",
                                    },
                                    ".MuiPaginationItem-root": {
                                        // border: "1px solid red",
                                        fontSize: isMobile405 ? "10px!important" : "0.875rem!important",
                                    },
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    transition: "all 0.3s ease-in-out",
                                    opacity: isLoaded ? "1" : "0"
                                }}
                                shape={"rounded"}
                                page={pageNav}
                                onChange={handleChangePage}
                                siblingCount={isMobileSm ? 0 : 1}
                                boundaryCount={isMobileSm ? 1 : 1}
                            />
                        )
                    }
                </div>
                {/*<div className="container-inscrits">*/}
                {/*    {*/}
                {/*        listInscrits.list.map((inscrit) =>*/}
                {/*            (*/}
                {/*                <div className="container-inscrit">*/}
                {/*                    <div className="container-inscrits-infos-perso">*/}
                {/*                        <img src={inscrit.imgProfile}/>*/}
                {/*                        <div className="infos-perso">*/}
                {/*                            <div className="infos-perso-name">*/}
                {/*                                <p>{inscrit.firstName} {inscrit.lastName}</p>*/}
                {/*                            </div>*/}
                {/*                            <div className="infos-perso-email-tel">*/}
                {/*                                <p>{inscrit.email} -</p>*/}
                {/*                                <p>{inscrit.tel}</p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="container-inscrits-infos-equipe-project">*/}
                {/*                        <Chip*/}
                {/*                            label={*/}
                {/*                                inscrit.typeParticipation === "team" ?*/}
                {/*                                    (<>{inscrit.nomEquipe}</>)*/}
                {/*                                    : inscrit.typeParticipation === "single" ?*/}
                {/*                                        (<> Seule</>)*/}
                {/*                                        : (<>Non déterminée</>)*/}
                {/*                            }*/}
                {/*                            icon={*/}
                {/*                                inscrit.typeParticipation === "team" ?*/}
                {/*                                    (<IconNetwork/>)*/}
                {/*                                    : inscrit.typeParticipation === "single" ?*/}
                {/*                                        (<IconProfile/>)*/}
                {/*                                        : (<IconCrossFilled/>)*/}
                {/*                            }*/}
                {/*                            sx={{*/}
                {/*                                backgroundColor: "white",*/}
                {/*                                padding: "5px",*/}
                {/*                                fontStyle: "italic",*/}
                {/*                                fontFamily: "var(--font-secondary)",*/}
                {/*                                fontWeight: "400",*/}
                {/*                                fontSize: "14px",*/}
                {/*                            }}*/}
                {/*                            className={"chip-infos-equipe-project"}*/}
                {/*                        />*/}
                {/*                        <ActionButton variant={"little-underline"}*/}
                {/*                                      className={"button-see-project"}>Voir les*/}
                {/*                            projets</ActionButton>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            )*/}
                {/*        )*/}
                {/*    }*/}
                {/*    {*/}
                {/*        filteredList.length === 0 ? (*/}
                {/*            <p>Pas de résultats</p>*/}
                {/*        ) : (*/}
                {/*            <Pagination*/}
                {/*                count={Math.ceil(listInscrits.totalInscrits / listInscrits.totalPerPage)}*/}
                {/*                sx={{*/}
                {/*                    ".MuiPagination-ul": {*/}
                {/*                        gap: isMobile405 ? "0" : "1rem",*/}
                {/*                    },*/}
                {/*                    ".MuiPaginationItem-root": {*/}
                {/*                        // border: "1px solid red",*/}
                {/*                        fontSize: isMobile405 ? "10px!important" : "0.875rem!important",*/}
                {/*                    },*/}
                {/*                    width: "100%",*/}
                {/*                    display: "flex",*/}
                {/*                    justifyContent: "center",*/}
                {/*                }}*/}
                {/*                shape={"rounded"}*/}
                {/*                page={pageNav}*/}
                {/*                onChange={handleChangePage}*/}
                {/*                siblingCount={isMobileSm ? 0 : 1}*/}
                {/*                boundaryCount={isMobileSm ? 1 : 1}*/}
                {/*            />*/}
                {/*        )*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
export default ListChallengeSubscribes;
