// hooks
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// icons
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { ReactComponent as IconDragNDrop } from '../../assets/icon-dragndrop.svg';

// utilities
import { InputAdornment, TextField } from '@mui/material';
import { IStepDeliverable } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeSteps/types';
import { v4 as uuidv4 } from 'uuid';


interface DeliverableProps {
    deliverable: IStepDeliverable;
    stepId: number | string;
    updateDeliverable: (stepId: string, updatedDeliverable: IStepDeliverable) => void;
    removeDeliverableFromStep: (stepId: string, deliverableId: string) => void;
    disabled?: boolean;
}

const DeliverableStep = ({ deliverable, stepId, updateDeliverable, removeDeliverableFromStep, disabled }: DeliverableProps) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: String(deliverable.id) || uuidv4() });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return (
        <div ref={setNodeRef} {...attributes} style={style} {...listeners}>
            <TextField
                variant="outlined"
                fullWidth={true}
                defaultValue={deliverable.name}
                placeholder="Livrable"
                multiline
                inputProps={{ maxLength: 45 }}
                // select
                label="Nom du livrable"
                disabled={disabled}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconClose 
                            className='icon-close icon-clickable cursor-pointer' 
                            onClick={disabled?() => {} :() => removeDeliverableFromStep(String(stepId), String(deliverable.id))}
                        />
                        <IconDragNDrop className={`icon-dragndrop icon-clickable ${!disabled&& 'draggable'} cursor-pointer`}/>
                    </InputAdornment>,
                }}
                InputLabelProps={{
                    style: { 
                        color: '#a9a9a9',
                        fontWeight: '400'
                    },
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '0px',
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }     
                    }
                }}
                onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, name: e.target.value })}
                // disabled={disabled}
            />
            <TextField
                variant="outlined"
                fullWidth={true}
                defaultValue={deliverable.description}
                placeholder="Description du livrable"
                multiline
                maxRows={3}
                minRows={2}
                inputProps={{ maxLength: 250 }}
                disabled={disabled}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        // borderRadius: '10px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '10px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '10px',
                        '& fieldset' :{
                            borderTop: '0px',
                        },

                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }   
                    }
                }}
                onChange={(e) => updateDeliverable(String(stepId), { ...deliverable, description: e.target.value })}
                // disabled={disabled}
            />
        </div>
    )
}

export default DeliverableStep;

