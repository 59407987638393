import React from "react";

const SwitchTranslateEn: React.FC = () => {
    return (
        <>
            <svg width="28" height="28"
                 viewBox="0 0 28 28" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_10281_9405)">
                    <path
                        d="M24.5778 0H3.42222C1.52444 0 0 1.43111 0 3.20444V19.2889C0 21.0622 1.52444 22.4933 3.42222 22.4933H5.66222V27.2533C5.66222 27.5333 5.84889 27.8133 6.12889 27.9378C6.25333 28 6.34667 28 6.47111 28C6.65778 28 6.84444 27.9378 7 27.8444L14.0622 22.4933H24.5778C26.4756 22.4933 28 21.0622 28 19.2889V3.20444C28 1.43111 26.4756 0 24.5778 0ZM26.4133 19.2578C26.4133 20.1911 25.6044 20.9378 24.6089 20.9378H13.7511C13.5644 20.9378 13.3778 21 13.2222 21.0933L7.28 25.6044V21.6844C7.28 21.28 6.90667 20.9378 6.47111 20.9378H3.42222C2.42667 20.9378 1.61778 20.1911 1.61778 19.2578V3.26667C1.61778 2.33333 2.42667 1.58667 3.42222 1.58667H24.64C25.6356 1.58667 26.4444 2.33333 26.4444 3.26667V19.2578H26.4133Z"
                        fill="#828D9B"/>
                    <path
                        d="M8.08932 11.7287H12.5071V10.1731H8.08932V8.24424H12.8804V6.62646H6.22266V15.7109H13.0982V14.062H8.08932V11.7287Z"
                        fill="#828D9B"/>
                    <path
                        d="M20.1596 12.9731L16.5507 6.62646H14.5596V15.7109H16.3329V9.27091L20.0351 15.7109H21.9329V6.62646H20.1596V12.9731Z"
                        fill="#828D9B"/>
                </g>
                <defs>
                    <clipPath id="clip0_10281_9405">
                        <rect width="28" height="28" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default SwitchTranslateEn;
