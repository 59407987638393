// components
import ActionButton from "../../../../../../components/ActionButton";
import { ReactComponent as IconAnnonce } from "../../../../../../assets/icon-annonce.svg";
// react-router-dom
import { ReactComponent as CalendarIcon } from '../../../../../../assets/icon-calendar.svg';
import { ReactComponent as AlertIcon } from '../../../../../../assets/icon-alert.svg';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/icon-download.svg';
import { ReactComponent as Arrow } from '../../../../../../assets/arrow-down.svg';
import { ReactComponent as LinkIcon } from '../../../../../../assets/icon-link.svg';
import { ReactComponent as EyeIcon } from '../../../../../../assets/icon-eye.svg';
import { ReactComponent as IconCopy } from '../../../../../../assets/icon-copy.svg';

// styles
import '../index.css';
import './index.css';
// react
import React, { useEffect, useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/type";

// moment
import moment from "moment";
import "moment/locale/fr"; // Importer la localisation française pour les noms de mois en français

import { Button, Input, InputAdornment, OutlinedInput, TextField, Tooltip, inputClasses } from "@mui/material";
import Modal from "../../../../../../modules/Modal";
import FaqCard from "../../../../../../modules/FaqCard";
import { useParams } from "react-router";
import IChallenge from "../../types";
import IStep from "../../types"
import api from "../../../../../../utils/api";

interface IError {
    error: string;

    message: string;
}

const AdminInfosChallengeGeneral = () => {

    const [published, setPublished] = useState<boolean>(false);
    const [unpublished, setUnpublished] = useState<boolean>(false);
    const [unableToPostChallenge, setUnableToPostChallenge] = useState<boolean>(false)
    const [publishedWithErrors, setPublishedWithErrors] = useState<boolean>(false)
    const [infosModal, setInfosModal] = useState([])
    const [openTooltip, setOpenToolTip] = useState(false);
    const [openTooltipLive, setOpenToolTipLive] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const id = useParams().id;
    const [challenge, setChallenge] = useState<IChallenge>();
    const [originalSteps, setOriginalSteps] = useState<IStep[]>([]);
    const [eligibilities, setEligibilities] = useState<any[]>([]);

    const getOneChallenge = async (id: string) => {
        await api.get('/challenge/preview', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                setChallenge(res.data.challenge)
                console.log(res.data.challenge)
                setValueLive(res.data.challenge.link_live);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getSteps = async () => {
        await api.get("/admin/challenge/steps",
            {
                params: {
                    challengeId: id
                },
                withCredentials: true
            }
        ).then((res) => {
            const steps = res.data.steps;
            setOriginalSteps(steps);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getOneChallenge(String(id))
        getSteps();
    }, [id])

    const dispatch = useDispatch()

    const handleTooltipOpen = () => {
        setOpenToolTip(true);
        navigator.clipboard.writeText(process.env.REACT_APP_FRONT_URL + "/challenge/" + id + "/general")
    };
    const handleTooltipClose = () => {
        setOpenToolTip(false);
    };

    const [valueLive, setValueLive] = useState<string>("");
    const [modalLive, setModalLive] = useState<boolean>(false);

    const handleTooltipLiveOpen = () => {
        setOpenToolTipLive(true);
        navigator.clipboard.writeText(valueLive);
    };
    const handleTooltipLiveClose = () => {
        setOpenToolTipLive(false);
    };

    useEffect(() => {
        if (openTooltip) {
            setTimeout(() => {
                setOpenToolTip(false);
            }, 2000);
        }
        if (openTooltipLive) {
            setTimeout(() => {
                setOpenToolTipLive(false);
            }, 2000);
        }
    }, [openTooltip, openTooltipLive]);
    const checkPublishment = async () => {
        api.post('/admin/challenge/publish/check',
            {
                challengeId: id
            },
            {
                withCredentials: true
            })
            .then((res) => {
                console.log('verifications effectuées');
                setPublished(true)
            })
            .catch((err) => {
                console.log(err)
                if (err.response.data.message === 'UnableToPostChallenge') {
                    if (err.response.data.requiredFields) {
                        setInfosModal(err.response.data.requiredFields)
                        setUnableToPostChallenge(true)
                    } else if (err.response.data.optionalFields) {
                        setInfosModal(err.response.data.optionalFields)
                        setPublishedWithErrors(true)
                    }
                }
            })
    }

    const publication = () => {
        api.put('/admin/challenge/publication',
            {
                challengeId: id,
                optionalFields: infosModal
            },
            {
                withCredentials: true
            })
            .then((res) => {
                console.log('challenge publié')
                setPublished(false)
                setPublishedWithErrors(false)
                console.log(res.data)
                getOneChallenge(String(id))
            })
            .catch((err) => {
                console.log(err)
                getOneChallenge(String(id))
            })
    }
    const registrationsLaunch = async () => {
        const path = challenge?.registration ? 'close' : 'launch'
        setError(null);
        await api.put('/admin/challenge/registrations/' + path,
            {
                challengeId: id,
            }, {
            withCredentials: true
        }).then((res) => {
            if (res.status === 200) {
                setOpenModalLaunchRegistrations(!openModalLaunchRegistrations);
                getOneChallenge(String(id))
            } else {
                setError(res.data);
                getOneChallenge(String(id))
            }
        })
            .catch((err) => {
                setError(err.response.data);
                getOneChallenge(String(id))
            });
    };


    useEffect(() => {

    }, [error]);


    const [openModalLaunchRegistrations, setOpenModalLaunchRegistrations] = useState<boolean>(false);

    useEffect(() => {
        console.log("openModalLaunchRegistrations", openModalLaunchRegistrations);
    }, [openModalLaunchRegistrations]);

    const linkLive = async () => {
        await api.put("/admin/challenge/link_live",
            {
                challengeId: id,
                valueLinkLive: valueLive
            },
            {
                withCredentials: true
            }
        );
        await getOneChallenge(String(id));
    }
    const getEligibility = async () => {
        await api.get('/challenge/eligibilities', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                setEligibilities(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };
    useEffect(() => {
        getEligibility();
    }, []);
    return (
        <>
            {challenge && (
                <section id="admin-infos-challenge-general" className="admin-infos-challenge-general">
                    <div className="left">
                        <div className="is-public">
                            <p className="bold"> Niveau de confidentialité du challenge</p>
                            <p className="text">Challenges et productions visibles
                                {challenge.public && ' par tous.'}
                                {!challenge.public && ' via un lien.'}
                            </p>
                        </div>
                        <div>
                            <p className="bold mb-2">Lien de partage de votre challenge</p>
                            <TextField
                                sx={{
                                    backgroundColor: '#EDF1F7',
                                    borderRadius: '10px',
                                    boxShadow: 'none',
                                    border: 'none',
                                    '& fieldset': {
                                        border: 'none', // Supprime la bordure du fieldset
                                    },
                                }}
                                hiddenLabel
                                id="filled-hidden-label-small"
                                defaultValue={`${process.env.REACT_APP_FRONT_URL}/challenge/${id}/general`}
                                // http://localhost:3000/participant/challenge/2/general
                                // http://localhost:3000/admin/challenges/infos/general/challenge2
                                value={`${process.env.REACT_APP_FRONT_URL}/challenge/${id}/general`}
                                variant="outlined"
                                InputProps={{
                                    sx: {
                                        backgroundColor: '#EDF1F7',
                                        borderRadius: '10px',
                                        boxShadow: 'none',
                                        border: 'none'
                                    },
                                    endAdornment:
                                        <InputAdornment
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                            position="start"
                                        >
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={() => handleTooltipClose}
                                                open={openTooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Copié !"
                                            >
                                                <IconCopy onClick={handleTooltipOpen} />
                                            </Tooltip>
                                        </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="publication">
                            <h2 className="h2">Publication de votre challenge</h2>
                            {
                                !challenge.published &&
                                <ActionButton
                                    variant={"primary"}
                                    onClick={() => checkPublishment()}
                                >
                                    Publier
                                </ActionButton>
                            }
                            {
                                challenge.published &&
                                <ActionButton
                                    variant={challenge.registration ? "disabled" : "tertiary"}
                                    onClick={challenge.registration ? () => {
                                    } : () => {
                                        setUnpublished(true)
                                        checkPublishment()
                                    }}
                                >
                                    Dépublier
                                </ActionButton>
                            }
                        </div>
                        <div className="dates-previsionnelles">
                            {
                                challenge?.registrationStartDate && challenge?.registrationEndDate &&
                                <>
                                    <h2 className="h2">Dates prévisionnelles des inscriptions</h2>
                                    <div className="dates">
                                        <div className="debut">
                                            <p className="bold">Début</p>
                                            <p className="text">
                                                <span><CalendarIcon /></span>{moment(challenge?.registrationStartDate).format("DD/MM/YYYY")}
                                            </p>
                                        </div>
                                        <div className="fin">
                                            <p className="bold">Fin</p>
                                            <p className="text">
                                                <span><CalendarIcon /></span>{moment(challenge?.registrationEndDate).format("DD/MM/YYYY")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="alert">
                                        <AlertIcon />
                                        <p>Les dates prévisionnelles indiquées ne déclenchent pas
                                            automatiquement l'ouverture et la fermeture des inscriptions : <br />
                                            cliquez sur les boutons Lancer ou Fermer les inscriptions.</p>
                                    </div>
                                </>
                            }
                            {(!challenge?.registrationStartDate || !challenge?.registrationEndDate) &&
                                <h2 className="h2">Lancement des inscriptions</h2>
                            }

                            {(!challenge?.registration || originalSteps.some((step) => step.status && step.status.name === 'done')) &&
                                <ActionButton
                                    variant={`${(challenge.status.name === 'finished' || !challenge.published || originalSteps.some((step) => step.status && step.status.name === 'done')) ? 'disabled' : 'primary'}`}
                                    onClick={(!challenge.published || originalSteps.some((step) => step.status && step.status.name === 'done')) ? () => {
                                    } : () => setOpenModalLaunchRegistrations(!openModalLaunchRegistrations)}
                                >
                                    Lancer les inscriptions
                                </ActionButton>
                            }
                            {(challenge.registration && !originalSteps.some((step) => step.status && step.status.name === 'done')) &&
                                <ActionButton
                                    variant={originalSteps.some((step) => step.status && step.status.name === 'done') ? "disabled" : 'tertiary'}
                                    onClick={originalSteps.some((step) => step.status && step.status.name === 'done') ? () => {
                                    } : () => setOpenModalLaunchRegistrations(!openModalLaunchRegistrations)}
                                >
                                    Fermer les inscriptions
                                </ActionButton>
                            }
                            {originalSteps.some((step) => step.status && step.status.name === 'done') && (
                                <p>Les projets ont déjà débuté, vous ne pouvez plus ouvrir les inscriptions.</p>
                            )

                            }
                            {!challenge.published &&
                                <p className="text text-italic">Votre challenge n'est pas publié</p>}
                        </div>
                        <section className={"live-challenge"}>
                            <p className={"live-challenge-title"}>Live du challenge</p>
                            <p className={"live-challenge-description"}>Live du challenge
                                pour tous les
                                moments clés de votre évènement : lancement, pitchs, keynote ...</p>
                            {
                                valueLive && (
                                    <TextField
                                        sx={{
                                            backgroundColor: '#EDF1F7',
                                            borderRadius: '10px',
                                            boxShadow: 'none',
                                            border: 'none',
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                            marginTop: "1rem"
                                        }}
                                        hiddenLabel
                                        id="filled-hidden-label-small"
                                        defaultValue={`${valueLive ? valueLive : "Ajoutez un lien de votre live..."}`}
                                        value={`${valueLive ? valueLive : "Ajoutez un lien de votre live..."}`}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            sx: {
                                                backgroundColor: "white",
                                                borderRadius: '10px',
                                                boxShadow: 'none',
                                                border: 'none'
                                            },
                                            endAdornment:
                                                <InputAdornment
                                                    sx={{
                                                        cursor: 'pointer',
                                                    }}
                                                    position="start"
                                                >
                                                    <Tooltip
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={() => handleTooltipLiveClose}
                                                        open={openTooltipLive}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title="Copié !"
                                                    >
                                                        <IconCopy onClick={handleTooltipLiveOpen} />
                                                    </Tooltip>
                                                </InputAdornment>,
                                        }}
                                    />
                                )
                            }
                            <ActionButton className={"add-button"} variant={"primary"}
                                onClick={() => setModalLive(true)}>
                                {
                                    valueLive ? "Modifier le lien" : "Ajouter un lien"
                                }
                            </ActionButton>
                        </section>

                        <div className="infos-generales">
                            <p className="h2">Du {moment(challenge.startDate).locale("fr").format("DD MMMM YYYY")} au {moment(challenge.endDate).locale("fr").format("DD MMMM YYYY")}</p>
                            <p className="text">{challenge.shortDescription ? challenge.shortDescription : 'Pas de description brève pour le moment'}</p>
                            <div>
                                <h2 className="h2">Description</h2>
                                <div className="text" dangerouslySetInnerHTML={{ __html: challenge.longDescription ? challenge.longDescription : 'Pas de description détaillée pour le moment' }} />
                                {/*<p className="text">{challenge.longDescription ? challenge.longDescription : 'Pas de description détaillée pour le moment'}</p> */}
                            </div>

                            <div className='modalites'>
                                <h2 className="h2">Modalités d'inscription</h2>
                                <h3 className="title-eligibilities">Critères pour participer à ce Challenge :</h3>
                                {
                                    eligibilities.length > 0 ? (
                                        <ul className={"bloc-eligibilities"}>
                                            {
                                                eligibilities.map((eligibility, index) => (
                                                    <li key={index} className="eligibility">{eligibility.label}</li>
                                                ))
                                            }
                                        </ul>
                                    ) : (
                                        <p className="no-eligibilities">Pas de critères d'éligibilité</p>
                                    )
                                }
                                {
                                    (challenge.maxMembers && challenge.participationType) ?
                                        <div className="all-modalites">
                                            {challenge.maxMembers &&
                                                <div>
                                                    <p className="bold number">{challenge.maxMembers}</p>
                                                    <p className="text">participants au maximum</p>
                                                </div>
                                            }
                                            {challenge.participationType &&
                                                <div>
                                                    <p className="bold">Participation :</p>
                                                    {challenge.participationType && <p className="text">
                                                        {
                                                            challenge.participationType === 'team' ?
                                                                'En équipe' :
                                                                challenge.participationType === 'both' ?
                                                                    'Seul(e) ou en équipe' :
                                                                    'Seul(e)'
                                                        }
                                                    </p>}
                                                    {challenge.participationType !== 'single' &&
                                                        <p className="nb-participants text">
                                                            {
                                                                challenge.participationType === 'team' ?
                                                                    'de ' + challenge.minMembersTeam + ' à ' + challenge.maxMembersTeam + ' membres' :
                                                                    "jusqu'à " + challenge.maxMembersTeam + ' membres'
                                                            }
                                                        </p>}
                                                </div>
                                            }
                                        </div> :
                                        <p className="text">Pas de modalités d'inscription pour le moment</p>
                                }
                            </div>

                            <div className='reglement'>
                                <h2 className="h2">Règlement du challenge</h2>
                                {
                                    challenge.file?.fileName ?
                                        <a href={challenge.file.fileUrl} target="_blank" rel="noreferrer">
                                            {challenge.file.originalFileName} <EyeIcon />
                                        </a> :
                                        <p className="text">Pas de règlement pour le moment</p>
                                }
                            </div>

                        </div>
                    </div>

                    <div className="right">
                        <div className="all-ressources list">
                            <h1>Ressources</h1>
                            {
                                challenge.resources.length === 0 ?
                                    <p className="text center-text">Pas de ressources pour le moment</p>
                                    :
                                    challenge.resources?.map((resources, index: number) => (
                                        <div key={index}>
                                            <a key={index} className="ressource"
                                                href={resources.link ? resources.link : resources.file?.fileUrl}
                                                target='_blank' rel="noreferrer">
                                                <div className="texts">
                                                    <p className="bold">{resources.name}</p>
                                                    {
                                                        //<p className="text">{resources.link ? resources.link : resources.file?.originalFileName}</p>*}
                                                    }
                                                </div>
                                                {resources.link && <LinkIcon />}
                                                {resources.file?.fileName && <DownloadIcon />}
                                            </a>
                                        </div>
                                    ))

                            }

                        </div>


                        <div className="all-defis list">
                            <h1>Défis</h1>
                            {!challenge.hasDefis ?
                                <p className="text center-text">Rubrique désactivée</p> :
                                challenge.hasDefis && challenge?.subChallenges.length === 0 ?
                                    <p className="center-text">Pas de défis pour le moment</p> :
                                    challenge?.subChallenges.map((dare, index: number) => (
                                        <div className="defi" key={index}>
                                            <p className="bold">{dare.name}</p>
                                            <p className="text">{dare.description}</p>
                                        </div>
                                    ))
                            }
                        </div>


                        <div className="all-prix list">
                            <h1>Prix</h1>
                            {!challenge.hasPrizes ?
                                <p className="text center-text">Rubrique désactivée</p> :
                                challenge.hasPrizes && challenge.prizes?.length === 0 ?
                                    <p className="center-text">Pas de prix pour le moment</p> :
                                    challenge?.prizes.map((price, index) => (
                                        <div className="prix" key={index}>
                                            <div className="texts-img">
                                                <div className="img"
                                                    style={{ background: `url(${price.prizePicture}) center center/cover` }}></div>
                                                <p className="bold">{price.name}</p>
                                            </div>
                                            {price.description && <p className="text">{price.description}</p>}
                                        </div>
                                    ))
                            }
                        </div>


                        <div className="all-partenaires list">
                            <h1>Partenaires</h1>
                            <div className="partenaires-cards">
                                {
                                    !challenge.hasPartners ?
                                        <p className="text center-text">Rubrique désactivée</p> :
                                        challenge.hasPartners && challenge.partners?.length === 0 ?
                                            <p className="center-text">Pas de partenaires pour le moment</p> :
                                            challenge.partners.map((partner, index) => (
                                                <div className="partenaire" key={index}>
                                                    <div className="img"
                                                        style={{ background: `url(${partner.partnerLogo}) center center/cover` }}></div>
                                                    <div className="texts">
                                                        <p className="bold">{partner.name}</p>
                                                        {partner.partnerLink &&
                                                            <div className="liens"><a href={partner.partnerLink}
                                                                target="_blank" rel="noreferrer">En
                                                                savoir plus </a> {'>'}</div>}
                                                    </div>
                                                </div>
                                            ))
                                }
                            </div>
                        </div>


                        <div className="all-faq list">
                            <h1>FAQ</h1>
                            <div className="questions">
                                {
                                    !challenge.hasFaq ?
                                        <p className="text center-text">Rubrique désactivée</p> :
                                        challenge.hasFaq && challenge?.itemFAQs?.length === 0 ?
                                            <p className="center-text">Pas de questions pour le moment</p> :
                                            challenge.itemFAQs.map((question, index: number) => <FaqCard
                                                question={question} key={index} />)
                                }
                            </div>
                        </div>
                    </div>

                    {/* Publier Dépublier */}
                    <Modal
                        open={published}
                        onClose={() => setPublished(false)}
                        icon={<IconAnnonce />}
                        iconColor={'saumon50'}
                        title={`Vous vous apprêtez à ${unpublished ? 'dépublier' : 'publier'} votre évènement`}
                        primaryButtonText={`${unpublished ? 'Dépublier' : 'Publier'}`}
                        primaryButtonAction={() => {
                            setPublished(false)
                            setUnpublished(false)
                            publication()
                        }}
                        tertiaryButtonText={'Annuler'}
                        tertiaryButtonAction={() => {
                            setPublished(false)
                            setUnpublished(false)
                        }}
                    >
                        <p className="text">Votre évènement {unpublished && 're'}deviendra {unpublished && 'in'}visible
                            par les membres de Makee. Confirmez-vous cette {unpublished && 'dé'}publication ?</p>
                    </Modal>
                    {/* {
                        published &&
                        <div className="modal">
                            <div className="modal-content">
                                <AnnonceIcon/>
                                <p className="h2">Vous vous apprêtez à {unpublished && 'dé'}publier votre évènement</p>
                                <p className="text">Votre évènement {unpublished && 're'}deviendra {unpublished && 'in'}visible
                                    par les membres de Makee. Confirmez-vous cette {unpublished && 'dé'}publication ?</p>
                                <div className="buttons">
                                    <ActionButton
                                        variant={"tertiary"}
                                        onClick={() => {
                                            setPublished(false)
                                            setUnpublished(false)
                                        }}
                                    >
                                        Annuler
                                    </ActionButton>
                                    <ActionButton
                                        variant={"primary"}
                                        onClick={() => {
                                            setPublished(false)
                                            setUnpublished(false)
                                            publication()
                                        }}
                                    >
                                        {unpublished && 'Dépublier'}
                                        {!unpublished && 'Publier'}
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                    } */}

                    {/* Publier error incomplet */}
                    <Modal
                        open={publishedWithErrors}
                        onClose={() => setPublishedWithErrors(false)}
                        icon={<IconAnnonce />}
                        iconColor={'saumon50'}
                        title={"Vous vous apprêtez à publier votre évènement"}
                        primaryButtonText={"Publier"}
                        primaryButtonAction={() => {
                            setPublishedWithErrors(false)
                            publication()
                        }}
                        tertiaryButtonText={"Annuler"}
                        tertiaryButtonAction={() => setPublishedWithErrors(false)}
                    >
                        <div className='recap'>
                            <p className="text">Votre évènement va être publié avec :</p>
                            <ul>
                                {
                                    infosModal?.map((info, index) => (
                                        info === 'subchallenges' ?
                                            <li key={index}
                                                className={challenge?.subChallenges.length === 0 ? 'text error' : "text"}>
                                                {challenge?.subChallenges.length + ' défis'}
                                            </li> :
                                            info === 'partners' ?
                                                <li key={index}
                                                    className={challenge?.partners.length === 0 ? 'text error' : "text"}>
                                                    {challenge?.partners.length + ' partenaire' + (challenge && challenge?.partners.length !== 1 ? 's' : '')}
                                                </li> :
                                                info === 'prizes' ?
                                                    <li key={index}
                                                        className={challenge?.prizes.length === 0 ? 'text error' : "text"}>
                                                        {challenge?.prizes.length + ' prix'}
                                                    </li> :
                                                    info === 'faq' ?
                                                        <li key={index}
                                                            className={challenge?.questions.length === 0 ? 'text error' : "text"}>
                                                            {challenge?.questions.length + ' question' + (challenge && challenge?.questions.length !== 1 ? 's' : '') + 'dans votre FAQ'}
                                                        </li> :
                                                        ''
                                    ))
                                }
                            </ul>
                            <p className="text">Les rubriques <span
                                className='text error'>activées et non utilisées</span> seront automatiquement
                                désactivées.</p>
                        </div>

                        <p className="text">Votre évènement deviendra visible par les membres de Makee. Confirmez-vous
                            cette publication ?</p>
                    </Modal>

                    {/* {
                        publishedWithErrors &&
                        <div className="modal">
                            <div className="modal-content error">
                                <AnnonceIcon/>
                                <p className="h2">Vous vous apprêtez à publier votre évènement</p>
                                <div className='recap'>
                                    <p className="text">Votre évènement va être publié avec :</p>
                                    <ul>
                                        {
                                            infosModal?.map((info) => (
                                                info === 'subchallenges' ?
                                                    <li className={getOneChallenge?.dares.length === 0 ? 'text error' : "text"}>
                                                        {getOneChallenge?.dares.length + ' défis'}
                                                    </li> :
                                                    info === 'partners' ?
                                                        <li className={getOneChallenge?.partners.length === 0 ? 'text error' : "text"}>
                                                            {getOneChallenge?.partners.length + ' partenaire' + (getOneChallenge && getOneChallenge?.partners.length !== 1 ? 's' : '')}
                                                        </li> :
                                                        info === 'prizes' ?
                                                            <li className={getOneChallenge?.prizes.length === 0 ? 'text error' : "text"}>
                                                                {getOneChallenge?.prizes.length + ' prix'}
                                                            </li> :
                                                            info === 'faq' ?
                                                                <li className={getOneChallenge?.questions.length === 0 ? 'text error' : "text"}>
                                                                    {getOneChallenge?.questions.length + ' question' + (getOneChallenge && getOneChallenge?.questions.length !== 1 ? 's' : '') + 'dans votre FAQ'}
                                                                </li> :
                                                                ''
                                            ))
                                        }
                                    </ul>
                                    <p className="text">Les rubriques <span
                                        className='text error'>activées et non utilisées</span> seront automatiquement
                                        désactivées.</p>
                                </div>

                                <p className="text">Votre évènement deviendra visible par les membres de Makee. Confirmez-vous
                                    cette publication ?</p>
                                <div className="buttons">
                                    <ActionButton
                                        variant={"tertiary"}
                                        onClick={() => setPublishedWithErrors(false)}
                                    >
                                        Annuler
                                    </ActionButton>
                                    <ActionButton
                                        variant={"primary"}
                                        onClick={() => {
                                            setPublishedWithErrors(false)
                                            publication()
                                        }}
                                    >
                                        Publier
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                    } */}

                    {/* Publier error incomplet */}
                    <Modal
                        open={unableToPostChallenge}
                        primaryButtonAction={() => setUnableToPostChallenge(false)}
                        onClose={() => setUnableToPostChallenge(false)}
                        primaryButtonText={"J'ai compris"}
                        icon={<IconAnnonce />}
                        iconColor={'saumon50'}
                        title={"Votre événement ne peut pas être publié car il n’est pas complet"}
                    >
                        <div>
                            <p className="text">{'Veuillez les remplir les éléments suivants avant de publier votre Challenge :'}</p>
                            <ul className="list-requiered">
                                {infosModal?.map((info, index) => (
                                    <li key={index} className="text">
                                        {
                                            info === 'steps' ? 'Au moins une étape' :
                                                info === 'name' ? 'Un nom' :
                                                    info === 'shortDescription' ? 'Une description courte' :
                                                        info === 'startDate' ? 'Une date de début de challenge' :
                                                            info === 'endDate' ? 'Une date de fin de challenge' :
                                                                info === 'participationType' ? 'Un type de participation' :
                                                                    info === 'challengePicture' ? 'Une image de couverture' :
                                                                        info === 'longDescription' ? 'Une description longue' :
                                                                            info === 'maxMembers' ? 'Un nombre maximum de participants' :
                                                                                ''
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Modal>
                    <Modal
                        open={openModalLaunchRegistrations}
                        onClose={() => setOpenModalLaunchRegistrations(false)}
                        icon={<IconAnnonce />}
                        iconColor={'saumon50'}
                        title={challenge.registration ? 'Fermer les inscriptions à votre challenge ?' : 'Ouvrir les inscriptions à votre challenge ?'}
                        tertiaryButtonText={'Annuler'}
                        tertiaryButtonAction={() => setOpenModalLaunchRegistrations(false)}
                        primaryButtonText={challenge.registration ? 'Fermer les inscriptions' : 'Ouvrir les inscriptions'}
                        primaryButtonAction={() => registrationsLaunch()}
                    >
                        {challenge.registration
                            ? <p>Les utilisateurs de Makee ne pourront plus s’inscrire à votre challenge.</p>
                            : <>
                                <p>Les utilisateurs de Makee pourront s’inscrire à votre challenge.</p>
                                {challenge.public
                                    ? <p><span>Pour rappel :</span> votre challenge est public. <br />
                                        Tous les utilisateurs de Makee pourront s’inscrire à votre Challenge.</p>
                                    : <p><span>Pour rappel :</span> votre challenge est privé. <br />
                                        Seules les personnes ayant reçu le lien de votre Challenge pourront s’inscrire.
                                    </p>
                                }
                            </>
                        }
                        <p className="error-message">{error && error.message}</p>
                    </Modal>
                    <Modal
                        open={modalLive}
                        onClose={() => {
                            setModalLive(false);
                            setValueLive(challenge.link_live);
                        }}
                        icon={<IconAnnonce />}
                        iconColor={'saumon50'}
                        title={valueLive ? "Modifier le lien du live" : "Ajouter le lien du live"}
                        primaryButtonText={valueLive ? "Modifier" : "Ajouter"}
                        primaryButtonAction={async () => {
                            await linkLive();
                            setModalLive(false);
                        }}
                        tertiaryButtonText={"Annuler"}
                        tertiaryButtonAction={() => {
                            setModalLive(false);
                            setValueLive(challenge.link_live);
                        }}
                    >
                        <TextField
                            label="Lien du live..."
                            placeholder="Lien du live..."
                            variant="outlined"
                            value={valueLive ? valueLive : ""}
                            onChange={(e: any) => setValueLive(e.target.value)}
                        />
                    </Modal>
                </section>
            )}
        </>
    )
}

export default AdminInfosChallengeGeneral;

