// Ces inputs sont utilisés pour les codes de vérification à 6 chiffres

// Ils possèdent plusieurs propriétés :
// - value : la valeur de l'input
// - valueLength : la longueur de la valeur de l'input
// - onChange : la fonction à exécuter au changement de la valeur de l'input

// react
import { Fragment, ChangeEvent, KeyboardEvent, FocusEvent, useState, useEffect } from 'react';
import { useMemo } from 'react';

// style 
import './index.css';

// props
type OtpProps = {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
    errorMessage?: string;
};

// regex pour vérifier que la valeur de l'input est un chiffre ou une lettre
export const RE_ALPHANUMERIC = new RegExp(/^[A-Z0-9]+$/i);

const OtpInput = ({
    value,
    valueLength,
    onChange,
    errorMessage
}: OtpProps) => {

    // Value Items correspond à un tableau contenant chaque caractère de la valeur de l'input
    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items: Array<string> = [];
        for (let i = 0; i < valueLength; i++) {
            let item = valueArray[i] || '';
            if (RE_ALPHANUMERIC.test(item)) {
                item = item.toUpperCase(); // Convertir en majuscules
            } else {
                item = '';
            }
            items.push(item);
        }
        console.log(items)
        return items;
    }, [value, valueLength])

    // Rendu des inputs (un input par caractère)
    const inputOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        idx: number
    ) => {
        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_ALPHANUMERIC.test(targetValue);
        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }
        targetValue = isTargetValueDigit ? targetValue : ' ';
        const targetValueLength = targetValue.length;
        if (targetValueLength === 1) {
            const newValue =
                value.substring(0, idx) + targetValue + value.substring(idx + 1);
            onChange(newValue);
            if (!isTargetValueDigit) {
                return;
            }
            const nextElementSibling =
                target.nextElementSibling as HTMLInputElement | null;
            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);
            target.blur();
        }
    };

    // Effacer le caractère précédent lors de l'appui sur la touche Backspace
    const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const targetValue = target.value;
        target.setSelectionRange(0, targetValue.length)
        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }
        const previousElementSibling =
            target.previousElementSibling as HTMLInputElement | null;
        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    // Selectionner tout le texte lors du focus
    const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
        const { target } = e;
        target.setSelectionRange(0, target.value.length);
    }

    // vérifier si il y a une erreur pour changer la classe des input et ainsi changer le style
    const [className, setClassName] = useState('otp-input' as string);
    useEffect(() => {
        if (errorMessage) {
            setClassName('otp-input-div-page-error');
        } else {
            setClassName('otp-input-div-page');
        }
    }, [errorMessage]);

    // if (errorMessage) {
    //     setClassName('otp-input otp-input-error');
    // } else {
    //     setClassName('otp-input');
    // }, [errorMessage]);

    return (
        <>
            <div className="otp-input-container">
                <section className={className}>
                    <div className="otp-group">
                        {valueItems.map((digit, index) => (
                            <Fragment key={index}>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    autoComplete="one-time-code"
                                    pattern="\d{1}"
                                    maxLength={valueLength}
                                    className="otp-input"
                                    value={digit}
                                    onChange={(event) => inputOnChange(event, index)}
                                    onKeyDown={inputOnKeyDown}
                                    onFocus={inputOnFocus}
                                    placeholder="_"
                                />
                            </Fragment>
                        ))}
                    </div>
                </section>
                {errorMessage && <p className="otp-error">{errorMessage}</p>}
            </div>
        </>
    );
}

export default OtpInput;