// challenges
import { Outlet, useLocation } from "react-router-dom";

// modules
import OutletNavBar from "../../../../../modules/MenuTabs";

const ProfilePreview = () => {

    const location = useLocation()

    return (
        <>
            <div className="grey-background"></div>
            {/*<div className="outlet-profile">*/}
            {/*    {location.pathname.includes('admin') && <MenuTabs*/}
            {/*        links={[*/}
            {/*            {*/}
            {/*                link: '/admin/profile/preview/infos',*/}
            {/*                label: 'Profil',*/}
            {/*                key: 'infos'*/}
            {/*            },*/}
            {/*            {*/}
            {/*                link: '/admin/profile/preview/profile-chall',*/}
            {/*                label: 'Challenges',*/}
            {/*                key: 'profile-chall'*/}
            {/*            }*/}
            {/*        ]}*/}
            {/*    />}*/}
            {/*</div>*/}
            <Outlet />
        </>
    )
}

export default ProfilePreview;