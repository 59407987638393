// dnd-kit
import {
    DndContext,
    useSensor
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';

// sensor
import GlobalPointerSensor from '../../utils/GlobalPointerSensor';

// types
import Dare from '../Dare';
import SubChallenge from './types';
import IChallenge from '../../pages/MINIMAL/Admin/AdminChallenges/types'


// react
import React, { useEffect, useState } from 'react';

// components
import ActionButton from '../ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';

// style
import './index.css'

import { v4 as uuidv4 } from 'uuid';
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/index';

interface DareListProps {
    setChallenge: (challenge: IChallenge) => void
    challenge: IChallenge
    // setChallenge: React.Dispatch<React.SetStateAction<IChallenge>>
}

const DareList = ({setChallenge, challenge }: DareListProps) => {    
    const sensors = [useSensor(GlobalPointerSensor)];
    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()

    useEffect(() => {
        console.log("errorFieldGeneral LIst DAre", errorFieldGeneral);
        //Si il y a plus de 2 subChallenges alors on supprime l'erreur avec le code subChallenges
        if (challenge.subChallenges.length > 1) {
            setErrorFieldGeneral(errorFieldGeneral.filter((error:any) => error.code !== "subChallenges"));
        }
    }, [challenge.subChallenges]);
        
    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = challenge.subChallenges.findIndex((dare) => dare.id === active.id);
            const newIndex = challenge.subChallenges.findIndex((dare) => dare.id === over.id);

            const newDares = challenge.subChallenges.map((dare) => {
                if (dare.id === active.id) {
                    return {
                        ...dare,
                        position: newIndex + 1
                    };
                } else if (dare.id === over.id) {
                    return {
                        ...dare,
                        position: oldIndex + 1
                    };
                }
                return dare;
            });

            // Mettre à jour le challenge en changeant la position
            setChallenge({
                ...challenge,
                subChallenges: arrayMove(newDares, oldIndex, newIndex)
            });
        }
    };

    const handleDeleteDare = (dare: SubChallenge) => {
        // Supprimer dare et remettre les positions à jour
        const newDares = challenge.subChallenges
            .filter((d) => String(d.id) !== String(dare.id))
            .map((d, index) => ({
                ...d,
                position: index + 1
            }));

        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            subChallenges: newDares
        });
    };

    const handleUpdateDare = (dare: SubChallenge) => {
        // Mettre à jour dare
        const newDares = challenge.subChallenges.map((d) =>
            String(d.id) === String(dare.id) ? dare : d
        );

        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            subChallenges: newDares
        });
    };

    const handleAddDare = () => {
        console.log('add dare');
        console.log("dare", challenge.subChallenges);
        // Ajouter Dare
        const newDare: SubChallenge = {
            id: "new-"+uuidv4(),
            name: "",
            description: "",
            position: challenge.subChallenges.length + 1
        };

        // Ajouter newDare à challenge
        setChallenge({
            ...challenge,
            subChallenges: [...challenge.subChallenges, newDare]
        });
    };

    return (
        <div className="defis">
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={challenge.subChallenges.sort((a, b) => a.position - b.position).map((dare) => dare.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {challenge.subChallenges
                        .map((dare, index) => (
                        <Dare
                            disabled={challenge.registration}
                            key={String(dare.id)}
                            dare={dare}
                            handleDeleteDare={handleDeleteDare}
                            handleUpdateDare={handleUpdateDare}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <ActionButton
                variant={(challenge.registration || challenge.status.name==="finished")?'disabled':'secondary'}
                onClick={(challenge.registration || challenge.status.name==="finished")?()=>{}:handleAddDare}
                className='button-add-dare'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter un défi
            </ActionButton>
        </div>
    )
}

export default DareList;
