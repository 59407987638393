import "./index.css";
import React from "react";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";


const SupportFooter = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <div className="support-footer">
            <p>
                <strong>{t("contactSupport")} : </strong>
                <a href="mailto:support@makee.io">support@makee.io</a>
            </p>
        </div>
    )
}

export default SupportFooter;