// style
import '../index.css'

// material UI
import {
    Alert,
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Snackbar, TextareaAutosize,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material'

// components
import SwitchButton from '../../../../../../components/SwitchButton'
import PrizeList from '../../../../../../components/PrizeList'
import PartnerList from '../../../../../../components/PartnerList'
import QuestionList from '../../../../../../components/QuestionList'
import DareList from '../../../../../../components/DareList'

// modules
import ImageUploader from '../../../../../../modules/ImageUploader/ImageUploader'

// react routeur
import {useNavigate, useParams} from 'react-router-dom'

// react
// react
import React, {createRef, useEffect, useState} from 'react'

// assets
import {ReactComponent as AnnonceIcon} from '../../../../../../assets/icon-annonce.svg'
import "./index.css"
// outlet context
import {useAdminEditChallengeContext} from '../index';
import moment, {Moment} from 'moment'
import {DateTimePicker, renderTimeViewClock} from '@mui/x-date-pickers'
import api from "../../../../../../utils/api";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
// CK EDITOR
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AdminEditChallengeGeneral = () => {

    const [imagePreview, setImagePreview] = useState<string>("");
    const [logoPreview, setLogoPreview] = useState<string>("");
    const {imgToUpload, setImgToUpload} = useAdminEditChallengeContext();
    const {logoToUpload, setLogoToUpload} = useAdminEditChallengeContext();

    useEffect(() => {
        console.log("logoToUpload Child", logoToUpload);
    }, [logoToUpload])

    // navigation
    const navigate = useNavigate();

    const id = useParams().id;

    const {getOneChallenge} = useAdminEditChallengeContext()
    const {challenge, setChallenge} = useAdminEditChallengeContext()
    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    // const {click, setClick} = useAdminEditChallengeContext();
    const {setChange} = useAdminEditChallengeContext();
    const {setPage} = useAdminEditChallengeContext();
    const {hasClickedOnCancelButton} = useAdminEditChallengeContext();
    const {truc, setTruc} = useAdminEditChallengeContext();
    const {truc2, setTruc2} = useAdminEditChallengeContext();
    const {handleDeleteImg, handleDeleteLogo} = useAdminEditChallengeContext();

    //Calcul Hauteur Header
    const [heightHeader, setHeightHeader] = useState(0);
    const [heightFooter, setHeightFooter] = useState(0);
    //SnackBar
    const {openSnackBarEditChallenge, setOpenSnackBarEditChallenge} = useAdminEditChallengeContext();
    const {variantSnackEditChallenge, setVariantSnackBarEditChallenge} = useAdminEditChallengeContext();
    const {textSnackBarEditChallenge, setTextSnackBarEditChallenge} = useAdminEditChallengeContext();
    const {setOriginalSteps} = useAdminEditChallengeContext();

    //Authorization Step
    const [smallestDateStartDeposite, setSmallestDateStartDeposite] = useState();
    const [largestDateStartDeposite, setLargestDateStartDeposite] = useState();

    useEffect(() => {
        getOneChallenge(String(id));
    }, [])
    const getSteps = async () => {
        await api.get("/admin/challenge/steps",
            {
                params: {
                    challengeId: id
                },
                withCredentials: true
            }
        ).then((res) => {
            const steps = res.data.steps;
            setOriginalSteps(steps);

            const smallestDateStartDeposite = steps.reduce((minDate: moment.MomentInput, step: {
                dateStartDeposite: moment.MomentInput
            }) => {
                const stepDate = moment(step.dateStartDeposite);
                return stepDate.isBefore(minDate) ? stepDate : minDate;
            }, moment());

            setSmallestDateStartDeposite(smallestDateStartDeposite)

            const getMaxDate = (steps: any[]) => {
                const compareDates = (a: string, b: string) => new Date(b).getTime() - new Date(a).getTime();
                let dates: any[] = [];

                steps.map((step) => {
                    if (step.dateEndSelection) {
                        dates.push(step.dateEndSelection)
                    } else {
                        dates.push(step.dateEndDeposite)
                    }
                })

                return dates.sort(compareDates)[0];
            }

            setLargestDateStartDeposite(getMaxDate(steps));

        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getSteps()
    }, [])
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);
    const updateChallenge = async (field: keyof any, value: string | Moment | boolean | null) => {

        if (challenge) {
            let copyChallenge: any = {...challenge};
            if ((field === "startDate" || field === 'endDate') && moment.isMoment(value)) {
                const currentLanguage = i18n.language;
                let dateFormat = 'DD/MM/YYYY hh:mm'; // Format par défaut
                if (currentLanguage === 'fr') {
                    dateFormat = 'DD/MM/YYYY HH:mm'; // Format français
                } else if (currentLanguage === 'en') {
                    dateFormat = 'MM/DD/YYYY hh:mm A'; // Format anglais
                }
                copyChallenge = {...challenge};
                copyChallenge[field] = moment(value, dateFormat).toISOString();
                let copy = [...errorFieldGeneral];
                //Type challenge
                if ((moment(copyChallenge.startDate).isAfter(moment(copyChallenge.endDate))) || (moment(copyChallenge.endDate).isBefore(moment(copyChallenge.startDate)))) {
                    copy.push(
                        {
                            code: 'startDate',
                            message: `Cette date doit être antérieure à votre date de fin du challenge`,
                            type: 'challenge'
                        },
                        {
                            code: 'endDate',
                            message: `Cette date doit être postérieure à votre date de début du challenge`,
                            type: 'challenge'
                        }
                    )
                } else {
                    //Supprimer les erreurs de type challenge startDate et endDate
                    copy = copy.filter((error: any) => !(error.type === "challenge"));
                }

                //Type step
                if (moment(copyChallenge.startDate).isAfter(moment(smallestDateStartDeposite))) {
                    copy.push(
                        {
                            code: 'startDate',
                            message: `Cette date doit se trouver avant votre première étape : ${moment(smallestDateStartDeposite).format(dateFormat)}`,
                            type: 'step'
                        },
                    )
                } else {
                    //Supprime les erreur step avec startDate
                    copy = copy.filter((error: any) => !(error.code === "startDate" && error.type === "step"));
                }

                if (largestDateStartDeposite && moment(copyChallenge.endDate).isBefore(moment(largestDateStartDeposite))) {
                    copy.push(
                        {
                            code: 'endDate',
                            message: `Cette date doit se trouver après votre dernière étape : ${moment(largestDateStartDeposite).format(dateFormat)}`,
                            type: 'step'
                        },
                    )
                } else {
                    //Supprime les erreur step avec endDate
                    copy = copy.filter((error: any) => !(error.code === "endDate" && error.type === "step"));
                }
                setErrorFieldGeneral(copy)

            } else {
                copyChallenge[field] = value;
            }
            setChallenge(copyChallenge);
        }
    };

    useEffect(() => {
        setPage('general');
    }, []);
    // params

    useEffect(() => {

        // Fonction pour afficher la hauteur
        const logHeight = () => {
            const heightHeader = document.querySelector('.admin-edit-challenge-outlet-nav')?.clientHeight;
            const heightFooter = document.querySelector('.admin-edit-challenge-nav-bottom')?.clientHeight;

            heightFooter && setHeightFooter(heightFooter);
            heightHeader && setHeightHeader(heightHeader);
        };

        // Appeler la fonction pour la première fois
        logHeight();

        // Mettre à jour la hauteur en temps réel (par exemple, lors d'un redimensionnement de la fenêtre)
        const handleResize = () => {
            logHeight();
        };

        // Ajouter un écouteur d'événements de redimensionnement de fenêtre
        window.addEventListener('resize', handleResize);

        // Assurez-vous de désinscrire l'événement lorsqu'il n'est plus nécessaire
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log("errorFieldGeneral", errorFieldGeneral);
    }, [errorFieldGeneral])

    const rubriques = (
        <>
            {challenge && (
                <Grid item maxWidth={'45rem'} margin={'auto'}>
                    <div className="switch-rubriques desktop">
                        {challenge.hasDefis && (
                            <div className="defis desktop">
                                {/* <div className="white-blank-rectangle"></div> */}
                                <div className="title text-center">
                                    <h1>Créez vos défis</h1>
                                    <p className="text italic text-center">Le nombre recommandé est de 3 à 4 défis, avec
                                        un minimum
                                        de 2 défis pour un Challenge.</p>
                                </div>
                                <DareList
                                    setChallenge={setChallenge}
                                    challenge={challenge}
                                />
                            </div>
                        )}
                        {challenge.hasPrizes && (
                            <div className="prizes desktop">
                                {/* <div className="white-blank-rectangle"></div> */}
                                <div className="title text-center">
                                    <h1>Prix à gagner</h1>
                                    <p className="text italic text-center">Affichez les prix à gagner et ajoutez une
                                        photo pour les
                                        rendre plus visibles et attractifs.</p>
                                </div>
                                <PrizeList
                                    setChallenge={setChallenge}
                                    challenge={challenge}
                                />
                            </div>
                        )}
                        {challenge.hasPartners &&
                            <div className="partners desktop">
                                {/* <div className="white-blank-rectangle"></div> */}
                                <div className="title text-center">
                                    <h1>Partenaires</h1>
                                    <p className="text italic text-center">Listez les partenaires de votre évènement et
                                        ajoutez leur
                                        logo et le lien vers leur site.</p>
                                </div>
                                <PartnerList
                                    setChallenge={setChallenge}
                                    challenge={challenge}
                                />
                            </div>
                        }
                        {challenge.hasFaq &&
                            <div className="questions desktop">
                                {/* <div className="white-blank-rectangle"></div> */}
                                <div className="title text-center">
                                    <h1>FAQ</h1>
                                    <p className="text italic text-center">Créez votre FAQ personnalisée et gagnez du
                                        temps dans la
                                        gestion de votre Challenge : anticipez les principales questions que pourraient
                                        se poser vos participants.</p>
                                </div>
                                <QuestionList
                                    setChallenge={setChallenge}
                                    challenge={challenge}
                                />
                            </div>
                        }
                    </div>
                </Grid>
            )}
        </>
    )
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {challenge && (
                <div className="admin-edit-challenge">
                    <Grid container justifyContent={"auto"} justifyItems={"auto"} spacing={2} paddingX={2}
                          paddingLeft={4}>
                        <Grid item xs={12} md={6} paddingX={2}>
                            <section className="admin-edit-challenge-general">
                                <div className="bloc-edit-general scroll">
                                    <div className="header-text">
                                        <h1>Présentation</h1>
                                        <p className="text p-annonce"><span
                                            className='icon-annonce'><AnnonceIcon/></span>Nécessaire à la publication de
                                            votre Challenge.</p>
                                    </div>
                                    <div className="text-champs-obligatoires">
                                        <p className="text">* Champs obligatoires</p>
                                    </div>
                                    <FormControl
                                        onChange={() => setChange(true)}
                                    >
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': {marginTop: '40px'},
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <p className="small-lead-text annonce-text">Nom du Challenge (max 50
                                                    caractères) *<span
                                                        className='icon-annonce'><AnnonceIcon/></span></p>
                                                <TextField
                                                    disabled={challenge.status.name === "finished"}
                                                    size='small'
                                                    id="name"
                                                    variant="outlined"
                                                    placeholder='Nom du challenge'
                                                    type='text'
                                                    required
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                    multiline
                                                    value={challenge.name ? challenge.name : ""}
                                                    onChange={(e: any) => updateChallenge("name", e.target.value)}
                                                    error={errorFieldGeneral.find((error: any) => error.code === "name") ? true : false}
                                                    helperText={errorFieldGeneral.find((error: any) => error.code === "name") ? errorFieldGeneral.find((error: any) => error.code === "name").message : ""}
                                                    // onChange={(e: any) => setTitle(e.target.value)}
                                                />
                                            </div>

                                            <div>
                                                <p className="small-lead-text annonce-text">Description brève (max 200
                                                    caractères) *<span className='icon-annonce'><AnnonceIcon/></span>
                                                </p>
                                                <TextField
                                                    disabled={challenge.status.name === "finished"}
                                                    size='small'
                                                    id="shortDescription"
                                                    variant="outlined"
                                                    placeholder='Écrire une description'
                                                    type='text'
                                                    required
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 200
                                                    }}
                                                    multiline
                                                    rows={4}
                                                    value={challenge.shortDescription ? challenge.shortDescription : ""}
                                                    onChange={(e: any) => updateChallenge("shortDescription", e.target.value)}
                                                    // onChange={(e: any) => setShortDescription(e.target.value)}
                                                />
                                            </div>

                                            <div className="inputs">
                                                <p className="small-lead-text annonce-text">Logo du Challenge</p>
                                                <ImageUploader
                                                    disabled={challenge.status.name === "finished"}
                                                    image={challenge.challengeLogo ? challenge.challengeLogo : ""}
                                                    defaultImage={"image-challenge-logo"}
                                                    imagePreview={logoPreview}
                                                    setImagePreview={setLogoPreview}
                                                    setFileToUpload={setLogoToUpload}
                                                    noImageText="Vous n'avez pas encore de logo pour votre challenge"
                                                    noImageButtonText='Ajouter une image'
                                                    hasClickedOnCancelButton={hasClickedOnCancelButton}
                                                    setChange={setChange}
                                                    truc={truc}
                                                    setTruc={setTruc}
                                                    imgRef={createRef()}
                                                    id={"logo-edit-challenge-general"}
                                                    handleDelete={handleDeleteLogo}
                                                    ratio="1/1"
                                                    width={"auto"}
                                                    height={"20rem"}
                                                />
                                            </div>

                                            <div className="inputs">
                                                <p className="small-lead-text annonce-text">Image de couverture du
                                                    challenge <span
                                                        className='icon-annonce'><AnnonceIcon/></span></p>
                                                <ImageUploader
                                                    disabled={challenge.status.name === "finished"}
                                                    image={challenge.challengePicture ? challenge.challengePicture : ""}
                                                    defaultImage={"image-challenge-banner"}
                                                    imagePreview={imagePreview}
                                                    setImagePreview={setImagePreview}
                                                    setFileToUpload={setImgToUpload}
                                                    rectangle={true}
                                                    noImageText="Vous n'avez pas encore d'image pour votre challenge"
                                                    noImageButtonText='Ajouter une image'
                                                    hasClickedOnCancelButton={hasClickedOnCancelButton}
                                                    setChange={setChange}
                                                    truc={truc2}
                                                    setTruc={setTruc2}
                                                    imgRef={createRef()}
                                                    id={"img-edit-challenge-general"}
                                                    handleDelete={handleDeleteImg}
                                                    ratio='1200/350'
                                                    // width={"100%"}
                                                    // height={"20vh"}
                                                />
                                            </div>

                                            <div>
                                                <p className="small-lead-text annonce-text">Début du Challenge * <span
                                                    className='icon-annonce'><AnnonceIcon/></span></p>
                                                <DateTimePicker
                                                    maxDateTime={(moment.isMoment(smallestDateStartDeposite) ? moment.max(smallestDateStartDeposite, moment(challenge.endDate).subtract(1, "minute")) : moment(challenge.endDate).subtract(1, "minute"))}
                                                    disabled={(challenge.registration || challenge.status.name === "finished")}
                                                    slotProps={{
                                                        textField: {
                                                            disabled: (challenge.registration || challenge.status.name === "finished"),
                                                            size: 'small',
                                                            fullWidth: true,
                                                            error: errorFieldGeneral.find((error: any) => error.code === "startDate") ? true : false,
                                                            helperText: errorFieldGeneral.find((error: any) => error.code === "startDate") ? errorFieldGeneral.find((error: any) => error.code === "startDate").message : "",
                                                        },
                                                    }}
                                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                                    value={moment(challenge.startDate)}
                                                    onChange={(date: Moment | null) => {
                                                        setChange(true)
                                                        updateChallenge("startDate", date)
                                                    }}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />

                                            </div>
                                            <div>
                                                <div className="annonce-text-div">
                                                    <p className="small-lead-text annonce-text">Fin du Challenge *<span
                                                        className='icon-annonce'><AnnonceIcon/></span></p>
                                                    <p className="text">(date d'annonce des résultats)</p>
                                                </div>
                                                <DateTimePicker
                                                    minDateTime={(moment(largestDateStartDeposite))}
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                            fullWidth: true,
                                                            error: errorFieldGeneral.find((error: any) => error.code === "endDate") ? true : false,
                                                            helperText: errorFieldGeneral.find((error: any) => error.code === "endDate") ? errorFieldGeneral.find((error: any) => error.code === "endDate").message : "",
                                                        },
                                                    }}
                                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                                    value={moment(challenge.endDate)}
                                                    onChange={(date: Moment | null,) => {
                                                        setChange(true)
                                                        updateChallenge("endDate", date)
                                                    }}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <p className="small-lead-text annonce-text">Description détaillée
                                                    * <span className='icon-annonce'><AnnonceIcon/></span></p>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={challenge.longDescription ? challenge.longDescription : ""}
                                                    disabled={challenge.status.name === "finished"}
                                                    onReady={editor => {
                                                        console.log('Editor is ready to use!', editor);
                                                        //console.log(Array.from( editor.ui.componentFactory.names()));
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        updateChallenge("longDescription", data);
                                                    }}
                                                    onBlur={(event, editor) => {
                                                        console.log('Blur.', editor);
                                                    }}
                                                    onFocus={(event, editor) => {
                                                        console.log('Focus.', editor);
                                                    }}
                                                    config={{
                                                        placeholder: "Écrire une description...",
                                                        toolbar: {
                                                            items: [
                                                                'undo', 'redo',
                                                                '|', 'heading',
                                                                '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                                                '|', 'link', 'blockQuote', 'codeBlock',
                                                                '|', 'alignment',
                                                                'fontColor',
                                                                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                                            ],
                                                            shouldNotGroupWhenFull: true
                                                        }
                                                    }}
                                                />
                                                {
                                                    /*

                                                <TextareaAutosize
                                                    disabled={challenge.status.name === "finished"}
                                                    id="longDescription"
                                                    placeholder='Écrire une description'
                                                    required
                                                    value={challenge.longDescription ? challenge.longDescription : ""}
                                                    onChange={(e: any) => updateChallenge("longDescription", e.target.value)}
                                                />
                                                     */
                                                }
                                            </div>

                                            <div className='radio-buttons'>
                                                <p className="small-lead-text annonce-text">Visibilité du Challenge
                                                    * <span
                                                        className='icon-annonce'><AnnonceIcon/></span></p>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={challenge.public ? "public" : "private"}
                                                    onChange={(e: any) => updateChallenge("public", e.target.value === "public" ? true : false)}
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel value="public" control={<Radio
                                                        disabled={challenge.status.name === "finished"}/>}
                                                                      label="Challenge public (visible par tous)"/>
                                                    <FormControlLabel value="private" control={<Radio
                                                        disabled={challenge.status.name === "finished"}/>}
                                                                      label="Challenge privé (accessible avec un lien)"/>
                                                </RadioGroup>
                                            </div>

                                        </Box>
                                    </FormControl>

                                </div>

                            </section>
                            {!isMdDown && rubriques}
                        </Grid>
                        <Grid item xs={12} md={6} paddingX={2}>
                            <div className={`${!isMdDown ? "sticky-desktop" : "sticky-mobile"}`}
                                 style={{top: `calc(50% + (${heightHeader}px - ${heightFooter}px)/2`}}>
                                <div className="all-switches">
                                    <div className="challenges-details-switch fixed">
                                        <div className="div-text">
                                            <h1 className='text-center'>Détaillez votre challenge</h1>
                                            <p className="text text-center">Choisissez les rubriques à ajouter à votre
                                                page Challenge :</p>
                                        </div>

                                        <div className='switch-boxes-list'>
                                            <div className='switch-boxes'>
                                                <div className='header'>
                                                    <SwitchButton
                                                        disabled={(challenge.registration || challenge.status.name === "finished")}
                                                        checked={challenge.hasDefis}
                                                        onChange={(e: any) => updateChallenge("hasDefis", e.target.checked)}
                                                        inputProps={{'aria-label': 'defis'}}
                                                        size={isMdDown ? 'small' : 'medium'}
                                                    />
                                                    <div className='text-section'>
                                                        <div className='header'>
                                                            <p className="small-lead-text">Rubrique Défis</p>
                                                            {challenge.hasDefis &&
                                                                <p className="text active">Activé</p>}
                                                        </div>
                                                        <p>Si votre thématique couvre un large périmètre, divisez votre
                                                            challenge en
                                                            sous-défis pour obtenir des projets pertinents et classés
                                                            par sujet clé. <br/>
                                                            <span className='italic'>Exemple : Hackathon Emploi <br/>
                                                            Défi 1 : Emploi des seniors <br/>
                                                            Défi 2 : Emploi des jeunes sans qualification <br/>
                                                            Défi 3 : Retour à l’emploi des chômeurs longue durée. </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='switch-boxes'>
                                                <div className='header'>
                                                    <SwitchButton
                                                        disabled={challenge.status.name === "finished"}
                                                        checked={challenge.hasPrizes}
                                                        onChange={(e: any) => updateChallenge("hasPrizes", e.target.checked)}
                                                        inputProps={{'aria-label': 'prix'}}
                                                        size={isMdDown ? 'small' : 'medium'}
                                                    />
                                                    <div className='text-section'>
                                                        <div className='header'>
                                                            <p className="small-lead-text">Rubrique Prix</p>
                                                            {challenge.hasPrizes &&
                                                                <p className="text active">Activé</p>}
                                                        </div>
                                                        <p>Attirez plus de participants en mettant en avant les prix en
                                                            jeu pour le ou les
                                                            gagnants de votre Challenge.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='switch-boxes'>
                                                <div className='header'>
                                                    <SwitchButton
                                                        disabled={challenge.status.name === "finished"}
                                                        checked={challenge.hasPartners}
                                                        onChange={(e: any) => updateChallenge("hasPartners", e.target.checked)}
                                                        inputProps={{'aria-label': 'partenaires'}}
                                                        size={isMdDown ? 'small' : 'medium'}
                                                    />
                                                    <div className='text-section'>
                                                        <div className='header'>
                                                            <p className="small-lead-text">Rubrique Partenaires</p>
                                                            {challenge.hasPartners &&
                                                                <p className="text active">Activé</p>}
                                                        </div>
                                                        <p>Mettez en valeur les partenaires de votre Challenge. <br/>
                                                            <span className='italic'>Vous cherchez des partenaires ? Rendez-vous  dans la section Annonces de Makee.</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='switch-boxes'>
                                                <div className='header'>
                                                    <SwitchButton
                                                        disabled={challenge.status.name === "finished"}
                                                        checked={challenge.hasFaq}
                                                        onChange={(e: any) => updateChallenge("hasFaq", e.target.checked)}
                                                        inputProps={{'aria-label': 'faq'}}
                                                        size={isMdDown ? 'small' : 'medium'}
                                                    />
                                                    <div className='text-section'>
                                                        <div className='header'>
                                                            <p className="small-lead-text">Rubrique Faq (foire aux
                                                                questions)</p>
                                                            {challenge.hasFaq && <p className="text active">Activé</p>}
                                                        </div>
                                                        <p>Répondez aux questions qui pourraient être le plus
                                                            fréquemment posées par vos
                                                            participants : inscriptions, jury, planning ...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isMdDown && rubriques}
                        </Grid>
                    </Grid>
                    <Snackbar
                        open={openSnackBarEditChallenge}
                        autoHideDuration={3000}
                        onClose={() => {
                            setOpenSnackBarEditChallenge(false)
                            setVariantSnackBarEditChallenge('success')
                            setTextSnackBarEditChallenge('')
                        }}
                    >
                        <Alert onClose={() => {
                            setOpenSnackBarEditChallenge(false)
                            setVariantSnackBarEditChallenge('success')
                            setTextSnackBarEditChallenge('')
                        }} severity={variantSnackEditChallenge}>{textSnackBarEditChallenge}</Alert>
                    </Snackbar>
                </div>
            )}
        </>
    )
}
export default AdminEditChallengeGeneral;
