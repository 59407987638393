// components
import {useEffect, useState} from 'react'
import ActionButton from '../../../../../../components/ActionButton'
import Ressource from '../../../../../../components/Ressource'

// react-routeur
import {useNavigate, useParams} from 'react-router-dom'

// assets
import {ReactComponent as AddIcon} from '../../../../../../assets/icon-add.svg'

// outlet context
import {useAdminEditChallengeContext} from '..'
import {useSelector} from 'react-redux'
import {Ressources, RootState} from '../../../../../../app/type'
import {TFuncReturn, use} from 'i18next'
import {log} from 'logrocket'
import {Alert, Button, Grid, Snackbar} from '@mui/material'
import {IResource} from 'src/utils/types'
import ResourceList from '../../../../../../components/ResourceList'

const AdminEditChallengeRessources = () => {
    const {
        page,
        setPage,
        click,
        setClick,
        change,
        setChange,
        resources,
        setResources,
        resourcesToDelete,
        setResourcesToDelete,
        errorResource,
        setErrorResource,
        openSnackBarResource, setOpenSnackBarResource,
        textSnackBarResource, setTextSnackBarResource,
        variantSnackBarResource, setVariantSnackBarResource,
    } = useAdminEditChallengeContext();
    const navigate = useNavigate();
    const {name} = useParams();
    // const getOneChallenge = useSelector((state: RootState) => state.challenges.getOneChallenge);

    const {fetchResources} = useAdminEditChallengeContext();
    useEffect(() => {
        setPage('ressources');
        fetchResources();
    }, []);

    return (
        <div className='admin-edit-challenge'>
            <section className="admin-edit-ressources">
                <div className="header">
                    <div className="header-name">
                        <h1 className="text-center">Ajouter des ressources</h1>
                        <p className="text  text-center">Ajoutez des ressources sous forme de documents ou de liens</p>
                    </div>
                </div>
                <ResourceList
                    errorResource={errorResource}
                    setErrorResource={setErrorResource}
                    resources={resources}
                    setResources={setResources}
                    resourcesToDelete={resourcesToDelete}
                    setResourcesToDelete={setResourcesToDelete}
                />
                <Snackbar
                    open={openSnackBarResource}
                    autoHideDuration={3000}
                    onClose={() => {
                        setOpenSnackBarResource(false)
                        setVariantSnackBarResource('success')
                        setTextSnackBarResource('')
                    }}
                >
                    <Alert onClose={() => {
                        setOpenSnackBarResource(false)
                        setVariantSnackBarResource('success')
                        setTextSnackBarResource('')
                    }} severity={variantSnackBarResource}>{textSnackBarResource}</Alert>
                </Snackbar>
            </section>
        </div>
    );
};


export default AdminEditChallengeRessources;