// style
import './index.css';

// utils
import { closeModal } from '../../utils/functions';
import { GetMyProfileAdmin, GetMyProfileParticipant } from '../../pages/MINIMAL/Global/Profile/ProfilePreview/ProfilePreviewInfos';

// icons
import { ReactComponent as IconCross } from '../../assets/icon-cross.svg';

// components
import { Box, FormControl, TextField } from '@mui/material';
import ActionButton from '../ActionButton';

// hoooks & context
import { useAuth } from '../../context/AuthContext';
import { Dispatch, useEffect, useRef } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';


// redux
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/type';
import api from "../../utils/api";


interface ChangeProfileEmailModalProps {
    id: string;
    email: string;
    type: "pro" | "recup";
}

const updateEmail = async (e: any, navigate: NavigateFunction, id: string, dispatch: Dispatch<AnyAction>, email_type: string) => {
    console.log('update email');
    e.preventDefault();

    const newmail = e.target.newmail.value;

    let request = '';
    let body = {};

    if(email_type.localeCompare('pro') === 0) {
         request = '/auth/change_email';
        body = {
            language: localStorage.getItem("translation"),
            newemail: newmail,
        }
    } else {
        request = '/auth/change_emailreset';
        body = {
            language: localStorage.getItem("translation"),
            newemailreset: newmail,
        }
    }

    await api.put(
        request,
        body,
        {
            withCredentials: true
        })
        .then((response) => {
            localStorage.setItem("emailprofile", e.target.newmail.value);
            // navigate('/admin/profile/update/email')
            if(email_type.localeCompare('pro') === 0){
                dispatch({
                    type: "SET_USER",
                    payload: {
                        email_pro: newmail,
                    }
                });
            } else {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        email_recup: newmail,
                    }
                });
            }
            closeModal(id);
        })
        .catch((error) => {
            console.log(error);
        }
    )
}

const ChangeProfileEmailModal = ({id, email, type}: ChangeProfileEmailModalProps) => {
    const { errorMessage } = useAuth();

    const location = useLocation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const submitButton = useRef<HTMLInputElement>(null);

    const handleOnSubmitButtonClicked = () => {
        submitButton.current?.click();
    }

    const establishmentName = useSelector((state: RootState) => state.user.establishment);

    const isMobile = window.innerWidth <= 768;
    const {profile} = useAuth();
    useEffect(() => {
        console.log('ici')

        profile === 'admin' ? GetMyProfileAdmin(establishmentName, dispatch) :
            console.log('erreur')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        profile === 'participant' ? GetMyProfileParticipant(dispatch) :
            console.log('erreur')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    return(
        <dialog id={id} className='modal-update-email-profile'>
            <div className="modal-update-email-profile-header">
                <h1 className='h1'>Modifier l'adresse e-mail</h1>
                {!isMobile && <IconCross className='close-modal-cross' onClick={() => closeModal(id)}/>}
                </div>
                <FormControl onSubmit={(e) => {updateEmail(e, navigate, id, dispatch, type)}} className='form-update-email-profile'>
                    <Box component="form" noValidate autoComplete='off' className='form-content-update-email-profile'>
                        <p className="text">Entrez votre adresse e-mail utilisée pour votre compte Makee et suivez les instructions pour réinitialiser votre adresse e-mail.</p>
                        <TextField
                            {
                            ...(
                                errorMessage && {
                                    error: true,
                                    helperText: errorMessage
                                })
                            }
                            id='mail'
                            label='Adresse e-mail actuelle'
                            variant='outlined'
                            placeholder='Adresse e-mail actuelle'
                            type='text'
                            className='form-text-auth input-text-field'
                            defaultValue={email}
                            key={email}
                            disabled
                        />
                        <TextField
                            {
                            ...(
                                errorMessage && {
                                    error: true,
                                    helperText: errorMessage
                                })
                            }
                            id='newmail'
                            label='Votre nouvelle adresse e-mail'
                            variant='outlined'
                            placeholder='Votre adresse e-mail'
                            type='text'
                            className='form-text-auth input-text-field'
                            key={email + "-confirm"}
                        />
                        <input type="submit" style={{display: 'none'}} ref={submitButton}/>
                    </Box>
                </FormControl>
                <div className="button-update-email-profile">
                    {isMobile && <ActionButton type='button' variant='tertiary' className='close-modal-button' onClick={() => closeModal(id)}>Annuler</ActionButton>}
                    <ActionButton type='submit' variant='primary' className='update-email-profile-confirm-button' onClick={handleOnSubmitButtonClicked}>
                        Modifier
                    </ActionButton>
                </div>
        </dialog>
    )
}

export default ChangeProfileEmailModal;
