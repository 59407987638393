import ChallengePlanning from "../../../../../../modules/Planning";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";

const AdminChallengePlanning = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    return (
        <section className="admin-challenge-planning">
            <div className="planning-header">
                <h2>Planning</h2>
                <p className="text">{t("page.challenge.planning.planningAdminInfo")}</p>
            </div>

            <ChallengePlanning />
        </section>
    )
}

export default AdminChallengePlanning;