// hooks
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import React, { useEffect, useState } from 'react';

// icons
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { ReactComponent as IconDragNDrop } from '../../assets/icon-dragndrop.svg';

// utilities
import { InputAdornment, TextField } from '@mui/material';
import { IStepQuestion } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeSteps/types';
import { v4 as uuidv4 } from 'uuid';


interface QuestionProps {
    question: IStepQuestion;
    stepId: number | string;
    updateQuestion: (stepId: string, updatedQuestion: IStepQuestion) => void;
    removeQuestionFromStep: (stepId: string, questionId: string) => void;
    disabled?: boolean;
}

const QuestionStep = ({ question, stepId, updateQuestion, removeQuestionFromStep, disabled}: QuestionProps) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: String(question.id) || uuidv4() });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return (
        <div ref={setNodeRef} {...attributes} style={style} {...listeners}>
            <TextField
                disabled={disabled}
                label="Question"
                variant="outlined"
                fullWidth={true}
                defaultValue={question.questionText}
                placeholder="Quel est ...?"
                multiline
                inputProps={{ maxLength: 250 }}
                maxRows={3}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconClose 
                            className='icon-close icon-clickable cursor-pointer' 
                            onClick={disabled?() => {} :() => removeQuestionFromStep(String(stepId), String(question.id))}
                        />
                        
                        <IconDragNDrop className={`icon-dragndrop icon-clickable ${!disabled &&'draggable'} cursor-pointer`}/>
                    </InputAdornment>,
                }}
                InputLabelProps={{
                    style: { 
                        color: '#a9a9a9',
                        fontWeight: '400'
                    },
                }}
                sx={{
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        background: "#fff",
                        borderRadius: '10px',
                        "&.Mui-focused": {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:'rgba(0, 0, 0, 0.23)',
                                border: 'solid 1px'
                            }
                        }   
                    }
                }}
                onChange={(e) => updateQuestion(String(stepId), { ...question, questionText: e.target.value })}
            />
        </div>
    )
}

export default QuestionStep;

