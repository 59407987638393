// hooks
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

// Components
import ActionButton from "../../../../../components/ActionButton";
import GoBack from "../../../../../components/GoBack";

// icons
import MakeeDiamondLogin from "../../../../../assets/makee-diamond-login";
import {ReactComponent as Diamond} from "../../../../../assets/diamond-eyes-up.svg";

// context
import {useAuth} from "../../../../../context/AuthContext";

// style
import "./index.css";
import {Alert, Snackbar} from "@mui/material";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import SupportFooter from "../../../../../modules/SupportFooter";

const SignupParticipantEmail = () => {

    const {resendEmail} = useAuth();

    const [email, setEmail] = useState(' ');

    //Snackbar
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [textSnackBar, setTextSnackBar] = useState<string>("");
    const [variantSnackBar, setVariantSnackBar] = useState<"error" | "success">("success");

    useEffect(() => {
        if (email === ' ') {
            const mail = localStorage.getItem('emailSignupParticipant')?.replace(/.{5}(?=@)/g, 'xxxxx') || ' ';
            setEmail(mail);
        } else {
            localStorage.removeItem('emailSignupParticipant');
        }
    }, [email]);

    const handleResendEmail = () => {
        resendEmail(email);
        setOpenSnackBar(true);
        setTextSnackBar(`${t("page.signUpParticipantEmail.snackBarText")}`);
    }

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    return (
        <>
            <div className='signup-participant-email'>
                <section className='signup-participant-email-section-container'>
                    <MakeeDiamondLogin className='signup-participant-email-makee-diamond-login'/>
                    <div className="signup-participant-email-main">
                        <div className="main-header">
                            <h1 className="h1-auth">{t("page.signUpParticipantEmail.title")}</h1>
                            <p className="text">{t("page.signUpParticipantEmail.textEmailReceived")}</p>
                        </div>
                        <h1>{email}</h1>
                        <div className="main-footer">
                            <p className="text italic">{t("page.signUpParticipantEmail.textSpam")}</p>
                            <ActionButton
                                type='button'
                                variant='tertiary'
                                className='button-text-auth'
                                onClick={() => handleResendEmail()}
                            >
                                {t("page.signUpParticipantEmail.buttonText")}
                            </ActionButton>
                            <a href="mailto:contact@challenkers.com"
                               className='text link'>{t("page.signUpParticipantEmail.notAcessEmail")}</a>
                        </div>
                    </div>
                    {window.innerWidth > 768 && <Diamond className='signup-participant-email-diamond'/>}
                    <Snackbar
                        open={openSnackBar}
                        autoHideDuration={3000}
                        onClose={() => {
                            setOpenSnackBar(false)
                            setVariantSnackBar('success')
                            setTextSnackBar('')
                        }}
                    >
                        <Alert onClose={() => {
                            setOpenSnackBar(false)
                            setVariantSnackBar('success')
                            setTextSnackBar('')
                        }} severity={variantSnackBar}>{textSnackBar}</Alert>
                    </Snackbar>
                </section>
            </div>
            <SupportFooter/>
        </>
    )
}

export default SignupParticipantEmail;