// assets
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icon-calendar.svg';
import { ReactComponent as EyeIcon } from '../../../../../assets/icon-eye.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/type';
import moment from 'moment';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    createTheme,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@mui/material';

import './index.css';
import { useEffect, useState } from 'react';
import { DEFAULT_ESTABLISHMENT_IMAGE } from '../../../../../utils/consts';
import { IChallenge, IPartners } from "../../../Global/Challenge/types";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";

const ChallengeGeneral = () => {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.up('sm'));
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const isbetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const ismorelg = useMediaQuery(theme.breakpoints.up('lg'));
    // const challenge .challenge= useSelector((state: RootState) => state.challenges.challenge).challenge
    // console.log("challenge".challenge, challenge).challenge;
    useEffect(() => {
        // console.log("isSmDown", isSmDown);
    }, [isSmDown])
    const pasDeReglement = true;
    const [challenge, setChallenge] = useState<IChallenge | null>(null);
    const challengeId = useParams().id;
    const [eligibilities, setEligibilities] = useState<any[]>([]);
    const getChallengeById = () => {
        api.get('/challenge/preview', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                setChallenge(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        getChallengeById();
    }, []);

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    // moment.locale(i18n.language);

    const getEligibility = async () => {
        await api.get('/challenge/eligibilities', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                setEligibilities(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };
    useEffect(() => {
        getEligibility();
    }, []);
    return (
        <section className="admin-preview-challenge-general">
            <div
                className={`header-infos-challenges ${isDownMd ? "px-2em" : ""} ${isbetweenMdAndLg ? "px-10em" : ""} ${ismorelg ? "px-12em" : ""}`}>
                <div className="infos-generales">
                    <h2 className="h1">{t("page.challenge.general.theChallenge")}</h2>
                    <div className="long-description text mb-2em"
                        dangerouslySetInnerHTML={{ __html: challenge?.challenge.longDescription ? challenge?.challenge.longDescription : 'Pas de description détaillée pour le moment' }} />
                </div>
                <div>
                    {
                        ((challenge?.challenge.status.name !== "finished") && challenge?.challenge.registrationStartDate && challenge?.challenge.registrationEndDate) &&
                        <div className="registrations-dates">
                            <div>
                                <CalendarIcon />
                                <h2 className="h2">{t("page.challenge.general.registrations")}</h2>
                                <p className="text">{t("challengeCard.from")} {moment(challenge?.challenge.registrationStartDate).format('LL')} {t("challengeCard.to")} {moment(challenge?.challenge.registrationEndDate).format('LL')}</p>
                            </div>
                        </div>
                    }
                    <div className="block-eligibilities">
                        <h3 className="title-eligibilities">{t("page.challenge.general.titleCriteria")}</h3>
                        {
                            eligibilities.length > 0 ? (
                                <ul>
                                    {
                                        eligibilities.map((eligibility, index) => (
                                            <li key={index} className="eligibility">{eligibility.label}</li>
                                        ))
                                    }
                                </ul>
                            ) : (
                                <p className="no-eligibilities">{t("page.challenge.general.noCriteria")}</p>
                            )
                        }
                    </div>
                    {
                        challenge?.challenge.file && challenge?.challenge.file.fileUrl &&
                        <div className="rules">
                            <h2 className="h2">{t("page.challenge.general.challengeRules")}</h2>
                            <a className="rules-name" href={challenge?.challenge.file.fileUrl} target='_blank'
                                rel='noreferrer'>
                                <p className="text">{challenge?.challenge.file.originalFileName}</p>
                                <EyeIcon />
                            </a>
                        </div>
                    }
                </div>
            </div>
            {
                challenge?.challenge.hasDefis &&
                <div className="all-defis list">
                    <h2 className="h1">{challenge?.challenge.subChallenges.length} {t("page.challenge.general.subchallenges")}</h2>
                    {/* <div className="defi-list"> */}
                    <Grid container spacing={1} justifyContent={"center"}>
                        {
                            challenge?.challenge.subChallenges.map((defi, index) => (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index} className='h-auto'>
                                    <div className="defis" key={index}>
                                        <div className="img-defis">
                                            <p className="h1 salmon">{index + 1}</p>
                                        </div>
                                        <h3 className="h2">{defi.name}</h3>
                                        <p className="text">
                                            {defi.description}
                                        </p>
                                    </div>
                                </Grid>
                                // <div className="defis" key={index}>
                                //     <div className="img-defis">
                                //         <p className="h1">{index + 1}</p>
                                //     </div>
                                //     <h3 className="h2">{defi.name}</h3>
                                //     <p className="text">
                                //         {defi.description}
                                //     </p>
                                // </div>
                            ))
                        }
                    </Grid>

                    {/* </div> */}
                </div>
            }

            {
                challenge?.challenge.hasPrizes &&
                <div className={`all-prizes list ${isbetweenMdAndLg || ismorelg ? "w-65" : "w-100"}`}>
                    <h2 className="h1">{t("page.challenge.general.prizesToBeWon")}</h2>
                    {
                        challenge?.challenge.prizes.map((price, index) => (

                            <Card className={`h-100 ${isDownMd ? "d-block" : "d-flex"}`}
                                sx={
                                    {
                                        width: "100%",
                                        borderRadius: "12px",
                                        margin: "auto",
                                        backgroundColor: "#EDF1F7",
                                        boxShadow: "none",

                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        [theme.breakpoints.down('sm')]: {

                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        },
                                    }
                                }
                                key={index}
                            >
                                <CardMedia
                                    loading="lazy"
                                    component="img"
                                    image={price.prizePicture}
                                    alt={`price ${index}`}
                                    sx={{
                                        maxWidth: '15rem',
                                        aspectRatio: '1/1',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: 'none',
                                            aspectRatio: 'unset',
                                        }
                                    }}

                                />
                                <CardContent className='h-100'>
                                    <Typography variant="h6" fontWeight={"700"} className="h2" component="div"
                                        alignContent={"center"}>
                                        <p className="h2"><p className="h2">{price.name}</p></p>
                                    </Typography>
                                    {price.description &&
                                        <Typography variant="subtitle2" color="text.secondary" component="div">
                                            <p>{price.description}</p></Typography>}

                                </CardContent>
                            </Card>
                            // <div className="prizes">
                            //     <div className="top">
                            //         <div className="img-prizes" style={{ background: `url(${price.image}) center center/cover` }}></div>
                            //         <div className="prizes-infos">
                            //             <h3 className="h2">{price.name}</h3>
                            //         </div>
                            //     </div>
                            //     {price.description && <p className="text">{price.description}</p>}
                            // </div>

                        ))
                    }

                </div>
            }

            <div className="organizer-focus list">
                <h2 className="h1">{t("page.challenge.general.theOrganizer")}</h2>
                <div className={"block-img-organizer"}>
                    <img className="img-organizer"
                        src={`${challenge?.challenge.establishments[0].establishmentPicture}`} alt='img-organizer' />
                    <p className="organizer-name">{challenge?.challenge.establishments[0].name}</p>
                </div>
            </div>

            {
                challenge?.challenge.hasPartners &&
                <div className="all-partenaires list">
                    <h2 className="h1">{t("page.challenge.general.partners")}</h2>
                    <div className="partenaires-list">
                        <Grid container spacing={4} justifyContent={"center"}>
                            {
                                challenge?.challenge.partners.map((partner: IPartners, index) => (
                                    <>
                                        {(partner.name || partner.partnerLink || partner.partnerLogo !== DEFAULT_ESTABLISHMENT_IMAGE) && (

                                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} justifyContent={"center"}
                                                key={index} className='h-auto'>
                                                <Card className='d-block h-100'
                                                    sx={
                                                        {
                                                            // display: "flex",
                                                            borderRadius: "12px",
                                                            width: "auto",
                                                            maxWidth: "300px",
                                                            margin: "auto",
                                                            backgroundColor: "#EDF1F7",
                                                            boxShadow: "none"

                                                        }
                                                    }
                                                    key={index}
                                                >
                                                    {partner.partnerLink ? (
                                                        <CardActionArea onClick={() => console.log("coucou tua")}>
                                                            <CardMedia
                                                                loading="lazy"
                                                                component="img"
                                                                image={partner.partnerLogo}
                                                                alt={`partner ${index}`}
                                                                className='square-img'

                                                            />
                                                        </CardActionArea>
                                                    ) : (
                                                        // Si partner.link n'est pas défini, affichez le contenu sans CardActionArea
                                                        <div>
                                                            <CardMedia
                                                                loading="lazy"
                                                                component="img"
                                                                image={partner.partnerLogo}
                                                                alt={`partner ${index}`}
                                                                className='square-img'
                                                            />
                                                        </div>
                                                    )}
                                                    {(partner.name || partner.partnerLink) && (
                                                        <CardActions id="actioncard" className='p-4'>
                                                            <div>
                                                                {partner.name && (
                                                                    <p className="h2">{partner.name}</p>
                                                                )}
                                                                {partner.partnerLink && <a className='a' target='blank'
                                                                    href={partner.partnerLink}>{t("page.challenge.general.learnMore")}
                                                                    <span>{'>'}</span></a>}
                                                            </div>
                                                        </CardActions>
                                                    )}

                                                </Card>
                                            </Grid>
                                        )}
                                    </>
                                ))
                            }
                        </Grid>
                    </div>
                </div>
            }


        </section>
    );
}

export default ChallengeGeneral;