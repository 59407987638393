import moment from "moment";
import { ICriterium, IStepPlanning } from "./types";
import { v4 as uuidv4 } from 'uuid';

/**
 * Ouvre le modal dont l'id est passé en paramètre.
 * 
 * ---
 * 
 * @param {string} id L'id du modal à ouvrir.
 */
export const openModal = (id: string) => {
    // on doit mettre en any car typescript ne reconnait pas showModal et close (trop récent)
    const modal: any = document.getElementById(id);
    modal?.showModal();
}
  
/**
 * Ferme le modal dont l'id est passé en paramètre.
 * 
 * ---
 * 
 * @param {string} id L'id du modal à fermer.
*/
export const closeModal = (id: string) => {
    // on doit mettre en any car typescript ne reconnait pas showModal et close (trop récent)
    const modal: any = document.getElementById(id);
    modal?.close();
}

/**
 * Renvoie un s ou non en fonction du nombre passé en paramètre.
 * 
 * @param nb - Le nombre à tester.
 * @returns Un s si le nombre est supérieur à 1, rien sinon.
 */
export const isPlural = (nb: number) => {
    return nb > 1 ? "s" : "";
}

/**
 * Retourne la date passée en paramètre au format passé en paramètre, adapté au fuseau horaire de l'utilisateur.
 * 
 * @param date - La date à localiser.
 * @param format  - Le format de la date à retourner.
 * @returns La date localisée.
 */
export const localiseDate = (date: string, format="YYYY-MM-DDTHH:mm:ss" as string) => {
    const userTimezoneOffset = moment().utcOffset();
    return moment(date).utcOffset(userTimezoneOffset).format(format);
}

/**
 * Génère une chaine de caractères aléatoire de longueur n
 * 
 * @param length - Longueur de la chaîne en caractères
 * @returns La chaîne de caractères aléatoire
 */
export const generateRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz ';
    const charactersLength = characters.length;

    for(let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

/**
 * Renvoie un nombre aléatoire compris entre deux valeurs passées en paramètre.
 * 
 * @param min - Le nombre minimum.
 * @param max - Le nombre maximum. Si vide, renvoie un nombre entre `min` et `Infinity`.
 * @returns Un nombre aléatoire compris entre min et max.
 */
export const generateRandomNumber = (min: number, max = Infinity as number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const generateRandomStepPlanning = (nbSteps: number) => {
    const steps: IStepPlanning[] = [];
    let randomStartDate = moment().add(generateRandomNumber(1, 10), "days").format();
    let randomEndDate = moment().add(generateRandomNumber(11, 20), "days").format();
    let randomEndSelectionDate = moment().add(generateRandomNumber(21, 30), "days").format();
    let isUnfinished = false;
    for(let i = 0; i < nbSteps; i++) {

        // Generate random hours and minutes for each step
        // avoiding overlap between steps
        let randomHours = generateRandomNumber(0, 21);
        let randomEndHours = generateRandomNumber(randomHours, 22);
        let randomEndSelectionHours = generateRandomNumber(randomEndHours, 23);

        let randomMinutes = generateRandomNumber(0, 59);

        let isStartDepositeFinished = false;
        let isEndDepositeFinished = false;
        let isSelectionFinished = false;

        if(!isUnfinished){
            isStartDepositeFinished = generateRandomNumber(0, 1) === 1;
            isEndDepositeFinished = generateRandomNumber(0, 1) === 1;
            isSelectionFinished = isEndDepositeFinished && generateRandomNumber(0, 1) === 1;
        } else {
            isStartDepositeFinished = false;
            isEndDepositeFinished = false;
            isSelectionFinished = false;
        }
        steps.push({
            name: generateRandomString(generateRandomNumber(5, 20)),
            dateStartDeposite: localiseDate(moment(randomStartDate).format()),
            dateEndDeposite: localiseDate(moment(randomEndDate).format()),
            hasSelection: generateRandomNumber(0, 1) === 1,
            dateEndSelection: localiseDate(moment(randomEndSelectionDate).format()),
            isStartDepositeFinished: isStartDepositeFinished,
            isEndDepositeFinished: isEndDepositeFinished,
            isSelectionFinished: isSelectionFinished
        })

        if(!isEndDepositeFinished || !isSelectionFinished || !isStartDepositeFinished) {
            isUnfinished = true;
        }

        // increment dates
        randomStartDate = moment(randomStartDate).add(generateRandomNumber(1, 10), "days").add(randomHours, "hours").add(randomMinutes, "minutes").format();
        randomEndDate = moment(randomEndDate).add(generateRandomNumber(1, 10), "days").add(randomEndHours, "hours").add(randomMinutes, "minutes").format();
        randomEndSelectionDate = moment(randomEndSelectionDate).add(generateRandomNumber(1, 10), "days").add(randomEndSelectionHours, "hours").add(randomMinutes, "minutes").format();
    }
    return steps;
}

/**
 * Génère un planning aléatoire.
 * 
 * @returns Un planning aléatoire.
 */
export const generateRandomPlanning = () => {
    return {
        startDate: localiseDate(moment().add(generateRandomNumber(1, 10), "days").format()),
        registrationsStartDate: localiseDate(moment().add(generateRandomNumber(1, 10), "days").format()),
        registrationsEndDate: localiseDate(moment().add(generateRandomNumber(11, 20), "days").format()),
        endDate: localiseDate(moment().add(generateRandomNumber(21, 30), "days").format()),
        steps: generateRandomStepPlanning(generateRandomNumber(1, 10))
    }
}

/**
 * Génère des critères aléatoires.
 * 
 * @param nbCriterium - Le nombre de critères à générer.
 * @returns Un tableau de critères aléatoires.
 */
export const generateRandomCriteria = (nbCriterium: number) => {
    const criteria: ICriterium[] = [];
    for(let i = 0; i < nbCriterium; i++) {
        var id = uuidv4();
        criteria.push({
            id: id,
            challengeId: 3,
            name: generateRandomString(generateRandomNumber(5, 20)),
            description: generateRandomString(generateRandomNumber(5, 200)),
            valuePoints: generateRandomNumber(1, 10)
        })
    }
    return criteria;
}

export const isEmail = (email: string) => {
    const emailRegex = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/, "gm");

    return emailRegex.test(email);
}