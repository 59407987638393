import React from "react";

import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import "./index.css";
import {IListTeams} from "./types";
import {Avatar, Chip, FormControl, InputAdornment, TextField, Tooltip} from "@mui/material";
import {RootState} from "../../../../app/type";
import {useSelector} from "react-redux";
import ActionButton from "../../../../components/ActionButton";
import {ReactComponent as IconAdd} from "../../../../assets/icon-add.svg";
import Modal from "../../../Modal";
import {ReactComponent as IconEdit} from "../../../../assets/icon-edit.svg";
import {ReactComponent as IconDelete} from "../../../../assets/icon-trash.svg";
import api from "../../../../utils/api";

const ListChallengeTeams = () => {
    const {id} = useParams();

    const [allTeams, setAllTeams] = useState<IListTeams>();
    const [challenge, setChallenge] = useState<any>();
    const [allResources, setAllResources] = useState<any>();
    const [modalShareResource, setModalShareResource] = useState<boolean>(false);
    const [modalDeleteShareResource, setModalDeleteShareResource] = useState<boolean>(false);
    const [modalEditShareResource, setModalEditShareResource] = useState<boolean>(false);
    const [resourceName, setResourceName] = useState<string>();
    const [resourceLink, setResourceLink] = useState<string>();
    const [resourceDescription, setResourceDescription] = useState<string>();
    const [resourceTeamId, setResourceTeamId] = useState<any>();
    const [resourceId, setResourceId] = useState<any>();
    const getAllTeams = () => {
        api.get(`/admin/challenge/teams`,
            {
                params: {
                    challengeId: id
                },
                withCredentials: true
            }
        ).then((res) => {
            setAllTeams(res.data.allTeams);
            setChallenge(res.data.challenge);
            setAllResources(res.data.allResources);
        }).catch((error) => {
            console.log("error :", error);
        });
    }
    const addResourceTeam = async () => {
        await api.post(`/admin/challenge/teams/resource`,
            {
                name: resourceName,
                link: resourceLink,
                teamId: resourceTeamId,
                description: resourceDescription
            },
            {
                withCredentials: true
            }
        ).then((res) => {

        }).catch((error) => {
            console.log("error :", error);
        });
    }
    const deleteResourceTeam = async () => {
        await api.delete(`/admin/challenge/teams/delete-resource`,
            {
                data: {
                    resourceId: resourceId
                },
                withCredentials: true
            }
        ).then((res) => {
            getAllTeams();
        }).catch((error) => {
            console.log("error :", error);
        });
    }
    const editResourceTeam = async () => {
        await api.put(`/admin/challenge/teams/edit-resource`,
            {
                resourceId: resourceId,
                newName: resourceName,
                newLink: resourceLink,
                newDescription: resourceDescription
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            getAllTeams();
        }).catch((error) => {
            console.log("error :", error);
        });
    }
    const getOneChallenge = useSelector((state: RootState) => state.challenges.getOneChallenge);

    useEffect(() => {
        getAllTeams();
    }, []);
    return (
        <section className={`admin-challenge-suivi-teams`}>
            <div className={`container-content-suivi-teams`}>
                <div>
                    {
                        allTeams?.validTeams && allTeams.validTeams.length >= 1 ? (
                            <>
                                <h2>{allTeams.validTeams.length} {allTeams.validTeams.length === 1 ? 'équipe valide' : 'équipes valides'}</h2>
                                <p>Equipes :
                                    de {challenge?.minMembersTeam} à {challenge?.maxMembersTeam} membres</p>
                            </>
                        ) : (
                            <h2>Aucune équipe valide</h2>
                        )
                    }
                </div>
                <div className={`container-list-teams`}>
                    {
                        allTeams?.validTeams.map((team, index) => (
                            <div className={`container-team-content`} key={index}>
                                <h3>Equipe {team.numero} - {team.name}</h3>
                                <p>{team.membersCount} {team.membersCount === 1 ? 'membre' : 'membres'}</p>
                                <div className={`container-team-content-teams`}>
                                    {
                                        team.profiles.map((profile, index) => (
                                            <Chip
                                                key={index}
                                                label={`${profile.user.firstname} ${profile.user.lastname}`}
                                                avatar={<Avatar src={profile.user.profile_picture}
                                                                sx={{width: "30px"}}/>}
                                                sx={{
                                                    backgroundColor: "white",
                                                    padding: "0px",
                                                    color: "var(--primary90)",
                                                    fontFamily: "var(--font-secondary)",
                                                    fontWeight: "400",
                                                    fontSize: "17px",
                                                    width: "min-content",
                                                    height: "32px",
                                                    ".MuiChip-avatar": {
                                                        margin: "0",
                                                        height: "100%",
                                                        width: "32px",
                                                    }
                                                }}
                                                className={"chip-user"}
                                            />
                                        ))
                                    }
                                    {
                                        allResources.map((resource: any, index: number) =>
                                            resource.teamId === team.id &&
                                            resource.resources &&
                                            resource.resources.map((item: any) => (
                                                <TextField
                                                    sx={{
                                                        backgroundColor: '#EDF1F7',
                                                        borderRadius: '10px',
                                                        boxShadow: 'none',
                                                        border: 'none',
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        marginTop: ".3rem",
                                                        width: "100%",
                                                        "& .MuiFormLabel-root": {
                                                            color: "black",
                                                            marginTop: "1rem"
                                                        }
                                                    }
                                                }
                                                    label={`${item.name}${(item.description) ? ` - ${item.description}` : ''}`}
                                                    id="filled-hidden-label-small"
                                                    defaultValue={`${item.link}`}
                                                    value={`${item.link}`}
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: {
                                                            backgroundColor: "white",
                                                            borderRadius: '10px',
                                                            boxShadow: 'none',
                                                            border: 'none',
                                                            paddingTop: "1rem"
                                                        },
                                                        endAdornment:
                                                            <InputAdornment
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                position="start"
                                                            >
                                                                <span
                                                                    className={"icon-resource"}
                                                                    onClick={async () => {
                                                                        await setResourceId(item.id);
                                                                        await setResourceName(item.name);
                                                                        await setResourceDescription(item.description);
                                                                        await setResourceLink(item.link);
                                                                        setModalEditShareResource(true);
                                                                    }}
                                                                >
                                                                    <IconEdit/>
                                                                </span>
                                                                <span className={"icon-resource"}
                                                                      onClick={async () => {
                                                                          await setResourceId(item.id);
                                                                          setModalDeleteShareResource(true);
                                                                      }}
                                                                >
                                                                    <IconDelete/>
                                                                </span>
                                                            </InputAdornment>,
                                                    }}
                                                />
                                            ))
                                        )
                                    }
                                    <ActionButton
                                        variant={"secondary"}
                                        iconTextStart
                                        icon={<IconAdd/>}
                                        className={"button-share-resource"}
                                        onClick={() => {
                                            setResourceTeamId(team.id);
                                            setModalShareResource(true);
                                        }}
                                    >
                                        Partager un outil
                                    </ActionButton>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {/*<hr className={`block-hr`}/>*/}
                <div className={"mt-5"}>
                    {
                        allTeams?.invalidTeams && allTeams.invalidTeams.length >= 1 ? (
                            <h2>{allTeams.invalidTeams.length} {allTeams.invalidTeams.length === 1 ? 'équipe incomplète' : 'équipes incomplètes'}</h2>
                        ) : (
                            <h2>Aucune équipe incomplète</h2>
                        )
                    }
                </div>
                <div className={`container-list-teams`}>
                    {
                        allTeams?.invalidTeams.map((team, index) => (
                            <div className={`container-team-content`} key={index}>
                                <h3>Equipe {team.numero} - {team.name}</h3>
                                <p>{team.membersCount} {team.membersCount === 1 ? 'membre' : 'membres'}</p>
                                <div className={`container-team-content-teams`}>
                                    {
                                        team.profiles.map((profile, index) => (
                                            <Chip
                                                key={index}
                                                label={`${profile.user.firstname} ${profile.user.lastname}`}
                                                avatar={<Avatar src={profile.user.profile_picture}/>}
                                                sx={{
                                                    backgroundColor: "white",
                                                    padding: "0px",
                                                    color: "var(--primary90)",
                                                    fontFamily: "var(--font-secondary)",
                                                    fontWeight: "400",
                                                    fontSize: "17px",
                                                    width: "min-content",
                                                    height: "32px",
                                                    ".MuiChip-avatar": {
                                                        margin: "0",
                                                        height: "100%",
                                                        width: "32px",
                                                    }
                                                }}
                                                className={"chip-user"}
                                            />
                                        ))
                                    }
                                </div>
                                {
                                    allResources.map((resource: any, index: number) =>
                                        resource.teamId === team.id &&
                                        resource.resources &&
                                        resource.resources.map((item: any) => (
                                            <TextField
                                                sx={{
                                                    backgroundColor: '#EDF1F7',
                                                    borderRadius: '10px',
                                                    boxShadow: 'none',
                                                    border: 'none',
                                                    '& fieldset': {
                                                        border: 'none',
                                                    },
                                                    marginTop: ".3rem",
                                                    width: "100%",
                                                    "& .MuiFormLabel-root": {
                                                        color: "black",
                                                        marginTop: "1rem"
                                                    }
                                                }
                                                }
                                                label={`${item.name}${(item.description) ? ` - ${item.description}` : ''}`}
                                                id="filled-hidden-label-small"
                                                defaultValue={`${item.link}`}
                                                value={`${item.link}`}
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                    sx: {
                                                        backgroundColor: "white",
                                                        borderRadius: '10px',
                                                        boxShadow: 'none',
                                                        border: 'none',
                                                        paddingTop: "1rem"
                                                    },
                                                    endAdornment:
                                                        <InputAdornment
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                            position="start"
                                                        >
                                                                <span
                                                                    className={"icon-resource"}
                                                                    onClick={async () => {
                                                                        await setResourceId(item.id);
                                                                        await setResourceName(item.name);
                                                                        await setResourceDescription(item.description);
                                                                        await setResourceLink(item.link);
                                                                        setModalEditShareResource(true);
                                                                    }}
                                                                >
                                                                    <IconEdit/>
                                                                </span>
                                                            <span className={"icon-resource"}
                                                                  onClick={async () => {
                                                                      await setResourceId(item.id);
                                                                      setModalDeleteShareResource(true);
                                                                  }}
                                                            >
                                                                    <IconDelete/>
                                                                </span>
                                                        </InputAdornment>,
                                                }}
                                            />
                                        ))
                                    )
                                }
                                <ActionButton
                                    variant={"secondary"}
                                    iconTextStart
                                    icon={<IconAdd/>}
                                    className={"button-share-resource"}
                                    onClick={() => {
                                        setResourceTeamId(team.id);
                                        setModalShareResource(true);
                                    }}
                                >
                                    Partager un outil
                                </ActionButton>
                            </div>
                        ))
                    }

                </div>
            </div>
            <Modal
                open={modalShareResource}
                onClose={async () => {
                    await setModalShareResource(false);
                    setResourceName("");
                    setResourceLink("");
                    setResourceTeamId("");
                    setResourceDescription("");
                }}
                title={"Partager un outil"}
                primaryButtonText={"Valider"}
                primaryButtonAction={async () => {
                    await addResourceTeam();
                    await getAllTeams();
                    await setModalShareResource(false);
                    setResourceName("");
                    setResourceLink("");
                    setResourceTeamId("");
                    setResourceDescription("");
                }}
                tertiaryButtonText={"Annuler"}
                tertiaryButtonAction={async () => {
                    await setModalShareResource(false);
                    setResourceName("");
                    setResourceLink("");
                    setResourceTeamId("");
                    setResourceDescription("");
                }}
            >
                <div className={"modal-share-resource-block-form"}>
                    <div className={"modal-share-resource-field"}>
                        <label htmlFor={"name"}>Nom de l'outil</label>
                        <TextField
                            variant="outlined"
                            placeholder="Nom de l'outil"
                            type="text"
                            id={"name"}
                            value={resourceName}
                            onChange={(e) => setResourceName(e.target.value)}
                        />
                    </div>
                    <div className={"modal-share-resource-field"}>
                        <label htmlFor={"description"}>Description</label>
                        <TextField
                            variant="outlined"
                            placeholder="Description"
                            type="text"
                            id={"description"}
                            value={resourceDescription}
                            onChange={(e) => setResourceDescription(e.target.value)}
                        />
                    </div>
                    <div className={"modal-share-resource-field"}>
                        <label htmlFor={"link"}>Lien de la ressource</label>
                        <TextField
                            variant="outlined"
                            placeholder="Lien de la ressource"
                            type="text"
                            id={"link"}
                            value={resourceLink}
                            onChange={(e) => setResourceLink(e.target.value)}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={modalDeleteShareResource}
                onClose={() => {
                    setModalDeleteShareResource(false);
                }}
                title={"Supprimer cet outil ?"}
                primaryButtonText={"Supprimer"}
                primaryButtonAction={async () => {
                    await deleteResourceTeam();
                    setModalDeleteShareResource(false);
                }}
                tertiaryButtonText={"Annuler"}
                tertiaryButtonAction={() => {
                    setModalDeleteShareResource(false);
                }}
            >
            </Modal>
            <Modal
                open={modalEditShareResource}
                onClose={async () => {
                    await setModalEditShareResource(false);
                    setResourceName("");
                    setResourceLink("");
                    setResourceTeamId("");
                    setResourceDescription("");
                }}
                title={"Modifier l'outil"}
                primaryButtonText={"Modifier"}
                primaryButtonAction={async () => {
                    await editResourceTeam();
                    await setModalEditShareResource(false);
                    setResourceName("");
                    setResourceLink("");
                    setResourceTeamId("");
                    setResourceDescription("");
                }}
                tertiaryButtonText={"Annuler"}
                tertiaryButtonAction={async () => {
                    await setModalEditShareResource(false);
                    setResourceName("");
                    setResourceLink("");
                    setResourceTeamId("");
                    setResourceDescription("");
                }}
            >
                <div className={"modal-share-resource-block-form"}>
                    <div className={"modal-share-resource-field"}>
                        <label htmlFor={"name"}>Nom de l'outil</label>
                        <TextField
                            variant="outlined"
                            placeholder="Nom de l'outil"
                            type="text"
                            id={"name"}
                            value={resourceName}
                            onChange={(e) => setResourceName(e.target.value)}
                        />
                    </div>
                    <div className={"modal-share-resource-field"}>
                        <label htmlFor={"description"}>Description</label>
                        <TextField
                            variant="outlined"
                            placeholder="Description"
                            type="text"
                            id={"description"}
                            value={resourceDescription}
                            onChange={(e) => setResourceDescription(e.target.value)}
                        />
                    </div>
                    <div className={"modal-share-resource-field"}>
                        <label htmlFor={"link"}>Lien de la ressource</label>
                        <TextField
                            variant="outlined"
                            placeholder="Lien de la ressource"
                            type="text"
                            id={"link"}
                            value={resourceLink}
                            onChange={(e) => setResourceLink(e.target.value)}
                        />
                    </div>
                </div>
            </Modal>
        </section>
    );
}

export default ListChallengeTeams;
