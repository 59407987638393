import React from "react";

const SwitchTranslateFr: React.FC = () => {
    return (
        <svg width="31" height="31" viewBox="0 0 100 95" xmlns="http://www.w3.org/2000/svg">
    <g>
        <path fill="#828D9B"
              d="M84,5H16C9.9,5,5,9.6,5,15.3V67c0,5.7,4.9,10.3,11,10.3h7.2v15.3c0,0.9,0.6,1.8,1.5,2.2 c0.4,0.2,0.7,0.2,1.1,0.2c0.6,0,1.2-0.2,1.7-0.5l22.7-17.2H84c6.1,0,11-4.6,11-10.3V15.3C95,9.6,90.1,5,84,5z M89.9,66.9 c0,3-2.6,5.4-5.8,5.4H49.2c-0.6,0-1.2,0.2-1.7,0.5L28.4,87.3V74.7c0-1.3-1.2-2.4-2.6-2.4H16c-3.2,0-5.8-2.4-5.8-5.4V15.5 c0-3,2.6-5.4,5.8-5.4h68.2c3.2,0,5.8,2.4,5.8,5.4L89.9,66.9L89.9,66.9z"/>
        <polygon fill="#828D9B"
                 points="26,55.5 32,55.5 32,43.3 45,43.3 45,38.2 32,38.2 32,31.5 46.8,31.5 46.8,26.3 26,26.3"/>
        <path fill="#828D9B"
              d="M73.6,53.1c-0.1-0.5-0.2-1.4-0.2-2.7v-2c0-2-0.3-3.6-0.8-4.6c-0.6-1-1.5-1.8-2.9-2.3 c1.6-0.6,2.8-1.5,3.5-2.8 s1-2.7,1-4.1c0-1.1-0.2-2.2-0.5-3.1s-0.9-1.7-1.5-2.5c-0.7-0.9-1.7-1.6-2.7-2c-1.1-0.5-2.6-0.7-4.6-0.8h-14v29.2h6V44h6 c1.7,0,2.9,0.3,3.5,0.9c0.6,0.6,0.9,1.9,1,3.7v2.7c0,0.9,0.1,1.7,0.3,2.5c0.1,0.4,0.2,0.9,0.4,1.6h6.7v-0.7 C74.1,54.4,73.7,53.8,73.6,53.1z M66.4,38.7c-0.7,0.3-1.6,0.5-2.9,0.5h-6.6v-7.8h6.7c1.3,0,2.2,0.2,2.8,0.5 c1.1,0.6,1.7,1.8,1.7,3.6C68.1,37.1,67.5,38.2,66.4,38.7z"/>
    </g>
</svg>

    )
}

export default SwitchTranslateFr;
