// components
import ActionButton from '../../../../../../components/ActionButton'
import GoBack from '../../../../../../components/GoBack'
import GoNext from '../../../../../../components/GoNext'

// assets
import {ReactComponent as AlertIcon} from '../../../../../../assets/icon-alert.svg'
import {ReactComponent as InfosIcon} from '../../../../../../assets/icon-info.svg'
import {ReactComponent as AnnonceIcon} from '../../../../../../assets/icon-annonce.svg'
import {ReactComponent as DeleteIcon} from '../../../../../../assets/icon-close.svg'
import {ReactComponent as ChangeIcon} from '../../../../../../assets/icon-change-profile.svg'


// mui
import {
    Alert,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Snackbar,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {renderTimeViewClock} from '@mui/x-date-pickers'


// react-routeur
import {useNavigate, useParams} from 'react-router-dom'

// style
import '../index.css'
import './index.css'

// react
import {useEffect, useRef, useState} from 'react'

// outlet context
import {useAdminEditChallengeContext} from '..'

// other
import moment, {Moment} from 'moment'
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import api from "../../../../../../utils/api";

const AdminEditChallengeSignUp = () => {
    const {id} = useParams();
    const {change, setChange} = useAdminEditChallengeContext();
    const {click, setClick} = useAdminEditChallengeContext();
    const {page, setPage} = useAdminEditChallengeContext();
    const {registrationStartDate, setRegistrationStartDate} = useAdminEditChallengeContext();
    const {registrationEndDate, setRegistrationEndDate} = useAdminEditChallengeContext();
    const {totalParticipants, setTotalParticipants} = useAdminEditChallengeContext();
    const {typeParticipation, setTypeParticipation} = useAdminEditChallengeContext();
    const {nbMinParticipants, setNbMinParticipants} = useAdminEditChallengeContext();
    const {nbMaxParticipants, setNbMaxParticipants} = useAdminEditChallengeContext();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {rules, setRules} = useAdminEditChallengeContext();
    const {eligibilities, setEligibilities} = useAdminEditChallengeContext();
    const {fileNameStorage, setFileNameStorage} = useAdminEditChallengeContext();
    const {fileName, setFileName} = useAdminEditChallengeContext();
    const {fileToDelete, setFileToDelete} = useAdminEditChallengeContext();

    const {error, setError} = useAdminEditChallengeContext();


    const {regulation, setRegulation} = useAdminEditChallengeContext();
    const {challenge, setChallenge} = useAdminEditChallengeContext();
    const {openSnackBarEditRegistration, setOpenSnackBarEditRegistration} = useAdminEditChallengeContext();
    const {variantSnackEditRegistration, setVariantSnackBarEditRegistration} = useAdminEditChallengeContext();
    const {textSnackBarEditRegistration, setTextSnackBarEditRegistration} = useAdminEditChallengeContext();
    const {originalSteps} = useAdminEditChallengeContext();
    const {getOneChallenge} = useAdminEditChallengeContext();
    //Calcul Hauteur Header
    const [heightHeader, setHeightHeader] = useState(0);
    const [heightFooter, setHeightFooter] = useState(0);

    useEffect(() => {
        // Fonction pour afficher la hauteur
        const logHeight = () => {
            const heightHeader = document.querySelector('.admin-edit-challenge-outlet-nav')?.clientHeight;
            const heightFooter = document.querySelector('.admin-edit-challenge-nav-bottom')?.clientHeight;

            heightFooter && setHeightFooter(heightFooter);
            heightHeader && setHeightHeader(heightHeader);
        };

        // Appeler la fonction pour la première fois
        logHeight();

        // Mettre à jour la hauteur en temps réel (par exemple, lors d'un redimensionnement de la fenêtre)
        const handleResize = () => {
            logHeight();
        };

        // Ajouter un écouteur d'événements de redimensionnement de fenêtre
        window.addEventListener('resize', handleResize);

        // Assurez-vous de désinscrire l'événement lorsqu'il n'est plus nécessaire
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getOneChallenge(String(id));
    }, [])
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);
    const updateChallenge = async (field: keyof any, value: string | number | Moment | boolean | null) => {
        if (challenge) {
            let startDate = challenge.startDate;
            let endDate = challenge.endDate;
            let dateMessage = " du challenge";

            console.log("field", field);
            console.log("value", value);


            if (originalSteps.length !== 0) {
                startDate = originalSteps[0].dateStartDeposite;
                endDate = originalSteps[0].dateEndDeposite;
                dateMessage = " de la première étape";
            }

            if (field === 'registrationStartDate') {
                if (moment.isMoment(value) && !value.isBetween(moment(startDate), moment(endDate))) {
                    setError({
                        error: "InvalidDateRangeStart",
                        message: "La date doit se trouver entre le début du challenge et la fin" + dateMessage
                    })
                } else {
                    setError(null)
                }
            }
            if (field === 'registrationEndDate') {
                if (moment.isMoment(value) && !value.isBetween(moment(startDate), moment(endDate))) {
                    setError({
                        error: "InvalidRegistrationEndDate",
                        message: "La date doit se trouver entre le début du challenge et la fin" + dateMessage
                    })
                } else {
                    setError(null)
                }
            }

            const currentLanguage = i18n.language;
            let dateFormat = 'DD/MM/YYYY hh:mm'; // Format par défaut
            if (currentLanguage === 'fr') {
                dateFormat = 'DD/MM/YYYY HH:mm'; // Format français
            } else if (currentLanguage === 'en') {
                dateFormat = 'MM/DD/YYYY hh:mm A'; // Format anglais
            }
            const newChallenge: any = {...challenge};
            if ((field === 'registrationStartDate' || field === 'registrationEndDate') && moment.isMoment(value)) {
                newChallenge[field] = moment(value, dateFormat).toISOString();
            } else {
                newChallenge[field] = value;
            }
            // console.log("newChallenge",newChallenge);
            setChallenge(newChallenge);
        }
    };

    useEffect(() => {
        console.log("Challenge", challenge);
    }, [challenge])

    const handleUpload = (event: any) => {
        setChange(true)
        const file = event.target.files[0];
        console.log(file);
        setRules(file)
        console.log("file", file);

        // Vous pouvez maintenant utiliser le fichier pour l'upload ou le lire.
    };

    useEffect(() => {
        setPage('signup');
    }, [])

    // params
    const {name} = useParams();
    const limitText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const [errorEligibilities, setErrorEligibilities] = useState(false);
    const getEligibility = async () => {
        await api.get('/challenge/eligibilities', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                setEligibilities(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };
    useEffect(() => {
        getEligibility();
        console.log(eligibilities);
    }, []);
    const addEligibility = () => {
        const allFieldsFilled = eligibilities.every((eligibility: any) => eligibility.label.trim() !== '');
        if (allFieldsFilled) {
            setEligibilities([...eligibilities, {label: "", index: Math.round(Math.random() * 1000000)}]);
            setErrorEligibilities(false);
        } else {
            setErrorEligibilities(true);
        }
    };

    const handleEligibilityChange = (index: any, value: any) => {
        const newEligibilities = [...eligibilities];
        newEligibilities[index].label = value;
        setEligibilities(newEligibilities);
    };

    const removeEligibility = async (index: any) => {
        const newEligibilities = [...eligibilities];
        const eligibilityToRemove = newEligibilities[index].index;
        newEligibilities.splice(index, 1);
        setEligibilities(newEligibilities);
        if (eligibilityToRemove) {
            try {
                await api.delete('/challenge/eligibilities', {
                    data: {
                        challengeId: id,
                        index: eligibilityToRemove
                    },
                    withCredentials: true
                });
                console.log('Critère d\'éligibilité supprimé avec succès.');
            } catch (error) {
                console.error('Erreur lors de la suppression du critère d\'éligibilité :', error);
            }
        } else {
            console.log(`Aucun critère d'éligibilité avec l'index ${index} trouvé.`);
        }
    };

    const renderEligibilityFields = () => {
        return eligibilities.map((eligibility: any, index: any) => (
            <div key={index}>
                <FormControl sx={{marginTop: "1rem", width: '100%'}} variant="outlined">
                    <InputLabel htmlFor={`outlined-adornment-eligibility-${index}`}>
                        Critère d'éligibilité {index + 1}
                    </InputLabel>
                    <OutlinedInput
                        id={`outlined-adornment-eligibility-${index}`}
                        type={"text"}
                        value={eligibility.label}
                        defaultValue={eligibility.label}
                        onChange={(e) => handleEligibilityChange(index, e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => removeEligibility(index)} edge="end">
                                    <DeleteIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        label={`Critère d'éligibilité ${index + 1}`}
                        error={errorEligibilities && !eligibility.label.trim()}
                    />
                </FormControl>
            </div>
        ));
    };

    return (
        <div className='admin-edit-challenge'>
            {challenge && (
                <section className='admin-edit-challenge-signup'>
                    <Grid container justifyContent={"auto"} justifyItems={"auto"} spacing={2}
                          paddingX={!isMdDown ? 2 : 0} paddingLeft={!isMdDown ? 4 : 2}>
                        <Grid item xs={12} md={5} paddingX={2}>
                            <div className={`${!isMdDown ? "sticky-desktop-registration" : "sticky-mobile"}`}
                                 style={{top: `calc(50% + (${heightHeader}px - ${heightFooter}px)/2`}}>

                                <div className="gerer-inscription fixed">
                                    <div className="header-text">
                                        <h2 className='text-center two-view-height'>Gérez les inscriptions</h2>
                                        <p className="small-lead-text text-center">Période d'inscription
                                            prévisionnelle</p>
                                        <p className="text text-center">Informez vos futurs participants pour qu’ils ne
                                            manquent pas l’occasion de
                                            s’inscrire à votre Challenge.</p>
                                    </div>

                                    <div>
                                        <Grid container spacing={2} justifyContent={"center"} marginBottom={2}>
                                            <Grid item lg={6}>
                                                <DateTimePicker
                                                    disabled={(originalSteps.some((step) => step.status && step.status.name !== 'todo') || (challenge.status.name === "finished"))}
                                                    slotProps={{
                                                        textField: {
                                                            disabled: (originalSteps.some((step) => step.status && step.status.name !== 'todo') || (challenge.status.name === "finished")),
                                                            size: "small",
                                                            fullWidth: true,
                                                            error: !!(error && (error.error === "InvalidDateRangeStart" || error.error === "InvalidStartDate")),
                                                            helperText: error && (error.error === "InvalidDateRangeStart" || error.error === "InvalidStartDate") ? error?.message : "",
                                                        },
                                                    }}
                                                    // value={moment(registrationStartDate, ["MM-DD-YYYY hh:mm", "DD-MM-YYYY hh:mm"])}
                                                    value={challenge && challenge.registrationStartDate ? moment(challenge.registrationStartDate) : null}
                                                    onChange={(date: Moment | null,) => {
                                                        setChange(true)
                                                        updateChallenge('registrationStartDate', date);
                                                        // setRegistrationStartDate(String(moment(date)));
                                                    }}
                                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={6}>
                                                <DateTimePicker
                                                    disabled={(originalSteps.some((step) => step.status && step.status.name !== 'todo') || (challenge.status.name === "finished"))}
                                                    slotProps={{
                                                        textField: {
                                                            disabled: (originalSteps.some((step) => step.status && step.status.name !== 'todo') || (challenge.status.name === "finished")),
                                                            size: "small",
                                                            fullWidth: true,
                                                            error: error && (error.error === "InvalidRegistrationEndDate" || error.error === "InvalidEndDate") ? true : false,
                                                            helperText: error && (error.error === "InvalidRegistrationEndDate" || error.error === "InvalidEndDate") ? error?.message : "",
                                                        },
                                                    }}
                                                    // value={moment(registrationStartDate, ["MM-DD-YYYY hh:mm", "DD-MM-YYYY hh:mm"])}
                                                    value={challenge && challenge.registrationEndDate ? moment(challenge.registrationEndDate) : null}
                                                    onChange={(originalSteps.some((step) => step.status && step.status.name !== 'todo') || (challenge.status.name === "finished")) ? () => {
                                                    } : (date: Moment | null) => {
                                                        setChange(true)
                                                        updateChallenge('registrationEndDate', date);
                                                        // setRegistrationEndDate(moment(date).toString());
                                                    }}
                                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* <p className='error-message'>{error && error.error && error.message?error.message:""}</p> */}
                                    </div>

                                    <div className="div-alert">
                                        <AlertIcon/>
                                        <p className="text">Les dates prévisionnelles indiquées ne déclenchent pas
                                            automatiquement l'ouverture et la fermeture des inscriptions. <br/>
                                            Cliquez sur les boutons Lancer ou Fermer les inscriptions dans la page de
                                            votre Challenge.</p>
                                    </div>
                                    <div className="admin-eligibilities">
                                        <h4 className="bold">Critères d'éligibilité des participants</h4>
                                        <p>Certains critères sont-ils nécessaires pour participer à votre Challenge
                                            ?</p>
                                        {renderEligibilityFields()}
                                        {errorEligibilities &&
                                            <p style={{color: 'red', textAlign: "center"}}>Veuillez remplir tous
                                                les champs des critères
                                                existants.</p>}
                                        <div className="container-button-add-eligibilities">
                                            <div className="button-add-eligibilities">
                                                <ActionButton
                                                    variant="secondary"
                                                    onClick={() => addEligibility()}
                                                >
                                                    Ajouter un critère d'éligibilité
                                                </ActionButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7} paddingX={2}>
                            <div className="modalites-incription scroll">
                                <div className="header-text">
                                    <h1 className='text-center two-view-height'>Modalités d’inscription</h1>
                                    <p className="text p-annonce"><span className='icon-annonce'><AnnonceIcon/></span>Requis
                                        pour
                                        publier votre Challenge.</p>
                                </div>
                                <div className="max-participants">
                                    <div>
                                        <p className="small-lead-text annonce-text">Nombre maximal de participants <span
                                            className='icon-annonce'><AnnonceIcon/></span></p>
                                        <p className="text">Définissez un nombre maximal de participants au total pour
                                            votre
                                            Challenge.</p>
                                    </div>
                                    <div className='compteur-max'>
                                        <p className="text">Jusqu'à</p>
                                        <TextField
                                            disabled={(challenge.registration || challenge.status.name === "finished")}
                                            sx={{padding: 0}}
                                            error={error && (error.error === "NoNumberMembersTeam" || error.error === "NoMaxMembersTeam") ? true : false}
                                            helperText={error && (error.error === "NoNumberMembersTeam" || error.error === "NoMaxMembersTeam") ? error.message : ""}
                                            id="outlined-adornment-weight"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0
                                                },
                                                endAdornment: <InputAdornment
                                                    position="end">participants</InputAdornment>,
                                            }}
                                            aria-describedby="outlined-weight-helper-text"
                                            type='number'
                                            defaultValue={30}
                                            value={challenge && challenge.maxMembers ? challenge.maxMembers : 0}
                                            onChange={(e) => {
                                                let value: string | number = e.target.value;
                                                if (Number(value) !== 0 && value[0] === '0') {
                                                    const valueString = String(value).substring(1);
                                                    value = Number(valueString);
                                                }
                                                setChange(true);
                                                updateChallenge('maxMembers', Number(value));
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="participation-mode">

                                    <div className='small-lead-text'>
                                        <p className="small-lead-text annonce-text">Mode de participation <span
                                            className='icon-annonce'><AnnonceIcon/></span></p>
                                        <p>Définissez si les inscrits à votre Challenge peuvent participer seul ou en
                                            équipe. Dans le
                                            cas d’une équipe, précisez le nombre minimal et maximal de membres.</p>
                                    </div>
                                    <div className='radio-buttons'>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="team"
                                            name="radio-buttons-group"
                                            value={challenge && challenge.participationType ? challenge.participationType : "team"}
                                            onChange={(e) => {
                                                setChange(true)
                                                updateChallenge('participationType', e.target.value);
                                            }}
                                        >
                                            <FormControlLabel value="single" control={<Radio
                                                disabled={(challenge.registration || challenge.status.name === "finished")}/>}
                                                              label="Uniquement seul"/>
                                            <FormControlLabel value="team" control={<Radio
                                                disabled={(challenge.registration || challenge.status.name === "finished")}/>}
                                                              label="Uniquement en équipe"/>

                                            {challenge.participationType === 'team' && <div className='nb-participants'>
                                                <p className="text">De</p>
                                                <OutlinedInput
                                                    // sx={
                                                    //     { padding: 0 }
                                                    // }
                                                    disabled={(challenge.registration || challenge.status.name === "finished")}
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment
                                                        position="end">membres</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                        min: 0
                                                    }}
                                                    type='number'
                                                    value={challenge && isNaN(challenge.minMembersTeam) ? 0 : challenge.minMembersTeam}
                                                    onChange={(e) => {
                                                        let value: string | number = e.target.value;
                                                        if (Number(value) !== 0 && value[0] === '0') {
                                                            const valueString = String(value).substring(1);
                                                            value = Number(valueString);
                                                        }
                                                        setChange(true);
                                                        updateChallenge('minMembersTeam', Number(value));
                                                    }}

                                                />
                                                <p className="text">à</p>
                                                <OutlinedInput
                                                    disabled={(challenge.registration || challenge.status.name === "finished")}
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment
                                                        position="end">membres</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                        min: 2
                                                    }}
                                                    type='number'
                                                    value={challenge && isNaN(challenge.maxMembersTeam) ? 0 : challenge.maxMembersTeam}
                                                    onChange={(e) => {
                                                        let value: string | number = e.target.value;
                                                        if (Number(value) !== 0 && value[0] === '0') {
                                                            const valueString = String(value).substring(1);
                                                            value = Number(valueString);
                                                        }
                                                        setChange(true);
                                                        updateChallenge('maxMembersTeam', Number(value));
                                                    }}
                                                />
                                            </div>}

                                            <FormControlLabel value="both" control={<Radio
                                                disabled={(challenge.registration || challenge.status.name === "finished")}/>}
                                                              label="Seul ou en équipe"/>
                                            {challenge.participationType === 'both' && <div className='nb-participants'>
                                                <p className="text">Jusqu'à </p>
                                                <OutlinedInput
                                                    // sx={
                                                    //     { padding: 0 }
                                                    // }
                                                    disabled={(challenge.registration || challenge.status.name === "finished")}
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment
                                                        position="end">membres</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                        min: 2
                                                    }}
                                                    type='number'
                                                    value={challenge && isNaN(challenge.maxMembersTeam) ? 0 : challenge.maxMembersTeam}
                                                    onChange={(e) => {
                                                        let value: string | number = e.target.value;
                                                        if (Number(value) !== 0 && value[0] === '0') {
                                                            const valueString = String(value).substring(1);
                                                            value = Number(valueString);
                                                        }
                                                        setChange(true);
                                                        updateChallenge('maxMembersTeam', Number(value));
                                                    }}
                                                />
                                            </div>}
                                        </RadioGroup>
                                    </div>
                                </div>

                                <div className="infos-participants">
                                    <InfosIcon/>
                                    <p className="small-lead-text">Informations récupérées auprès des participants</p>
                                    <p>A chaque inscription d’un participant, vous récupérez automatiquement les
                                        informations
                                        suivantes dans la liste des inscrits à votre Challenge :</p>
                                    <ul>
                                        <li className='text'>Nom</li>
                                        <li className='text'>Prénom</li>
                                        <li className='text'>Adresse e-mail</li>
                                        <li className='text'>Téléphone</li>
                                    </ul>
                                </div>

                                <div className="scroll-rules">
                                    <div className="header-text">
                                        <h1 className='text-center'>Règlement</h1>
                                        <p className="text">Si vous le souhaitez, spécifiez les conditions de
                                            participation à votre
                                            Challenge en ajoutant un règlement.</p>
                                    </div>
                                    <div className='form'>
                                        <p className="small-lead-text">Règlement du Challenge <span>(facultatif)</span>
                                        </p>
                                        {/* <Input
                                            // {...(error && { error: true })}
                                            id="lastname"
                                            placeholder='Nom du challenge'
                                            type='file'
                                            required
                                            fullWidth
                                            value={rules}
                                            onChange={handleFileChange}
                                        /> */}
                                        {/* {
                                            fileName ?
                                                <div className='file-name'>
                                                    <p className='text'>{fileName}</p>
                                                    <div className="buttons">
                                                        <ChangeIcon onClick={() => {
                                                            console.log('change')
                                                            setFileToDelete(fileNameStorage)
                                                            setFileName("")
                                                            setFileNameStorage("")
                                                            setRules(undefined)
                                                            setChange(true)
                                                        }}
                                                        />
                                                        <DeleteIcon onClick={() => {
                                                            console.log('delete')
                                                            setFileToDelete(fileNameStorage)
                                                            setFileName("")
                                                            setFileNameStorage("")
                                                            setRules(undefined)
                                                            setChange(true)
                                                        }}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <TextField
                                                    error={error && (error.error === "BadFileType" || error.error === "FileNotCreated") ? true : false}
                                                    helperText={error && (error.error === "BadFileType" || error.error === "FileNotCreated") ? error.message : ""}
                                                    id="file"
                                                    placeholder='Nom du challenge'
                                                    type='file'
                                                    inputProps={{accept: "application/pdf"}}
                                                    required
                                                    fullWidth
                                                    onChange={handleUpload}
                                                />
                                        } */}

                                        <div style={{border: '1px'}}>
                                            {/* <label>Document de la ressource</label> */}
                                            <Button
                                                disabled={challenge.status.name === 'finished'}
                                                size='small'
                                                variant="contained"
                                                className={"upload-file"}
                                                component="label"
                                                sx={{
                                                    maxWidth: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    borderColor: "none",
                                                }}
                                            >
                                                <p style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    margin: 0,
                                                }}
                                                   className={`button-text w-80 ${challenge.status.name === "finished" ? "rule-text-disabled" : ""}`}>
                                                    {limitText(
                                                        (rules && rules.name) ||
                                                        ((challenge && challenge.file && challenge.file.originalFileName) || 'Importer un document'),
                                                        15
                                                    )}
                                                </p>
                                                <input
                                                    disabled={challenge.status.name === 'finished'}
                                                    type="file"
                                                    hidden
                                                    accept=".pdf"
                                                    onChange={challenge.status.name === 'finished' ? () => {
                                                    } : (event) => {
                                                        const files = (event.target as HTMLInputElement).files
                                                        if (files && files.length > 0) {
                                                            const selectedFile = files[0];
                                                            if (selectedFile && ((selectedFile.size && selectedFile.size > 2 * 1024 * 1024) || selectedFile.type !== 'application/pdf')) {
                                                                console.log("Invalid file type. Please select a PDF file.");
                                                                // Vous pouvez également afficher un message d'erreur à l'utilisateur ici.
                                                            } else {
                                                                setChange(true);
                                                                setRules(selectedFile);
                                                            }
                                                        }
                                                    }
                                                    }
                                                />
                                                <div>
                                                    <Button
                                                        onClick={challenge.status.name === 'finished' ? () => {
                                                        } : () => {
                                                            setChange(true)
                                                            setRules(undefined)
                                                            updateChallenge('file', null);
                                                        }
                                                        }>
                                                        <DeleteIcon
                                                            className='ms-auto'
                                                        />
                                                    </Button>
                                                </div>


                                            </Button>
                                            <FormHelperText
                                                error={rules && ((rules.size && rules.size > 2 * 1024 * 1024) || rules.type !== 'application/pdf') ? true : false}
                                            >
                                                PDF uniquement, taille maximale 2Mo
                                            </FormHelperText>

                                            {/* <FormHelperText
                                                error={errorResource.some(error => String(error.id) === String(resource.id) && (error.code === "linkOrFile" || error.code === "file"))}
                                            >
                                                {errorResource.some(error => String(error.id) === String(resource.id) && (error.code === "linkOrFile" || error.code === "file")) ? errorResource.find(error => String(error.id) === String(resource.id) && (error.code === "linkOrFile" || error.code === "file"))?.message : ""}
                                            </FormHelperText> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Snackbar
                        open={openSnackBarEditRegistration}
                        autoHideDuration={3000}
                        onClose={() => {
                            setOpenSnackBarEditRegistration(false)
                            setVariantSnackBarEditRegistration('success')
                            setTextSnackBarEditRegistration('')
                        }}
                    >
                        <Alert onClose={() => {
                            setOpenSnackBarEditRegistration(false)
                            setVariantSnackBarEditRegistration('success')
                            setTextSnackBarEditRegistration('')
                        }} severity={variantSnackEditRegistration}>{textSnackBarEditRegistration}</Alert>
                    </Snackbar>
                </section>
            )}
        </div>
    )
}

export default AdminEditChallengeSignUp;
