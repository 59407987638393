import { Action } from 'redux';
import {ParticipantProfile, ProfileState} from '../type';

import { AdminProfile, CollaboratorProfile } from '../type';

// Définissez le type pour l'action 'SET_USER'
interface SetProfileAction extends Action<'SET_PROFILES'> {
    payload: {
        allProfiles: {
            Profiles: {
                superadmin: Array<{ email: string }> | null;
                participant: Array<ParticipantProfile> | null;
                admin: Array<AdminProfile> | null;
                collaborator: Array<CollaboratorProfile> | null;
            }
        };
        nbAdmin: number;
        nbCollaborator: number;
        nbParticipant: number;
        nbProfiles: number;
        profiles: string[];
    };
};

const initialState: ProfileState = {
    allProfiles: {
        Profiles: {
            superadmin: null,
            participant: null,
            admin: null,
            collaborator: null
        }
    },
    nbAdmin: 0,
    nbCollaborator: 0,
    nbParticipant: 0,
    nbProfiles: 0,
    profiles: []
};

const profileReducer = (state = initialState, action: Action): ProfileState => {
    switch (action.type) {
        case 'SET_PROFILES':
            // Utilisez une assertion de type pour indiquer que l'action a le type approprié
            const setProfileAction = action as SetProfileAction;
            return {
                ...state,
                allProfiles: setProfileAction.payload.allProfiles || state.allProfiles,
                nbAdmin: setProfileAction.payload.nbAdmin || state.nbAdmin,
                nbCollaborator: setProfileAction.payload.nbCollaborator || state.nbCollaborator,
                nbParticipant: setProfileAction.payload.nbParticipant || state.nbParticipant,
                nbProfiles: setProfileAction.payload.nbProfiles || state.nbProfiles,
                profiles: setProfileAction.payload.profiles || state.profiles
            };
        case 'CLEAR_PROFILES':
            return initialState;
        default:
            return state;
    }
};

export default profileReducer;
